import { KnockProvider as ActualKnockProvider } from "@knocklabs/react";
import { KnockFeedProvider } from "@knocklabs/react-notification-feed";
import { ReactElement } from "react";

import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";

const KnockProvider = withCurrentActor(
  ({
    children,
    actor,
    userNotificationToken,
  }: {
    readonly children: ReactElement;
    readonly actor: UserWithInstitutionFragment;
    readonly userNotificationToken: string;
  }) => {
    const knockApiKey = process.env.NEXT_PUBLIC_KNOCK_PUBLIC_API_KEY || ``;
    const knockFeedId = process.env.NEXT_PUBLIC_KNOCK_FEED_ID || ``;

    return (
      <ActualKnockProvider
        apiKey={knockApiKey}
        userId={actor.id}
        userToken={userNotificationToken}
      >
        <KnockFeedProvider
          rootless
          userId={actor.id}
          apiKey={knockApiKey}
          feedId={knockFeedId}
          userToken={userNotificationToken}
        >
          {children}
        </KnockFeedProvider>
      </ActualKnockProvider>
    );
  },
);

export default KnockProvider;
