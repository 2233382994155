import { constants } from ".";

const { ceil, trunc, log10 } = Math;

const exponent = (number: number) => 10 ** trunc(log10(number));

export const roundUp = (
  number: number,
  factor: number = constants.listing_shares_rounding_factor,
) => {
  if (number < 10) return number;

  const base = (factor * exponent(number)) / 10;
  return trunc(ceil(number / base) * base);
};

export const isNegativeTrend = (trend: string | number) => Number(trend) < 0;
export const isNeutralTrend = (trend: string | number) => Number(trend) === 0;
