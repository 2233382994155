import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

const CompanyLastRoundDetailsCardSkeleton = () => (
  <Card>
    <CardHeader>
      <Skeleton h={5} maxW="30%" />
    </CardHeader>
    <CardBody>
      <Grid templateColumns="1fr 3fr" rowGap="60px" columnGap={40}>
        {[...Array(10).keys()].map((index) => (
          <GridItem key={index} _even={{ maxW: `75%` }}>
            <Skeleton h="14px" />
          </GridItem>
        ))}
      </Grid>
    </CardBody>
    <CardFooter>
      <Flex direction="column" gap={4} w="full">
        <Skeleton h="14px" />
        <Skeleton h="14px" maxW="50%" />
      </Flex>
    </CardFooter>
  </Card>
);

export default CompanyLastRoundDetailsCardSkeleton;
