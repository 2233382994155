import { useSelect as useDownshiftListbox } from "downshift";
import React, { ReactElement } from "react";

import { ItemList } from "@/components/form";

/**
 * Custom wrapper around downshift useSelect
 */
const useListbox = <TItem>({
  onClickItem,
  items,
  itemToString,
  selectedItem,
  itemRenderer,
  buttonStyle,
  menuStyle,
}: {
  readonly onClickItem: (item: TItem) => void;
  readonly items: ItemList<TItem>;
  readonly itemToString: (item: TItem) => string;
  readonly selectedItem: TItem | null;
  readonly itemRenderer?: (item: TItem) => ReactElement;
  readonly buttonStyle?: React.CSSProperties;
  readonly menuStyle?: React.CSSProperties;
}) => {
  const {
    isOpen,
    getToggleButtonProps,
    getItemProps,
    getMenuProps,
    getLabelProps,
    highlightedIndex,
  } = useDownshiftListbox<TItem>({
    items,
    itemToString: (item: TItem | null) => (!!item ? itemToString(item) : ``),
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return;
      onClickItem(selectedItem);
    },
    initialSelectedItem: selectedItem,
  });

  const buttonProps = {
    itemToString,
    selectedItem,
    getToggleButtonProps,
    itemRenderer,
    style: buttonStyle,
  };

  const itemProps = {
    highlightedIndex,
    getItemProps,
  };

  const menuProps = {
    isOpen,
    items,
    getMenuProps,
    style: menuStyle,
  };

  const labelProps = {
    ...getLabelProps(),
  };

  return { buttonProps, itemProps, menuProps, labelProps };
};
export default useListbox;
