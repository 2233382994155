import { Text, Stack, StackProps } from "@chakra-ui/react";

import { KYCSensitive } from "@/components/common";

const ShareDetailsStat = ({
  title,
  children,
  ...stackProps
}: StackProps & {
  readonly title: string;
  readonly children?: React.ReactNode;
}) => (
  <Stack
    spacing={2}
    alignItems="center"
    direction={{ base: `row`, sm: `column` }}
    textAlign={{ base: `left`, sm: `center` }}
    flex="1 1 0"
    data-testid={`share-details-stat-${title}`}
    {...stackProps}
  >
    <Text textStyle="heading-2xs">{title}</Text>
    <Text flex="1" textStyle={{ base: `heading-lg`, md: `heading-3xl` }}>
      <KYCSensitive>{children}</KYCSensitive>
    </Text>
  </Stack>
);

export default ShareDetailsStat;
