import { X } from "@phosphor-icons/react";
import { ReactNode } from "react";

import { Box, HStack, IconButton, ModalHeader } from "@chakra-ui/react";

import { useModal } from "@/hooks";

const HiiveModalHeader = ({
  children,
  closeModal,
}: {
  readonly children: ReactNode;
  readonly closeModal?: () => void;
}) => {
  const { closeModal: modalProviderCloseModal } = useModal();

  return (
    <ModalHeader
      as={HStack}
      pr={{ base: 4, md: 6 }}
      justifyContent="space-between"
      alignItems="center"
      w="full"
      color="white"
      bg="grey.900"
    >
      <Box textStyle="heading-3xl">{children}</Box>
      <IconButton
        aria-label="Close modal"
        onClick={closeModal || modalProviderCloseModal}
        variant="icon-close"
        icon={<X size={32} />}
      />
    </ModalHeader>
  );
};

export default HiiveModalHeader;
