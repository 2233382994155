import { Box, Text, Link } from "@chakra-ui/react";

import { AuthContainer } from "@/components/auth";
import { InternalLink } from "@/components/common";
import { withGuestRoute } from "@/components/hoc";
import { useIsHiiveConnect } from "@/hooks";

import SignUpForm from "./SignUpForm";

const getIsConfiguredEnv = () => {
  const env = process.env.NEXT_PUBLIC_INFRA_ENV;
  if (!env) return false;
  return [`production`, `development`].includes(env);
};

const getHiiveMarketplaceLink = () => {
  const isConfiguredEnv = getIsConfiguredEnv();

  return `${isConfiguredEnv ? process.env.NEXT_PUBLIC_CLIENT_HOST : ``}/signup`;
};

const SignUpPage = () => {
  const hiiveConnect = useIsHiiveConnect();
  const hiiveMarketplaceLink = getHiiveMarketplaceLink();

  return (
    <AuthContainer
      title={hiiveConnect ? `Sign up for HiiveConnect` : `Sign up`}
    >
      <>
        {hiiveConnect && (
          <>
            <Text align="center" py={4} textStyle="text-sm" color="h-dark-grey">
              HiiveConnect is a specialized platform for broker-dealers and
              securities intermediaries to submit bids to Hiive without
              accessing the Hiive marketplace.
            </Text>
            <Text
              align="center"
              maxW="md"
              textStyle="text-sm"
              color="h-dark-grey"
            >
              If you are a seller or investor acting on your own behalf, please
              visit the {` `}
              <Box as="span" fontWeight="medium" color="h-dark-grey">
                <InternalLink href={hiiveMarketplaceLink}>
                  Hiive Marketplace.
                </InternalLink>
              </Box>
            </Text>
          </>
        )}

        <Text
          margin={7}
          align="center"
          maxW="md"
          textStyle="deprecated-text-xl"
          color="h-dark-grey"
        >
          <Box as="span" mr={1}>
            Already have an account?
          </Box>
          <Box as="span" fontWeight="medium" color="h-dark-grey">
            <Link href="/login">Sign in</Link>
          </Box>
        </Text>
        <SignUpForm />
      </>
    </AuthContainer>
  );
};

export default withGuestRoute(SignUpPage);
