import { useRouter } from "next/router";

import { ChartAnnouncement } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { useMultipleHoldings } from "@/hooks/featureFlags";
import { getIsUnaccreditedSeller } from "@/utils";

import { CompanyPageContent } from "./CompanyPageContent";

interface CompanyPageProps {
  readonly id: string;
  readonly actor: UserWithInstitutionFragment;
}

const CompanyPage = withCurrentActor(({ id, actor }: CompanyPageProps) => {
  const router = useRouter();

  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  const multipleHoldingsEnabled = useMultipleHoldings();

  if (isUnaccreditedSeller && !multipleHoldingsEnabled) {
    router.replace(`/my-company`);
    return null;
  }

  return (
    <>
      {!isUnaccreditedSeller && <ChartAnnouncement />}
      <CompanyPageContent companyId={id} />
    </>
  );
});

export default CompanyPage;
