/* eslint-disable functional/immutable-data */
import Knock from "@knocklabs/client";
import { useMemo, useRef } from "react";

import { UserWithInstitutionFragment } from "@/gql";

const useKnockClient = ({
  userNotificationToken,
  actor: { id: meId },
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly userNotificationToken: string;
}) => {
  const knockClientRef = useRef<Knock | null>();

  return useMemo(() => {
    if (knockClientRef.current) knockClientRef.current.teardown();

    const knockClient = new Knock(
      process.env.NEXT_PUBLIC_KNOCK_PUBLIC_API_KEY || ``,
    );

    knockClient.authenticate(meId, userNotificationToken);

    knockClientRef.current = knockClient;

    return knockClient;
  }, [meId, userNotificationToken]);
};

export default useKnockClient;
