import dayjs from "dayjs";
import { t } from "i18next";
import { useContext } from "react";
import { Trans } from "react-i18next";

import { VStack, Text, Wrap, HStack, Box } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import {
  AvailableTimeRanges,
  IndexPriceIndicator,
  IndexPriceTrend,
} from "@/gql";
import {
  formatPercentage,
  formatPricePerShare,
  getTrendPercentageColor,
} from "@/utils";

import { PricingChartContext } from "./PricingChartProvider";

const LABELS = {
  [IndexPriceIndicator.IndexPrice]: <Trans i18nKey="aggregate" />,
  [IndexPriceIndicator.IndexPriceShareTypeCommon]: <Trans i18nKey="common" />,
  [IndexPriceIndicator.IndexPriceShareTypePreferred]: (
    <Trans i18nKey="preferred" />
  ),
  [IndexPriceIndicator.IndexPriceTransferTypeDirect]: (
    <Trans i18nKey="direct" />
  ),
  [IndexPriceIndicator.IndexPriceTransferTypeIndirect]: (
    <Trans i18nKey="indirect" />
  ),
};

const RANGE_LABELS = {
  [AvailableTimeRanges.Max]: <Trans i18nKey="all_time" />,
  [AvailableTimeRanges.OneYear]: <Trans i18nKey="past_year" />,
  [AvailableTimeRanges.SixMonths]: <Trans i18nKey="past_six_months" />,
  [AvailableTimeRanges.ThreeMonths]: <Trans i18nKey="past_three_months" />,
};

export const PricingStats = () => {
  const { range, displayedPriceTrendsCount, loading, priceTrends } = useContext(
    PricingChartContext,
  );

  const date = dayjs(Date.now()).format(`Do MMMM`);

  if (loading)
    return (
      <>
        <HStack h={20} w="full" spacing={8}>
          {Array.from({ length: displayedPriceTrendsCount }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Skeleton key={`skeleton-${index}`} h="100%" maxW={24} />
          ))}
        </HStack>
        <Box>
          <Text
            position="relative"
            top="-7px"
            mb={-1.5}
            textStyle="text-xs"
            color="grey.500"
            fontStyle="italic"
          >
            {t(`updated`)} {date}
          </Text>
        </Box>
      </>
    );

  return (
    <VStack alignItems="start" minH={28}>
      <Wrap spacingX={10}>
        {priceTrends.map(
          ({ trendName, currentPrice, changePercentage }: IndexPriceTrend) => {
            if (currentPrice === null || currentPrice === undefined)
              return null;
            const price = formatPricePerShare(currentPrice);
            return (
              <VStack alignItems="start" key={trendName} spacing={0}>
                <Text textStyle="text-xs" color="grey.800">
                  {LABELS[trendName]}
                </Text>
                <Text textStyle="text-3xl">{price}</Text>
                {changePercentage !== undefined && changePercentage !== null && (
                  <Text
                    textStyle="heading-xs"
                    color={getTrendPercentageColor(changePercentage)}
                  >
                    {formatPercentage(changePercentage)}
                  </Text>
                )}
                <Text textStyle="text-xs" color="grey.500">
                  {RANGE_LABELS[range]}
                </Text>
              </VStack>
            );
          },
        )}
      </Wrap>
      <Text textStyle="text-xs" color="grey.500" fontStyle="italic">
        {t(`updated`)} {date}
      </Text>
    </VStack>
  );
};
