import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
  Input as InputComponent,
} from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

export const Input = defineStyleConfig(
  defineMultiStyleConfig({
    defaultProps: {
      ...InputComponent.defaultProps,
      variant: `base`,
    },
    variants: {
      base: {
        field: {
          borderColor: `grey.600`,
          borderWidth: 1,
          bg: `white`,
          borderRadius: `md`,
          autoComplete: `off`,
          _disabled: {
            pointerEvents: `none`,
            borderColor: `grey.200`,
            _placeholder: {
              color: `grey.200`,
            },
          },
          _hover: {
            borderColor: `grey.200`,
          },
          _placeholder: {
            color: `grey.600`,
          },
          _focus: {
            boxShadow: `focus`,
            borderColor: `grey.600`,
          },
          _invalid: {
            borderColor: `red.500`,
            borderWidth: `2px`,
          },
        },
      },
    },
  }),
);
