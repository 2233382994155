import { useAuthUserOrNull } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface EnrollMfaResponse {
  readonly errors: string[];
  readonly qrCode: string;
}

const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/authenticator/enroll`;

const useEnrollAppMfa = () => {
  const { t } = useTranslation();
  const user = useAuthUserOrNull();
  const [loading, setLoading] = useState(false);
  const genericErrorMessage = t(`failed_to_enroll_mfa`);

  const enrollMfa = useCallback(async () => {
    setLoading(true);

    try {
      if (!user) {
        throw new Error(t(`invalid_request`));
      }

      const response = await fetch(API_URL, {
        method: `POST`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      const data: EnrollMfaResponse = await response.json();

      if (!response.ok) {
        if (data.errors.length > 0) {
          const [message] = data.errors;
          const errorMessage = message || genericErrorMessage;
          throw new Error(errorMessage);
        }
      }

      setLoading(false);
      return { data, error: null };
    } catch (error) {
      Sentry.captureException(error);
      setLoading(false);
      return { data: null, error };
    }
  }, [t, user]);

  return [enrollMfa, { loading }] as const;
};

export default useEnrollAppMfa;
