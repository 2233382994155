import dayjs from "dayjs";
import { ReactNode } from "react";
import { Trans } from "react-i18next";

import { Box, Text, Center, BoxProps, VStack, Link } from "@chakra-ui/react";

import { Logo } from "@/components/common";
import { useIsHiiveConnect } from "@/hooks";
import { constants } from "@/utils";

interface AuthContainerProps extends BoxProps {
  readonly title: string;
}

const FooterLink = ({
  href,
  children,
}: {
  readonly href: string;
  readonly children?: ReactNode; // optional for use with i18n Trans component
}) => (
  <Link href={href} textDecoration="underline" target="_blank">
    {children}
  </Link>
);

const AuthFooter = () => {
  const currentYear = dayjs().year();

  return (
    <VStack py={8} maxW="50rem" px={4} spacing={4}>
      <Text textAlign="center" textStyle="text-xs">
        <Trans
          i18nKey="signup_footer_disclaimer"
          components={[
            <FooterLink key="finra" href="https://www.finra.org/" />,
            <FooterLink key="sipc" href="https://www.sipc.org/" />,
            <FooterLink
              key="broker-check"
              href="https://brokercheck.finra.org/firm/summary/316580"
            />,
          ]}
        />
      </Text>
      <Text textAlign="center" textStyle="text-xs">
        <Trans
          i18nKey="signup_footer_copyright"
          components={[
            <FooterLink
              key="terms"
              href={`${constants.marketing_website_url}/terms`}
            />,
            <FooterLink
              key="privacy"
              href={`${constants.marketing_website_url}/privacy`}
            />,
            <FooterLink
              key="disclosures"
              href={`${constants.marketing_website_url}/disclosures`}
            />,
          ]}
          values={{ year: currentYear }}
        />
      </Text>
    </VStack>
  );
};

export const AuthContainer = ({
  title,
  children,
  ...boxProps
}: AuthContainerProps) => {
  const hiiveConnect = useIsHiiveConnect();

  return (
    <VStack
      pt={14}
      px={{ base: 4, lg: 8 }}
      bg="grey.50"
      minH={{ base: `auto`, lg: `100vh` }}
      w="full"
      justifyContent="space-between"
    >
      <Box maxW="30rem" mx="auto" position="relative" w="full" {...boxProps}>
        <Center>
          {hiiveConnect ? (
            <Logo.ConnectGrey
              mb={{ base: 9, md: 14 }}
              width={40}
              height="auto"
            />
          ) : (
            <Logo.Grey mb={{ base: 9, md: 14 }} width={40} />
          )}
        </Center>
        <Text textAlign="center" textStyle="heading-2xl" color="grey.900">
          {title}
        </Text>
        {children}
      </Box>
      <AuthFooter />
    </VStack>
  );
};
