import isNil from "lodash/isNil";

import { Maybe } from "@/types";
import { constants } from "@/utils";

export const getAreFeesHigh = ({
  pricePerShare,
  numShares,
}: {
  readonly pricePerShare?: number | null;
  readonly numShares?: number | null;
}) =>
  !isNil(pricePerShare) && !isNil(numShares)
    ? pricePerShare * numShares < constants.high_fees_warning_threshold
    : false;

export const getAreFeesHighForListing = ({
  highestBid,
  pricePerShare,
  numberOfShares,
}: {
  readonly highestBid?: Maybe<number>;
  readonly pricePerShare?: Maybe<number>;
  readonly numberOfShares: number;
}) => {
  const highestCompanyBidPrice = highestBid
    ? Math.round(highestBid / 100)
    : null;
  const sharePrice = pricePerShare || highestCompanyBidPrice;

  const isWithinWarningThreshold = sharePrice
    ? getAreFeesHigh({ pricePerShare: sharePrice, numShares: numberOfShares })
    : false;

  return isWithinWarningThreshold;
};
