import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  VStack,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  Skeleton,
  TextAreaInputSkeleton,
} from "@/components/common";

const MessageCounterpartyCardSkeleton = () => (
  <Card variant="darkened-footer">
    <CardHeader py={4}>
      <Skeleton h="26px" w="112px" />
    </CardHeader>
    <CardBody>
      <VStack gap={3} alignItems="flex-start">
        <Skeleton h="14px" />
        <Skeleton h="14px" maxW="75%" />
        <TextAreaInputSkeleton />
      </VStack>
    </CardBody>
    <CardFooter>
      <ButtonFooterWrapper>
        <Skeleton h="48px" borderRadius="full" maxW="170px" />
      </ButtonFooterWrapper>
    </CardFooter>
  </Card>
);

export default MessageCounterpartyCardSkeleton;
