import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { TileHeader, Skeleton } from "@/components/common";
import { formatCurrency } from "@/utils";

const ListingTotalTransactionValue = ({
  totalValue,
  isLoading,
}: {
  readonly totalValue: string | number;
  readonly isLoading: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TileHeader alignSelf="center">{t(`total_transaction_value`)}</TileHeader>
      {isLoading ? (
        <Skeleton h={6} w={16} />
      ) : (
        <Text>
          {formatCurrency(totalValue, {
            precision: 2,
            symbol: `$`,
            fromCents: true,
          })}
        </Text>
      )}
    </>
  );
};

export default ListingTotalTransactionValue;
