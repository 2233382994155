import { FrigadeAnnouncement } from "@frigade/react";

import Head from "next/head";

import { FlowKind } from "@/components/providers";
import { useFlowLauncher } from "@/hooks";

const UnaccreditedSellerWelcomeFlow = ({
  flowKind,
}: {
  readonly flowKind: FlowKind;
}) => {
  const { getFlowID } = useFlowLauncher();
  const flowId = getFlowID(flowKind);

  return (
    <>
      <Head>
        <link rel="preload" as="image" href="/guide/IM_U16R_1.png" />
        <link rel="preload" as="image" href="/guide/IM_U16R_2.png" />
        <link rel="preload" as="image" href="/guide/IM_U16R_3.png" />
      </Head>
      <FrigadeAnnouncement
        appearance={{
          styleOverrides: {
            announcementContainer: {
              gap: `2rem`,
            },
            announcementPaginationContainer: {
              margin: `0`,
            },
            announcementSubtitle: {
              marginBottom: `0`,
            },
            announcementMediaContainer: {
              order: `-1`,
              margin: `1rem`,
              maxHeight: `200px`,
            },
            announcementCTAContainer: {
              margin: `0`,
              flexWrap: `wrap-reverse`,
            },
          },
        }}
        flowId={flowId}
        allowBackNavigation
      />
    </>
  );
};

export default UnaccreditedSellerWelcomeFlow;
