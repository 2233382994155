import currency from "currency.js";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveAdvancedOptionsDropdown,
  HiiveSubmitButton,
  SharePrice,
  TileHeader,
} from "@/components/common";
import {
  CheckboxInput,
  EmailInput,
  FormField,
  FormNumberInput,
  QuestionTooltip,
  StepPropsV2,
  TextInput,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  bidTimeLimitSchema,
  representedEmailSchema,
  representedNameSchema,
} from "@/components/postings";
import { RepresentedUserTooltip } from "@/components/tooltip";
import {
  ListingPageBuyerActivityPlaceBidCardPlaceBidListingFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useStepValidator } from "@/hooks";
import {
  constants,
  getNumSharesAvailableRounded,
  getMinOrMaxPPSValidationErrorMessage,
} from "@/utils";

import { PlaceBidCardStepFormContext } from "./PlaceBidCardStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { PlaceBidCardFormValues } from "./types";

const MIN_TRANSACTION_ERROR = `MIN_TRANSACTION_ERROR`;

const useValidationSchema = ({
  listing,
  actor,
}: {
  readonly listing?: ListingPageBuyerActivityPlaceBidCardPlaceBidListingFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const minPostPps = (listing?.company.minPostPps || 0) / 100;
  const maxPostPps = (listing?.company.maxPostPps || 0) / 100;

  return Yup.object().shape({
    pricePerShare: Yup.number()
      .nullable()
      .when([], {
        is: () => !!listing?.company.minPostPps,
        then: Yup.number().min(
          minPostPps,
          getMinOrMaxPPSValidationErrorMessage(
            listing?.company.minPostPps,
            listing?.company.maxPostPps,
          ),
        ),
      })
      .when([], {
        is: () => !!listing?.company.maxPostPps,
        then: Yup.number().max(
          maxPostPps,
          getMinOrMaxPPSValidationErrorMessage(
            listing?.company.minPostPps,
            listing?.company.maxPostPps,
          ),
        ),
      })
      .test(
        `min-bid-size`,
        MIN_TRANSACTION_ERROR,
        function test(value: number) {
          const { numShares } = this.parent;

          return value * numShares >= constants.min_bid_lot.number;
        },
      )
      .required(`Price per share is required`),
    numShares: Yup.number()
      .nullable()
      .test(
        `min-bid-size`,
        MIN_TRANSACTION_ERROR,
        function test(value: number) {
          const { pricePerShare } = this.parent;

          return value * pricePerShare >= constants.min_bid_lot.number;
        },
      )
      .max(
        listing ? getNumSharesAvailableRounded(listing) : 0,
        `Can't request more shares than a listing has`,
      )
      .required(`Required`),
    timeLimit: bidTimeLimitSchema,
    representedEmail: representedEmailSchema(actor),
    representedFirstName: representedNameSchema(actor),
    representedLastName: representedNameSchema(actor),
    notifyRepresentingEmail: Yup.boolean().required(`Required`),
    confirmed: Yup.boolean().oneOf([true], `Required`),
    muteNotifyWatchers: Yup.boolean().nullable(),
  });
};

interface PlaceBidProps extends StepPropsV2<StepKeys, PlaceBidCardFormValues> {
  readonly actor: UserWithInstitutionFragment;
  readonly listing: ListingPageBuyerActivityPlaceBidCardPlaceBidListingFragment;
}

const PlaceBid = withCurrentActor(
  ({ actor, listing, stepRouter, values, ...formikProps }: PlaceBidProps) => {
    const { isSubmitting } = formikProps;
    const { stepControls } = stepRouter;
    const { t } = useTranslation();
    const minTransactionSizeErrorMessage = t(`min_transaction_size_error`, {
      minSize: constants.min_listing_size.text,
    });
    const [_numSharesField, { error: numSharesFieldError }] = useField(
      `numShares`,
    );
    const [_pricePerShareField, { error: pricePerShareFieldError }] = useField(
      `pricePerShare`,
    );

    const onSuccess = () => stepControls.nextStep();

    const validationSchema = useValidationSchema({
      listing,
      actor,
    });

    useStepValidator({
      Context: PlaceBidCardStepFormContext,
      stepKey: stepKeys.placeBidFormCard,
      validator: {
        validationSchema,
        onSuccess,
      },
      values,
    });

    const { numShares, pricePerShare } = values;

    // return type of pricePerShare is string | number
    const parsedPPS = pricePerShare ? parseFloat(pricePerShare.toString()) : 0;
    const value = currency(numShares * parsedPPS).format();

    // A little further down, you'll see some weird handling of error messages.
    // We need to show the min_transaction_size_error across both form fields
    // only for that error.
    const showMinTransactionError =
      (numSharesFieldError === MIN_TRANSACTION_ERROR ||
        pricePerShareFieldError === MIN_TRANSACTION_ERROR) &&
      formikProps.submitCount !== 0;

    return (
      <Card variant="darkened-footer" data-testid="place-bid-card">
        <CardHeader py={4}>
          <Text textStyle="heading-2xl">{t(`place_a_bid`)}</Text>
        </CardHeader>
        <CardBody>
          <Text mb={4}>{t(`place_bid_description`)}</Text>
          <SimpleGrid columns={2} columnGap={9} rowGap={6}>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormField.Control name="numShares">
                <FormField.NumberInput
                  label={t(`number_of_shares`)}
                  readOnly={!listing.acceptPartialBid}
                />
                {numSharesFieldError !== MIN_TRANSACTION_ERROR && (
                  <FormField.Error />
                )}
              </FormField.Control>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormField.Control name="pricePerShare">
                <FormField.MoneyInput label="Price per share" />
                {pricePerShareFieldError !== MIN_TRANSACTION_ERROR && (
                  <FormField.Error />
                )}
              </FormField.Control>
            </GridItem>
            {showMinTransactionError && (
              <GridItem colSpan={2}>
                <Text textStyle="text-sm" color="red.500">
                  {minTransactionSizeErrorMessage}
                </Text>
              </GridItem>
            )}
            <GridItem colSpan={2}>
              <FormNumberInput
                name="timeLimit"
                label={t(`bid_valid_for_days`)}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <SimpleGrid
                bg="teal.25"
                alignItems="baseline"
                borderColor="teal.500"
                borderWidth="0.5px"
                px="6"
                py="4"
                overflow="hidden"
                borderRadius="md"
                columns={2}
              >
                <GridItem colSpan={2}>
                  <TileHeader color="grey.400">
                    {t(`gross_value_of_your_bid`)}
                  </TileHeader>
                </GridItem>
                <GridItem>
                  <TileHeader>
                    <SharePrice
                      numShares={numShares}
                      pricePerShare={parsedPPS * 100}
                    />
                  </TileHeader>
                </GridItem>
                <GridItem justifySelf="end">{value}</GridItem>
              </SimpleGrid>
            </GridItem>
            {actor.isHiiveUser && (
              <GridItem colSpan={2}>
                <HiiveAdvancedOptionsDropdown
                  validationSchema={validationSchema}
                  fieldNames={[
                    `muteNotifyWatchers`,
                    `representedEmail`,
                    `representedFirstName`,
                    `representedLastName`,
                    `notifyRepresentingEmail`,
                  ]}
                >
                  <VStack spacing={4} alignItems="flex-start">
                    <CheckboxInput
                      name="muteNotifyWatchers"
                      label="Do not send a notification to watchers about this change."
                    />
                    <Text
                      align="center"
                      textStyle="deprecated-heading-lg"
                      color="h-dark-grey"
                    >
                      {t(`represented_user_info`)}
                      <QuestionTooltip
                        translateY={1.5}
                        translateX={1.0}
                        transform="auto"
                        tooltipContent={<RepresentedUserTooltip />}
                      />
                    </Text>
                    <HStack gap={4} w="full" alignItems="top">
                      <TextInput
                        data-testid="represented-user-first-name"
                        name="representedFirstName"
                        placeholder={t(`first_name`)}
                      />
                      <TextInput
                        data-testid="represented-user-last-name"
                        name="representedLastName"
                        placeholder={t(`last_name`)}
                      />
                    </HStack>
                    <EmailInput
                      data-testid="represented-email"
                      name="representedEmail"
                      placeholder="Email"
                      type="email"
                    />
                    <CheckboxInput
                      name="notifyRepresentingEmail"
                      label="Notify the person being represented"
                    />
                  </VStack>
                </HiiveAdvancedOptionsDropdown>
              </GridItem>
            )}
            <GridItem colSpan={2}>
              <CheckboxInput name="confirmed" label={t(`place_bid_intent`)} />
            </GridItem>
          </SimpleGrid>
        </CardBody>
        <CardFooter py={6}>
          <HStack justifyContent="flex-end" w="full">
            <HiiveSubmitButton
              sentryLabel="[PlaceBid/Submit]"
              isLoading={isSubmitting}
              submitText={t(`place_bid`)}
              type="submit"
            />
          </HStack>
        </CardFooter>
      </Card>
    );
  },
);

export default PlaceBid;
