import { useAuthUserOrNull } from "@frontegg/nextjs";
import { useEffect, useCallback } from "react";

import { useRouter } from "next/router";

import { useCheckAuthenticationToken, useTokenExchange } from "@/hooks";
import { constants } from "@/utils";

export default function useAuthRedirectionEffect() {
  const { replace, query } = useRouter();
  const sessionToken = useCheckAuthenticationToken();
  const fronteggUser = useAuthUserOrNull();

  const { exchangeToken, fetching: fetchingSessionToken } = useTokenExchange({
    onError: () => replace(constants.frontegg_routes.logout),
  });

  const isLoading = sessionToken || fetchingSessionToken;
  const shouldExchangeToken =
    fronteggUser && !sessionToken && !fetchingSessionToken;
  const { redirect } = query;

  const handleRedirection = useCallback(async () => {
    if (sessionToken) {
      await replace(redirect ? (redirect as string) : `/dashboard`);
    } else if (shouldExchangeToken) {
      await exchangeToken(fronteggUser.accessToken);
    }
  }, [
    exchangeToken,
    fronteggUser?.accessToken,
    replace,
    sessionToken,
    shouldExchangeToken,
    redirect,
  ]);

  useEffect(() => {
    handleRedirection();
  }, [handleRedirection]);

  return { isLoading };
}
