import { ReactNode } from "react";

import { Box, BoxProps, HStack } from "@chakra-ui/react";

interface FormFooterWrapperProps extends BoxProps {
  readonly children: ReactNode;
}

const FormFooterWrapper = ({
  children,
  ...boxProps
}: FormFooterWrapperProps) => (
  <Box my={9} {...boxProps}>
    <HStack
      justifyContent="flex-end"
      width="full"
      spacing={{ base: `auto`, md: 4 }}
    >
      {children}
    </HStack>
  </Box>
);

export default FormFooterWrapper;
