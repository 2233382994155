import { InquiryError } from "persona/dist/lib/interfaces";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { UserWithInstitutionFragment } from "@/gql";
import { setComplete } from "@/state/persona";
import { ToastDuration } from "@/types/toast";

import useCustomToast from "./useCustomToast";

type Fields = {
  readonly [fieldKey: string]: string | number | boolean | null | undefined;
};

type FlowStatus = "loading" | "loaded" | "closed" | "ready";

type PersonaCompleteResponse = {
  readonly inquiryId: string;
  readonly status: string;
  readonly fields: Fields;
};

type PersonaCancelResponse = {
  readonly inquiryId?: string;
  readonly sessionToken?: string;
};

interface UseIdentityVerificationModalProps {
  readonly actor: UserWithInstitutionFragment;
  readonly onComplete?: (response: PersonaCompleteResponse) => void;
  readonly onCancel?: (response: PersonaCancelResponse) => void;
}

const useIdentityVerificationModal = ({
  onComplete,
  onCancel,
  actor: {
    id: referenceId,
    firstName: nameFirst,
    lastName: nameLast,
    phoneNumber,
    email: emailAddress,
  },
}: UseIdentityVerificationModalProps) => {
  const { errorToast } = useCustomToast();
  const dispatch = useDispatch();

  const [flowStatus, setFlowStatus] = useState<FlowStatus>(`closed`);

  const onError = (err: InquiryError) => {
    errorToast(`${err.status} ${err.code}`, {
      duration: ToastDuration.VeryLong,
    });
  };

  const createClient = async () => {
    setFlowStatus(`loading`);

    const environment = process.env.NEXT_PUBLIC_PERSONA_ENV || `sandbox`;
    const templateId = process.env.NEXT_PUBLIC_PERSONA_TEMPLATE_ID;

    const { Client: PersonaClient } = await import(`persona`);

    const client = new PersonaClient({
      templateId,
      environment,
      referenceId,
      fields: {
        nameFirst,
        nameLast,
        phoneNumber,
        emailAddress,
      },
      onLoad: () => {
        setFlowStatus(`loaded`);
        client.open();
      },
      onReady: () => setFlowStatus(`ready`),
      onComplete: (response: PersonaCompleteResponse) => {
        dispatch(setComplete(true));
        if (onComplete) {
          onComplete(response);
        }
      },
      onCancel: (response: PersonaCancelResponse) => {
        setFlowStatus(`closed`);
        if (onCancel) {
          onCancel(response);
        }
      },
      onError,
    });
  };

  const isOpen = flowStatus !== `closed`;
  const onOpen = () => createClient();

  return { isOpen, onOpen };
};

export default useIdentityVerificationModal;
