import { useRef } from "react";

import {
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialog as ChakraAlertDialog,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";

interface AlertDialogProps {
  readonly title: string;
  readonly body: string | JSX.Element;
  readonly cancelText?: string;
  readonly confirmText?: string;
  readonly onConfirm: () => void;
  readonly onClose: () => void;
  readonly isOpen: boolean;
  readonly isLoading?: boolean;
}

const AlertDialog = ({
  onClose,
  isOpen,
  title,
  body,
  cancelText = `Cancel`,
  confirmText = `Confirm`,
  onConfirm,
  isLoading,
}: AlertDialogProps) => {
  const cancelRef = useRef(null);

  return (
    <ChakraAlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{body}</AlertDialogBody>
        <AlertDialogFooter>
          <HiiveButton
            ref={cancelRef}
            onClick={onClose}
            variant="rounded-outline-grey"
            size="md"
            sentryLabel="[AlertDialog] Cancel"
          >
            {cancelText}
          </HiiveButton>
          <HiiveButton
            variant="rounded-solid-salmon"
            size="md"
            ml={3}
            onClick={onConfirm}
            sentryLabel={`[AlertDialog/Confirm] ${confirmText}`}
            isLoading={isLoading}
          >
            {confirmText}
          </HiiveButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </ChakraAlertDialog>
  );
};

export default AlertDialog;
