import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Text } from "@chakra-ui/react";

import { ActivityGroupEmptyState, HiiveButton } from "@/components/common";
import { CompanyMarketActivitySellersBadge } from "@/components/companies";
import { UnaccreditedSellerCompanyPageMarketActivityCompanyFragment } from "@/gql";
import { useModal } from "@/hooks";
import { getAvailableCompanyActions } from "@/utils";

export const ListingsEmptyState = ({
  company,
  numberOfStandingBids,
}: {
  readonly company: UnaccreditedSellerCompanyPageMarketActivityCompanyFragment;
  readonly numberOfStandingBids: number;
}) => {
  const { t } = useTranslation();
  const { canCreateListing } = getAvailableCompanyActions(company);
  const { onOpenModal, modals } = useModal();

  const numberOfSellers = company.numHolders + company.numWatchers;
  const showPlaceListingCTA =
    canCreateListing && numberOfSellers > 0 && numberOfStandingBids === 0;

  return showPlaceListingCTA ? (
    <Card w="full">
      <CardBody>
        <Flex direction="column" gap={5}>
          <Flex direction="column" gap={4}>
            <CompanyMarketActivitySellersBadge
              variant="rounded-olive"
              company={company}
            />
            <Text>
              {t(`potential_sellers_but_no_listings`, {
                count: numberOfSellers,
                companyName: company.name,
              })}
              {` `}
              <Trans
                i18nKey="u16r_place_listing_to_notify_buyers"
                components={{
                  bold: <Text as="strong" textStyle="heading-md" />,
                }}
              />
            </Text>
          </Flex>
          <HiiveButton
            flex="none"
            p={0}
            maxWidth="fit-content"
            variant="text-salmon"
            onClick={() =>
              onOpenModal(modals.createListingOnCompany(company))()
            }
            sentryLabel="[ListingsEmptyState] Place a Listing CTA"
          >
            {t(`place_a_listing`)}
          </HiiveButton>
        </Flex>
      </CardBody>
    </Card>
  ) : (
    <ActivityGroupEmptyState
      message={t(`no_listings`, { companyName: company.name })}
    />
  );
};
