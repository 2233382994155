import { useTranslation } from "react-i18next";

import { Text, HStack } from "@chakra-ui/react";

import {
  ShareDetailsStatsWrapper,
  ShareDetailsPricePerShareStat,
  ShareDetailsStat,
  ShareDetailsStats,
} from "@/components/common";
import { NumShareAndPriceDetailsListingFragment } from "@/gql";
import {
  formatShares,
  lot,
  getIsPartiallySoldListing,
  getListingNumSharesOriginal,
} from "@/utils";

const ListingNumShareAndPriceDetailsPreview = ({
  numShares,
  pricePerShare,
  listing,
}: {
  readonly numShares: number;
  readonly pricePerShare: number | null;
  readonly listing: NumShareAndPriceDetailsListingFragment;
}) => {
  const { t } = useTranslation();
  const numSharesOriginal = getListingNumSharesOriginal(listing);
  const isPartiallySoldListing = getIsPartiallySoldListing(listing);

  if (isPartiallySoldListing) {
    return (
      <ShareDetailsStatsWrapper>
        <ShareDetailsStat title={t(`shares`)}>
          <HStack>
            <Text as="del" color="grey.200">
              {formatShares(numSharesOriginal)}
            </Text>
            <Text>{numShares ? formatShares(numShares) : `-`}</Text>
          </HStack>
        </ShareDetailsStat>
        <ShareDetailsPricePerShareStat pricePerShare={pricePerShare} />
        <ShareDetailsStat title={t(`total`)}>
          {pricePerShare && numShares ? lot(numShares, pricePerShare) : `-`}
        </ShareDetailsStat>
      </ShareDetailsStatsWrapper>
    );
  }

  return (
    <ShareDetailsStats
      numberOfShares={numShares}
      pricePerShare={pricePerShare}
    />
  );
};

export default ListingNumShareAndPriceDetailsPreview;
