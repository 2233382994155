import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { useRouter } from "next/router";

import Meta from "@/components/Meta";
import { withCurrentActor } from "@/components/hoc";
import {
  InvestorStatus,
  InvestorType,
  UserWithInstitutionFragment,
} from "@/gql";
import { useAccreditationPageStatus, useSuitabilityPageStatus } from "@/hooks";
import {
  useMultipleHoldings,
  useMarketplaceAuthSystem,
  useTrustedContactPerson,
  useSuitabilityV3,
} from "@/hooks/featureFlags";
import {
  getAvailableInstitutionActions,
  hasInvestorType,
  getIsInstitutionUser,
  getIsUnaccreditedSeller,
} from "@/utils";

import { AccreditationCard } from "./AccreditationCard";
import { ChangeComplianceEmailCard } from "./ChangeComplianceEmailCard";
import { ChangeEmailCard } from "./ChangeEmailCard";
import { ChangePasswordCard } from "./ChangePasswordCard";
import { ChangePhoneCard } from "./ChangePhoneCard";
import { CompletedTransfersCard } from "./CompletedTransfersCard";
import { ContactCard } from "./ContactCard";
import { CustomerAgreementCard } from "./CustomerAgreementCard";
import { HoldingsCard } from "./HoldingsCard";
import { IdentityVerificationCard } from "./IdentityVerificationCard";
import { IndividualTraderCustomerAgreementCard } from "./IndividualTraderCustomerAgreementCard";
import { ManageInstitutionUsersCard } from "./ManageInstitutionUsersCard";
import { NotificationsCard } from "./NotificationsCard";
import { SecurityCard } from "./SecurityCard";
import { SuitabilityCard } from "./SuitabilityCard";
import { SuitabilityCard as SuitabilityCardV2 } from "./SuitabilityCardV2";
import {
  TransactingEntitiesIndividualCard,
  TransactingEntitiesInstitutionCard,
} from "./TransactingEntitiesCard";
import { TrustedContactPersonCard } from "./TrustedContactPerson";
import { UnaccreditedSellerCustomerAgreementCard } from "./UnaccreditedSellerCustomerAgreementCard";
import { UnaccreditedSellerNotificationsCard } from "./UnaccreditedSellerNotificationsCard";

const AccountPageSetting = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const router = useRouter();

    const isInstitutionUser = getIsInstitutionUser(actor);
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    const multipleHoldingsEnabled = useMultipleHoldings();
    const marketplaceAuthSystemEnabled = useMarketplaceAuthSystem();

    const isIndividualTrader =
      actor.investorStatus === InvestorStatus.Individual &&
      hasInvestorType(actor, InvestorType.Trader);

    const { t } = useTranslation();

    const accreditationPageStatus = useAccreditationPageStatus(actor);
    const suitabilityPageStatus = useSuitabilityPageStatus(actor);
    const trustedContactPersonEnabled = useTrustedContactPerson();
    const isSuitabilityV3Enabled = useSuitabilityV3();

    const canSeeAccreditationPage = accreditationPageStatus !== `hidden`;
    const canSeeSuitabilityPage = suitabilityPageStatus !== `hidden`;

    const {
      canUpdateComplianceEmail,
      canManageInstitutionUsers,
    } = getAvailableInstitutionActions(actor.institution);

    const getAccountPageView = () =>
      match({
        asPath: router.asPath,
        isInstitutionUser,
        isUnaccreditedSeller,
        canUpdateComplianceEmail,
        canManageInstitutionUsers,
        canSeeAccreditationPage,
        canSeeSuitabilityPage,
        multipleHoldingsEnabled,
        trustedContactPersonEnabled,
        isSuitabilityV3Enabled,
      })
        .with({ asPath: `/account` }, () => ({
          view: marketplaceAuthSystemEnabled ? (
            <ContactCard />
          ) : (
            <ChangeEmailCard />
          ),
          title: marketplaceAuthSystemEnabled
            ? t(`contact_info`)
            : t(`change_email`),
        }))
        .with(
          {
            asPath: `/account/profile/accreditation`,
            canSeeAccreditationPage: true,
          },
          () => ({
            view: <AccreditationCard />,
            title: t(`accreditation`),
          }),
        )
        .with(
          {
            asPath: `/account/profile/suitability`,
            canSeeSuitabilityPage: true,
            isSuitabilityV3Enabled: true,
          },
          () => ({
            view: <SuitabilityCardV2 />,
            title: t(`suitability`),
          }),
        )
        .with(
          {
            asPath: `/account/profile/suitability`,
            canSeeSuitabilityPage: true,
          },
          () => ({
            view: <SuitabilityCard />,
            title: t(`suitability`),
          }),
        )
        .with(
          {
            asPath: `/account/profile/identity-verification`,
            isInstitutionUser: false,
          },
          () => ({
            view: <IdentityVerificationCard />,
            title: t(`verify_identity`),
          }),
        )
        .with(
          {
            asPath: `/account/profile/customer-agreement`,
          },
          () => {
            if (isUnaccreditedSeller) {
              return {
                view: <UnaccreditedSellerCustomerAgreementCard />,
                title: `Customer Agreement`,
              };
            }
            if (isIndividualTrader) {
              return {
                view: <IndividualTraderCustomerAgreementCard />,
                title: `Customer Agreement`,
              };
            }
            return {
              view: <CustomerAgreementCard />,
              title: `Customer Agreement`,
            };
          },
        )
        .with(
          {
            asPath: `/account/profile/my-holdings`,
            isUnaccreditedSeller: true,
            multipleHoldingsEnabled: true,
          },
          () => ({
            view: <HoldingsCard />,
            title: t(`my_holdings`),
          }),
        )
        .with(
          {
            asPath: `/account/profile/suitability`,
            canSeeSuitabilityPage: true,
          },
          () => ({
            view: <SuitabilityCard />,
            title: t(`suitability`),
          }),
        )
        .with(
          {
            asPath: `/account/profile/transacting-entities`,
          },
          () => ({
            view:
              actor.investorStatus === InvestorStatus.Individual ? (
                <TransactingEntitiesIndividualCard />
              ) : (
                <TransactingEntitiesInstitutionCard />
              ),
            title: t(`transacting_entities.transacting_entities`, {
              ns: `account`,
            }),
          }),
        )
        .with(
          {
            asPath: `/account/profile/trusted-contact-person`,
            trustedContactPersonEnabled: true,
            isInstitutionUser: false,
          },
          () => ({
            view: <TrustedContactPersonCard />,
            title: t(`trusted_contact_person`),
          }),
        )
        .with({ asPath: `/account/settings/contact` }, () => ({
          view: <ContactCard />,
          title: t(`contact_info`),
        }))
        .with({ asPath: `/account/settings/change-email` }, () => ({
          view: <ChangeEmailCard />,
          title: `Change Email`,
        }))
        .with({ asPath: `/account/settings/change-phone` }, () => ({
          view: <ChangePhoneCard />,
          title: `Change Phone`,
        }))
        .with({ asPath: `/account/settings/security` }, () => ({
          view: <SecurityCard />,
          title: t(`security`),
        }))
        .with({ asPath: `/account/settings/change-password` }, () => ({
          view: <ChangePasswordCard />,
          title: `Change Password`,
        }))
        .with(
          {
            asPath: `/account/settings/change-compliance-email`,
            canUpdateComplianceEmail: true,
          },
          () => ({
            view: <ChangeComplianceEmailCard />,
            title: `Change Compliance Email Address`,
          }),
        )
        .with({ asPath: `/account/preferences/notifications` }, () => ({
          view: isUnaccreditedSeller ? (
            <UnaccreditedSellerNotificationsCard />
          ) : (
            <NotificationsCard />
          ),
          title: `Notifications`,
        }))
        .with({ asPath: `/account/transfers/completed-transfers` }, () => ({
          view: <CompletedTransfersCard />,
          title: `Completed Transfers`,
        }))
        .with(
          {
            asPath: `/account/manage-users/account-users`,
            canManageInstitutionUsers: true,
          },
          () => ({
            view: <ManageInstitutionUsersCard />,
            title: `Account Users`,
          }),
        )
        .otherwise(() => null);

    const pageView = getAccountPageView();

    if (!pageView) {
      router.push(`/page-not-found`);
      return null;
    }

    return (
      <>
        <Meta title={`Account - ${pageView.title}`} shouldTrackPageEvent />
        {pageView.view}
      </>
    );
  },
);

export default AccountPageSetting;
