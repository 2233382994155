import { CardHeader, Flex } from "@chakra-ui/react";

import {
  ActivityCardBadge,
  ActivityCardCompany,
  ActivityCardDisplayId,
} from "@/components/common";
import { UserActivityTransactionFragment } from "@/gql";

const DashboardTransactionCardHeader = ({
  transaction,
}: {
  readonly transaction: UserActivityTransactionFragment;
}) => {
  const title = transaction.bid.standingBid
    ? `Transaction on Standing Bid`
    : `Transaction on Bid`;

  const displayId =
    transaction.bid.standingBid?.displayId || transaction.bid.displayId;

  return (
    <CardHeader>
      <Flex alignItems="center" gap={2}>
        <ActivityCardBadge variant="transaction" title={title} />
        <ActivityCardDisplayId displayId={displayId} />
        <ActivityCardCompany ml="auto" company={transaction.bid.company} />
      </Flex>
    </CardHeader>
  );
};

export default DashboardTransactionCardHeader;
