import { motion } from "framer-motion";
import { ReactNode } from "react";

import { Box, EASINGS, useBreakpointValue } from "@chakra-ui/react";

const AnimatedModalStep = ({ children }: { readonly children: ReactNode }) => {
  const animateScale = useBreakpointValue(
    { base: 1, md: 0.95 },
    { ssr: false },
  );
  const duration = 0.2;
  return (
    <Box
      w="full"
      as={motion.div}
      exit={{
        scale: animateScale,
        opacity: 0,
        transition: {
          scale: { ease: EASINGS.easeIn, duration },
          duration,
        },
      }}
      initial={{ scale: animateScale, opacity: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
        transition: {
          scale: { ease: EASINGS.easeOut, duration },
          duration,
        },
      }}
    >
      {children}
    </Box>
  );
};

export default AnimatedModalStep;
