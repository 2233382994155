import * as Yup from "yup";

import { EntityType, UserWithInstitutionInstitutionFragment } from "@/gql";

const validationSchema = (
  institution?: UserWithInstitutionInstitutionFragment | null,
) =>
  Yup.object().shape({
    entityType:
      !!institution && institution.country.name === `UK`
        ? Yup.string().nullable()
        : Yup.string()
            .nullable()
            .oneOf(Object.values(EntityType), `Entity type is required`),
    jurisdictionOfIncorporationId: Yup.string().required(
      `Jurisdiction is required`,
    ),
    streetAddress: Yup.string()
      .nullable()
      .required(`Street address is required`)
      .matches(/[a-zA-Z]/, { message: `Street address is invalid` }),
    city: Yup.string()
      .required(`Required`)
      .matches(/[a-zA-Z]/, { message: `City is invalid` }),
    region:
      !!institution &&
      (institution.country.name === `CH` || institution.country.name === `UK`)
        ? Yup.string().nullable()
        : Yup.string()
            .required(`Required`)
            .matches(/[a-zA-Z]/, { message: `Invalid` }),
    postalCode: Yup.string()
      .required(`Required`)
      .matches(/[0-9]/, { message: `Invalid` }),
  });

export default validationSchema;
