import { useRouter } from "next/router";

import { Link, ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import {
  AcceptStandingBidModalStandingBidFragment,
  AcceptStandingBidMutation,
} from "@/gql";
import {
  formatPricePerShare,
  formatShares,
  getFullDocumentTitleByTransferMethod,
  getTransferMethodByStandingBidAcceptsTransferMethodsOptions,
  makeUrl,
  transferMethodToLongString,
} from "@/utils";

import { useAcceptStandingBidSequenceModalContext } from "./AcceptStandingBidSequenceModalContext";
import { StepKeys } from "./steps";
import { AcceptStandingBidSequenceModalFormValues } from "./types";

interface AcceptStandingBidConfirmationModalProps
  extends StepPropsV2<StepKeys, AcceptStandingBidSequenceModalFormValues> {
  readonly standingBid: AcceptStandingBidModalStandingBidFragment;
}

const AcceptStandingBidConfirmationModal = ({
  stepRouter,
  values,
  standingBid,
  isSubmitting,
}: AcceptStandingBidConfirmationModalProps) => {
  const router = useRouter();
  const { submitMutation } = useAcceptStandingBidSequenceModalContext();

  const { stepControls } = stepRouter;

  const onClickSubmit = () =>
    submitMutation().then((response: AcceptStandingBidMutation) => {
      stepControls.nextStep();

      const transaction = response.acceptStandingBid?.bid?.transaction;

      if (!transaction) return;

      router.push(makeUrl(transaction));
    });

  const transferMethod = getTransferMethodByStandingBidAcceptsTransferMethodsOptions(
    values.transferMethod,
  );
  const formattedTransferMethod = transferMethodToLongString(transferMethod);
  const documentTitle = getFullDocumentTitleByTransferMethod(transferMethod);

  return (
    <div data-testid="standing-bid-accept-confirmation-modal">
      <HiiveModalHeader>Confirm</HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start">
          <Text textStyle="heading-2xl">
            Are you sure you want to accept this standing bid?
          </Text>
          <Text>
            By accepting this bid, you indicate to the buyer that you intend to
            sell at this price and quantity.
          </Text>
          <Text>
            After you accept the bid, you and the buyer will execute an {` `}
            {documentTitle}
            {` `}
            to document your agreement to sell.
          </Text>
          <Text>
            The sale may be conditional on issuer approval and other processes.
            If this sale closes, at closing, you will owe Hiive a fee as set out
            in the{` `}
            <Link
              href="/terms-and-conditions"
              textDecor="underline"
              target="_blank"
            >
              Terms & Conditions
            </Link>
            .
          </Text>
          <Text>
            {formatShares(values.numShares)}
            {` `}
            shares of {standingBid.company.name} @{` `}
            {formatPricePerShare(values.pricePerShare, false)}/sh{` `}
            {formattedTransferMethod}
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[AcceptStandingBid/Cancel]"
            onCancel={stepControls.previousStep}
          />
        </Show>
        <HiiveSubmitButton
          sentryLabel="[AcceptStandingBid/Submit]"
          isLoading={isSubmitting}
          submitText="Confirm"
          type="button"
          onClick={onClickSubmit}
        />
      </HiiveModalFooter>
    </div>
  );
};

export default AcceptStandingBidConfirmationModal;
