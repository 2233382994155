/* eslint-disable no-param-reassign */

/* eslint-disable functional/immutable-data */

/* eslint-disable functional/no-let */
// TODO: Add logic if another toast is being shown to push previous one up
import {
  checkCircleIcon,
  infoIcon,
  warningCircleIcon,
  somethingWentWrongIcon,
  xIcon,
} from "./icons";
import styles from "./style.module.css";

export enum NotificationDuration {
  SHORT = 3000,
  MEDIUM = 5000,
  LONG = 7000,
  NEVER = 0,
}

type ToastrType = "success" | "warning" | "info" | "error";

const DEFAULT_ERROR_TITLE = `Oops!`;
const DEFAULT_ERROR_MSG = `Something went wrong. Please refresh and try again.`;

export default function toastr() {
  const getWrapperEl = () => {
    let wrapperEl = document.querySelector(styles.toastrWrapper);
    if (wrapperEl) return wrapperEl;

    wrapperEl = document.createElement(`div`);
    wrapperEl.setAttribute(`id`, `toastrWrapper`);
    wrapperEl.classList.add(styles.toastrWrapper);
    const body = document.querySelector(`body`);
    body?.appendChild(wrapperEl);

    return wrapperEl;
  };

  const createToastrEl = () => {
    const toastrEl = document.createElement(`div`);
    toastrEl.classList.add(styles.toastrElement);
    return toastrEl;
  };

  const createButtonEl = () => {
    const buttonEl = document.createElement(`button`);
    buttonEl.classList.add(styles.button);
    buttonEl.innerHTML = `Try Again`;
    return buttonEl;
  };

  const createCloseIconEl = () => {
    const closeIconEl = document.createElement(`div`);
    closeIconEl.classList.add(styles.closeIcon);
    closeIconEl.innerHTML = xIcon;
    return closeIconEl;
  };

  const createToastrIconEl = (type: ToastrType) => {
    const iconEl = document.createElement(`div`);

    switch (type) {
      case `success`:
        iconEl.innerHTML = checkCircleIcon;
        break;
      case `warning`:
        iconEl.innerHTML = warningCircleIcon;
        break;
      case `error`:
        iconEl.innerHTML = somethingWentWrongIcon;
        break;
      case `info`:
        iconEl.innerHTML = infoIcon;
        break;
      default:
        throw new Error(`Unhandled toastr type`);
    }

    return iconEl;
  };

  const createToastrTitleWrapperEl = (title: string) => {
    const toastrTitleWrapperEl = document.createElement(`div`);

    toastrTitleWrapperEl.classList.add(styles.toastrTitleWrapper);
    toastrTitleWrapperEl.innerHTML = title;
    return toastrTitleWrapperEl;
  };

  const createToastrMsgWrapperEl = (msg: string) => {
    const toastrMsgWrapperEl = document.createElement(`div`);

    toastrMsgWrapperEl.classList.add(styles.toastrMsgWrapper);
    toastrMsgWrapperEl.innerHTML = msg;
    return toastrMsgWrapperEl;
  };

  const show = (
    type: ToastrType,
    title: string,
    msg: string,
    duration: NotificationDuration,
  ) => {
    const el = createToastrEl();
    el.classList.add(styles[type]);

    const titleWrapperEl = createToastrTitleWrapperEl(title);
    const msgWrapperEl = createToastrMsgWrapperEl(msg);
    const wrapperEl = getWrapperEl();
    const toastrIcon = createToastrIconEl(type);
    const buttonEl = createButtonEl();
    const closeIconEl = createCloseIconEl();

    buttonEl.onclick = () => wrapperEl.removeChild(el);
    closeIconEl.onclick = () => wrapperEl.removeChild(el);

    el.appendChild(toastrIcon);
    el.appendChild(titleWrapperEl);
    el.appendChild(msgWrapperEl);
    el.appendChild(buttonEl);
    el.appendChild(closeIconEl);

    wrapperEl.appendChild(el);
    wrapperEl.setAttribute(
      `style`,
      `left: calc(50vw - ${el.getBoundingClientRect().width / 2}px)`,
    );

    setTimeout(() => {
      if (duration === NotificationDuration.NEVER) return;
      wrapperEl.removeChild(el);
    }, duration);
  };

  return {
    success(title: string, msg: string, duration: NotificationDuration = 3000) {
      show(`success`, title, msg, duration);
    },
    info(title: string, msg: string, duration: NotificationDuration = 3000) {
      show(`info`, title, msg, duration);
    },
    warning(title: string, msg: string, duration: NotificationDuration = 3000) {
      show(`warning`, title, msg, duration);
    },
    error(
      title: string = DEFAULT_ERROR_TITLE,
      msg: string = DEFAULT_ERROR_MSG,
      duration: NotificationDuration = 5000,
    ) {
      show(`error`, title, msg, duration);
    },
    clear() {
      document.querySelectorAll(`#toastrWrapper`).forEach((el) => el.remove());
    },
  };
}
