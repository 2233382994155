/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable functional/no-let */
import { useEffect, createRef } from "react";

import { Box } from "@chakra-ui/react";

interface LazyLoadObserverProps {
  readonly root: Element;
  readonly rootMargin?: string;
  readonly threshold?: number;
  readonly triggerOnce?: boolean;
  readonly onIntersection: () => void;
}

const LazyLoadObserver = ({
  root,
  rootMargin,
  threshold,
  triggerOnce,
  onIntersection,
}: LazyLoadObserverProps) => {
  let io: IntersectionObserver;
  const el = createRef<HTMLDivElement>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    addIntersectionObserver();
  }, []);

  const getOptions = () => ({
    root,
    threshold,
    rootMargin,
    triggerOnce,
  });
  const addIntersectionObserver = () => {
    io = new IntersectionObserver(
      (data: readonly IntersectionObserverEntry[]) => {
        if (data[0].isIntersecting) {
          onIntersection();
          if (triggerOnce) {
            io.disconnect();
          }
        }
      },
      getOptions(),
    );
    io.observe(el.current!);
  };

  return <Box h={0} ref={el} />;
};

export default LazyLoadObserver;
