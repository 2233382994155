import { ReactNode } from "react";

import { Box, FormLabel, FormLabelProps } from "@chakra-ui/react";

import QuestionTooltip from "./QuestionTooltip";

interface TooltipFormLabelProps extends FormLabelProps {
  readonly children: ReactNode;
  readonly htmlFor?: string | undefined;
  readonly tooltipContent?: ReactNode;
  readonly tooltipSize?: string;
  readonly href?: string;
}

const TooltipFormLabel = ({
  children,
  htmlFor,
  tooltipContent,
  tooltipSize,
  href,
  ...formLabelProps
}: TooltipFormLabelProps) => (
  <FormLabel pb="6px" m={0} htmlFor={htmlFor} {...formLabelProps}>
    {children}
    {tooltipContent && (
      <Box as="span" whiteSpace="nowrap">
        &nbsp;
        <QuestionTooltip
          translateY={1.5}
          transform="auto"
          tooltipContent={tooltipContent}
          tooltipSize={tooltipSize}
          href={href}
        />
      </Box>
    )}
  </FormLabel>
);

export default TooltipFormLabel;
