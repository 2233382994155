import { defineStyleConfig, Text as TextComponent } from "@chakra-ui/react";

export const Text = defineStyleConfig({
  defaultProps: {
    ...TextComponent.defaultProps,
  },
  variants: {
    tooltip: {
      textDecoration: `underline`,
      textDecorationStyle: `dashed`,
      textDecorationThickness: `0.5px`,
      textUnderlineOffset: 4,
    },
  },
});
