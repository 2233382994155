import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  AutomatedSTNSellerSteps,
  AutomatedSTNStatesMap,
  AutomatedSTNTransactionState,
} from "@/components/postings";
import { AutomatedStnSellerStatesBidFragment, TransactionState } from "@/gql";
import { useDocumentSigners } from "@/hooks";

import BidAcceptedStatusItem from "./BidAcceptedStatusItem";
import { TransferStatusItem } from "./TransferStatusItem";

const AutomatedSTNSellerStates = ({
  state,
  bid,
  isBroker,
}: {
  readonly state: AutomatedSTNTransactionState;
  readonly bid: AutomatedStnSellerStatesBidFragment;
  readonly isBroker?: boolean;
}) => {
  const { t } = useTranslation();

  const states: AutomatedSTNStatesMap<AutomatedSTNSellerSteps> = {
    [TransactionState.BidAccepted]: {
      approvalByIssuer: null,
      issuerApproved: null,
      feePayment: null,
      closed: null,
    },
    [TransactionState.Expired]: {
      approvalByIssuer: null,
      issuerApproved: null,
      feePayment: null,
      closed: null,
    },
    [TransactionState.IssuerPendingApproval]: {
      approvalByIssuer: `PENDING`,
      issuerApproved: null,
      feePayment: null,
      closed: null,
    },
    [TransactionState.IssuerApproved]: {
      approvalByIssuer: `SUCCESS`,
      issuerApproved: `PENDING`,
      feePayment: null,
      closed: null,
    },
    [TransactionState.IssuerApprovalDeclined]: {
      approvalByIssuer: null,
      issuerApproved: null,
      feePayment: null,
      closed: null,
    },
    [TransactionState.ClosedFeePending]: {
      approvalByIssuer: `SUCCESS`,
      issuerApproved: `SUCCESS`,
      feePayment: `PENDING`,
      closed: null,
    },
    [TransactionState.ClosedFeePaid]: {
      approvalByIssuer: `SUCCESS`,
      issuerApproved: `SUCCESS`,
      feePayment: `SUCCESS`,
      closed: `SUCCESS`,
    },
  };

  const bidState = useDocumentSigners(bid.transaction);

  const [
    stnSignedSubtitle,
    issuerApprovalCompletedSubtitle,
    awaitingPaymentSubtitle,
  ] = useMemo(
    () => [
      bidState.haveBothSigned && states[state].approvalByIssuer === `PENDING`
        ? `Awaiting ${bid.listing.company.name}'s approval`
        : undefined,
      states[state].approvalByIssuer === `SUCCESS` &&
      states[state].issuerApproved === `PENDING`
        ? `Awaiting closing`
        : undefined,
      states[state].issuerApproved === `SUCCESS` &&
      states[state].feePayment === `PENDING`
        ? t(`awaiting_closing_fee`)
        : undefined,
    ],
    [states, state, bidState],
  );

  return (
    <>
      {!!bid.transaction && (
        <BidAcceptedStatusItem transaction={bid.transaction} />
      )}
      <TransferStatusItem
        isCompleted={bidState.haveBothSigned}
        title="Share Transfer Notice signed"
        subtitle={stnSignedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].approvalByIssuer === `SUCCESS`}
        title={`Approved by ${bid.listing.company.name}`}
        subtitle={issuerApprovalCompletedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].issuerApproved === `SUCCESS`}
        title="Transaction completed"
        subtitle={awaitingPaymentSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].closed === `SUCCESS`}
        title={t(isBroker ? `hiive_co_broker_fee_payed` : `fee_payed`)}
      />
    </>
  );
};

export default AutomatedSTNSellerStates;
