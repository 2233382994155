import { ReactNode } from "react";

import { Box } from "@chakra-ui/react";

import { getTopOffset } from "@/utils";

const SectionNavWrapper = ({
  containerElement,
  children,
}: {
  readonly containerElement: Element | null;
  readonly children: ReactNode;
}) => (
  <Box display="block" position="relative" h="full">
    <Box
      position="sticky"
      {...(!!containerElement && {
        top: getTopOffset(containerElement),
      })}
    >
      {children}
    </Box>
  </Box>
);

export default SectionNavWrapper;
