import { TabPanel, TabPanelProps, Box } from "@chakra-ui/react";

const MobileTabPanel = ({ children, ...props }: TabPanelProps) => (
  <TabPanel {...props} p={0} h="full" overflow="auto">
    {children}
    <Box h="mobile-tab-list-footer-height" />
  </TabPanel>
);

export default MobileTabPanel;
