import { useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Text, VStack } from "@chakra-ui/react";

import { CTACard, MailtoLink } from "@/components/common";
import { StandingBidBrokerCounterpartyActivityStandingBidFragment } from "@/gql";
import { useModal } from "@/hooks";
import { constants } from "@/utils";

const StandingBidBrokerCounterpartyActivity = ({
  standingBid,
}: {
  readonly standingBid: StandingBidBrokerCounterpartyActivityStandingBidFragment;
}) => {
  const { t } = useTranslation();

  const { modals, onOpenModal } = useModal();

  const contactEmail =
    standingBid.buyerEmail || constants.email_security_specialist;

  const companyName = standingBid.company.name;

  return (
    <Flex w="full" direction="column" gap={4}>
      <Card>
        <CardBody>
          <VStack alignItems="flex-start" spacing={3}>
            <Text textStyle="heading-sm">{t(`accept_standing_bid`)}</Text>
            <Text>
              {t(`broker_cannot_accept_standing_bid`, {
                companyName,
              })}
            </Text>
            <MailtoLink
              email={contactEmail}
              subject={`Broker questions on ${companyName}`}
              fontWeight="medium"
            >
              {contactEmail}
            </MailtoLink>
          </VStack>
        </CardBody>
      </Card>
      <CTACard
        direction="row"
        heading={t(`broker_submit_a_listing_instead`)}
        description={t(`broker_alternatively_submit_a_listing`, {
          companyName,
        })}
        action={{
          ctaText: t(`submit_a_listing`),
          onClick: () =>
            onOpenModal(
              modals.brokerSubmitListingOnCompany(standingBid.company),
            )(),
        }}
      />
    </Flex>
  );
};

export default StandingBidBrokerCounterpartyActivity;
