import { Box, BoxProps, keyframes } from "@chakra-ui/react";

import { chakraColor } from "@/utils";

export interface SkeletonProps extends BoxProps {
  /** The height of the skeleton element(s). */
  readonly h: BoxProps["h"];
  /** The number of skeleton elements to render. */
  readonly count?: number;
}

const animationKeyframes = keyframes`
  0% { background-position: -200px }
  100% { background-position: 800px }`;

const baseColor = chakraColor(`grey.75`);
const shineColor = chakraColor(`white`);

const Skeleton = ({
  count = 1,
  w = `full`,
  ...skeletonProps
}: SkeletonProps) => (
  <>
    {[...Array(count).keys()].map((index) => (
      <Box
        borderRadius="md"
        borderColor="grey.75"
        borderWidth={2}
        bgImage={`linear-gradient(90deg, ${baseColor} 0px, ${shineColor} 100px, ${baseColor} 200px)`}
        bgSize="1000px"
        key={index}
        w={w}
        {...skeletonProps}
        animation={`${animationKeyframes} 1.5s linear infinite`}
      />
    ))}
  </>
);

export default Skeleton;
