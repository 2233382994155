import { X } from "@phosphor-icons/react";

import { Fade } from "@chakra-ui/react";

import { useColors } from "@/hooks";

export const ComboboxClearButton = () => {
  const [grey700] = useColors([`grey.700`]);

  return (
    <Fade in transition={{ enter: { duration: 0.2 } }}>
      <X size={20} color={grey700} />
    </Fade>
  );
};
