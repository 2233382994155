import { AnimatePresence } from "framer-motion";

import { FadeInOut, TotalCount, WithQuery } from "@/components/common";
import {
  BidState,
  useUnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery,
} from "@/gql";

const UnaccreditedSellerPastBidNotificationsCountV2 = ({
  id,
}: {
  id: string;
}) => {
  const query = useUnaccreditedSellerByCompanyIdPastBidNotificationsCountQuery({
    variables: { id },
  });

  return (
    <WithQuery query={query} hideLoadingSpinner>
      {({
        data: {
          companyById: {
            activity: { myListings },
          },
        },
      }) => {
        const listing = myListings[0];
        const pastBids = listing.bids.filter((bid) =>
          [BidState.Withdrawn, BidState.Expired].includes(bid.state),
        );
        const totalPastBids = pastBids.length;
        const hasPastBids = totalPastBids > 0;

        return (
          <AnimatePresence>
            {hasPastBids && (
              <FadeInOut>
                <TotalCount totalCount={totalPastBids} />
              </FadeInOut>
            )}
          </AnimatePresence>
        );
      }}
    </WithQuery>
  );
};

export default UnaccreditedSellerPastBidNotificationsCountV2;
