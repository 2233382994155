import { ReactNode } from "react";

import { Center, Text } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";

type ShareDetailsDisclaimerProps = {
  readonly tooltip: ReactNode;
  readonly children: ReactNode;
};

const ShareDetailsDisclaimer = ({
  tooltip,
  children,
}: ShareDetailsDisclaimerProps) => (
  <Center p={4} pt={2}>
    <CustomTooltip placement="top" tooltipContent={tooltip}>
      <Text
        decoration="underline"
        textDecorationStyle="dashed"
        textDecorationThickness="0.5px"
        textUnderlineOffset={4}
      >
        {children}
      </Text>
    </CustomTooltip>
  </Center>
);

export default ShareDetailsDisclaimer;
