import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import Link from "next/link";

import { Button, Text } from "@chakra-ui/react";

import {
  Tile,
  TileHeader,
  TileRow,
  SensitiveText,
  Skeleton,
} from "@/components/common";
import { withConfig } from "@/components/hoc";
import {
  ListingPageListingHiiveUserTileListingFragment,
  ListingPageListingHiiveUserTileBrokerFragment,
  ListingState,
  RootConfigFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  toTimestamp,
  getHasExpired,
  getLongDocumentTitleByTransferMethod,
  getShortDocumentTitleByTransferMethod,
  hoursToDays,
} from "@/utils";
import { metabaseListingLink } from "@/utils/metabase";

interface MetabaseLinkProps {
  readonly listing: ListingPageListingHiiveUserTileListingFragment;
}

const MetabaseLink = ({ listing }: MetabaseLinkProps) => (
  <Link target="_blank" href={metabaseListingLink(listing.shortId)} passHref>
    <Button variant="text-salmon" px={0}>
      <TileHeader>Metabase</TileHeader>
    </Button>
  </Link>
);

const StatusTileSkeleton = () => <Skeleton h="5rem" w="full" />;

const StatusTile = withConfig(
  ({
    listing,
    config,
  }: {
    readonly listing: ListingPageListingHiiveUserTileListingFragment;
    readonly config: RootConfigFragment;
  }) => {
    const { t } = useTranslation();

    const documentTypeLongText = getLongDocumentTitleByTransferMethod(
      listing.transferMethod,
    );
    const documentTypeShortText = getShortDocumentTitleByTransferMethod(
      listing.transferMethod,
    );
    const hoursToSign = hoursToDays(config.stnLoiHoursValidHours);

    const statusText = match(listing.state)
      .with(ListingState.InReview, () =>
        t(`listing_submitted_in_review`, {
          date: toTimestamp(listing.insertedAt),
        }),
      )
      .with(ListingState.Open, () => t(`listing_live_status`))
      .with(ListingState.ConditionallySold, () =>
        t(`listing_conditionally_sold_status`, {
          documentTypeLongText,
          documentTypeShortText,
          hoursToSign,
        }),
      )
      .with(P.union(ListingState.Closed, ListingState.Withdrawn), () =>
        t(`listing_closed_status`),
      )
      .otherwise(() => null);

    const showStatusTile =
      !!statusText || !!listing.placedAt || !!listing.expireAt;

    if (!showStatusTile) return null;

    return (
      <TileRow>
        <TileHeader>Status</TileHeader>
        <Text>{statusText}</Text>
      </TileRow>
    );
  },
  { fallback: <StatusTileSkeleton /> },
);

const PartnerBrokerTile = ({
  listingState,
  broker,
}: {
  readonly listingState: ListingState;
  readonly broker: ListingPageListingHiiveUserTileBrokerFragment;
}) => {
  const { t } = useTranslation();

  return listingState === ListingState.InReview ? (
    <>
      <TileHeader>{t(`representing_hiive_connect_header`)}</TileHeader>
      <div>{`${broker.firstName} ${broker.lastName} - ${broker.email}`}</div>
    </>
  ) : (
    <>
      <TileHeader>Representing</TileHeader>
      <Text>
        <Text>{t(`listing_placed_by_hiive`)}</Text>
        <Text>Name: {`${broker.firstName} ${broker.lastName}`}</Text>
        <Text>Email: {broker.email}</Text>
      </Text>
    </>
  );
};

interface ListingHiiveUserTileProps {
  readonly listing: ListingPageListingHiiveUserTileListingFragment;
}

const ListingHiiveUserTile = ({ listing }: ListingHiiveUserTileProps) => {
  const { t } = useTranslation();

  const actor = useCurrentActor();

  const showInsertedAt = !(listing.brokerId === actor.id);

  return (
    <Tile
      py={2}
      gap={4}
      display="grid"
      gridTemplateColumns={{
        base: `1fr`,
        md: `auto 1fr`,
      }}
      flexDirection="column"
      alignItems="flex-start"
      data-testid="listing-hiive-user-tile"
    >
      {showInsertedAt && (
        <TileRow>
          <TileHeader>Placed</TileHeader>
          <SensitiveText>{toTimestamp(listing.placedAt)}</SensitiveText>
        </TileRow>
      )}
      {listing.expireAt && (
        <TileRow>
          <TileHeader>
            {getHasExpired(listing.expireAt) ? `Expired` : `Expires`}
          </TileHeader>
          <SensitiveText>{toTimestamp(listing.expireAt)}</SensitiveText>
        </TileRow>
      )}
      <StatusTile listing={listing} />
      {!!listing.broker && (
        <TileRow>
          <PartnerBrokerTile
            listingState={listing.state}
            broker={listing.broker}
          />
        </TileRow>
      )}
      <MetabaseLink listing={listing} />
      <TileHeader>
        {t(
          listing.solicited
            ? `listing_solicitation_solicited`
            : `listing_solicitation_unsolicited`,
        )}
      </TileHeader>
    </Tile>
  );
};

export default ListingHiiveUserTile;
