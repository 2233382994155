import { useField } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";

import styles from "./PhoneNumberInput.module.css";

interface PhoneNumberInputProps {
  readonly label: string;
  readonly name: string;
  readonly isRequired?: boolean;
  readonly onChange?: (value: string) => void;
}

const PhoneNumberInput = ({
  label,
  name,
  isRequired,
  onChange,
}: PhoneNumberInputProps) => {
  const [field, { touched, error }, { setValue }] = useField(name);

  const handleChange = (value: string) => {
    setValue(value);
  };

  const showError = !!error && touched;

  return (
    <FormControl id={name} isInvalid={showError}>
      <FormLabel htmlFor={name}>
        {label}
        {isRequired ? ` *` : ``}
      </FormLabel>

      <PhoneInput
        {...field}
        inputProps={{
          id: name,
        }}
        onChange={(value, _c, _e, formattedValue) => {
          handleChange(formattedValue);
          onChange?.(value);
        }}
        country="us"
        containerClass={styles.container}
        inputClass={`${styles.input} ${showError ? styles.error : ``}`}
        buttonClass={`${styles.inputButton} ${showError ? styles.error : ``}`}
        dropdownClass={styles.dropdown}
      />

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default PhoneNumberInput;
