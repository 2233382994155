import { match } from "ts-pattern";

import { CardFooter } from "@chakra-ui/react";

import {
  DiscussionMembershipState,
  DiscussionThreadCardFooterDiscussionFragment,
} from "@/gql";
import { getAvailableDiscussionActions } from "@/utils";

import { AcceptDiscussionRequestForm } from "./AcceptDiscussionRequestForm";
import { SendMessageForm } from "./SendMessageForm";

const DiscussionThreadCardFooter = ({
  discussion,
}: {
  readonly discussion: DiscussionThreadCardFooterDiscussionFragment;
}) => {
  const {
    canSendMessage,
    canAcceptDiscussionRequest,
  } = getAvailableDiscussionActions(discussion);

  const isMembershipPending =
    discussion.myMembershipState === DiscussionMembershipState.Pending;

  return match({
    isMembershipPending,
    canAcceptDiscussionRequest,
    canSendMessage,
  })
    .with(
      { isMembershipPending: true, canAcceptDiscussionRequest: true },
      () => (
        <CardFooter>
          <AcceptDiscussionRequestForm discussion={discussion} />
        </CardFooter>
      ),
    )
    .with({ isMembershipPending: false, canSendMessage: true }, () => (
      <CardFooter>
        <SendMessageForm discussion={discussion} />
      </CardFooter>
    ))
    .otherwise(() => null);
};

export default DiscussionThreadCardFooter;
