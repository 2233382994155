import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isNil from "lodash/fp/isNil";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CardBody,
  CardFooter,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";

import { QuestionTooltip } from "@/components/form";
import { UserWithInstitutionFragment } from "@/gql";
import { useAccreditationPageStatus } from "@/hooks";
import { getIsInstitutionUser } from "@/utils";

import AccreditationCardModifyContentIsQIB from "./AccreditationCardModifyContentIsQIB";

const AccreditationCardContent = ({
  actor,
  onOpen,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly onOpen: () => void;
}) => {
  const { t } = useTranslation();
  dayjs.extend(advancedFormat);

  const isInstitutionUser = getIsInstitutionUser(actor);

  const accreditationPageStatus = useAccreditationPageStatus(actor);

  if (isInstitutionUser && !actor.institution)
    throw new Error(
      `Actor is an institution but does not contain any institution data`,
    );

  const accreditationLastUpdatedAt = isInstitutionUser
    ? actor.institution?.accreditationLastUpdatedAt
    : actor.accreditationLastUpdatedAt;

  const accreditationAnswer = isInstitutionUser
    ? actor.institution?.accreditationAnswers[0]
    : actor.accreditationAnswers[0];

  const questionTextAsAnswer = accreditationAnswer?.question.text;
  const customAnswer = accreditationAnswer?.text;

  const isUSAInstitution = actor.institution?.country.name === `US`;

  const isQib = isUSAInstitution && accreditationAnswer?.question.order === 1;

  if (!accreditationAnswer) {
    return <Text>You don&apos;t have any accreditation answer.</Text>;
  }

  return (
    <>
      <CardBody p={{ base: 4, lg: 10 }}>
        <Stack
          direction={{ base: `column-reverse`, lg: `row` }}
          alignItems={{ base: `flex-end`, lg: `normal` }}
          gap={4}
        >
          <Box w="full">
            {isUSAInstitution && (
              <Box mb={10}>
                <Text textStyle="heading-lg">
                  {t(`is_qualified_qib`)}
                  <QuestionTooltip
                    href="https://www.law.cornell.edu/cfr/text/17/230.144A"
                    pl={1}
                    translateY={1.5}
                    transform="auto"
                    tooltipContent={t(`qualified_institutional_buyer`)}
                  />
                </Text>
                <Text mt={3}>
                  {t(`answer`)}:{` `}
                  <AccreditationCardModifyContentIsQIB actor={actor} />
                </Text>
              </Box>
            )}
            {questionTextAsAnswer && !isQib && (
              <>
                <Text textStyle="heading-lg">
                  {isInstitutionUser
                    ? t(`firm_fund_must_be_accredited_and_how`)
                    : t(`must_be_accredited`)}
                </Text>
                <Text mt={6}>
                  {t(`answer`)}: {questionTextAsAnswer}
                </Text>
              </>
            )}
            {customAnswer && (
              <Textarea variant="grey" mt={4} maxW={552} resize="none" readOnly>
                {customAnswer}
              </Textarea>
            )}
          </Box>
          <Box pb={{ base: 4, md: 0 }}>
            <Button
              variant="boxed-grey"
              size="xl"
              disabled={accreditationPageStatus !== `modify`}
              onClick={onOpen}
            >
              <Text as="span" px={6}>
                {t(`modify`)}
              </Text>
            </Button>
          </Box>
        </Stack>
      </CardBody>
      {!isNil(accreditationLastUpdatedAt) && (
        <CardFooter>
          {t(`submitted_on`, {
            date: `${dayjs(accreditationLastUpdatedAt).format(
              `Do MMM YYYY`,
            )} (${dayjs(accreditationLastUpdatedAt).format(`hh:mm A z`)})`,
          })}
        </CardFooter>
      )}
    </>
  );
};

export default AccreditationCardContent;
