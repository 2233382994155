import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Card, CardBody, CardFooter, CardHeader, Text } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveCancelButton,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import { SubmitBidCardBrokerSubmitBidMutation } from "@/gql";
import { useModal } from "@/hooks";

import { SubmitBidCardStepFormContext } from "./SubmitBidCardStepFormContext";
import { SubmitBidCardFormValues, StepKeys } from "./types";

const SubmitBidCardConfirmation = ({
  stepRouter: { stepControls },
  isSubmitting,
}: StepPropsV2<StepKeys, SubmitBidCardFormValues>) => {
  const { submitMutation } = useContext(SubmitBidCardStepFormContext);
  const { t } = useTranslation();

  const { onOpenModal, modals } = useModal();

  const router = useRouter();

  const onClickSubmit = () =>
    submitMutation().then((response: SubmitBidCardBrokerSubmitBidMutation) => {
      const newBid = response.brokerSubmitBid.bid;
      if (!newBid?.id) return;

      onOpenModal(modals.placeBidSuccess(newBid))();
      router.push(`/listings/bids/${newBid.id}`);
    });

  return (
    <Card variant="darkened-footer" w="full">
      <CardHeader py={4}>
        <Text textStyle="heading-2xl">{t(`confirm`)}</Text>
      </CardHeader>
      <CardBody>
        <Text textStyle="heading-lg">
          {t(`broker_submit_bid_confirmation_body`)}
        </Text>
      </CardBody>
      <CardFooter py={6}>
        <ButtonFooterWrapper>
          <HiiveCancelButton
            sentryLabel="[SubmitBidCardConfirmation/Cancel]"
            onCancel={stepControls.previousStep}
          />
          <HiiveSubmitButton
            sentryLabel="[SubmitBidCardConfirmation/Submit]"
            onClick={onClickSubmit}
            submitText={t(`confirm`)}
            isLoading={isSubmitting}
          />
        </ButtonFooterWrapper>
      </CardFooter>
    </Card>
  );
};

export default SubmitBidCardConfirmation;
