import { forwardRef, ReactNode } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

import { getTopOffset } from "@/utils";

interface ScrollSectionProps extends BoxProps {
  readonly children: ReactNode;
  readonly containerElement: Element | null;
}

const ScrollSection = forwardRef<HTMLDivElement, ScrollSectionProps>(
  ({ children, containerElement, ...boxProps }, ref) => (
    <Box
      w="full"
      ref={ref}
      __css={{
        counterIncrement: `section`,
        "&:last-child": {
          ...(!!containerElement && {
            minH: {
              base: `unset`,
              lg: `calc(100vh - ${getTopOffset(containerElement)}px - 56px)`,
            },
          }),
        },
      }}
      {...boxProps}
    >
      {children}
    </Box>
  ),
);

export default ScrollSection;
