import { useState } from "react";
import { useTranslation } from "react-i18next";

import Link from "next/link";

import { Box, Card, CardBody, Flex, Text } from "@chakra-ui/react";

import Meta from "@/components/Meta";
import { SlideAnimation } from "@/components/auth-v2";
import { HiiveButton } from "@/components/common";
import { withGuestRoute } from "@/components/hoc";

import ResetPasswordForm from "./ResetPasswordForm";

const PasswordResetMessage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SlideAnimation>
        <Card maxW="fit-content" mx="auto">
          <CardBody p={{ base: 6, md: 7 }}>
            <Text align="center" textStyle="text-md" color="grey.900">
              {t(`can_sign_in_with_new_password`)}
            </Text>
          </CardBody>
        </Card>
      </SlideAnimation>

      <HiiveButton
        as={Link}
        variant="rounded-solid-salmon"
        size="xl"
        href="/login"
        sentryLabel="[ResetPasswordPage] Sign In"
        w={{ base: `full`, sm: `auto` }}
        maxW="auto"
        alignSelf="center"
      >
        {t(`sign_in`)}
      </HiiveButton>
    </>
  );
};

const ResetPasswordPage = ({ token }: { readonly token: string }) => {
  const [passwordReset, setPasswordReset] = useState<boolean>(false);
  const { t } = useTranslation();

  const onSuccess = () => {
    setPasswordReset(true);
  };

  return (
    <>
      <Meta title={t(`reset_password`)} shouldTrackPageEvent />
      <Flex
        direction="column"
        bg="grey.50"
        minH="100vh"
        w="full"
        py={{ base: 20, sm: 32 }}
        px={{ base: 4, lg: 8 }}
        gap={9}
      >
        <Box mx="auto" w="full" maxW="lg">
          <Flex direction="column" gap={8}>
            <Text as="h1" textStyle="heading-2xl" align="center">
              {t(`reset_password_page_heading`)}
            </Text>
            {passwordReset ? (
              <PasswordResetMessage />
            ) : (
              <>
                <Text align="center" textStyle="text-md" color="grey.900">
                  {t(`enter_new_password`)}
                </Text>
                <ResetPasswordForm token={token} onSuccess={onSuccess} />
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default withGuestRoute(ResetPasswordPage);
