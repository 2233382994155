import { Form } from "formik";
import * as Yup from "yup";

import { HStack, Link, Radio, Spacer, Text, VStack } from "@chakra-ui/react";

import {
  HiiveButton,
  UnaccreditedSellerCustomerAgreement,
} from "@/components/common";
import { FormikQL, FormRadioInput } from "@/components/form";
import {
  CurrentContextDocument,
  useUpdateAgreedToCustomerAgreementMutation,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

const initialValues = {
  agreedToCustomerAgreement: null,
};

const validationSchema = Yup.object().shape({
  agreedToCustomerAgreement: Yup.string().required(`Required`),
});

const mapVariables = ({
  agreedToCustomerAgreement,
}: {
  readonly agreedToCustomerAgreement: string | null;
}) => ({
  agreedToCustomerAgreement: agreedToCustomerAgreement === `true`,
});

const SellerAgreementPage = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useUpdateAgreedToCustomerAgreementMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => {
    routeToNextStep();
  };

  return (
    <VStack
      maxW={{ base: `none`, md: `40rem`, lg: `50rem` }}
      mx={{
        base: `auto`,
        md: `calc((100% - 40rem) / 2)`,
        lg: `calc((100% - 50rem) / 2)`,
      }}
      spacing={4}
    >
      <Text align="center" color="grey.900">
        Before you can post your own listing, or respond to potential buyers’
        bids, you must read and agree to the Customer Agreement.
      </Text>
      <Text align="center" color="grey.900">
        The Customer Agreement references the Hiive Terms and Conditions which
        can be found{` `}
        <Link fontWeight="medium" href="/terms-and-conditions" target="_blank">
          here
        </Link>
        .
      </Text>
      <UnaccreditedSellerCustomerAgreement />
      <FormikQL
        initialValues={initialValues}
        mapVariables={mapVariables}
        mutation={mutation}
        mutationNames={[`updateAgreedToCustomerAgreement`]}
        onSuccess={onSuccess}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <Text textAlign="center" my={4}>
              By clicking the &quot;agree&quot; button below I agree to the
              above.
            </Text>
            <VStack spacing={8}>
              <FormRadioInput name="agreedToCustomerAgreement">
                <HStack w="full" justifyContent="center" spacing={4}>
                  <Radio value="false" variant="base">
                    Decline
                  </Radio>
                  <Radio value="true" variant="base">
                    Agree
                  </Radio>
                </HStack>
              </FormRadioInput>
              {!!values.agreedToCustomerAgreement ? (
                <HiiveButton
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  sentryLabel="[SellerAgreementPage] Next"
                  size="md"
                  type="submit"
                  variant="rounded-solid-salmon"
                >
                  Next
                </HiiveButton>
              ) : (
                <Spacer h={10} />
              )}
            </VStack>
          </Form>
        )}
      </FormikQL>
    </VStack>
  );
};

export default SellerAgreementPage;
