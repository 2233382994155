import { Box } from "@chakra-ui/react";

import { MarkdownDocument } from "@/components/common";
import styles from "@/styles/suitability-markdown-styles.module.css";

const SuitabilityModifyFieldDescription = ({
  markdown,
}: {
  readonly markdown: string;
}) => (
  <Box my={6} maxW={700}>
    <div className={styles[`suitability-markdown`]}>
      <MarkdownDocument markdown={markdown} />
    </div>
  </Box>
);

export default SuitabilityModifyFieldDescription;
