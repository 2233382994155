import { ButtonGroup, Button, Text } from "@chakra-ui/react";

import { Hiive50IndexPriceDateRange } from "@/gql";

const RANGE_LABELS: Record<Hiive50IndexPriceDateRange, string> = {
  [Hiive50IndexPriceDateRange.ThreeMonths]: `3m`,
  [Hiive50IndexPriceDateRange.SixMonths]: `6m`,
  [Hiive50IndexPriceDateRange.OneYear]: `1y`,
  [Hiive50IndexPriceDateRange.Max]: `max`,
};

export const RangeSelector = ({
  selected,
  onChange,
}: {
  readonly selected: Hiive50IndexPriceDateRange;
  readonly onChange: (range: Hiive50IndexPriceDateRange) => void;
}) => (
  <ButtonGroup variant="outline" size="sm" isAttached>
    {Object.keys(RANGE_LABELS).map((option: Hiive50IndexPriceDateRange) => (
      <Button
        key={option}
        onClick={() => onChange(option)}
        bg={selected === option ? `grey.800` : `transparent`}
        color={selected === option ? `white` : `inherit`}
      >
        <Text textStyle="heading-sm">{RANGE_LABELS[option]}</Text>
      </Button>
    ))}
  </ButtonGroup>
);
