import {
  Center,
  useTab,
  useStyleConfig,
  Flex,
  TabProps,
} from "@chakra-ui/react";

const MobileTab = (props: TabProps) => {
  const tabProps = useTab(props);
  const isSelected = !!tabProps[`aria-selected`];
  const styles = useStyleConfig(`Tab`);

  return (
    <Flex
      sx={styles}
      {...tabProps}
      w="full"
      bg="white"
      fontWeight="medium"
      justifyContent="center"
      alignItems="center"
      outline="none"
      textStyle="heading-xs"
      h="full"
      borderTopWidth={0.25}
      borderTopColor="grey.100"
      _selected={{
        bg: `grey.25`,
        borderTopColor: `salmon.900`,
      }}
      __css={{
        "&:last-of-type > *": {
          borderRightWidth: 0,
        },
      }}
    >
      <Center
        h="full"
        w="full"
        borderRightWidth="0.25px"
        borderRightColor="grey.200"
        borderTopWidth={4}
        borderTopColor={isSelected ? `salmon.900` : `white`}
        lineHeight="none"
      >
        {tabProps.children}
      </Center>
    </Flex>
  );
};

export default MobileTab;
