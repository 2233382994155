import { AnimatePresence } from "framer-motion";
import intersection from "lodash/intersection";

import { FadeInOut, TotalCount, WithQuery } from "@/components/common";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import { useUnaccreditedSellerStandingBidNotificationsCountQuery } from "@/gql";

const UnaccreditedSellerStandingBidNotificationsCount = () => {
  const { newStandingBidIds } = useUnaccreditedSellerInlineNotifications();
  const query = useUnaccreditedSellerStandingBidNotificationsCountQuery();

  return (
    <WithQuery query={query} hideLoadingSpinner>
      {({
        data: {
          unaccreditedSellerMyCompanyActivity: { availableStandingBids },
        },
      }) => {
        const standingBidIds = availableStandingBids.map(({ id }) => id);

        const numNewStandingBids = intersection(
          newStandingBidIds,
          standingBidIds,
        ).length;

        const hasNewStandingBids = numNewStandingBids > 0;

        const totalStandingBids = availableStandingBids.length;
        const hasStandingBids = totalStandingBids > 0;

        return (
          <AnimatePresence>
            {hasStandingBids && (
              <FadeInOut>
                <TotalCount
                  totalCount={totalStandingBids}
                  hasNewNotifications={hasNewStandingBids}
                />
              </FadeInOut>
            )}
          </AnimatePresence>
        );
      }}
    </WithQuery>
  );
};

export default UnaccreditedSellerStandingBidNotificationsCount;
