import currency from "currency.js";

import { Maybe } from "@/types";
import { constants } from "@/utils";

export const getAreFeesHighForBid = ({
  pricePerShare,
  numberOfShares,
}: {
  readonly pricePerShare?: Maybe<number>;
  readonly numberOfShares: number;
}) => {
  const pricePerShareInDollars = pricePerShare
    ? Math.round(pricePerShare / 100)
    : null;

  const isWithinWarningThreshold = pricePerShareInDollars
    ? pricePerShareInDollars * numberOfShares <
      constants.high_fees_warning_threshold
    : false;

  return isWithinWarningThreshold;
};

export const getMinOrMaxPPSValidationErrorMessage = (
  min?: number | null,
  max?: number | null,
): string => {
  const start = `You must bid`;
  const end = `Factors such as a recent share split or consolidation may be the cause for this restriction.`;

  if (!!min && !!max) {
    return `${start} within a ${currency(min / 100).format()} to ${currency(
      max / 100,
    ).format()} per share range for this security. ${end}`;
  }
  if (min) {
    return `${start} over ${currency(
      min / 100,
    ).format()} per share for this security. ${end}`;
  }
  if (max) {
    return `${start} under ${currency(
      max / 100,
    ).format()} per share for this security. ${end}`;
  }

  return ``;
};
