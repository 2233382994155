import { Form } from "formik";
import * as Yup from "yup";

import { Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FormikQL } from "@/components/form";
import {
  AnswerInstitutionAccreditationQuestionsInput,
  CurrentContextDocument,
  InstitutionAccreditationPageCountryFragment,
  useAnswerInstitutionAccreditationQuestionsMutation,
} from "@/gql";
import { useCountryList, useRouteToNextOnboardingStep } from "@/hooks";

import { OtherInstitutionAccreditationFields } from "./OtherInstitutionAccreditationFields";

const initialValues = {
  answers: [],
};

const validationSchema = Yup.object().shape({
  answers: Yup.array()
    .of(
      Yup.object({
        key: Yup.string().required(`Must select an answer`),
        text: Yup.string(),
      }),
    )
    .test({
      message: `Must select at least one option`,
      test: (arr) => (arr ? arr.length > 0 : false),
    }),
});

const mapVariables = (
  values: AnswerInstitutionAccreditationQuestionsInput,
): { readonly input: AnswerInstitutionAccreditationQuestionsInput } => ({
  input: {
    ...values,
  },
});

export const OtherInstitutionAccreditationForm = ({
  country,
}: {
  readonly country: InstitutionAccreditationPageCountryFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useAnswerInstitutionAccreditationQuestionsMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => routeToNextStep();

  const countryList = useCountryList();

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`answerInstitutionAccreditationQuestions`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <Form>
          <VStack spacing={10}>
            <Text mt={7} align="center" color="h-dark-grey">
              <Text textStyle="deprecated-heading-xl" mb={6}>
                {countryList.getName(country.name)}
              </Text>
              <Text mb={3}>
                In order to access Hiive, your firm/fund must be permitted by
                the laws of the jurisdiction in which it is located to buy and
                sell unregistered private securities.
              </Text>
              <Text>
                These are securities for which no public disclosure or other
                information is available. Investments in these securities are
                very risky and illiquid. They are only appropriate for investors
                with significant investment risk tolerance, no short-term
                liquidity requirements, and extensive investment experience.
              </Text>
            </Text>
            <OtherInstitutionAccreditationFields />
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[OtherInstitutionAccreditationForm] Next"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};
