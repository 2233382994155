import { ReactNode } from "react";

import { SimpleGrid } from "@chakra-ui/react";

export const CompanyActionsCTACardGrid = ({
  children,
  numActionsAvailable,
}: {
  readonly children: ReactNode;
  readonly numActionsAvailable: number;
}) => (
  <SimpleGrid
    columns={{
      base: 1,
      sm: numActionsAvailable === 1 ? 1 : 2,
      md: 1,
      xl: numActionsAvailable === 1 ? 1 : 2,
    }}
    gap={4}
    w="full"
  >
    {children}
  </SimpleGrid>
);
