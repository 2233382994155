import { ComboboxClearButton } from "./ComboboxClearButton";
import { ComboboxInput } from "./ComboboxInput";
import { ComboboxItem } from "./ComboboxItem";
import { ComboboxLabel } from "./ComboboxLabel";
import { ComboboxMenu } from "./ComboboxMenu";
import { ComboboxDescription } from "./ComboxboxDescription";
import { SelectContainer } from "./SelectContainer";

export default {
  Container: SelectContainer,
  Input: ComboboxInput,
  Menu: ComboboxMenu,
  Item: ComboboxItem,
  Label: ComboboxLabel,
  Description: ComboboxDescription,
  ClearButton: ComboboxClearButton,
};
