import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { BrokerSubmitBidSequenceModalBrokerSubmitBidMutation } from "@/gql";

import { StepKeys } from "./steps";

export const BrokerSubmitBidSequenceModalStepFormContext = createStepFormContext<
  StepKeys,
  BrokerSubmitBidSequenceModalBrokerSubmitBidMutation
>();

export const useBrokerSubmitBidSequenceModalStepFormContext = () =>
  useContext(BrokerSubmitBidSequenceModalStepFormContext);
