import {
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  VStack,
  Flex,
} from "@chakra-ui/react";

import { ActivityCard, Skeleton } from "@/components/common";

interface ActivityCardSkeletonProps {
  /** Whether to display the card body */
  readonly includeBody?: boolean;
  /** Whether to display property pills when the body is present */
  readonly includePropertyPills?: boolean;
}

const ActivityCardSkeleton = ({
  includeBody,
  includePropertyPills,
}: ActivityCardSkeletonProps) => (
  <ActivityCard href="#" pointerEvents="none">
    <CardHeader>
      <HStack>
        <Skeleton h="24px" w="50px" />
        <Skeleton h="20px" w="60px" />
      </HStack>
    </CardHeader>
    {includeBody && (
      <CardBody
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex direction="row" gap={6} width="400px">
          <Skeleton h="24px" maxW="38px" />
          <Skeleton h="24px" maxW="132px" />
          <Skeleton h="24px" maxW="80px" />
        </Flex>
        {includePropertyPills && (
          <VStack justifyContent="flex-end">
            <Skeleton h="35px" w="100px" />
            <Skeleton h="35px" w="100px" />
          </VStack>
        )}
      </CardBody>
    )}
    <CardFooter py={3}>
      <Skeleton h="14px" w="35%" />
    </CardFooter>
  </ActivityCard>
);

export default ActivityCardSkeleton;
