import { HiiveModalHeader } from "@/components/common";
import { StepPropsV2, StepsHeader } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  PlaceStandingBidSelectCompanyInputCompanyFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { getIsBroker, Nullable } from "@/utils";

import {
  bidDetailsValidationSchema,
  createAdditionalDetailsValidationSchema,
  PlaceStandingBidSequenceModalFormValues,
} from ".";
import { AdditionalDetails } from "./AdditionalDetails";
import { BidDetails } from "./BidDetails";
import { stepKeys, StepKeys } from "./steps";

interface PlaceStandingBidModalProps
  extends StepPropsV2<
    StepKeys,
    Nullable<PlaceStandingBidSequenceModalFormValues>
  > {
  readonly actor: UserWithInstitutionFragment;
  readonly initialCompany?: PlaceStandingBidSelectCompanyInputCompanyFragment;
}

const PlaceStandingBidModal = ({
  stepRouter,
  actor,
  initialCompany,
  ...formikProps
}: PlaceStandingBidModalProps) => {
  const { values } = formikProps;

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  const isBidDetailsStepValid = bidDetailsValidationSchema.isValidSync(values);
  const isAdditionalDetailsStepValid = createAdditionalDetailsValidationSchema(
    actor,
  ).isValidSync(values);

  const actorIsBroker = getIsBroker(actor);

  return (
    <div data-testid="create-standing-bid-modal">
      <HiiveModalHeader>
        {actorIsBroker ? `Submit` : `Place`} standing bid
      </HiiveModalHeader>
      <StepsHeader
        stepRouter={stepRouter}
        steps={[
          {
            stepKey: stepKeys.placeStandingBidBidDetails,
            stepTitle: `Bid information`,
            isStepValid: isBidDetailsStepValid,
          },
          {
            stepKey: stepKeys.placeStandingBidAdditionalDetails,
            stepTitle: `Additional details`,
            isStepValid: isAdditionalDetailsStepValid,
          },
        ]}
      />
      {stepKeys.placeStandingBidBidDetails === currentStepKey && (
        <BidDetails
          initialCompany={initialCompany}
          stepRouter={stepRouter}
          {...formikProps}
        />
      )}
      {stepKeys.placeStandingBidAdditionalDetails === currentStepKey && (
        <AdditionalDetails stepRouter={stepRouter} {...formikProps} />
      )}
    </div>
  );
};

export default withCurrentActor(PlaceStandingBidModal);
