import { ReactNode } from "react";

import {
  defineStyleConfig,
  useStyleConfig,
  Box,
  BoxProps,
  TextProps,
  Text,
  Flex,
} from "@chakra-ui/react";

import { useIsDesktop } from "@/hooks";

export const TileConfig = defineStyleConfig({
  baseStyle: {
    display: `flex`,
    bg: `grey.25`,
    borderWidth: 0.5,
    borderColor: `grey.200`,
    borderRadius: `md`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    px: 4,
    py: 4,
    gap: 4,
  },
});

type TileProps = BoxProps;
type TileHeaderProps = TextProps;

export const TileRow = ({ children }: { readonly children: ReactNode }) => {
  const isDesktop = useIsDesktop();
  return isDesktop ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <Flex direction="column" alignItems="flex-start" gap={1} w="full">
      {children}
    </Flex>
  );
};

export const TileHeader = (props: TileHeaderProps) => (
  <Text textStyle="heading-2xs" lineHeight="inherit" {...props} />
);

export const Tile = (props: TileProps) => {
  const themeStyles = useStyleConfig(`Tile`, props);

  return <Box __css={themeStyles} {...props} />;
};
