import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

export const Modal = defineMultiStyleConfig({
  baseStyle: {
    dialog: {
      my: { base: 0, md: `auto` },
      mx: { base: 0, md: `auto` },
      borderColor: `grey.200`,
      borderRadius: { base: 0, md: `lg` },
      bg: `white`,
      boxShadow: `card`,
    },
    header: {
      borderWidth: 0.5,
      borderColor: `grey.700`,

      bg: `grey.900`,
      py: { base: 4, md: 6 },
      px: { base: 5, md: 10 },
      borderTopRadius: { base: 0, md: `lg` },

      color: `white`,
      textStyle: `heading-2xl`,
      fontWeight: `medium`,
    },
    closeButton: {
      color: `white`,
      size: `xl`,
      position: `absolute`,
    },
    body: {
      bg: `white`,
      borderColor: `grey.200`,
      borderWidth: 0.5,
      borderTopWidth: 0,
      "&:last-child": {
        borderBottomRadius: `md`,
      },
      py: 6,
      px: { base: 5, md: 10 },
    },
    footer: {
      bg: `grey.25`,
      borderColor: `grey.200`,
      borderWidth: { base: 0, md: 0.5 },
      borderTopWidth: { base: 0, md: 0 },
      borderBottomRadius: { base: 0, md: `md` },
      py: 6,
    },
  },
});
