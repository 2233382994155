import { match } from "ts-pattern";

import { TransferMethod, UnaccreditedSellerTransferMethodInput } from "@/gql";

import { transferTypeChoices, TransferTypeChoices } from "./types";

export const transferTypeChoiceToTransferMethod = (
  transferTypeChoice: TransferTypeChoices,
) =>
  match(transferTypeChoice)
    .with(`Yes`, () => UnaccreditedSellerTransferMethodInput.Direct)
    .with(`No`, () => UnaccreditedSellerTransferMethodInput.ForwardContract)
    .with(`DontKnow`, () => UnaccreditedSellerTransferMethodInput.Unknown)
    .exhaustive();

export const transferMethodToTransferTypeChoice = (
  transferMethod: TransferMethod,
) =>
  match(transferMethod)
    .with(TransferMethod.Direct, () => transferTypeChoices.Yes)
    .with(TransferMethod.ForwardContract, () => transferTypeChoices.No)
    .with(TransferMethod.Unknown, () => transferTypeChoices.DontKnow)
    .otherwise(() => {
      throw new Error(
        `Invalid transferMethod in transferMethodToTransferTypeChoice`,
      );
    });

export const transferTypeChoiceToString = (
  transferTypeChoice: TransferTypeChoices,
) =>
  match(transferTypeChoice)
    .with(`Yes`, () => `I can transfer the shares directly`)
    .with(`No`, () => `I can't transfer the shares directly`)
    .with(`DontKnow`, () => `I am not sure if I transfer the shares directly`)
    .exhaustive();
