import { Tabs, TabsProps } from "@chakra-ui/react";

interface MobileTabsProps extends TabsProps {
  readonly defaultIndex?: number;
}

const MobileTabs = ({ children, ...props }: MobileTabsProps) => (
  <Tabs {...props} w="full" isLazy onChange={() => window.scrollTo({ top: 0 })}>
    {children}
  </Tabs>
);

export default MobileTabs;
