import { Form } from "formik";
import * as Yup from "yup";

import { Box, GridItem, SimpleGrid, Spacer, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { AutosizeTextAreaInput, FormikQL, TextInput } from "@/components/form";
import {
  CurrentContextDocument,
  UserWithInstitutionFragment,
  useAddBrokerInfoMutation,
} from "@/gql";
import { useCurrentActor, useRouteToNextOnboardingStep } from "@/hooks";

const initialValues = ({
  firstName,
  lastName,
}: UserWithInstitutionFragment) => ({
  firstName,
  lastName,
  brokerRegistrationNumber: ``,
  firmName: ``,
  firmRegistrationNumber: ``,
  firmStreetAddress: ``,
  firmCity: ``,
  firmState: ``,
  firmPostalCode: ``,
});

const createValidationSchema = (isRegisteredInUSA: boolean) => () => {
  const licenseType = isRegisteredInUSA ? `CRD` : `NRD`;
  return Yup.object().shape({
    firstName: Yup.string().nullable().required(`First name is required`),
    lastName: Yup.string().nullable().required(`Last name is required`),
    brokerRegistrationNumber: Yup.string()
      .nullable()
      .required(`Your ${licenseType} number is required`),
    firmName: Yup.string().nullable().required(`Firm name is required`),
    firmRegistrationNumber: Yup.string()
      .nullable()
      .required(`Firm ${licenseType} number is required`),
    firmStreetAddress: Yup.string()
      .nullable()
      .required(`Street address is required`),
    firmCity: Yup.string().nullable().required(`City is required`),
    firmState: Yup.string()
      .nullable()
      .required(`${isRegisteredInUSA ? `State` : `Province`} is required`),
    firmPostalCode: Yup.string()
      .nullable()
      .required(`${isRegisteredInUSA ? `Zip` : `Postal`} code is required`),
  });
};

interface FormLabels {
  readonly brokerRegistrationNumberLabel: string;
  readonly brokerRegistrationNumberPlaceholder: string;
  readonly firmRegistrationNumberLabelPlaceholder?: string;
  readonly stateLabel?: string;
  readonly postalCodeLabel?: string;
}

const formLabels = (isRegisteredInUSA: boolean): FormLabels => {
  if (isRegisteredInUSA) {
    return {
      brokerRegistrationNumberLabel: `Your CRD number`,
      brokerRegistrationNumberPlaceholder: `CRD number`,
      firmRegistrationNumberLabelPlaceholder: `Firm CRD number`,
      stateLabel: `State`,
      postalCodeLabel: `Zip Code`,
    };
  }
  return {
    brokerRegistrationNumberLabel: `Your NRD number`,
    brokerRegistrationNumberPlaceholder: `NRD number`,
    firmRegistrationNumberLabelPlaceholder: `Firm NRD number`,
    stateLabel: `Province`,
    postalCodeLabel: `Postal Code`,
  };
};

const BrokerInformationForm = () => {
  const actor = useCurrentActor();

  const finraRegistered = !!actor?.finraRegistered;
  const countryCode = actor.country?.name;
  const isRegisteredInUSA = countryCode === `US` || finraRegistered;

  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useAddBrokerInfoMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => {
    routeToNextStep();
  };

  const {
    brokerRegistrationNumberLabel,
    brokerRegistrationNumberPlaceholder,
    firmRegistrationNumberLabelPlaceholder,
    stateLabel,
    postalCodeLabel,
  } = formLabels(isRegisteredInUSA);

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`addBrokerInfo`]}
      initialValues={initialValues(actor)}
      validationSchema={createValidationSchema(isRegisteredInUSA)}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <VStack spacing={9}>
            <SimpleGrid columns={4} columnGap={5} rowGap={6} w="full">
              <GridItem colSpan={2}>
                <TextInput
                  name="firstName"
                  label="Your full name"
                  placeholder="First"
                  bg="h-white"
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Spacer h={6} />
                <TextInput name="lastName" placeholder="Last" bg="h-white" />
              </GridItem>
              <GridItem colSpan={4}>
                <TextInput
                  name="brokerRegistrationNumber"
                  label={brokerRegistrationNumberLabel}
                  placeholder={brokerRegistrationNumberPlaceholder}
                  bg="h-white"
                />
              </GridItem>
              <GridItem colSpan={4}>
                <TextInput
                  name="firmName"
                  label="Firm name"
                  placeholder="Name"
                  bg="h-white"
                />
              </GridItem>
              <GridItem colSpan={4}>
                <TextInput
                  name="firmRegistrationNumber"
                  label={firmRegistrationNumberLabelPlaceholder}
                  placeholder={firmRegistrationNumberLabelPlaceholder}
                  bg="h-white"
                />
              </GridItem>
              <GridItem colSpan={4}>
                <AutosizeTextAreaInput
                  name="firmStreetAddress"
                  label="Street address of firm"
                  maxRows={3}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <TextInput name="firmCity" label="City" bg="h-white" />
              </GridItem>
              <GridItem colSpan={1}>
                <TextInput name="firmState" label={stateLabel} bg="h-white" />
              </GridItem>
              <GridItem colSpan={1}>
                <TextInput
                  name="firmPostalCode"
                  label={postalCodeLabel}
                  bg="h-white"
                />
              </GridItem>
            </SimpleGrid>
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[BrokerResidenceForm] Next"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

const BrokerInformationPage = () => (
  <Box mt={{ base: `1rem`, md: `3rem` }}>
    <BrokerInformationForm />
  </Box>
);

export default BrokerInformationPage;
