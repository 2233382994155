import { GridItem, SimpleGrid, VStack } from "@chakra-ui/react";

import { FullContentWrapper, Skeleton } from "@/components/common";

import { SharePriceCardSkeleton } from "./SharePriceCardSkeleton";

export const UnaccreditedSellerModifyListingPageContentSkeleton = () => (
  <FullContentWrapper px={{ base: 4, lg: 8 }}>
    <SimpleGrid
      columnGap={6}
      rowGap={4}
      maxW="max-width-lg"
      gridTemplateColumns={{ base: `1fr`, lg: `416px 1fr` }}
      w="full"
    >
      <GridItem gridColumn={{ base: 1, lg: 2 }}>
        <Skeleton h="24px" w="256px" mb="5px" />
      </GridItem>
      <GridItem gridColumn={{ base: 1, lg: 2 }}>
        <VStack spacing={4} alignItems="flex-start">
          <SharePriceCardSkeleton />
        </VStack>
      </GridItem>
    </SimpleGrid>
  </FullContentWrapper>
);
