import { Form } from "formik";
import * as Yup from "yup";

import { Box, GridItem, SimpleGrid, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { AutosizeTextAreaInput, FormikQL, TextInput } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  CurrentContextDocument,
  InstitutionEntityType,
  UpdateInstitutionMutationVariables,
  UserWithInstitutionFragment,
  useUpdateInstitutionMutation,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

import { EntityTypeInput } from "./EntityTypeInput";

// TODO move some of these into shared validations
const validationSchema = Yup.object().shape({
  entityType: Yup.string()
    .nullable()
    .oneOf(
      [
        InstitutionEntityType.Corporation,
        InstitutionEntityType.Partnership,
        InstitutionEntityType.Other,
      ],
      `Entity type is required`,
    ),
  streetAddress: Yup.string()
    .nullable()
    .required(`Street address is required`)
    .matches(/[a-zA-Z]/, { message: `Street address is invalid` }),
  caJurisdictionOfIncorporation: Yup.string()
    .nullable()
    .required(`Jurisdiction is required`),
  city: Yup.string()
    .required(`Required`)
    .matches(/[a-zA-Z]/, { message: `City is invalid` }),
  region: Yup.string()
    .required(`Required`)
    .matches(/[a-zA-Z]/, { message: `Invalid` }),
  postalCode: Yup.string()
    .required(`Required`)
    .matches(/[0-9]/, { message: `Invalid` }),
});

const initialValues = {
  entityType: null,
  streetAddress: ``,
  caJurisdictionOfIncorporation: ``,
  city: ``,
  region: ``,
  postalCode: ``,
};

interface InstitutionCanadaBuildProfileFormValues {
  readonly streetAddress: string;
  readonly entityType: InstitutionEntityType | null;
  readonly caJurisdictionOfIncorporation: string;
  readonly city: string;
  readonly region: string;
  readonly postalCode: string;
}

const mapVariables = ({ institutionId }: UserWithInstitutionFragment) => ({
  ...values
}: InstitutionCanadaBuildProfileFormValues): UpdateInstitutionMutationVariables => ({
  institutionId: institutionId || ``,
  input: {
    ...values,
  },
});

const InstitutionCanadaBuildProfilePage = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useUpdateInstitutionMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => routeToNextStep();

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`updateInstitution`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables(actor)}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <VStack spacing={5} mt={5}>
            <Text mb={3} textAlign="center">
              What is the legal entity for your institution?
            </Text>
            <EntityTypeInput />
            {values.entityType && (
              <>
                <TextInput
                  name="caJurisdictionOfIncorporation"
                  label="Jurisdiction of incorporation/registration"
                />
                <AutosizeTextAreaInput
                  name="streetAddress"
                  label="Street address of headquarters/main office"
                  minRows={2}
                />
                <SimpleGrid columns={4} columnGap={2.5} w="full">
                  <GridItem colSpan={2}>
                    <TextInput name="city" label="City" />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <TextInput name="region" label="Province" />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <TextInput name="postalCode" label="Postal Code" />
                  </GridItem>
                </SimpleGrid>
              </>
            )}
            <Box py={8}>
              <HiiveButton
                type="submit"
                size="xl"
                variant="rounded-solid-salmon"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                sentryLabel="[InstitutionBuildProfilePage] Next"
              >
                Next
              </HiiveButton>
            </Box>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

export default withCurrentActor(InstitutionCanadaBuildProfilePage);
