import Link from "next/link";

import {
  Card,
  CardBody,
  Divider,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";

import { Hiive50BadgeShort, WatchlistButton } from "@/components/common";
import { CustomTooltip } from "@/components/form";
import { BrowseCompaniesPageCompanyCardCompanyFragment } from "@/gql";
import { useCurrentActor, useObscureCompanyInfo } from "@/hooks";
import { useHiive50Enabled } from "@/hooks/featureFlags";

import CompanyActivity from "./CompanyActivity";
import PricingBlock from "./PricingBlock";

const Tag = ({
  name,
  showInfo,
}: {
  readonly name: string;
  readonly showInfo: boolean;
}) => (
  <Flex
    mr={1}
    mb={1}
    bg="skyBlue.50"
    px={1.5}
    h={7}
    align="center"
    borderRadius="sm"
  >
    <Text
      textStyle={showInfo ? `heading-3xs` : `sensitive`}
      color="skyBlue.1300"
    >
      {name}
    </Text>
  </Flex>
);

const Description = ({
  company,
}: {
  readonly company: BrowseCompaniesPageCompanyCardCompanyFragment;
}) => {
  const actor = useCurrentActor();
  const { showInfo, message } = useObscureCompanyInfo(actor);

  const sanitizedInvestors = company.investors.filter(({ name }) => !!name);

  return (
    <Flex direction="column" gap={4}>
      <Text textStyle="text-sm" noOfLines={3}>
        {company.description}
      </Text>
      {company.industries.length > 0 && (
        <VStack align="flex-start" w="full">
          <Text
            w={{ base: 36, sm: 40 }}
            whiteSpace="nowrap"
            mr={{ base: 2, sm: 3 }}
            textStyle="heading-3xs"
            textTransform="uppercase"
          >
            Industries
          </Text>
          <CustomTooltip tooltipContent={message}>
            <Flex flexWrap="wrap" w="full">
              {company.industries.map(({ name, id }) => (
                <Tag showInfo={showInfo} key={id} name={name} />
              ))}
            </Flex>
          </CustomTooltip>
        </VStack>
      )}
      {sanitizedInvestors.length > 0 && (
        <VStack align="flex-start" w="full">
          <Text
            w={{ base: 36, sm: 40 }}
            whiteSpace="nowrap"
            mr={{ base: 2, sm: 3 }}
            textStyle="heading-3xs"
            textTransform="uppercase"
          >
            Investors
          </Text>
          <CustomTooltip tooltipContent={message}>
            <Flex flexWrap="wrap" w="full" maxH={128} overflowY="clip">
              {sanitizedInvestors.map(({ name, id }) => (
                <Tag showInfo={showInfo} key={id} name={name} />
              ))}
            </Flex>
          </CustomTooltip>
        </VStack>
      )}
    </Flex>
  );
};

const CompanyCard = ({
  company,
}: {
  readonly company: BrowseCompaniesPageCompanyCardCompanyFragment;
}) => {
  const isHiive50Enabled = useHiive50Enabled();
  const isActiveOnHiive50Index = company.activeOnHiiveFiftyIndex;
  const showH50Badge = isHiive50Enabled && isActiveOnHiive50Index;

  return (
    <Link
      href={`/companies/${company.id}`}
      style={{ width: `100%`, height: `100%` }}
    >
      <Card
        cursor="pointer"
        w="full"
        h="full"
        _hover={{ boxShadow: `lg`, transform: `scale(1.005)` }}
        transition="all 0.2s"
      >
        <CardBody>
          <Flex direction="column" gap={4}>
            <Flex justify="space-between" alignItems="center" w="full">
              <HStack spacing={3}>
                {company.logoUrl && (
                  <Box minW={6}>
                    <Image
                      h={{ base: 5, sm: 6 }}
                      src={company.logoUrl}
                      alt={company.name}
                    />
                  </Box>
                )}
                <Text textStyle="heading-3xl">{company.name}</Text>
              </HStack>

              <HStack>
                {showH50Badge && (
                  <Link href="/hiive50">
                    <Hiive50BadgeShort />
                  </Link>
                )}
                <WatchlistButton
                  alignSelf="flex-start"
                  flex="none"
                  isCollapsed
                  companyId={company.id}
                />
              </HStack>
            </Flex>

            <PricingBlock company={company} />
            <CompanyActivity company={company} />
            <Divider />
            <Description company={company} />
          </Flex>
        </CardBody>
      </Card>
    </Link>
  );
};

export default CompanyCard;
