import { Form } from "formik";
import * as Yup from "yup";

import { useRouter } from "next/router";

import { Text, ModalBody, Show } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormikQL } from "@/components/form";
import {
  WithdrawBidModalBidFragment,
  WithdrawBidMutation,
  useWithdrawBidMutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import { makeUrl } from "@/utils";

interface WithdrawBidModalFormValues {
  readonly bidId: string;
}

const validationSchema = Yup.object().shape({
  bidId: Yup.string().nullable().required(`Required`),
});

const mapVariables = ({ bidId }: WithdrawBidModalFormValues) => ({
  bidId,
});

const createInitialValues = (bid: WithdrawBidModalBidFragment) => ({
  bidId: bid.id,
});

interface WithdrawBidModalProps {
  readonly bid: WithdrawBidModalBidFragment;
}

const WithdrawBidModal = ({ bid }: WithdrawBidModalProps) => {
  const router = useRouter();
  const { successToast } = useCustomToast();

  const { closeModal } = useModal();

  const mutation = useWithdrawBidMutation();

  const onSuccess = (response: WithdrawBidMutation) => {
    successToast(`Bid withdrawn.`);
    closeModal();

    if (!response.withdrawBid.bid) return;

    router.push(makeUrl(response.withdrawBid.bid.listing));
  };

  const initialValues = createInitialValues(bid);

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        mutation={mutation}
        mutationNames={[`withdrawBid`]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        mapVariables={mapVariables}
        onSuccess={onSuccess}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <HiiveModalHeader>
              Withdraw Bid{` `}
              <Text as="span">{bid.displayId}</Text>
            </HiiveModalHeader>
            <ModalBody>
              Are you sure? You can&apos;t undo this action afterwards.
            </ModalBody>
            <HiiveModalFooter>
              <Show above="md" ssr={false}>
                <HiiveCancelButton
                  sentryLabel="[WithdrawBid/Cancel]"
                  onCancel={closeModal}
                />
              </Show>
              <HiiveSubmitButton
                sentryLabel="[WithdrawBid/Submit]"
                isLoading={isSubmitting}
                submitText="Withdraw"
                type="submit"
              />
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default WithdrawBidModal;
