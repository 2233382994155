import { useAuthUserOrNull } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import useMfaDevices from "./useMfaDevices";

interface PreDisableSmsMfaResponse {
  readonly errors: string[];
  readonly otcToken: string;
  readonly phoneNumber: string;
}

const usePreDisableSmsMfa = () => {
  const { t } = useTranslation();
  const user = useAuthUserOrNull();
  const getMfaDevices = useMfaDevices();
  const genericErrorMessage = t(`failed_to_predisable_mfa`);

  return useCallback(async () => {
    try {
      if (!user) {
        throw new Error(t(`invalid_request`));
      }

      const mfaDevices = await getMfaDevices();

      if (mfaDevices instanceof Error) {
        throw new Error(mfaDevices.message);
      }

      const { phones } = mfaDevices;

      if (phones.length === 0) {
        throw new Error(t(`no_mfa_device_found`));
      }

      const [{ id: deviceId }] = phones;

      const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/sms/${deviceId}/disable`;

      const response = await fetch(API_URL, {
        method: `POST`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      const data: PreDisableSmsMfaResponse = await response.json();

      if (!response.ok) {
        if (data.errors.length > 0) {
          const [message] = data.errors;
          const errorMessage = message || genericErrorMessage;
          throw new Error(errorMessage);
        }
      }

      return { data, error: null };
    } catch (error) {
      Sentry.captureException(error);
      return { data: null, error };
    }
  }, [t, user]);
};

export default usePreDisableSmsMfa;
