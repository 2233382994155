import { Form } from "formik";
import { AnimatePresence } from "framer-motion";

import { HiiveModalContentWrapper } from "@/components/common";
import { AnimatedModalStep, StepFormikQL } from "@/components/form";
import {
  AcceptBidModalBidFragment,
  AcceptBidMutationVariables,
  BidPageBidByIdDocument,
  BidPageMyActivityDocument,
  useAcceptBidMutation,
} from "@/gql";
import { useStepRouter } from "@/hooks";

import {
  AcceptBidConfirmationModal,
  AcceptBidSuccessModal,
  AcceptBidSequenceModalStepFormContext,
  StepKeys,
  stepKeys,
} from "./steps";

const createMapVariables = (
  bid: AcceptBidModalBidFragment,
) => (): AcceptBidMutationVariables => ({
  bidId: bid.id,
});

const AcceptBidSequenceModal = ({
  bid,
}: {
  readonly bid: AcceptBidModalBidFragment;
}) => {
  const mutation = useAcceptBidMutation({
    refetchQueries: [BidPageMyActivityDocument, BidPageBidByIdDocument],
  });

  const mapVariables = createMapVariables(bid);

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [stepKeys.acceptBidConfirmation, stepKeys.acceptBidSuccess],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  return (
    <HiiveModalContentWrapper>
      <StepFormikQL
        stepRouter={stepRouter}
        mutation={mutation}
        mutationNames={[`acceptBid`]}
        initialValues={{}}
        mapVariables={mapVariables}
        context={AcceptBidSequenceModalStepFormContext}
      >
        {({ formikProps }) => (
          <Form>
            <AnimatePresence mode="wait" initial={false}>
              {stepKeys.acceptBidConfirmation === currentStepKey && (
                <AnimatedModalStep key={stepKeys.acceptBidConfirmation}>
                  <AcceptBidConfirmationModal
                    stepRouter={stepRouter}
                    bid={bid}
                    {...formikProps}
                  />
                </AnimatedModalStep>
              )}
              {stepKeys.acceptBidSuccess === currentStepKey && (
                <AnimatedModalStep key={stepKeys.acceptBidSuccess}>
                  <AcceptBidSuccessModal />
                </AnimatedModalStep>
              )}
            </AnimatePresence>
          </Form>
        )}
      </StepFormikQL>
    </HiiveModalContentWrapper>
  );
};

export default AcceptBidSequenceModal;
