import {
  forwardRef,
  ReactNode,
  useState,
  useImperativeHandle,
  ForwardedRef,
} from "react";

import { DropdownControls } from "./types";

type Props = {
  readonly children: ({
    onClickDropdown,
    showDropdown,
  }: {
    readonly showDropdown: boolean;
    readonly onClickDropdown: () => void;
  }) => ReactNode;
  readonly show?: boolean;
};

const HeadlessDropdown = forwardRef(
  ({ children, show }: Props, ref: ForwardedRef<DropdownControls>) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(show || false);

    useImperativeHandle(
      ref,
      () => ({
        setShowDropdown,
      }),
      [],
    );

    const onClickDropdown = () => setShowDropdown(!showDropdown);

    return <>{children({ showDropdown, onClickDropdown })}</>;
  },
);

export default HeadlessDropdown;
