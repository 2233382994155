import { FormikValues } from "formik";
import invert from "lodash/invert";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import {
  SetStateAction,
  useEffect,
  useState,
  useCallback,
  useMemo,
  ChangeEvent,
} from "react";
import { useTranslation } from "react-i18next";

import {
  Text,
  Stack,
  Box,
  SimpleGrid,
  GridItem,
  RadioProps,
} from "@chakra-ui/react";

import { MarkdownDocument } from "@/components/common";
import { RadioCardInput, RadioCard, TextInput } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  InvestmentGoalOption,
  InvestmentGoalQuestion,
  InvestorStatus,
  useInvestmentGoalQuestionGroupsQuery,
  UserWithInstitutionFragment,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import styles from "@/styles/suitability-markdown-styles.module.css";
import { getUserCountry, getIsInstitutionUser } from "@/utils";

import SuitabilityModifyFieldDescription from "./SuitabilityModifyFieldDescription";

type SuitabilityModifyRadioCardOption = {
  readonly value: string;
  readonly label: string;
  readonly description?: string | null;
  readonly custom: boolean;
  readonly nextQuestionIds?: readonly string[];
  readonly order: number;
};

type SuitabilityQuestionsUpdate = (
  questionId: string,
  ids: readonly string[],
  removalIds: readonly string[],
) => void;

const getQuestionsToRemove = (
  value: string,
  avoidanceIds: readonly string[],
  options: readonly {
    readonly value: string;
    readonly label: string;
    readonly custom: boolean;
    readonly nextQuestionIds?: readonly string[] | undefined;
  }[],
) =>
  options
    .filter((option) => value !== option.value)
    .flatMap(({ nextQuestionIds }) => nextQuestionIds)
    .filter((option): option is string => !!option)
    .filter((nextQuestionId) => !avoidanceIds.includes(nextQuestionId));

const mapOptionsToRadioCardOptions = (
  options: readonly InvestmentGoalOption[],
) =>
  options
    .filter((option): option is InvestmentGoalOption => !!option)
    .sort((a, b) => a.order - b.order)
    .map(({ text, id, description, custom, nextQuestionIds, order }) => ({
      label: text ?? ``,
      value: id ?? ``,
      description,
      custom: custom ?? false,
      nextQuestionIds:
        nextQuestionIds?.filter(
          (nextQuestionId): nextQuestionId is string => !!nextQuestionId,
        ) ?? [],
      order: order || 0,
    }));

const SuitabilityModifyFieldsOption = ({
  options,
  option,
  questionId,
  values,
  getRadioProps,
  onChange,
  updateQuestions,
}: {
  readonly options: readonly SuitabilityModifyRadioCardOption[];
  readonly option: SuitabilityModifyRadioCardOption;
  readonly questionId: string;
  readonly values: FormikValues;
  readonly getRadioProps: (options: { readonly value: string }) => RadioProps;
  readonly onChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  readonly updateQuestions: SuitabilityQuestionsUpdate;
}) => {
  const { label, value, custom, nextQuestionIds } = option;
  return custom ? (
    <TextInput
      name={`answers.${questionId}`}
      placeholder={label}
      w={{ base: `full`, lg: `50%` }}
      onBlur={({ target }) => {
        if (target.value)
          updateQuestions(
            questionId,
            nextQuestionIds ?? [],
            getQuestionsToRemove(value, nextQuestionIds ?? [], options),
          );
      }}
    />
  ) : (
    <Box mt={{ base: `0px !important`, lg: 6 }}>
      <RadioCard
        w={{ base: `full`, lg: 234 }}
        h="60px"
        boxProps={{
          mt: `0px !important`,
          w: `auto`,
        }}
        {...getRadioProps({ value })}
        isChecked={values.answers[questionId] === option.value}
        onChange={(event) => {
          onChange(event).then(() => {
            updateQuestions(
              questionId,
              nextQuestionIds ?? [],
              getQuestionsToRemove(value, nextQuestionIds ?? [], options),
            );
          });
        }}
      >
        {label}
      </RadioCard>
    </Box>
  );
};

const SuitabilityModifyFields = withCurrentActor(
  ({
    values,
    setValues,
    onQuestionsChange,
    actor,
    version = 2,
    initialAnswers,
  }: {
    readonly values: FormikValues;
    readonly setValues: (values: SetStateAction<FormikValues>) => void;
    readonly onQuestionsChange?: (questions: {
      readonly questions: readonly InvestmentGoalQuestion[];
    }) => void;
    readonly actor: UserWithInstitutionFragment;
    readonly version?: number;
    readonly initialAnswers?: { readonly answers: Record<string, string> };
  }) => {
    const { t } = useTranslation();

    const isDesktop = useIsDesktop();

    const { id: countryId } = getUserCountry(actor);
    const isInstitutionUser = getIsInstitutionUser(actor);

    const [initialQuestions, setInitialQuestions] = useState<
      readonly InvestmentGoalQuestion[]
    >();
    const [questions, setQuestions] = useState<
      readonly InvestmentGoalQuestion[]
    >();

    const suitabilityResponse = useInvestmentGoalQuestionGroupsQuery({
      variables: {
        investorType: isInstitutionUser
          ? InvestorStatus.Institutional
          : InvestorStatus.Individual,
        countryId,
        version,
      },
    });

    // This function is to keep the object tree in sync.
    // When a user answering a flow midway changes it to something else,
    // we would ideally want to remove their previous answers
    const updateAnswers = useCallback(
      (
        updatedQuestions: readonly InvestmentGoalQuestion[],
        currentQuestion: InvestmentGoalQuestion,
      ) => {
        const currentOrder = currentQuestion.order;
        const isCurrentQuestionDivergent = currentQuestion.options.some(
          (option) => !!option?.nextQuestionIds,
        );

        setValues((prevValues) => {
          const newValues = updatedQuestions
            // Get order of the current question to pop off anything that comes later
            ?.filter(({ order }) =>
              isCurrentQuestionDivergent ? order <= currentOrder : true,
            )
            ?.reduce((prevQuestions, { id }) => {
              const newItem = prevValues.answers?.[id];
              if (!newItem) return { ...prevQuestions };

              return {
                ...prevQuestions,
                [id]: prevValues.answers[id],
              };
            }, {} as Record<string, string>);

          return {
            ...prevValues,
            answers: newValues,
          };
        });
      },
      [setValues],
    );

    // This will check to see where in the flow a user should progress,
    // by adding and removing the nessesary questions to show the user
    const updateQuestions: SuitabilityQuestionsUpdate = useCallback(
      (questionId, ids, removalIds) =>
        setQuestions((prevQuestions) => {
          if (!prevQuestions) return prevQuestions;

          const questionsHashMap = keyBy(initialQuestions, `id`);
          const newQuestions = ids.map((id) => questionsHashMap[id]);

          const currentQuestion = questionsHashMap[questionId];
          const questionOrder = questionsHashMap[questionId].order;

          // This is to calculate items that dont need to be shown based on splits.
          // If the questionOrder is 1 and this order is 4, we know not to show items
          // 2 and 3
          const minPossibleOrder = Math.min(
            ...newQuestions.map(({ order }) => order),
          );

          // This is to pop ids that "shouldnt" be showing based on what the split is capible of rendering
          // If the number exceeds the max number of all the ids next in queue, it will pop the remainders
          // with higher values
          const lastPossibleOrder = Math.max(
            ...ids.map((id) => questionsHashMap[id]).map(({ order }) => order),
          );

          // TODO: there is likely a better way to impliment this
          const orderIsInfiniteAndBranches =
            !Number.isFinite(lastPossibleOrder) &&
            currentQuestion.options?.some(
              (option) => (option?.nextQuestionIds ?? []).length > 0,
            );

          // This is calculating the question orders to remove based on indexes that are
          // should not be shown
          const questionOrdersToRemove = Number.isFinite(minPossibleOrder)
            ? Array.from(
                { length: minPossibleOrder - questionOrder - 1 },
                (_, i) => i + questionOrder + 1,
              )
            : [];

          // These are all questions without additional filtering
          const newQuestionsWithUnaccountedSplits = uniq([
            ...prevQuestions,
            ...newQuestions,
          ]);

          // These are the final questions after all nessesary filters are applied
          const updatedQuestions = newQuestionsWithUnaccountedSplits
            .filter(({ order }) => !questionOrdersToRemove.includes(order))
            .filter(({ order }) => {
              if (!orderIsInfiniteAndBranches)
                return ids.length > 0 ? lastPossibleOrder >= order : true;
              return questionOrder >= order;
            })
            .filter(({ id }) => !removalIds.includes(id));

          // To keep the form values in sync
          updateAnswers(updatedQuestions, currentQuestion);

          return updatedQuestions;
        }),
      [initialQuestions, updateAnswers, setQuestions],
    );

    // Checks to see if an option is selected
    const checkOptionChecked = useCallback(
      (optionId: string) => {
        const items = values?.answers;

        if (!items) return false;

        return Object.keys(invert(items)).includes(optionId);
      },
      [values],
    );

    // Get the currently selected option within a question
    const getCurrentlyCheckedOption = useCallback(
      (options: readonly SuitabilityModifyRadioCardOption[]) => {
        const [option] = options.filter(({ value }) =>
          checkOptionChecked(value),
        );

        return option;
      },
      [checkOptionChecked],
    );

    // Get all currently selected options as a hashmap
    const currentlySelectedOptions = useMemo(() => {
      const options: Record<string, SuitabilityModifyRadioCardOption> =
        questions?.reduce(
          (
            prevOptions: Record<string, SuitabilityModifyRadioCardOption>,
            { id, options },
          ) => ({
            ...prevOptions,
            [id]: getCurrentlyCheckedOption(
              mapOptionsToRadioCardOptions(
                (options ?? []).filter(
                  (option): option is InvestmentGoalOption => !!option,
                ),
              ),
            ),
          }),
          {},
        ) ?? {};

      return options;
    }, [questions, getCurrentlyCheckedOption]);

    // Inital load. This will compute which questions need to be shown by default
    useEffect(() => {
      if (
        suitabilityResponse.data &&
        suitabilityResponse.data.investmentGoalQuestionGroups
      ) {
        const [group] = suitabilityResponse.data.investmentGoalQuestionGroups;

        const items =
          group?.questions.filter(
            (question): question is InvestmentGoalQuestion => !!question,
          ) ?? [];

        const defaultNextQuestionIds = items
          .filter((question) => !!question?.options)
          .flatMap(({ options }) =>
            options
              .filter((option): option is InvestmentGoalOption => !!option)
              .flatMap(({ nextQuestionIds }) => nextQuestionIds),
          );

        const nextQuestionIdsFromAnswers = Object.keys(
          initialAnswers?.answers ?? {},
        );

        setQuestions(
          uniq([
            ...items.filter(({ id }) => !defaultNextQuestionIds.includes(id)),
            ...items.filter(({ id }) =>
              nextQuestionIdsFromAnswers.includes(id),
            ),
          ]),
        );
        setInitialQuestions(items);
      }
    }, [
      suitabilityResponse,
      initialAnswers,
      setQuestions,
      setInitialQuestions,
    ]);

    // This is a callback function in the case that the current visible questions
    // need to be observed
    useEffect(() => {
      if (questions) onQuestionsChange?.({ questions });
    }, [questions, onQuestionsChange]);

    useEffect(() => {
      if (initialAnswers) setValues(initialAnswers);
    }, [setValues, initialAnswers]);

    if (!questions) return null;

    return (
      <>
        {questions
          // The slice is needed here since "technically" sort isnt allowed on readonly arrays
          // since it apparently mutates the original array
          .slice()
          .sort((a, b) => a.order - b.order)
          .map(
            ({
              id: questionId,
              text: questionText,
              options: questionOptions,
              description: questionDescription,
            }) => (
              <RadioCardInput<SuitabilityModifyRadioCardOption>
                name={`answers.${questionId}`}
                key={questionId}
                options={mapOptionsToRadioCardOptions(
                  questionOptions.filter(
                    (option): option is InvestmentGoalOption => !!option,
                  ),
                )}
                renderOptions={(
                  options,
                  { getRootProps, getRadioProps, setValueAsync },
                ) => (
                  <Box my={12}>
                    <div
                      className={styles[`suitability-question-text-markdown`]}
                    >
                      <MarkdownDocument markdown={questionText} />
                    </div>
                    {options.some(
                      (option) => !!option.description && !option.custom,
                    ) && (
                      <Text color="grey.800" mt={2}>
                        {t(`select_option_learn_more`)}
                      </Text>
                    )}

                    {questionDescription && (
                      <SuitabilityModifyFieldDescription
                        markdown={questionDescription}
                      />
                    )}

                    {isDesktop ? (
                      <Stack
                        w={{ base: `full` }}
                        flexDir={{ base: `column`, lg: `row` }}
                        gap={{ base: 1, lg: 3 }}
                        flexWrap="wrap"
                        mt={{ base: 2, lg: 4 }}
                        {...getRootProps()}
                      >
                        {options.map((option) => (
                          <Box
                            mt="0px !important"
                            h="full"
                            w={option.custom ? `full` : `auto`}
                            key={option.value}
                          >
                            <SuitabilityModifyFieldsOption
                              questionId={questionId}
                              options={options}
                              option={option}
                              values={values}
                              getRadioProps={getRadioProps}
                              onChange={setValueAsync}
                              updateQuestions={updateQuestions}
                            />
                          </Box>
                        ))}
                      </Stack>
                    ) : (
                      <SimpleGrid
                        columns={2}
                        columnGap={2}
                        rowGap={2}
                        mt={{ base: 2, lg: 4 }}
                        {...getRootProps()}
                      >
                        {options.map((option) => (
                          <GridItem
                            key={option.value}
                            mt="0px !important"
                            h="full"
                            w={option.custom ? `full` : `auto`}
                          >
                            <SuitabilityModifyFieldsOption
                              questionId={questionId}
                              options={options}
                              option={option}
                              values={values}
                              getRadioProps={getRadioProps}
                              onChange={setValueAsync}
                              updateQuestions={updateQuestions}
                            />
                          </GridItem>
                        ))}
                      </SimpleGrid>
                    )}

                    {(() => {
                      const currentOption =
                        currentlySelectedOptions[questionId];

                      if (!currentOption || !currentOption.description)
                        return null;

                      return (
                        <SuitabilityModifyFieldDescription
                          markdown={currentOption.description}
                        />
                      );
                    })()}
                  </Box>
                )}
              />
            ),
          )}
      </>
    );
  },
);
export default SuitabilityModifyFields;
