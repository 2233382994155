import { ArrowsInSimple } from "@phosphor-icons/react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  HStack,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image,
  VStack,
  IconButton,
} from "@chakra-ui/react";

import { HiiveButton, PoweredByBadge } from "@/components/common";
import { Chart, Legend, ChartHeader } from "@/components/companies";
import { useColors, useIsDesktop } from "@/hooks";

import { PricingChartCompany } from "./types";

export const PricingChartModal = ({
  company,
  isOpen,
  onClose,
}: {
  readonly company: PricingChartCompany;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}) => {
  const { t } = useTranslation();
  const isDesktopView = useIsDesktop();
  const [grey900] = useColors([`grey.900`]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent p={{ base: 4, sm: 9 }}>
        <HStack justifyContent="space-between">
          <HStack alignItems="end" spacing={{ base: 2, md: 4 }}>
            {company.logoUrl && (
              <Box minW={6} alignSelf="center">
                <Image
                  h={{ base: 6, md: 12 }}
                  src={company.logoUrl}
                  alt={company.name}
                />
              </Box>
            )}
            <VStack alignItems="start" spacing={0}>
              <Text textStyle="heading-4xl">{company.name}</Text>
              <HStack>
                <Text textStyle="text-md">{t(`historical_pricing`)}</Text>
                {isDesktopView && <PoweredByBadge />}
              </HStack>
            </VStack>
          </HStack>
          {isDesktopView ? (
            <HiiveButton
              rightIcon={<ArrowsInSimple size={24} color={grey900} />}
              variant="boxed-grey"
              onClick={onClose}
              sentryLabel="[PricingChartModal] Close Fullscreen"
            >
              {t(`close_fullscreen`)}
            </HiiveButton>
          ) : (
            <IconButton
              aria-label="Open Fullscreen"
              icon={<ArrowsInSimple size={24} color={grey900} />}
              variant="boxed-grey"
              onClick={onClose}
            />
          )}
        </HStack>
        <ModalBody
          border="none"
          px={0}
          py={9}
          h="full"
          display="flex"
          flexDirection="column"
        >
          <VStack alignItems="start" h="full" flexGrow="1">
            <ChartHeader
              availableTimeRanges={company.priceChartConfig.availableTimeRanges}
            />
            <Box h="96" w="full" flexGrow="1">
              <Chart modal />
            </Box>
            <Legend />
            <Text as="span" textStyle="text-xs">
              <Trans i18nKey="price_chart_note_collapsed" />
              <Trans i18nKey="price_chart_note_full" />
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
