import { Trans, useTranslation } from "react-i18next";

import { Text, VStack } from "@chakra-ui/react";

import { InternalLink, Note } from "@/components/common";

const FeesDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="flex-start" spacing={3}>
      <Note>{t(`fees_disclaimer`)}</Note>
      <Text textStyle="text-xs">
        <Trans
          i18nKey="unaccredited_seller_fee_disclaimer_terms_and_conditions"
          components={{
            italic: (
              <InternalLink
                target="_blank"
                textDecorationLine="underline"
                href="/terms-and-conditions"
              />
            ),
          }}
        />
      </Text>
    </VStack>
  );
};

export default FeesDisclaimer;
