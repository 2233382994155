import { HiiveModalContentWrapper } from "@/components/common";
import {
  PlaceBidSequenceModalCompanyFragment,
  PlaceBidSequenceModalListingFragment,
} from "@/gql";

import { PlaceBidSequenceModalAuthorization } from "./PlaceBidSequenceModalAuthorization";

const PlaceBidSequenceModal = ({
  company,
  initialListing,
}: {
  readonly company: PlaceBidSequenceModalCompanyFragment;
  readonly initialListing?: PlaceBidSequenceModalListingFragment;
}) => (
  <HiiveModalContentWrapper>
    <PlaceBidSequenceModalAuthorization
      company={company}
      initialListing={initialListing}
    />
  </HiiveModalContentWrapper>
);

export default PlaceBidSequenceModal;
