import { ActivityCard } from "@/components/common";
import {
  CompanyStandingBidCardBody,
  CompanyStandingBidCardHeader,
  CompanyStandingBidCardFooter,
} from "@/components/companies";
import {
  CompanyYourActivityStandingBidCardCompanyFragment,
  CompanyYourActivityStandingBidCardStandingBidFragment,
} from "@/gql";

const CompanyYourActivityStandingBidCard = ({
  standingBid,
  company,
}: {
  readonly standingBid: CompanyYourActivityStandingBidCardStandingBidFragment;
  readonly company: CompanyYourActivityStandingBidCardCompanyFragment;
}) => (
  <ActivityCard href={`/standing-bids/${standingBid.id}`}>
    <CompanyStandingBidCardHeader standingBid={standingBid} />
    <CompanyStandingBidCardBody standingBid={standingBid} company={company} />
    <CompanyStandingBidCardFooter standingBid={standingBid} />
  </ActivityCard>
);

export default CompanyYourActivityStandingBidCard;
