import { useAuthUserOrNull } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/devices`;

interface BaseDevice {
  id: string;
}

interface Authenticator extends BaseDevice {}
interface Phone extends BaseDevice {
  phoneNumber: string;
}

export interface MfaDevices {
  authenticators: Authenticator[];
  errors: string[];
  phones: Phone[];
}

const useMfaDevices = () => {
  const { t } = useTranslation();
  const user = useAuthUserOrNull();
  const genericErrorMessage = t(`failed_to_get_mfa_devices`);

  return useCallback(async () => {
    try {
      if (!user) {
        throw new Error(t(`invalid_request`));
      }

      const response = await fetch(API_URL, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      const data: MfaDevices = await response.json();

      if (!response.ok) {
        if (data.errors.length > 0) {
          const [message] = data.errors;
          const errorMessage = message || genericErrorMessage;
          throw new Error(errorMessage);
        }
      }

      return data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }, [t, user]);
};

export default useMfaDevices;
