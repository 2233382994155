/* eslint-disable functional/immutable-data */
import { useCallback, useEffect, useRef } from "react";

import { useRouter } from "next/router";

const useIsChangingRoute = () => {
  const isChangingRoute = useRef<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    router.events.on(`routeChangeStart`, () => {
      isChangingRoute.current = true;
    });
    router.events.on(`routeChangeComplete`, () => {
      isChangingRoute.current = false;
    });

    return () => {
      router.events.off(`routeChangeStart`, () => {
        isChangingRoute.current = true;
      });
      router.events.off(`routeChangeComplete`, () => {
        isChangingRoute.current = false;
      });
    };
  }, []);

  return useCallback(() => isChangingRoute.current, []);
};

export default useIsChangingRoute;
