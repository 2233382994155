import { VStack } from "@chakra-ui/react";

import {
  DiscussionThreadCard,
  MessageCounterpartyCard,
} from "@/components/common";
import { TransactingEntitySellerCard } from "@/components/postings";
import {
  BankAccountRequestStatus,
  BidPageSellerActivityBidFragment,
  TransactionState,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  useMultiEntityExperienceEnabled,
  useSellerExternalAccountEnabled,
} from "@/hooks/featureFlags";
import { getAvailableBidActions } from "@/utils";

import ModernTreasuryAccountCard from "./modern-treasury/ModernTreasuryAccountCard";

export const SellerActivity = ({
  actor,
  bid,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly bid: BidPageSellerActivityBidFragment;
}) => {
  const transactionAwaitingClosing =
    bid.transaction?.state &&
    [
      TransactionState.InReview,
      TransactionState.BidAccepted,
      TransactionState.AwaitingClosing,
      TransactionState.ClosedFeePending,
      TransactionState.IssuerApproved,
      TransactionState.IssuerPendingApproval,
    ].includes(bid.transaction?.state);
  const isMultiEntityExperienceEnabled = useMultiEntityExperienceEnabled();

  const showTransactingEntityCard =
    isMultiEntityExperienceEnabled &&
    transactionAwaitingClosing &&
    !actor.isHiiveUser;

  const {
    canRequestDiscussion,
    canMessageCounterparty,
  } = getAvailableBidActions(bid);
  const isSEAEnabled = useSellerExternalAccountEnabled();
  const shouldShowAccountCollection =
    bid.transaction?.sellerBankAccountRequestStatus &&
    [
      BankAccountRequestStatus.Requested,
      BankAccountRequestStatus.Collected,
    ].includes(bid.transaction?.sellerBankAccountRequestStatus);

  if (canRequestDiscussion) {
    return <MessageCounterpartyCard bidId={bid.id} topic={bid.listing} />;
  }
  const isInstitution = !!actor.institutionId;

  return (
    <VStack w="full" spacing={4}>
      {showTransactingEntityCard && (
        <TransactingEntitySellerCard
          displayId={bid.displayId}
          entities={
            isInstitution ? actor.institution?.entities : actor.entities
          }
          isInstitution={isInstitution}
          transaction={bid?.transaction}
        />
      )}
      {isSEAEnabled && !!shouldShowAccountCollection && !!bid.transaction && (
        <ModernTreasuryAccountCard transaction={bid.transaction} />
      )}
      {canMessageCounterparty && !!bid.discussion && (
        <DiscussionThreadCard discussion={bid.discussion} />
      )}
    </VStack>
  );
};
