import { RadioGroup } from "@chakra-ui/react";

import { AccreditationRadioInputProps } from "./types";

const AccreditationRadioInput = ({
  children,
  onChange,
  value,
  ...props
}: AccreditationRadioInputProps) => (
  <RadioGroup onChange={onChange} value={value} {...props}>
    {children}
  </RadioGroup>
);

export default AccreditationRadioInput;
