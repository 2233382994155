import { curveLinear } from "@visx/curve";
import { ParentSize } from "@visx/responsive";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import {
  XYChart,
  Axis,
  LineSeries,
  DataProvider,
  GlyphSeries,
  AreaSeries,
  Grid,
} from "@visx/xychart";
import { useCallback, useContext, useRef, useState } from "react";

import { Box, useBreakpointValue } from "@chakra-ui/react";

import {
  AreaGradient,
  formatDateTick,
  isDateNotInPresentYear,
  SkeletonChart,
  TooltipCrosshair,
  useTooltipHandler,
} from "@/components/common";
import {
  shouldShowAreaForIndexPrice,
  getRange,
  formatYTick,
  ChartDailyPriceData,
  getSeriesColor,
  TooltipContent,
  GlyphRenderer,
  GlyphTypes,
  ChartSeriesKey,
  TooltipGlyphs,
} from "@/components/companies";
import { useColors } from "@/hooks";

import { PricingChartContext } from "./PricingChartProvider";

const margin = { top: 20, right: 42, bottom: 20, left: 6 };

export const Chart = ({ modal = false }: { readonly modal?: boolean }) => {
  const [xPosition, setXPosition] = useState<number>(0);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [grey50, grey500] = useColors([`grey.50`, `grey.500`]);

  const isMobileView = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    { ssr: false },
  );

  const { selectedIndicators, data, loading, startDate } = useContext(
    PricingChartContext,
  );

  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal(
    {
      scroll: true,
      detectBounds: true,
    },
  );

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<ChartDailyPriceData | null>({
    // initial tooltip state
    tooltipOpen: false,
    tooltipLeft: 0,
    tooltipTop: 0,
    tooltipData: null,
  });

  const showYearInXTick = isDateNotInPresentYear(startDate);
  const showArea = shouldShowAreaForIndexPrice(selectedIndicators);

  const range = getRange(data);

  const xAccessor = useCallback(
    (d: ChartDailyPriceData) => (d ? new Date(d.day) : new Date()),
    [],
  );

  const handleTooltip = useTooltipHandler<ChartDailyPriceData>({
    tooltipRef,
    containerBounds,
    showTooltip,
    setXPosition,
  });

  const renderLineSeries = useCallback(
    (dataKey: ChartSeriesKey, additionalProps = {}) => (
      <LineSeries
        // eslint-disable-next-line functional/prefer-readonly-type
        data={data as ChartDailyPriceData[]}
        dataKey={dataKey}
        xAccessor={xAccessor}
        yAccessor={(d) => d[dataKey]}
        colorAccessor={getSeriesColor}
        {...additionalProps}
      />
    ),
    [data, xAccessor],
  );

  const renderGlyphSeries = useCallback(
    (dataKey: ChartSeriesKey, glyphType: GlyphTypes, additionalProps = {}) => (
      <GlyphSeries
        // eslint-disable-next-line functional/prefer-readonly-type
        data={data as ChartDailyPriceData[]}
        dataKey={dataKey}
        xAccessor={xAccessor}
        yAccessor={(d) => {
          const value = d[dataKey];
          if (Array.isArray(value) && value.length > 0) {
            return value[0];
          }
          return null;
        }}
        {...additionalProps}
        renderGlyph={(data) => (
          <GlyphRenderer glyphType={glyphType} data={data} dataKey={dataKey} />
        )}
      />
    ),
    [data, xAccessor],
  );

  return (
    <ParentSize>
      {({ width, height }) =>
        loading ? (
          <SkeletonChart />
        ) : (
          <Box ref={containerRef}>
            <DataProvider
              xScale={{ type: `time`, nice: false }}
              yScale={{
                type: `linear`,
                domain: range,
                zero: false,
                nice: true,
              }}
            >
              <XYChart
                height={height}
                width={width}
                margin={margin}
                onPointerMove={handleTooltip}
                onPointerDown={handleTooltip}
                onPointerOut={() => (isMobileView ? null : hideTooltip())}
                pointerEventsDataKey="all" // no events triggered without this
              >
                <Grid numTicks={6} lineStyle={{ stroke: grey50 }} />
                <Axis
                  orientation="bottom"
                  numTicks={6}
                  stroke={grey50}
                  hideTicks
                  tickFormat={(value) => formatDateTick(value, showYearInXTick)}
                  tickLabelProps={() => ({
                    fontSize: isMobileView ? 8 : 10,
                    fill: grey500,
                    textAnchor: `start`,
                  })}
                />
                <Axis
                  orientation="right"
                  numTicks={6}
                  stroke={grey50}
                  hideTicks
                  tickFormat={formatYTick}
                  tickLabelProps={() => ({
                    fontSize: isMobileView ? 8 : 10,
                    fill: grey500,
                  })}
                />
                {/* ordering by business requirements */}
                {/* items drawn first are rendered lower - are less important  */}
                {/* posted asks */}
                {renderGlyphSeries(
                  `postedAsksShareTypePreferred`,
                  GlyphTypes.InvertedTriangle,
                )}
                {renderGlyphSeries(
                  `postedAsksShareTypeCommon`,
                  GlyphTypes.InvertedTriangle,
                )}
                {renderGlyphSeries(
                  `postedAsksTransferTypeIndirect`,
                  GlyphTypes.InvertedTriangle,
                )}
                {renderGlyphSeries(
                  `postedAsksTransferTypeDirect`,
                  GlyphTypes.InvertedTriangle,
                )}

                {renderGlyphSeries(`postedAsks`, GlyphTypes.InvertedTriangle)}

                {/* posted bids */}
                {renderGlyphSeries(
                  `postedBidsShareTypePreferred`,
                  GlyphTypes.Triangle,
                )}
                {renderGlyphSeries(
                  `postedBidsShareTypeCommon`,
                  GlyphTypes.Triangle,
                )}
                {renderGlyphSeries(
                  `postedBidsTransferTypeIndirect`,
                  GlyphTypes.Triangle,
                )}
                {renderGlyphSeries(
                  `postedBidsTransferTypeDirect`,
                  GlyphTypes.Triangle,
                )}

                {renderGlyphSeries(`postedBids`, GlyphTypes.Triangle)}

                {/* price lines */}
                {renderLineSeries(`lastRoundPrice`, {
                  strokeWidth: 1,
                  strokeDasharray: `5,5`,
                })}
                {/* a workaround for safari when going from fullscreen to normal */}
                <AreaGradient id={`${modal && `modal`}-area-gradient`} />
                {renderLineSeries(`indexPriceShareTypePreferred`)}
                {renderLineSeries(`indexPriceShareTypeCommon`)}
                {renderLineSeries(`indexPriceTransferTypeIndirect`)}
                {renderLineSeries(`indexPriceTransferTypeDirect`)}

                {showArea ? (
                  <AreaSeries
                    // eslint-disable-next-line functional/prefer-readonly-type
                    data={data as ChartDailyPriceData[]}
                    dataKey="indexPrice"
                    xAccessor={xAccessor}
                    yAccessor={(d) => d.indexPrice}
                    curve={curveLinear}
                    fill={`url(#${modal && `modal`}-area-gradient)`}
                    lineProps={{
                      stroke: getSeriesColor(`indexPrice`),
                      strokeWidth: 2,
                    }}
                  />
                ) : (
                  renderLineSeries(`indexPrice`)
                )}
                {/* accepted bids */}
                {renderGlyphSeries(
                  `acceptedBidsShareTypePreferred`,
                  GlyphTypes.Dot,
                )}
                {renderGlyphSeries(
                  `acceptedBidsShareTypeCommon`,
                  GlyphTypes.Dot,
                )}

                {renderGlyphSeries(
                  `acceptedBidsTransferTypeIndirect`,
                  GlyphTypes.Dot,
                )}
                {renderGlyphSeries(
                  `acceptedBidsTransferTypeDirect`,
                  GlyphTypes.Dot,
                )}
                {renderGlyphSeries(`acceptedBids`, GlyphTypes.Dot)}
                {tooltipOpen && (
                  <>
                    <TooltipCrosshair
                      fromX={xPosition}
                      fromY={margin.top}
                      toX={xPosition}
                      toY={height - margin.bottom}
                    />
                    {tooltipData && (
                      <TooltipGlyphs tooltipData={tooltipData} x={xPosition} />
                    )}
                    <TooltipInPortal
                      left={tooltipLeft}
                      top={tooltipTop}
                      style={{
                        ...defaultStyles,
                        zIndex: modal ? 1400 : `auto`,
                        backgroundColor: `rgba(255,255,255, 0.85)`,
                      }}
                    >
                      <Box ref={tooltipRef}>
                        {tooltipData && (
                          <TooltipContent tooltipData={tooltipData} />
                        )}
                      </Box>
                    </TooltipInPortal>
                  </>
                )}
              </XYChart>
            </DataProvider>
          </Box>
        )
      }
    </ParentSize>
  );
};
