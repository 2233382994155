import isNil from "lodash/isNil";

import { Flex, FormLabel, Input, Text } from "@chakra-ui/react";

const FixedValueInput = ({
  label,
  name,
  fixedValue,
  subLabel,
}: {
  readonly label: string;
  readonly name?: string;
  readonly fixedValue: string | number;
  readonly subLabel?: string | null;
}) => (
  <Flex direction="column">
    <FormLabel mb={!isNil(subLabel) ? 0 : 1.5} htmlFor={name}>
      {label}
    </FormLabel>
    {!isNil(subLabel) && (
      <Text textStyle="text-sm" mb={1.5}>
        {subLabel}
      </Text>
    )}
    <Input
      name={name}
      value={fixedValue}
      isDisabled
      px={0}
      borderColor="transparent"
      _disabled={{ opacity: 1 }}
      _hover={{ opacity: 1 }}
    />
  </Flex>
);

export default FixedValueInput;
