import { useField } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Text, VStack } from "@chakra-ui/react";

import { CustomRadio } from "@/components/common";
import {
  AccreditationRadioCardInput,
  AccreditationRadioInput,
  CustomRadioTextArea,
} from "@/components/form";
import { QuestionFormCard } from "@/components/onboarding";
import { toBool } from "@/utils";

export const USInstitutionAccreditationFields = () => {
  const { t } = useTranslation();
  const [_field, _, { setValue: setAnswers }] = useField(`answers`);

  const [radioValue, setRadioValue] = useState<string | undefined>();
  const [otherCriteriaText, setOtherCriteriaText] = useState<string>(``);
  const [isQib, setIsQib] = useState<string>(`true`);

  useEffect(() => {
    setAnswers([
      {
        key: `us-institutional-qib`,
      },
    ]);
  }, []);

  const onChangeRadioValue = (value?: string) => {
    setRadioValue(value);

    if (!value) {
      setAnswers([]);
      return;
    }

    if (value === `us-institutional-other-criteria`) {
      setAnswers([{ key: value, text: otherCriteriaText }]);
      return;
    }

    setAnswers([{ key: value }]);
  };

  const onChangeOtherCriteriaText = (value: string) => {
    setOtherCriteriaText(value);
    if (radioValue !== `us-institutional-other-criteria`) return;
    setAnswers([{ key: `us-institutional-other-criteria`, text: value }]);
  };

  const onChangeIsQib = (value: string) => {
    setRadioValue(``);
    setIsQib(value);
    if (!toBool(value)) {
      setAnswers([]);
      return;
    }
    setAnswers([
      {
        key: `us-institutional-qib`,
      },
    ]);
  };

  return (
    <>
      <Text mb={3} textAlign="center">
        {t(`firm_fund_must_be_accredited`)}
      </Text>
      <AccreditationRadioCardInput
        label={t(`is_qualified_qib`)}
        tooltipContent={t(`qualified_institutional_buyer`)}
        value={isQib}
        dataTestid="accreditation-question"
        href="https://www.law.cornell.edu/cfr/text/17/230.144A"
        onChange={onChangeIsQib}
        options={[
          { label: t(`yes`), value: `true` },
          { label: t(`no`), value: `false` },
        ]}
      />
      {!toBool(isQib) && (
        <QuestionFormCard
          dataTestId="accreditation-criteria"
          text={t(`firm_fund_must_be_accredited_and_how`)}
        >
          <AccreditationRadioInput
            value={radioValue}
            onChange={onChangeRadioValue}
          >
            <VStack alignItems="flex-start">
              <CustomRadio value="us-institutional-registered-adviser">
                {t(`us_institutional_registered_adviser`)}
              </CustomRadio>
              <CustomRadio value="us-institutional-assets-investments">
                {t(`us_institutional_assets_investments`)}
              </CustomRadio>
              <CustomRadio value="us-institutional-family-office">
                {t(`us_institutional_family_office`)}
              </CustomRadio>
              <CustomRadio value="us-institutional-owners-accredited">
                {t(`us_institutional_owners_accredited`)}
              </CustomRadio>
              <CustomRadio value="us-institutional-bank-savings-insurance">
                {t(`us_institutional_bank_savings_insurance`)}
              </CustomRadio>
              <CustomRadio value="us-institutional-not-accredited">
                {t(`us_institutional_not_accredited`)}
              </CustomRadio>
              <CustomRadioTextArea
                textAreaValue={otherCriteriaText}
                onTextChange={onChangeOtherCriteriaText}
                placeholder={t(`description`)}
                textAreaLabel={t(`please_describe`)}
                selectedRadioValue={radioValue}
                radioValue="us-institutional-other-criteria"
              >
                {t(`us_institutional_other_criteria`)}
              </CustomRadioTextArea>
            </VStack>
          </AccreditationRadioInput>
        </QuestionFormCard>
      )}
    </>
  );
};
