import dayjs from "dayjs";

export const formatDateTick = (tickValue: string, showYear = false) =>
  `${dayjs(tickValue).format(showYear ? `MMM YY` : `MMM`)}`;

export const isDateNotInPresentYear = (date: string | Date): boolean => {
  const startYear = dayjs(date).year();
  const currentYear = dayjs().year();
  return startYear !== currentYear;
};
