import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Card, CardBody, HStack, Text } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  BankAccountRequestStatus,
  UserWithInstitutionFragment,
  useTransactionOnSellerBankAccountCreatedSubscription,
  TransactionPageTransactionByIdDocument,
  BidPageSellerActivityBidTransactionFragment,
  useUpdateTransactionSellerBankAccountIdMutation,
  ModernTreasuryVerifyAccountCardBankAccountFragment,
  BankAccountVerificationStatus,
} from "@/gql";

import ModernTreasuryConnectAccountCard from "./ModernTreasuryConnectAccountCard";
import ModernTreasuryVerifyAccountCard from "./ModernTreasuryVerifyAccountCard";
import ModernTreasuryVeryAccountCardContainer from "./ModernTreasuryVerifyAccountCardContainer";
import SelectBankAccountCard from "./SelectBankAccountCard";

/*
 * Loading state shown when we are waiting on webhooks to update our server.
 */
const PendingServerUpdateCard = () => {
  const { t } = useTranslation();

  return (
    <ModernTreasuryVeryAccountCardContainer>
      <CardBody>
        <Card variant="flat">
          <CardBody as={HStack} justifyContent="center" w="full">
            <Text textStyle="heading-md" color="grey.600">
              {t(`submitting_your_details`)}
            </Text>
          </CardBody>
        </Card>
      </CardBody>
    </ModernTreasuryVeryAccountCardContainer>
  );
};

const ModernTreasuryAccountCard = withCurrentActor(
  ({
    transaction,
    actor,
  }: {
    readonly transaction: BidPageSellerActivityBidTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const router = useRouter();
    const transactionId = router.query.id as string;

    const apolloClient = useApolloClient();

    const [
      updateTransactionSellerBankAccountId,
    ] = useUpdateTransactionSellerBankAccountIdMutation();

    useTransactionOnSellerBankAccountCreatedSubscription({
      variables: {
        transactionId: transactionId || ``,
      },
      onSubscriptionData: () => {
        apolloClient.refetchQueries({
          include: [TransactionPageTransactionByIdDocument],
        });
      },
    });

    const onUseAccount = async (
      bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment,
    ) => {
      await updateTransactionSellerBankAccountId({
        variables: {
          transactionId,
          sellerBankAccountId: bankAccount.id,
        },
      });

      apolloClient.refetchQueries({
        include: [TransactionPageTransactionByIdDocument],
      });
    };

    const onAddAccount = async () => {
      await router.push(`/transactions/${router.query.id}/connect-account`);
    };

    if (transaction.sellerBankAccount) {
      return (
        <ModernTreasuryVerifyAccountCard
          transactionBankAccount={transaction.sellerBankAccount}
        />
      );
    }

    const isRequested =
      transaction.sellerBankAccountRequestStatus ===
      BankAccountRequestStatus.Requested;

    const filteredUserBankAccounts = actor.bankAccounts.filter((account) =>
      [
        BankAccountVerificationStatus.PendingVerification,
        BankAccountVerificationStatus.Verified,
        BankAccountVerificationStatus.Manual,
      ].includes(account.verificationStatus),
    );

    if (isRequested) {
      return filteredUserBankAccounts.length === 0 ? (
        <ModernTreasuryConnectAccountCard />
      ) : (
        <SelectBankAccountCard
          bankAccounts={filteredUserBankAccounts}
          onAddNewAccount={onAddAccount}
          onUseAccount={onUseAccount}
        />
      );
    }

    return <PendingServerUpdateCard />;
  },
);

export default ModernTreasuryAccountCard;
