import { useTranslation } from "react-i18next";

import { Card, CardBody, Text, VStack } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { BidPageBrokerListerActivityBidFragment } from "@/gql";

const SecuritySpecialistCard = ({
  bid,
}: {
  readonly bid: BidPageBrokerListerActivityBidFragment;
}) => {
  const { t } = useTranslation();

  const contactEmail = bid.listing.sellerEmail;

  if (!contactEmail) return null;

  const companyName = bid.listing.company.name;

  return (
    <Card>
      <CardBody>
        <VStack spacing={4} alignItems="flex-start">
          <Text>{t(`broker_lister_live_bid_contact_security_specialist`)}</Text>
          <MailtoLink
            email={contactEmail}
            subject={t(
              `broker_lister_live_bid_email_security_specialist_subject`,
              { companyName },
            )}
            fontWeight="medium"
          >
            {contactEmail}
          </MailtoLink>
        </VStack>
      </CardBody>
    </Card>
  );
};

export const BrokerListerActivity = ({
  bid,
}: {
  readonly bid: BidPageBrokerListerActivityBidFragment;
}) => <SecuritySpecialistCard bid={bid} />;
