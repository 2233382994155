import {
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  WithQuery,
} from "@/components/common";
import {
  CompanyYourActivityListingCard,
  CompanyYourActivityStandingBidCard,
  CompanyYourBidCard,
  CompanyYourDiscussionCard,
  BrokerCompanyBidCard,
  BrokerCompanyStandingBidCard,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyPageYourActivityBidFragment,
  CompanyPageYourActivityCompanyFragment,
  CompanyPageYourActivityDiscussionFragment,
  CompanyPageYourActivityListingFragment,
  CompanyPageYourActivityStandingBidFragment,
  useCompanyPageYourActivityCompanyByIdQuery,
  UserWithInstitutionFragment,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import { getIsBroker, getIsInstitutionUser } from "@/utils";

import { YourActivitySkeleton } from "./YourActivitySkeleton";

const Listings = ({
  listings,
  company,
}: {
  readonly listings: readonly CompanyPageYourActivityListingFragment[];
  readonly company: CompanyPageYourActivityCompanyFragment;
}) => (
  <ActivityGroup title="Listings">
    {listings.map((listing) => (
      <CompanyYourActivityListingCard
        key={listing.id}
        listing={listing}
        company={company}
      />
    ))}
  </ActivityGroup>
);

const Bids = withCurrentActor(
  ({
    bids,
    actor,
  }: {
    readonly bids: readonly CompanyPageYourActivityBidFragment[];
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isBroker = getIsBroker(actor);

    return (
      <ActivityGroup title="Bids">
        {bids
          .filter((bid) => !bid?.transaction?.rofr)
          .map((bid) =>
            isBroker ? (
              <BrokerCompanyBidCard key={bid.id} bid={bid} />
            ) : (
              <CompanyYourBidCard key={bid.id} bid={bid} />
            ),
          )}
      </ActivityGroup>
    );
  },
);

const StandingBids = ({
  standingBids,
  actor,
  company,
}: {
  readonly standingBids: readonly CompanyPageYourActivityStandingBidFragment[];
  readonly actor: UserWithInstitutionFragment;
  readonly company: CompanyPageYourActivityCompanyFragment;
}) => {
  const isBroker = getIsBroker(actor);

  return (
    <ActivityGroup title="Standing Bids">
      {standingBids.map((standingBid) =>
        isBroker ? (
          <BrokerCompanyStandingBidCard
            key={standingBid.id}
            standingBid={standingBid}
            company={company}
          />
        ) : (
          <CompanyYourActivityStandingBidCard
            key={standingBid.id}
            standingBid={standingBid}
            company={company}
          />
        ),
      )}
    </ActivityGroup>
  );
};

const Discussions = ({
  discussions,
}: {
  readonly discussions: readonly CompanyPageYourActivityDiscussionFragment[];
}) => (
  <ActivityGroup title="Inquiries">
    {discussions.map((discussion) => (
      <CompanyYourDiscussionCard key={discussion.id} discussion={discussion} />
    ))}
  </ActivityGroup>
);

export const YourActivityContent = withCurrentActor(
  ({
    company,
    actor,
  }: {
    readonly company: CompanyPageYourActivityCompanyFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isDesktopView = useIsDesktop();
    const isInstitutionUser = getIsInstitutionUser(actor);

    const { institution: myInstitution } = actor;

    const activities = company.activity;

    const {
      myBids,
      myStandingBids,
      myListings,
      myActiveDiscussions,
      myInstitutionBids,
      myInstitutionStandingBids,
      myInstitutionListings,
    } = activities;

    const hasPersonalActivity =
      myBids.length > 0 ||
      myStandingBids.length > 0 ||
      myListings.length > 0 ||
      myActiveDiscussions.length > 0;

    const hasInstitutionalActivity =
      myInstitutionBids.length > 0 ||
      myInstitutionStandingBids.length > 0 ||
      myInstitutionListings.length > 0;

    const myActivityTitle = isInstitutionUser
      ? `Your Activity on Behalf of ${myInstitution?.legalName}`
      : `Your Activity`;

    const myInstitutionActivityTitle = hasPersonalActivity
      ? `Other ${myInstitution?.legalName} Activity`
      : `${myInstitution?.legalName} Activity`;

    const showEmptyState =
      !isDesktopView && !hasPersonalActivity && !hasInstitutionalActivity;

    const isSSBPFlagOn = useSellSideBrokerPortalEnabled();

    const showListings = isSSBPFlagOn || !getIsBroker(actor);

    return (
      <>
        {hasPersonalActivity && (
          <ActivitySection title={myActivityTitle}>
            {showListings && (
              <Listings listings={myListings} company={company} />
            )}
            <Bids bids={myBids} />
            <StandingBids
              standingBids={myStandingBids}
              actor={actor}
              company={company}
            />
            <Discussions discussions={myActiveDiscussions} />
          </ActivitySection>
        )}
        {hasInstitutionalActivity && (
          <ActivitySection title={myInstitutionActivityTitle}>
            <Listings listings={myInstitutionListings} company={company} />
            <Bids bids={myInstitutionBids} />
            <StandingBids
              standingBids={myInstitutionStandingBids}
              actor={actor}
              company={company}
            />
          </ActivitySection>
        )}
        {showEmptyState && (
          <ActivitySection title="Your Activity">
            <ActivityGroupEmptyState
              w="full"
              message={`You currently have no activity for ${company.name}.`}
            />
          </ActivitySection>
        )}
      </>
    );
  },
);

export const YourActivity = ({ companyId }: { readonly companyId: string }) => {
  const query = useCompanyPageYourActivityCompanyByIdQuery({
    variables: { id: companyId },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<YourActivitySkeleton />}>
      {({ data: { companyById: company } }) => (
        <YourActivityContent company={company} />
      )}
    </WithQuery>
  );
};
