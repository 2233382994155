import { CaretLeft } from "@phosphor-icons/react";
import { match } from "ts-pattern";

import { useRouter } from "next/router";

import { HStack, Text } from "@chakra-ui/react";

import { FullContentWrapper, InternalLink } from "@/components/common";

import { AccountPageNavigation } from "./AccountPageNavigation";
import { AccountPageSetting } from "./AccountPageSetting";

const AccountPageNavigationTab = () => <AccountPageNavigation />;

const AccountPageSettingTab = () => (
  <>
    <InternalLink href="/account" mb={4}>
      <HStack
        spacing={0.5}
        cursor="pointer"
        color="grey.900"
        alignItems="flex-start"
        display={{ base: `flex`, md: `none` }}
      >
        <CaretLeft size={18} weight="bold" />
        <Text textStyle="heading-md" lineHeight="18px">
          Back
        </Text>
      </HStack>
    </InternalLink>
    <AccountPageSetting />
  </>
);

export const MobileAccountPage = () => {
  const { asPath } = useRouter();

  return (
    <FullContentWrapper px={4} alignItems="flex-start">
      {match(asPath)
        .with(`/account`, () => <AccountPageNavigationTab />)
        .otherwise(() => (
          <AccountPageSettingTab />
        ))}
    </FullContentWrapper>
  );
};
