import { useMemo } from "react";

const API_PARAM = `api`;
const IS_PRODUCTION = process.env.NEXT_PUBLIC_INFRA_ENV === `production`;
const DEFAULT_API_URL =
  process.env.NEXT_PUBLIC_API_HOST ||
  `http://localhost:${process.env.NEXT_PUBLIC_PORT || 4000}/`;

function isValidUrl(url: string) {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
}

function formatAPI(api: string) {
  return api.endsWith(`/`) ? api : `${api}/`;
}

function getApiUrl() {
  if (IS_PRODUCTION || typeof window === `undefined` || !localStorage) {
    return null;
  }

  const params = new URLSearchParams(window.location.search);
  const newApiParam = params.get(API_PARAM) || ``;
  const prevApiParam = localStorage.getItem(API_PARAM) || ``;

  const apiParam = newApiParam || prevApiParam;

  if (!isValidUrl(apiParam)) {
    return null;
  }

  const apiUrl = formatAPI(apiParam);

  if (apiUrl === DEFAULT_API_URL) {
    localStorage.removeItem(API_PARAM);
    return null;
  }

  const prevApiUrl = formatAPI(prevApiParam);

  if (apiUrl !== prevApiUrl) {
    localStorage.setItem(API_PARAM, apiParam);
  }

  return apiUrl;
}

export function useServerPreview() {
  return useMemo(() => getApiUrl(), []);
}
