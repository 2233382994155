import { useMemo, useState } from "react";

import { StepsInfoV2 } from "@/components/form";

const useStepRouter = <TStepKeys extends string>({
  stepKeys,
}: {
  readonly stepKeys: readonly TStepKeys[];
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const stepsInfo: StepsInfoV2<TStepKeys> = useMemo(
    () => ({
      currentStepIndex,
      isFirstStep: currentStepIndex === 0,
      isLastStep: currentStepIndex === stepKeys.length - 1,
      currentStepKey: stepKeys[currentStepIndex],
      stepOrder: stepKeys,
    }),
    [stepKeys, currentStepIndex],
  );

  const stepControls = useMemo(
    () => ({
      nextStep: () => {
        if (stepsInfo.isLastStep) return;

        setCurrentStepIndex(currentStepIndex + 1);
      },
      previousStep: () => {
        if (stepsInfo.isFirstStep) return;

        setCurrentStepIndex(currentStepIndex - 1);
      },
      jumpToStep: (stepKey: string) => {
        const jumpToIndex = stepKeys.findIndex(
          (_stepKey) => _stepKey === stepKey,
        );
        if (jumpToIndex < 0) return;

        setCurrentStepIndex(jumpToIndex);
      },
    }),
    [stepKeys, currentStepIndex, setCurrentStepIndex],
  );

  return {
    stepControls,
    stepsInfo,
  };
};
export default useStepRouter;
