import { useField } from "formik";
import { ReactNode } from "react";

import { HStack } from "@chakra-ui/react";

import { CustomRadio } from "@/components/common";
import { fromBool, toBool } from "@/utils";

import RadioInput from "./RadioInput";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onChange = (setValue: (value: any, shouldValidate?: boolean) => void) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
) => setValue(toBool(value));

export interface YesNoRadioInputProps {
  readonly name: string;
  readonly label: string;
  readonly tooltipContent?: ReactNode;
}

/**
 * @deprecated use <FormRadioInput/>
 */
const YesNoRadioInput = ({
  name,
  label,
  tooltipContent,
  ...props
}: YesNoRadioInputProps) => {
  const [field, _, { setValue }] = useField(name);

  return (
    <RadioInput
      name={name}
      label={label}
      value={fromBool(field.value)}
      onChange={onChange(setValue)}
      tooltipContent={tooltipContent}
      {...props}
    >
      <HStack
        as="span"
        spacing={12}
        my={2}
        data-testid={`${name}-YesNoRadioInput`}
      >
        <CustomRadio value="true">Yes</CustomRadio>
        <CustomRadio value="false">No</CustomRadio>
      </HStack>
    </RadioInput>
  );
};

export default YesNoRadioInput;
