import { useContext } from "react";

import { HStack, VStack } from "@chakra-ui/react";

import {
  AdvancedOptions,
  PricingStats,
  RangeSelector,
} from "@/components/companies";
import { AvailableTimeRanges } from "@/gql";
import { useIsDesktop } from "@/hooks";

import { PricingChartContext } from "./PricingChartProvider";

interface ChartHeaderProps {
  readonly availableTimeRanges: readonly AvailableTimeRanges[];
}

export const ChartHeader = ({ availableTimeRanges }: ChartHeaderProps) => {
  const isDesktopView = useIsDesktop();

  const { range, setRange } = useContext(PricingChartContext);

  return (
    <>
      <PricingStats />
      {isDesktopView ? (
        <HStack justifyContent="space-between" w="full">
          <RangeSelector
            availableTimeRanges={availableTimeRanges}
            selected={range}
            onChange={setRange}
          />
          <AdvancedOptions />
        </HStack>
      ) : (
        <VStack alignSelf="end" alignItems="end">
          <RangeSelector
            availableTimeRanges={availableTimeRanges}
            selected={range}
            onChange={setRange}
          />
          <AdvancedOptions />
        </VStack>
      )}
    </>
  );
};
