import { FocusedShareDetails, ShareDetailsStats } from "@/components/common";
import { BidSellerRoundingDisclaimer } from "@/components/postings";
import { BidInfoCardCounterBidShareDetailsBidFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  getBidNumSharesActual,
  getNumOfShares,
  getPricePerShare,
} from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";

const BuyerShareDetails = ({
  bid,
}: {
  readonly bid: BidInfoCardCounterBidShareDetailsBidFragment;
}) => {
  const numberOfShares =
    bid.counterNumShares ?? getNumOfShares(bid, true, true);
  const pricePerShare = bid.counterPricePerShare ?? getPricePerShare(bid);

  return (
    <ShareDetailsStats
      numberOfShares={numberOfShares}
      pricePerShare={pricePerShare}
    />
  );
};

const SellerShareDetails = ({
  bid,
}: {
  readonly bid: BidInfoCardCounterBidShareDetailsBidFragment;
}) => {
  const numberOfShares = bid.counterNumShares ?? getBidNumSharesActual(bid);
  const pricePerShare = bid.counterPricePerShare ?? getPricePerShare(bid);

  return (
    <ShareDetailsStats
      numberOfShares={numberOfShares}
      pricePerShare={pricePerShare}
    />
  );
};

export const BidInfoCardCounterBidShareDetails = ({
  bid,
}: {
  readonly bid: BidInfoCardCounterBidShareDetailsBidFragment;
}) => {
  const actor = useCurrentActor();
  const isBuyer = getIsBuyerForBid(actor, bid);

  const isDisclaimerVisible = getBidNumSharesActual(bid) !== bid.numShares;

  return (
    <>
      <FocusedShareDetails.HeaderCard>
        {isBuyer ? (
          <BuyerShareDetails bid={bid} />
        ) : (
          <SellerShareDetails bid={bid} />
        )}
      </FocusedShareDetails.HeaderCard>
      {isDisclaimerVisible && (
        <BidSellerRoundingDisclaimer numSharesOriginal={bid.numShares} />
      )}
    </>
  );
};
