import { Text, VStack, StackProps } from "@chakra-ui/react";
import { ThemingProps, useStyleConfig } from "@chakra-ui/system";

interface ActivityCardBadgeProps
  extends StackProps,
    ThemingProps<`ActivityCardBadge`> {
  readonly title: string;
}

const ActivityCardBadge = ({
  size,
  variant,
  title,
  ...rest
}: ActivityCardBadgeProps) => {
  const styles = useStyleConfig(`ActivityCardBadge`, { size, variant });

  return (
    <VStack
      pt={1.5}
      pb={1}
      px={2.5}
      borderWidth={0.5}
      borderRadius="md"
      lineHeight={4}
      w="fit-content"
      flexBasis={{ base: `min-content`, sm: `auto` }}
      __css={styles}
      {...rest}
    >
      <Text textTransform="uppercase" textStyle="heading-2xs">
        {title}
      </Text>
    </VStack>
  );
};

export default ActivityCardBadge;
