import { CardBody, HStack, VStack } from "@chakra-ui/react";

import {
  ActivityCardStandingBidNumShareAndPriceDetails,
  ActivityCardListingSolicitedCardBody,
} from "@/components/common";
import {
  CompanyStandingBidCardBodyStandingBidFragment,
  CompanyStandingBidCardBodyCompanyFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { getIsBroker } from "@/utils";

import { AcceptableShareTypes } from "./AcceptableShareTypes";
import { AcceptableTransferMethods } from "./AcceptableTransferMethods";

const StandingBidCardBodyContent = ({
  standingBid,
  company,
}: {
  readonly standingBid: CompanyStandingBidCardBodyStandingBidFragment;
  readonly company?: CompanyStandingBidCardBodyCompanyFragment;
}) => {
  const { acceptsShares, acceptsTransferMethods } = standingBid;
  const actor = useCurrentActor();

  if (getIsBroker(actor) && standingBid.solicited)
    return (
      <ActivityCardListingSolicitedCardBody
        spacing={0}
        alignItems="flex-start"
        textAlign="left"
        company={company}
      />
    );

  return (
    <>
      <ActivityCardStandingBidNumShareAndPriceDetails
        standingBid={standingBid}
      />
      <VStack align="flex-end" spacing={1.5}>
        <AcceptableTransferMethods
          acceptsTransferMethods={acceptsTransferMethods}
        />
        <AcceptableShareTypes acceptsShares={acceptsShares} />
      </VStack>
    </>
  );
};

const CompanyStandingBidCardBody = ({
  standingBid,
  company,
}: {
  readonly standingBid: CompanyStandingBidCardBodyStandingBidFragment;
  readonly company?: CompanyStandingBidCardBodyCompanyFragment;
}) => {
  const actor = useCurrentActor();

  if (getIsBroker(actor) && standingBid.solicited)
    return (
      <CardBody pt={2} pb={2}>
        <HStack justify="space-between">
          <ActivityCardListingSolicitedCardBody
            spacing={0}
            alignItems="flex-start"
            textAlign="left"
            company={company}
          />
        </HStack>
      </CardBody>
    );

  return (
    <CardBody pt={2} pb={2}>
      <HStack justify="space-between">
        <StandingBidCardBodyContent
          standingBid={standingBid}
          company={company}
        />
      </HStack>
    </CardBody>
  );
};

export default CompanyStandingBidCardBody;
