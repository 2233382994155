import { FormListbox } from "@/components/form";
import { TransferMethod } from "@/gql";
import { transferMethodToLongString } from "@/utils";

const TransferMethodInput = ({
  name,
  label,
  onChange,
}: {
  readonly name: string;
  readonly label: string;
  readonly onChange?: (item: TransferMethod) => void;
}) => (
  <FormListbox
    onSelectItem={onChange}
    name={name}
    label={label}
    itemToString={transferMethodToLongString}
    getItemKey={(item) => item}
    items={[
      TransferMethod.Direct,
      TransferMethod.ForwardContract,
      TransferMethod.SpvLister,
      TransferMethod.SpvThirdparty,
    ]}
  />
);

export default TransferMethodInput;
