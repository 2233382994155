/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as Yup from "yup";

import { useRouter } from "next/router";

import { Link, ModalBody, Show, Text, VStack } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import {
  AcceptCounterBidModalBidFragment,
  AcceptCounteredBidMutation,
} from "@/gql";
import { useModal, useStepValidator } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getBidNumSharesActual,
  getShortDocumentTitleByTransferMethod,
  makeUrl,
  transferMethodToString,
} from "@/utils";

import {
  AcceptCounterBidSequenceModalStepFormContext,
  useAcceptCounterBidSequenceModalStepFormContext,
} from "./AcceptCounterBidSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { AcceptCounterBidSequenceModalFormValues } from "./types";

const validationSchema = Yup.object().shape({
  bidId: Yup.string().nullable().required(`Required`),
});

interface AcceptCounterBidModalProps
  extends StepPropsV2<StepKeys, AcceptCounterBidSequenceModalFormValues> {
  readonly bid: AcceptCounterBidModalBidFragment;
}

const AcceptCounterBidModal = ({
  bid,
  stepRouter: { stepControls },
  values,
  isSubmitting,
}: AcceptCounterBidModalProps) => {
  const { closeModal } = useModal();
  const router = useRouter();

  const documentType = getShortDocumentTitleByTransferMethod(
    bid.listing.transferMethod,
  );
  const formattedNumShares = bid.counterNumShares
    ? formatShares(bid.counterNumShares)
    : formatShares(getBidNumSharesActual(bid));
  const formattedSharePrice =
    bid.counterPricePerShare && formatPricePerShare(bid.counterPricePerShare);
  const transferMethod = transferMethodToString(bid.listing.transferMethod);

  const { submitMutation } = useAcceptCounterBidSequenceModalStepFormContext();

  const onSuccess = () =>
    submitMutation().then((response: AcceptCounteredBidMutation) => {
      const transaction = response.acceptCounteredBid.bid?.transaction;

      if (!transaction) return;

      router.push(makeUrl(transaction));
      stepControls.nextStep();
    });

  useStepValidator({
    stepKey: stepKeys.acceptCounterBid,
    validator: {
      validationSchema,
      onSuccess,
    },
    Context: AcceptCounterBidSequenceModalStepFormContext,
    values,
  });

  return (
    <>
      <HiiveModalHeader>
        Accept Counter Bid{` `}
        {bid.displayId}
      </HiiveModalHeader>
      <ModalBody>
        <VStack align="flex-start">
          <Text>Are you sure you want to accept this counter bid?</Text>
          <Text>
            Accepting this bid will constitute an Introduction Event under the
            Hiive Customer{` `}
            <Link
              href="/terms-and-conditions"
              textDecoration="underline"
              target="_blank"
            >
              Terms & Conditions
            </Link>
            .
          </Text>
          <Text>
            The next step after this will be to execute an{` `}
            {documentType} with the seller.
          </Text>
          <Text>
            {formattedNumShares} shares of{` `}
            {bid.company.name}
            {` `}@ {formattedSharePrice}/sh{` `}
            {transferMethod}
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[AcceptCounterBid/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveSubmitButton
          sentryLabel="[AcceptCounterBidConfirmation/Submit]"
          isLoading={isSubmitting}
          submitText="Confirm"
          type="submit"
        />
      </HiiveModalFooter>
    </>
  );
};

export default AcceptCounterBidModal;
