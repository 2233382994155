import Meta from "@/components/Meta";
import { WithQuery } from "@/components/common";
import { useUnaccreditedSellerCompanyPageMetadataQuery } from "@/gql";

const Metadata = () => {
  const query = useUnaccreditedSellerCompanyPageMetadataQuery();

  return (
    <WithQuery query={query} hideLoadingSpinner>
      {({
        data: {
          unaccreditedSellerMyActivity: {
            myCompany: { name },
          },
        },
      }) => <Meta title={`Company - ${name}`} />}
    </WithQuery>
  );
};

export default Metadata;
