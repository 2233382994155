import { isString } from "lodash";

import { HoldingBundle } from "./types";

export const mapToVariables = ({
  holdingBundles,
}: {
  holdingBundles: HoldingBundle[];
}) => {
  const holdings = holdingBundles.flatMap((hb) => {
    const { holding } = hb;

    const numShares = isString(holding?.numShares)
      ? parseInt(holding.numShares, 10)
      : hb.holding.numShares;

    return { ...hb.holding, numShares };
  });

  return {
    addHoldingsInput: { holdings },
  };
};

export const ADD_UNLISTED_INPUT_ACTION = `__ADD_UNLISTED_INPUT__`;
