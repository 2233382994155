import { isNil } from "lodash";
import pluralize from "pluralize";

import { Badge, Circle, Text } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  BidState,
  CompanyOpenBidsBadgeBidFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { countTotalUnreadBidDiscussionMessages } from "@/utils/bid";

const openBidStates = [BidState.Active, BidState.Countered];

const countTotalOpenBidsByMe = (
  bids: readonly CompanyOpenBidsBadgeBidFragment[],
  actor: UserWithInstitutionFragment,
): number =>
  bids.reduce((count: number, bid: CompanyOpenBidsBadgeBidFragment) => {
    if (
      (bid.buyerId === actor.id ||
        (!isNil(actor.institutionId) &&
          actor.institutionId === bid.buyerInstitutionId)) &&
      bid.state in openBidStates
    ) {
      return count + 1;
    }
    return count;
  }, 0);

const OpenBidsBadge = withCurrentActor(
  ({
    totalOpenBids,
    bids,
    actor,
  }: {
    readonly totalOpenBids: number;
    readonly bids: readonly CompanyOpenBidsBadgeBidFragment[];
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const totalOpenBidsByMe = countTotalOpenBidsByMe(bids, actor);
    const hasOpenBidsByMe = totalOpenBidsByMe >= 1;

    const totalOpenBidsByOthers = totalOpenBids - totalOpenBidsByMe;
    const hasOpenBidsByOthers = totalOpenBidsByOthers >= 1;

    const totalUnreadBidDiscussionMessages = countTotalUnreadBidDiscussionMessages(
      bids,
    );
    const hasUnreadBidDiscussionMessages =
      totalUnreadBidDiscussionMessages >= 1;

    switch (true) {
      case hasOpenBidsByMe && hasOpenBidsByOthers:
        return (
          <Badge variant="rounded-plum">
            You and{` `}
            {`${totalOpenBidsByOthers} ${pluralize(
              `other`,
              totalOpenBidsByOthers,
            )}`}
            {` `}
            have bid
          </Badge>
        );
      case hasOpenBidsByMe:
        return <Badge variant="rounded-plum">You are the only bidder</Badge>;
      default:
        return (
          <Badge variant="rounded-plum" pos="relative">
            {`${totalOpenBidsByOthers} ${pluralize(
              `Bid`,
              totalOpenBidsByOthers,
            )}`}
            {hasUnreadBidDiscussionMessages && (
              <Circle
                bg="salmon.900"
                pos="absolute"
                size={3.5}
                top={-1.5}
                right={-1.5}
              >
                <Text textStyle="text-xs" color="white">
                  {totalUnreadBidDiscussionMessages}
                </Text>
              </Circle>
            )}
          </Badge>
        );
    }
  },
);

export default OpenBidsBadge;
