import { TFunction } from "i18next";
import * as Yup from "yup";

import { HoldingType } from "@/gql";

const holdingSchema = (t: TFunction) =>
  Yup.object().shape({
    value: Yup.string().required(
      t(`validation_required`, {
        field: t(`seller_lot_details_company_name`),
      }),
    ),
    numShares: Yup.number()
      .positive(
        t(`validation_min_items`, {
          min: 1,
          field: t(`share`),
        }),
      )
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    type: Yup.string().oneOf(Object.values(HoldingType)).required(),
  });

const selectedCompany = Yup.object().shape({
  id: Yup.string().uuid().required(),
  name: Yup.string().required(),
  logoUrl: Yup.string().required(),
});

const holdingBundle = (t: TFunction) =>
  Yup.object().shape({
    company: selectedCompany.nullable(),
    holding: holdingSchema(t),
  });

const createValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    holdingBundles: Yup.array()
      .of(holdingBundle(t))
      .min(1, t(`seller_lot_details_min_items`, {}))
      .max(4, t(`seller_lot_details_max_items`, {})),
    sellerLotDisclaimer: Yup.boolean()
      .oneOf([true], t`required`)
      .required(t`required`),
  });

export { createValidationSchema };
