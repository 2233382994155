import { Text } from "@chakra-ui/react";

import { TransferMethod } from "@/gql";
import { getShortDocumentTitleByTransferMethod } from "@/utils";

const EnableManualSigningProcedureTooltip = ({
  transferMethod,
}: {
  readonly transferMethod: TransferMethod;
}) => (
  <Text>
    A manual signing procedure means an{` `}
    {getShortDocumentTitleByTransferMethod(transferMethod)}
    {` `}
    document would be manually handled by a Security Specialist at Hiive.{` `}
    <br />
    <br />
    {` `}
    Selecting this option accommodates listings have more complex signing
    requirements.
    <br />
    <br />
    {` `}
    All forward contracts default to a manual signing procedure.
  </Text>
);

export default EnableManualSigningProcedureTooltip;
