import { useEffect } from "react";
import { match } from "ts-pattern";

import { Text, VStack, Link } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { withCurrentActor, WithCurrentActorProps } from "@/components/hoc";
import {
  InstitutionalCustomerAgreement,
  SignedCustomerAgreement,
} from "@/components/pandadoc";
import {
  InvestorType,
  useCurrentContextQuery,
  UserWithInstitutionFragment,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

const TermsAndConditions = () => (
  <Text textStyle="deprecated-text-sm" textAlign="center">
    The customer agreement references the Hiive Terms and Conditions which can
    be found{` `}
    <Link href="/terms-and-conditions" fontWeight="medium" target="_blank">
      here
    </Link>
    .
  </Text>
);

const UnsignedCustomerAgreementDetails = withCurrentActor(
  ({
    actor,
    onFormSigning,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly onFormSigning: () => void;
  }) => {
    const routeToNextStep = useRouteToNextOnboardingStep();

    const { investorType } = actor;

    return (
      <>
        {match(investorType)
          .with(InvestorType.Trader, () => (
            <>
              <Text align="center" maxW="md" textStyle="deprecated-text-xl">
                Upon completion of this onboarding process you will be able to
                view some data on the Hiive platform, including listings of
                stock for sale, as well as bids and standing bids.
              </Text>
              <Text align="center" maxW="md" textStyle="deprecated-text-xl">
                Before you can see prices of transactions, bids and listings,
                make your own listings and bids on the Hiive platform, or
                respond to buyers&apos; standing bids, you must read and agree
                to the Customer Agreement.
              </Text>
            </>
          ))
          .with(InvestorType.Seller, () => (
            <>
              <Text align="center" maxW="md" textStyle="deprecated-text-xl">
                Upon completion of this onboarding process you will be able to
                view some data on the Hiive platform, including listings of
                stock for sale, as well as bids and standing bids.
              </Text>
              <Text align="center" maxW="md" textStyle="deprecated-text-xl">
                Before you can see prices of transactions, bids and listings,
                make your own listings on the Hiive platform, or respond to
                buyers&apos; standing bids, you must read and agree to the
                Customer Agreement.
              </Text>
            </>
          ))
          .run()}
        <Text
          textStyle="deprecated-heading-sm"
          color="h-salmon-pink"
          cursor="pointer"
          onClick={() => routeToNextStep()}
        >
          Skip this step
        </Text>
        <TermsAndConditions />
        <InstitutionalCustomerAgreement onFormSigning={onFormSigning} />
      </>
    );
  },
);

const SignedCustomerAgreementDetails = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();
  return (
    <>
      <Text align="center" maxW="md" textStyle="deprecated-text-xl">
        Thank you for signing the Customer Agreement.
      </Text>
      <TermsAndConditions />
      <SignedCustomerAgreement />
      <HiiveButton
        size="md"
        variant="rounded-solid-salmon"
        onClick={() => routeToNextStep()}
        sentryLabel="[InstitutionCustomerAgreementPage] Next"
      >
        Next
      </HiiveButton>
    </>
  );
};

const InstitutionCustomerAgreementPage = ({ actor }: WithCurrentActorProps) => {
  const { startPolling, stopPolling } = useCurrentContextQuery();

  const membershipAgreementSigned =
    actor.institution && actor.institution.membershipAgreementSigned;

  const onFormSigning = () => startPolling(1000);

  useEffect(() => {
    if (!membershipAgreementSigned) return;
    stopPolling();
  }, [membershipAgreementSigned]);

  return (
    <VStack my={6} spacing={6}>
      {membershipAgreementSigned ? (
        <SignedCustomerAgreementDetails />
      ) : (
        <UnsignedCustomerAgreementDetails onFormSigning={onFormSigning} />
      )}
    </VStack>
  );
};
export default withCurrentActor(InstitutionCustomerAgreementPage);
