import { useField } from "formik";
import React, { ReactNode } from "react";

import {
  FormControl,
  FormErrorMessage,
  RadioGroup,
  Stack,
  RadioGroupProps,
} from "@chakra-ui/react";

import { TooltipFormLabel } from ".";

interface RadioInputProps extends RadioGroupProps {
  readonly name: string;
  readonly label?: string;
  readonly tooltipContent?: ReactNode;
}

const RadioInput = ({
  name,
  label,
  tooltipContent,
  children,
  ...props
}: RadioInputProps) => {
  const [field, { touched, error }, { setValue }] = useField(name);

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <TooltipFormLabel tooltipContent={tooltipContent} htmlFor={name}>
        {label}
      </TooltipFormLabel>
      <RadioGroup onChange={setValue} value={field.value} {...props}>
        <Stack direction="row">{children}</Stack>
      </RadioGroup>

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default RadioInput;
