import isNil from "lodash/isNil";

import { Text } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";

const PricingBlockHeading = ({
  children,
  tooltip,
}: {
  readonly children: React.ReactNode;
  readonly tooltip?: React.ReactNode;
}) => (
  <CustomTooltip tooltipContent={tooltip} placement="top">
    <Text
      maxW={{ base: `min-content`, lg: `unset` }}
      display="inline-block"
      whiteSpace="nowrap"
      textStyle="heading-2xs"
      borderBottom={!isNil(tooltip) ? `0.5px dashed` : `none`}
      pb={1}
    >
      {children}
    </Text>
  </CustomTooltip>
);
export default PricingBlockHeading;
