import { useEffect } from "react";

import { useOnboardingCurrentActorQuery } from "@/gql";

import { OnboardingRoutes, onboardingSteps } from "./config";
import useRouteToNextOnboardingStep from "./useRouteToNextOnboardingStep";

/**
 * Put this hook in an onboarding step
 * to automatically route to the next step if the current step is complete
 * @param stepKey Key of the current step
 */
const useOnboardingStepCompleteGuard = (stepKey: OnboardingRoutes) => {
  const { data } = useOnboardingCurrentActorQuery();

  const routeToNextStep = useRouteToNextOnboardingStep();

  const actor = data?.currentActor;

  useEffect(() => {
    if (!actor) return;

    if (!onboardingSteps[stepKey].isComplete(actor)) return;

    routeToNextStep();
  }, [actor]);
};

export default useOnboardingStepCompleteGuard;
