import {
  BidPermission,
  BidState,
  GetAvailableBidActionsBidFragment,
  GetCanCounterNumberOfSharesBidFragment,
} from "@/gql";
import { iHaveEntityPermission } from "@/hooks";

const getIsBidActionable = (state: BidState) =>
  [BidState.Active, BidState.Countered].includes(state);

const getCanAcceptBid = (bid: GetAvailableBidActionsBidFragment) =>
  getIsBidActionable(bid.state) &&
  iHaveEntityPermission(bid, BidPermission.AcceptBid);

const getCanCounterBid = (bid: GetAvailableBidActionsBidFragment) =>
  bid.state === BidState.Active &&
  (bid.listing.listingPricePerShare !== bid.pricePerShare ||
    bid.listing.numSharesAvailable !== bid.numSharesActual) &&
  iHaveEntityPermission(bid, BidPermission.CounterBid);

export const getCanCounterNumberOfShares = (
  bid: GetCanCounterNumberOfSharesBidFragment,
) =>
  bid.listing.acceptPartialBid &&
  bid.listing.numSharesAvailable !== bid.numSharesActual;

const getCanAcceptCounterBid = (bid: GetAvailableBidActionsBidFragment) =>
  bid.state === BidState.Countered &&
  iHaveEntityPermission(bid, BidPermission.AcceptCounterBid);

const getCanWithdrawBid = (bid: GetAvailableBidActionsBidFragment) =>
  getIsBidActionable(bid.state) &&
  iHaveEntityPermission(bid, BidPermission.WithdrawBid);

const getCanModifyBid = (bid: GetAvailableBidActionsBidFragment) =>
  getIsBidActionable(bid.state) &&
  iHaveEntityPermission(bid, BidPermission.ModifyBid);

const getCanRequestDiscussion = (bid: GetAvailableBidActionsBidFragment) =>
  getIsBidActionable(bid.state) &&
  !bid.discussion &&
  iHaveEntityPermission(bid, BidPermission.StartDiscussion);

const getCanMessageCounterparty = (bid: GetAvailableBidActionsBidFragment) =>
  [BidState.Active, BidState.Countered, BidState.Accepted].includes(bid.state);

export const getAvailableBidActions = (
  bid: GetAvailableBidActionsBidFragment,
) => {
  const canAcceptBid = getCanAcceptBid(bid);
  const canCounterBid = getCanCounterBid(bid);
  const canWithdrawBid = getCanWithdrawBid(bid);
  const canModifyBid = getCanModifyBid(bid);
  const canRequestDiscussion = getCanRequestDiscussion(bid);
  const canMessageCounterparty = getCanMessageCounterparty(bid);

  const canAcceptCounterBid = getCanAcceptCounterBid(bid);
  const hasCounterBid = bid.state === BidState.Countered;

  return {
    canAcceptBid,
    canAcceptCounterBid,
    canCounterBid,
    canWithdrawBid,
    canModifyBid,
    canRequestDiscussion,
    canMessageCounterparty,
    hasCounterBid,
    canActOnBidAsBuyer: canModifyBid || canWithdrawBid || canAcceptCounterBid,
    canActOnBidAsSeller: (canAcceptBid || canCounterBid) && !hasCounterBid,
    canAcceptOriginalBid: canAcceptBid && hasCounterBid,
  };
};
