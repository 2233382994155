import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Text, VStack } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { canAccessPlatform, useRouteToNextOnboardingStep } from "@/hooks";
import { constants } from "@/utils";

const BrokerNeedsFurtherVerificationPage = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const { t } = useTranslation();

  useEffect(() => {
    if (!canAccessPlatform(actor)) return;
    routeToNextStep();
  }, []);

  if (canAccessPlatform(actor)) return null;

  return (
    <VStack
      spacing={6}
      textStyle="deprecated-text-xl"
      textAlign="center"
      pt={10}
    >
      <Text textStyle="heading-md">
        {t(`broker_needs_further_verification_page_heading`)}
      </Text>
      <Text>{t(`broker_needs_further_verification_page_response_period`)}</Text>
      <Text>
        <Trans
          i18nKey="broker_needs_further_verification_page_registration_status"
          t={t}
          components={[
            <MailtoLink
              key={constants.email_accounts}
              email={constants.email_accounts}
              whiteSpace="nowrap"
              fontWeight="medium"
              display="block"
            />,
          ]}
          values={{ contactEmail: constants.email_accounts }}
        />
      </Text>
    </VStack>
  );
};

export default withCurrentActor(BrokerNeedsFurtherVerificationPage);
