/* eslint-disable no-console */
import { WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, ModalBody, Text, VStack } from "@chakra-ui/react";

import { GqlError } from "@/apollo-client";
import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  PIN_LENGTH,
} from "@/components/common";
import {
  CurrentContextDocument,
  useUpdateUserMultiFactorAuthenticationMutation,
  UserMultiFactorAuthenticationMethod,
  UserMultiFactorAuthenticationStatus,
} from "@/gql";
import { useModal } from "@/hooks";
import { useDisableSmsMfa } from "@/hooks/frontegg";
import { GqlErrorCodes } from "@/types";

const DisableSmsMfaModal = ({
  lastFourDigits,
  otcToken,
}: {
  readonly lastFourDigits: string;
  readonly otcToken?: string;
}) => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();
  const [disableMfa, { error, loading }] = useDisableSmsMfa();
  const [pinValue, setPinValue] = useState<string>(``);

  const [
    updateUserMultiFactorAuthentication,
  ] = useUpdateUserMultiFactorAuthenticationMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const callMutation = async () => {
    const variables = {
      input: {
        method: UserMultiFactorAuthenticationMethod.Sms,
        status: UserMultiFactorAuthenticationStatus.PendingInactive,
      },
    };

    const result = await updateUserMultiFactorAuthentication({
      variables,
      errorPolicy: `all`,
    });

    const [mutationError] = result.errors || [];

    const mfaUnenrollmentPending =
      result.data?.updateUserMultiFactorAuthentication
        ?.userMultiFactorAuthentication ||
      (mutationError as GqlError).code ===
        GqlErrorCodes.MFA_UNENROLLMENT_PENDING;

    return mfaUnenrollmentPending;
  };

  const handlePinComplete = async (value: string) => {
    if (!otcToken) {
      return;
    }

    const mfaUnenrollmentPending = await callMutation();

    if (mfaUnenrollmentPending) {
      const ok = await disableMfa({ code: value, otcToken });

      if (ok) {
        onOpenModal(modals.disableAppMfaSuccess())();
      }
    }
  };

  const handleSubmit = async () => {
    const mfaUnenrollmentPending = await callMutation();

    if (mfaUnenrollmentPending) {
      const ok = await disableMfa();

      if (ok) {
        onOpenModal(modals.disableAppMfaSuccess())();
      }
    }
  };

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`remove_sms`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          <Text>
            <Trans
              i18nKey={
                otcToken
                  ? `remove_sms_description_with_code`
                  : `remove_sms_description_without_code`
              }
              t={t}
              components={{
                a: (
                  <Button
                    as="span"
                    cursor="pointer"
                    fontWeight={400}
                    textDecoration="underline"
                    variant="text-ghost"
                    whiteSpace="none"
                    onClick={() => console.log(`open account recovery modal`)}
                  />
                ),
              }}
              values={{ lastFourDigits }}
            />
          </Text>

          {otcToken && (
            <CodeInput
              disabled={loading}
              onChange={(value: string) => setPinValue(value)}
              onComplete={handlePinComplete}
            />
          )}

          {error && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{t(`incorrect_code`)}</Text>
            </CallOut>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          {otcToken ? (
            <HiiveSubmitButton
              isDisabled={pinValue.length !== PIN_LENGTH || loading}
              sentryLabel="[DisableSmsMfa/Submit]"
              submitText={t(`remove`)}
              type="submit"
              onClick={() => handlePinComplete(pinValue)}
            />
          ) : (
            <HiiveSubmitButton
              sentryLabel="[DisableSmsMfa/Submit]"
              submitText={t(`remove`)}
              type="submit"
              onClick={handleSubmit}
            />
          )}
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default DisableSmsMfaModal;
