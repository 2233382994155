import { Company, DailyPriceData, Indicator } from "@/gql";

export type ChartDailyPriceData = Omit<DailyPriceData, "__typename">;

export type ChartSeriesKey =
  | "postedAsks"
  | "postedAsksTransferTypeDirect"
  | "postedAsksTransferTypeIndirect"
  | "postedAsksShareTypeCommon"
  | "postedAsksShareTypePreferred"
  | "postedBids"
  | "postedBidsTransferTypeDirect"
  | "postedBidsTransferTypeIndirect"
  | "postedBidsShareTypeCommon"
  | "postedBidsShareTypePreferred"
  | "acceptedBids"
  | "acceptedBidsTransferTypeDirect"
  | "acceptedBidsTransferTypeIndirect"
  | "acceptedBidsShareTypeCommon"
  | "acceptedBidsShareTypePreferred"
  | "indexPrice"
  | "indexPriceTransferTypeDirect"
  | "indexPriceTransferTypeIndirect"
  | "indexPriceShareTypeCommon"
  | "indexPriceShareTypePreferred"
  | "lastRoundPrice";

export interface ChartSeriesSetting {
  readonly color: string;
  readonly indicator: JSX.Element;
  readonly translation: string;
}

export enum GlyphTypes {
  Dot = `dot`,
  Triangle = `triangle`,
  InvertedTriangle = `invertedTriangle`,
  Cross = `cross`,
}

export type TrendLine =
  | Indicator.IndexPrice
  | Indicator.IndexPriceTransferTypeDirect
  | Indicator.IndexPriceTransferTypeIndirect
  | Indicator.IndexPriceShareTypeCommon
  | Indicator.IndexPriceShareTypePreferred;

export type PricingChartCompany = Pick<
  Company,
  "id" | "name" | "logoUrl" | "priceChartConfig"
>;

type Option = {
  readonly available: boolean;
  readonly selected: boolean;
};

export type OptionsState = {
  readonly aggregate: Option;
  readonly transferTypeDirect: Option;
  readonly transferTypeIndirect: Option;
  readonly shareTypeCommon: Option;
  readonly shareTypePreferred: Option;
  readonly acceptedBids: Option;
  readonly postedBidsAsks: Option;
  readonly lastRoundPps: Option;
};
