import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";

import { BrokerIntermediaryInfoForm } from "./BrokerIntermediaryInfoForm";

const BrokerIntermediaryInfoPage = () => {
  const { t } = useTranslation();

  return (
    <OnboardingContainerV2 metaTitle={t(`intermediary_information`)}>
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`intermediary_information`)} *
          </Text>
        </VStack>

        <BrokerIntermediaryInfoForm />
      </Flex>
    </OnboardingContainerV2>
  );
};

export default BrokerIntermediaryInfoPage;
