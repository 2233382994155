import { useField } from "formik";
import { ReactNode } from "react";
import { ReactDatePickerProps } from "react-datepicker";

import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";

import { DatePicker } from "./DatePicker";
import TooltipFormLabel from "./TooltipFormLabel";

interface DateInputProps extends Omit<ReactDatePickerProps, "onChange"> {
  readonly name: string;
  readonly label: string;
  readonly helperText?: string;
  readonly tooltipContent?: ReactNode;
  readonly tooltipSize?: string;
}

const DateInput = ({
  name,
  label,
  helperText,
  tooltipContent,
  tooltipSize,
  ...props
}: DateInputProps) => {
  const [field, { touched, error }, { setValue }] = useField(name);

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <TooltipFormLabel
        htmlFor={name}
        tooltipContent={tooltipContent}
        tooltipSize={tooltipSize}
      >
        {label}
      </TooltipFormLabel>
      <DatePicker
        {...field}
        id={name}
        autoComplete="none"
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => setValue(val)}
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default DateInput;
