import { useTranslation } from "react-i18next";

import { Flex, Text } from "@chakra-ui/react";

import { FocusedShareDetails } from "@/components/common";
import {
  BidInfoCardCounterBidShareDetails,
  BidInfoCardStatusTile,
} from "@/components/postings";
import { CounterBidInfoCardFragment } from "@/gql";

import BrokerSellerBidInfoCard from "./BrokerSellerBidInfoCard";
import { BrokerSellerCounterBidFeeBreakdown } from "./shared";

const BrokerSellerCounterBidInfoCard = ({
  bid,
}: {
  readonly bid: CounterBidInfoCardFragment;
}) => {
  const { t } = useTranslation();
  const placedAt = bid.counteredAt || bid.expireAt;
  return (
    <Flex direction="column" gap={7}>
      <Flex direction="column" gap={7} data-testid="bid-info-card">
        <Text textStyle="heading-sm">{t`bid_info_seller_counter_bid`}</Text>
        <FocusedShareDetails.Card variant="bid">
          <FocusedShareDetails.Header title={`Bid ${bid.displayId}`}>
            <BidInfoCardCounterBidShareDetails bid={bid} />
          </FocusedShareDetails.Header>
          <FocusedShareDetails.Content>
            <FocusedShareDetails.ContentSection>
              <Flex direction="column" gap={6}>
                {placedAt && (
                  <BidInfoCardStatusTile bid={bid} showCounterBidDetails />
                )}
                <BrokerSellerCounterBidFeeBreakdown bid={bid} />
              </Flex>
            </FocusedShareDetails.ContentSection>
          </FocusedShareDetails.Content>
        </FocusedShareDetails.Card>
      </Flex>
      <BrokerSellerBidInfoCard bid={bid} />
    </Flex>
  );
};

export default BrokerSellerCounterBidInfoCard;
