import { LinearGradient } from "@visx/gradient";

import { chakraColor } from "@/utils";

export const AreaGradient = ({ id }: { readonly id: string }) => (
  <LinearGradient
    id={id}
    from={chakraColor(`skyBlue.300`)}
    to={chakraColor(`skyBlue.200`)}
    fromOffset="0%"
    toOffset="100%"
    toOpacity={0}
  />
);
