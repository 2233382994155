import { Form, useFormikContext } from "formik";
import { TFunction } from "i18next";
import xor from "lodash/xor";
import React, {
  forwardRef,
  ForwardedRef,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { HStack, Spacer, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  FormCompaniesCombobox,
  FormCompaniesControls,
  FormikQL,
  FormNumberInput,
  FormSwitch,
  TextInput,
} from "@/components/form";
import {
  CurrentContextDocument,
  ListCompaniesOrderBy,
  OnboardingSelectCompanyInputCompanyFragment,
  SellerLotDetailsMutationVariables,
  useOnboardingSelectCompanyInputListCompaniesLazyQuery,
  useSellerLotDetailsMutation,
} from "@/gql";
import {
  OnboardingRoutes,
  useDebounce,
  useOnboardingStepCompleteGuard,
  useRouteToNextOnboardingStep,
} from "@/hooks";
import { useMultipleHoldings } from "@/hooks/featureFlags";

import MultipleHoldingDetailsPage from "./SellerInfoPage/MutlipleHoldingsDetailPage";

const getVariables = (searchText: string) => ({
  first: 20,
  orderBy: ListCompaniesOrderBy.MarketActivity,
  searchText,
});

interface Holding {
  readonly company: OnboardingSelectCompanyInputCompanyFragment | null;
  readonly companyName: string | null;
  readonly numShares: number | null;
}

interface SellerLotDetailsFormValues {
  readonly eitherHolding: Holding;
  readonly companyNotListed: boolean;
}

const initialValues = {
  eitherHolding: {
    company: null,
    numShares: null,
    companyName: null,
  },
  companyNotListed: false,
};

const createValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    eitherHolding: Yup.object().shape({
      company: Yup.object()
        .nullable()
        .shape({
          id: Yup.string().required(),
        })
        .test({
          message: t(`validation_required`, {
            field: t(`company`),
          }),
          test: (company, context) => {
            const { companyName } = context.parent;
            return xor([!!company?.id], [!!companyName]).length === 2;
          },
        }),
      companyName: Yup.string()
        .nullable()
        .test({
          message: t(`validation_required`, {
            field: t(`company`),
          }),
          test: (companyName, context) => {
            const { company } = context.parent;
            return xor([!!companyName], [!!company?.id]).length === 2;
          },
        }),
      numShares: Yup.number()
        .nullable()
        .min(
          1,
          t(`validation_min_items`, {
            min: 1,
            field: t(`share`),
          }),
        ),
    }),
  });

const mapVariables = ({
  eitherHolding: { companyName, company, numShares },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  companyNotListed: _companyNotListed,
}: SellerLotDetailsFormValues): SellerLotDetailsMutationVariables => ({
  holdingInput: {
    companyName,
    companyId: company?.id,
    numShares,
  },
});

const OtherCompanyInput = ({ name }: { readonly name: string }) => {
  const { setFieldValue } = useFormikContext<SellerLotDetailsFormValues>();
  const { t } = useTranslation();

  useEffect(() => () => setFieldValue(`${name}.companyName`, null), []);

  return (
    <TextInput
      name={`${name}.companyName`}
      label={t(`seller_lot_details_company_manual`)}
      placeholder={t(`seller_lot_details_company_name`)}
      w="full"
    />
  );
};

interface SelectCompanyFormInputProps {
  readonly name: string;
  readonly isDisabled: boolean;
}

const SelectCompanyFormInput = forwardRef(
  (
    { name, isDisabled }: SelectCompanyFormInputProps,
    ref: ForwardedRef<FormCompaniesControls>,
  ) => {
    const { debounce, isDebouncing } = useDebounce();
    const { t } = useTranslation();

    const [
      loadCompanies,
      { data, loading },
    ] = useOnboardingSelectCompanyInputListCompaniesLazyQuery({
      fetchPolicy: `no-cache`,
    });

    useEffect(() => {
      loadCompanies({
        variables: getVariables(``),
      });
    }, []);

    const handleChangeSearch = (search: string) => {
      debounce(() =>
        loadCompanies({
          variables: getVariables(search),
        }),
      );
    };

    const companyItems = useMemo(
      () =>
        data?.listCompanies?.edges?.reduce(
          (soFar, edge) => (edge?.node ? [...soFar, edge.node] : soFar),
          [],
        ) || [],
      [data?.listCompanies],
    );

    const isLoading = loading || isDebouncing;

    return (
      <FormCompaniesCombobox
        getItems={() => companyItems}
        placeholder={t(`seller_lot_details_company_search`)}
        onChangeSearch={handleChangeSearch}
        name={name}
        label={t(`seller_lot_details_company_which`)}
        isLoading={isLoading}
        isDisabled={isDisabled}
        ref={ref}
      />
    );
  },
);

const HoldingInput = ({ name }: { readonly name: string }) => {
  const {
    setFieldValue,
    values,
  } = useFormikContext<SellerLotDetailsFormValues>();

  const dropdownRef = useRef<FormCompaniesControls>(null);

  const handleChangeInput = () => {
    setFieldValue(`${name}.company`, null);
    dropdownRef.current?.handleClear();
  };

  const { t } = useTranslation();

  return (
    <>
      <SelectCompanyFormInput
        name={`${name}.company`}
        isDisabled={values.companyNotListed}
        ref={dropdownRef}
      />
      <FormSwitch
        name="companyNotListed"
        label={t(`seller_lot_details_company_unlisted`)}
        onChangeInput={handleChangeInput}
      />
      <Spacer />
      {values.companyNotListed && (
        <>
          <OtherCompanyInput name={name} />
          <Spacer />
        </>
      )}
      <FormNumberInput
        backgroundColor="white"
        subLabel={t(`seller_lot_details_shares_owned`)}
        label={t(`seller_lot_details_shares_approx`)}
        placeholder={t(`seller_lot_details_shares_number`)}
        name={`${name}.numShares`}
      />
    </>
  );
};

const SingleLotDetailsPage = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useSellerLotDetailsMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const { t } = useTranslation();

  const onSuccess = () => routeToNextStep();

  const validationSchema = createValidationSchema(t);

  return (
    <VStack spacing={7}>
      <Text align="center" color="grey.900" my={7} mx={{ base: 0, sm: 4 }}>
        {t(`seller_lot_details_multiple_holdings_details_prompt`)}
      </Text>

      <FormikQL
        initialValues={initialValues}
        mapVariables={mapVariables}
        mutation={mutation}
        mutationNames={[`addHolding`]}
        onSuccess={onSuccess}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <VStack align="left" mb={7}>
              <HoldingInput name="eitherHolding" />
            </VStack>
            <HStack justify="center" w="full">
              <HiiveButton
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                sentryLabel="[SellerLotDetailsPage] Next"
                size="md"
                type="submit"
                variant="rounded-solid-salmon"
              >
                {t(`next`)}
              </HiiveButton>
            </HStack>
          </Form>
        )}
      </FormikQL>
    </VStack>
  );
};

const SellerLotDetailsPage = () => {
  useOnboardingStepCompleteGuard(OnboardingRoutes.SellerLotDetails);
  const multipleHoldingsEnabled = useMultipleHoldings();

  return multipleHoldingsEnabled ? (
    <MultipleHoldingDetailsPage />
  ) : (
    <SingleLotDetailsPage />
  );
};

export default SellerLotDetailsPage;
