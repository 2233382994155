import { CheckCircle } from "@phosphor-icons/react";
import { motion, stagger, useAnimate } from "framer-motion";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";

import { AuthContainer } from "@/components/auth-v2";
import { InternalLink } from "@/components/common";
import { withConfig, withGuestRoute } from "@/components/hoc";
import { SignupConfigFragment } from "@/gql";
import { useColors, useIsHiiveConnect } from "@/hooks";

import SignUpForm from "./SignUpForm";

const getIsConfiguredEnv = () => {
  const env = process.env.NEXT_PUBLIC_INFRA_ENV;
  if (!env) return false;
  return [`production`, `development`].includes(env);
};

const getHiiveMarketplaceLink = () => {
  const isConfiguredEnv = getIsConfiguredEnv();

  return `${isConfiguredEnv ? process.env.NEXT_PUBLIC_CLIENT_HOST : ``}/signup`;
};

const ChecklistItem = ({ children }: { children: React.ReactNode }) => {
  const [salmon900] = useColors([`salmon.900`]);

  return (
    <HStack as="li" opacity={0} scale={0} filter="blur(10px)">
      <CheckCircle size={32} weight="fill" color={salmon900} />
      <Text textStyle={{ base: `heading-md`, md: `heading-xl` }}>
        {children}
      </Text>
    </HStack>
  );
};

const staggerChecklistItems = stagger(0.3, { startDelay: 0.2 });

function useChecklistAnimation() {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      `li`,
      { opacity: 1, scale: 1, filter: `blur(0px)` },
      {
        duration: 0.2,
        delay: staggerChecklistItems,
      },
    );
  }, []);

  return scope;
}

const PlatformDescription = () => {
  const { t } = useTranslation();
  const scope = useChecklistAnimation();

  return (
    <VStack
      maxW={{ base: `lg`, lg: `sm` }}
      spacing={12}
      zIndex="1"
      alignItems="center"
      justifyContent="center"
      as={motion.div}
    >
      <VStack spacing={5}>
        <Text textStyle={{ base: `heading-2xl`, md: `heading-4xl` }} w="full">
          {t(`signup_checklist_heading`)}
        </Text>
        <Text textStyle={{ base: `text-lg`, md: `text-2xl` }} w="full">
          {t(`signup_checklist_description`)}
        </Text>
      </VStack>
      <Flex as="ul" direction="column" gap={9} w="full" ref={scope}>
        <ChecklistItem>{t(`signup_checklist_market_pricing`)}</ChecklistItem>
        <ChecklistItem>{t(`signup_checklist_market_access`)}</ChecklistItem>
        <ChecklistItem>
          {t(`signup_checklist_transaction_automation`)}
        </ChecklistItem>
        <ChecklistItem>{t(`signup_checklist_expert_help`)}</ChecklistItem>
      </Flex>
    </VStack>
  );
};

const SignUpPage = withConfig(
  ({ config }: { config: SignupConfigFragment }) => {
    const { t } = useTranslation();
    const hiiveConnect = useIsHiiveConnect();
    const hiiveMarketplaceLink = getHiiveMarketplaceLink();

    return (
      <Grid
        as={motion.div}
        layout
        templateColumns={hiiveConnect ? `1fr` : { base: `1fr`, lg: `3fr 2fr` }}
      >
        <GridItem>
          <AuthContainer
            title={hiiveConnect ? t(`sign_up_hiive_connect`) : t(`sign_up`)}
          >
            <Box>
              {hiiveConnect && (
                <>
                  <Text
                    align="center"
                    py={4}
                    textStyle="text-sm"
                    color="grey.900"
                  >
                    {t(`signup_hiive_connect_description`)}
                  </Text>
                  <Text
                    align="center"
                    maxW="md"
                    textStyle="text-sm"
                    color="grey.900"
                  >
                    <Trans
                      i18nKey="signup_hiive_connect_non_broker_redirect"
                      components={[
                        <InternalLink
                          key="marketplace-link"
                          variant="chunky"
                          href={hiiveMarketplaceLink}
                        />,
                      ]}
                    />
                    .
                  </Text>
                </>
              )}

              <Text
                margin={7}
                align="center"
                maxW="md"
                textStyle="text-md"
                color="grey.900"
              >
                <Box as="span" mr={1}>
                  {t(`signup_existing_account_prompt`)}
                </Box>
                <InternalLink variant="chunky" href="/login">
                  {t(`sign_in`)}
                </InternalLink>
              </Text>
              <SignUpForm config={config} />
            </Box>
          </AuthContainer>
        </GridItem>
        {!hiiveConnect && (
          <GridItem
            position="relative"
            overflow="hidden"
            as={Flex}
            px={{ base: 6, md: 12 }}
            pb={12}
            pt={{ base: 12, lg: `21.5rem` }}
            bg="teal.1000"
            color="white"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Box
              position="absolute"
              top={{ base: 0, lg: `10%` }}
              w="full"
              transform={{
                lg: `scale(2)`,
              }}
            >
              <chakra.img
                src="/assets/bg-wave.svg"
                alt={t(`signup_wave_graphic_alt_text`)}
                w="full"
              />
            </Box>
            <PlatformDescription />
          </GridItem>
        )}
      </Grid>
    );
  },
);

export default withGuestRoute(SignUpPage);
