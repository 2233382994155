import { useIsDesktop } from "@/hooks";

import { DesktopAccountPage } from "./DesktopAccountPage";
import { MobileAccountPage } from "./MobileAccountPage";

const AccountPage = () => {
  const isDesktopView = useIsDesktop();
  return isDesktopView ? <DesktopAccountPage /> : <MobileAccountPage />;
};

export default AccountPage;
