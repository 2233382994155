import currency from "currency.js";
import { Form } from "formik";
import { AnimatePresence } from "framer-motion";

import { HiiveModalContentWrapper } from "@/components/common";
import { AnimatedModalStep, StepFormikQL } from "@/components/form";
import {
  BidPageMyActivityDocument,
  BrokerSubmitBidInput,
  BrokerSubmitBidSequenceModalCompanyFragment,
  CompanyWatchlistActorDocument,
  ListingPageListingByIdDocument,
  useBrokerSubmitBidSequenceModalBrokerSubmitBidMutation,
  UserActivityMyActivityDocument,
} from "@/gql";
import { useStepRouter } from "@/hooks";

import {
  BrokerSubmitBidModal,
  BrokerSubmitBidSuccessModal,
  BrokerSubmitBidConfirmationModal,
  BrokerSubmitBidSequenceModalFormValues,
  BrokerSubmitBidSequenceModalStepFormContext,
  StepKeys,
  stepKeys,
} from "./steps";

const initialValues: BrokerSubmitBidSequenceModalFormValues = {
  listingId: ``,
  numShares: 0,
  pricePerShare: 0,
  timeLimit: 7,
  confirmed: false,
  solicited: null,
  affiliate: null,
};

const mapVariables = ({
  confirmed: _confirmed,
  pricePerShare,
  timeLimit,
  solicited,
  affiliate,
  ...values
}: BrokerSubmitBidSequenceModalFormValues) => {
  const input: BrokerSubmitBidInput = {
    pricePerShare: currency(pricePerShare).intValue,
    timeLimit: timeLimit * 24,
    solicited: !!solicited,
    affiliate: !!affiliate,
    ...values,
  };
  return {
    input,
  };
};

interface BrokerSubmitBidSequenceModalProps {
  readonly company: BrokerSubmitBidSequenceModalCompanyFragment;
}

const BrokerSubmitBidSequenceModal = ({
  company,
}: BrokerSubmitBidSequenceModalProps) => {
  const mutation = useBrokerSubmitBidSequenceModalBrokerSubmitBidMutation({
    refetchQueries: [
      ListingPageListingByIdDocument,
      BidPageMyActivityDocument,
      UserActivityMyActivityDocument,
      CompanyWatchlistActorDocument,
    ],
  });

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.brokerSubmitBid,
      stepKeys.brokerSubmitBidConfirmation,
      stepKeys.brokerSubmitBidSuccess,
    ],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  return (
    <HiiveModalContentWrapper>
      <StepFormikQL
        stepRouter={stepRouter}
        mutation={mutation}
        mutationNames={[`brokerSubmitBid`]}
        mapVariables={mapVariables}
        initialValues={initialValues}
        context={BrokerSubmitBidSequenceModalStepFormContext}
      >
        {({ formikProps }) => (
          <Form>
            <AnimatePresence mode="wait" initial={false}>
              {stepKeys.brokerSubmitBid === currentStepKey && (
                <AnimatedModalStep key={stepKeys.brokerSubmitBid}>
                  <BrokerSubmitBidModal
                    stepRouter={stepRouter}
                    company={company}
                    {...formikProps}
                  />
                </AnimatedModalStep>
              )}
              {stepKeys.brokerSubmitBidConfirmation === currentStepKey && (
                <AnimatedModalStep key={stepKeys.brokerSubmitBidConfirmation}>
                  <BrokerSubmitBidConfirmationModal
                    stepRouter={stepRouter}
                    {...formikProps}
                  />
                </AnimatedModalStep>
              )}
              {stepKeys.brokerSubmitBidSuccess === currentStepKey && (
                <AnimatedModalStep key={stepKeys.brokerSubmitBidSuccess}>
                  <BrokerSubmitBidSuccessModal company={company} />
                </AnimatedModalStep>
              )}
            </AnimatePresence>
          </Form>
        )}
      </StepFormikQL>
    </HiiveModalContentWrapper>
  );
};

export default BrokerSubmitBidSequenceModal;
