import { useFormikContext } from "formik";
import isNil from "lodash/isNil";
import { forwardRef, useDeferredValue } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  CompanyEligibilityCriteriaCheckbox,
  ConfirmCompanyInformationDisclosureInput,
  FocusedShareDetails,
  HiiveSubmitButton,
  ShareDetailsStats,
} from "@/components/common";
import { ListingSummaryTile, ConfirmIntentInput } from "@/components/listings";
import { ListingSellerRoundingDisclaimer } from "@/components/postings";
import {
  ShareSeries,
  UnaccreditedSellerCreateListingPageByIdCompanyFragment,
} from "@/gql";
import {
  useManagedMarketsEnabled,
  useManagedMarketsFastFollowEnabled,
} from "@/hooks/featureFlags";
import { currencyValue, roundUp } from "@/utils";

import { CreateListingFormValues } from "./types";

export const SummaryAndConfirmationCard = forwardRef<
  HTMLDivElement,
  {
    readonly company: UnaccreditedSellerCreateListingPageByIdCompanyFragment | null;
  }
>(({ company }, ref) => {
  const { values, isSubmitting } = useFormikContext<CreateListingFormValues>();

  const { t } = useTranslation();

  const {
    pricePerShare,
    numShares,
    otherDetails,
    transferTypeChoice,
    shareSeries,
  } = useDeferredValue(values);

  const displayedNumberOfShares = roundUp(numShares);
  const displayedPricePerShare = !isNil(pricePerShare)
    ? currencyValue(pricePerShare)
    : null;

  const eligibilityCriterionText = company?.companyEligibilityCriterion?.text;
  const showConfirmEligibilityRequirementsCheckbox =
    !!useManagedMarketsFastFollowEnabled() &&
    !isNil(eligibilityCriterionText) &&
    shareSeries === ShareSeries.Common;

  const showConfirmInformationDisclosureCheckbox =
    !!useManagedMarketsEnabled() && company?.hasActiveInformationDisclosure;

  return (
    <Card w="full" ref={ref}>
      <CardHeader>
        <Text textStyle="heading-sm">4. Summary & Confirmation</Text>
      </CardHeader>
      <CardBody>
        <VStack alignItems="flex-start" w="full" spacing={4}>
          <Text textStyle="heading-2xs">Review Listing Summary</Text>
          <Text>
            Here is the information you entered. Please confirm it is accurate.
            {` `} You can modify your listing at anytime, even after it is
            posted.
            {` `}
          </Text>
          <ListingSummaryTile
            numShares={numShares}
            pricePerShare={pricePerShare}
            otherDetails={otherDetails}
            transferTypeChoice={transferTypeChoice}
          />
          <Text>
            This is how your listing will look inside the marketplace. You can
            modify it anytime.
          </Text>
          <FocusedShareDetails.Header
            title={t(`listing_preview`)}
            variant="listing-preview"
          >
            <FocusedShareDetails.HeaderCard>
              <ShareDetailsStats
                numberOfShares={displayedNumberOfShares}
                pricePerShare={displayedPricePerShare}
              />
            </FocusedShareDetails.HeaderCard>
            <ListingSellerRoundingDisclaimer />
          </FocusedShareDetails.Header>
        </VStack>
      </CardBody>
      <CardBody>
        <VStack alignItems="flex-start" spacing="4">
          <Text textStyle="heading-2xs">What happens next</Text>
          <Text>{t(`place_listing_what_happens_next`)}</Text>
        </VStack>
      </CardBody>
      <CardBody>
        <VStack alignItems="flex-start" w="full">
          {showConfirmEligibilityRequirementsCheckbox && (
            <CompanyEligibilityCriteriaCheckbox
              name="confirmedEligibilityRequirements"
              label={t(`agreed_to_eligibility_requirements`, {
                companyName: company?.name,
              })}
              eligibilityCriterionText={eligibilityCriterionText}
            />
          )}
          {showConfirmInformationDisclosureCheckbox && (
            <ConfirmCompanyInformationDisclosureInput company={company} />
          )}
          <ConfirmIntentInput textProps={{ ml: 9 }} />
        </VStack>
      </CardBody>
      <CardFooter bg="grey.25">
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            isLoading={isSubmitting}
            type="submit"
            submitText="Add Listing"
            sentryLabel="[UnaccreditedSellerCreateListing/SummaryAndConfirmationCard/Submit]"
            px={16}
          />
        </ButtonFooterWrapper>
      </CardFooter>
    </Card>
  );
});
