/* eslint-disable func-names */

/* eslint-disable object-shorthand */
import { Form } from "formik";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Box,
  GridItem,
  HStack,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  AutosizeTextAreaInput,
  FormikQL,
  RadioCard,
  TextInput,
  FormCustomRadioInput,
} from "@/components/form";
import {
  CurrentContextDocument,
  UserWithInstitutionFragment,
  useAddBrokerIntermediaryInfoMutation,
} from "@/gql";
import { useCurrentActor, useRouteToNextOnboardingStep } from "@/hooks";
import { fromBool, toBool } from "@/utils";

const initialValues = ({
  firstName,
  lastName,
}: UserWithInstitutionFragment) => ({
  firstName,
  lastName,
  intermediaryPermission: null,
  intermediaryNotes: ``,
  brokerRegistrationNumber: ``,
  firmName: ``,
});

const createValidationSchema = () => () =>
  Yup.object().shape({
    firstName: Yup.string().nullable().required(`First name is required`),
    lastName: Yup.string().nullable().required(`Last name is required`),
    intermediaryPermission: Yup.boolean()
      .nullable()
      .required(`Answer is required`),
    intermediaryNotes: Yup.string()
      .nullable()
      .test({
        name: `checkIntermediaryNotesRequired`,
        test: function (intermediaryNotes: string) {
          if (this.parent.intermediaryPermission) return true;

          if (!isNil(intermediaryNotes)) return true;

          return this.createError({
            message: `Answer is Required`,
          });
        },
      }),
    firmName: Yup.string().nullable().required(`Firm name is required`),
  });

const BrokerIntermediaryInformationForm = () => {
  const { t } = useTranslation();

  const actor = useCurrentActor();
  const routeToNextStep = useRouteToNextOnboardingStep();
  const mutation = useAddBrokerIntermediaryInfoMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => {
    routeToNextStep();
  };

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`addBrokerIntermediaryInfo`]}
      initialValues={initialValues(actor)}
      validationSchema={createValidationSchema()}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, values }) => (
        <Form autoComplete="off">
          <VStack spacing={9}>
            <SimpleGrid columns={4} columnGap={5} rowGap={6} w="full">
              <GridItem colSpan={2}>
                <TextInput
                  name="firstName"
                  label="Your full name"
                  placeholder="First"
                  bg="h-white"
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Spacer h={6} />
                <TextInput name="lastName" placeholder="Last" bg="h-white" />
              </GridItem>
              <GridItem colSpan={4}>
                <TextInput
                  name="firmName"
                  label="Firm name"
                  placeholder="Name"
                  bg="h-white"
                />
              </GridItem>
              <GridItem colSpan={4}>
                <TextInput
                  name="brokerRegistrationNumber"
                  label="Dealer/intermediary registration number (optional)"
                  placeholder="Number"
                  bg="h-white"
                />
              </GridItem>
              <GridItem colSpan={4}>
                <FormCustomRadioInput
                  name="intermediaryPermission"
                  mapper={{ to: toBool, from: fromBool }}
                >
                  {({ getRootProps, getRadioProps }) => (
                    <Stack direction={{ base: `column`, sm: `row` }}>
                      <Text>{t(`are_you_permitted_by_the_laws`)}</Text>
                      <HStack
                        spacing={{ base: 3, md: 4 }}
                        data-testid="intermediary-permission"
                        {...getRootProps()}
                      >
                        <RadioCard
                          h="3.75rem"
                          w={20}
                          boxProps={{ w: `auto` }}
                          {...getRadioProps({ value: `true` })}
                        >
                          Yes
                        </RadioCard>
                        <RadioCard
                          h="3.75rem"
                          w={20}
                          boxProps={{ w: `auto` }}
                          {...getRadioProps({ value: `false` })}
                        >
                          No
                        </RadioCard>
                      </HStack>
                    </Stack>
                  )}
                </FormCustomRadioInput>
              </GridItem>
              {values.intermediaryPermission === false && (
                <GridItem colSpan={4}>
                  <AutosizeTextAreaInput
                    name="intermediaryNotes"
                    label="Reason for your answer"
                    data-testid="intermediary-notes"
                    maxRows={3}
                  />
                </GridItem>
              )}
            </SimpleGrid>
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[BrokerResidenceForm] Next"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

const BrokerIntermediaryInformationPage = () => (
  <Box mt={{ base: `1rem`, md: `3rem` }}>
    <BrokerIntermediaryInformationForm />
  </Box>
);

export default BrokerIntermediaryInformationPage;
