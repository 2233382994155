import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Fade,
  Flex,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ActivityCardBadge,
  ActivityCardDisplayId,
  ActivityCardFooterCountsWrapper,
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardNewBadge,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTransactionStatus,
  HiiveButton,
} from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import {
  DocumentType,
  PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment,
  SigningProcedure,
  TransactionPermission,
  UserWithInstitutionFragment,
} from "@/gql";
import { iHaveEntityPermission, useSyncHeight } from "@/hooks";
import { useMultipleHoldings } from "@/hooks/featureFlags";
import {
  appendSellerCompanyIdToUrl,
  getIsUnaccreditedSeller,
  getLongDocumentTitleByDocumentType,
  getTransactionDisplayId,
  getTransactionExpirationDate,
  getTransactionTitle,
} from "@/utils";
import { makeTransactionDocumentPageUrl, makeUrl } from "@/utils/schema";

const SignDocumentAlert = withCurrentActor(
  ({
    pendingSale,
    actor,
  }: {
    readonly pendingSale: PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const router = useRouter();
    const { t } = useTranslation();

    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
    const isMultipleHoldingsEnabled = useMultipleHoldings();

    if (!pendingSale.document) return null;

    const documentTitle = getLongDocumentTitleByDocumentType(
      pendingSale.document?.type,
    );
    const companyName = pendingSale.bid.company.name;
    const expirationDate = getTransactionExpirationDate(pendingSale);

    const onClick = () => {
      if (isUnaccreditedSeller && isMultipleHoldingsEnabled) {
        router.push(
          appendSellerCompanyIdToUrl(
            makeTransactionDocumentPageUrl(pendingSale.bid.id, pendingSale.id),
            pendingSale.company.id,
          ),
        );
        return;
      }
      router.push(
        makeTransactionDocumentPageUrl(pendingSale.bid.id, pendingSale.id),
      );
    };

    const description =
      pendingSale.document.type === DocumentType.Loi
        ? t(`sign_to_begin_transfer_loi`, {
            companyName,
            documentTitle,
            expirationDate,
            action: t(`sale`),
          })
        : t(`sign_to_begin_transfer_stn`, {
            companyName,
            documentTitle,
            expirationDate,
            action: t(`sale`),
          });

    return (
      <Card variant="teal" w="full">
        <CardBody>
          <VStack alignItems="flex-start">
            <Text textStyle="heading-md">
              Awaiting your signature on the{` `}
              {documentTitle}
            </Text>
            <Text>{description}</Text>
            <HiiveButton
              onClick={onClick}
              variant="text-salmon"
              px={0}
              sentryLabel="[SignDocumentAlert] Review"
            >
              Review and Sign
            </HiiveButton>
          </VStack>
        </CardBody>
      </Card>
    );
  },
);

export const PendingSaleCard = ({
  pendingSale,
}: {
  readonly pendingSale: PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment;
}) => {
  const router = useRouter();
  const {
    getIsNewAcceptedBid,
    getInquiryNumNewMessages,
  } = useUnaccreditedSellerInlineNotifications();
  const isMultipleHoldingsEnabled = useMultipleHoldings();

  const {
    bid: { discussion },
  } = pendingSale;

  const numNewMessages = !!discussion
    ? getInquiryNumNewMessages(discussion)
    : 0;

  const totalNumMessages = !!discussion ? discussion.totalNumMessages : 0;

  const hasMessages = totalNumMessages > 0;

  const title = getTransactionTitle(pendingSale);
  const displayId = getTransactionDisplayId(pendingSale);

  const { numShares, pricePerShare } = pendingSale;

  const isAwaitingMySignature =
    iHaveEntityPermission(
      pendingSale,
      TransactionPermission.SignTransactionDocument,
    ) && pendingSale.signingProcedure !== SigningProcedure.Manual;

  const signDocumentAlertRef = useRef<HTMLDivElement>(null);
  const signDocumentAlertSpacerRef = useRef<HTMLDivElement>(null);
  useSyncHeight(signDocumentAlertRef, signDocumentAlertSpacerRef);

  const isNewAcceptedBid = getIsNewAcceptedBid(pendingSale.bid);

  const showFooterCounts = isNewAcceptedBid || hasMessages;

  return (
    <Box position="relative" w="full">
      <Card
        variant="activity"
        as="button"
        onClick={() =>
          router.push(
            isMultipleHoldingsEnabled
              ? appendSellerCompanyIdToUrl(
                  makeUrl(pendingSale),
                  pendingSale.company.id,
                )
              : makeUrl(pendingSale),
          )
        }
      >
        <CardHeader>
          <Flex alignItems="center" gap={2}>
            <ActivityCardBadge variant="transaction" title={title} />
            <ActivityCardDisplayId displayId={displayId} />
          </Flex>
        </CardHeader>
        <CardBody>
          <ActivityCardPricingWrapper>
            <ActivityCardPricingNumSharesAndPrice
              numShares={numShares}
              price={pricePerShare}
            />
            <ActivityCardPricingTotal
              numShares={numShares}
              price={pricePerShare}
            />
          </ActivityCardPricingWrapper>
        </CardBody>
        <CardFooter {...(isAwaitingMySignature && { pb: 4 })}>
          <VStack spacing={2} w="full" alignItems="flex-start">
            <ActivityCardFooterWrapper py={0}>
              <ActivityCardFooterStatusWrapper>
                <ActivityCardTransactionStatus
                  transaction={pendingSale}
                  company={pendingSale.bid.company}
                  bid={pendingSale.bid}
                />
              </ActivityCardFooterStatusWrapper>
              {showFooterCounts && (
                <ActivityCardFooterCountsWrapper>
                  <HStack>
                    {hasMessages && (
                      <MessagesBadge
                        numUnreadMessages={numNewMessages}
                        totalNumMessages={totalNumMessages}
                      />
                    )}
                    {isNewAcceptedBid && (
                      <Fade in>
                        <ActivityCardNewBadge />
                      </Fade>
                    )}
                  </HStack>
                </ActivityCardFooterCountsWrapper>
              )}
            </ActivityCardFooterWrapper>
            {isAwaitingMySignature && (
              <Spacer ref={signDocumentAlertSpacerRef} />
            )}
          </VStack>
        </CardFooter>
      </Card>
      {isAwaitingMySignature && (
        <Box position="absolute" bottom="4" px={4} ref={signDocumentAlertRef}>
          <SignDocumentAlert pendingSale={pendingSale} />
        </Box>
      )}
    </Box>
  );
};
