import { Text } from "@chakra-ui/react";

const HighFeesWarning = () => (
  <Text color="red.700" textStyle="text-xs">
    Hiive&apos;s total fee, as a percentage of transaction value, is higher for
    smaller transactions.
  </Text>
);

export default HighFeesWarning;
