import { Text } from "@chakra-ui/react";

const StandingBidAllowPartialAcceptTooltip = () => (
  <Text>
    Allowing partial acceptance enables you to purchase shares from multiple
    sellers with a single Standing Bid. If a portion of the standing bid is
    accepted by a seller, and the residual number of shares is above the minimum
    number you set, then the Standing Bid will remain live on Hiive and
    available to other sellers.
  </Text>
);

export default StandingBidAllowPartialAcceptTooltip;
