import isNil from "lodash/isNil";
import { useState } from "react";

import {
  Text,
  Link,
  Box,
  Flex,
  HStack,
  RadioGroup,
  Radio,
  VStack,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  CurrentContextDocument,
  useUpdateAgreedToCustomerAgreementMutation,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import { fromBool, toBool } from "@/utils";

const BrokerCustomerAgreementPage = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();
  const [runMutation] = useUpdateAgreedToCustomerAgreementMutation({
    refetchQueries: [CurrentContextDocument],
  });
  const [agreed, setAgreed] = useState<boolean | null>();
  const ssbpEnabled = useSellSideBrokerPortalEnabled();

  const handleClickNext = () => {
    runMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { agreedToCustomerAgreement: agreed! },
    }).then(() => routeToNextStep());
  };

  return (
    <Flex my={10} direction="column" align="center">
      <Text align="center" maxW="md" textStyle="text-md">
        In order to access HiiveConnect you must review and agree to the terms
        below.
      </Text>

      <Box
        mt={4}
        borderWidth="0.5px"
        borderColor="grey.200"
        borderRadius="md"
        p={12}
        bg="white"
        overflowX="auto"
        w="3xl"
      >
        <VStack spacing={4}>
          <Text>
            I have read the{` `}
            <Link
              fontWeight="medium"
              href="/terms-and-conditions"
              target="_blank"
            >
              HiiveConnect Terms and Conditions
            </Link>
            , the{` `}
            <Link
              fontWeight="medium"
              href="https://www.hiiveconnect.com/privacy"
              target="_blank"
            >
              Privacy Policy
            </Link>
            , and these{` `}
            <Link
              fontWeight="medium"
              href="https://www.hiiveconnect.com/disclosures"
              target="_blank"
            >
              Disclaimers & Disclosures
            </Link>
            , and understand that these documents, along with the terms below,
            govern my use of Hiive Markets Limited&apos;s
            (&quot;Hiive&apos;s&quot;) portal, &quot;HiiveConnect&quot;, and
            interactions with registered representatives of Hiive.
          </Text>
          {ssbpEnabled ? (
            <Text>
              I understand that I may use HiiveConnect only to submit bids,
              standing bids, or listings on behalf of buyer customers who have
              authorized or asked me to do so, and who have a serious and bona
              fide intention to transact on the terms that I am posting, and
              that I should not use HiiveConnect to submit bids, standing bids,
              or listings on behalf of other brokers or intermediaries.
            </Text>
          ) : (
            <Text>
              I understand that I may use HiiveConnect only to make bids or
              standing bids on behalf of buyer customers who have authorized or
              asked me to do so, and who have a serious and bona fide intention
              to transact on the terms that I am posting, and that I should not
              use HiiveConnect to submit bids or standing bids on behalf of
              other brokers or intermediaries.
            </Text>
          )}

          <Text>
            I understand that Hiive may provide me with Market Data through
            HiiveConnect, including price charts, historical pricing, last round
            values, and other information or data that is owned by Hiive and/or
            a third party, and that I may use this Market Data only in
            accordance with the HiiveConnect Terms of Use in order to facilitate
            transactions through HiiveConnect.
          </Text>

          <Text>
            By clicking the &quot;Agree&quot; button below I acknowledge that I
            understand and agree to the above.
          </Text>
        </VStack>
      </Box>

      <RadioGroup
        onChange={(value) => setAgreed(toBool(value))}
        value={fromBool(agreed)}
      >
        <HStack mt={12} spacing={12} w="full" justifyContent="center">
          <Radio variant="base" value="false">
            Decline
          </Radio>
          <Radio variant="base" value="true">
            Agree
          </Radio>
        </HStack>
      </RadioGroup>

      {!isNil(agreed) && (
        <HiiveButton
          size="md"
          variant="rounded-solid-salmon"
          sentryLabel="[BrokerCustomerAgreementPage] Next"
          mt={8}
          onClick={handleClickNext}
        >
          Next
        </HiiveButton>
      )}
    </Flex>
  );
};

export default BrokerCustomerAgreementPage;
