import { Form } from "formik";

import { Box, GridItem, SimpleGrid, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  AutosizeTextAreaInput,
  FormCountriesCombobox,
  FormikQL,
  TextInput,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  UpdateInstitutionInput,
  CurrentContextDocument,
  UpdateInstitutionMutationVariables,
  useUpdateInstitutionMutation,
  UserWithInstitutionFragment,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

import EntityTypeInput from "./EntityTypeInput";
import validationSchema from "./validationSchema";

const initialValues = ({ institution }: UserWithInstitutionFragment) => ({
  entityType: null,
  jurisdictionOfIncorporationId: !!institution ? institution.country?.id : ``,
  streetAddress: ``,
  city: ``,
  region: ``,
  postalCode: ``,
});

type InstitutionBuildProfileContinuedFormValues = Pick<
  UpdateInstitutionInput,
  | "jurisdictionOfIncorporationId"
  | "streetAddress"
  | "entityType"
  | "city"
  | "region"
  | "postalCode"
>;

const mapVariables = ({ institutionId }: UserWithInstitutionFragment) => ({
  ...values
}: InstitutionBuildProfileContinuedFormValues): UpdateInstitutionMutationVariables => ({
  institutionId: institutionId || ``,
  input: {
    ...values,
  },
});

const InstitutionBuildProfileContinuedPage = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useUpdateInstitutionMutation({
    refetchQueries: [CurrentContextDocument],
  });

  if (!actor.institutionId) return null;

  const onSuccess = () => routeToNextStep();

  const isSwissInstitution = actor.institution?.country.name === `CH`;
  const isUKInstitution = actor.institution?.country.name === `UK`;
  const inIndianInstitution = actor.institution?.country.name === `IN`;

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`updateInstitution`]}
      initialValues={initialValues(actor)}
      validationSchema={validationSchema(actor.institution)}
      mapVariables={mapVariables(actor)}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <VStack spacing={5} mt={5}>
            {!isUKInstitution && (
              <>
                <Text mb={3} textAlign="center">
                  What is the legal entity for your institution?
                </Text>
                <EntityTypeInput />
              </>
            )}
            {(!!values.entityType || isUKInstitution) && (
              <>
                <FormCountriesCombobox
                  name="jurisdictionOfIncorporationId"
                  label="Jurisdiction of incorporation/registration"
                />
                <AutosizeTextAreaInput
                  name="streetAddress"
                  label="Street address of headquarters/main office"
                  minRows={2}
                />
                <SimpleGrid
                  columns={isSwissInstitution || isUKInstitution ? 3 : 4}
                  columnGap={2.5}
                  w="full"
                >
                  <GridItem colSpan={2}>
                    <TextInput name="city" label="City" />
                  </GridItem>
                  {!isSwissInstitution && !isUKInstitution && (
                    <GridItem colSpan={1}>
                      <TextInput name="region" label="State" />
                    </GridItem>
                  )}
                  <GridItem colSpan={1}>
                    <TextInput
                      name="postalCode"
                      label={inIndianInstitution ? `PIN Code` : `Postal Code`}
                    />
                  </GridItem>
                </SimpleGrid>
              </>
            )}
            <Box py={8}>
              <HiiveButton
                type="submit"
                size="xl"
                variant="rounded-solid-salmon"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                sentryLabel="[InstitutionBuildProfilePage] Next"
              >
                Next
              </HiiveButton>
            </Box>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

export default withCurrentActor(InstitutionBuildProfileContinuedPage);
