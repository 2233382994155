import { ReactNode } from "react";

import { Box, Text, useMultiStyleConfig } from "@chakra-ui/react";

interface ShareDetailsProps {
  readonly variant: "listing" | "bid" | "discussion" | "transaction";
  readonly title: string;
  readonly children: ReactNode;
}

const ShareDetails = ({ variant, title, children }: ShareDetailsProps) => {
  const styles = useMultiStyleConfig(`ShareDetails`, { variant });

  return (
    <Box __css={styles.container}>
      <Box as={Text} __css={styles.header}>
        {title}
      </Box>
      {children}
    </Box>
  );
};

export default ShareDetails;
