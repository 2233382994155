import { useField } from "formik";

/**
 * Abstraction of Formik useField, for form fields with generic TItem types
 */
export const useItemField = <TItem>(name: string) => {
  const [
    { value: selectedItem },
    { touched, error },
    { setValue: setSelectedItem },
  ] = useField<TItem | null>(name);

  return {
    selectedItem,
    isInvalid: (error && touched) || false,
    error,
    setSelectedItem,
  };
};
