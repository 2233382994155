import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { TileHeader, Skeleton } from "@/components/common";
import { formatCurrency } from "@/utils";

const ListingBrokerNetPricePerShare = ({
  isLoading,
  netPricePerShare,
}: {
  readonly isLoading: boolean;
  readonly netPricePerShare: string | number;
}) => {
  const { t } = useTranslation();

  const netPricePerShareText =
    Number(netPricePerShare) > 0
      ? formatCurrency(netPricePerShare, {
          precision: 2,
          symbol: `$`,
          fromCents: true,
        })
      : `-`;

  return (
    <>
      <TileHeader alignSelf="center">
        {t(`approximate_net_price_per_share`)}
      </TileHeader>
      {isLoading ? (
        <Skeleton h={6} w={16} />
      ) : (
        <Text>{netPricePerShareText}</Text>
      )}
    </>
  );
};

export default ListingBrokerNetPricePerShare;
