import { Form, useFormikContext } from "formik";
import { isString, has } from "lodash";
import { useRef } from "react";
import { Trans, useTranslation } from "react-i18next";

import { HStack, VStack, Text, FormLabel } from "@chakra-ui/react";
import { isEmptyObject } from "@chakra-ui/utils";

import { HiiveButton, InternalLink } from "@/components/common";
import { FormCompaniesControls, CheckboxInput } from "@/components/form";
import {
  HoldingCompanySelect,
  HoldingsFormValues,
  ADD_UNLISTED_INPUT_ACTION,
  HoldingsInput,
} from "@/components/unaccredited-seller";
import { HoldingSelectCompanyFragment, HoldingType } from "@/gql";
import constants from "@/utils/constants";

const FormErrors = ({ error }: { error: string }) => (
  <VStack align="left" mb={7}>
    <Text color="red.500" fontSize="md" fontWeight={500}>
      {error}
    </Text>
  </VStack>
);

const HoldingsForm = () => {
  const { t } = useTranslation();

  const {
    values,
    setFieldValue,
    isSubmitting,
    errors,
    touched,
  } = useFormikContext<HoldingsFormValues>();

  const { holdingBundles } = values;

  const dropdownRef = useRef<FormCompaniesControls>(null);

  const handleRemove = (index: number) => () => {
    setFieldValue(
      `holdingBundles`,
      holdingBundles.filter((_holding, cardIndex) => cardIndex !== index),
    );
  };

  const addUnlistedHoldingInput = ({ name }: { name?: string }) => {
    const holding = {
      value: name,
      type: HoldingType.Unlisted,
      numShares: null,
    };

    setFieldValue(`holdingBundles`, [
      ...values.holdingBundles,
      { company: null, holding },
    ]);
  };

  const addListedHoldingInput = (company: HoldingSelectCompanyFragment) => {
    const alreadyIncluded = holdingBundles.find(
      ({ holding }) => holding.value === company.id,
    );

    if (!alreadyIncluded) {
      const holding = {
        value: company?.id,
        type: HoldingType.Listed,
        numShares: null,
      };

      setFieldValue(`holdingBundles`, [
        ...values.holdingBundles,
        { company, holding },
      ]);
    }
  };

  const anyOutstandingErrors =
    !isEmptyObject(errors) &&
    Object.keys(errors).some((key) => has(touched, key));

  const formErrors = Object.entries(errors).reduce((acc, [key, value]) => {
    const formErrorKeys = [`holdingBundles`, `companyName`];
    return formErrorKeys.includes(key) && isString(value)
      ? [...acc, [key, value]]
      : acc;
  }, []);

  const onSelectInput = (company: HoldingSelectCompanyFragment | null) => {
    dropdownRef?.current?.handleFocus(false);
    dropdownRef?.current?.handleClear();

    if (company?.id === ADD_UNLISTED_INPUT_ACTION) {
      addUnlistedHoldingInput({ name: company?.name });
      return;
    }
    if (company?.__typename === `Company`) {
      addListedHoldingInput(company);
    }
  };

  const selectedHoldingCompanyIds = holdingBundles
    .filter(({ holding }) => holding.type === HoldingType.Listed)
    .map(({ holding }) => holding.value);

  return (
    <Form>
      <VStack align="left" gap={6}>
        <VStack gap={2} align="start">
          <FormLabel>{t(`seller_lot_details_company_which`)}</FormLabel>
          <HoldingCompanySelect
            name="selectedCompany"
            isDisabled={values.holdingBundles.length >= constants.max_holdings}
            selectedHoldingCompanyIds={selectedHoldingCompanyIds}
            ref={dropdownRef}
            manualActionName={ADD_UNLISTED_INPUT_ACTION}
            onSelectInput={onSelectInput}
          />

          {anyOutstandingErrors &&
            formErrors?.map(([_, errorMessage]) => (
              <FormErrors key={errorMessage} error={errorMessage} />
            ))}
        </VStack>

        <HoldingsInput handleRemove={handleRemove} />
        <CheckboxInput
          name="sellerLotDisclaimer"
          label={
            <Text textStyle="text-md" fontWeight={400}>
              <Trans
                i18nKey="seller_lot_details_terms_and_conditions"
                components={{
                  italic: (
                    <InternalLink
                      target="_blank"
                      textDecorationLine="underline"
                      href="/terms-and-conditions"
                    />
                  ),
                }}
              />
            </Text>
          }
          align="flex-start"
        />
        <HStack justify="center" w="full">
          <HiiveButton
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            sentryLabel="[SellerLotDetailsPage] Next"
            size="md"
            type="submit"
            variant="rounded-solid-salmon"
          >
            {t(`next`)}
          </HiiveButton>
        </HStack>
      </VStack>
    </Form>
  );
};

export default HoldingsForm;
