import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

const BrokerStandingBidAllowPartialAcceptTooltip = () => {
  const { t } = useTranslation();

  return <Text>{t(`broker_standing_bid_allow_partial_accept_tooltip`)}</Text>;
};

export default BrokerStandingBidAllowPartialAcceptTooltip;
