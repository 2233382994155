import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";

import { Input, InputProps } from "@chakra-ui/react";

type TextInputProps<TFieldValues extends FieldValues> = InputProps &
  ControllerRenderProps<TFieldValues, Path<TFieldValues>>;

export const TextInput = <TFieldValues extends FieldValues>({
  ...textInputProps
}: TextInputProps<TFieldValues>) => <Input {...textInputProps} />;
