import { Text } from "@chakra-ui/react";

import { MailtoLink, PleaseNote } from "@/components/common";
import {
  PlaceStandingBidSequenceModalFormValues,
  UnauthorizedModalBodyWrapper,
} from "@/components/modals";
import { InsufficientTradingPermissions } from "@/components/postings";
import {
  CompanyPermission,
  PlaceStandingBidModalCompanyFragment,
  UserPermissionV2,
} from "@/gql";
import { iHaveEntityPermission, useIHavePermission } from "@/hooks";
import { getSecuritySpecialistContact } from "@/utils";

import { PlaceStandingBidSequenceModalContent } from "./PlaceStandingBidSequenceModalContent";

const PlaceStandingBidNotTradable = ({
  initialCompany,
}: {
  readonly initialCompany: PlaceStandingBidModalCompanyFragment;
}) => {
  const securitySpecialistEmail = getSecuritySpecialistContact(initialCompany);

  return (
    <PleaseNote>
      <Text textStyle="text-lg">
        Automated bidding for{` `}
        {initialCompany.name}
        {` `}is not currently enabled. However, you may still place this bid by
        contacting our{` `}
        <Text textStyle="deprecated-heading-lg" as="span">
          {initialCompany.name} Security Specialist at{` `}
          <MailtoLink
            email={securitySpecialistEmail}
            textStyle="deprecated-heading-lg"
          >
            {securitySpecialistEmail}
          </MailtoLink>
        </Text>
      </Text>
    </PleaseNote>
  );
};

export const PlaceStandingBidSequenceModalAuthorization = ({
  initialCompany,
  initialValues,
}: {
  readonly initialCompany?: PlaceStandingBidModalCompanyFragment;
  readonly initialValues?: Partial<PlaceStandingBidSequenceModalFormValues>;
}) => {
  const authorizedToPlaceStandingBid = useIHavePermission(
    UserPermissionV2.PlaceStandingBid,
  );

  if (!authorizedToPlaceStandingBid)
    return (
      <UnauthorizedModalBodyWrapper
        title="Place Standing Bid"
        sentryLabelPrefix="PlaceStandingBid/Unauthorized"
      >
        <InsufficientTradingPermissions type="PLACE_STANDING_BID" />
      </UnauthorizedModalBodyWrapper>
    );

  if (
    !!initialCompany &&
    !iHaveEntityPermission(initialCompany, CompanyPermission.PlaceStandingBid)
  )
    return (
      <UnauthorizedModalBodyWrapper
        title="Place Standing Bid"
        sentryLabelPrefix="PlaceStandingBid/NotTradable"
      >
        <PlaceStandingBidNotTradable initialCompany={initialCompany} />
      </UnauthorizedModalBodyWrapper>
    );

  return (
    <PlaceStandingBidSequenceModalContent
      initialCompany={initialCompany}
      initialValues={initialValues}
    />
  );
};
