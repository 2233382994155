import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import { StandingBid } from "@/gql";
import { useModal } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getSecuritySpecialistContact,
  Nullable,
} from "@/utils";

import { usePlaceStandingBidSequenceModalStepFormContext } from "./PlaceStandingBidSequenceModalStepFormContext";
import { StepKeys } from "./steps";
import { PlaceStandingBidSequenceModalFormValues } from "./types";

const SecuritySpecialistLink = ({
  standingBid,
}: {
  readonly standingBid: {
    readonly company: Pick<
      StandingBid[`company`],
      "id" | "name" | "securitySpecialist"
    >;
  };
}) => {
  const securitySpecialistEmail = getSecuritySpecialistContact(
    standingBid.company,
  );

  return (
    <Text as="span" fontWeight="medium">
      <MailtoLink
        email={securitySpecialistEmail}
        subject={`Contact ${standingBid.company.name} Security Specialist`}
      >
        {`${standingBid.company.name} Security Specialist`}
      </MailtoLink>
    </Text>
  );
};

type StandingBidContentType = {
  readonly pricePerShare: number;
  readonly company: Pick<StandingBid[`company`], "id" | "name">;
};

const PlaceStandingBidSuccessModalBrokerContent = ({
  numShares,
  standingBid,
}: {
  readonly numShares: number;
  readonly standingBid: StandingBidContentType;
}) => (
  <>
    <HiiveModalHeader>Standing bid is in review</HiiveModalHeader>
    <ModalBody>
      <Text textStyle="heading-xl" mb={4}>
        {`Your ${standingBid.company.name} standing bid for ${formatShares(
          numShares,
        )} shares at ${formatPricePerShare(
          standingBid.pricePerShare,
        )}/sh is now in review.`}
      </Text>
      <VStack mb={4} spacing={6} alignItems="start">
        <Text>
          Your standing bid is being reviewed and may then be placed on the
          Hiive marketplace. You will be notified once it gets posted, and Hiive
          will keep you informed of any interest shown by potential sellers in
          response to your bid.
        </Text>
        <Text>
          You may also reach out to the{` `}
          <SecuritySpecialistLink standingBid={standingBid} />
          {` `}
          for any help.
        </Text>
      </VStack>
    </ModalBody>
  </>
);

const PlaceStandingBidSuccessModalBuyerContent = ({
  numShares,
  standingBid,
}: {
  readonly numShares: number;
  readonly standingBid: StandingBidContentType;
}) => (
  <>
    <HiiveModalHeader>Standing bid placed!</HiiveModalHeader>
    <ModalBody>
      <Text textStyle="heading-xl" mb={4}>
        {`Your ${standingBid.company.name} standing bid for ${formatShares(
          numShares,
        )} shares at ${formatPricePerShare(
          standingBid.pricePerShare,
        )}/sh is now live on Hiive!`}
      </Text>
      <VStack mb={4} spacing={6} alignItems="start">
        <Text>
          {`Potential ${standingBid.company.name} sellers will now be notified of your bid. Sellers may
      view, accept, or message you on your standing bid. You will be notified of any interest in your bid from potential sellers.`}
        </Text>
        <Text>
          You may also reach out anytime to the{` `}
          <SecuritySpecialistLink standingBid={standingBid} />
          {` `}
          for assistance on your transaction.
        </Text>
      </VStack>
    </ModalBody>
  </>
);

const PlaceStandingBidSuccessModal = ({
  values,
}: StepPropsV2<
  StepKeys,
  Nullable<PlaceStandingBidSequenceModalFormValues>
>) => {
  const { mutationData } = usePlaceStandingBidSequenceModalStepFormContext();
  const { numShares } = values;
  const { closeModal } = useModal();

  const standingBid = mutationData?.placeStandingBid?.standingBid;
  const brokerPlaced = !!standingBid?.brokerId;

  if (!standingBid || !numShares) return null;

  return (
    <>
      {brokerPlaced ? (
        <PlaceStandingBidSuccessModalBrokerContent
          numShares={numShares}
          standingBid={standingBid}
        />
      ) : (
        <PlaceStandingBidSuccessModalBuyerContent
          numShares={numShares}
          standingBid={standingBid}
        />
      )}
      <HiiveModalFooter>
        <HiiveSubmitButton
          data-testId="placeStandingBidSuccessButton"
          sentryLabel="[PlaceStandingBidSuccess/Submit]"
          submitText="Okay"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default PlaceStandingBidSuccessModal;
