import { useField } from "formik";
import { ReactNode } from "react";

import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
} from "@chakra-ui/react";

import styles from "./CheckboxInput.module.css";
import TooltipFormLabel from "./TooltipFormLabel";

const CheckboxInput = ({
  name,
  label,
  helperText,
  align = `center`,
  tooltipContent,
  dataTestId,
  isDisabled = false,
}: {
  readonly name: string;
  readonly label: ReactNode;
  readonly helperText?: string;
  readonly align?: "flex-start" | "center";
  readonly tooltipContent?: ReactNode;
  readonly dataTestId?: string;
  readonly isDisabled?: boolean;
}) => {
  const [field, { touched, error }, { setValue }] = useField(name);
  const isInvalid = (error && touched) || false;

  return (
    <FormControl isInvalid={isInvalid} width="auto">
      <HStack spacing={4} alignItems={align}>
        <Checkbox
          size="lg"
          // Customizing chakra checkbox styling requires some css module overrides
          className={`${styles.checkbox} ${!isInvalid && styles.checkboxValid}`}
          isInvalid={isInvalid}
          alignItems="flex-start"
          colorScheme="white"
          iconColor="h-dark-grey"
          isChecked={field.value}
          _focus={{
            boxShadow: `focus`,
          }}
          id={name}
          data-testid={dataTestId}
          onChange={(e) => {
            setValue(e.target.checked);
          }}
          isDisabled={isDisabled}
        />
        <TooltipFormLabel tooltipContent={tooltipContent} htmlFor={name} pb={0}>
          {label}
        </TooltipFormLabel>
      </HStack>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default CheckboxInput;
