import { useTranslation } from "react-i18next";

import { CardFooter, Button, HStack } from "@chakra-ui/react";

const SuitabilityCardCreateContentFooter = ({
  onClose,
  disabled,
}: {
  readonly onClose: () => void;
  readonly disabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <CardFooter>
      <HStack gap={4} flexDirection="row-reverse" w="full">
        <Button
          type="submit"
          variant="rounded-solid-salmon"
          size="xl"
          w={{ base: `full`, lg: 180 }}
          minW="auto"
          disabled={disabled}
        >
          {t(`save`)}
        </Button>
        <Button
          variant="rounded-outline-grey"
          size="xl"
          onClick={onClose}
          w={{ base: `full`, lg: 180 }}
          minW="auto"
        >
          {t(`cancel`)}
        </Button>
      </HStack>
    </CardFooter>
  );
};

export default SuitabilityCardCreateContentFooter;
