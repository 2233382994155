/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationTuple } from "@apollo/client";
import { ReactElement, useEffect } from "react";

import { Spinner, Center } from "@chakra-ui/react";

interface WithMutationProps {
  readonly mutation: MutationTuple<any, any>;
  readonly children: ({ data }: { readonly data: any }) => ReactElement;
  readonly hideLoadingSpinner?: boolean;
}

/**
 * Ensures that the mutation has returned data before rendering children.
 * Renders Error/Spinner on error/loading state.
 */
const WithMutation = ({
  mutation,
  children,
  hideLoadingSpinner = false,
}: WithMutationProps) => {
  const [runMutation, { called, data, loading, error }] = mutation;

  useEffect(() => {
    runMutation();
  }, []);

  if (!called || loading)
    return hideLoadingSpinner ? null : (
      <Center m={20}>
        <Spinner />
      </Center>
    );

  // Error redirects are handled by top-level apollo error link
  if (error) return null;

  return children({ data });
};

export default WithMutation;
