/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useRouter } from "next/router";

import { useOnboardingCurrentActorQuery } from "@/gql";
import { canAccessPlatform } from "@/hooks";
import { useFeatureFlags } from "@/hooks/featureFlags";

import { OnboardingRoutes, onboardingSteps } from "./config";
import { getNextIncompleteOnboardingStep } from "./util";

const useRouteToNextOnboardingStep = () => {
  const router = useRouter();
  const { refetch } = useOnboardingCurrentActorQuery();
  const featureFlags = useFeatureFlags();

  /**
   * Routes to the first incomplete step in the onboarding tree that comes after the current step
   */
  const routeToNextIncompleteStep = async (
    currentPathname?: OnboardingRoutes,
  ) => {
    // Ensure actor is refetched
    const {
      data: { currentActor: actor },
    } = await refetch();
    if (!actor) return;

    if (canAccessPlatform(actor)) {
      router.push(`/dashboard`);
      return;
    }

    const path = !!currentPathname
      ? currentPathname
      : (router.asPath as OnboardingRoutes);
    if (!path) throw new Error(`Invalid onboarding route ${path}`);

    // Skip one step past current
    const currentStep = onboardingSteps[path];

    const subsequentStepRoute = currentStep.getSubsequentStepRoute(
      actor,
      featureFlags,
    );
    if (!subsequentStepRoute) {
      router.push(`/dashboard`);
      return;
    }

    const subsequentStep = onboardingSteps[subsequentStepRoute];
    const nextStep = getNextIncompleteOnboardingStep(
      subsequentStep,
      actor,
      featureFlags,
    );
    if (!nextStep) {
      router.push(`/dashboard`);
      return;
    }

    router.push(nextStep.route);
  };

  return routeToNextIncompleteStep;
};

export default useRouteToNextOnboardingStep;
