import { useField } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { VStack } from "@chakra-ui/react";

import { CustomRadio } from "@/components/common";
import { AccreditationRadioInput } from "@/components/form";
import { QuestionFormCard } from "@/components/onboarding";

import { ContinueAsSellerCTA } from "./ContinueAsSellerCTA";

export const CanadaIndividualAccreditationFields = () => {
  const { t } = useTranslation();

  const [_field, _, { setValue: setAnswers }] = useField(`answers`);

  const [radioValue, setRadioValue] = useState<string | undefined>();

  const onChangeRadioValue = (value?: string) => {
    setRadioValue(value);
    const answers = !!value ? [{ key: value }] : [];
    setAnswers(answers);
  };

  return (
    <>
      <QuestionFormCard text={t(`must_be_accredited`)}>
        <AccreditationRadioInput
          value={radioValue}
          onChange={onChangeRadioValue}
        >
          <VStack alignItems="flex-start">
            <CustomRadio value="ca-individual-net-investments">
              I have, either on my own or with my spouse, more than CAD 5
              million of assets, including my home, net of any liabilities.
            </CustomRadio>
            <CustomRadio value="ca-individual-net-assets">
              I have, either on my own or with my spouse, more than CAD 1
              million of financial assets net of any liabilities.
            </CustomRadio>
            <CustomRadio value="ca-individual-income">
              I have had more than CAD $200,000 of income in each of the last
              two years and expect to exceed that income level this year, or I
              had more than CAD $300,000 income combined with my spouse in each
              of the last two years and we expect to exceed that income this
              year.
            </CustomRadio>
            <CustomRadio value="ca-individual-not-accredited">
              I’m not accredited yet.
            </CustomRadio>
          </VStack>
        </AccreditationRadioInput>
      </QuestionFormCard>
      {radioValue === `ca-individual-not-accredited` && <ContinueAsSellerCTA />}
    </>
  );
};
