import { useTranslation } from "react-i18next";

import { FormCountriesCombobox } from "@/components/form";

type TransactingEntityJurisdictionOfFormationInputProps = Partial<
  Pick<HTMLInputElement, "disabled">
>;
const TransactingEntityJurisdictionOfFormationInput = ({
  disabled,
}: TransactingEntityJurisdictionOfFormationInputProps) => {
  const { t } = useTranslation();

  return (
    <FormCountriesCombobox
      disabled={disabled}
      placeholder={t(`search_country`)}
      name="jurisdictionOfFormationId"
      label={t(`jurisdiction_of_formation`)}
      showSearchIcon
    />
  );
};

export default TransactingEntityJurisdictionOfFormationInput;
