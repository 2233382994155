import { EventHandlerParams } from "@visx/xychart";
import { useCallback } from "react";
import { RectReadOnly } from "react-use-measure";

export const useTooltipHandler = <T>({
  tooltipRef,
  containerBounds,
  showTooltip,
  setXPosition,
}: {
  readonly tooltipRef: React.RefObject<HTMLDivElement>;
  readonly containerBounds: RectReadOnly;
  readonly showTooltip: (args: {
    readonly tooltipLeft: number;
    readonly tooltipTop: number;
    readonly tooltipData: T;
  }) => void;
  readonly setXPosition: (x: number) => void;
}) =>
  useCallback(
    ({ svgPoint, datum }: EventHandlerParams<T>) => {
      const tooltipWidth = tooltipRef.current?.clientWidth || 0;
      const tooltipHeight = tooltipRef.current?.clientHeight || 0;
      const containerWidth = containerBounds.width;
      const pointerX = svgPoint?.x || 0;
      const pointerY = svgPoint?.y || 0;

      // fit the tooltip within the container
      const tooltipLeft =
        containerWidth - pointerX > tooltipWidth - 20
          ? pointerX
          : pointerX - tooltipWidth;
      const tooltipTop =
        pointerY < tooltipHeight ? pointerY + tooltipHeight : pointerY;

      setXPosition(pointerX);
      showTooltip({
        tooltipLeft: tooltipLeft < 0 ? 0 : tooltipLeft,
        tooltipTop,
        tooltipData: datum,
      });
    },
    [showTooltip, setXPosition, containerBounds, tooltipRef],
  );
