import numeral from "numeral";
import { match } from "ts-pattern";

import {
  ShareTypeV2,
  StandingBidAcceptsSharesOptions,
  StandingBidAcceptsTransferMethodsOptions,
  TransferMethod,
  UnaccreditedSellerTransferMethodInput,
  ValidTransferMethod,
} from "@/gql";

export const transferMethodToString = (
  transferMethod: TransferMethod | ValidTransferMethod,
) =>
  match(transferMethod)
    .with(TransferMethod.Direct, () => `Direct`)
    .with(TransferMethod.ForwardContract, () => `Forward`)
    .with(TransferMethod.SpvLister, () => `SPV`)
    .with(TransferMethod.SpvThirdparty, () => `SPV`)
    .with(TransferMethod.Unknown, () => `TBD`)
    .otherwise(() => {
      throw new Error(
        `Unrecognized transfer method in transferMethodToString: ${transferMethod}`,
      );
    });

export const transferMethodToLongString = (transferMethod: TransferMethod) =>
  match(transferMethod)
    .with(TransferMethod.Direct, () => `Direct`)
    .with(TransferMethod.ForwardContract, () => `With a Forward Contract`)
    .with(TransferMethod.SpvLister, () => `SPV Managed by Me`)
    .with(TransferMethod.SpvThirdparty, () => `SPV Managed by Third Party`)
    .with(TransferMethod.Unknown, () => `To Be Determined`)
    .otherwise(() => {
      throw new Error(
        `Unrecognized transfer method in transferMethodToLongString: ${transferMethod}`,
      );
    });

export const standingBidAcceptsTransferMethodOptionsToString = (
  transferMethod: StandingBidAcceptsTransferMethodsOptions,
) =>
  match(transferMethod)
    .with(StandingBidAcceptsTransferMethodsOptions.Direct, () => `Direct`)
    .with(
      StandingBidAcceptsTransferMethodsOptions.ForwardContract,
      () => `Forward`,
    )
    .with(StandingBidAcceptsTransferMethodsOptions.SpvLister, () => `SPV`)
    .with(StandingBidAcceptsTransferMethodsOptions.SpvThirdparty, () => `SPV`)
    .exhaustive();

export const standingBidAcceptsTransferMethodOptionsToLongString = (
  transferMethod:
    | StandingBidAcceptsTransferMethodsOptions
    | UnaccreditedSellerTransferMethodInput,
) =>
  match(transferMethod)
    .with(StandingBidAcceptsTransferMethodsOptions.Direct, () => `Direct`)
    .with(
      StandingBidAcceptsTransferMethodsOptions.ForwardContract,
      () => `With a Forward Contract`,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptions.SpvLister,
      () => `SPV Managed by Me`,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptions.SpvThirdparty,
      () => `SPV Managed by Third Party`,
    )
    .otherwise(() => {
      throw new Error(
        `Unrecognized transfer method in standingBidAcceptsTransferMethodOptionsToLongString: ${transferMethod}`,
      );
    });

export const shareTypeToString = (shareType: ShareTypeV2) =>
  ({
    [ShareTypeV2.Common]: `Common`,
    [ShareTypeV2.Pref]: `Preferred`,
    [ShareTypeV2.Mix]: `Mix`,
    [ShareTypeV2.RestrictedStockUnits]: `Restricted Stock Units`,
  }[shareType]);

export const acceptsSharesToString = (
  acceptsShares: StandingBidAcceptsSharesOptions,
): string =>
  match(acceptsShares)
    .with(StandingBidAcceptsSharesOptions.Both, () => `Any`)
    .with(StandingBidAcceptsSharesOptions.Common, () => `Common`)
    .with(StandingBidAcceptsSharesOptions.Pref, () => `Preferred`)
    .exhaustive();

export const acceptsTransferMethodsToString = (
  acceptsTransferMethods: readonly StandingBidAcceptsTransferMethodsOptions[],
) =>
  [
    ...new Set(
      acceptsTransferMethods.map(
        (transferMethod) =>
          ({
            [StandingBidAcceptsTransferMethodsOptions.Direct]: `Direct`,
            [StandingBidAcceptsTransferMethodsOptions.ForwardContract]: `Forward`,
            [StandingBidAcceptsTransferMethodsOptions.SpvLister]: `SPV`,
            [StandingBidAcceptsTransferMethodsOptions.SpvThirdparty]: `SPV`,
          }[transferMethod]),
      ),
    ),
  ]
    .sort()
    .join(`, `);

export const lot = (
  numOfShares: number,
  pricePerShare: number,
  format?: string,
) => {
  const inputString = format || `$0,0[.]00`;
  return `${numeral((pricePerShare / 100) * numOfShares).format(inputString)}`;
};
