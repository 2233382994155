import {
  VStack,
  StackProps,
  Card,
  CardBody,
  CardHeader,
  Flex,
} from "@chakra-ui/react";

import { Skeleton, WithQuery } from "@/components/common";
import { InformativeArticlesCardV2 } from "@/components/dashboard";
import { usePostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery } from "@/gql";

import { NavigationCardV2 } from "./NavigationCardV2";
import { SellNowCardV2 } from "./SellNowCardV2";

const SidebarSkeleton = () => (
  <VStack spacing={4}>
    <Card w="full">
      <CardHeader>
        <Skeleton h={4} maxW="40%" />
      </CardHeader>
      <CardBody>
        <VStack w="full" spacing={2}>
          <Skeleton h="41px" w="full" count={6} borderRadius="lg" />
        </VStack>
      </CardBody>
    </Card>
    <Card w="full">
      <CardHeader>
        <Skeleton h={5} maxW="45%" />
      </CardHeader>
      <CardBody>
        <Flex direction="column" gap={4}>
          <Skeleton h={3} count={5} />
          <Skeleton h={3} maxW="35%" />
          <Skeleton h={12} borderRadius="full" maxW="70%" m="0 auto" />
        </Flex>
      </CardBody>
    </Card>
  </VStack>
);

const SidebarV2 = ({ id, ...stackProps }: { id: string } & StackProps) => {
  const query = usePostActivityUnaccreditedSellerDashboardByIdPageSidebarQuery({
    variables: { id },
  });

  return (
    <WithQuery query={query} fallback={<SidebarSkeleton />}>
      {({ data: { companyById: company } }) => (
        <VStack spacing={4} {...stackProps}>
          <NavigationCardV2 company={company} />
          <SellNowCardV2
            hasListing={company.activity.myListings.length > 0}
            id={company.id}
          />
          <InformativeArticlesCardV2 company={company} />
        </VStack>
      )}
    </WithQuery>
  );
};

export default SidebarV2;
