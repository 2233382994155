import { t } from "i18next";

import { useRouter } from "next/router";

import { Box, SimpleGrid, VStack } from "@chakra-ui/react";

import Meta from "@/components/Meta";
import {
  FeeDiscountAlert,
  FullContentWrapper,
  MobileTab,
  MobileTabList,
  MobileTabPanel,
  MobileTabPanels,
  MobileTabs,
  WithQuery,
} from "@/components/common";
import { useFeeDiscountAlert } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  InvestorType,
  UnaccreditedSellerCompanyByIdPageCompanyFragment,
  UserWithInstitutionFragment,
  useUnaccreditedSellerCompanyByIdPageQuery,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import { hasInvestorType } from "@/utils";

import { ActionsV2 } from "./Actions";
import { InfoV2 } from "./Info";
import { MarketActivityV2 } from "./MarketActivity";
import { YourActivityV2 } from "./YourActivity";

const DesktopUnaccreditedSellerCompanyPage = ({
  company,
}: {
  readonly company: UnaccreditedSellerCompanyByIdPageCompanyFragment;
}) => {
  const { feeDiscount, feeDiscountAlertType } = useFeeDiscountAlert({
    company,
  });

  return (
    <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
      <InfoV2 id={company.id} />
      <VStack alignItems="flex-start" spacing={8}>
        {feeDiscountAlertType && (
          <FeeDiscountAlert
            companyName={company.name}
            feeDiscount={feeDiscount}
            type={feeDiscountAlertType}
          />
        )}
        <ActionsV2 id={company.id} />
        <YourActivityV2 id={company.id} />
        <MarketActivityV2 id={company.id} />
      </VStack>
    </SimpleGrid>
  );
};

const MobileUnaccreditedSellerCompanyPage = ({
  company,
}: {
  readonly company: UnaccreditedSellerCompanyByIdPageCompanyFragment;
}) => {
  const { feeDiscount, feeDiscountAlertType } = useFeeDiscountAlert({
    company,
  });

  return (
    <>
      {feeDiscountAlertType && !!company.feeDiscountApplications?.length && (
        <Box mb={4}>
          <FeeDiscountAlert
            companyName={company.name}
            feeDiscount={feeDiscount}
            type={feeDiscountAlertType}
          />
        </Box>
      )}
      <MobileTabs>
        <MobileTabList>
          <MobileTab>{t(`overview`)}</MobileTab>
          <MobileTab>{t(`your_activity`)}</MobileTab>
          <MobileTab>{t(`market_activity`)}</MobileTab>
        </MobileTabList>
        <MobileTabPanels>
          <MobileTabPanel>
            <InfoV2 id={company.id} />
          </MobileTabPanel>
          <MobileTabPanel>
            <VStack spacing={8}>
              <ActionsV2 id={company.id} />
              <YourActivityV2 id={company.id} />
            </VStack>
          </MobileTabPanel>
          <MobileTabPanel>
            <MarketActivityV2 id={company.id} />
          </MobileTabPanel>
        </MobileTabPanels>
      </MobileTabs>
    </>
  );
};

const UnaccreditedSellerCompanyPageV2 = withCurrentActor(
  ({
    id,
    actor,
  }: {
    readonly id: string;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const query = useUnaccreditedSellerCompanyByIdPageQuery({
      variables: { id },
    });

    const isDesktopView = useIsDesktop();
    const router = useRouter();

    const isTrader = hasInvestorType(actor, InvestorType.Trader);
    const isSeller = hasInvestorType(actor, InvestorType.Seller);
    const isBroker = hasInvestorType(actor, InvestorType.Broker);

    if (isTrader || isSeller || isBroker) {
      router.replace(`/page-not-found`);
      return null;
    }

    return (
      <WithQuery query={query}>
        {({ data: { companyById: company } }) => (
          <>
            <Meta title={`Company - ${company.name}`} />
            <FullContentWrapper px={{ base: 4, lg: 8 }}>
              <Box w="full" maxW="max-width-md">
                {isDesktopView ? (
                  <DesktopUnaccreditedSellerCompanyPage company={company} />
                ) : (
                  <MobileUnaccreditedSellerCompanyPage company={company} />
                )}
              </Box>
            </FullContentWrapper>
          </>
        )}
      </WithQuery>
    );
  },
);

export default UnaccreditedSellerCompanyPageV2;
