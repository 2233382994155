import { SealCheck } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Box, HStack, Text, VStack } from "@chakra-ui/react";

import { FeeDiscountAlertType } from "@/components/companies";
import { FeeDiscountFragment, FeeDiscountType } from "@/gql";
import { formatCurrency } from "@/utils";

interface FeeDiscountAlertProps {
  readonly companyName: string;
  readonly feeDiscount: FeeDiscountFragment;
  readonly type: FeeDiscountAlertType;
}

const FeeDiscountAlert = ({
  companyName,
  feeDiscount,
  type,
}: FeeDiscountAlertProps) => {
  const { t } = useTranslation();

  const { type: feeDiscountType, value } = feeDiscount;

  const decimalValue = value / 100;

  const title = match(feeDiscountType)
    .with(
      FeeDiscountType.FlatFee,
      () =>
        `${
          Number.isInteger(decimalValue)
            ? formatCurrency(decimalValue, { precision: 0 })
            : formatCurrency(value, { fromCents: true })
        } ${t(`fee_discount`)}`,
    )
    .with(
      FeeDiscountType.PercentageFee,
      () =>
        `${
          Number.isInteger(decimalValue)
            ? decimalValue.toFixed(0)
            : decimalValue
        }% ${t(`fee_discount`)}`,
    )
    .with(FeeDiscountType.OverrideCommission, () => t(`flat_fee_discount`))
    .exhaustive();

  const discountValue = match(feeDiscountType)
    .with(FeeDiscountType.FlatFee, () =>
      Number.isInteger(decimalValue)
        ? formatCurrency(decimalValue, { precision: 0 })
        : formatCurrency(value, { fromCents: true }),
    )
    .with(FeeDiscountType.PercentageFee, () =>
      Number.isInteger(decimalValue)
        ? `${decimalValue.toFixed(0)}%`
        : `${decimalValue}%`,
    )
    .with(FeeDiscountType.OverrideCommission, () => null)
    .exhaustive();

  const description =
    type === FeeDiscountAlertType.Company
      ? t(`fee_discount_alert_company_description`, {
          companyName,
          discountValue,
        })
      : t(`fee_discount_alert_user_description`, {
          companyName,
          discountValue,
        });

  return (
    <HStack
      alignItems="flex-start"
      bg="teal.50"
      borderColor="teal.200"
      borderWidth={1}
      borderRadius="md"
      boxShadow="card"
      padding={4}
      width="100%"
    >
      <Box flexShrink={0}>
        <SealCheck width={54} height={54} fill="#19434F" />
      </Box>
      <VStack alignItems="flex-start">
        <Text
          textColor="teal.1000"
          textStyle="heading-sm"
          wordBreak="break-word"
        >
          {title}
        </Text>
        <Text textColor="teal.1000">{description}</Text>
      </VStack>
    </HStack>
  );
};

export default FeeDiscountAlert;
