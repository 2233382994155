import { forwardRef } from "react";

import { Card, CardBody, CardHeader, Text, VStack } from "@chakra-ui/react";

import { FormTextarea } from "@/components/form";
import { ListingNotesExplainer } from "@/components/listings";

export const ListingNotesCard = forwardRef<HTMLDivElement>((_, ref) => (
  <Card w="full" ref={ref}>
    <CardHeader>
      <Text textStyle="heading-sm">3. Listing Notes (Optional)</Text>
    </CardHeader>
    <CardBody>
      <VStack spacing={5} alignItems="flex-start">
        <ListingNotesExplainer />
        <FormTextarea
          name="otherDetails"
          label="Additional listing notes"
          placeholder="Add optional details"
          labelSrOnly
        />
      </VStack>
    </CardBody>
  </Card>
));
