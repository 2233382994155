import { Form, FormikProps, FormikValues } from "formik";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { FormikQL } from "@/components/form";
import {
  CurrentContextDocument,
  useUpdateIndividualAccreditationAnswerMutation,
} from "@/gql";

type AccreditationCardModifyFormValues = {
  readonly answer: string;
};

const AccreditationCardModifyForm = ({
  children,
  onSuccess,
  initialValues,
}: {
  readonly children: (props: FormikProps<FormikValues>) => ReactElement;
  readonly onSuccess: () => void;
  readonly initialValues: AccreditationCardModifyFormValues;
}) => {
  const { t } = useTranslation();

  const mutation = useUpdateIndividualAccreditationAnswerMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const validationSchema = Yup.object().shape({
    answer: Yup.string().required(t(`must_select_answer`)),
  });

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`updateIndividualAccreditationAnswer`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSuccess={() => {
        onSuccess();
      }}
      mapVariables={({ answer }) => ({
        input: {
          questionId: answer,
        },
      })}
    >
      {(formikProps) => (
        <Form style={{ display: `contents` }}>
          {children({ ...formikProps })}
        </Form>
      )}
    </FormikQL>
  );
};

export default AccreditationCardModifyForm;
