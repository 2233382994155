import { Text, TextProps } from "@chakra-ui/react";

const StandingBidHighFeesWarning = (textProps: TextProps) => (
  <Text color="salmon.900" {...textProps}>
    Hiive&apos;s total fee, as a percentage of transaction value, is higher for
    smaller transactions.
  </Text>
);

export default StandingBidHighFeesWarning;
