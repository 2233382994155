import { AvailableTimeRanges, DailyPriceData, Indicator } from "@/gql";
import { chakraColor } from "@/utils";

import {
  chartSeriesSetting,
  EXCLUDING_INDICATORS,
  TREND_LINES_TO_OPTIONS,
} from "./constants";
import { ChartDailyPriceData, ChartSeriesKey, OptionsState } from "./types";

// convert to dollars
export const mapCompanyPriceData = (
  data: readonly DailyPriceData[],
): readonly ChartDailyPriceData[] =>
  data.map(
    (item: DailyPriceData): ChartDailyPriceData =>
      Object.fromEntries(
        Object.keys(item)
          .filter((key) => key !== `__typename`)
          .map((key) => {
            const originalValue = item[key as keyof DailyPriceData];
            if (Array.isArray(originalValue)) {
              return [
                key,
                originalValue.map((num) =>
                  typeof num === `number`
                    ? Math.round((num / 100) * 100) / 100
                    : num,
                ),
              ];
            }
            if (typeof originalValue === `number`) {
              return [key, Math.round((originalValue / 100) * 100) / 100];
            }
            return [key, originalValue];
          }),
      ) as ChartDailyPriceData,
  ) ?? [];

export const shouldShowAreaForIndexPrice = (
  selectedIndicators: readonly Indicator[],
) => {
  const isExcludingIndicator = selectedIndicators.some((indicator) =>
    EXCLUDING_INDICATORS.includes(indicator),
  );
  return !isExcludingIndicator;
};

export const getRange = (data: readonly ChartDailyPriceData[]) => {
  if (!data) return [0, 0];

  const allValues: readonly number[] = data.reduce<readonly number[]>(
    (acc, item) => {
      const itemValues: readonly number[] = Object.entries(item).reduce<
        readonly number[]
      >((itemAcc, [key, value]) => {
        if (key !== `day`) {
          if (Array.isArray(value)) {
            return itemAcc.concat(value.filter((v) => typeof v === `number`));
          }
          if (typeof value === `number`) {
            return itemAcc.concat(value);
          }
        }
        return itemAcc;
      }, []);

      return acc.concat(itemValues);
    },
    [],
  );

  const min = Math.min(...allValues);
  const max = Math.max(...allValues);

  // if there is only one value present in dataset, return it without "padding"
  // to avoid chart trying to fit more ticks and labels than necessary
  if (min === max) return [min, max];

  const minRange = Math.min(...allValues) - 1;
  const maxRange = Math.max(...allValues) + 1;

  return [minRange < 0 ? 0 : minRange, maxRange];
};

export const getVisibleSeries = (
  data: readonly ChartDailyPriceData[],
): readonly ChartSeriesKey[] => {
  if (!data) return [];

  const uniqueKeys = new Set<ChartSeriesKey>();

  data.forEach((data) => {
    Object.keys(data).forEach((key) => {
      if (key !== `day`) {
        const value = data[key as keyof ChartDailyPriceData];
        if (value !== null && !(Array.isArray(value) && value.length === 0)) {
          uniqueKeys.add(key as ChartSeriesKey);
        }
      }
    });
  });

  return [...uniqueKeys];
};

export const formatYTick = (tickValue: number): string => {
  if (tickValue >= 1000) {
    const dividedValue = tickValue / 1000;
    const formattedValue = parseFloat(dividedValue.toFixed(2));
    return `$${formattedValue}k`;
  }
  return `$${tickValue}`;
};

export const getSeriesColor = (key: ChartSeriesKey) =>
  chakraColor(chartSeriesSetting[key]?.color);

export const getStartDate = ({
  range,
  maxDate,
}: {
  readonly range: AvailableTimeRanges;
  readonly maxDate: string;
}) => {
  const currentDate = new Date();
  switch (range) {
    case AvailableTimeRanges.OneYear:
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      break;
    case AvailableTimeRanges.SixMonths:
      currentDate.setMonth(currentDate.getMonth() - 6);
      break;
    case AvailableTimeRanges.ThreeMonths:
      currentDate.setMonth(currentDate.getMonth() - 3);
      break;
    default:
      return maxDate;
  }

  return currentDate.toISOString().split(`T`)[0];
};

export const getSelectedTrendLinesCount = (
  optionsState: OptionsState,
): number => {
  const options = Object.values(TREND_LINES_TO_OPTIONS);

  return options.reduce((count, option) => {
    if (
      optionsState[option as keyof OptionsState] &&
      optionsState[option as keyof OptionsState].selected
    ) {
      return count + 1;
    }
    return count;
  }, 0);
};

export const getDisplayedPriceTrendsCount = (optionsState: OptionsState) => {
  const selectedTrendLinesCount = getSelectedTrendLinesCount(optionsState);
  const isAggregateSelected = optionsState.aggregate.selected;
  // aggregate price trend is always shown, even if trend line not selected
  return isAggregateSelected
    ? selectedTrendLinesCount
    : selectedTrendLinesCount + 1;
};

export const isAggregateOnlyOptionAvailable = (optionsState: OptionsState) => {
  const isAggregateAvailable = optionsState.aggregate.available;
  const areOtherOptionsAvailable = Object.entries(optionsState)
    .filter(([key, _]) => key !== `aggregate`)
    .some(([_, option]) => option.available);

  return isAggregateAvailable && !areOtherOptionsAvailable;
};
