import { SVGProps } from "react";

const TrendLineSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19.0625 4.375V9.375C19.0625 9.62364 18.9637 9.8621 18.7879 10.0379C18.6121 10.2137 18.3736 10.3125 18.125 10.3125C17.8763 10.3125 17.6379 10.2137 17.4621 10.0379C17.2862 9.8621 17.1875 9.62364 17.1875 9.375V6.64063L11.2882 12.5406C11.2011 12.628 11.0977 12.6974 10.9837 12.7447C10.8697 12.792 10.7476 12.8164 10.6242 12.8164C10.5008 12.8164 10.3786 12.792 10.2647 12.7447C10.1507 12.6974 10.0472 12.628 9.96012 12.5406L7.49997 10.0781L2.53825 15.0383C2.36213 15.2144 2.12326 15.3133 1.87418 15.3133C1.62511 15.3133 1.38624 15.2144 1.21012 15.0383C1.034 14.8622 0.935059 14.6233 0.935059 14.3742C0.935059 14.1251 1.034 13.8863 1.21012 13.7102L6.83512 8.08516C6.92222 7.99776 7.02571 7.92841 7.13967 7.88109C7.25362 7.83377 7.3758 7.80942 7.49918 7.80942C7.62257 7.80942 7.74475 7.83377 7.8587 7.88109C7.97266 7.92841 8.07615 7.99776 8.16325 8.08516L10.625 10.5469L15.8593 5.3125H13.125C12.8763 5.3125 12.6379 5.21373 12.4621 5.03791C12.2862 4.8621 12.1875 4.62364 12.1875 4.375C12.1875 4.12636 12.2862 3.8879 12.4621 3.71209C12.6379 3.53627 12.8763 3.4375 13.125 3.4375H18.125C18.3736 3.4375 18.6121 3.53627 18.7879 3.71209C18.9637 3.8879 19.0625 4.12636 19.0625 4.375Z" />
  </svg>
);

export default TrendLineSvg;
