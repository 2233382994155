import { useTranslation } from "react-i18next";

import { FeeBreakdown } from "@/components/common";
import { BidInfoCardBidFragment } from "@/gql";
import { getBidNumSharesActual } from "@/utils";

export const BrokerSellerFeeBreakdown = ({
  bid,
}: {
  readonly bid: BidInfoCardBidFragment;
}) => {
  const { t } = useTranslation();

  const { pricePerShare, commission, feeDiscountApplications } = bid;
  const { commissionAmount, flatFeeAmount, feeDiscountAmount, netFees } =
    commission || {};
  const numberOfShares = getBidNumSharesActual(bid);

  return (
    <FeeBreakdown
      numShares={numberOfShares}
      pricePerShare={pricePerShare}
      feeDiscountApplications={feeDiscountApplications}
      flatFeeAmount={flatFeeAmount}
      commissionAmount={commissionAmount}
      feeDiscountAmount={feeDiscountAmount}
      netFees={netFees}
      combineHiiveFeesLabel={t(`hiive_co_broker_fee`)}
      combineHiiveFeesTooltip={t(`hiive_co_broker_fee_tooltip`)}
      netProceedsTitle={t(`broker_seller_proceeds`)}
      netProceedsSubtitle={t(`gross_of_your_brokerage_fees`)}
      netProceedsTooltip={t(`broker_seller_proceeds_tooltip`)}
      disclaimerVariant="brokerSeller"
    />
  );
};
