import { Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  FormCountriesCombobox,
  FormikQL,
  QuestionTooltip,
  RadioCard,
  FormCustomRadioInput,
} from "@/components/form";
import {
  CurrentContextDocument,
  useUpdateBrokerResidenceMutation,
  useCountriesQuery,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";
import { fromBool, toBool } from "@/utils";

const initialValues = {
  countryId: ``,
  finraRegistered: null,
};

const createValidationSchema = () => () =>
  Yup.object().shape({
    countryId: Yup.string().required(`Country is required`),
    finraRegistered: Yup.boolean().nullable().required(`Answer is required`),
  });

const BrokerResidenceForm = () => {
  const { t } = useTranslation();

  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useUpdateBrokerResidenceMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => {
    routeToNextStep();
  };

  const validationSchema = createValidationSchema();

  return (
    <FormikQL
      query={useCountriesQuery()}
      mutation={mutation}
      mutationNames={[`updateBrokerResidence`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, values }) => (
        <Form autoComplete="off">
          <VStack spacing={9}>
            <FormCountriesCombobox
              name="countryId"
              label="Where are you located?"
              placeholder="Select Country"
            />
            {values.countryId && (
              <FormCustomRadioInput
                name="finraRegistered"
                mapper={{ to: toBool, from: fromBool }}
              >
                {({ getRootProps, getRadioProps }) => (
                  <Stack direction={{ base: `column`, sm: `row` }} spacing={3}>
                    <Text>
                      {t(`are_you_registered_with_finra`)}
                      <QuestionTooltip tooltipContent="If you, your firm, or your customers are located in the US, then FINRA registration is required. Otherwise, you can proceed by selecting 'No'." />
                    </Text>
                    <HStack
                      data-testid="finra-registered"
                      spacing={{ base: 3, md: 4 }}
                      {...getRootProps()}
                    >
                      <RadioCard
                        h="3.75rem"
                        w={20}
                        boxProps={{ w: `auto` }}
                        {...getRadioProps({ value: `true` })}
                      >
                        Yes
                      </RadioCard>
                      <RadioCard
                        h="3.75rem"
                        w={20}
                        boxProps={{ w: `auto` }}
                        {...getRadioProps({ value: `false` })}
                      >
                        No
                      </RadioCard>
                    </HStack>
                  </Stack>
                )}
              </FormCustomRadioInput>
            )}
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[BrokerResidenceForm] Next"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

const BrokerResidencePage = () => (
  <Box mt={{ base: `1rem`, md: `3rem` }}>
    <BrokerResidenceForm />
  </Box>
);

export default BrokerResidencePage;
