import { FeedItem } from "@knocklabs/client";

import { Holding, HoldingV2, ListedHoldingFragment } from "@/gql";

type NotificationArray = Array<Pick<FeedItem, `data`>>;

type HoldingsArray = Array<Pick<HoldingV2, `__typename` | `id`>>;

type HoldingNotification = {
  readonly companyId: string;
  readonly notifications: NotificationArray;
};

type GetNotificationForHoldingProps = {
  readonly holding: Holding;
  readonly notifications: NotificationArray;
};

type MapHoldingNotificationsProps = {
  readonly currentHolding?: ListedHoldingFragment;
  readonly holdings: HoldingsArray;
  readonly notifications: NotificationArray;
};

type GetNotifiedHoldingsProps = {
  readonly holdingNotifications: Array<HoldingNotification>;
  readonly holdings: HoldingsArray;
};

type DoesHoldingHaveNotification = {
  readonly holdingNotifications: Array<HoldingNotification>;
  readonly holding: Pick<Holding, `__typename` | `id` | `companyId`>;
};

function getNotificationsForHolding({
  holding,
  notifications,
}: GetNotificationForHoldingProps) {
  return notifications.filter(
    (notification) =>
      notification?.data?.company_id === holding.companyId ||
      notification?.data?.company_name === holding.company.name,
  );
}

export function mapHoldingNotifications({
  holdings,
  notifications,
  currentHolding,
}: MapHoldingNotificationsProps) {
  const holdingNotifications = holdings
    .filter((holding: HoldingV2) => holding.__typename === `Holding`)
    .map((holding: Holding) => ({
      companyId: holding?.companyId,
      notifications: getNotificationsForHolding({ holding, notifications }),
    }));

  switch (currentHolding?.__typename) {
    case `Holding`:
      return holdingNotifications.filter(
        (holdingNotification) =>
          holdingNotification.companyId !== currentHolding.companyId,
      );
    default:
      return holdingNotifications;
  }
}

export function getNotifiedHoldings({
  holdingNotifications,
  holdings,
}: GetNotifiedHoldingsProps) {
  return holdings.filter(
    (holding: Holding) =>
      (
        holdingNotifications.find(
          (holdingNotification) =>
            holdingNotification.companyId === holding.companyId,
        )?.notifications || []
      )?.length > 0,
  );
}

export function doesHoldingHaveNotification({
  holding,
  holdingNotifications,
}: DoesHoldingHaveNotification) {
  const holdingNotification = holdingNotifications.find(
    (holdingNotification) =>
      holdingNotification.companyId === holding.companyId,
  );

  return (
    !!holdingNotification?.notifications &&
    holdingNotification.notifications.length > 0
  );
}
