import { Form } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

import { VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  TextInput,
  RadioCardInput,
  FormikQL,
  FormCountriesCombobox,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  CreateInstitutionInput,
  CurrentContextDocument,
  InstitutionDetailsMutationVariables,
  UpdateUserInput,
  useInstitutionDetailsMutation,
  UserWithInstitutionFragment,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";
import { toBool } from "@/utils";

const initialValues = {
  countryId: ``,
  legalName: ``,
  jobTitle: ``,
  isBrokerDealer: `false`,
};

type InstitutionDetailsFormValues = Pick<
  CreateInstitutionInput,
  "countryId" | "legalName"
> & { readonly isBrokerDealer: string } & Pick<UpdateUserInput, "jobTitle">;

const validationSchema = Yup.object().shape({
  countryId: Yup.string().required(`Country is required`),
  legalName: Yup.string().required(`Legal name is required`),
  jobTitle: Yup.string().required(`Job title is required`),
  isBrokerDealer: Yup.boolean().required(`Required`),
});

const mapVariables = ({
  jobTitle,
  isBrokerDealer,
  countryId,
  legalName,
}: InstitutionDetailsFormValues): InstitutionDetailsMutationVariables => ({
  createInstitutionInput: {
    isBrokerDealer: !!toBool(isBrokerDealer),
    countryId,
    legalName,
  },
  updateUserInput: {
    jobTitle,
  },
});

const InstitutionDetailsPage = ({
  actor: { institutionId },
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();
  const hasCompletedInstitutionDetails = !!institutionId;

  useEffect(() => {
    if (!hasCompletedInstitutionDetails) return;
    routeToNextStep();
  }, []);

  const mutation = useInstitutionDetailsMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => routeToNextStep();

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`createInstitution`, `updateUser`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <VStack spacing={5} mt={5}>
            <FormCountriesCombobox
              name="countryId"
              label="Where is your firm/fund located?"
            />
            <TextInput
              name="legalName"
              label="What is the full legal name of your firm/fund?"
              isRequired
              placeholder="Firm/fund name"
            />
            <TextInput
              name="jobTitle"
              label="What is your position in the firm/fund?"
              isRequired
              placeholder="Position"
            />
            <RadioCardInput
              name="isBrokerDealer"
              label="Are you a broker-dealer, intermediary or agent acting on behalf of an unrelated client?"
              options={[
                { value: `true`, label: `Yes` },
                { value: `false`, label: `No` },
              ]}
              cardSize="md"
            />
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[InstitutionDetailsPage] Next"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

export default withCurrentActor(InstitutionDetailsPage);
