import { Card, CardBody, CardFooter, SimpleGrid } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

const ListingFeeBreakdownSkeleton = () => (
  <Card boxShadow="none" w="full">
    <CardBody>
      <SimpleGrid
        gridTemplateColumns="1fr min-content"
        alignItems="baseline"
        rowGap={8}
      >
        <Skeleton h="14px" w="128px" />
        <Skeleton h="14px" w="40px" />
        <Skeleton h="14px" w="112px" />
        <Skeleton h="14px" w="40px" />
        <Skeleton h="14px" w="132px" />
        <Skeleton h="14px" w="40px" />
      </SimpleGrid>
    </CardBody>
    <CardFooter bg="grey.25" py={5}>
      <SimpleGrid
        gridTemplateColumns="1fr min-content"
        alignItems="baseline"
        w="full"
      >
        <Skeleton h="14px" w="168px" />
        <Skeleton h="14px" w="40px" />
      </SimpleGrid>
    </CardFooter>
  </Card>
);

export default ListingFeeBreakdownSkeleton;
