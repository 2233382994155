import * as Yup from "yup";

import { UserWithInstitutionFragment } from "@/gql";

const representedEmailSchema = (actor: UserWithInstitutionFragment) =>
  actor.isHiiveUser
    ? Yup.string().email(`Invalid email address`).required(`Required`)
    : Yup.string().email(`Invalid email address`);

const representedNameSchema = (actor: UserWithInstitutionFragment) =>
  actor.isHiiveUser
    ? Yup.string().nullable().required(`Required`)
    : Yup.string().nullable();

export { representedEmailSchema, representedNameSchema };
