import { useMemo, useState } from "react";

import { Center, Flex, Spinner, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FilterTypes, getFilterValue } from "@/components/companies";
import { Industry, useCompaniesIndustriesQuery } from "@/gql";

import { FilterTag } from "./FilterTag";

export const IndustryList = ({
  search,
  filters,
  onFilterChange,
}: {
  readonly search: string;
  readonly onFilterChange: (
    field: keyof FilterTypes,
    value: string | readonly string[],
  ) => void;
  readonly filters: FilterTypes;
}) => {
  const { data, loading, error } = useCompaniesIndustriesQuery();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const getIsIndustrySelected = (industryId: string) =>
    filters.industries.includes(industryId);

  const toggleIndustrySelected = (industryId: string) => {
    const currentIndustries = getFilterValue(filters.industries);

    if (getIsIndustrySelected(industryId)) {
      const nextIndustries = currentIndustries.filter(
        (industry) => industry !== industryId,
      );
      onFilterChange(`industries`, nextIndustries);
    } else {
      onFilterChange(`industries`, [...currentIndustries, industryId]);
    }
  };

  const displayedIndustries = useMemo(() => {
    const industriesFilteredBySearchText =
      data?.companiesIndustries.filter((industry) =>
        industry.name.toLowerCase().includes(search.toLowerCase()),
      ) || [];
    return isExpanded
      ? industriesFilteredBySearchText
      : industriesFilteredBySearchText.slice(0, 20);
  }, [data?.companiesIndustries, isExpanded, search]);

  if (error) return null;
  if (loading || !data?.companiesIndustries) {
    return (
      <Center boxSize="full">
        <Spinner />
      </Center>
    );
  }

  const isListExpandable = displayedIndustries.length >= 20;

  return displayedIndustries.length > 0 ? (
    <Flex direction="column" gap={2}>
      <Flex gap={1} flexWrap="wrap" maxW="lg" maxH="sm" overflowY="auto">
        {displayedIndustries.map((industry: Industry) => (
          <FilterTag
            key={industry.id}
            id={industry.id}
            name={industry.name}
            selected={getIsIndustrySelected(industry.id)}
            onClick={toggleIndustrySelected}
          />
        ))}
      </Flex>
      {isListExpandable && (
        <HiiveButton
          px={0}
          alignSelf="flex-start"
          variant="text-salmon"
          onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
          sentryLabel="[IndustryList] Toggle"
        >
          {isExpanded ? `Collapse` : `Expand`}
        </HiiveButton>
      )}
    </Flex>
  ) : (
    <Text>No results found.</Text>
  );
};
