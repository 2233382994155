import { t } from "i18next";

import { useRouter } from "next/router";

import { Card, CardBody, HStack, VStack, Text, Button } from "@chakra-ui/react";

import { ActionNeeded } from "@/components/common";
import { HoldingCompanyLogo } from "@/components/unaccredited-seller";
import { ListedHoldingFragment, UnlistedHoldingFragment } from "@/gql";
import { formatShares } from "@/utils";

export const ListedHolding = ({
  holding,
}: {
  holding: ListedHoldingFragment;
}) => {
  const router = useRouter();

  const { company, numSharesV2 } = holding;

  return (
    <Card variant="flat" data-testid={company.name}>
      <CardBody>
        <VStack alignItems="start" spacing={4}>
          <HStack w="full" justifyContent="space-between">
            <HStack>
              <HoldingCompanyLogo
                logoUrl={company.logoUrl}
                name={company.name}
              />
              <Text textStyle="heading-md" noOfLines={1} maxW={44}>
                {company.name}
              </Text>
            </HStack>
            <Button
              variant="boxed-grey"
              size="sm"
              onClick={() => router.push(`/dashboard/${company.id}`)}
            >
              <Text>{t(`dashboard`)}</Text>
            </Button>
          </HStack>
          <VStack alignItems="start" spacing={1}>
            <Text textStyle="heading-md">{t(`shares_you_own`)}</Text>
            <Text>{numSharesV2 ? formatShares(numSharesV2) : `-`}</Text>
          </VStack>
        </VStack>
      </CardBody>
    </Card>
  );
};

export const UnListedHolding = ({
  holding,
}: {
  holding: UnlistedHoldingFragment;
}) => (
  <Card variant="flat" h="full" data-testid={holding.companyName}>
    <CardBody>
      <VStack alignItems="start" spacing={4}>
        <HStack w="full" justifyContent="space-between">
          <HStack>
            <HoldingCompanyLogo name={holding.companyName} />
            <Text
              color="grey.100"
              textStyle="heading-md"
              noOfLines={1}
              maxW={44}
            >
              {holding.companyName}
            </Text>
          </HStack>
          <Text color="grey.500" textStyle="text-sm">
            {t(`unavailable`)}
          </Text>
        </HStack>
        <ActionNeeded variant="warning" noHeading>
          <Text textStyle="text-sm">{t(`unlisted_holding_needs_action`)}</Text>
        </ActionNeeded>
      </VStack>
    </CardBody>
  </Card>
);
