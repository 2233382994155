import { TabListProps, TabList } from "@chakra-ui/react";

const MobileTabList = ({ children, ...props }: TabListProps) => (
  <TabList
    {...props}
    position="fixed"
    h="mobile-tab-list-footer-height"
    w="full"
    borderBottom="none"
    boxShadow="0px 4px 10px 6px rgba(0, 0, 0, 0.05)"
    bottom={0}
    left={0}
    right={0}
    zIndex="overlay"
  >
    {children}
  </TabList>
);

export default MobileTabList;
