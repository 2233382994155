import { ContentBlock, FeedItem } from "@knocklabs/client";
import { WarningCircle } from "@phosphor-icons/react";
import { Trans } from "react-i18next";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ComplianceReminderLink, Skeleton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { useColors, useNotifications } from "@/hooks";
import { useSuppressNotifications } from "@/hooks/featureFlags";
import * as datetime from "@/utils/datetime";

const removeHTMLTags = (str: string) => str.replace(/<\/?[^>]+(>|$)/g, ``);

const toHtml = (block: ContentBlock) => {
  switch (block.type) {
    case `text`:
      return removeHTMLTags(block.rendered);
    case `markdown`:
      return removeHTMLTags(block.rendered);
    case `button_set`:
      return ``;
    default:
      return ``;
  }
};

const DashboardNotification = ({
  notification,
  onClickNotification,
}: {
  readonly notification: FeedItem;
  readonly onClickNotification: (notification: FeedItem) => void;
}) => (
  <Card
    onClick={() => onClickNotification(notification)}
    variant="notification-subcard"
    as="button"
    w="full"
  >
    <CardBody w="full">
      <HStack w="full" justifyContent="space-between" alignItems="flex-end">
        <VStack alignItems="flex-start">
          <Text textAlign="start" textStyle="text-sm">
            {toHtml(notification.blocks[0])}
          </Text>
          <Text textStyle="deprecated-text-2xs" color="grey.700">
            {datetime.format(`Do MMM (h:mm A z)`, notification.inserted_at)}
          </Text>
        </VStack>
      </HStack>
    </CardBody>
  </Card>
);

const DashboardNotificationsEmptyState = () => (
  <VStack>
    <Text textAlign="center" maxW="sm">
      You have no unread notifications.
    </Text>
  </VStack>
);

const DashboardNotificationsList = withCurrentActor(
  ({
    actor,
    userNotificationToken,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly userNotificationToken: string;
  }) => {
    const [red600] = useColors([`red.600`]);
    const {
      feedData,
      loading,
      notificationFeed,
      user: knockUser,
    } = useNotifications({
      actor,
      userNotificationToken,
    });

    const notifications = feedData?.entries;
    const suppressNotificationsEnabled = useSuppressNotifications();

    const router = useRouter();

    const onClickNotification = (notification: FeedItem) => {
      notificationFeed.markAsRead(notification);
      notificationFeed.markAsSeen(notification);

      if (notification.data?.cta_url) {
        router.push(notification.data?.cta_url);
        return;
      }

      if (notification.data?.cta_link) {
        router.push(notification.data?.cta_link);
      }
    };

    if (
      suppressNotificationsEnabled &&
      knockUser &&
      !knockUser.fully_registered
    ) {
      return (
        <HStack gap="2" data-testid="compliance-reminder" alignItems="center">
          <Box flexShrink={0}>
            <WarningCircle color={red600} size={20} weight="fill" />
          </Box>
          <Text textStyle="text-sm">
            <Trans
              i18nKey="complete_your_profile_to_receive_notifications"
              components={{
                italic: <ComplianceReminderLink />,
              }}
            />
          </Text>
        </HStack>
      );
    }

    if (loading || !notifications) {
      return (
        <VStack spacing={4}>
          <Skeleton h={20} count={3} />
        </VStack>
      );
    }

    if (notifications.length === 0) return <DashboardNotificationsEmptyState />;

    return (
      <VStack spacing={4}>
        {notifications.map((notification) => (
          <DashboardNotification
            onClickNotification={onClickNotification}
            notification={notification}
            key={notification.id}
          />
        ))}
      </VStack>
    );
  },
);

export const DashboardNotifications = withCurrentActor(() => (
  <Card w="full">
    <CardHeader>
      <Text textStyle="heading-sm">Latest Notifications</Text>
    </CardHeader>
    <CardBody maxH="xs" overflowY="auto">
      <DashboardNotificationsList />
    </CardBody>
  </Card>
));
