import useEventListener from "@use-it/event-listener";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { CustomEvents, useModal } from "@/hooks";

import { InstitutionInvitationsTable } from "./InstitutionInvitationsTable";
import { InstitutionUsersTable } from "./InstitutionUsersTable";

const ManageInstitutionUsersCard = () => {
  const { t } = useTranslation();
  const { modals, onOpenModal } = useModal();
  const [tabIndex, setTabIndex] = useState(0);
  useEventListener(CustomEvents.UserInvitationSent, () => setTabIndex(1));

  return (
    <Card w="full" flex="1">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`account_users`)}</Text>
      </CardHeader>
      <CardBody>
        <Tabs variant="underline" index={tabIndex} onChange={setTabIndex}>
          <HStack alignItems="center" justifyContent="space-between" mb={5}>
            <TabList alignItems="center" pb={0}>
              <Tab>{t(`users`)}</Tab>
              <Tab>{t(`invites`)}</Tab>
            </TabList>
            <HiiveButton
              onClick={onOpenModal(modals.inviteInstitutionUser())}
              size="xl"
              variant="rounded-solid-salmon"
              sentryLabel="[ManageInstitutionUsersCard] Invite"
            >
              {t(`invite`)}
            </HiiveButton>
          </HStack>
          <TabPanels>
            <TabPanel>
              <InstitutionUsersTable />
            </TabPanel>
            <TabPanel>
              <InstitutionInvitationsTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
};
export default ManageInstitutionUsersCard;
