import { useUser } from "@frigade/react";
import { Form } from "formik";
import { useEffect } from "react";

import { HStack, Link, Radio, Spacer, Text, VStack } from "@chakra-ui/react";

import {
  IndividualTraderCustomerAgreement,
  HiiveButton,
} from "@/components/common";
import { FormikQL, FormRadioInput } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  CurrentContextDocument,
  UserWithInstitutionFragment,
  useUpdateAgreedToCustomerAgreementMutation,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

const initialValues = {
  agreedToCustomerAgreement: null,
};

const mapVariables = ({
  agreedToCustomerAgreement,
}: {
  readonly agreedToCustomerAgreement: string | null;
}) => ({
  agreedToCustomerAgreement: agreedToCustomerAgreement === `true`,
});

const IndividualTraderCustomerAgreementPage = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { addPropertiesToUser } = useUser();
    const routeToNextStep = useRouteToNextOnboardingStep();

    const mutation = useUpdateAgreedToCustomerAgreementMutation({
      refetchQueries: [CurrentContextDocument],
    });

    useEffect(() => {
      if (!actor.agreedToCustomerAgreement) return;
      routeToNextStep();
    }, []);

    const onSuccess = () => {
      addPropertiesToUser({ agreedToCustomerAgreement: true });
      routeToNextStep();
    };

    return (
      <VStack
        maxW={{ base: `none`, md: `40rem`, lg: `50rem` }}
        mx={{
          base: `auto`,
          md: `calc((100% - 40rem) / 2)`,
          lg: `calc((100% - 50rem) / 2)`,
        }}
        spacing={4}
      >
        <Text align="center" color="grey.900">
          Before you can post your own listing, or respond to potential buyers’
          bids, you must read and agree to the Customer Agreement.
        </Text>
        <Text align="center" color="grey.900">
          The Customer Agreement references the Hiive Terms and Conditions which
          can be found{` `}
          <Link
            fontWeight="medium"
            href="/terms-and-conditions"
            target="_blank"
          >
            here
          </Link>
          .
        </Text>
        <IndividualTraderCustomerAgreement />
        <FormikQL
          initialValues={initialValues}
          mapVariables={mapVariables}
          mutation={mutation}
          mutationNames={[`updateAgreedToCustomerAgreement`]}
          onSuccess={onSuccess}
        >
          {({ isSubmitting, values }) => (
            <Form autoComplete="off">
              <Text textAlign="center" my={4}>
                By clicking the &quot;agree&quot; button below I acknowledge
                that I understand and agree to the above.
              </Text>
              <VStack spacing={8}>
                <FormRadioInput name="agreedToCustomerAgreement">
                  <HStack w="full" justifyContent="center" spacing={4}>
                    <Radio value="false" variant="base">
                      Decline
                    </Radio>
                    <Radio value="true" variant="base">
                      Agree
                    </Radio>
                  </HStack>
                </FormRadioInput>
                {!!values.agreedToCustomerAgreement ? (
                  <HiiveButton
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    sentryLabel="[IndividualTraderCustomerAgreementPage] Next"
                    size="md"
                    type="submit"
                    variant="rounded-solid-salmon"
                  >
                    Next
                  </HiiveButton>
                ) : (
                  <Spacer h={10} />
                )}
              </VStack>
            </Form>
          )}
        </FormikQL>
      </VStack>
    );
  },
);

export default IndividualTraderCustomerAgreementPage;
