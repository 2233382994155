import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";
import { useCurrentActor } from "@/hooks";
import { getIsBroker } from "@/utils";

import { WatchlistCompaniesSearch } from "./WatchlistCompaniesSearch";

const WatchlistPage = () => {
  const { t } = useTranslation();
  const actor = useCurrentActor();
  const isBroker = getIsBroker(actor);

  return (
    <OnboardingContainerV2
      canGoBack={!isBroker}
      metaTitle={t(`watchlist_page_title`)}
    >
      <Flex direction="column" maxW="40rem">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text textStyle="heading-3xl">{t(`watchlist_page_title`)} *</Text>
          <Text>{t(`watchlist_page_description`)}</Text>
          <Text as="strong" textStyle="heading-xs">
            {t(`watchlist_page_select_at_least_one`)}
          </Text>
        </VStack>

        <WatchlistCompaniesSearch />
      </Flex>
    </OnboardingContainerV2>
  );
};

export default WatchlistPage;
