import { Form } from "formik";

import { useRouter } from "next/router";

import { SimpleGrid, GridItem, Text, ModalBody, Show } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormikQL } from "@/components/form";
import {
  PostActivityUnaccreditedSellerDashboardPageActiveBidsPageDocument,
  UnaccreditedSellerWithdrawListingModalListingFragment,
  useUnaccreditedSellerWithdrawListingMutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";

interface UnaccreditedSellerWithdrawListingModalProps {
  readonly listing: UnaccreditedSellerWithdrawListingModalListingFragment;
}

const UnaccreditedSellerWithdrawListingModal = ({
  listing,
}: UnaccreditedSellerWithdrawListingModalProps) => {
  const mutation = useUnaccreditedSellerWithdrawListingMutation({
    refetchQueries: [
      PostActivityUnaccreditedSellerDashboardPageActiveBidsPageDocument,
    ],
  });

  const router = useRouter();

  const { closeModal } = useModal();

  const { successToast } = useCustomToast();

  const onSuccess = () => {
    closeModal();
    successToast(`Listing withdrawn.`);

    router.push(`/dashboard`);
  };

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        initialValues={{}}
        mutation={mutation}
        mutationNames={[`unaccreditedSellerWithdrawListing`]}
        onSuccess={onSuccess}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <HiiveModalHeader>
              <Text as="span">Withdraw listing{` `}</Text>
              <Text as="span">{listing.displayId}</Text>
            </HiiveModalHeader>
            <ModalBody>
              <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
                <GridItem colSpan={2}>
                  <Text textStyle="text-md">
                    If you withdraw this listing, we will cancel any bids on it
                    and notify the corresponding bidders.
                  </Text>
                  <Text mt={2} textStyle="text-md">
                    After you withdraw, we will redirect you to the original
                    marketplace page where you first logged in, and you can
                    create a new listing from there.
                  </Text>
                </GridItem>
              </SimpleGrid>
            </ModalBody>
            <HiiveModalFooter>
              <Show above="md" ssr={false}>
                <HiiveCancelButton
                  sentryLabel="[UnaccreditedSellerWithdrawListing/Cancel]"
                  onCancel={closeModal}
                />
              </Show>
              <HiiveSubmitButton
                sentryLabel="[UnaccreditedSellerWithdrawListing/Submit]"
                submitText="Withdraw"
                type="submit"
                isLoading={isSubmitting}
              />
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default UnaccreditedSellerWithdrawListingModal;
