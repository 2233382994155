import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { ModalBody, Text } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";

const DisableAppMfaSuccessModal = () => {
  const { t } = useTranslation();
  const { reload } = useRouter();

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`removal_complete`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <Text>{t(`remove_mfa_success_message`)}</Text>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            sentryLabel="[DisableAppMfaSuccess/Submit]"
            submitText={t(`refresh_page`)}
            onClick={() => reload()}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default DisableAppMfaSuccessModal;
