import { match } from "ts-pattern";

import { InvestorType, InvestorStatus, OnboardingUserFragment } from "@/gql";
import { canAccessPlatform } from "@/hooks";
import { FeatureFlags } from "@/hooks/featureFlags";
import { getIsBroker } from "@/utils";

export enum OnboardingRoutes {
  IndividualAccreditation = `/onboarding/individual/accreditation`,
  IndividualConsent = `/onboarding/individual/consent`,
  IndividualCustomerAgreement = `/onboarding/individual/customer-agreement`,
  IndividualCustomerAgreementDeclined = `/onboarding/individual/customer-agreement-declined`,
  IndividualNeedsFurtherVerification = `/onboarding/individual/needs-further-verification`,

  // Broker
  BrokerWatchlist = `/onboarding/broker/watchlist`,
  BrokerResidence = `/onboarding/broker/broker-residence`,
  BrokerInformation = `/onboarding/broker/broker-information`,
  BrokerIntermediaryInformation = `/onboarding/broker/broker-intermediary-information`,
  BrokerCustomerAgreement = `/onboarding/broker/broker-customer-agreement`,
  BrokerCustomerAgreementDeclined = `/onboarding/broker/broker-customer-agreement-declined`,
  BrokerNeedsFurtherVerification = `/onboarding/broker/needs-further-verification`,

  // Canada
  InstitutionCanadaBuildProfile = `/onboarding/institution/ca/build-profile`,

  InstitutionDetails = `/onboarding/institution/details`,
  InstitutionAccreditation = `/onboarding/institution/accreditation`,
  InstitutionBuildProfile = `/onboarding/institution/build-profile`,
  InstitutionCustomerAgreement = `/onboarding/institution/customer-agreement`,
  InstitutionSuitability = `/onboarding/institution/suitability`,
  InstitutionConsent = `/onboarding/institution/consent`,
  InstitutionNeedsFurtherVerification = `/onboarding/institution/needs-further-verification`,
  InstitutionApprovalWait = `/onboarding/institution/approval-wait`,

  IndividualWatchlist = `/onboarding/individual/watchlist`,
  InstitutionalWatchlist = `/onboarding/institution/watchlist`,

  IdentityVerification = `/onboarding/identity-verification`,
  InvestorType = `/onboarding/investor-type`,
  InvestorStatus = `/onboarding/investor-status`,
  RequestEmailVerification = `/onboarding/request-email-verification`,
  VerifyEmail = `/verify-email/[token]`,

  // Seller
  SellerInfo = `/onboarding/seller/information`,
  SellerResidence = `/onboarding/seller/residence`,
  SellerLotDetails = `/onboarding/seller/lot-details`,
  SellerAgreement = `/onboarding/seller/agreement`,
  CustomerAgreementDeclined = `/onboarding/seller/customer-agreement-declined`,
}

export interface OnboardingStep {
  readonly route: string;
  readonly isComplete: (user: OnboardingUserFragment) => boolean;
  readonly getSubsequentStepRoute: (
    user: OnboardingUserFragment,
    featureFlags: FeatureFlags,
  ) => OnboardingRoutes | null;
}

export const onboardingSteps: Record<OnboardingRoutes, OnboardingStep> = {
  [OnboardingRoutes.InvestorStatus]: {
    route: OnboardingRoutes.InvestorStatus,
    isComplete: ({ investorStatus, investorType }) =>
      investorStatus === InvestorStatus.Institutional
        ? !!(investorStatus && investorType)
        : !!investorStatus,
    getSubsequentStepRoute: ({ investorStatus }) =>
      match({ investorStatus })
        .with(
          {
            investorStatus: InvestorStatus.Individual,
          },
          () => OnboardingRoutes.InvestorType,
        )
        .with(
          {
            investorStatus: InvestorStatus.Institutional,
          },
          () => OnboardingRoutes.InstitutionalWatchlist,
        )
        .run(),
  },
  [OnboardingRoutes.InvestorType]: {
    route: OnboardingRoutes.InvestorType,
    isComplete: ({ investorStatus, investorType }) =>
      !!investorStatus && !!investorType,
    getSubsequentStepRoute: ({ investorType }) =>
      match({ investorType })
        .with(
          {
            investorType: InvestorType.Broker,
          },
          () => OnboardingRoutes.BrokerWatchlist,
        )
        .with(
          {
            investorType: InvestorType.Trader,
          },
          () => OnboardingRoutes.IndividualAccreditation,
        )
        .with(
          {
            investorType: InvestorType.UnaccreditedSeller,
          },
          () => OnboardingRoutes.SellerInfo,
        )
        .otherwise(() => {
          throw new Error(
            `[onboarding - getSubsequentStepRoute] Unexpected investor type ${investorType}`,
          );
        }),
  },
  [OnboardingRoutes.SellerInfo]: {
    route: OnboardingRoutes.SellerInfo,
    isComplete: ({ hasHolding }) => hasHolding,
    getSubsequentStepRoute: () => OnboardingRoutes.SellerResidence,
  },
  [OnboardingRoutes.SellerLotDetails]: {
    route: OnboardingRoutes.SellerLotDetails,
    isComplete: ({ hasHolding }) => hasHolding,
    getSubsequentStepRoute: () => OnboardingRoutes.SellerAgreement,
  },
  [OnboardingRoutes.SellerAgreement]: {
    route: OnboardingRoutes.SellerAgreement,
    isComplete: ({ agreedToCustomerAgreement }) => !!agreedToCustomerAgreement,
    getSubsequentStepRoute: ({ agreedToCustomerAgreement }) => {
      if (agreedToCustomerAgreement) {
        return OnboardingRoutes.RequestEmailVerification;
      }
      return OnboardingRoutes.CustomerAgreementDeclined;
    },
  },
  [OnboardingRoutes.CustomerAgreementDeclined]: {
    route: OnboardingRoutes.CustomerAgreementDeclined,
    isComplete: () => false,
    getSubsequentStepRoute: () => null,
  },
  [OnboardingRoutes.SellerResidence]: {
    route: OnboardingRoutes.SellerResidence,
    isComplete: ({ country }) => !!country,
    getSubsequentStepRoute: () => OnboardingRoutes.SellerLotDetails,
  },
  [OnboardingRoutes.InstitutionDetails]: {
    route: OnboardingRoutes.InstitutionDetails,
    isComplete: ({ institutionId }) => !!institutionId,
    getSubsequentStepRoute: ({ institution }: OnboardingUserFragment) => {
      if (institution?.isBrokerDealer) {
        return OnboardingRoutes.InstitutionNeedsFurtherVerification;
      }
      return OnboardingRoutes.InstitutionAccreditation;
    },
  },
  [OnboardingRoutes.InstitutionAccreditation]: {
    route: OnboardingRoutes.InstitutionAccreditation,
    isComplete: ({ institution }: OnboardingUserFragment) => {
      if (
        institution?.country.name === `CH` ||
        institution?.country.name === `UK`
      ) {
        return institution?.accreditationAnswers.length === 2;
      }

      return (institution?.accreditationAnswers?.length ?? 0) > 0;
    },
    getSubsequentStepRoute: ({ institution }) =>
      match({ country: institution?.country })
        .with(
          { country: { name: `CA` } },
          () => OnboardingRoutes.InstitutionCanadaBuildProfile,
        )
        .otherwise(() => OnboardingRoutes.InstitutionBuildProfile),
  },
  [OnboardingRoutes.InstitutionCanadaBuildProfile]: {
    route: OnboardingRoutes.InstitutionCanadaBuildProfile,
    isComplete: ({ institution }: OnboardingUserFragment) =>
      !!institution?.entityType && !!institution?.streetAddress,
    getSubsequentStepRoute: () => OnboardingRoutes.InstitutionCustomerAgreement,
  },
  [OnboardingRoutes.InstitutionBuildProfile]: {
    route: OnboardingRoutes.InstitutionBuildProfile,
    isComplete: ({ institution }: OnboardingUserFragment) => {
      if (
        (institution?.country.name !== `UK` && !institution?.entityType) ||
        !institution?.jurisdictionOfIncorporation ||
        !institution?.streetAddress
      )
        return false;
      return true;
    },
    getSubsequentStepRoute: ({ institution }) => {
      if (
        institution?.country.name === `US` ||
        institution?.country.name === `CA`
      )
        return OnboardingRoutes.InstitutionCustomerAgreement;

      if (institution?.accredited && institution?.country.name === `IN`) {
        return OnboardingRoutes.InstitutionConsent;
      }
      if (institution?.accredited)
        return OnboardingRoutes.InstitutionCustomerAgreement;
      return OnboardingRoutes.RequestEmailVerification;
    },
  },
  [OnboardingRoutes.InstitutionSuitability]: {
    route: OnboardingRoutes.InstitutionSuitability,
    isComplete: ({ institution }: OnboardingUserFragment) => {
      if (
        institution?.country.name === `CH` ||
        institution?.country.name === `UK` ||
        (institution?.investmentGoalOptions?.length ?? 0) > 0 ||
        institution?.isFinraInstitutionAccount
      )
        return true;

      return false;
    },
    getSubsequentStepRoute: ({ institution }: OnboardingUserFragment) => {
      if (institution?.accredited) {
        return OnboardingRoutes.InstitutionCustomerAgreement;
      }
      return OnboardingRoutes.RequestEmailVerification;
    },
  },
  [OnboardingRoutes.InstitutionConsent]: {
    route: OnboardingRoutes.InstitutionConsent,
    isComplete: () => false,
    getSubsequentStepRoute: () => OnboardingRoutes.InstitutionCustomerAgreement,
  },
  [OnboardingRoutes.InstitutionNeedsFurtherVerification]: {
    route: OnboardingRoutes.InstitutionNeedsFurtherVerification,
    isComplete: () => false,
    getSubsequentStepRoute: () => null,
  },
  [OnboardingRoutes.InstitutionApprovalWait]: {
    route: OnboardingRoutes.InstitutionApprovalWait,
    isComplete: (actor) => canAccessPlatform(actor),
    getSubsequentStepRoute: () => null,
  },
  [OnboardingRoutes.IndividualWatchlist]: {
    route: OnboardingRoutes.IndividualWatchlist,
    isComplete: ({ watchlist }) => watchlist.length > 0,
    getSubsequentStepRoute: ({
      investorType,
      accredited,
      country,
      institutionId,
    }) => {
      if (country?.name === `IN`) {
        if (accredited) return OnboardingRoutes.IndividualConsent;
        return OnboardingRoutes.RequestEmailVerification;
      }

      if (investorType === InvestorType.Seller) {
        if (!accredited) return OnboardingRoutes.RequestEmailVerification;
      }

      if (investorType === InvestorType.Trader && !institutionId) {
        return OnboardingRoutes.IndividualCustomerAgreement;
      }

      return OnboardingRoutes.InstitutionCustomerAgreement;
    },
  },
  [OnboardingRoutes.InstitutionalWatchlist]: {
    route: OnboardingRoutes.InstitutionalWatchlist,
    isComplete: ({ watchlist }) => watchlist.length > 0,
    getSubsequentStepRoute: () => OnboardingRoutes.InstitutionDetails,
  },
  [OnboardingRoutes.BrokerWatchlist]: {
    route: OnboardingRoutes.BrokerWatchlist,
    isComplete: ({ watchlist }) => watchlist.length > 0,
    getSubsequentStepRoute: () => OnboardingRoutes.BrokerResidence,
  },
  [OnboardingRoutes.BrokerResidence]: {
    route: OnboardingRoutes.BrokerResidence,
    isComplete: ({ brokerInfo }) => !!brokerInfo,
    getSubsequentStepRoute: ({ country, finraRegistered }) =>
      [`US`, `CA`].includes(country?.name ?? ``) || !!finraRegistered
        ? OnboardingRoutes.BrokerInformation
        : OnboardingRoutes.BrokerIntermediaryInformation,
  },
  [OnboardingRoutes.BrokerInformation]: {
    route: OnboardingRoutes.BrokerInformation,
    isComplete: ({ brokerInfo }) =>
      !!brokerInfo?.firstName && !!brokerInfo?.lastName,
    getSubsequentStepRoute: () => OnboardingRoutes.BrokerCustomerAgreement,
  },
  [OnboardingRoutes.BrokerIntermediaryInformation]: {
    route: OnboardingRoutes.BrokerIntermediaryInformation,
    isComplete: ({ brokerInfo }) =>
      !!brokerInfo?.firstName && !!brokerInfo?.lastName,
    getSubsequentStepRoute: () => OnboardingRoutes.BrokerCustomerAgreement,
  },
  [OnboardingRoutes.IndividualAccreditation]: {
    route: OnboardingRoutes.IndividualAccreditation,
    isComplete: ({ accreditationQuestions, country, investorType }) =>
      match(investorType)
        .with(InvestorType.UnaccreditedSeller, () => true)
        .otherwise(
          () =>
            !!country?.name &&
            ([`US`, `CA`, `IN`].includes(country?.name)
              ? accreditationQuestions.length !== 0
              : true),
        ),
    getSubsequentStepRoute: ({ investorType }) =>
      match(investorType)
        .with(
          InvestorType.UnaccreditedSeller,
          () => OnboardingRoutes.SellerInfo,
        )
        .otherwise(() => OnboardingRoutes.IndividualWatchlist),
  },
  [OnboardingRoutes.IndividualConsent]: {
    route: OnboardingRoutes.IndividualConsent,
    isComplete: () => false,
    getSubsequentStepRoute: () => OnboardingRoutes.IndividualCustomerAgreement,
  },
  [OnboardingRoutes.IndividualCustomerAgreement]: {
    route: OnboardingRoutes.IndividualCustomerAgreement,
    isComplete: ({ agreedToCustomerAgreement }) => !!agreedToCustomerAgreement,
    getSubsequentStepRoute: ({ agreedToCustomerAgreement }) =>
      agreedToCustomerAgreement
        ? OnboardingRoutes.IdentityVerification
        : OnboardingRoutes.IndividualCustomerAgreementDeclined,
  },
  [OnboardingRoutes.IndividualCustomerAgreementDeclined]: {
    route: OnboardingRoutes.IndividualCustomerAgreementDeclined,
    isComplete: () => false,
    getSubsequentStepRoute: () => null,
  },
  [OnboardingRoutes.InstitutionCustomerAgreement]: {
    route: OnboardingRoutes.InstitutionCustomerAgreement,
    isComplete: ({ institution, emailVerified }) => {
      const hasSignedAgreement =
        !!institution && institution.membershipAgreementSigned;
      const hasSkippedAgreement = emailVerified;

      return hasSignedAgreement || hasSkippedAgreement;
    },
    getSubsequentStepRoute: () => OnboardingRoutes.RequestEmailVerification,
  },
  [OnboardingRoutes.BrokerCustomerAgreement]: {
    route: OnboardingRoutes.BrokerCustomerAgreement,
    isComplete: ({ agreedToCustomerAgreement }) =>
      agreedToCustomerAgreement === true,
    getSubsequentStepRoute: ({ agreedToCustomerAgreement }) => {
      if (agreedToCustomerAgreement)
        return OnboardingRoutes.RequestEmailVerification;
      return OnboardingRoutes.BrokerCustomerAgreementDeclined;
    },
  },
  [OnboardingRoutes.BrokerCustomerAgreementDeclined]: {
    route: OnboardingRoutes.BrokerCustomerAgreementDeclined,
    isComplete: () => false,
    getSubsequentStepRoute: () => null,
  },
  [OnboardingRoutes.IdentityVerification]: {
    route: OnboardingRoutes.IdentityVerification,
    isComplete: ({ identityVerified }) => identityVerified,
    getSubsequentStepRoute: () => OnboardingRoutes.RequestEmailVerification,
  },
  [OnboardingRoutes.RequestEmailVerification]: {
    route: OnboardingRoutes.RequestEmailVerification,
    isComplete: ({ emailVerified }) => emailVerified,
    getSubsequentStepRoute: () => OnboardingRoutes.VerifyEmail,
  },
  [OnboardingRoutes.VerifyEmail]: {
    route: OnboardingRoutes.VerifyEmail,
    isComplete: () => true,
    getSubsequentStepRoute: (actor) => {
      const { institutionId } = actor;

      if (canAccessPlatform(actor)) return null;

      if (institutionId) return OnboardingRoutes.InstitutionApprovalWait;

      const isBroker = getIsBroker(actor);

      if (isBroker) return OnboardingRoutes.BrokerNeedsFurtherVerification;

      return OnboardingRoutes.IndividualNeedsFurtherVerification;
    },
  },
  [OnboardingRoutes.IndividualNeedsFurtherVerification]: {
    route: OnboardingRoutes.IndividualNeedsFurtherVerification,
    isComplete: (actor) => canAccessPlatform(actor),
    getSubsequentStepRoute: () => null,
  },
  [OnboardingRoutes.BrokerNeedsFurtherVerification]: {
    route: OnboardingRoutes.BrokerNeedsFurtherVerification,
    isComplete: (actor) => canAccessPlatform(actor),
    getSubsequentStepRoute: () => null,
  },
};
