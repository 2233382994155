import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(alertAnatomy.keys);

const grey = definePartsStyle({
  container: {
    background: `grey.900`,
  },
  title: {
    color: `white`,
  },
  description: {
    color: `white`,
  },
});

const infoGrey = definePartsStyle({
  container: {
    background: `grey.25`,
    borderColor: `grey.800`,
    borderRadius: `md`,
    borderWidth: 1,
  },
  title: {
    fontWeight: 500,
  },
});

export const Alert = defineMultiStyleConfig({
  variants: { grey, infoGrey },
});
