import { useRouter } from "next/router";

import { Flex, Text, VStack } from "@chakra-ui/react";

import {
  ActivityCardSkeleton,
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  Skeleton,
  WithQuery,
} from "@/components/common";
import {
  PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment,
  usePostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery,
} from "@/gql";
import { getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard } from "@/utils";

import { CompletedSaleCard } from "./CompletedSaleCard";
import { PendingSaleCard } from "./PendingSaleCard";

const CompletedSalesEmptyState = () => (
  <ActivityGroupEmptyState message="You have no executed transactions." />
);

const CompletedSales = ({
  completedSales,
}: {
  readonly completedSales: readonly PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment[];
}) => (
  <ActivityGroup emptyState={<CompletedSalesEmptyState />}>
    {completedSales.map((completedSale) => (
      <CompletedSaleCard key={completedSale.id} completedSale={completedSale} />
    ))}
  </ActivityGroup>
);

const PendingSalesEmptyState = () => (
  <ActivityGroupEmptyState message="You have not accepted any bids." />
);

const PendingSales = ({
  pendingSales,
}: {
  readonly pendingSales: readonly PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment[];
}) => (
  <ActivityGroup emptyState={<PendingSalesEmptyState />}>
    {pendingSales.map((pendingSale) => (
      <PendingSaleCard key={pendingSale.id} pendingSale={pendingSale} />
    ))}
  </ActivityGroup>
);

interface TransactionsPageContentProps {
  readonly pendingSales: readonly PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment[];
  readonly completedSales: readonly PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment[];
}

const TransactionsPageContent = ({
  pendingSales,
  completedSales,
}: TransactionsPageContentProps) => (
  <ActivitySection pt={{ base: 4, lg: 8 }}>
    <VStack alignItems="flex-start" spacing={4}>
      <Text textStyle="heading-sm" textTransform="uppercase">
        Your Pending Sales
      </Text>
      <Text textStyle="text-md">Status of your accepted bids.</Text>
      <PendingSales pendingSales={pendingSales} />
    </VStack>
    <VStack alignItems="flex-start" spacing={4}>
      <Text textStyle="heading-sm" textTransform="uppercase">
        Your Completed Sales
      </Text>
      <Text textStyle="text-md">History of your executed transactions.</Text>
      <CompletedSales completedSales={completedSales} />
    </VStack>
  </ActivitySection>
);

const TransactionsPageSkeleton = () => (
  <VStack spacing={8} w="full" pt={{ base: 4, lg: 8 }}>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" w="full" maxW="156px" />
      <Skeleton h="16px" maxW="85%" />
      <ActivityCardSkeleton includeBody />
    </Flex>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" w="full" maxW="144px" />
      <Skeleton h="16px" maxW="75%" />
      <ActivityCardSkeleton includeBody />
    </Flex>
  </VStack>
);

const TransactionsPage = () => {
  const query = usePostActivityUnaccreditedSellerDashboardPageTransactionsPageQuery();
  const router = useRouter();

  return (
    <WithQuery query={query} fallback={<TransactionsPageSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity,
          unaccreditedSellerMyCompanyActivity,
        },
      }) => {
        const { myListing } = unaccreditedSellerMyActivity;

        if (
          !getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard(
            unaccreditedSellerMyActivity,
            unaccreditedSellerMyCompanyActivity,
          ) &&
          !myListing
        ) {
          router.replace(`/dashboard`);
          return null;
        }

        return <TransactionsPageContent {...unaccreditedSellerMyActivity} />;
      }}
    </WithQuery>
  );
};

export default TransactionsPage;
