import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { PlaceBidSequenceModalPlaceBidMutation } from "@/gql";

import { StepKeys } from "./steps";

export const PlaceBidSequenceModalStepFormContext = createStepFormContext<
  StepKeys,
  PlaceBidSequenceModalPlaceBidMutation
>();

export const usePlaceBidSequenceModalStepFormContext = () =>
  useContext(PlaceBidSequenceModalStepFormContext);
