import { SignupAttributionInput } from "@/gql";

export const getUTMCookieData = (): SignupAttributionInput | null => {
  const cookieArray: readonly string[] = document.cookie.split(`;`);

  const foundCookie = cookieArray
    .map((cookie) => {
      const [key, value] = cookie.split(`=`);
      return { key: key.trim(), value: decodeURIComponent(value || ``) };
    })
    .reduce((acc, current) => {
      if (current.key === `hiive_utm`) {
        return current.value;
      }
      return acc;
    }, null);

  if (!!foundCookie) {
    return JSON.parse(foundCookie);
  }

  return null;
};

export const utmCookieDataToSegmentOption = (
  attribution: SignupAttributionInput | null,
) =>
  !!attribution ? { attribution, campaign: attribution.lastTouch } : undefined;
