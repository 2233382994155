import { Form } from "formik";
import * as Yup from "yup";

import { Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FormikQL } from "@/components/form";
import {
  AnswerInstitutionAccreditationQuestionsInput,
  CurrentContextDocument,
  useAnswerInstitutionAccreditationQuestionsMutation,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

import { CanadaInstitutionAccreditationFields } from "./CanadaInstitutionAccreditationFields";

const initialValues = {
  answers: [],
};

const validationSchema = Yup.object().shape({
  answers: Yup.array()
    .of(
      Yup.object({
        key: Yup.string().required(`Must select an answer`),
        text: Yup.string(),
      }),
    )
    .test({
      message: `Must select at least one option`,
      test: (arr) => (arr ? arr.length > 0 : false),
    }),
});

const mapVariables = (
  values: AnswerInstitutionAccreditationQuestionsInput,
): { readonly input: AnswerInstitutionAccreditationQuestionsInput } => ({
  input: {
    ...values,
  },
});

export const CanadaInstitutionAccreditationForm = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useAnswerInstitutionAccreditationQuestionsMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => routeToNextStep();

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`answerInstitutionAccreditationQuestions`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <Form>
          <VStack spacing={10}>
            <Text
              mt={7}
              align="center"
              textStyle="deprecated-heading-xl"
              color="h-dark-grey"
            >
              Canada
            </Text>
            <CanadaInstitutionAccreditationFields />
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[CanadaInstitutionAccreditationForm] Next"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};
