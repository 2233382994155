import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";

import Error from "next/error";
import { useRouter } from "next/router";

import { Box } from "@chakra-ui/react";

import { OnboardingRoutes, useResumeOnboarding } from "@/hooks";

import {
  IndividualAccreditationPage,
  IndividualTraderCustomerAgreementPage,
  InstitutionCustomerAgreementPage,
  IdentityVerificationPage,
  InvestorTypePage,
  InvestorStatusPage,
  IndividualNeedsFurtherVerificationPage,
  OnboardingContainer,
  RequestEmailVerificationPage,
  WatchlistPage,
  InstitutionAccreditationPage,
  InstitutionBuildProfilePage,
  InstitutionDetailsPage,
  InstitutionNeedsFurtherVerificationPage,
  InstitutionSuitabilityPage,
  InstitutionApprovalWaitPage,
  IndividualConsentPage,
  SellerInfoPage,
  SellerResidencePage,
  SellerLotDetailsPage,
  SellerAgreementPage,
  CustomerAgreementDeclinedPage,
} from ".";
import {
  BrokerResidencePage,
  BrokerInformationPage,
  BrokerIntermediaryInformationPage,
  BrokerCustomerAgreementPage,
  BrokerCustomerAgreementDeclinedPage,
  BrokerWatchlistPage,
  BrokerNeedsFurtherVerificationPage,
} from "./broker";
import {
  InstitutionCanadaBuildProfilePage,
  InstitutionConsentPage,
} from "./institutional";

const PageNotFound = () => (
  <Box pos="fixed" top={0} left={0} boxSize="full" zIndex={9999999}>
    <Error statusCode={404} />
  </Box>
);

const getOnboardingView = (
  path: string,
): {
  readonly title: string;
  readonly heading: string;
  readonly page: JSX.Element;
} => {
  switch (path) {
    case OnboardingRoutes.IndividualAccreditation:
      return {
        title: `Individual Accreditation`,
        heading: `Place of Residence`,
        page: <IndividualAccreditationPage />,
      };
    case OnboardingRoutes.BrokerResidence:
      return {
        title: `Name/Residence`,
        heading: `Name/Residence`,
        page: <BrokerResidencePage />,
      };
    case OnboardingRoutes.BrokerInformation:
      return {
        title: `Broker-Dealer Information`,
        heading: `Broker-Dealer Information`,
        page: <BrokerInformationPage />,
      };
    case OnboardingRoutes.BrokerIntermediaryInformation:
      return {
        title: `Intermediary Information`,
        heading: `Intermediary Information`,
        page: <BrokerIntermediaryInformationPage />,
      };

    case OnboardingRoutes.BrokerCustomerAgreement:
      return {
        title: `HiiveConnect User Agreement`,
        heading: `HiiveConnect User Agreement`,
        page: <BrokerCustomerAgreementPage />,
      };
    case OnboardingRoutes.BrokerCustomerAgreementDeclined:
      return {
        title: `HiiveConnect User Agreement`,
        heading: `HiiveConnect User Agreement`,
        page: <BrokerCustomerAgreementDeclinedPage />,
      };
    case OnboardingRoutes.InvestorType:
      return {
        title: `Type of User`,
        heading: `Type of User`,
        page: <InvestorTypePage />,
      };
    case OnboardingRoutes.InvestorStatus:
      return {
        title: `Investor Status`,
        heading: `Investor Status`,
        page: <InvestorStatusPage />,
      };
    case OnboardingRoutes.InstitutionDetails:
      return {
        title: `Institution Details`,
        heading: `Name/Residence`,
        page: <InstitutionDetailsPage />,
      };
    case OnboardingRoutes.InstitutionAccreditation:
      return {
        title: `Institution Accreditation`,
        heading: `Accreditation`,
        page: <InstitutionAccreditationPage />,
      };
    case OnboardingRoutes.InstitutionNeedsFurtherVerification:
      return {
        title: `Institution Needs Further Verification`,
        heading: `Thank you for your interest`,
        page: <InstitutionNeedsFurtherVerificationPage />,
      };
    case OnboardingRoutes.InstitutionBuildProfile:
      return {
        title: `Institution Profile`,
        heading: `Build your Profile`,
        page: <InstitutionBuildProfilePage />,
      };
    case OnboardingRoutes.InstitutionCanadaBuildProfile:
      return {
        title: `Institution Profile`,
        heading: `Build your Profile`,
        page: <InstitutionCanadaBuildProfilePage />,
      };
    case OnboardingRoutes.InstitutionSuitability:
      return {
        title: `Institution Suitability`,
        heading: `Suitability for Institutional Accounts`,
        page: <InstitutionSuitabilityPage />,
      };
    case OnboardingRoutes.InstitutionConsent:
      return {
        title: `Institution Consent`,
        heading: `Acknowledgement and Consent`,
        page: <InstitutionConsentPage />,
      };
    case OnboardingRoutes.InstitutionApprovalWait:
      return {
        title: `Institution Awaiting Approval`,
        heading: `Awaiting Approval for your Institution`,
        page: <InstitutionApprovalWaitPage />,
      };
    case OnboardingRoutes.BrokerWatchlist:
      return {
        title: `Watchlist`,
        heading: `Build your Confidential Profile`,
        page: <BrokerWatchlistPage />,
      };
    case OnboardingRoutes.IndividualWatchlist:
    case OnboardingRoutes.InstitutionalWatchlist:
      return {
        title: `Watchlist`,
        heading: `Build your Confidential Profile`,
        page: <WatchlistPage />,
      };
    case OnboardingRoutes.IndividualCustomerAgreement:
      return {
        title: `User Agreement`,
        heading: `User Agreement`,
        page: <IndividualTraderCustomerAgreementPage />,
      };
    case OnboardingRoutes.IndividualCustomerAgreementDeclined:
      return {
        title: `User Agreement`,
        heading: `User Agreement`,
        page: <CustomerAgreementDeclinedPage />,
      };
    case OnboardingRoutes.InstitutionCustomerAgreement:
      return {
        title: `Customer Agreement`,
        heading: `Customer Agreement (Optional)`,
        page: <InstitutionCustomerAgreementPage />,
      };
    case OnboardingRoutes.IdentityVerification:
      return {
        title: `Identity Verification`,
        heading: `Identity Verification (Optional)`,
        page: <IdentityVerificationPage />,
      };
    case OnboardingRoutes.RequestEmailVerification:
      return {
        title: `Email Verification`,
        heading: `Verify your Email`,
        page: <RequestEmailVerificationPage />,
      };
    case OnboardingRoutes.IndividualNeedsFurtherVerification:
      return {
        title: `Individual Needs Further Verification`,
        heading: `Welcome to Hiive`,
        page: <IndividualNeedsFurtherVerificationPage />,
      };
    case OnboardingRoutes.BrokerNeedsFurtherVerification:
      return {
        title: `Broker Needs Further Verification`,
        heading: `Welcome to HiiveConnect`,
        page: <BrokerNeedsFurtherVerificationPage />,
      };
    case OnboardingRoutes.IndividualConsent:
      return {
        title: `Acknowledgement and Consent`,
        heading: `Acknowledgement and Consent`,
        page: <IndividualConsentPage />,
      };
    case OnboardingRoutes.SellerInfo:
      return {
        title: `Selling on Hiive in three steps`,
        heading: `Selling on Hiive in three steps`,
        page: <SellerInfoPage />,
      };
    case OnboardingRoutes.SellerResidence:
      return {
        title: `Place of residence`,
        heading: `Place of residence`,
        page: <SellerResidencePage />,
      };
    case OnboardingRoutes.SellerLotDetails:
      return {
        title: `Holding Details`,
        heading: `Holding Details`,
        page: <SellerLotDetailsPage />,
      };
    case OnboardingRoutes.SellerAgreement:
      return {
        title: `Customer Agreement`,
        heading: `Customer Agreement`,
        page: <SellerAgreementPage />,
      };
    case OnboardingRoutes.CustomerAgreementDeclined:
      return {
        title: `Customer Agreement`,
        heading: `Customer Agreement`,
        page: <CustomerAgreementDeclinedPage />,
      };
    default:
      return {
        title: `Page Not Found`,
        heading: ``,
        page: <PageNotFound />,
      };
  }
};

const flyThrough = {
  initial: {
    opacity: 0,
    scale: 1,
    y: 25,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      opacity: { duration: 0.4, ease: `linear` },
      y: { duration: 0.8, ease: [0.16, 0.7, 0.01, 1] },
    },
  },
  exit: {
    opacity: 0,
    y: 0,
    scale: 0.5,
    transition: {
      opacity: { duration: 0.15, ease: `linear` },
      y: { duration: 0.3, ease: [0.59, 0.15, 1, 0.01] },
      scale: { duration: 0.2, ease: [0.59, 0.15, 1, 0.01] },
    },
  },
};

const OnboardingPage = () => {
  const { asPath } = useRouter();

  const { heading, title, page } = getOnboardingView(asPath);

  const { routeToFirstIncompleteStep } = useResumeOnboarding();

  useEffect(() => {
    if (asPath) {
      routeToFirstIncompleteStep();
    }
  }, [Boolean(asPath)]);

  return (
    <OnboardingContainer heading={heading} title={title}>
      <AnimatePresence mode="wait">
        <motion.div
          key={asPath}
          initial="initial"
          exit="exit"
          animate="animate"
          variants={flyThrough}
        >
          {page}
        </motion.div>
      </AnimatePresence>
    </OnboardingContainer>
  );
};

export default OnboardingPage;
