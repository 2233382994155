import { Flex } from "@chakra-ui/react";

import { ActivityCardSkeleton, Skeleton } from "@/components/common";

export const YourActivitySkeleton = () => (
  <Flex direction="column" gap={4} w="full">
    <Skeleton h="30px" maxW="25%" />
    <Skeleton h="20px" maxW="10%" />

    <ActivityCardSkeleton includeBody />

    <Skeleton h="20px" maxW="20%" />

    <ActivityCardSkeleton includePropertyPills includeBody />
    <ActivityCardSkeleton includePropertyPills includeBody />
    <ActivityCardSkeleton includePropertyPills includeBody />
  </Flex>
);
