import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from "@chakra-ui/react";

const multiStyleConfigHelpers = createMultiStyleConfigHelpers([
  `container`,
  `label`,
  `closeButton`,
]);

const baseStyle = {};
const sizes = {};

const variants = {
  blue: {
    container: {
      color: `teal.900`,
      bg: `skyBlue.50`,
    },
  },
  salmon: {
    container: {
      color: `grey.900`,
      bg: `salmon.50`,
      fontWeight: 400,
      border: `1px solid white`,
    },
  },
};

export const Tag = defineStyleConfig<
  typeof baseStyle,
  typeof sizes,
  typeof variants
>(
  multiStyleConfigHelpers.defineMultiStyleConfig({
    variants,
  }),
);
