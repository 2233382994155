import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { UnaccreditedSellerAcceptStandingBidV2Mutation } from "@/gql";

import { StepKeys } from "./steps";

export const UnaccreditedSellerAcceptStandingBidSequenceModalContext = createStepFormContext<
  StepKeys,
  UnaccreditedSellerAcceptStandingBidV2Mutation
>();

export const useUnaccreditedSellerAcceptStandingBidSequenceModalContext = () =>
  useContext(UnaccreditedSellerAcceptStandingBidSequenceModalContext);
