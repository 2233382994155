import { Flex } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import {
  CompanyLastRoundDetailsCard,
  PricingChartWrapper,
} from "@/components/companies";
import { useUnaccreditedSellerCompanyPageInfoQuery } from "@/gql";

import { DescriptionCard } from "./DescriptionCard";
import { InfoSkeleton } from "./InfoSkeleton";

const Info = () => {
  const query = useUnaccreditedSellerCompanyPageInfoQuery();
  return (
    <WithQuery query={query} fallback={<InfoSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myCompany },
        },
      }) => (
        <Flex direction="column" gap={4}>
          <DescriptionCard company={myCompany} />
          {myCompany.priceChartConfig.displayChart && (
            <PricingChartWrapper company={myCompany} />
          )}
          <CompanyLastRoundDetailsCard company={myCompany} />
        </Flex>
      )}
    </WithQuery>
  );
};

export default Info;
