import { InformativeArticlesV2 } from "@/components/dashboard";
import { UnaccreditedSellerInformativeArticlesCompanyFragment } from "@/gql";
import { constants } from "@/utils";

const marketingWebsiteLinks = [
  constants.marketing_website_links.calculate_price_of_shares,
  constants.marketing_website_links.dive_into_vc_secondaries,
  constants.marketing_website_links.how_to_sell_private_shares,
];

export const InformativeArticlesCardV2 = ({
  company,
}: {
  company: UnaccreditedSellerInformativeArticlesCompanyFragment;
}) => (
  <InformativeArticlesV2.Card company={company}>
    {marketingWebsiteLinks.map(({ title, url }) => (
      <InformativeArticlesV2.Link key={url} href={url}>
        {title}
      </InformativeArticlesV2.Link>
    ))}
  </InformativeArticlesV2.Card>
);
