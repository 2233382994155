import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const grey = definePartsStyle({
  control: defineStyle({
    borderColor: `grey.600`,
    borderWidth: 0.5,
  }),
});

export const Checkbox = defineMultiStyleConfig({
  variants: { grey },
});
