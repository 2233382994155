import { CheckCircle, Circle } from "@phosphor-icons/react";

import {
  Flex,
  FlexProps,
  Text,
  Center,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { chakraColor } from "@/utils";

const StepCircle = ({ isFilled }: { readonly isFilled: boolean }) => (
  <Center>
    {isFilled ? (
      <CheckCircle size={20} weight="fill" />
    ) : (
      <Circle size={20} fill={chakraColor(`grey.200`)} />
    )}
  </Center>
);

interface TransferStatusItemProps extends FlexProps {
  readonly isCompleted: boolean;
  readonly title: string;
  readonly description?: string;
  readonly subtitle?: string;
  readonly isSubitem?: boolean;
}

export const TransferStatusItem = ({
  isCompleted = false,
  title,
  subtitle,
  description,
  isSubitem,
  ...flexProps
}: TransferStatusItemProps) => {
  const statusColor = isCompleted ? `grey.900` : `grey.500`;

  return (
    <Flex direction="column" borderRadius="md" py={2} {...flexProps}>
      <Flex
        justify="space-between"
        align="center"
        color={statusColor}
        textStyle="heading-xs"
        w="full"
        pl={isSubitem ? 6 : 0}
      >
        <Grid templateColumns="20px 1fr" columnGap={2}>
          <GridItem>
            <StepCircle isFilled={isCompleted} />
          </GridItem>

          <GridItem>
            <Text>
              <Text
                as="strong"
                textStyle="heading-xs"
                data-test={`transfer-status-item-${
                  isCompleted ? `completed` : `incomplete`
                }`}
              >
                {title}
                {description && `: `}
              </Text>
              {description && (
                <Text as="span" fontWeight="normal">
                  {description}
                </Text>
              )}
              {subtitle && <Text fontWeight="normal">{subtitle}</Text>}
            </Text>
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};
