import { CaretLeft } from "@phosphor-icons/react";
import truncate from "lodash/truncate";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Flex, Text, VStack, useBreakpointValue } from "@chakra-ui/react";

import Meta from "@/components/Meta";
import { HiiveButton, Logo } from "@/components/common";
import { useTransitionBackToPreviousStepMutation } from "@/gql";
import {
  useBasicCurrentContext,
  useColors,
  useMutationWithError,
  useSignOut,
} from "@/hooks";
import { getIsBroker } from "@/utils";

const OnboardingContainerV2 = ({
  canGoBack = true,
  children,
  metaTitle,
}: {
  canGoBack?: boolean;
  children: ReactNode;
  metaTitle: string;
}) => {
  const { actor } = useBasicCurrentContext();
  const signOut = useSignOut();
  const { t } = useTranslation();
  const [grey900] = useColors([`grey.900`]);
  const isBroker = actor ? getIsBroker(actor) : false;

  const displayedEmail = useBreakpointValue(
    {
      base: truncate(actor?.email, { length: 24 }),
      sm: actor?.email,
    },
    {
      ssr: false,
    },
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [transitionBackToPreviousStepMutation] = useMutationWithError(
    useTransitionBackToPreviousStepMutation(),
    `transitionBackToPreviousStep`,
  );

  if (!actor) return null;

  const onBack = () =>
    transitionBackToPreviousStepMutation({
      // TODO: remove after SUP completion
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { onboardingId: actor.onboardingV2?.id! },
    });

  return (
    <>
      <Meta title={`Onboarding | ${metaTitle}`} shouldTrackPageEvent />
      <VStack minH="100vh">
        <Flex
          px={{ base: 4, lg: 7 }}
          py={{ base: 4, lg: 8 }}
          bg="white"
          w="full"
          justify="space-between"
          alignItems="center"
          borderBottom="0.5px solid"
          borderColor="grey.200"
        >
          {isBroker ? (
            <Logo.ConnectGrey height="auto" width={24} />
          ) : (
            <Logo.RedBlack />
          )}
          <Flex direction="column" alignItems="flex-end">
            {actor && (
              <Text color="grey.600" textStyle="text-md">
                {displayedEmail}
              </Text>
            )}
            <Text
              color="grey.500"
              textStyle={{ base: `heading-xs`, lg: `heading-md` }}
              cursor="pointer"
              onClick={() => signOut()}
            >
              {t(`log_out`)}
            </Text>
          </Flex>
        </Flex>
        <Flex
          position="relative"
          direction="column"
          p={{ base: 0, lg: 9 }}
          flex={1}
          boxSize="full"
        >
          {canGoBack && (
            <Flex
              as={HiiveButton}
              variant="unstyled"
              minW="auto"
              h="auto"
              direction="row"
              cursor="pointer"
              alignItems="center"
              justify="center"
              mr="auto"
              onClick={onBack}
              gap={2}
              position="absolute"
              top={{ base: 4, lg: `auto` }}
              left={{ base: 4, lg: `auto` }}
              sentryLabel="[OnboardingContainer] Back button"
            >
              <CaretLeft color={grey900} size={18} weight="bold" />
              <Text mt={0.5} textStyle="heading-md">
                {t(`back_button_text`)}
              </Text>
            </Flex>
          )}
          <Flex
            flex={1}
            py={{ base: canGoBack ? 20 : 9, lg: 24 }}
            px={{ base: 4, lg: 0 }}
            justify="center"
          >
            {children}
          </Flex>
        </Flex>
      </VStack>
    </>
  );
};

export default OnboardingContainerV2;
