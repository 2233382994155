import { Icon, IconProps } from "@chakra-ui/react";

const PreqinIcon = (props: IconProps) => (
  <Icon {...props} width={51} height={3} viewBox="0 0 51 12" fill="none">
    <svg
      width="51"
      height="12"
      viewBox="0 0 51 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_393_15)">
        <path
          d="M35.0555 8.81755C35.4513 9.21177 35.8448 9.60277 36.2325 9.98896C35.7725 10.4482 35.3157 10.9034 34.8548 11.3627C34.4301 10.938 34.0005 10.5076 33.571 10.0781C32.182 10.8087 30.7521 10.9388 29.2836 10.4008C28.0953 9.96568 27.1937 9.17644 26.5835 8.07007C25.3976 5.92075 25.8962 3.13875 27.9628 1.54985C29.9307 0.0364109 32.7729 0.18655 34.5698 1.88304C36.4943 3.69997 36.7408 6.70918 35.0555 8.81836V8.81755ZM33.6176 7.36514C34.4277 6.22584 34.3891 4.49964 33.1984 3.36196C32.0551 2.26923 30.2527 2.22588 29.0451 3.26561C27.8312 4.31016 27.612 6.10782 28.5385 7.40367C29.4819 8.72201 31.0652 8.91952 32.0455 8.53976C31.6722 8.16963 31.3053 7.80672 30.9496 7.45345C31.4088 6.9942 31.8649 6.53897 32.3273 6.07651C32.752 6.50043 33.1816 6.93078 33.6168 7.36594L33.6176 7.36514Z"
          fill="#1E065B"
        />
        <path
          d="M41.99 0.751775C42.0205 0.750169 42.0486 0.747761 42.0759 0.747761C42.5922 0.747761 43.1076 0.747761 43.6239 0.746155C43.6801 0.746155 43.717 0.763015 43.7532 0.806371C44.9768 2.29733 46.202 3.78748 47.4272 5.27683C47.9434 5.90468 48.4597 6.53254 48.9767 7.1604C48.9888 7.17565 49.0024 7.1893 49.0273 7.21579V0.754987H51V10.6104H50.8988C50.3882 10.6104 49.8784 10.6088 49.3677 10.612C49.3035 10.612 49.265 10.5903 49.2256 10.5421C47.4922 8.43216 45.758 6.32299 44.0237 4.21381C44.0077 4.19454 43.9908 4.17608 43.9603 4.14075V10.604H41.9892V0.751775H41.99Z"
          fill="#1E065B"
        />
        <path
          d="M17.136 10.6104C17.0902 10.6104 17.0629 10.6104 17.0348 10.6104C16.3347 10.6104 15.6346 10.6104 14.9344 10.612C14.8734 10.612 14.8381 10.5943 14.8052 10.5413C14.2167 9.59715 13.6257 8.65457 13.0356 7.71118C13.0115 7.67264 12.9939 7.63811 12.9345 7.63811C12.2006 7.64052 11.466 7.63972 10.7322 7.63972C10.7185 7.63972 10.7049 7.64213 10.6816 7.64374V10.6032H8.70808V0.747764C8.7394 0.747764 8.7691 0.747764 8.79961 0.747764C10.2255 0.747764 11.6515 0.740538 13.0774 0.750173C13.9734 0.755793 14.7779 1.03359 15.4443 1.6486C16.0232 2.18252 16.3507 2.85132 16.4607 3.6253C16.5763 4.43862 16.4615 5.21662 16.0625 5.94323C15.808 6.40649 15.4563 6.78063 15.0131 7.06887C14.9939 7.08091 14.9746 7.09296 14.9561 7.1058C14.9521 7.10901 14.9497 7.11463 14.9425 7.12587C15.6683 8.27962 16.3965 9.43658 17.1352 10.6104H17.136ZM10.6848 5.66141C10.7105 5.66302 10.7273 5.66463 10.7442 5.66463C11.5318 5.65981 12.3203 5.66463 13.1079 5.64857C13.8449 5.63412 14.3355 5.23267 14.4864 4.52694C14.5996 3.99704 14.5025 3.51049 14.1075 3.11467C13.8329 2.83928 13.4812 2.72768 13.1023 2.72286C12.3114 2.71322 11.5206 2.71804 10.7297 2.71724C10.7161 2.71724 10.7024 2.72045 10.6848 2.72286V5.66141Z"
          fill="#1E065B"
        />
        <path
          d="M24.7561 4.69073V6.66261H20.3652V8.6361H24.7561V10.604H18.3941V0.753387H24.7545V2.71644H20.3676V4.69073H24.7561Z"
          fill="#1E065B"
        />
        <path
          d="M0 0.750979C0.0176634 0.749373 0.0256923 0.747768 0.034524 0.747768C1.36169 0.747768 2.68886 0.718864 4.01442 0.758205C5.31108 0.795941 6.31709 1.37964 6.93772 2.54542C7.27895 3.18613 7.37529 3.87982 7.30464 4.59679C7.24041 5.24472 7.02363 5.83645 6.62138 6.3535C6.0979 7.02471 5.40582 7.42134 4.57323 7.57068C4.31791 7.61644 4.05537 7.6333 3.79604 7.63812C3.22358 7.64775 2.65032 7.64133 2.07786 7.64133H1.97188V10.6032H0V0.750979ZM1.97349 5.65821C1.99677 5.66062 2.01604 5.66463 2.03531 5.66463C2.66156 5.66463 3.28781 5.67025 3.91406 5.66302C4.30908 5.65821 4.66797 5.54018 4.95379 5.25195C5.22276 4.98057 5.33677 4.64176 5.35042 4.26761C5.36407 3.88062 5.27896 3.51852 5.02525 3.21342C4.74344 2.8738 4.36448 2.73009 3.93734 2.72206C3.30547 2.71002 2.6736 2.71724 2.04173 2.71644C2.02006 2.71644 1.99838 2.72045 1.97349 2.72286V5.6574V5.65821Z"
          fill="#1E065B"
        />
        <path
          d="M37.9274 0.750977H39.8881V10.6048H37.9274V0.750977Z"
          fill="#1E065B"
        />
      </g>
      <defs>
        <clipPath id="clip0_393_15">
          <rect
            width="51"
            height="12"
            fill="white"
            transform="translate(0 0.505295)"
          />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);
export default PreqinIcon;
