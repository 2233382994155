import { Text, TextProps } from "@chakra-ui/react";

import { toTimestamp } from "@/utils/datetime";

interface ActivityCardTimestampProps extends Omit<TextProps, "title"> {
  readonly title?: string | null;
  readonly date: string;
}

const ActivityCardTimestamp = ({
  title,
  date,
  ...textProps
}: ActivityCardTimestampProps) => (
  <Text textStyle="text-sm" whiteSpace="nowrap" {...textProps}>
    {!!title && `${title} `}
    {toTimestamp(date)}
  </Text>
);

export default ActivityCardTimestamp;
