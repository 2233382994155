import { ReactNode } from "react";

import { Box, VStack } from "@chakra-ui/react";

import { CustomRadio } from "@/components/common";

import AccreditationTextAreaInput from "./AccreditationTextAreaInput";

const CustomRadioTextArea = ({
  radioValue,
  selectedRadioValue,
  textAreaValue,
  onTextChange,
  textAreaLabel,
  children,
  placeholder,
}: {
  readonly radioValue: string;
  readonly selectedRadioValue?: string;
  readonly textAreaValue: string;
  readonly onTextChange: (value: string) => void;
  readonly children: ReactNode;
  readonly textAreaLabel?: string | null;
  readonly placeholder?: string | null;
}) => (
  <VStack w="full" align="flex-start">
    <CustomRadio value={radioValue}>{children}</CustomRadio>
    {selectedRadioValue === radioValue && (
      <Box pl={9} w="full">
        <AccreditationTextAreaInput
          placeholder={placeholder}
          label={textAreaLabel}
          value={textAreaValue}
          onChange={onTextChange}
        />
      </Box>
    )}
  </VStack>
);
export default CustomRadioTextArea;
