import { ReactNode } from "react";

import { Card } from "@chakra-ui/react";

const FlatCard = ({ children }: { readonly children: ReactNode }) => (
  <Card variant="flat" borderWidth={1}>
    {children}
  </Card>
);

export default FlatCard;
