import { useTranslation } from "react-i18next";

import { GridItem, SimpleGrid, Text } from "@chakra-ui/react";

import {
  FeeDiscountAlert,
  HeroBannerWrapper,
  WithQuery,
} from "@/components/common";
import {
  FeeDiscountAlertType,
  useFeeDiscountAlert,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  FeeDiscountFragment,
  UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFragment,
  UserWithInstitutionFragment,
  useUnaccreditedSellerDashboardByIdPageQuery,
} from "@/gql";
import { getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2 } from "@/utils";

import HeroBannerSkeleton from "./HeroBannerSkeleton";

interface DefaultPreListingHeroBannerContentWithFeeDiscountProps {
  readonly companyName: string;
  readonly firstName: string;
  readonly feeDiscount: FeeDiscountFragment;
  readonly feeDiscountAlertType: FeeDiscountAlertType;
}

const DefaultPreListingHeroBannerContentWithFeeDiscount = ({
  companyName,
  firstName,
  feeDiscount,
  feeDiscountAlertType,
}: DefaultPreListingHeroBannerContentWithFeeDiscountProps) => {
  const { t } = useTranslation();

  return (
    <SimpleGrid
      columns={12}
      columnGap={2}
      alignItems="center"
      pb={{ base: 6, lg: 42 }}
    >
      <GridItem colSpan={{ base: 12, lg: 5, xl: 7 }}>
        <Text textStyle="heading-4xl">
          {t(`hello`)}, {firstName}
        </Text>
      </GridItem>

      <GridItem colSpan={{ base: 12, lg: 7, xl: 5 }} mt={{ base: 2, lg: 0 }}>
        <FeeDiscountAlert
          type={feeDiscountAlertType}
          companyName={companyName}
          feeDiscount={feeDiscount}
        />
      </GridItem>
    </SimpleGrid>
  );
};

interface DefaultPreListingHeroBannerContentProps {
  readonly actor: UserWithInstitutionFragment;
  readonly company: UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFragment;
}

function DefaultPreListingHeroBannerContentChild({
  actor,
  company,
}: DefaultPreListingHeroBannerContentProps) {
  const { t } = useTranslation();

  const { feeDiscount, feeDiscountAlertType } = useFeeDiscountAlert({
    company,
  });

  return (
    <HeroBannerWrapper bg={{ base: `transparent`, lg: `white` }}>
      {feeDiscountAlertType ? (
        <DefaultPreListingHeroBannerContentWithFeeDiscount
          companyName={company.name}
          firstName={actor.firstName}
          feeDiscount={feeDiscount}
          feeDiscountAlertType={feeDiscountAlertType}
        />
      ) : (
        <Text textStyle="heading-4xl" mb={7}>
          {t(`hello`)}, {actor.firstName}
        </Text>
      )}
    </HeroBannerWrapper>
  );
}

const DefaultPreListingHeroBannerContent = withCurrentActor(
  (props: DefaultPreListingHeroBannerContentProps) => (
    <DefaultPreListingHeroBannerContentChild {...props} />
  ),
);

const PostActivityPreListingHeroBannerContent = ({
  companyName,
}: {
  readonly companyName: string;
}) => {
  const { t } = useTranslation();

  return (
    <HeroBannerWrapper bg={{ base: `transparent`, lg: `white` }}>
      <Text textStyle="heading-4xl" mb={7}>
        {t(`your_company_dashboard`, { companyName })}
      </Text>
      <Text textStyle="heading-2xl" mb={2}>
        {t(`monitor_your_transactions`)}
      </Text>
      <Text textStyle="text-md">
        {t(`monitor_your_transactions_description`)}
      </Text>
    </HeroBannerWrapper>
  );
};

const PreListingHeroBannerV2 = ({ id }: { id: string }) => {
  const query = useUnaccreditedSellerDashboardByIdPageQuery({
    variables: { id },
  });

  return (
    <WithQuery query={query} fallback={<HeroBannerSkeleton />}>
      {({ data: { companyById: company } }) => {
        const { activity } = company;
        if (
          getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2({
            activity,
          })
        ) {
          return (
            <PostActivityPreListingHeroBannerContent
              companyName={company.name}
            />
          );
        }

        return <DefaultPreListingHeroBannerContent company={company} />;
      }}
    </WithQuery>
  );
};

export default PreListingHeroBannerV2;
