import { ReactNode } from "react";

import { Box, BoxProps, Text, VStack } from "@chakra-ui/react";

interface ActivitySectionProps extends BoxProps {
  readonly title?: string;
  readonly children: ReactNode;
}

const ActivitySection = ({
  title,
  children,
  ...boxProps
}: ActivitySectionProps) => (
  <Box w="full" {...boxProps}>
    {!!title && (
      <Text textStyle="heading-3xl" mb={{ base: 0, md: 4 }}>
        {title}
      </Text>
    )}
    <VStack alignItems="left" spacing={8}>
      {children}
    </VStack>
  </Box>
);

export default ActivitySection;
