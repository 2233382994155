import { ShareDetailsDisclaimer } from "@/components/common";
import { BidRoundedSharesTooltip } from "@/components/tooltip";
import { formatShares } from "@/utils";

const BidSellerRoundingDisclaimer = ({
  numSharesOriginal,
}: {
  readonly numSharesOriginal: number;
}) => (
  <ShareDetailsDisclaimer tooltip={<BidRoundedSharesTooltip />}>
    of buyer&apos;s original bid of {formatShares(numSharesOriginal)} shares
  </ShareDetailsDisclaimer>
);

export default BidSellerRoundingDisclaimer;
