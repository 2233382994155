import Image from "next/image";

import { chakra } from "@chakra-ui/react";

const NextImageProps = [
  `alt`,
  `blurDataURL`,
  `height`,
  `layout`,
  `loader`,
  `loading`,
  `onError`,
  `onLoad`,
  `onLoadingComplete`,
  `placeholder`,
  `priority`,
  `quality`,
  `sizes`,
  `src`,
  `width`,
];

const NextImage = chakra(Image, {
  shouldForwardProp: (prop) => NextImageProps.includes(prop),
});

export default NextImage;
