import { Box, Flex, Text, VStack } from "@chakra-ui/react";

import { StepRouter } from "./types";

const StepsHeader = <TStepKeys extends string>({
  stepRouter: { stepsInfo, stepControls },
  steps,
}: {
  readonly stepRouter: StepRouter<TStepKeys>;
  readonly steps: readonly {
    readonly stepTitle: string;
    readonly stepKey: TStepKeys;
    readonly isStepValid: boolean;
  }[];
}) => (
  <Flex h="20">
    {steps.map(({ stepTitle, stepKey }, index) => {
      const isCurrentStep = stepsInfo.currentStepKey === stepKey;
      const isDisabled = index !== 0 && !steps[index - 1].isStepValid;

      return (
        <VStack
          disabled={isDisabled}
          key={stepKey}
          as="button"
          _hover={{
            bg: `grey.75`,
          }}
          _active={{
            bg: `grey.100`,
          }}
          _disabled={{
            color: `grey.400`,
            cursor: `not-allowed`,
            bg: `grey.25`,
          }}
          borderColor="grey.200"
          borderWidth={0.5}
          __css={{
            "&:not(:first-child)": {
              borderLeftWidth: 0,
            },
          }}
          _focus={{
            outline: `none`,
          }}
          _focusVisible={{
            outline: `none`,
            boxShadow: `focus`,
          }}
          transition="all .1s ease-in-out"
          flex="1"
          onClick={(e) => {
            e.preventDefault();
            stepControls.jumpToStep(stepKey);
          }}
          bg={isCurrentStep ? `grey.50` : `grey.25`}
          justify="center"
          h="full"
          p={0}
          m={0}
          position="relative"
        >
          <Text fontWeight="medium">
            {index + 1}. {stepTitle}
          </Text>
          {isCurrentStep && (
            <Box
              position="absolute"
              bottom="0"
              w="full"
              h="1"
              bg="salmon.900"
            />
          )}
        </VStack>
      );
    })}
  </Flex>
);
export default StepsHeader;
