import { VStack, StackProps } from "@chakra-ui/react";

import { InformativeArticles } from "@/components/dashboard";
import { constants } from "@/utils";

import { NavigationCard } from "./NavigationCard";
import { SellNowCard } from "./SellNowCard";

const marketingWebsiteLinks = [
  constants.marketing_website_links.calculate_price_of_shares,
  constants.marketing_website_links.dive_into_vc_secondaries,
  constants.marketing_website_links.how_to_sell_private_shares,
];

const Sidebar = ({ ...stackProps }: StackProps) => (
  <VStack spacing={4} {...stackProps}>
    <NavigationCard />
    <SellNowCard />
    <InformativeArticles.Card>
      {marketingWebsiteLinks.map(({ title, url }) => (
        <InformativeArticles.Link key={url} href={url}>
          {title}
        </InformativeArticles.Link>
      ))}
    </InformativeArticles.Card>
  </VStack>
);

export default Sidebar;
