import { useTranslation } from "react-i18next";

import { BidState, BrokerBidActivityCardTimestampsBidFragment } from "@/gql";

import ActivityCardTimestamp from "./ActivityCardTimestamp";

const BrokerBidActivityCardTimestamps = ({
  bid,
}: {
  readonly bid: BrokerBidActivityCardTimestampsBidFragment;
}) => {
  const isExpired = bid.state === BidState.Expired;
  const { t } = useTranslation();

  const showSubmittedAt = bid.state === BidState.InReview;
  const showPlacedAt = !!bid.placedAt && bid.state !== BidState.InReview;
  const showExpiresAt = !!bid.expireAt && !isExpired;
  const showExpiredAt = !!bid.expireAt && isExpired;

  return (
    <>
      {showSubmittedAt && (
        <ActivityCardTimestamp
          title={t(`submitted_to_hiive_for_review`)}
          date={bid.insertedAt}
        />
      )}
      {showPlacedAt && (
        <ActivityCardTimestamp
          title={t(`placed_by_hiive`)}
          date={bid.placedAt}
        />
      )}
      {showExpiresAt && (
        <ActivityCardTimestamp title={t(`expires`)} date={bid.expireAt} />
      )}
      {showExpiredAt && (
        <ActivityCardTimestamp title={t(`expired`)} date={bid.expireAt} />
      )}
    </>
  );
};

export default BrokerBidActivityCardTimestamps;
