import { Text } from "@chakra-ui/react";

import { CompanyStandingBidCardTransferDetailsStandingBidFragment } from "@/gql";
import { acceptsSharesToString, acceptsTransferMethodsToString } from "@/utils";

const StandingBidCardTransferDetails = ({
  standingBid,
}: {
  readonly standingBid: CompanyStandingBidCardTransferDetailsStandingBidFragment;
}) => {
  const acceptsShares = acceptsSharesToString(standingBid.acceptsShares);
  const acceptsTransferMethods = acceptsTransferMethodsToString(
    standingBid.acceptsTransferMethods,
  );

  return (
    <Text
      textStyle="text-lg"
      whiteSpace="nowrap"
      display={{ base: `none`, md: `block` }}
    >
      {acceptsTransferMethods}
      {` | `}
      {acceptsShares}
    </Text>
  );
};

export default StandingBidCardTransferDetails;
