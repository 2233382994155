import { ArrowRight } from "@phosphor-icons/react";
import { useFormikContext } from "formik";
import { ReactNode } from "react";
import { Trans } from "react-i18next";

import { Box, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  CurrentContextDocument,
  InvestorType,
  useUpdateUserMutation,
} from "@/gql";
import { useMutationWithError, useRouteToNextOnboardingStep } from "@/hooks";

import { IndividualAccreditationPageFormValues } from "./types";

const ContinueAsSellerButton = ({
  children,
}: {
  readonly children?: ReactNode;
}) => {
  const { values } = useFormikContext<IndividualAccreditationPageFormValues>();

  const routeToNextStep = useRouteToNextOnboardingStep();

  const [updateUserMutation] = useMutationWithError(
    useUpdateUserMutation({
      refetchQueries: [CurrentContextDocument],
    }),
    `updateUser`,
  );

  const handleClick = () => {
    updateUserMutation({
      variables: {
        input: {
          investorType: InvestorType.UnaccreditedSeller,
          countryId: values.countryId,
        },
      },
    }).then(() => routeToNextStep());
  };

  return (
    <HiiveButton
      variant="text-grey"
      sentryLabel="[IndividualAccreditationForm/ContinueAsSellerButton]"
      p="0"
      onClick={handleClick}
    >
      {children}
      <Box
        as={ArrowRight}
        key="icon"
        size={22}
        display="inline-block"
        verticalAlign="middle"
        ml={1}
      />
    </HiiveButton>
  );
};

export const ContinueAsSellerCTA = () => (
  <Box textAlign="center">
    <Box>
      <Trans
        i18nKey="dont_meet_criteria_but_sell"
        components={[
          <strong key="bold" />,
          <ContinueAsSellerButton key="seller" />,
        ]}
      />
    </Box>
    <Text mt={12}>
      <Trans i18nKey="contact_discuss_accreditation_status" />
    </Text>
  </Box>
);
