import {
  GetIsBrokerCounterpartyForStandingBidStandingBidFragment,
  GetIsBrokerCounterpartyForStandingBidUserFragment,
  GetIsBuyerForStandingBidStandingBidFragment,
  GetIsBuyerForStandingBidUserFragment,
  GetIsConditionallyCompletedStandingBidStandingBidFragment,
  GetIsPartiallyAcceptedStandingBidStandingBidFragment,
  GetIsSolicitedStandingBidStandingBidFragment,
  StandingBidState,
} from "@/gql";
import { getIsBroker, getIsBrokerForStandingBid } from "@/utils";

export const getIsPartiallyAcceptedStandingBid = (
  standingBid: GetIsPartiallyAcceptedStandingBidStandingBidFragment,
): boolean =>
  standingBid.state === StandingBidState.Open &&
  !!standingBid.allowPartialAccept &&
  standingBid.acceptedBidPrices.length >= 1;

export const getIsConditionallyCompletedStandingBid = (
  standingBid: GetIsConditionallyCompletedStandingBidStandingBidFragment,
): boolean => standingBid.state === StandingBidState.ConditionallyCompleted;

export const getIsBuyerForStandingBid = (
  user: GetIsBuyerForStandingBidUserFragment,
  standingBid: GetIsBuyerForStandingBidStandingBidFragment,
) =>
  standingBid.buyerId === user.id ||
  (!!standingBid.buyerInstitutionId &&
    standingBid.buyerInstitutionId === user.institutionId);

export const getIsBrokerCounterpartyForStandingBid = ({
  user,
  standingBid,
}: {
  readonly user: GetIsBrokerCounterpartyForStandingBidUserFragment;
  readonly standingBid: GetIsBrokerCounterpartyForStandingBidStandingBidFragment;
}) => getIsBroker(user) && !getIsBrokerForStandingBid({ user, standingBid });

export const getIsSolicitedStandingBid = (
  standingBid: GetIsSolicitedStandingBidStandingBidFragment,
) => !!standingBid.solicited;
