import { useField } from "formik";

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputProps,
} from "@chakra-ui/react";

import { ImageUploader } from "@/components/common";
import { TransloaditTemplateKind } from "@/gql";

interface LogoInputProps extends InputProps {
  readonly label: string;
  readonly name: string;
}

const LogoInput = ({ label, name }: LogoInputProps) => {
  const [field, { touched, error }, { setValue }] = useField(name);

  const onUploadComplete = (url: string) => setValue(url);

  return (
    <FormControl id={field.name} isInvalid={(error && touched) || false}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <ImageUploader
        currentImageUrl={field.value}
        templateKind={TransloaditTemplateKind.CompanyImage}
        onUploadComplete={onUploadComplete}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default LogoInput;
