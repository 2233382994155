import sortBy from "lodash/sortBy";

import {
  BidState,
  CountTotalUnreadBidDiscussionMessagesBidFragment,
  FilterOpenBidsBidFragment,
  FilterPastBidsBidFragment,
  GetBidNumSharesActualBidFragment,
  GetIsBuyerForBidBidFragment,
  RejectionReason,
  UserWithInstitutionFragment,
} from "@/gql";

/**
 * @deprecated replace with filtering / sorting on server
 *
 * Keeps "open" bids including `active` and `countered` bids
 */
export const filterOpenBids = <T extends FilterOpenBidsBidFragment>(
  bids: readonly T[],
) =>
  bids.filter(({ state }) =>
    [BidState.Active, BidState.Countered].includes(state),
  );

/**
 * @deprecated replace with filtering / sorting on server
 *
 * Keeps "past" bids
 */
export const filterPastBids = <T extends FilterPastBidsBidFragment>(
  bids: readonly T[],
) =>
  sortBy(
    bids.filter(
      ({ state, rejectionReason }) =>
        state === BidState.Accepted ||
        state === BidState.Expired ||
        (state === BidState.Rejected &&
          rejectionReason !== RejectionReason.BidOrderRejectedByHiive),
    ),
    (bid) => bid.state !== BidState.Accepted,
  );

export const getIsBuyerForBid = (
  actor: UserWithInstitutionFragment,
  bid: GetIsBuyerForBidBidFragment,
) =>
  bid.buyerId === actor.id ||
  (!!bid.buyerInstitutionId && bid.buyerInstitutionId === actor.institutionId);

export const getBidNumSharesActual = (bid: GetBidNumSharesActualBidFragment) =>
  bid.actualNumSharesVisible ? bid.numSharesActual : bid.numShares;

const getIsInactiveBid = (state: BidState): boolean =>
  [
    BidState.Closed,
    BidState.Expired,
    BidState.Rejected,
    BidState.Withdrawn,
    BidState.Superseded,
  ].includes(state);

export const countTotalUnreadBidDiscussionMessages = (
  bids: readonly CountTotalUnreadBidDiscussionMessagesBidFragment[],
): number =>
  bids.reduce(
    (count: number, bid: CountTotalUnreadBidDiscussionMessagesBidFragment) => {
      if (
        getIsInactiveBid(bid.state) ||
        !bid.discussion ||
        bid.discussion.numUnreadMessages === 0
      ) {
        return count;
      }
      return count + bid.discussion.numUnreadMessages;
    },
    0,
  );
