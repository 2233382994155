import { MagnifyingGlass } from "@phosphor-icons/react";

import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from "@chakra-ui/react";

interface SearchInputProps extends Omit<InputProps, "onChange"> {
  readonly onChange: (searchText: string) => void;
  readonly searchText: string;
}

const SearchInput = ({ onChange, searchText, ...rest }: SearchInputProps) => (
  <InputGroup w="full">
    <InputLeftElement>
      <MagnifyingGlass size={20} color="var(--chakra-colors-grey-200)" />
    </InputLeftElement>
    <Input
      value={searchText}
      onChange={(e) => onChange(e.target.value)}
      {...rest}
    />
  </InputGroup>
);

export default SearchInput;
