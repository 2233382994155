import {
  ActivityGroup,
  ActivityGroupEmptyState,
  WithQuery,
} from "@/components/common";
import {
  MessageTopicType,
  StandingBidBuyerDiscussionsStandingBidFragment,
  useMyDiscussionListDiscussionsQuery,
} from "@/gql";

import { StandingBidBuyerDiscussionsSkeleton } from "./StandingBidBuyerDiscussionsSkeleton";
import { YourDiscussionCard } from "./YourDiscussionCard";

const DiscussionsEmptyState = () => (
  <ActivityGroupEmptyState message="You have no active inquiries on your Standing Bid, you will be notified when you receive a message from an interested seller." />
);

export const StandingBidBuyerDiscussions = ({
  standingBid,
}: {
  readonly standingBid: StandingBidBuyerDiscussionsStandingBidFragment;
}) => {
  const query = useMyDiscussionListDiscussionsQuery({
    variables: {
      topicFilter: { type: MessageTopicType.StandingBid, id: standingBid.id },
    },
  });

  return (
    <WithQuery query={query} fallback={<StandingBidBuyerDiscussionsSkeleton />}>
      {({ data: { myDiscussions } }) => (
        <ActivityGroup title="Inquiries" emptyState={<DiscussionsEmptyState />}>
          {myDiscussions.map((discussion) => (
            <YourDiscussionCard key={discussion.id} discussion={discussion} />
          ))}
        </ActivityGroup>
      )}
    </WithQuery>
  );
};
