import { ReactNode } from "react";

import { Flex } from "@chakra-ui/react";

/**
 * Example usage
 * ```tsx
 * <TagList>
 *      {industries.map({id, name}) => (
 *          <Tag variant="blue" key={id}>
 *              {name}
 *          </Tag>
 *      )}
 * </TagList>
 * ```
 */
const TagList = ({ children }: { readonly children: ReactNode }) => (
  <Flex gap={2} wrap="wrap">
    {children}
  </Flex>
);

export default TagList;
