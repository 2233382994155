import { ApolloProvider } from "@apollo/client";
import { withFronteggApp } from "@frontegg/nextjs/pages";
import { SpeedInsights } from "@vercel/speed-insights/next";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";

import { ChakraProvider } from "@chakra-ui/react";

import { AsyncLDProvider } from "@/components/async-with-ld-provider";
import {
  PageFooter,
  PageHeader,
  boxStyle,
  boxTitleStyle,
  containerStyle,
  rootStyle,
} from "@/components/frontegg";
import { AnalyticsProvider, FrigadeProvider } from "@/components/providers";
import { useApolloClient, useMaintenanceMode } from "@/hooks";
import "@/i18n";
import store, { persistor } from "@/state";
import Fonts from "@/styles/fonts";
import "@/styles/globals.css";
import theme from "@/styles/theme";
import type { Page } from "@/types/page";

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const clientSideId = process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID;

type Props = AppProps & {
  readonly Component: Page;
};

const FrontChatScript = () => (
  <Script
    src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
    strategy="afterInteractive"
  />
);

const GoogleAnalyticsScript = () => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) return null;
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
        `}
      </Script>
    </>
  );
};

const useMaintenanceModeRedirect = () => {
  const maintenanceMode = useMaintenanceMode();
  const router = useRouter();

  useEffect(() => {
    if (!maintenanceMode) return;
    router.push(`/maintenance`);
  }, [maintenanceMode]);
};

const MyApp = ({ Component, pageProps }: Props) => {
  const getLayout = Component.getLayout ?? ((page) => page);
  useMaintenanceModeRedirect();

  const apolloClient = useApolloClient();

  return (
    <>
      <ChakraProvider theme={theme}>
        <Fonts />
        <ApolloProvider client={apolloClient}>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AnalyticsProvider>
                <FrigadeProvider>
                  <GoogleAnalyticsScript />
                  <FrontChatScript />
                  <AsyncLDProvider clientSideId={clientSideId}>
                    {getLayout(<Component {...pageProps} />)}
                  </AsyncLDProvider>
                </FrigadeProvider>
              </AnalyticsProvider>
            </PersistGate>
          </ReduxProvider>
        </ApolloProvider>
      </ChakraProvider>
      <SpeedInsights />
    </>
  );
};

export default withFronteggApp(MyApp, {
  hostedLoginBox: false,
  authOptions: {
    keepSessionAlive: true,
  },
  customLoader: () => null,
  themeOptions: {
    loginBox: {
      login: {
        docTitle: `Sign in | Hiive`,
        signupMessage: ``,
        // @ts-expect-error Need to apply custom style to the root element
        rootStyle,
        boxStyle,
        boxTitleStyle,
        containerStyle,
        pageHeader: () => <PageHeader />,
        pageFooter: () => <PageFooter />,
      },
    },
  },
  contextOptions: {
    tenantResolver: () => ({ tenant: process.env.NEXT_PUBLIC_FRONTEGG_TENANT }),
  },
});
