import { ReactNode } from "react";

import { VStack, HStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

const SkeletonIcon = () => <Skeleton w={6} h={6} mx={0.5} />;

const SkeletonWrapper = ({ children }: { readonly children: ReactNode }) => (
  <HStack
    h={12}
    spacing={3.5}
    alignItems="center"
    justifyContent="center"
    px={5}
    py={2.5}
    borderBottomWidth={1}
    borderColor="grey.50"
    _last={{ borderBottomWidth: 0 }}
  >
    {children}
  </HStack>
);

const CompanyComboboxSkeleton = () => (
  <VStack alignItems="start" spacing={0}>
    <SkeletonWrapper>
      <SkeletonIcon />
      <Skeleton h="12px" w="60px" />
    </SkeletonWrapper>
    <SkeletonWrapper>
      <SkeletonIcon />
      <Skeleton h="12px" w="120px" />
    </SkeletonWrapper>
    <SkeletonWrapper>
      <SkeletonIcon />
      <Skeleton h="12px" w="90px" />
    </SkeletonWrapper>
    <SkeletonWrapper>
      <SkeletonIcon />
      <Skeleton h="12px" w="120px" />
    </SkeletonWrapper>
    <SkeletonWrapper>
      <SkeletonIcon />
      <Skeleton h="12px" w="150px" />
    </SkeletonWrapper>
  </VStack>
);

export { CompanyComboboxSkeleton as Skeleton };
