import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { Flex, GridItem, Link, SimpleGrid } from "@chakra-ui/react";

import { FormField } from "@/components/form";
import {
  BidCancellationWarning,
  TransactionSizeError,
} from "@/components/listings";
import {
  BidState,
  ModifyListingModalBidFragment,
  ModifyListingModalListingFragment,
} from "@/gql";
import { constants, sumShareSeriesMakeup } from "@/utils";

import { ModifyListingModalFormValues } from "./types";

const getNumberOfBidsToCancel = (
  bids: readonly ModifyListingModalBidFragment[],
  maxShares: number,
) =>
  bids.filter(({ state, numSharesActual, counterNumShares }) => {
    if (state === BidState.Active) {
      return numSharesActual > maxShares;
    }

    if (state === BidState.Countered && !!counterNumShares) {
      return counterNumShares > maxShares;
    }

    return false;
  }).length;

export const ModifyListingLotFields = ({
  listing,
}: {
  readonly listing: ModifyListingModalListingFragment;
}) => {
  const {
    values: { shareSeriesMakeup },
    errors,
    touched,
  } = useFormikContext<ModifyListingModalFormValues>();

  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  const { t } = useTranslation();

  const numShares = sumShareSeriesMakeup(shareSeriesMakeup);

  const [_numSharesField, { error: numSharesError }] = useField(
    `shareSeriesMakeup[0].numShares`,
  );
  const [_pricePerShareField, { error: pricePerShareError }] = useField(
    `pricePerShare`,
  );

  const minTransactionSizeError = t(`min_transaction_size_error`, {
    minSize: isPartiallySold
      ? constants.min_listing_size_for_partial_bids.text
      : constants.min_listing_size.text,
  });

  const showTransactionSizeError =
    !!touched.pricePerShare &&
    Object.values(errors).some((error) => error === minTransactionSizeError);

  const numberOfBidsToCancel = getNumberOfBidsToCancel(listing.bids, numShares);

  const showBidCancellationWarning =
    numberOfBidsToCancel > 0 && !showTransactionSizeError;

  return (
    <SimpleGrid columns={2} rowGap={{ base: 7, md: 2 }} columnGap={9}>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <Flex direction="column" gap={3}>
          <FormField.Control name="shareSeriesMakeup[0].numShares">
            <FormField.NumberInput
              controlledValue={numShares}
              label="Number of shares"
              isDisabled={!!shareSeriesMakeup && shareSeriesMakeup.length > 1}
            />
            {numSharesError !== minTransactionSizeError && <FormField.Error />}
          </FormField.Control>
          {showBidCancellationWarning && (
            <BidCancellationWarning
              numberOfBidsToCancel={numberOfBidsToCancel}
            />
          )}
        </Flex>
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <FormField.Control name="pricePerShare">
          <FormField.MoneyInput
            label="Price per share"
            info={
              <Link
                href="/terms-and-conditions#fees-and-commissions"
                target="_blank"
                fontSize="sm"
                color="h-salmon-pink"
              >
                Fees
              </Link>
            }
          />
          {pricePerShareError !== minTransactionSizeError && (
            <FormField.Error />
          )}
        </FormField.Control>
      </GridItem>

      {showTransactionSizeError && (
        <TransactionSizeError error={minTransactionSizeError} />
      )}
    </SimpleGrid>
  );
};
