import { Flex } from "@chakra-ui/react";

import { StandingBidTransactions } from "@/components/postings";
import { StandingBidBuyerActivityStandingBidFragment } from "@/gql";

import { StandingBidBuyerDiscussions } from "./StandingBidBuyerDiscussions";

const StandingBidBuyerActivity = ({
  standingBid,
}: {
  readonly standingBid: StandingBidBuyerActivityStandingBidFragment;
}) => (
  <Flex w="full" direction="column" gap={8}>
    <StandingBidTransactions standingBid={standingBid} />
    <StandingBidBuyerDiscussions standingBid={standingBid} />
  </Flex>
);

export default StandingBidBuyerActivity;
