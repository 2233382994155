import { Flex } from "@chakra-ui/react";

import { ActivityCardSkeleton, Skeleton } from "@/components/common";

import { DashboardNotificationsSkeleton } from "./DashboardNotificationsSkeleton";

export const DashboardYourActivitySkeleton = () => (
  <Flex direction="column" gap={8} w="full">
    <Flex direction="column" gap={4}>
      <Skeleton h="30px" maxW="25%" />
      <DashboardNotificationsSkeleton />
    </Flex>

    <Flex direction="column" gap={4}>
      <Skeleton h="20px" maxW="20%" />
      <ActivityCardSkeleton includeBody />
      <ActivityCardSkeleton includeBody />

      <Skeleton h="20px" maxW="30%" />
      <ActivityCardSkeleton includeBody />
      <ActivityCardSkeleton includeBody />
      <ActivityCardSkeleton includeBody />
    </Flex>
  </Flex>
);
