import { Form } from "formik";
import * as Yup from "yup";

import { Stack, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { AutosizeTextAreaInput, FormikQL } from "@/components/form";
import {
  DiscussionPageDiscussionByIdDocument,
  SendMessageFormDiscussionFragment,
  SendMessageInput,
  useSendMessageMutation,
} from "@/gql";

import OffPlatformReminder from "./OffPlatformReminder";

const initialValues = (discussionId: string): SendMessageInput => ({
  discussionId,
  messageBody: ``,
});

const validationSchema = Yup.object().shape({
  discussionId: Yup.string().nullable().required(`Required`),
  messageBody: Yup.string().nullable().required(`Required`),
});

export const SendMessageForm = ({
  discussion,
}: {
  readonly discussion: SendMessageFormDiscussionFragment;
}) => {
  const mutation = useSendMessageMutation({
    refetchQueries: [DiscussionPageDiscussionByIdDocument],
  });

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`sendMessage`]}
      initialValues={initialValues(discussion.id)}
      validationSchema={validationSchema}
      resetAfterSubmit
    >
      {({ isSubmitting }) => (
        <Form style={{ width: `100%` }}>
          <VStack alignItems="flex-start" w="full">
            <AutosizeTextAreaInput
              name="messageBody"
              rows={5}
              minRows={5}
              maxRows={6}
              placeholder="Type message..."
            />
            <Stack
              direction={{ base: `column-reverse`, md: `row` }}
              alignItems="center"
              spacing={3}
            >
              <OffPlatformReminder discussion={discussion} />
              <HiiveButton
                type="submit"
                sentryLabel="[SendMessageForm] Send"
                variant="rounded-solid-salmon"
                size="xl"
                maxW="unset"
                w={{ base: `full`, md: `170px` }}
                isLoading={isSubmitting}
              >
                Send
              </HiiveButton>
            </Stack>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};
