import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { AcceptBidMutation } from "@/gql";

import { StepKeys } from "./steps";

export const AcceptBidSequenceModalStepFormContext = createStepFormContext<
  StepKeys,
  AcceptBidMutation
>();

export const useAcceptBidSequenceModalStepFormContext = () =>
  useContext(AcceptBidSequenceModalStepFormContext);
