/* eslint-disable functional/prefer-readonly-type */
import { ReactNode } from "react";

import { List } from "@chakra-ui/react";

import { ItemList } from "@/components/form";

import { SelectMenuEmptyState } from "./SelectMenuEmptyState";
import { SelectMenuSkeleton } from "./SelectMenuSkeleton";
import { UseListboxGetMenuProps } from "./types";

const ListboxMenuEmptyState = () => <SelectMenuEmptyState />;
const ListboxMenuSkeleton = () => <SelectMenuSkeleton />;

const ListboxMenuContent = <TItem,>({
  children,
  items,
  isLoading,
}: {
  readonly children: ReactNode;
  readonly items: ItemList<TItem>;
  readonly isLoading?: boolean;
}) => {
  if (isLoading) return <ListboxMenuSkeleton />;

  if (items.length === 0) return <ListboxMenuEmptyState />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

interface ListboxMenuProps<TItem> {
  readonly children: ReactNode;
  readonly isOpen: boolean;
  readonly items: ItemList<TItem>;
  readonly getMenuProps: UseListboxGetMenuProps;
  readonly isLoading?: boolean;
  readonly style?: React.CSSProperties;
}

export const ListboxMenu = <TItem,>({
  isOpen,
  children,
  items,
  getMenuProps,
  isLoading,
  style,
}: ListboxMenuProps<TItem>) => (
  <List
    variant="menu"
    display={isOpen ? `block` : `none`}
    {...getMenuProps()}
    style={style}
  >
    {isOpen && (
      <ListboxMenuContent isLoading={isLoading} items={items}>
        {children}
      </ListboxMenuContent>
    )}
  </List>
);
