import currency from "currency.js";
import { Form } from "formik";
import { AnimatePresence } from "framer-motion";

import { AnimatedCardStep, StepFormikQL } from "@/components/form";
import {
  BidPageMyActivityDocument,
  CompanyWatchlistActorDocument,
  ListingPageListingByIdDocument,
  useSubmitBidCardBrokerSubmitBidMutation,
  UserActivityMyActivityDocument,
  ListingPageBrokerCounterpartyActivitySubmitBidCardListingFragment,
  BrokerSubmitBidInput,
} from "@/gql";
import { useMobileScrollIntoView, useStepRouter } from "@/hooks";
import { getNumSharesAvailableRounded } from "@/utils";

import SubmitBid from "./SubmitBid";
import SubmitBidCardConfirmation from "./SubmitBidCardConfirmation";
import { SubmitBidCardStepFormContext } from "./SubmitBidCardStepFormContext";
import { stepKeys } from "./steps";
import { SubmitBidCardFormValues, StepKeys } from "./types";

const getInitialValues = (
  listing?: ListingPageBrokerCounterpartyActivitySubmitBidCardListingFragment,
): SubmitBidCardFormValues => ({
  listingId: listing?.id || ``,
  numShares: listing ? getNumSharesAvailableRounded(listing) : 0,
  pricePerShare: !!listing?.listingPricePerShare
    ? listing.listingPricePerShare / 100
    : ``,
  timeLimit: 7,
  confirmed: false,
  unsolicited: null,
  affiliate: null,
});

const mapVariables = ({
  confirmed: _confirmed,
  pricePerShare,
  timeLimit,
  unsolicited,
  affiliate,
  ...values
}: SubmitBidCardFormValues) => {
  const input: BrokerSubmitBidInput = {
    ...values,
    solicited: !unsolicited,
    affiliate: !!affiliate,
    pricePerShare: currency(pricePerShare).intValue,
    timeLimit: timeLimit * 24,
  };

  return {
    input,
  };
};

const SubmitBidCard = ({
  listing,
}: {
  readonly listing: ListingPageBrokerCounterpartyActivitySubmitBidCardListingFragment;
}) => {
  const mutation = useSubmitBidCardBrokerSubmitBidMutation({
    refetchQueries: [
      ListingPageListingByIdDocument,
      BidPageMyActivityDocument,
      UserActivityMyActivityDocument,
      CompanyWatchlistActorDocument,
    ],
  });

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.submitBidFormCard,
      stepKeys.submitBidConfirmationFormCard,
    ],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  const { scrollRef } = useMobileScrollIntoView({}, [currentStepKey]);

  return (
    <StepFormikQL
      stepRouter={stepRouter}
      mutation={mutation}
      mutationNames={[`brokerSubmitBid`]}
      initialValues={getInitialValues(listing)}
      mapVariables={mapVariables}
      context={SubmitBidCardStepFormContext}
    >
      {({ formikProps }) => (
        <Form ref={scrollRef} style={{ width: `100%` }}>
          <AnimatePresence initial={false} mode="wait">
            {stepKeys.submitBidFormCard === currentStepKey && (
              <AnimatedCardStep key={stepKeys.submitBidFormCard}>
                <SubmitBid
                  listing={listing}
                  stepRouter={stepRouter}
                  {...formikProps}
                />
              </AnimatedCardStep>
            )}
            {stepKeys.submitBidConfirmationFormCard === currentStepKey && (
              <AnimatedCardStep key={stepKeys.submitBidConfirmationFormCard}>
                <SubmitBidCardConfirmation
                  stepRouter={stepRouter}
                  {...formikProps}
                />
              </AnimatedCardStep>
            )}
          </AnimatePresence>
        </Form>
      )}
    </StepFormikQL>
  );
};
export default SubmitBidCard;
