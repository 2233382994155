import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardHeader, Text } from "@chakra-ui/react";

/**
 * Outer container that houses various states of seller bank account collection/verification.
 */
const ModernTreasuryVeryAccountCardContainer = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <Card w="full">
      <CardHeader>
        <Text
          textStyle={{ base: `heading-xl`, md: `heading-2xl` }}
        >{t`account_information`}</Text>
      </CardHeader>
      {children}
    </Card>
  );
};

export default ModernTreasuryVeryAccountCardContainer;
