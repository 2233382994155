import { FlowKind } from "@/components/providers";
import { useFlowLauncher } from "@/hooks";

import RecurringAnnouncement from "./RecurringAnnouncement";

const CornerAnnouncement = ({ flowKind }: { readonly flowKind: FlowKind }) => {
  const { getFlowID } = useFlowLauncher();
  const flowID = getFlowID(flowKind);

  return (
    <RecurringAnnouncement
      flowId={flowID}
      modalPosition="bottom-right"
      appearance={{
        styleOverrides: {
          announcementTitle: {
            display: `block`,
            textAlign: `left`,
            fontSize: `1.5rem`,
            marginBottom: `1rem`,
          },
          announcementSubtitle: {
            display: `block`,
            textAlign: `left`,
            fontSize: `1rem`,
          },
          announcementMediaContainer: {
            order: `-1`,
            maxHeight: `200px`,
          },
          image: {
            minHeight: `auto`,
          },
          announcementCTAContainer: {
            justifyContent: `flex-start`,
          },
        },
      }}
    />
  );
};

export default CornerAnnouncement;
