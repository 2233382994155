import { ReactNode } from "react";

import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";

interface CallOutProps extends BoxProps {
  /** The visual variant */
  readonly variant: "olive" | "plum" | "yellow" | "red";
  /** The main content to display inside the call-out */
  readonly children: ReactNode;
}

const CallOut = ({ variant, ...rest }: CallOutProps) => {
  const themeStyles = useStyleConfig(`CallOut`, { variant });

  return <Box __css={themeStyles} {...rest} />;
};

export default CallOut;
