import { OnboardingUserFragment } from "@/gql";
import { FeatureFlags } from "@/hooks/featureFlags";

import { OnboardingStep, onboardingSteps } from "./config";

export const getNextIncompleteOnboardingStep = (
  currentStep: OnboardingStep,
  user: OnboardingUserFragment,
  featureFlags: FeatureFlags,
): OnboardingStep | null => {
  if (!currentStep.isComplete(user)) return currentStep;

  const subsequentStepRoute = currentStep.getSubsequentStepRoute(
    user,
    featureFlags,
  );
  if (!subsequentStepRoute) return null;

  const subsequentStep = onboardingSteps[subsequentStepRoute];
  return getNextIncompleteOnboardingStep(subsequentStep, user, featureFlags);
};
