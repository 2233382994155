import { useTranslation } from "react-i18next";

import { Card, CardHeader, Text, useDisclosure } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";

import AccreditationCardContent from "./AccreditationCardContent";
import AccreditationCardModifyContent from "./AccreditationCardModifyContent";

const AccreditationCard = withCurrentActor(({ actor }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: `accreditation-card-modify`,
  });

  return (
    <Card variant="darkened-footer" w="full" flex="1">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`accreditation`)}</Text>
      </CardHeader>
      {isOpen ? (
        <AccreditationCardModifyContent actor={actor} onClose={onClose} />
      ) : (
        <AccreditationCardContent actor={actor} onOpen={onOpen} />
      )}
    </Card>
  );
});

export default AccreditationCard;
