export const seller_info = {
  page: {
    description: `Hiive’s platform aims to give you transparency into the market for your stock, and control over your sale. Assess the market price, set your own ask, then negotiate with buyers and complete your sale.`,
  },
  explore_pricing: {
    title: `Explore pricing`,
    description: `Browse listings and bids placed by other users to get insight into the potential value of your stock. Compare current listings and bids to "last round value". If you need help, talk to a Hiive representative to get insight into the market.`,
  },
  create_listing: {
    title: `Create your listing`,
    description: `Finalize an asking price with the help of our quick comparison tool. Then post an anonymous listing. A listing will enable you to receive bids from buyers, but doesn't commit you to selling. You control the pace and terms of your sale.`,
  },
  sell_shares: {
    title: `Accept a bid and sell your shares!`,
    description: `Interested buyers may place a bid or inquire about your listing. You can then accept, counter-offer, or message them to negotiate. If you accept a bid, Hiive will guide you through the closing process, which can take 30-90 days and requires the company’s approval.`,
  },
};
