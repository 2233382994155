import { Card, CardBody, CardFooter, CardHeader, Flex } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import { CompanyPriceGraphSkeleton } from "@/components/companies";

const CompanyHistoricalPricingCardSkeleton = () => (
  <Card>
    <CardHeader>
      <Skeleton h="20px" w="50%" />
    </CardHeader>
    <CardBody>
      <CompanyPriceGraphSkeleton />
    </CardBody>
    <CardFooter>
      <Flex direction="column" gap={4} justify="start" w="full">
        {[...Array(3).keys()].map((index) => (
          <Skeleton key={index} h="14px" />
        ))}
        <Skeleton h="14px" maxW="80%" />
      </Flex>
    </CardFooter>
  </Card>
);

export default CompanyHistoricalPricingCardSkeleton;
