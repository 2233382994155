import { useEffect, useRef, useState } from "react";

import { Box, CardBody, VStack } from "@chakra-ui/react";

import { client } from "@/apollo-client";
import {
  DiscussionState,
  DiscussionThreadCardDiscussionFragment,
  DiscussionThreadCardDiscussionMessageFragment,
  StandingBidSellerDiscussionListDiscussionFragment,
  useDiscussionThreadOnNewMessageSubscription,
  useMarkMessageAsReadMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";

import { DiscussionMessageList } from "./DiscussionMessageList";

const maybeSetDiscussionStateToActive = (
  discussion:
    | DiscussionThreadCardDiscussionFragment
    | StandingBidSellerDiscussionListDiscussionFragment,
  messages: readonly DiscussionThreadCardDiscussionMessageFragment[],
) => {
  const partyIds = messages.reduce(
    (
      acc: readonly string[],
      msg: DiscussionThreadCardDiscussionMessageFragment,
    ) => {
      if (acc.includes(msg.senderId)) return acc;
      return [...acc, msg.senderId];
    },
    [],
  );

  const hasMessagesFromBothParties = partyIds.length === 2;

  if (
    discussion.state === DiscussionState.Active ||
    !hasMessagesFromBothParties
  )
    return;

  client.cache.modify({
    id: client.cache.identify(discussion),
    fields: {
      state() {
        return DiscussionState.Active;
      },
    },
  });
};

const useScrollToBottomOfThread = (depsLength?: number | undefined) => {
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [isInitialScroll, setIsInitialScroll] = useState(true);

  const scrollToLastMessage = () => {
    if (depsLength === 0) {
      setIsInitialScroll(false);
      return;
    }

    scrollToRef.current?.scrollIntoView({
      block: `nearest`,
      inline: `nearest`,
      behavior: isInitialScroll ? `auto` : `smooth`,
    });
    setIsInitialScroll(false);
  };

  useEffect(() => {
    scrollToLastMessage();
  }, [depsLength]);

  return { scrollToRef, isInitialScroll };
};

const useWithNewMessages = (
  discussion:
    | DiscussionThreadCardDiscussionFragment
    | StandingBidSellerDiscussionListDiscussionFragment,
  initialMessages: readonly DiscussionThreadCardDiscussionMessageFragment[],
) => {
  const [allMessages, setAllMessages] = useState(initialMessages);

  const [markMessageAsReadMutation] = useMutationWithError(
    useMarkMessageAsReadMutation(),
    `markMessageAsRead`,
  );

  useDiscussionThreadOnNewMessageSubscription({
    variables: { discussionId: discussion.id },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const newMessage = data?.onNewMessage as DiscussionThreadCardDiscussionMessageFragment;
      if (!newMessage) return;
      setAllMessages((prevMessages) => {
        const hasAlreadyAddedNewMessage = prevMessages.some(
          ({ id }) => id === newMessage.id,
        );

        if (hasAlreadyAddedNewMessage) return prevMessages;

        markMessageAsReadMutation({
          variables: { messageId: newMessage.id },
        });

        const nextMessages = [...prevMessages, newMessage];

        maybeSetDiscussionStateToActive(discussion, nextMessages);

        return nextMessages;
      });
    },
  });

  return allMessages;
};

const DiscussionThreadCardBody = ({
  discussion,
}: {
  readonly discussion: DiscussionThreadCardDiscussionFragment;
}) => {
  const allMessages = useWithNewMessages(discussion, discussion.messages);

  const { scrollToRef, isInitialScroll } = useScrollToBottomOfThread(
    allMessages.length,
  );

  return (
    <CardBody
      // There is a slight delay when we scroll to the bottom of the thread on page load
      // This just prevents the scrollbar from "jumping" from the top to the bottom of the thread
      // Which looks ugly
      css={
        isInitialScroll && {
          "&::-webkit-scrollbar": {
            display: `contents`,
          },
        }
      }
      maxH="120"
      overflowY="auto"
      h={{ base: `96`, md: `120` }}
      flex="none"
      p={0}
      px={{ base: 4, md: 6 }}
      pt={{ base: 4, md: 6 }}
      as={VStack}
      alignItems="flex-start"
    >
      <DiscussionMessageList
        discussion={discussion}
        allMessages={allMessages}
        visibility={isInitialScroll ? `hidden` : `visible`}
      />
      <Box pt={{ base: 4, md: 6 }} ref={scrollToRef} />
    </CardBody>
  );
};

export default DiscussionThreadCardBody;
