import { ReactNode } from "react";

import { Box, BoxProps, Container } from "@chakra-ui/react";

interface HeroBannerWrapperProps extends BoxProps {
  readonly children: ReactNode;
}

const HeroBannerWrapper = ({
  children,
  ...boxProps
}: HeroBannerWrapperProps) => (
  <Box
    w="full"
    px={{ base: 4, lg: 8 }}
    borderBottomColor={{ base: `transparent`, lg: `grey.200` }}
    borderBottomWidth={0.5}
    minH={{ base: `unset`, lg: 71 }}
    {...boxProps}
  >
    <Container maxW="max-width-md" px="0" py={{ base: 4, lg: 7 }}>
      {children}
    </Container>
  </Box>
);
export default HeroBannerWrapper;
