import { Td, Tr, HStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

export const TableSkeleton = () => (
  <>
    {[...Array(20).keys()].map((index) => (
      <Tr
        key={`hiive50-index-table-${index}`}
        borderY={index === 0 ? `none` : `1px solid`}
        borderColor={{ base: `transparent`, md: `grey.50` }}
      >
        <Td border="none">
          <HStack alignItems="center">
            <Skeleton h={6} w={6} />
            <Skeleton h={6} w={44} />
          </HStack>
        </Td>
        <Td border="none">
          <Skeleton h={6} w={24} />
        </Td>
        <Td border="none">
          <Skeleton h={6} w={12} />
        </Td>
        <Td border="none">
          <Skeleton h={6} w={12} />
        </Td>
        <Td border="none">
          <Skeleton h={6} w={12} />
        </Td>
        <Td border="none">
          <Skeleton h={6} w={24} />
        </Td>
      </Tr>
    ))}
  </>
);
