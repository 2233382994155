/* eslint-disable consistent-return */
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { FlowKind, ModalKind } from "@/components/providers";
import {
  PlaceBidSequenceModalPlaceBidSuccessBidListingCompanyFragment,
  PlaceBidSequenceModalPlaceBidSuccessBidFragment,
} from "@/gql";
import { useFlowLauncher, useModal } from "@/hooks";
import { useSPVEnabled } from "@/hooks/featureFlags";
import {
  constants,
  getAvailableCompanyActions,
  getSecuritySpecialistContact,
} from "@/utils";

import { usePlaceBidSequenceModalStepFormContext } from "./PlaceBidSequenceModalStepFormContext";

const SecuritySpecialistLink = ({
  company,
}: {
  company: PlaceBidSequenceModalPlaceBidSuccessBidListingCompanyFragment;
}) => {
  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <Text as="span" fontWeight="medium">
      <MailtoLink
        email={securitySpecialistEmail}
        subject={`Contact ${company.name} Security Specialist`}
      >
        {`${company.name} Security Specialist`}
      </MailtoLink>
    </Text>
  );
};

const PlaceBidTranslations = {
  title: `place_bid_success_title`,
  heading: `place_bid_success_heading`,
  body: `place_bid_success_body`,
  contactUs: `place_bid_success_contact_us`,
} as const;

const PlaceBidSPVTranslations = {
  title: `place_bid_success_title`,
  heading: `place_bid_success_heading`,
  body: `place_bid_spv_success_body`,
  contactUs: `place_bid_spv_success_contact_us`,
} as const;

const getPlaceBidSuccessModalTranslationKeys = (
  bid: PlaceBidSequenceModalPlaceBidSuccessBidFragment,
  isSPVEnabled: boolean,
) => {
  if (isSPVEnabled && bid.listing.isHiiveSpv) {
    return PlaceBidSPVTranslations;
  }
  return PlaceBidTranslations;
};

const getContactUsComponent = (
  bid: PlaceBidSequenceModalPlaceBidSuccessBidFragment,
  isSPVEnabled: boolean,
) => {
  if (isSPVEnabled && bid.listing.isHiiveSpv) {
    return <MailtoLink fontWeight="medium" email={constants.email_funds} />;
  }
  return (
    <SecuritySpecialistLink
      key={bid.listing.displayId}
      company={bid.listing.company}
    />
  );
};

const PlaceBidSuccess = () => {
  const { mutationData } = usePlaceBidSequenceModalStepFormContext();
  const {
    closeModal,
    addModalCloseHandler,
    removeModalCloseHandler,
  } = useModal();
  const { t } = useTranslation();

  const { showFlow } = useFlowLauncher();
  const isSPVEnabled = useSPVEnabled();

  const bid = mutationData?.placeBid.bid;
  const isSPV = bid?.listing.isHiiveSpv;

  useEffect(() => {
    if (!bid) return;

    const { canPlaceStandingBid } = getAvailableCompanyActions(
      bid.listing.company,
    );

    addModalCloseHandler(ModalKind.PlaceBid, () => {
      // skip standing bid CTA for SPV
      if (canPlaceStandingBid && !isSPV) {
        showFlow(FlowKind.BuyerPlaceBidSuccessStandingBidCTA);
      }
    });

    return () => {
      removeModalCloseHandler(ModalKind.PlaceBid);
    };
  }, [bid]);

  if (!bid) return null;

  const contactUsComponent = getContactUsComponent(bid, Boolean(isSPVEnabled));
  const translationKeys = getPlaceBidSuccessModalTranslationKeys(
    bid,
    Boolean(isSPVEnabled),
  );

  return (
    <>
      <HiiveModalHeader>{t(translationKeys.title)}</HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="start">
          <Text textStyle="heading-lg">
            {t(translationKeys.heading, {
              companyName: bid.listing.company.name,
              displayId: bid.listing.displayId,
            })}
          </Text>
          <Text>
            <Trans
              i18nKey={translationKeys.body}
              values={{
                companyName: bid.listing.company.name,
                displayId: bid.listing.displayId,
              }}
            />
          </Text>
          <Text>
            <Trans
              i18nKey={translationKeys.contactUs}
              t={t}
              components={[contactUsComponent]}
            />
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          data-testid="place-bid-success-modal"
          sentryLabel="[PlaceBidSuccess/Submit]"
          submitText={t(`okay`)}
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};
export default PlaceBidSuccess;
