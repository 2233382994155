import currency from "currency.js";
import { ReactElement } from "react";

import FormFieldError from "./FormFieldError";
import {
  FormNumberInputControl,
  FormNumberInputField,
  FormNumberInputProps,
} from "./FormNumberInput";

const fromValue = (val: string) => {
  switch (val.toString().lastIndexOf(`.`)) {
    case -1:
      return currency(val, { precision: 0 }).format();
    case val.length - 1:
      return `$${val}`;
    case val.length - 2:
      return currency(val, { precision: 1 }).format();
    default:
      return currency(val, { precision: 2 }).format();
  }
};

const toValue = (val: string) => {
  switch (val.toString().lastIndexOf(`.`)) {
    case val.length - 1:
      return val;
    case val.length - 2:
      return val;
    default:
      return currency(val).value;
  }
};

export type MoneyInputFieldProps = MoneyInputProps;

export const MoneyInputField = ({ step = 1, ...props }: MoneyInputProps) => (
  <FormNumberInputField
    step={step}
    fromValue={fromValue}
    toValue={toValue}
    min={0}
    {...props}
  />
);

export interface MoneyInputProps extends FormNumberInputProps {
  readonly label: string;
  readonly subLabel?: string;
  readonly info?: ReactElement;
  readonly name: string;
  readonly step?: number;
}

const MoneyInput = ({ name, ...props }: MoneyInputProps) => (
  <FormNumberInputControl name={name}>
    <MoneyInputField name={name} {...props} />
    <FormFieldError name={name} />
  </FormNumberInputControl>
);
export default MoneyInput;
