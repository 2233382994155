import { ReactNode } from "react";

import { Stack } from "@chakra-ui/react";

const ActivityCardFooterCountsWrapper = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <Stack
    direction="row"
    spacing={0}
    columnGap={3}
    rowGap={1}
    alignItems="center"
    justifyContent="flex-end"
  >
    {children}
  </Stack>
);
export default ActivityCardFooterCountsWrapper;
