/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FormListbox } from "@/components/form";
import { useValidShareSeriesQuery } from "@/gql";
import { getShareSeriesText } from "@/utils";

const ShareSeriesInput = ({
  name,
  label,
  invalidShareSeriesItems = [],
  isDisabled,
}: {
  readonly name: string;
  readonly label: string;
  readonly invalidShareSeriesItems?: readonly string[];
  readonly isDisabled?: boolean;
}) => {
  const { loading, data } = useValidShareSeriesQuery();

  const filteredItems = !!data?.validShareSeries
    ? data.validShareSeries.filter(
        (shareSeries) => !invalidShareSeriesItems.includes(shareSeries),
      )
    : [];

  const isLoading = loading || !data;

  return (
    <FormListbox
      name={name}
      label={label}
      isDisabled={isDisabled}
      isLoading={isLoading}
      itemToString={(item) => getShareSeriesText(item)}
      getItemKey={(item) => item}
      items={filteredItems}
    />
  );
};

export default ShareSeriesInput;
