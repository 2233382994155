import { Box, SimpleGrid } from "@chakra-ui/react";

import { FullContentWrapper } from "@/components/common";
import { useIsDesktop } from "@/hooks";

import { StandingBidInfoSkeleton } from "./StandingBidInfoSkeleton";

export const StandingBidPageSkeleton = () => {
  const isDesktopView = useIsDesktop();

  return (
    <FullContentWrapper px={{ base: 4, lg: 8 }}>
      <Box w="full" maxW="max-width-md">
        {isDesktopView ? (
          <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
            <StandingBidInfoSkeleton />
          </SimpleGrid>
        ) : (
          <StandingBidInfoSkeleton />
        )}
      </Box>
    </FullContentWrapper>
  );
};
