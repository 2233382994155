import { match } from "ts-pattern";

import { Flex, Text } from "@chakra-ui/react";

import { Tile, TileHeader, SensitiveText } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  ListingPageListingPartialBidDetailsListingFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useMyInstitutionDisplayName } from "@/hooks";
import { formatShares, getIsLister, getIsSellerForListing } from "@/utils";

export const ListingPartialBidDetails = withCurrentActor(
  ({
    listing,
    actor,
  }: {
    readonly listing: ListingPageListingPartialBidDetailsListingFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isSeller = getIsSellerForListing(actor, listing);

    const shouldShowBuyerView = !isSeller;

    const amILister = getIsLister(actor, listing);

    const institutionName = useMyInstitutionDisplayName();

    return (
      <Flex direction="column" gap={3}>
        <Text textStyle="heading-sm">Acceptable Bid Size Range</Text>
        <Text>
          {match({
            shouldShowBuyerView,
            acceptPartialBid: listing.acceptPartialBid,
            amILister,
          })
            .with(
              { shouldShowBuyerView: true, acceptPartialBid: true },
              () =>
                `The seller is open to multiple partial bids for this listing.`,
            )
            .with(
              { shouldShowBuyerView: true, acceptPartialBid: false },
              () =>
                `The seller is not open to partial bids and requires a full lot sale.`,
            )
            .with(
              {
                shouldShowBuyerView: false,
                acceptPartialBid: true,
                amILister: false,
              },
              () =>
                `As the seller, ${institutionName} is open to multiple partial bids for this listing.`,
            )
            .with(
              {
                shouldShowBuyerView: false,
                amILister: false,
                acceptPartialBid: false,
              },
              () =>
                `As the seller, ${institutionName} is not open to partial bids and requires a full lot sale.`,
            )
            .with(
              {
                shouldShowBuyerView: false,
                acceptPartialBid: true,
              },
              () =>
                `As the seller, you are open to multiple partial bids for this listing.`,
            )
            .with(
              {
                shouldShowBuyerView: false,
                acceptPartialBid: false,
              },
              () =>
                `As the seller, you are not open to partial bids and require a full lot sale.`,
            )

            .exhaustive()}
        </Text>
        {listing.acceptPartialBid && !!listing.minPartialBidNumShares && (
          <Tile w={{ base: `full`, md: `50%` }}>
            <TileHeader w="full" maxW={24} whiteSpace="nowrap">
              Minimum Bid
            </TileHeader>
            <SensitiveText>{`${formatShares(
              listing.minPartialBidNumShares,
            )} shares`}</SensitiveText>
          </Tile>
        )}
      </Flex>
    );
  },
);
