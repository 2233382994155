import { useMemo } from "react";

import {
  AutomatedSTNBuyerSteps,
  AutomatedSTNStatesMap,
  AutomatedSTNTransactionState,
} from "@/components/postings";
import { AutomatedStnBuyerStatesBidFragment, TransactionState } from "@/gql";
import { useDocumentSigners } from "@/hooks";

import BidAcceptedStatusItem from "./BidAcceptedStatusItem";
import { TransferStatusItem } from "./TransferStatusItem";

const AutomatedSTNBuyerStates = ({
  state,
  bid,
}: {
  readonly state: AutomatedSTNTransactionState;
  readonly bid: AutomatedStnBuyerStatesBidFragment;
}) => {
  const states: AutomatedSTNStatesMap<AutomatedSTNBuyerSteps> = {
    [TransactionState.BidAccepted]: {
      approvalByIssuer: null,
      issuerApproved: null,
      closed: null,
    },
    [TransactionState.Expired]: {
      approvalByIssuer: null,
      issuerApproved: null,
      closed: null,
    },
    [TransactionState.IssuerPendingApproval]: {
      approvalByIssuer: `PENDING`,
      issuerApproved: null,
      closed: null,
    },
    [TransactionState.IssuerApproved]: {
      approvalByIssuer: `SUCCESS`,
      issuerApproved: `PENDING`,
      closed: null,
    },
    [TransactionState.IssuerApprovalDeclined]: {
      approvalByIssuer: null,
      issuerApproved: null,
      closed: null,
    },
    [TransactionState.ClosedFeePending]: {
      approvalByIssuer: `SUCCESS`,
      issuerApproved: `SUCCESS`,
      closed: `SUCCESS`,
    },
    [TransactionState.ClosedFeePaid]: {
      approvalByIssuer: `SUCCESS`,
      issuerApproved: `SUCCESS`,
      closed: `SUCCESS`,
    },
  };

  const bidState = useDocumentSigners(bid.transaction);

  const [stnSignedSubtitle, issuerApprovalCompletedSubtitle] = useMemo(
    () => [
      bidState.haveBothSigned && states[state].approvalByIssuer === `PENDING`
        ? `Awaiting ${bid.listing.company.name}'s approval`
        : undefined,
      states[state].approvalByIssuer === `SUCCESS` &&
      states[state].issuerApproved === `PENDING`
        ? `Awaiting closing`
        : undefined,
    ],
    [states, state, bidState],
  );

  return (
    <>
      {!!bid.transaction && (
        <BidAcceptedStatusItem transaction={bid.transaction} />
      )}
      <TransferStatusItem
        isCompleted={bidState.haveBothSigned}
        title="Share Transfer Notice signed"
        subtitle={stnSignedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].approvalByIssuer === `SUCCESS`}
        title={`Approved by ${bid.listing.company.name}`}
        subtitle={issuerApprovalCompletedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].closed === `SUCCESS`}
        title="Transaction completed"
      />
    </>
  );
};

export default AutomatedSTNBuyerStates;
