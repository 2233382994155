/* eslint-disable functional/prefer-readonly-type */
// The generated companies query does not allow passing in readonly
// values for industries / investors.
import { NetworkStatus } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import { createRef, useEffect, useState } from "react";
import { match } from "ts-pattern";

import { useRouter } from "next/router";

import { Flex } from "@chakra-ui/react";

import { FullContentWrapper } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyStatus,
  ListCompaniesOrderBy,
  PageInfo,
  useBrowseCompaniesPageListCompaniesQuery,
  UserWithInstitutionFragment,
} from "@/gql";
import { useDebounce } from "@/hooks";

import BrowseCompaniesPageSkeleton from "./BrowseCompaniesPageSkeleton";
import CompaniesList from "./CompaniesList";
import { Header } from "./Header";
import { getFilterValue } from "./util";

export type ValuationRangeUnit = "million" | "billion";

export type FilterTypes = {
  readonly industries: string | string[];
  readonly investors: string | string[];
  readonly countries: string | string[];
  readonly lastRoundSeries: string | string[];
  readonly valuationMin: number | null;
  readonly valuationMinUnit: ValuationRangeUnit;
  readonly valuationMax: number | null;
  readonly valuationMaxUnit: ValuationRangeUnit;
  readonly searchText: string;
  readonly orderBy: ListCompaniesOrderBy;
};

const initialFilterTypesValue: FilterTypes = {
  industries: [],
  investors: [],
  countries: [],
  lastRoundSeries: [],
  valuationMin: null,
  valuationMinUnit: `million`,
  valuationMax: null,
  valuationMaxUnit: `billion`,
  searchText: ``,
  orderBy: ListCompaniesOrderBy.MarketActivity,
};

const getRangeFilterValue = ({
  valuationMin,
  valuationMax,
  valuationMinUnit,
  valuationMaxUnit,
}: FilterTypes) => {
  const unitValues = {
    million: 1_000_000,
    billion: 1_000_000_000,
  };

  return {
    min: valuationMin ? valuationMin * unitValues[valuationMinUnit] : null,
    max: valuationMax ? valuationMax * unitValues[valuationMaxUnit] : null,
  };
};

const BrowseCompaniesPage = ({
  actor: { isSuperadmin },
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const router = useRouter();
  const pageElementRef = createRef<HTMLDivElement>();

  const initialFilters = { ...initialFilterTypesValue, ...router.query };
  const [filters, setFilters] = useState<FilterTypes>(initialFilters);

  const { debounce, isDebouncing: isSearchDebouncing } = useDebounce();

  const {
    data,
    loading: isLoadingCompanies,
    fetchMore: fetchMoreCompanies,
    refetch: refetchCompanies,
    error,
    networkStatus,
  } = useBrowseCompaniesPageListCompaniesQuery({
    variables: {
      first: 20,
      orderBy: filters.orderBy,
      investorIds: getFilterValue(filters.investors),
      industryIds: getFilterValue(filters.industries),
      countryIds: getFilterValue(filters.countries),
      lastRoundPostValuationRange: getRangeFilterValue(filters),
      searchText: filters.searchText,
      statuses: isSuperadmin
        ? [CompanyStatus.Listed, CompanyStatus.Delisted, CompanyStatus.Draft]
        : [CompanyStatus.Listed],
    },
    notifyOnNetworkStatusChange: true,
  });

  const replaceQueryPathname = (_filters: FilterTypes) => {
    const query = queryString.stringify(_filters);
    router.replace({ pathname: router.route, query });
  };

  const onRefetchSuccess = (
    _filters: FilterTypes,
    updateFiltersOnRefetchSuccess: boolean,
  ) => {
    if (!updateFiltersOnRefetchSuccess) return;
    setFilters(_filters);
  };

  const applyFilters = (
    _filters: FilterTypes,
    updateFiltersOnRefetchSuccess = false,
  ) => {
    replaceQueryPathname(_filters);

    const params = {
      first: 20,
      orderBy: _filters.orderBy,
      investorIds: getFilterValue(_filters.investors),
      industryIds: getFilterValue(_filters.industries),
      countryIds: getFilterValue(_filters.countries),
      lastRoundPostValuationRange: getRangeFilterValue(_filters),
      searchText: _filters.searchText,
    };

    refetchCompanies(params).then(() =>
      onRefetchSuccess(_filters, updateFiltersOnRefetchSuccess),
    );
  };

  useEffect(() => {
    const hasFilters = !isEmpty(router.query);
    if (hasFilters) return;
    // Apply initial filters
    applyFilters(filters);
  }, [router.query]);

  const updateFilters = (
    field: keyof FilterTypes,
    value: string | readonly string[] | ListCompaniesOrderBy,
    forceApply?: boolean,
  ) => {
    const nextFilters = { ...filters, [field]: value };
    setFilters(nextFilters);

    if (field === `searchText`) {
      replaceQueryPathname(nextFilters);
      debounce(() => applyFilters(nextFilters));
      return;
    }

    if (forceApply) applyFilters(nextFilters);
  };

  const resetFilters = (
    action: "ALL" | "INDUSTRIES" | "INVESTORS" | "LAST_ROUND_VALUATION",
  ) => {
    const nextFilters = match(action)
      .with(`ALL`, () => initialFilterTypesValue)
      .with(`INDUSTRIES`, () => ({
        ...filters,
        industries: initialFilterTypesValue.industries,
      }))
      .with(`INVESTORS`, () => ({
        ...filters,
        investors: initialFilterTypesValue.investors,
      }))
      .with(`LAST_ROUND_VALUATION`, () => ({
        ...filters,
        valuationMin: initialFilterTypesValue.valuationMin,
        valuationMinUnit: initialFilterTypesValue.valuationMinUnit,
        valuationMax: initialFilterTypesValue.valuationMax,
        valuationMaxUnit: initialFilterTypesValue.valuationMaxUnit,
      }))
      .exhaustive();

    applyFilters(nextFilters, true);
  };

  if (error) return null;

  const companies = data?.listCompanies?.edges?.flatMap((edge) => {
    if (!edge?.node) return [];

    return [edge.node];
  });

  const pageInfo = data?.listCompanies?.pageInfo as PageInfo;

  const isLoadingMoreCompanies = networkStatus === NetworkStatus.fetchMore;

  const isLoading =
    (isLoadingCompanies ||
      isSearchDebouncing ||
      !data?.listCompanies ||
      !pageInfo) &&
    // if we're loading more companies we want to display a different spinner
    !isLoadingMoreCompanies;

  const handleFetchMoreCompanies = () => {
    const params = {
      variables: {
        first: 20,
        orderBy: filters.orderBy,
        investorIds: getFilterValue(filters.investors),
        industryIds: getFilterValue(filters.industries),
        countryIds: getFilterValue(filters.countries),
        lastRoundPostValuationRange: getRangeFilterValue(filters),
        searchText: filters.searchText,
        cursor: pageInfo?.endCursor,
      },
    };

    fetchMoreCompanies(params);
  };

  return (
    <FullContentWrapper ref={pageElementRef} px={{ base: 4, lg: 8 }}>
      <Flex direction="column" gap={6} w="full" maxW="max-width-xl">
        <Header
          filters={filters}
          onFilterChange={updateFilters}
          onApplyFilters={() => applyFilters(filters)}
          onResetFilters={resetFilters}
        />
        {isLoading || !companies ? (
          <BrowseCompaniesPageSkeleton />
        ) : (
          <CompaniesList
            rootElement={pageElementRef.current as HTMLDivElement}
            companies={companies}
            pageInfo={pageInfo}
            onFetchMoreCompanies={handleFetchMoreCompanies}
            isLoadingMore={isLoadingMoreCompanies}
          />
        )}
      </Flex>
    </FullContentWrapper>
  );
};

export default withCurrentActor(BrowseCompaniesPage);
