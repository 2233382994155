import { Box, Center, Text, Image } from "@chakra-ui/react";

export const HoldingCompanyLogo = ({
  logoUrl = null,
  name,
}: {
  readonly logoUrl?: string | null;
  readonly name: string;
}) =>
  logoUrl ? (
    <Box minW={6} position="relative" bottom="1px">
      <Image
        h={{ base: 5, sm: 6 }}
        maxW={6}
        objectFit="contain"
        src={logoUrl}
        alt={name}
      />
    </Box>
  ) : (
    <Center
      bg="grey.100"
      h={6}
      w={6}
      borderRadius="100%"
      position="relative"
      bottom="1px"
    >
      <Text
        color="white"
        textStyle="heading-md"
        translateY="1px"
        transform="auto"
        textTransform="uppercase"
      >
        {name.charAt(0)}
      </Text>
    </Center>
  );
