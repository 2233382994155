import { match } from "ts-pattern";

import { UserPermissionV2, UserWithInstitutionFragment } from "@/gql";
import { useIHavePermission } from "@/hooks";
import { getAvailableInstitutionActions, getIsInstitutionUser } from "@/utils";

const useAccreditationPageStatus = (actor: UserWithInstitutionFragment) => {
  const isInstitutionUser = getIsInstitutionUser(actor);

  const { canUpdateInstitutionAccreditation } = getAvailableInstitutionActions(
    actor.institution,
  );

  const canView = useIHavePermission(UserPermissionV2.CanViewAccreditation);
  const canUpdateIndividualAccreditation = useIHavePermission(
    UserPermissionV2.CanUpdateAccreditation,
  );

  const canUpdate = isInstitutionUser
    ? canUpdateInstitutionAccreditation
    : canUpdateIndividualAccreditation;

  return match({ canView, canUpdate })
    .with({ canView: false }, () => `hidden`)
    .with({ canView: true, canUpdate: false }, () => `view-only`)
    .with({ canView: true, canUpdate: true }, () => `modify`)
    .exhaustive();
};

export default useAccreditationPageStatus;
