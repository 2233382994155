import { ReactNode, createContext, useContext, useMemo } from "react";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
} from "react-hook-form";

import { GridProps, useMultiStyleConfig } from "@chakra-ui/react";

import TileCheckboxInput from "./TileCheckboxInput";

type Size = "sm" | "md" | "lg";

const RadioTileGroupContext = createContext<{
  size: Size | undefined;
}>({ size: undefined });

const RadioTileGroup = ({
  size,
  children,
  display: _display,
  ...gridProps
}: {
  size: Size;
  children: ReactNode;
} & GridProps) => {
  const contextValue = useMemo(() => ({ size }), [size]);
  const styles = useMultiStyleConfig(`RadioTile`, { size });

  return (
    <RadioTileGroupContext.Provider value={contextValue}>
      <TileCheckboxInput.Container sx={styles.group} {...gridProps}>
        {children}
      </TileCheckboxInput.Container>
    </RadioTileGroupContext.Provider>
  );
};

const RadioTile = <TFieldValues extends FieldValues>({
  name,
  control,
  children,
  value,
  ...gridProps
}: {
  readonly children: ReactNode;
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  value: PathValue<TFieldValues, Path<TFieldValues>>;
} & GridProps) => {
  const { field } = useController({
    name,
    control,
  });
  const { size } = useContext(RadioTileGroupContext);
  const styles = useMultiStyleConfig(`RadioTile`, { size });

  return (
    <TileCheckboxInput.Item
      onClick={field.onChange}
      isSelected={field.value === value}
      value={value}
      __css={styles.tile}
      {...gridProps}
    >
      {children}
    </TileCheckboxInput.Item>
  );
};

export default {
  Group: RadioTileGroup,
  Tile: RadioTile,
};
