import { HStack, VStack } from "@chakra-ui/react";

import { RadioCard, RadioCardInput } from "@/components/form";
import { EntityType } from "@/gql";

export const EntityTypeInput = () => (
  <RadioCardInput
    name="entityType"
    tooltipContent=""
    options={[
      { label: `Corporation`, value: EntityType.Corporation },
      { label: `Partnership`, value: EntityType.Partnership },
      { label: `Other`, value: EntityType.Other },
    ]}
    renderOptions={(options, { getRootProps, getRadioProps }) => (
      <VStack>
        <HStack
          {...getRootProps()}
          spacing={5}
          display={{ base: `none`, md: `flex` }}
        >
          {options.map(({ value, label }) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard w="130px" h={20} key={value} {...radio}>
                {label}
              </RadioCard>
            );
          })}
        </HStack>
        <VStack
          display={{ base: `flex`, md: `none` }}
          {...getRootProps()}
          spacing={3}
          w="full"
        >
          {options.map(({ value, label }) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard flex="1" w="full" h={16} key={value} {...radio}>
                {label}
              </RadioCard>
            );
          })}
        </VStack>
      </VStack>
    )}
  />
);
