import { match, P } from "ts-pattern";

import { Text } from "@chakra-ui/react";

import {
  ActivityCardCompanyFragment,
  ActivityCardTransactionFragment,
  ActivityCardBidFragment,
  SigningProcedure,
  TransactionState,
} from "@/gql";
import { useCurrentActor, useHaveISignedDocument } from "@/hooks";
import { getLongDocumentTitleByDocumentType, getIsBrokerForBid } from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";

const ActivityCardTransactionStatus = ({
  bid,
  transaction,
  company,
}: {
  readonly bid: ActivityCardBidFragment;
  readonly transaction: ActivityCardTransactionFragment;
  readonly company: ActivityCardCompanyFragment;
}) => {
  const actor = useCurrentActor();
  const haveISigned = useHaveISignedDocument(transaction.document);
  const isBuyer = getIsBuyerForBid(actor, bid);
  const isBrokerForBid = getIsBrokerForBid({ user: actor, bid });
  const bidType = bid.standingBid ? `Standing bid` : `Bid`;

  const statusText = match({
    state: transaction.state,
    signingProcedure: transaction.signingProcedure,
    haveISigned,
    isBuyer,
    isBrokerForBid,
  })
    .with(
      {
        state: TransactionState.InReview,
      },
      () => `Pending Hiive review`,
    )
    .with(
      {
        state: TransactionState.BidAccepted,
        signingProcedure: SigningProcedure.Manual,
      },
      () => `${bidType} accepted`,
    )
    .with(
      { state: TransactionState.BidAccepted, haveISigned: true },
      () => `Awaiting counterparty signature`,
    )
    .with(
      { state: TransactionState.BidAccepted, isBrokerForBid: true },
      () =>
        `${bidType} accepted${` - Send the ${
          !!transaction.document &&
          getLongDocumentTitleByDocumentType(transaction.document?.type)
        }`} to your customer for signature`,
    )
    .with(
      { state: TransactionState.BidAccepted },
      () =>
        `${bidType} accepted${` - Sign the ${
          !!transaction.document &&
          getLongDocumentTitleByDocumentType(transaction.document?.type)
        }`}`,
    )
    .with(
      { state: TransactionState.IssuerPendingApproval },
      () => `${bidType} accepted: ${company.name} approval pending`,
    )
    .with(
      { state: TransactionState.IssuerApproved, isBuyer: false },
      () => `Fee payment pending`,
    )
    .with(
      { state: TransactionState.IssuerApproved, isBuyer: true },
      () => `${bidType} accepted: ${company.name} has approved`,
    )
    .with(
      { state: TransactionState.ClosedFeePending, isBuyer: false },
      () => `Fee payment pending`,
    )
    .with(
      P.union(
        { state: TransactionState.Pending },
        { state: TransactionState.ClosedFeePending, isBuyer: true },
      ),
      () => `Pending`,
    )
    .with(
      { state: TransactionState.AwaitingClosing },
      (_) => `Awaiting closing`,
    )
    .with({ state: TransactionState.Expired }, (_) => `Expired`)
    .with({ state: TransactionState.Cancelled }, (_) => `Cancelled`)
    .with(
      {
        state: P.union(
          TransactionState.ClosedFeePaid,
          TransactionState.IssuerApprovalDeclined,
        ),
      },
      (_) => `Closed`,
    )
    .exhaustive();

  return (
    <Text textStyle="text-sm" textAlign="left">
      {statusText}
    </Text>
  );
};

export default ActivityCardTransactionStatus;
