/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject } from "react";

export interface ScrollIntoViewOptions {
  readonly offsetTop?: number;
}

const smoothScrollTo = (options?: ScrollToOptions) => {
  window.scrollTo({ behavior: `smooth`, ...options });
};

export const scrollIntoView = (
  ref: MutableRefObject<any>,
  options: ScrollIntoViewOptions = {},
) => {
  if (!ref.current) return;
  const bounds = ref.current.getBoundingClientRect();
  const elementDocumentTop = bounds.top + window.pageYOffset;

  const { offsetTop } = options;

  if (offsetTop) {
    smoothScrollTo({ top: elementDocumentTop + offsetTop });
    return;
  }
  smoothScrollTo({ top: elementDocumentTop });
};
