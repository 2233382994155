import { Show } from "@chakra-ui/react";

import { AskPriceComparisonChartCompanyFragment } from "@/gql";

import { HorizontalAskPriceComparisonChart } from "./HorizontalAskPriceComparisonChart";
import { VerticalAskPriceComparisonChart } from "./VerticalAskPriceComparisonChart";

const AskPriceComparisonChart = ({
  askPriceInDollars,
  company,
}: {
  readonly askPriceInDollars: number;
  readonly company: AskPriceComparisonChartCompanyFragment;
}) => (
  <>
    <Show above="xl" ssr={false}>
      <HorizontalAskPriceComparisonChart
        askPriceInDollars={askPriceInDollars}
        company={company}
      />
    </Show>
    <Show below="xl" ssr={false}>
      <VerticalAskPriceComparisonChart
        askPriceInDollars={askPriceInDollars}
        company={company}
      />
    </Show>
  </>
);

export default AskPriceComparisonChart;
