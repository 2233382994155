import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import { Grid } from "@chakra-ui/react";

import { FormTextAreaInput, FormTextInput } from "@/components/react-hook-form";
import { OnboardingStep } from "@/gql";
import { useCurrentActor } from "@/hooks";

import { BrokerInfoFormValues } from "./BrokerInfoForm";

export const AddressFields = ({
  control,
}: {
  control: Control<BrokerInfoFormValues>;
}) => {
  const { t } = useTranslation();
  const actor = useCurrentActor();

  const finraRegistered = !!actor?.finraRegistered;
  const countryCode = actor.country?.name;

  return match({ countryCode, finraRegistered })
    .with(P.union({ finraRegistered: true }, { countryCode: `US` }), () => (
      <>
        <FormTextAreaInput
          name="firmStreetAddress"
          rows={2}
          label={t(`street_address_of_firm`)}
          control={control}
        />
        <Grid gridTemplateColumns={{ base: `1fr`, md: `2fr 1fr 1fr` }} gap={5}>
          <FormTextInput name="firmCity" label={t(`city`)} control={control} />
          <FormTextInput
            name="firmState"
            label={t(`state`)}
            control={control}
          />
          <FormTextInput
            name="firmPostalCode"
            label={t(`zip_code`)}
            control={control}
          />
        </Grid>
      </>
    ))
    .with({ countryCode: `CA`, finraRegistered: false }, () => (
      <>
        <FormTextAreaInput
          name="firmStreetAddress"
          rows={2}
          label={t(`street_address_of_firm`)}
          control={control}
        />
        <Grid gridTemplateColumns={{ base: `1fr`, md: `2fr 1fr 1fr` }} gap={5}>
          <FormTextInput name="firmCity" label={t(`city`)} control={control} />
          <FormTextInput
            name="firmState"
            label={t(`province`)}
            control={control}
          />
          <FormTextInput
            name="firmPostalCode"
            label={t(`postal_code`)}
            control={control}
          />
        </Grid>
      </>
    ))
    .otherwise(() => {
      throw new Error(
        `Unexpected country ${countryCode} for onboarding step ${OnboardingStep.BrokerInfo}`,
      );
    });
};
