import { HiiveModalContentWrapper } from "@/components/common";
import { CreateListingSequenceModalAuthorization } from "@/components/listings";
import { CreateListingSelectCompanyInputCompanyFragment } from "@/gql";

import { CreateListingSequenceModalContent } from "./CreateListingSequenceModalContent";

interface CreateListingSequenceModalProps {
  readonly initialCompany?: CreateListingSelectCompanyInputCompanyFragment;
}

const CreateListingSequenceModal = ({
  initialCompany,
}: CreateListingSequenceModalProps) => (
  <HiiveModalContentWrapper>
    <CreateListingSequenceModalAuthorization initialCompany={initialCompany}>
      <CreateListingSequenceModalContent initialCompany={initialCompany} />
    </CreateListingSequenceModalAuthorization>
  </HiiveModalContentWrapper>
);

export default CreateListingSequenceModal;
