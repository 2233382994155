import { AnimatePresence, motion } from "framer-motion";

export const SlideAnimation = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => (
  <AnimatePresence mode="wait">
    <motion.div
      initial={{ translateY: -5, opacity: 0, zIndex: 1 }}
      animate={{ translateY: 0, opacity: 1 }}
      transition={{
        type: `spring`,
        stiffness: 500,
        damping: 15,
      }}
      exit={{ translateY: 5, opacity: 0 }}
    >
      {children}
    </motion.div>
  </AnimatePresence>
);
