import { Text, VStack } from "@chakra-ui/react";

import { HiiveButton, InfoCard } from "@/components/common";
import { useRouteToNextOnboardingStep } from "@/hooks";

import { seller_info } from "./constants";
import img_create_listing from "./create_listing.png";
import img_explore_pricing from "./explore_pricing.png";
import img_sell_shares from "./sell_shares.png";

const SellerInfoPage = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  return (
    <VStack
      maxW={{ base: `none`, md: `50rem` }}
      mx={{ base: `auto`, md: `calc((100% - 50rem) / 2)` }}
      spacing={7}
    >
      <Text align="center" color="grey.900" my={7}>
        {seller_info.page.description}
      </Text>
      <InfoCard
        description={seller_info.explore_pricing.description}
        image={img_explore_pricing}
        title={seller_info.explore_pricing.title}
      />
      <InfoCard
        description={seller_info.create_listing.description}
        image={img_create_listing}
        title={seller_info.create_listing.title}
      />
      <InfoCard
        description={seller_info.sell_shares.description}
        image={img_sell_shares}
        title={seller_info.sell_shares.title}
      />
      <HiiveButton
        onClick={() => routeToNextStep()}
        sentryLabel="[SellerInfoPage] Next"
        size="md"
        type="submit"
        variant="rounded-solid-salmon"
      >
        Next
      </HiiveButton>
    </VStack>
  );
};

export default SellerInfoPage;
