import { useTranslation } from "react-i18next";

import { Flex, Text } from "@chakra-ui/react";

import { FocusedShareDetails } from "@/components/common";
import {
  BidInfoCardStatusTile,
  BidInfoCardCounterBidShareDetails,
  SellerCounterBidActionsTile,
  CounterBidFeeBreakdown,
  SellerBidInfoCard,
} from "@/components/postings";
import { CounterBidInfoCardFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { getIsBroker } from "@/utils";

const SellerCounterBidInfoCard = ({
  bid,
}: {
  readonly bid: CounterBidInfoCardFragment;
}) => {
  const actor = useCurrentActor();

  const { t } = useTranslation();
  const isBroker = getIsBroker(actor);
  const placedAt = bid.counteredAt || bid.expireAt;
  const showFeeBreakdown = !isBroker;
  return (
    <Flex direction="column" gap={7}>
      <Flex direction="column" gap={7} data-testid="bid-info-card">
        <Text textStyle="heading-sm">{t`bid_info_seller_counter_bid`}</Text>
        <FocusedShareDetails.Card variant="bid">
          <FocusedShareDetails.Header title={`Bid ${bid.displayId}`}>
            <BidInfoCardCounterBidShareDetails bid={bid} />
          </FocusedShareDetails.Header>
          <FocusedShareDetails.Content>
            <FocusedShareDetails.ContentSection>
              <Flex direction="column" gap={6}>
                <SellerCounterBidActionsTile bid={bid} />
                {placedAt && (
                  <BidInfoCardStatusTile bid={bid} showCounterBidDetails />
                )}
                {showFeeBreakdown && <CounterBidFeeBreakdown bid={bid} />}
              </Flex>
            </FocusedShareDetails.ContentSection>
          </FocusedShareDetails.Content>
        </FocusedShareDetails.Card>
      </Flex>
      <SellerBidInfoCard bid={bid} />
    </Flex>
  );
};

export default SellerCounterBidInfoCard;
