import { Form } from "formik";
import * as Yup from "yup";

import {
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";

import { Card, HiiveButton } from "@/components/common";
import { FormikQL } from "@/components/form";
import {
  AnswerInstitutionAccreditationQuestionsInput,
  CurrentContextDocument,
  OnboardingCurrentActorDocument,
  useAnswerInstitutionAccreditationQuestionsMutation,
  useOnboardingCurrentActorQuery,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

import { UKInstitutionAccreditationFields } from "./UKInstitutionAccreditationFields";

const initialValues = {
  answers: [],
};

const validationSchema = Yup.object().shape({
  answers: Yup.array()
    .of(
      Yup.object({
        key: Yup.string().required(`Must select an answer`),
        text: Yup.string(),
      }),
    )
    .test({
      message: `Must select at least one option`,
      test: (arr) => (arr ? arr.length > 0 : false),
    }),
});

const mapVariables = (
  values: AnswerInstitutionAccreditationQuestionsInput,
): { readonly input: AnswerInstitutionAccreditationQuestionsInput } => ({
  input: {
    ...values,
  },
});

const UKAccreditationConfirm = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useAnswerInstitutionAccreditationQuestionsMutation({
    refetchQueries: [`currentContext`, `onboardingCurrentActor`],
  });

  const onSuccess = () => routeToNextStep();
  const initialValues = {
    answers: [{ key: `uk-institutional-confirm` }],
  };

  const mapVariables = (
    values: AnswerInstitutionAccreditationQuestionsInput,
  ): { readonly input: AnswerInstitutionAccreditationQuestionsInput } => ({
    input: {
      ...values,
    },
  });

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`answerInstitutionAccreditationQuestions`]}
      initialValues={initialValues}
      mapVariables={mapVariables}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <Form>
          <VStack spacing={8}>
            <Text
              mt={7}
              align="center"
              textStyle="deprecated-heading-xl"
              color="h-dark-grey"
            >
              United Kingdom
            </Text>
            <Card>
              <Text fontWeight="medium">
                As a professional client or eligible counterparty participating
                in the Hiive platform, you acknowledge that:
              </Text>
              <UnorderedList>
                <ListItem>
                  The protections of the Financial Services and Markets Act
                  (FSMA) will not apply to any investment activities that the
                  entity may initiate through the Hiive platform.
                </ListItem>
                <ListItem>
                  Hiive does not carry out suitability or appropriateness tests
                  in the provision of services to the entity.
                </ListItem>
                <ListItem>
                  Any transaction that results from the entity’s participation
                  in the Hiive platform would not fall within the jurisdiction
                  of any UK-regulated dispute resolution or compensation scheme.
                </ListItem>
                <ListItem>
                  Hiive does not provide investment advice or make investment
                  recommendations.
                </ListItem>
              </UnorderedList>
            </Card>
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isLoading={isSubmitting}
              sentryLabel="[UKInstitutionAccreditationForm] I Confirm"
            >
              I Confirm
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

export const UKInstitutionAccreditationForm = () => {
  const { data } = useOnboardingCurrentActorQuery();

  const mutation = useAnswerInstitutionAccreditationQuestionsMutation({
    refetchQueries: [CurrentContextDocument, OnboardingCurrentActorDocument],
  });

  const accreditationQuestions = data?.currentActor?.accreditationQuestions;

  if (!accreditationQuestions) return <Spinner />;

  const hasAnsweredZeroAccreditationQuestions =
    accreditationQuestions.length === 0;

  return hasAnsweredZeroAccreditationQuestions ? (
    <FormikQL
      mutation={mutation}
      mutationNames={[`answerInstitutionAccreditationQuestions`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables}
    >
      {({ isSubmitting }) => (
        <Form>
          <VStack spacing={10}>
            <Text
              mt={7}
              align="center"
              textStyle="deprecated-heading-xl"
              color="h-dark-grey"
            >
              United Kingdom
            </Text>
            <UKInstitutionAccreditationFields />
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[UKInstitutionAccreditationForm] Next"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  ) : (
    <UKAccreditationConfirm />
  );
};
