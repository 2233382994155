import { useTranslation } from "react-i18next";

import { FormListbox } from "@/components/form";
import { EntityType } from "@/gql";
import { useTranslatedTransactingEntityNames } from "@/hooks";

type TransactingEntityTypeSelectProps = {
  filterIndividualEntityType?: boolean;
  isInstitution: boolean;
};

const TransactingEntityTypeSelect = ({
  filterIndividualEntityType,
  isInstitution,
}: TransactingEntityTypeSelectProps) => {
  const { t } = useTranslation();

  const { getFormattedEntityName } = useTranslatedTransactingEntityNames();

  const items = isInstitution
    ? [
        EntityType.Corporation,
        EntityType.Partnership,
        EntityType.Llc,
        EntityType.Trust,
        EntityType.Other,
      ]
    : [
        EntityType.Trust,
        EntityType.Corporation,
        EntityType.Llc,
        EntityType.Partnership,
        ...(filterIndividualEntityType ? [] : [EntityType.Individual]),
        EntityType.Other,
      ];

  return (
    <FormListbox<EntityType>
      name="type"
      items={items}
      itemToString={(item) => getFormattedEntityName(item)}
      getItemKey={(item) => item}
      placeholder={t(`select_entity_type`)}
      label={t(`entity_type`)}
    />
  );
};

export default TransactingEntityTypeSelect;
