import { WithQuery } from "@/components/common";
import { useUnaccreditedSellerCompanyByIdActionsQuery } from "@/gql";

import { ActionsSkeleton } from "./ActionsSkeleton";
import { ListForSaleCTACardV2 } from "./ListForSaleCTACardV2";

const ActionsV2 = ({ id }: { id: string }) => {
  const query = useUnaccreditedSellerCompanyByIdActionsQuery({
    variables: { id },
  });

  return (
    <WithQuery query={query} fallback={<ActionsSkeleton />}>
      {({ data: { companyById: company } }) => {
        if (company.activity.myListings.length > 0) {
          return null;
        }

        return <ListForSaleCTACardV2 company={company} />;
      }}
    </WithQuery>
  );
};

export default ActionsV2;
