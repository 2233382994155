import { useEffect, useRef, DependencyList } from "react";

import { useTheme } from "@chakra-ui/react";

import { scrollIntoView, ScrollIntoViewOptions } from "@/utils";

import useIsDesktop from "./useIsDesktop";

const useMobileScrollIntoView = (
  options: ScrollIntoViewOptions,
  dependencies: DependencyList = [],
) => {
  const scrollRef = useRef(null);
  const isDesktopView = useIsDesktop();
  const theme = useTheme();
  const mobileHeaderHeight = parseInt(theme.sizes[`mobile-header-height`], 10);
  const offsetTop = options.offsetTop || -(mobileHeaderHeight + 10);

  useEffect(() => {
    if (!isDesktopView) {
      scrollIntoView(scrollRef, { offsetTop, ...options });
    }
  }, dependencies);

  return { scrollRef };
};

export default useMobileScrollIntoView;
