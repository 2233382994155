import { useRouter } from "next/router";

import { useOnboardingCurrentActorQuery } from "@/gql";
import { canAccessPlatform } from "@/hooks";
import { useFeatureFlags } from "@/hooks/featureFlags";

import { OnboardingRoutes, onboardingSteps } from "./config";
import { getNextIncompleteOnboardingStep } from "./util";

const useResumeOnboarding = () => {
  const router = useRouter();
  const { refetch } = useOnboardingCurrentActorQuery();
  const featureFlags = useFeatureFlags();

  const routeToFirstIncompleteStep = async () => {
    // Ensure actor is refetched
    const {
      data: { currentActor: actor },
    } = await refetch();
    if (!actor) return;

    if (canAccessPlatform(actor)) {
      router.push(`/dashboard`);
      return;
    }

    const initialStep = onboardingSteps[OnboardingRoutes.InvestorStatus];
    const nextStep = getNextIncompleteOnboardingStep(
      initialStep,
      actor,
      featureFlags,
    );

    if (!nextStep) {
      router.push(`/dashboard`);
      return;
    }

    router.push(nextStep.route);
  };

  return { routeToFirstIncompleteStep };
};

export default useResumeOnboarding;
