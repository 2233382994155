import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { ModifyStandingBidMutation } from "@/gql";

import { StepKeys } from "./steps";

export const ModifyStandingBidModalStepFormContext = createStepFormContext<
  StepKeys,
  ModifyStandingBidMutation
>();

export const useModifyStandingBidModalStepFormContext = () =>
  useContext(ModifyStandingBidModalStepFormContext);
