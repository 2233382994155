import dynamic from "next/dynamic";

import { Text, VStack } from "@chakra-ui/react";

import { useRouteToNextOnboardingStep } from "@/hooks";

const IdentityVerificationButton = dynamic(
  () => import(`@/components/persona/IdentityVerificationButton`),
  { ssr: false },
);

const IdentityVerificationPage = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const onFlowComplete = () => routeToNextStep();

  return (
    <>
      <VStack
        my={6}
        spacing={4}
        textStyle="deprecated-text-xl"
        textAlign="center"
      >
        <Text maxW="md">
          We are required by law to verify your identity and perform certain
          diligence including anti-money laundering checks.
        </Text>
        <Text maxW="md">
          This process takes about 2 minutes and you must complete it before you
          can view pricing and other details on the platform, or create your own
          bids, listings and/or messages to other users.
        </Text>
        <Text maxW="md">
          You may also return to this step later, inside the platform.
        </Text>
      </VStack>

      <VStack mt={8} spacing={8}>
        <IdentityVerificationButton onComplete={onFlowComplete} />
        <Text
          textStyle="deprecated-heading-sm"
          color="h-salmon-pink"
          cursor="pointer"
          onClick={() => routeToNextStep()}
        >
          Skip this step
        </Text>
      </VStack>
    </>
  );
};

export default IdentityVerificationPage;
