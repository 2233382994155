import { useField } from "formik";
import { ChangeEvent, ReactNode } from "react";

import {
  FormControl,
  FormErrorMessage,
  HStack,
  useRadioGroup,
} from "@chakra-ui/react";

import { TooltipFormLabel } from ".";
import RadioCard from "./RadioCard";
import { RadioGroup } from "./types";

interface Option {
  readonly value: string;
  readonly label: string;
}

type Size = `md` | `lg`;

/**
 * @deprecated - use <FormCustomRadioInput />
 */
const RadioCardInput = <TOption extends Option>({
  name,
  options,
  label,
  isRequired,
  tooltipContent,
  cardSize = `md`,
  renderOptions,
}: {
  readonly name: string;
  readonly options: readonly TOption[];
  readonly label?: string;
  readonly isRequired?: boolean;
  readonly tooltipContent?: string;
  readonly cardSize?: Size;
  readonly dataTestid?: string;
  readonly renderOptions?: (
    options: readonly TOption[],
    radioGroup: RadioGroup & {
      readonly setValueAsync: (
        event: ChangeEvent<HTMLInputElement>,
      ) => Promise<void>;
    },
  ) => ReactNode;
  readonly textAlign?: `left` | `center` | `right`;
}) => {
  const [field, { touched, error }, { setValue }] = useField(name);

  const radioGroup = useRadioGroup({
    name,
    onChange: setValue,
    value: field.value,
  });

  const setValueAsync = async (event: ChangeEvent<HTMLInputElement>) => {
    await setValue(event.target.value);
  };

  const group = radioGroup.getRootProps();

  if (!!renderOptions) {
    return (
      <FormControl id={name} isInvalid={(error && touched) || false}>
        {renderOptions(options, { ...radioGroup, setValueAsync })}
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  }

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <HStack spacing={5} justify="space-between">
        <TooltipFormLabel
          fontWeight="normal"
          htmlFor={name}
          tooltipContent={tooltipContent}
        >
          {label}
          {isRequired ? ` *` : ``}
        </TooltipFormLabel>
        <HStack spacing={5} {...group}>
          {options.map(({ value, label }) => {
            const radio = radioGroup.getRadioProps({ value });

            switch (cardSize) {
              case `md`:
                return (
                  <RadioCard w={20} h="60px" key={String(value)} {...radio}>
                    {label}
                  </RadioCard>
                );
              case `lg`:
                return (
                  <RadioCard w="130px" h={20} key={String(value)} {...radio}>
                    {label}
                  </RadioCard>
                );
              default:
                return null;
            }
          })}
        </HStack>
      </HStack>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
export default RadioCardInput;
