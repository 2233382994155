import { Form, useField } from "formik";
import { ChangeEvent, useState } from "react";
import * as Yup from "yup";

import {
  HStack,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";

import { Card, CustomCheckbox, HiiveButton } from "@/components/common";
import { FormikQL, QuestionTooltip, RadioCard } from "@/components/form";
import {
  AnswerInstitutionAccreditationQuestionsInput,
  useAnswerInstitutionAccreditationQuestionsMutation,
  useOnboardingCurrentActorQuery,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

const ProfessionalOrInstitutionClientInput = () => {
  const [_field, _, { setValue: setAnswers }] = useField(`answers`);
  const [radioValue, setRadioValue] = useState<string | undefined>();

  const onChangeRadioValue = (value: string) => {
    setRadioValue(value);
    setAnswers([{ key: value }]);
  };

  const radioGroup = useRadioGroup({
    onChange: onChangeRadioValue,
    value: radioValue,
  });

  const group = radioGroup.getRootProps();

  return (
    <HStack spacing={5} {...group}>
      <RadioCard
        w="130px"
        h={20}
        key="ch-institutional-institution"
        {...radioGroup.getRadioProps({
          value: `ch-institutional-institution`,
        })}
        data-testid="ch-institutional-institution"
      >
        Institutional Client
      </RadioCard>
      <RadioCard
        w="130px"
        h={20}
        key="ch-institutional-professional"
        {...radioGroup.getRadioProps({
          value: `ch-institutional-professional`,
        })}
        data-testid="ch-institutional-professional"
      >
        Professional Client
      </RadioCard>
    </HStack>
  );
};

const SwissAccreditationProfessionalOrInstitutionClient = () => {
  const [iAgree, setIAgree] = useState<boolean>(false);

  const mutation = useAnswerInstitutionAccreditationQuestionsMutation({
    refetchQueries: [`currentContext`, `onboardingCurrentActor`],
  });

  const initialValues = {
    answers: [],
  };

  const validationSchema = Yup.object().shape({
    answers: Yup.array().of(
      Yup.object({
        key: Yup.string().required(`Must select an answer`),
        text: Yup.string(),
      }),
    ),
  });

  const mapVariables = (
    values: AnswerInstitutionAccreditationQuestionsInput,
  ): { readonly input: AnswerInstitutionAccreditationQuestionsInput } => ({
    input: {
      ...values,
    },
  });

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`answerInstitutionAccreditationQuestions`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <VStack spacing={9}>
            <Text
              mt={7}
              align="center"
              textStyle="deprecated-heading-xl"
              color="h-dark-grey"
            >
              Switzerland
            </Text>
            <Text textAlign="center">
              In order to participate in Hiive, an institution or entity must
              qualify as a Professional Client
              <QuestionTooltip
                iconSize={16}
                placement="right"
                pr={1}
                tooltipContent="The entity is a Professional Client, for example public entities with professional treasury operations,
                  occupational pension schemes and other occupational institutions
                  providing professional treasury operations, companies with
                  professional treasury operations, large companies (which exceed two
                  of the following thresholds: a balance sheet of CHF 20 million,
                  turnover of CHF 40 million or equity of CHF 2 million) and private
                  investment structures with professional treasury operations
                  established for high net worth individuals"
                transform="auto"
                translateY={0.5}
              />
              or an Institutional Client
              <QuestionTooltip
                iconSize={16}
                placement="right"
                pr={1}
                tooltipContent="The entity is an Institutional Client, for example, banks, fund management companies, authorised asset managers, insurance companies under the Insurance Supervision Act, central banks as well as national and supranational public entities with professional treasury operations"
                transform="auto"
                translateY={0.5}
              />
              under FinSA. Which of the following describes your entity?
            </Text>
            <ProfessionalOrInstitutionClientInput />
            {values.answers.length > 0 && (
              <>
                <Text textAlign="center">
                  If the entity chooses to “opt in” or “opt out” to a different
                  categorization than the one selected above, the entity must
                  immediately notify Hiive of its decision to do so, and the
                  entity may no longer be permitted to access the Hiive
                  platform.
                </Text>
                <CustomCheckbox
                  spacing={4}
                  isChecked={iAgree}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setIAgree(e.target.checked)
                  }
                >
                  I agree
                </CustomCheckbox>
              </>
            )}
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isDisabled={!iAgree || isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[SwitzerlandInstitutionAccreditationForm] Next"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

const SwissAccreditationConfirm = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useAnswerInstitutionAccreditationQuestionsMutation({
    refetchQueries: [`currentContext`, `onboardingCurrentActor`],
  });

  const onSuccess = () => routeToNextStep();

  const initialValues = {
    answers: [{ key: `ch-institutional-confirm` }],
  };

  const mapVariables = (
    values: AnswerInstitutionAccreditationQuestionsInput,
  ): { readonly input: AnswerInstitutionAccreditationQuestionsInput } => ({
    input: {
      ...values,
    },
  });

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`answerInstitutionAccreditationQuestions`]}
      initialValues={initialValues}
      mapVariables={mapVariables}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <Form>
          <VStack spacing={8}>
            <Text
              mt={7}
              align="center"
              textStyle="deprecated-heading-xl"
              color="h-dark-grey"
            >
              Switzerland
            </Text>
            <Card>
              <Text fontWeight="medium">
                As an Institutional Client or a Professional Client, you
                acknowledge that:
              </Text>
              <UnorderedList>
                <ListItem>
                  The disclosure, documentation and accountability obligations
                  under FinSA do not apply to the entity’s relationship with
                  Hiive;
                </ListItem>
                <ListItem>
                  Hiive does not carry out suitability or appropriateness tests
                  in the provision of services to the entity;
                </ListItem>
                <ListItem>
                  Hiive does not provide investment advice or make investment
                  recommendations; and
                </ListItem>
                <ListItem>
                  Hiive is not obliged to apply the transparency and due
                  diligence obligations for client mandates in accordance with
                  FinSA.
                </ListItem>
              </UnorderedList>
            </Card>
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isLoading={isSubmitting}
              sentryLabel="[SwitzerlandInstitutionAccreditationForm] I Confirm"
            >
              I Confirm
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

export const SwitzerlandInstitutionAccreditationForm = () => {
  const { data } = useOnboardingCurrentActorQuery();

  const accreditationQuestions = data?.currentActor?.accreditationQuestions;

  if (!accreditationQuestions) return <Spinner />;

  const hasAnsweredZeroAccreditationQuestions =
    accreditationQuestions.length === 0;

  return hasAnsweredZeroAccreditationQuestions ? (
    <SwissAccreditationProfessionalOrInstitutionClient />
  ) : (
    <SwissAccreditationConfirm />
  );
};
