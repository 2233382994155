import { useApolloClient } from "@apollo/client";
import { Form } from "formik";
import { omit } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import Link from "next/link";
import { useRouter } from "next/router";

import {
  Box,
  GridItem,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import { EMAIL_REGEX, PasswordField, PASSWORD_REGEX } from "@/components/auth";
import {
  CheckboxInput,
  EmailInput,
  FormFooter,
  FormikQL,
  PhoneNumberInput,
  TextInput,
} from "@/components/form";
import { withConfig } from "@/components/hoc";
import {
  Session,
  SignupConfigFragment,
  SignupMutation,
  useSignupMutation,
  SignupAttributionInput,
} from "@/gql";
import {
  OnboardingRoutes,
  useAnalytics,
  useCustomToast,
  useIsHiiveConnect,
} from "@/hooks";
import { RootState } from "@/state";
import { reset as resetAuth, setAuthFromSession } from "@/state/auth";
import {
  constants,
  getUTMCookieData,
  utmCookieDataToSegmentOption,
  validPhoneNumber,
} from "@/utils";
import { normalizePhoneNumber } from "@/utils/format";

const createValidationSchema = (requireSignupKey: boolean) =>
  Yup.object().shape({
    firstName: Yup.string().nullable().required(`First name is required`),
    lastName: Yup.string().nullable().required(`Last name is required`),
    phoneNumber: Yup.string()
      .nullable()
      .required(`Phone number is required`)
      .test(
        `valid phone number`,
        `Please enter a valid phone number`,
        validPhoneNumber,
      ),
    email: Yup.string()
      .nullable()
      .required(`Email is required`)
      .matches(EMAIL_REGEX, `Invalid email address`),
    password: Yup.string()
      .nullable()
      .required(`Password is required`)
      .matches(
        PASSWORD_REGEX,
        `Must contain at least 8 characters, one uppercase, one lowercase, one number or punctuation character`,
      ),
    passwordConfirmation: Yup.string()
      .nullable()
      .required(`Password confirmation is required`)
      .oneOf([Yup.ref(`password`)], `Passwords must match`),
    hasAgreedToPolicy: Yup.boolean().required(`Required`),
    signupKey: requireSignupKey
      ? Yup.string().nullable().required(`Missing signup key`)
      : Yup.string().nullable(),
  });

interface SignUpFormValues {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phoneNumber: string | null;
  readonly password: string;
  readonly passwordConfirmation: string;
  readonly hasAgreedToPolicy: boolean;
  readonly signupKey: string;
  readonly brokerUser: boolean;
}

const initialValues = (brokerUser: boolean): SignUpFormValues => ({
  firstName: ``,
  lastName: ``,
  email: ``,
  phoneNumber: null,
  password: ``,
  passwordConfirmation: ``,
  signupKey: ``,
  hasAgreedToPolicy: false,
  brokerUser,
});

const mapAttribution = (attribution: SignupAttributionInput | null) =>
  attribution &&
  Object.keys(attribution).reduce(
    (acc, key: keyof SignupAttributionInput) => ({
      ...acc,
      [key]: omit(attribution[key], `v`),
    }),
    {},
  );

// Note: The hasAgreedToPolicy field is used only for the UI form validation, and we destructure it here
// so that it won't be sent up in the mutation request with the other fields.
const mapVariables = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { hasAgreedToPolicy, ...values }: SignUpFormValues,
  attribution: SignupAttributionInput | null,
) => ({
  input: {
    signup_attribution: attribution ? mapAttribution(attribution) : undefined,
    ...values,
    ...(values.phoneNumber
      ? {
          phoneNumber: normalizePhoneNumber(values.phoneNumber),
        }
      : {}),
  },
});

const buildPayloadFromSession = (session: Session) => ({
  userId: session.userId,
  token: session.token,
  refreshToken: session.refreshToken,
});

const SignUpForm = withConfig(
  ({ config }: { readonly config: SignupConfigFragment }) => {
    const { successToast } = useCustomToast();
    const mutation = useSignupMutation();
    const dispatch = useDispatch();
    const client = useApolloClient();
    const router = useRouter();
    const analytics = useAnalytics();
    const token = useSelector((state: RootState) => state.auth.token);
    const isHiiveConnect = useIsHiiveConnect();

    const [isSigningIn, setIsSigningIn] = useState(false);
    const attribution = getUTMCookieData();
    useEffect(() => {
      if (!token) return;
      // Clearing Apollo Client cache and redux persist on mount
      dispatch(resetAuth());
      client.clearStore();
    }, []);

    useEffect(() => {
      // Navigate to onboarding if we're signing in and there's a token in the store
      if (token && isSigningIn) router.replace(OnboardingRoutes.InvestorStatus);
    }, [token, isSigningIn]);

    const onSuccess = async (data: SignupMutation) => {
      if (!data.signup?.session) return;

      const option = utmCookieDataToSegmentOption(attribution);

      analytics.identify(data.signup.session.userId, {}, option);

      successToast(`Account created successfully!`);
      setIsSigningIn(true);

      const sessionPayload = buildPayloadFromSession(data.signup.session);
      dispatch(setAuthFromSession(sessionPayload));
    };

    return (
      <FormikQL
        mutation={mutation}
        mutationNames={[`signup`]}
        initialValues={initialValues(isHiiveConnect)}
        validationSchema={createValidationSchema(config.requireSignupKey)}
        mapVariables={(formValues) => mapVariables(formValues, attribution)}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <SimpleGrid columns={2} columnGap={5} rowGap={6} w="full">
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <TextInput
                  isRequired
                  name="firstName"
                  label="Name"
                  placeholder="First"
                  bg="white"
                />
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <Spacer display={{ base: `none`, md: `block` }} h={6} />
                <TextInput name="lastName" placeholder="Last" bg="white" />
              </GridItem>
              <GridItem colSpan={2}>
                <PhoneNumberInput
                  isRequired
                  name="phoneNumber"
                  label="Telephone"
                />
              </GridItem>
              <GridItem colSpan={2}>
                <EmailInput
                  isRequired
                  name="email"
                  label="Email"
                  placeholder="Email address"
                  bg="white"
                />
              </GridItem>
              <GridItem colSpan={2}>
                <VStack spacing={3}>
                  <PasswordField
                    isRequired
                    name="password"
                    label="Password"
                    placeholder="Enter a password"
                    showPolicy
                  />
                  <PasswordField
                    name="passwordConfirmation"
                    placeholder="Confirm password"
                  />
                </VStack>
              </GridItem>
              {config.requireSignupKey && (
                <GridItem colSpan={2}>
                  <PasswordField
                    isRequired
                    name="signupKey"
                    label="Signup Key"
                    placeholder="Enter your signup key"
                  />
                </GridItem>
              )}
            </SimpleGrid>

            <HStack spacing={4} mt={5}>
              <CheckboxInput
                name="hasAgreedToPolicy"
                dataTestId="termsCheckbox"
                label={
                  <Text textStyle="deprecated-text-sm">
                    I agree to Hiive’s{` `}
                    <Link
                      target="_blank"
                      href={`${constants.marketing_website_url}/terms`}
                    >
                      <Box
                        as="span"
                        textStyle="deprecated-text-md"
                        cursor="pointer"
                      >
                        Terms of Use
                      </Box>
                    </Link>
                    {` `}
                    and{` `}
                    <Link
                      target="_blank"
                      href={`${constants.marketing_website_url}/privacy`}
                    >
                      <Box
                        as="span"
                        textStyle="deprecated-text-md"
                        cursor="pointer"
                      >
                        Privacy Policy.
                      </Box>
                    </Link>
                  </Text>
                }
              />
            </HStack>
            <FormFooter
              disableSubmit={!values.hasAgreedToPolicy}
              isSubmitting={isSubmitting}
              submitText="Sign up"
              formName="SignUpForm"
            />
          </Form>
        )}
      </FormikQL>
    );
  },
);

export default SignUpForm;
