/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import { useTranslation, Trans } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  GridItem,
  ModalBody,
  Show,
  SimpleGrid,
  HStack,
  Radio,
  chakra,
  Text,
} from "@chakra-ui/react";

import {
  HiiveBackButton,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CheckboxInput,
  DateInput,
  YesNoRadioInput,
  OtherDetailsInput,
  StepPropsV2,
  FormRadioInput,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { TransferMethodInput } from "@/components/postings";
import { CreateListingMutation, UserWithInstitutionFragment } from "@/gql";
import { useModal, useStepValidator } from "@/hooks";
import { fromBool, toBool } from "@/utils";

import { BrokerSubmitListingSequenceModalFormValues } from ".";
import {
  BrokerSubmitListingSequenceModalStepFormContext,
  useBrokerSubmitListingSequenceModalStepFormContext,
} from "./BrokerSubmitListingSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";

interface AdditionalDetailsProps
  extends StepPropsV2<StepKeys, BrokerSubmitListingSequenceModalFormValues> {
  readonly actor: UserWithInstitutionFragment;
}

export const createValidationSchema = () =>
  Yup.object().shape({
    transferMethod: Yup.string().required(`Required`),
    confirmed: Yup.boolean().oneOf([true], `Required`),
    solicited: Yup.bool().nullable().required(`Required`),
    affiliate: Yup.bool().nullable().required(`Required`),
    hasExpirationDate: Yup.bool().required(`Required`),
    expireAt: Yup.string()
      .nullable()
      .test({
        name: `checkRequired`,
        message: `Required`,
        test: function () {
          if (this.parent.hasExpirationDate) {
            return !!this.parent.expireAt;
          }
          return true;
        },
      }),
    otherDetails: Yup.string().nullable(),
  });

export const AdditionalDetails = withCurrentActor(
  ({ values, stepRouter, isSubmitting }: AdditionalDetailsProps) => {
    const { closeModal } = useModal();
    const { stepControls } = stepRouter;
    const router = useRouter();
    const { t } = useTranslation();

    const { company } = values;

    const {
      submitMutation,
    } = useBrokerSubmitListingSequenceModalStepFormContext();

    const onSuccess = () =>
      submitMutation().then((response: CreateListingMutation) => {
        const newListingId = response.createListing.listing?.id;
        if (!newListingId) return;
        stepControls.nextStep();
        router.push(`/listings/${newListingId}`);
      });

    const validationSchema = createValidationSchema();

    useStepValidator({
      Context: BrokerSubmitListingSequenceModalStepFormContext,
      stepKey: stepKeys.brokerSubmitListingAdditionalDetails,
      validator: {
        validationSchema,
        onSuccess,
      },
      values,
    });

    return (
      <>
        <ModalBody>
          <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
            <GridItem colSpan={2} data-testid="transfer-method-input">
              <TransferMethodInput
                name="transferMethod"
                label={t(`transfer_method_question`)}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <YesNoRadioInput
                name="hasExpirationDate"
                label={t(`end_date_question`)}
                tooltipContent={t(`end_date_question_tooltip`)}
              />
            </GridItem>
            {values.hasExpirationDate && (
              <GridItem colSpan={2}>
                <DateInput
                  name="expireAt"
                  label={t(`end_date_input_label`)}
                  tooltipContent={t(`end_date_input_tooltip`)}
                />
              </GridItem>
            )}
            <GridItem colSpan={2}>
              <OtherDetailsInput
                name="otherDetails"
                label={t(`listing_other_details_input_label`)}
                placeholder={t(`listing_other_details_input_placeholder`)}
                tooltipContent={
                  <Trans
                    i18nKey="broker_submit_listing_additional_notes_tooltip"
                    components={{
                      ul: <chakra.ul pl={5} />,
                      li: <li />,
                    }}
                  />
                }
              />
            </GridItem>
            <GridItem colSpan={2}>
              <FormRadioInput
                label={t(`broker_submit_listing_affiliate_question`, {
                  companyName: company?.name,
                })}
                name="affiliate"
                data-testId="affiliate-question"
                mapper={{ from: fromBool, to: toBool }}
                tooltipContent={
                  <>
                    <Text pb={4}>
                      {t(
                        `broker_submit_listing_affiliate_question_tooltip_part_1`,
                      )}
                    </Text>
                    <Text>
                      {t(
                        `broker_submit_listing_affiliate_question_tooltip_part_2`,
                      )}
                    </Text>
                  </>
                }
              >
                <HStack spacing={12}>
                  <Radio variant="base" value="true">
                    Yes
                  </Radio>
                  <Radio variant="base" value="false">
                    No
                  </Radio>
                </HStack>
              </FormRadioInput>
            </GridItem>
            <GridItem colSpan={2}>
              <FormRadioInput
                label={t(`broker_submit_listing_unsolicited_question`)}
                name="solicited"
                data-testId="solicited-question"
                mapper={{ from: fromBool, to: toBool }}
                tooltipContent={t(
                  `broker_submit_listing_unsolicited_question_tooltip`,
                )}
              >
                <HStack spacing={12}>
                  <Radio variant="base" value="false">
                    Yes
                  </Radio>
                  <Radio variant="base" value="true">
                    No
                  </Radio>
                </HStack>
              </FormRadioInput>
            </GridItem>
            <GridItem colSpan={2}>
              <CheckboxInput
                name="confirmed"
                dataTestId="broker_submit_listing_acknowledgement"
                label={t(`broker_submit_listing_acknowledgement`)}
              />
            </GridItem>
          </SimpleGrid>
        </ModalBody>
        <HiiveModalFooter>
          <Show above="md" ssr={false}>
            <HiiveCancelButton
              sentryLabel="[BrokerSubmitListing/AdditionalDetails/Cancel]"
              onCancel={closeModal}
            />
          </Show>
          <HiiveBackButton
            sentryLabel="[BrokerSubmit/AdditionalDetails/Back]"
            onClick={stepControls.previousStep}
          />
          <HiiveSubmitButton
            sentryLabel="[BrokerSubmit/AdditionalDetails/Submit]"
            isLoading={isSubmitting}
            submitText="Submit Listing"
            type="submit"
          />
        </HiiveModalFooter>
      </>
    );
  },
);
