import { ReactNode } from "react";

import { Flex, TextProps } from "@chakra-ui/react";

interface ActivityCardPricingWrapperProps extends TextProps {
  readonly children: ReactNode;
}

const ActivityCardPricingWrapper = ({
  children,
  ...textProps
}: ActivityCardPricingWrapperProps) => (
  <Flex
    wrap="wrap"
    textStyle="heading-lg"
    columnGap={6}
    rowGap={1}
    {...textProps}
  >
    {children}
  </Flex>
);

export default ActivityCardPricingWrapper;
