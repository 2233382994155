import { HStack } from "@chakra-ui/react";

import { CustomRadio } from "@/components/common";
import { RadioInput } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { getIsInstitutionUser } from "@/utils";

const CaAgreedToInvestmentRiskInput = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const isInstitutionUser = getIsInstitutionUser(actor);

  return (
    <RadioInput name="caAgreedToInvestmentRisk">
      <HStack
        as="span"
        spacing={isInstitutionUser ? 4 : 12}
        my={2}
        w="full"
        justifyContent="center"
      >
        <CustomRadio value="true">
          {isInstitutionUser ? `I agree on behalf of my firm/fund.` : `I agree`}
        </CustomRadio>
        <CustomRadio value="false">I don&apos;t agree</CustomRadio>
      </HStack>
    </RadioInput>
  );
};

export default withCurrentActor(CaAgreedToInvestmentRiskInput);
