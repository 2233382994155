import { useState } from "react";
import { useTranslation } from "react-i18next";

import Link from "next/link";
import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Text,
  VStack,
  HStack,
  Wrap,
} from "@chakra-ui/react";

import { Hiive50Logo } from "@/components/common";
import {
  H50Chart,
  IndexStat,
  Pulse,
  RangeSelector,
} from "@/components/hiive50";
import {
  Hiive50IndexPriceDateRange,
  useHiiveFiftyIndexPricesQuery,
} from "@/gql";
import { formatSimulatedTimestamp } from "@/utils";

import { H50Ticker } from "./H50Ticker";

export const DashboardHiive50Card = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const onClick = () => {
    router.push(`/hiive50`);
  };

  const [range, setRange] = useState(Hiive50IndexPriceDateRange.Max);

  const {
    data: chartResponse,
    loading: chartLoading,
  } = useHiiveFiftyIndexPricesQuery({
    variables: {
      filterBy: {
        range,
      },
    },
  });

  const chartData = chartResponse?.hiiveFiftyIndexPrices?.prices || [];

  const {
    currentPrice,
    percentageChange,
  } = chartResponse?.hiiveFiftyIndexPrices || {
    currentPrice: 0,
    percentageChange: 0,
  };

  const simulatedTimestamp = formatSimulatedTimestamp();

  return (
    <Card w="full">
      <CardHeader>
        <Wrap spacingX={3}>
          <Link href="/hiive50">
            <Hiive50Logo width="133px" />
          </Link>
          <HStack spacing={1}>
            <Pulse top="2px" />
            <Text
              textStyle="heading-3xs"
              color="salmon.900"
              lineHeight="unset"
              position="relative"
              top="3px"
            >
              {t(`updated`)} {simulatedTimestamp}
            </Text>
          </HStack>
        </Wrap>
      </CardHeader>
      <CardBody pr={{ base: 1, md: 2 }}>
        <VStack spacing={5} alignItems="start">
          <Flex
            direction={{ base: `column`, sm: `row` }}
            alignItems={{ base: `start`, sm: `end` }}
            justifyContent="space-between"
            w="full"
            gap={2}
          >
            <IndexStat
              loading={chartLoading}
              currentPrice={currentPrice}
              percentageChange={percentageChange}
              range={range}
            />
            <Box pr={4}>
              <RangeSelector selected={range} onChange={setRange} />
            </Box>
          </Flex>
          <Box h="48" w="full" onClick={onClick} cursor="pointer">
            <H50Chart data={chartData} loading={chartLoading} />
          </Box>
        </VStack>
      </CardBody>
      <CardFooter py={6}>
        <VStack w="full" alignItems="start">
          <Text textStyle="heading-md">{t(`hiive50_members`)}</Text>
          <Flex direction={{ base: `column`, md: `row` }} gap={1}>
            <Text textStyle="text-sm">{t(`hiive50_members_explanation`)}</Text>
            <Link href="/hiive50">
              <Text
                textStyle="text-sm"
                fontWeight="medium"
                textDecoration="underline"
              >
                {t(`view_all_constituents`)}
              </Text>
            </Link>
          </Flex>
          <H50Ticker />
        </VStack>
      </CardFooter>
    </Card>
  );
};
