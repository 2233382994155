import { useField } from "formik";
import { useState } from "react";

import { ListItem, UnorderedList, VStack } from "@chakra-ui/react";

import { CustomRadio } from "@/components/common";
import {
  AccreditationRadioInput,
  CustomRadioTextArea,
} from "@/components/form";
import { QuestionFormCard } from "@/components/onboarding";

export const UKInstitutionAccreditationFields = () => {
  const [_field, _, { setValue: setAnswers }] = useField(`answers`);

  const [radioValue, setRadioValue] = useState<string | undefined>();
  const [otherCriteriaText, setOtherCriteriaText] = useState<string>(``);

  const onChangeRadioValue = (value?: string) => {
    setRadioValue(value);

    if (!value) {
      setAnswers([]);
      return;
    }

    if (value === `uk-institutional-per-se`) {
      setAnswers([{ key: value, text: otherCriteriaText }]);
      return;
    }

    setAnswers([{ key: value }]);
  };

  const onChangeOtherCriteriaText = (value: string) => {
    setOtherCriteriaText(value);

    if (radioValue !== `uk-institutional-per-se`) return;

    setAnswers([{ key: `uk-institutional-per-se`, text: value }]);
  };

  return (
    <QuestionFormCard text="In order to participate in the Hiive platform, an institution or entity located in the UK must qualify as an elective or “per se” professional client or an eligible counterparty. Which of the following describes your entity?">
      <AccreditationRadioInput value={radioValue} onChange={onChangeRadioValue}>
        <VStack alignItems="flex-start">
          <CustomRadio value="uk-institutional-authorised">
            The entity is required to be authorised or regulated to operate in
            the financial markets, such as a credit institution, investment
            firm, financial institution, insurance company, collective
            investment scheme or management company of such a scheme, pension
            fund or management company of a pension fund, or any other
            institutional investor.
          </CustomRadio>
          <CustomRadio value="uk-institutional-mifid">
            The entity is a MiFID or equivalent third country business and meets
            two of the following requirements on a company basis (a) balance
            sheet total of EUR 20,000,000; (b) net turnover of EUR 40,000,000;
            (c) own funds of EUR 2,000,000.
          </CustomRadio>
          <CustomRadio value="uk-institutional-not-mifid">
            The entity is not a MiFID or equivalent third country business, but
            is a large undertaking meeting any one of the following conditions:
            <UnorderedList>
              <ListItem>
                Has called up share capital or net assets of at least £5 million
                or equivalent;
              </ListItem>
              <ListItem>
                Meets at least two of the following criteria: (i) a balance
                sheet total of EUR 12,500,000, (ii) a net turnover of EUR
                25,000,000, (ii) an average number of employees during the year
                of 250;
              </ListItem>
              <ListItem>
                Is a partnership or unincorporated association with net assets
                of at least £5 million or equivalent (in the case of a limited
                partnership this is calculated without deducting loans owing to
                any of the partners);
              </ListItem>
              <ListItem>
                Is a trustee of a trust which has assets of at least £10 million
                or equivalent, calculated by aggregating the value of the cash
                and designated investments forming part of the trust’s assets,
                but before deducting liabilities;
              </ListItem>
              <ListItem>
                Is a trustee of an occupational pension scheme or SSAS, or a
                trustee or operator of a personal pension scheme or stakeholder
                pension scheme where the scheme has (i) at least 50 members; and
                (ii) assets under management of at least £10 million or
                equivalent.
              </ListItem>
            </UnorderedList>
          </CustomRadio>
          <CustomRadioTextArea
            textAreaValue={otherCriteriaText}
            onTextChange={onChangeOtherCriteriaText}
            placeholder="Description"
            textAreaLabel="Please describe"
            selectedRadioValue={radioValue}
            radioValue="uk-institutional-per-se"
          >
            The entity is considered a per se or elective professional client,
            or an eligible counterparty based on a criterion that is not listed
            above.
          </CustomRadioTextArea>
        </VStack>
      </AccreditationRadioInput>
    </QuestionFormCard>
  );
};
