import { SVGProps } from "react";

const Hiive50Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 133 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M125.382 4.38181L128.097 9.01442L130.812 4.38181H132.818L129.159 10.0472L132.818 15.7125H130.812L128.097 11.316L125.382 15.7125H123.376L126.976 10.0472L123.376 4.38181H125.382Z"
      fill="#323232"
    />
    <path
      d="M117.049 15.9486C115.957 15.9486 115.016 15.7077 114.224 15.2257C113.437 14.7388 112.83 14.0602 112.402 13.1897C111.979 12.3143 111.767 11.2963 111.767 10.1357C111.767 8.97512 111.979 7.95221 112.402 7.067C112.83 6.17687 113.425 5.48345 114.187 4.98675C114.954 4.48512 115.849 4.23431 116.872 4.23431C117.462 4.23431 118.045 4.33267 118.62 4.52938C119.196 4.7261 119.719 5.04576 120.192 5.48837C120.664 5.92606 121.04 6.50636 121.32 7.22929C121.601 7.95221 121.741 8.84234 121.741 9.89968V10.6374H113.007V9.1325H119.97C119.97 8.49317 119.842 7.9227 119.587 7.42108C119.336 6.91946 118.977 6.52357 118.51 6.23342C118.047 5.94327 117.502 5.79819 116.872 5.79819C116.179 5.79819 115.579 5.97032 115.072 6.31457C114.571 6.6539 114.184 7.0965 113.914 7.64239C113.643 8.18827 113.508 8.77349 113.508 9.39806V10.4013C113.508 11.257 113.656 11.9824 113.951 12.5774C114.251 13.1676 114.666 13.6176 115.198 13.9274C115.729 14.2323 116.346 14.3848 117.049 14.3848C117.506 14.3848 117.92 14.3208 118.288 14.193C118.662 14.0602 118.984 13.8635 119.255 13.6028C119.525 13.3373 119.734 13.0078 119.882 12.6143L121.564 13.0864C121.387 13.6569 121.089 14.1585 120.671 14.5913C120.253 15.0192 119.737 15.3536 119.122 15.5946C118.507 15.8306 117.816 15.9486 117.049 15.9486Z"
      fill="#323232"
    />
    <path
      d="M103.675 15.9485C102.731 15.9485 101.897 15.71 101.174 15.2329C100.451 14.751 99.8857 14.0723 99.4775 13.1969C99.0693 12.3166 98.8652 11.2765 98.8652 10.0766C98.8652 8.88644 99.0693 7.8537 99.4775 6.97832C99.8857 6.10294 100.454 5.42674 101.182 4.9497C101.909 4.47267 102.75 4.23416 103.704 4.23416C104.442 4.23416 105.025 4.3571 105.453 4.603C105.885 4.84397 106.215 5.11937 106.441 5.42919C106.672 5.7341 106.852 5.98491 106.98 6.18163H107.127V0.604782H108.868V15.7124H107.186V13.9715H106.98C106.852 14.1781 106.67 14.4387 106.434 14.7534C106.198 15.0633 105.861 15.3411 105.423 15.587C104.986 15.828 104.403 15.9485 103.675 15.9485ZM103.911 14.3846C104.609 14.3846 105.199 14.2026 105.681 13.8387C106.163 13.4699 106.53 12.9609 106.781 12.3117C107.031 11.6577 107.157 10.9028 107.157 10.0471C107.157 9.20119 107.034 8.46105 106.788 7.82665C106.542 7.18733 106.178 6.69062 105.696 6.33654C105.214 5.97754 104.619 5.79803 103.911 5.79803C103.173 5.79803 102.559 5.98737 102.067 6.36605C101.58 6.7398 101.214 7.2488 100.968 7.89304C100.727 8.53236 100.606 9.25037 100.606 10.0471C100.606 10.8536 100.729 11.5863 100.975 12.2453C101.226 12.8994 101.595 13.4207 102.082 13.8092C102.573 14.1928 103.183 14.3846 103.911 14.3846Z"
      fill="#323232"
    />
    <path
      d="M88.9858 8.89644V15.7126H87.2449V4.38185H88.9268V6.15228H89.0743C89.3399 5.57689 89.7431 5.11461 90.2841 4.76544C90.8251 4.41136 91.5234 4.23431 92.3791 4.23431C93.1463 4.23431 93.8176 4.39169 94.393 4.70643C94.9684 5.01625 95.4159 5.48837 95.7355 6.12277C96.0552 6.75225 96.215 7.54895 96.215 8.51285V15.7126H94.4741V8.63087C94.4741 7.74074 94.243 7.04733 93.7807 6.55062C93.3184 6.049 92.684 5.79819 91.8775 5.79819C91.3218 5.79819 90.8251 5.91868 90.3874 6.15965C89.9546 6.40063 89.6128 6.75225 89.362 7.21453C89.1112 7.67681 88.9858 8.23745 88.9858 8.89644Z"
      fill="#323232"
    />
    <path
      d="M83.8126 0.604782V15.7124H81.9832V0.604782H83.8126Z"
      fill="#323232"
    />
    <path
      d="M67.7277 16C66.513 16 65.4705 15.6926 64.6 15.0779C63.7345 14.4583 63.0681 13.5657 62.6009 12.4001C62.1386 11.2297 61.9075 9.82072 61.9075 8.17324C61.9124 6.52576 62.146 5.12417 62.6083 3.96848C63.0755 2.80786 63.7418 1.92265 64.6074 1.31284C65.4778 0.703023 66.518 0.398117 67.7277 0.398117C68.9375 0.398117 69.9777 0.703023 70.8481 1.31284C71.7186 1.92265 72.3849 2.80786 72.8472 3.96848C73.3144 5.12909 73.548 6.53068 73.548 8.17324C73.548 9.82564 73.3144 11.2371 72.8472 12.4075C72.3849 13.573 71.7186 14.4632 70.8481 15.0779C69.9826 15.6926 68.9425 16 67.7277 16ZM67.7277 13.6911C68.672 13.6911 69.417 13.2263 69.9629 12.2969C70.5137 11.3625 70.7891 9.98793 70.7891 8.17324C70.7891 6.97328 70.6637 5.96513 70.4129 5.14876C70.1621 4.3324 69.808 3.71767 69.3506 3.30457C68.8933 2.88655 68.3523 2.67754 67.7277 2.67754C66.7884 2.67754 66.0458 3.14474 65.5 4.07913C64.9541 5.0086 64.6787 6.37331 64.6738 8.17324C64.6688 9.37811 64.7893 10.3912 65.0352 11.2125C65.286 12.0338 65.6401 12.6534 66.0975 13.0714C66.5548 13.4845 67.0983 13.6911 67.7277 13.6911Z"
      fill="#F15F61"
    />
    <path
      d="M54.2503 15.919C53.2667 15.919 52.3864 15.7346 51.6094 15.3657C50.8324 14.992 50.2152 14.4805 49.7578 13.8313C49.3054 13.1822 49.0644 12.4396 49.0349 11.6036H51.6906C51.7397 12.2232 52.0078 12.7297 52.4946 13.1232C52.9815 13.5117 53.5667 13.7059 54.2503 13.7059C54.7863 13.7059 55.2634 13.583 55.6814 13.3371C56.0994 13.0912 56.4289 12.7494 56.6699 12.3117C56.9109 11.874 57.0289 11.3749 57.024 10.8142C57.0289 10.2438 56.9084 9.73723 56.6625 9.29463C56.4166 8.85202 56.0797 8.50531 55.6519 8.2545C55.224 7.99877 54.7322 7.87091 54.1765 7.87091C53.7241 7.86599 53.279 7.94959 52.8413 8.12172C52.4036 8.29384 52.0569 8.52006 51.8012 8.80038L49.33 8.39466L50.1193 0.604782H58.8829V2.89158H52.384L51.9487 6.89717H52.0373C52.3176 6.56768 52.7135 6.29474 53.2249 6.07835C53.7364 5.85705 54.297 5.7464 54.9068 5.7464C55.8215 5.7464 56.6379 5.96278 57.3559 6.39555C58.0739 6.82341 58.6395 7.41355 59.0526 8.16598C59.4657 8.91841 59.6722 9.77903 59.6722 10.7479C59.6722 11.7462 59.4411 12.6363 58.9788 13.4182C58.5215 14.1953 57.8846 14.8075 57.0682 15.2551C56.2568 15.6977 55.3175 15.919 54.2503 15.919Z"
      fill="#F15F61"
    />
    <path
      d="M41.8297 15.9413C40.7133 15.9413 39.7519 15.7027 38.9454 15.2257C38.1438 14.7438 37.5241 14.0676 37.0864 13.1971C36.6536 12.3217 36.4373 11.2963 36.4373 10.121C36.4373 8.96037 36.6536 7.93746 37.0864 7.05224C37.5241 6.16703 38.1339 5.47607 38.9159 4.97937C39.7027 4.48266 40.6223 4.23431 41.6748 4.23431C42.3141 4.23431 42.9337 4.34005 43.5337 4.55152C44.1337 4.76298 44.6722 5.09494 45.1492 5.54738C45.6263 5.99982 46.0025 6.58751 46.2779 7.31043C46.5533 8.02844 46.691 8.90136 46.691 9.92919V10.7111H37.6839V9.05873H44.5296C44.5296 8.47842 44.4116 7.9645 44.1755 7.51698C43.9394 7.06454 43.6075 6.70799 43.1796 6.44735C42.7567 6.1867 42.26 6.05638 41.6895 6.05638C41.0699 6.05638 40.5289 6.20883 40.0666 6.51374C39.6093 6.81373 39.2552 7.20716 39.0044 7.69402C38.7585 8.17597 38.6355 8.69972 38.6355 9.26528V10.5562C38.6355 11.3136 38.7683 11.9578 39.0339 12.4889C39.3044 13.0201 39.6806 13.4258 40.1625 13.7061C40.6445 13.9815 41.2076 14.1192 41.8518 14.1192C42.2698 14.1192 42.651 14.0602 42.9952 13.9422C43.3395 13.8192 43.637 13.6372 43.8878 13.3963C44.1386 13.1553 44.3304 12.8578 44.4632 12.5037L46.5508 12.8799C46.3836 13.4946 46.0836 14.0331 45.6509 14.4954C45.223 14.9528 44.6845 15.3093 44.0353 15.565C43.3911 15.8159 42.6559 15.9413 41.8297 15.9413Z"
      fill="#19434F"
    />
    <path
      d="M35.279 4.38181L31.1702 15.7125H28.8096L24.6934 4.38181H27.0613L29.9309 13.1012H30.0489L32.9111 4.38181H35.279Z"
      fill="#19434F"
    />
    <path
      d="M20.4134 15.7125V4.38181H22.619V15.7125H20.4134ZM21.5273 2.63351C21.1437 2.63351 20.8142 2.50565 20.5388 2.24992C20.2683 1.98927 20.1331 1.67945 20.1331 1.32044C20.1331 0.956522 20.2683 0.646697 20.5388 0.390969C20.8142 0.130323 21.1437 0 21.5273 0C21.9109 0 22.2379 0.130323 22.5084 0.390969C22.7838 0.646697 22.9215 0.956522 22.9215 1.32044C22.9215 1.67945 22.7838 1.98927 22.5084 2.24992C22.2379 2.50565 21.9109 2.63351 21.5273 2.63351Z"
      fill="#19434F"
    />
    <path
      d="M15.2405 15.7125V4.38181H17.4462V15.7125H15.2405ZM16.3544 2.63351C15.9708 2.63351 15.6413 2.50565 15.3659 2.24992C15.0954 1.98927 14.9602 1.67945 14.9602 1.32044C14.9602 0.956522 15.0954 0.646697 15.3659 0.390969C15.6413 0.130323 15.9708 0 16.3544 0C16.738 0 17.065 0.130323 17.3355 0.390969C17.6109 0.646697 17.7486 0.956522 17.7486 1.32044C17.7486 1.67945 17.6109 1.98927 17.3355 2.24992C17.065 2.50565 16.738 2.63351 16.3544 2.63351Z"
      fill="#19434F"
    />
    <path
      d="M0 15.7124V0.604782H2.27942V7.17011H9.81112V0.604782H12.0979V15.7124H9.81112V9.12496H2.27942V15.7124H0Z"
      fill="#19434F"
    />
  </svg>
);

export default Hiive50Logo;
