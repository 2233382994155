import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { CreateListingMutation } from "@/gql";

import { StepKeys } from "./steps";

export const CreateListingSequenceModalStepFormContext = createStepFormContext<
  StepKeys,
  CreateListingMutation
>();

export const useCreateListingSequenceModalStepFormContext = () =>
  useContext(CreateListingSequenceModalStepFormContext);
