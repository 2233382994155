import { ReactNode } from "react";

import {
  Box,
  Center,
  GridItem,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/react";

import CheckmarkSvg from "./CheckmarkSvg";
import CheckmarkSvgLight from "./CheckmarkSvgLight";

const BorderWrapper = ({ children }: { readonly children: ReactNode }) => (
  <Box w="full" borderRadius={10} borderColor="grey.100" borderWidth={1} p={5}>
    {children}
  </Box>
);

export const RoleDefinitionTable = () => (
  <BorderWrapper>
    <SimpleGrid columns={6} columnGap={6} rowGap={4} w="full">
      <GridItem colSpan={3} />
      <GridItem colSpan={1}>
        <Center>
          <Text textStyle="heading-xs">View Only</Text>
        </Center>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <Text textStyle="heading-xs">Trader</Text>
        </Center>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <Text textStyle="heading-xs">Admin</Text>
        </Center>
      </GridItem>
    </SimpleGrid>
    <Spacer display="block" h={5} />
    <SimpleGrid columns={6} columnGap={6} rowGap={4} w="full">
      <GridItem colSpan={3}>
        <Text textStyle="text-sm">View listings, bids and standing bids</Text>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvg />
        </Center>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvg />
        </Center>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvg />
        </Center>
      </GridItem>
    </SimpleGrid>
    <Spacer display="block" h={5} />
    <SimpleGrid columns={6} columnGap={6} rowGap={4} w="full">
      <GridItem colSpan={3}>
        <Text textStyle="text-sm">View the transactions of other users</Text>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvg />
        </Center>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvg />
        </Center>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvg />
        </Center>
      </GridItem>
    </SimpleGrid>
    <Spacer display="block" h={5} />
    <SimpleGrid columns={6} columnGap={6} rowGap={4} w="full">
      <GridItem colSpan={3}>
        <Text textStyle="text-sm">Place bids, listings and standing bids</Text>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvgLight />
        </Center>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvg />
        </Center>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvg />
        </Center>
      </GridItem>
    </SimpleGrid>
    <Spacer display="block" h={5} />
    <SimpleGrid columns={6} columnGap={6} rowGap={4} w="full">
      <GridItem colSpan={3}>
        <Text textStyle="text-sm">Invite, deactivate and edit user roles</Text>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvgLight />
        </Center>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvgLight />
        </Center>
      </GridItem>
      <GridItem colSpan={1}>
        <Center>
          <CheckmarkSvg />
        </Center>
      </GridItem>
    </SimpleGrid>
  </BorderWrapper>
);
