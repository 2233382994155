import { t } from "i18next";

import { ShareDetailsDisclaimer } from "@/components/common";
import { ListingSellerRoundedSharesTooltip } from "@/components/tooltip";

const ListingSellerRoundingDisclaimer = () => (
  <ShareDetailsDisclaimer tooltip={<ListingSellerRoundedSharesTooltip />}>
    {t(`listing_viewer_rounding_disclaimer`)}
  </ShareDetailsDisclaimer>
);

export default ListingSellerRoundingDisclaimer;
