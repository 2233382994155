/* eslint-disable functional/immutable-data */

/* eslint-disable @typescript-eslint/ban-types */
import isNil from "lodash/isNil";
import { useRef, useState } from "react";

import { constants } from "@/utils";

const useDebounce = () => {
  const timeoutIdRef = useRef<number | null>(null);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);

  const clearDebounce = () => {
    if (isNil(timeoutIdRef.current)) return;
    window.clearTimeout(timeoutIdRef.current);
  };

  const debounce = (
    fn: Function,
    timeout: number = constants.search_debounce_time,
  ) => {
    setIsDebouncing(true);
    clearDebounce();

    timeoutIdRef.current = window.setTimeout(() => {
      setIsDebouncing(false);
      fn();
    }, timeout);
  };

  return { debounce, isDebouncing, clearDebounce };
};

export default useDebounce;
