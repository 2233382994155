import { useField } from "formik";
import { useState } from "react";

import { AccreditationTextAreaInput } from "@/components/form";

export const OtherInstitutionAccreditationFields = () => {
  const [_field, _, { setValue: setAnswers }] = useField(`answers`);

  const [textValue, setTextValue] = useState<string | undefined>();

  const onChangeTextValue = (value?: string) => {
    setTextValue(value);
    const answers = !!value
      ? [
          {
            key: `other-institutional-explain-qualification`,
            text: value.trim(),
          },
        ]
      : [];
    setAnswers(answers);
  };

  return (
    <AccreditationTextAreaInput
      label="Please explain below how your firm/fund is qualified and permitted by law to participate in the purchase and sale of private unregistered securities through Hiive:"
      maxLength={400}
      placeholder="Explanation"
      value={textValue}
      onChange={onChangeTextValue}
    />
  );
};
