import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { AcceptBidSuccessModalBidListingCompanyFragment } from "@/gql";
import { useModal } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getBidNumSharesActual,
  getSecuritySpecialistContact,
  getShortDocumentTitleByTransferMethod,
} from "@/utils";

import { useAcceptCounterBidSequenceModalStepFormContext } from "./AcceptCounterBidSequenceModalStepFormContext";

const SecuritySpecialistLink = ({
  company,
}: {
  readonly company: AcceptBidSuccessModalBidListingCompanyFragment;
}) => {
  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <Text as="span" fontWeight="medium">
      <MailtoLink
        email={securitySpecialistEmail}
        subject={`Contact ${company.name} Security Specialist`}
      >
        {`${company.name} Security Specialist`}
      </MailtoLink>
    </Text>
  );
};

const AcceptCounterBidSuccessModal = () => {
  const { mutationData } = useAcceptCounterBidSequenceModalStepFormContext();

  const { closeModal } = useModal();

  const bid = mutationData?.acceptCounteredBid.bid;
  const listing = bid?.listing;

  if (!bid || !listing) return null;

  const numShares = formatShares(getBidNumSharesActual(bid));
  const pricePerShare = formatPricePerShare(bid.pricePerShare);
  const documentTitle = getShortDocumentTitleByTransferMethod(
    bid.listing.transferMethod,
  );
  return (
    <>
      <HiiveModalHeader>Countered Bid accepted!</HiiveModalHeader>
      <ModalBody>
        <Text textStyle="heading-xl" mb={4}>
          {`You accepted ${listing.company.name} Bid ${bid.shortId} for ${numShares} shares at ${pricePerShare}/sh!`}
        </Text>
        <VStack mb={4} spacing={6} alignItems="start">
          <Text>
            The next step is for you and the seller to execute the {` `}
            {documentTitle}
            {` `}for this transaction. You may message the lister directly with
            any questions or comments.
          </Text>
          <Text>
            You may also reach out anytime to the{` `}
            <SecuritySpecialistLink company={listing.company} />
            {` `}
            for assistance on your transaction.
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          sentryLabel="[AcceptCounterBidSuccess/Submit]"
          submitText="Okay"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default AcceptCounterBidSuccessModal;
