import { match } from "ts-pattern";

import {
  StandingBidAcceptsTransferMethodsOptions,
  TransferMethod,
  UnaccreditedSellerTransferMethodInput,
} from "@/gql";

/**
 * Converts StandingBidAcceptsTransferMethodsOptions to proper TransferMethod
 */
export const getTransferMethodByStandingBidAcceptsTransferMethodsOptions = (
  transferMethod: StandingBidAcceptsTransferMethodsOptions,
) =>
  match(transferMethod)
    .with(
      StandingBidAcceptsTransferMethodsOptions.Direct,
      () => TransferMethod.Direct,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptions.ForwardContract,
      () => TransferMethod.ForwardContract,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptions.SpvLister,
      () => TransferMethod.SpvLister,
    )
    .with(
      StandingBidAcceptsTransferMethodsOptions.SpvThirdparty,
      () => TransferMethod.SpvThirdparty,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transferMethod in getTransferMethodByStandingBidAcceptsTransferMethodsOptions: ${transferMethod}`,
      );
    });

/**
 * Converts UnaccreditedSellerTransferMethodInput to proper TransferMethod
 */
export const getTransferMethodByUnaccreditedSellerTransferMethodInput = (
  transferMethod: UnaccreditedSellerTransferMethodInput,
) =>
  match(transferMethod)
    .with(
      UnaccreditedSellerTransferMethodInput.Direct,
      () => TransferMethod.Direct,
    )
    .with(
      UnaccreditedSellerTransferMethodInput.ForwardContract,
      () => TransferMethod.ForwardContract,
    )
    .with(
      UnaccreditedSellerTransferMethodInput.Unknown,
      () => TransferMethod.Unknown,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transferMethod in getTransferMethodByUnaccreditedSellerTransferMethodInput: ${transferMethod}`,
      );
    });
