import { useMemo } from "react";

import { Text, TextProps } from "@chakra-ui/react";

import { obscureText } from "@/utils";

interface ObscureTextProps extends TextProps {
  readonly children: string;
  readonly obscure?: boolean;
  readonly dataTestId?: string;
}

const ObscureText = ({
  children,
  obscure = true,
  dataTestId,
  ...textProps
}: ObscureTextProps) => {
  const text = useMemo(() => obscureText(children), [children]);

  return obscure ? (
    <Text
      layerStyle="sensitive"
      wordBreak="break-word"
      {...textProps}
      data-testid={dataTestId}
    >
      {text}
    </Text>
  ) : (
    <Text {...textProps} data-testid={dataTestId}>
      {children}
    </Text>
  );
};

export default ObscureText;
