import { Chat } from "@phosphor-icons/react";

import { HStack, Text } from "@chakra-ui/react";

const MessageNotificationCount = ({ count }: { readonly count: number }) => (
  <HStack px={2} h="5" bg="salmon.900" rounded="full" justifyContent="center">
    <Text color="white" textStyle="heading-3xs">
      {count}
    </Text>
    <Chat size={14} color="white" weight="fill" />
  </HStack>
);

export default MessageNotificationCount;
