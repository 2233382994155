import { Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveButton,
  HiiveModalFooter,
  HiiveModalHeader,
} from "@/components/common";
import { FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  AddEntityInput,
  AddEntityMutationVariables,
  CurrentContextDocument,
  TransactionPageTransactionByIdDocument,
  UserWithInstitutionFragment,
  useAddEntityMutation,
  EntityType,
} from "@/gql";
import { useCustomToast } from "@/hooks";

import { TransactingEntityFormFields } from "./TransactingEntityFormFields";

export enum CreateTransactingEntityModalTypes {
  Buyer,
  Seller,
}

export type CreateTransactingEntityModalProps = Omit<ModalProps, "children"> & {
  actor: UserWithInstitutionFragment;
  transactionId?: string;
  type?: CreateTransactingEntityModalTypes;
};

const mapVariables = (input: AddEntityInput): AddEntityMutationVariables => ({
  input,
});

export const TRANSACTING_ENTITY_DEFAULT_VALUES = {
  jurisdictionOfFormationId: ``,
  legalName: ``,
  otherType: undefined,
  type: `` as EntityType,
};

const CreateTransactingEntityModal = withCurrentActor(
  ({
    actor,
    transactionId,
    type,
    ...props
  }: CreateTransactingEntityModalProps) => {
    const { t } = useTranslation();
    const { onClose } = props;
    const mutation = useAddEntityMutation({
      refetchQueries: [
        CurrentContextDocument,
        TransactionPageTransactionByIdDocument,
      ],
    });
    const { successToast } = useCustomToast();
    const onSuccess = () => {
      onClose();
      successToast(t(`entity_created_successfully`));
    };
    const validationSchema = Yup.object().shape({
      jurisdictionOfFormationId: Yup.string().required(
        t(`jurisdiction_of_formation_required`),
      ),
      legalName: Yup.string().required(t(`legal_entity_name_required`)),
      otherType: Yup.string().when(`type`, {
        is: (type: EntityType) => type === EntityType.Other,
        then: (schema) => schema.required(t(`entity_type_name_is_required`)),
      }),
      type: Yup.string().nullable().required(t(`entity_type_is_required`)),
    });
    const isInstitution = !!actor.institutionId;
    const entities = isInstitution
      ? actor.institution?.entities
      : actor?.entities;
    const individualEntities = entities?.filter(
      (entity) => entity.type === EntityType.Individual,
    );
    const hasAnIndividualEntity =
      individualEntities && individualEntities?.length > 0;

    const getDefaultValues = () => {
      if (isInstitution) {
        return {
          ...TRANSACTING_ENTITY_DEFAULT_VALUES,
          transactionId,
        };
      }

      return hasAnIndividualEntity
        ? {
            ...TRANSACTING_ENTITY_DEFAULT_VALUES,
            transactionId,
          }
        : {
            ...TRANSACTING_ENTITY_DEFAULT_VALUES,
            jurisdictionOfFormationId: actor.country?.id ?? ``,
            legalName: actor.name,
            transactionId,
            type: EntityType.Individual,
          };
    };

    return (
      <Modal {...props} size="xl">
        <ModalOverlay />
        <FormikQL
          initialValues={getDefaultValues()}
          mapVariables={mapVariables}
          mutation={mutation}
          mutationNames={[]}
          onSuccess={onSuccess}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalContent>
                <HiiveModalHeader closeModal={onClose}>
                  <Text>{t(`new_entity`)}</Text>
                </HiiveModalHeader>
                <ModalBody>
                  <TransactingEntityFormFields
                    filterIndividualEntityType={hasAnIndividualEntity}
                    type={type}
                    transactionId={transactionId}
                  />
                </ModalBody>
                <HiiveModalFooter>
                  <ButtonFooterWrapper
                    direction={{
                      base: `row`,
                    }}
                  >
                    <HiiveButton
                      onClick={onClose}
                      size="xl"
                      sentryLabel="[CreateTransactingEntities] Cancel"
                      variant="rounded-outline-grey"
                    >
                      {t(`cancel`)}
                    </HiiveButton>
                    <HiiveButton
                      type="submit"
                      size="xl"
                      sentryLabel="[CreateTransactingEntities] Create Entity"
                      variant="rounded-solid-salmon"
                      isLoading={isSubmitting}
                    >
                      {t(`create_entity`)}
                    </HiiveButton>
                  </ButtonFooterWrapper>
                </HiiveModalFooter>
              </ModalContent>
            </Form>
          )}
        </FormikQL>
      </Modal>
    );
  },
);

export default CreateTransactingEntityModal;
