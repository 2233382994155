import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { GridItem, Link, SimpleGrid } from "@chakra-ui/react";

import { FormField } from "@/components/form";
import { TransactionSizeError } from "@/components/listings";
import { constants, sumShareSeriesMakeup } from "@/utils";

import { BrokerSubmitListingSequenceModalFormValues } from "./types";

export const CreateListingLotFields = () => {
  const {
    values: { shareSeriesMakeup },
    errors,
    touched,
  } = useFormikContext<BrokerSubmitListingSequenceModalFormValues>();

  const [_numSharesField, { error: numSharesFieldError }] = useField(
    `shareSeriesMakeup[0].numShares`,
  );
  const [_pricePerShareField, { error: pricePerShareFieldError }] = useField(
    `pricePerShare`,
  );

  const { t } = useTranslation();
  const minTransactionSizeError = t(`min_transaction_size_error`, {
    minSize: constants.min_listing_size.text,
  });

  const showTransactionSizeError =
    !!touched.pricePerShare &&
    Object.values(errors).some((error) => error === minTransactionSizeError);

  const numShares = sumShareSeriesMakeup(shareSeriesMakeup);

  return (
    <SimpleGrid columns={2} rowGap={{ base: 7, md: 2 }} columnGap={9}>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <FormField.Control name="shareSeriesMakeup[0].numShares">
          <FormField.NumberInput
            controlledValue={numShares}
            label="Number of shares"
            isDisabled={!!shareSeriesMakeup && shareSeriesMakeup.length > 1}
          />
          {numSharesFieldError !== minTransactionSizeError && (
            <FormField.Error />
          )}
        </FormField.Control>
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <FormField.Control name="pricePerShare">
          <FormField.MoneyInput
            label="Price per share"
            info={
              <Link
                href="/terms-and-conditions#fees-and-commissions"
                target="_blank"
                fontSize="sm"
                color="h-salmon-pink"
              >
                Fees
              </Link>
            }
          />
          {pricePerShareFieldError !== minTransactionSizeError && (
            <FormField.Error />
          )}
        </FormField.Control>
      </GridItem>
      {showTransactionSizeError && (
        <TransactionSizeError error={minTransactionSizeError} />
      )}
    </SimpleGrid>
  );
};
