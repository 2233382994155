import { t } from "i18next";

import { useRouter } from "next/router";

import { Box, SimpleGrid, VStack } from "@chakra-ui/react";

import {
  FeeDiscountAlert,
  FullContentWrapper,
  MobileTab,
  MobileTabList,
  MobileTabPanel,
  MobileTabPanels,
  MobileTabs,
  WithQuery,
} from "@/components/common";
import { useFeeDiscountAlert } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  InvestorType,
  UnaccreditedSellerCompanyPageCompanyFragment,
  UserWithInstitutionFragment,
  useUnaccreditedSellerCompanyPageQuery,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import { hasInvestorType } from "@/utils";

import { Actions } from "./Actions";
import { Info } from "./Info";
import { MarketActivity } from "./MarketActivity";
import { Metadata } from "./Metadata";
import { YourActivity } from "./YourActivity";

const DesktopUnaccreditedSellerCompanyPage = ({
  company,
}: {
  readonly company: UnaccreditedSellerCompanyPageCompanyFragment;
}) => {
  const { feeDiscount, feeDiscountAlertType } = useFeeDiscountAlert({
    company,
  });

  return (
    <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
      <Info />
      <VStack alignItems="flex-start" spacing={8}>
        {feeDiscountAlertType && (
          <FeeDiscountAlert
            companyName={company.name}
            feeDiscount={feeDiscount}
            type={feeDiscountAlertType}
          />
        )}
        <Actions />
        <YourActivity />
        <MarketActivity />
      </VStack>
    </SimpleGrid>
  );
};

const MobileUnaccreditedSellerCompanyPage = ({
  company,
}: {
  readonly company: UnaccreditedSellerCompanyPageCompanyFragment;
}) => {
  const { feeDiscount, feeDiscountAlertType } = useFeeDiscountAlert({
    company,
  });

  return (
    <>
      {feeDiscountAlertType && !!company.feeDiscountApplications?.length && (
        <Box mb={4}>
          <FeeDiscountAlert
            companyName={company.name}
            feeDiscount={feeDiscount}
            type={feeDiscountAlertType}
          />
        </Box>
      )}
      <MobileTabs>
        <MobileTabList>
          <MobileTab>{t(`overview`)}</MobileTab>
          <MobileTab>{t(`your_activity`)}</MobileTab>
          <MobileTab>{t(`market_activity`)}</MobileTab>
        </MobileTabList>
        <MobileTabPanels>
          <MobileTabPanel>
            <Info />
          </MobileTabPanel>
          <MobileTabPanel>
            <VStack spacing={8}>
              <Actions />
              <YourActivity />
            </VStack>
          </MobileTabPanel>
          <MobileTabPanel>
            <MarketActivity />
          </MobileTabPanel>
        </MobileTabPanels>
      </MobileTabs>
    </>
  );
};

const UnaccreditedSellerCompanyPage = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const query = useUnaccreditedSellerCompanyPageQuery();

    const isDesktopView = useIsDesktop();
    const router = useRouter();

    const isTrader = hasInvestorType(actor, InvestorType.Trader);
    const isSeller = hasInvestorType(actor, InvestorType.Seller);
    const isBroker = hasInvestorType(actor, InvestorType.Broker);

    if (isTrader || isSeller || isBroker) {
      router.replace(`/page-not-found`);
      return null;
    }

    return (
      <WithQuery query={query}>
        {({
          data: {
            unaccreditedSellerMyActivity: { myCompany },
          },
        }) => (
          <>
            <Metadata />
            <FullContentWrapper px={{ base: 4, lg: 8 }}>
              <Box w="full" maxW="max-width-md">
                {isDesktopView ? (
                  <DesktopUnaccreditedSellerCompanyPage company={myCompany} />
                ) : (
                  <MobileUnaccreditedSellerCompanyPage company={myCompany} />
                )}
              </Box>
            </FullContentWrapper>
          </>
        )}
      </WithQuery>
    );
  },
);

export default UnaccreditedSellerCompanyPage;
