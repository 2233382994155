import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";

import { TrustedContactPersonForm } from "./TrustedContactPersonForm";
import { TrustedContactPersonInfo } from "./TrustedContactPersonInfo";

export const TrustedContactPersonCard = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const showForm = actor.trustedContactPerson === null;
    return showForm ? (
      <TrustedContactPersonForm />
    ) : (
      <TrustedContactPersonInfo />
    );
  },
);
