import {
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  WithQuery,
} from "@/components/common";
import { CompanyYourActivityListingCard } from "@/components/companies";
import {
  UnaccreditedSellerCompanyPageYourActivityCompanyFragment,
  UnaccreditedSellerCompanyPageYourActivityListingFragment,
  useUnaccreditedSellerCompanyPageYourActivityQuery,
} from "@/gql";
import { useIsDesktop } from "@/hooks";

import { YourActivitySkeleton } from "./YourActivitySkeleton";

const MyListing = ({
  listing,
  company,
}: {
  readonly listing: UnaccreditedSellerCompanyPageYourActivityListingFragment;
  readonly company: UnaccreditedSellerCompanyPageYourActivityCompanyFragment;
}) => (
  <ActivityGroup title="Your Listing">
    {[listing].map((listing) => (
      <CompanyYourActivityListingCard
        key={listing.id}
        listing={listing}
        company={company}
        dataTestId="your-listing-card"
      />
    ))}
  </ActivityGroup>
);

const YourActivity = () => {
  const query = useUnaccreditedSellerCompanyPageYourActivityQuery();
  const isDesktopView = useIsDesktop();

  return (
    <WithQuery query={query} fallback={<YourActivitySkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myListing, myCompany },
        },
      }) => {
        const showEmptyState = !isDesktopView && !myListing;

        return (
          <>
            {!!myListing && (
              <ActivitySection title="Your Activity">
                <MyListing listing={myListing} company={myCompany} />
              </ActivitySection>
            )}
            {showEmptyState && (
              <ActivitySection title="Your Activity">
                <ActivityGroupEmptyState
                  w="full"
                  message={`You currently have no activity for ${myCompany.name}.`}
                />
              </ActivitySection>
            )}
          </>
        );
      }}
    </WithQuery>
  );
};

export default YourActivity;
