import {
  defineStyleConfig,
  Textarea as TextareaComponent,
} from "@chakra-ui/react";

export const Textarea = defineStyleConfig({
  defaultProps: {
    ...TextareaComponent.defaultProps,
    variant: `base`,
  },
  variants: {
    base: {
      h: `32`,
      bg: `white`,
      borderColor: `grey.600`,
      borderWidth: 1,
      borderRadius: `md`,
      _disabled: {
        pointerEvents: `none`,
        borderColor: `grey.200`,
        _placeholder: {
          color: `grey.200`,
        },
      },
      _hover: {
        borderColor: `grey.200`,
      },
      _placeholder: {
        color: `grey.600`,
      },
      _focus: {
        boxShadow: `focus`,
        borderColor: `grey.600`,
      },
      _invalid: {
        borderColor: `red.500`,
        borderWidth: 2,
      },
    },
    grey: {
      bg: `grey.50`,
    },
  },
});
