import { InformativeArticles } from "@/components/dashboard";
import { constants } from "@/utils";

const marketingWebsiteLinks = [
  constants.marketing_website_links.calculate_price_of_shares,
  constants.marketing_website_links.dive_into_vc_secondaries,
  constants.marketing_website_links.how_to_sell_private_shares,
];

export const InformativeArticlesCard = () => (
  <InformativeArticles.Card>
    {marketingWebsiteLinks.map(({ title, url }) => (
      <InformativeArticles.Link key={url} href={url}>
        {title}
      </InformativeArticles.Link>
    ))}
  </InformativeArticles.Card>
);
