import { CompanyPageContentCompanyFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { getIsBroker } from "@/utils";

export enum FeeDiscountAlertType {
  User = `User`,
  Company = `Company`,
}

const useFeeDiscountAlert = ({
  company,
}: {
  company: CompanyPageContentCompanyFragment;
}) => {
  const actor = useCurrentActor();
  const isBroker = getIsBroker(actor);

  const [companyFeeDiscountApplication] = company.feeDiscountApplications || [];
  const [userFeeDiscountApplication] = (
    actor.feeDiscountApplications || []
  ).filter((item) => item.company?.id === company.id);

  // NOTE: As it stands, the user fee discount always takes priority
  const feeDiscountApplication =
    userFeeDiscountApplication || companyFeeDiscountApplication;

  const feeDiscount = feeDiscountApplication?.feeDiscount;
  const showFeeDiscountAlert = !!feeDiscountApplication && !isBroker;

  if (showFeeDiscountAlert && userFeeDiscountApplication)
    return {
      feeDiscount,
      feeDiscountAlertType: FeeDiscountAlertType.User,
    };

  if (showFeeDiscountAlert && companyFeeDiscountApplication)
    return {
      feeDiscount,
      feeDiscountAlertType: FeeDiscountAlertType.Company,
    };

  return {
    feeDiscount,
    feeDiscountAlertType: null,
  };
};

export default useFeeDiscountAlert;
