import { match } from "ts-pattern";

import {
  ManualSTNBuyerSteps,
  ManualSTNStatesMap,
  ManualSTNTransactionState,
} from "@/components/postings";
import { TransactionState, ManualStnBuyerStatesBidFragment } from "@/gql";
import { getTransactionCounterpartyName } from "@/utils";

import { TransferStatusItem } from "./TransferStatusItem";

const ManualSTNBuyerStates = ({
  state,
  bid,
}: {
  readonly state: ManualSTNTransactionState;
  readonly bid: ManualStnBuyerStatesBidFragment;
}) => {
  const states: ManualSTNStatesMap<ManualSTNBuyerSteps> = {
    [TransactionState.BidAccepted]: {
      bidAccepted: `PENDING`,
      approvalByIssuer: null,
      issuerApproved: null,
      closed: null,
    },
    [TransactionState.IssuerPendingApproval]: {
      bidAccepted: `SUCCESS`,
      approvalByIssuer: `PENDING`,
      issuerApproved: null,
      closed: null,
    },
    [TransactionState.IssuerApprovalDeclined]: {
      bidAccepted: `SUCCESS`,
      approvalByIssuer: null,
      issuerApproved: null,
      closed: null,
    },
    [TransactionState.IssuerApproved]: {
      bidAccepted: `SUCCESS`,
      approvalByIssuer: `SUCCESS`,
      issuerApproved: `PENDING`,
      closed: null,
    },
    [TransactionState.ClosedFeePending]: {
      bidAccepted: `SUCCESS`,
      approvalByIssuer: `SUCCESS`,
      issuerApproved: `SUCCESS`,
      closed: `SUCCESS`,
    },
    [TransactionState.ClosedFeePaid]: {
      bidAccepted: `SUCCESS`,
      approvalByIssuer: `SUCCESS`,
      issuerApproved: `SUCCESS`,
      closed: `SUCCESS`,
    },
  };

  const counterpartyName = bid.transaction
    ? getTransactionCounterpartyName(bid.transaction)
    : null;

  const bidAcceptedSubtitle = match(states[state].bidAccepted)
    .with(`PENDING`, () => `Awaiting signed Share Transfer Notice`)
    .with(`SUCCESS`, () => `Counter-party signer: ${counterpartyName}`)
    .otherwise(() => undefined);

  const stnSignedSubtitle =
    states[state].bidAccepted === `SUCCESS` &&
    states[state].approvalByIssuer === `PENDING`
      ? `Awaiting ${bid.listing.company.name} approval`
      : undefined;

  const awaitingClosingSubtitle =
    states[state].approvalByIssuer === `SUCCESS` &&
    states[state].issuerApproved === `PENDING`
      ? `Awaiting closing`
      : undefined;

  return (
    <>
      <TransferStatusItem
        isCompleted
        title="Bid accepted"
        subtitle={bidAcceptedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].bidAccepted === `SUCCESS`}
        title="Share Transfer Notice signed"
        subtitle={stnSignedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].approvalByIssuer === `SUCCESS`}
        title={`Approved by ${bid.listing.company.name}`}
        subtitle={awaitingClosingSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].closed === `SUCCESS`}
        title="Transaction completed"
      />
    </>
  );
};

export default ManualSTNBuyerStates;
