import { ReactNode } from "react";

import { Flex, FlexProps } from "@chakra-ui/react";

interface InfoRowWrapperProps extends FlexProps {
  readonly children: ReactNode;
}

const InfoRowWrapper = ({ children, ...flexProps }: InfoRowWrapperProps) => (
  <Flex
    bg="white"
    borderBottom="light-grey-border"
    align={{ base: `flex-start`, lg: `center` }}
    pt={{ base: 4, sm: 5 }}
    pb={{ base: 2.5, sm: 4 }}
    minH={{ base: `unset`, sm: 14 }}
    direction={{ base: `column`, lg: `row` }}
    pl={{ base: 2.5, lg: 4, xl: 0 }}
    pr={{ base: 2.5, lg: 4 }}
    {...flexProps}
  >
    {children}
  </Flex>
);

export default InfoRowWrapper;
