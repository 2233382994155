import pluralize from "pluralize";

import { VStack, Flex, Badge, Text } from "@chakra-ui/react";

import { BrowseCompaniesPageCompanyActivityCompanyFragment } from "@/gql";
import {
  formatLastRoundValue,
  formatLastRoundSeries,
  formatLastRoundSize,
} from "@/utils";

import PropertyRow from "./PropertyRow";

const CompanyActivity = ({
  company,
}: {
  readonly company: BrowseCompaniesPageCompanyActivityCompanyFragment;
}) => {
  const {
    numOpenListings,
    numOpenStandingBidsAndActiveBids,
  } = company.activity;

  const {
    lastRoundPostValuation,
    lastRoundPricePerShare,
    lastRoundDate,
    lastRoundSeries,
    lastRoundRaised,
    totalRaised,
  } = company;

  const hasActivity =
    numOpenListings > 0 || numOpenStandingBidsAndActiveBids > 0;

  return (
    <VStack spacing={4}>
      <PropertyRow isSensitive title="Last Round Value">
        {formatLastRoundValue({
          lastRoundPostValuation,
          lastRoundPricePerShare,
        })}
      </PropertyRow>
      <PropertyRow isSensitive title="Last Round Series">
        {formatLastRoundSeries({ lastRoundSeries, lastRoundDate })}
      </PropertyRow>
      <PropertyRow isSensitive title="Last Round Size">
        {formatLastRoundSize({ lastRoundRaised, totalRaised })}
      </PropertyRow>
      <PropertyRow title="Market Activity">
        {hasActivity ? (
          <Flex gap={2} alignItems="center" flexWrap="wrap">
            {numOpenListings > 0 && (
              <Badge variant="grey" py={2} px={3}>
                <Text textStyle="text-xs" fontWeight="normal">
                  <Text as="strong" fontSize="3xs" mr={2}>
                    {numOpenListings}
                  </Text>
                  {` `}
                  {pluralize(`Listing`, numOpenListings)}
                </Text>
              </Badge>
            )}
            {numOpenStandingBidsAndActiveBids > 0 && (
              <Badge variant="grey" py={2} px={3}>
                <Text textStyle="text-xs" fontWeight="normal">
                  <Text as="strong" fontSize="3xs" mr={2}>
                    {numOpenStandingBidsAndActiveBids}
                  </Text>
                  {` `}
                  {pluralize(`Bid`, numOpenStandingBidsAndActiveBids)}
                </Text>
              </Badge>
            )}
          </Flex>
        ) : (
          `None`
        )}
      </PropertyRow>
    </VStack>
  );
};

export default CompanyActivity;
