import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  ManualLOISellerSteps,
  ManualLOIStatesMap,
  ManualLOITransactionState,
} from "@/components/postings";
import { TransactionState, ManualLoiSellerStatesBidFragment } from "@/gql";
import { getTransactionCounterpartyName } from "@/utils";

import { TransferStatusItem } from "./TransferStatusItem";

const ManualLOISellerStates = ({
  state,
  bid,
  isBroker,
}: {
  readonly state: ManualLOITransactionState;
  readonly bid: ManualLoiSellerStatesBidFragment;
  readonly isBroker?: boolean;
}) => {
  const { t } = useTranslation();

  const states: ManualLOIStatesMap<ManualLOISellerSteps> = {
    [TransactionState.BidAccepted]: {
      bidAccepted: `PENDING`,
      awaitingClosing: null,
      feePayment: null,
      closed: null,
    },
    [TransactionState.AwaitingClosing]: {
      bidAccepted: `SUCCESS`,
      awaitingClosing: `PENDING`,
      feePayment: null,
      closed: null,
    },
    [TransactionState.ClosedFeePending]: {
      bidAccepted: `SUCCESS`,
      awaitingClosing: `SUCCESS`,
      feePayment: `PENDING`,
      closed: null,
    },
    [TransactionState.ClosedFeePaid]: {
      bidAccepted: `SUCCESS`,
      awaitingClosing: `SUCCESS`,
      feePayment: `SUCCESS`,
      closed: `SUCCESS`,
    },
  };

  const counterpartyName = bid.transaction
    ? getTransactionCounterpartyName(bid.transaction)
    : null;

  const bidAcceptedSubtitle = match(states[state].bidAccepted)
    .with(`PENDING`, () => `Awaiting signed letter of intent`)
    .with(`SUCCESS`, () => `Counter-party signer: ${counterpartyName}`)
    .otherwise(() => undefined);

  const loiSignedSubtitle =
    states[state].bidAccepted === `SUCCESS` &&
    states[state].awaitingClosing === `PENDING`
      ? `Awaiting closing`
      : undefined;

  const transactionFeeSubtitle =
    states[state].awaitingClosing === `SUCCESS` &&
    states[state].feePayment === `PENDING`
      ? t(`awaiting_closing_fee`)
      : undefined;

  return (
    <>
      <TransferStatusItem
        isCompleted
        title="Bid accepted"
        subtitle={bidAcceptedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].bidAccepted === `SUCCESS`}
        title="Letter of Intent signed"
        subtitle={loiSignedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].awaitingClosing === `SUCCESS`}
        title="Transaction completed"
        subtitle={transactionFeeSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].closed === `SUCCESS`}
        title={t(isBroker ? `hiive_co_broker_fee_payed` : `fee_payed`)}
      />
    </>
  );
};

export default ManualLOISellerStates;
