import { useTranslation } from "react-i18next";

import { TextInput } from "@/components/form";

const TransactingEntityTypeInput = () => {
  const { t } = useTranslation();

  return <TextInput name="otherType" label={t(`provide_entity_type`)} />;
};

export default TransactingEntityTypeInput;
