import {
  GetAvailableStandingBidActionsStandingBidFragment,
  StandingBidPermission,
  StandingBidState,
} from "@/gql";
import { iHaveEntityPermission } from "@/hooks";

const getIsStandingBidOpen = (
  standingBid: GetAvailableStandingBidActionsStandingBidFragment,
) => standingBid.state === StandingBidState.Open;

const getCanWithdrawStandingBid = (
  standingBid: GetAvailableStandingBidActionsStandingBidFragment,
) =>
  getIsStandingBidOpen(standingBid) &&
  iHaveEntityPermission(standingBid, StandingBidPermission.WithdrawStandingBid);

const getCanModifyStandingBid = (
  standingBid: GetAvailableStandingBidActionsStandingBidFragment,
) =>
  getIsStandingBidOpen(standingBid) &&
  iHaveEntityPermission(standingBid, StandingBidPermission.ModifyStandingBid);

const getCanAcceptStandingBid = (
  standingBid: GetAvailableStandingBidActionsStandingBidFragment,
) =>
  iHaveEntityPermission(standingBid, StandingBidPermission.AcceptStandingBid);

const getCanRequestDiscussion = (
  standingBid: GetAvailableStandingBidActionsStandingBidFragment,
) => iHaveEntityPermission(standingBid, StandingBidPermission.StartDiscussion);

export const getAvailableStandingBidActions = (
  standingBid: GetAvailableStandingBidActionsStandingBidFragment,
) => {
  const canWithdrawStandingBid = getCanWithdrawStandingBid(standingBid);
  const canModifyStandingBid = getCanModifyStandingBid(standingBid);
  const canAcceptStandingBid = getCanAcceptStandingBid(standingBid);
  const canRequestDiscussion = getCanRequestDiscussion(standingBid);
  const canActOnStandingBid = canModifyStandingBid || canWithdrawStandingBid;

  return {
    canWithdrawStandingBid,
    canModifyStandingBid,
    canAcceptStandingBid,
    canRequestDiscussion,
    canActOnStandingBid,
  };
};
