import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  `container`,
  `header`,
  `logo`,
  `logoHover`,
  `item`,
]);

export const ShareDetailsHeader = defineMultiStyleConfig({
  baseStyle: {
    container: {
      w: `full`,
      px: 5,
      borderBottomWidth: `1px`,
      boxShadow: `0px 2px 10px 0px rgba(0, 0, 0, 0.05)`,
      borderColor: `grey.1000`,
      borderWidth: `0.5px`,
      borderTopRadius: `md`,
      borderBottomRadius: `30px`,
      pb: 2,
      zIndex: 1,
    },
    header: {
      textStyle: `heading-3xl`,
      textColor: `grey.1000`,
      py: 5,
      pb: 4,
    },
    logo: {
      minH: 12,
      background: `white`,
      borderRadius: `md`,
      borderColor: `grey.200`,
      borderWidth: `1px`,
      boxShadow: `0px 4px 10px 0px rgba(0, 0, 0, 0.05)`,
      transition: `box-shadow 0.3s ease-in-out`,
      display: `flex`,
      alignItems: `center`,
      _hover: {
        boxShadow: `0px 4px 15px 0px rgba(0, 0, 0, 0.1)`,
      },
    },
    item: {
      borderRadius: `md`,
      borderWidth: `0.5px`,
      boxShadow: `0px 1px 10px 0px rgba(0, 0, 0, 0.05)`,
      background: `white`,
      py: 3,
      mb: 4,
    },
  },
  variants: {
    base: {
      item: {
        borderColor: `grey.200`,
      },
    },
    listing: {
      container: {
        borderColor: `olive.200`,
        bg: `olive.25`,
      },
      header: {
        textColor: `olive.1000`,
      },
      item: {
        borderColor: `olive.400`,
      },
    },
    "listing-preview": {
      container: {
        borderColor: `olive.200`,
        bg: `olive.25`,
        boxShadow: `none`,
      },
      item: {
        borderColor: `olive.400`,
      },
      header: {
        textColor: `olive.1000`,
        textStyle: `heading-md`,
      },
    },
    bid: {
      container: {
        borderColor: `plum.200`,
        bg: `plum.25`,
      },
      header: {
        textColor: `plum.1000`,
      },
      item: {
        borderColor: `plum.400`,
      },
    },
    transaction: {
      container: {
        borderColor: `teal.200`,
        bg: `teal.25`,
      },
      header: {
        textColor: `teal.1000`,
      },
      item: {
        borderColor: `teal.400`,
      },
    },
    discussion: {
      container: {
        borderColor: `grey.200`,
        bg: `grey.25`,
      },
      header: {
        textColor: `grey.1000`,
      },
      item: {
        borderColor: `grey.400`,
      },
    },
  },
  defaultProps: {
    variant: `listing`,
  },
});
