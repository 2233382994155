import { Card, CardBody, SimpleGrid, VStack } from "@chakra-ui/react";

import { ShareDetailsHeaderSkeleton, Skeleton } from "@/components/common";

export const ListingInfoCardSkeleton = ({
  shouldShowActivity,
}: {
  readonly shouldShowActivity?: boolean;
}) => (
  <Card variant="darkened-footer">
    <ShareDetailsHeaderSkeleton />
    <CardBody>
      <VStack gap={8} spacing={0} alignItems="left">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
          <Skeleton h="58px" w="full" count={3} />
        </SimpleGrid>
        <VStack alignItems="left" gap={3}>
          <Skeleton h="18px" w="25%" />;
          <Skeleton h="14px" />
        </VStack>
      </VStack>
    </CardBody>
    {shouldShowActivity && (
      <CardBody>
        <VStack gap={8} spacing={0} alignItems="left">
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
            <Skeleton h="58px" w="full" count={3} />
          </SimpleGrid>
          <VStack alignItems="left" gap={3} spacing={0}>
            <Skeleton h="18px" w="25%" />;
            <Skeleton h="14px" />
            <Skeleton h="14px" />
            <Skeleton h="14px" />
          </VStack>
        </VStack>
      </CardBody>
    )}
  </Card>
);
