import { Card, CardBody, CardHeader, HStack, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

export const DashboardMostActiveCompaniesSkeleton = () => (
  <Card w="full">
    <CardHeader>
      <Skeleton h={4} maxW="50%" />
    </CardHeader>
    <CardBody>
      <VStack spacing={4}>
        <Skeleton h="88px" count={5} />
        <HStack py={1} w="full" justifyContent="center">
          <Skeleton h="26px" maxW="65%" />
        </HStack>
      </VStack>
    </CardBody>
  </Card>
);
