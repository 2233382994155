import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { FlowKind, ModalKind } from "@/components/providers";
import {
  BidState,
  PlaceBidSuccessModalBidFragment,
  PlaceBidSuccessModalBidListingCompanyFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useFlowLauncher, useModal } from "@/hooks";
import { useSPVEnabled } from "@/hooks/featureFlags";
import {
  constants,
  getAvailableCompanyActions,
  getIsBroker,
  getSecuritySpecialistContact,
} from "@/utils";

const SecuritySpecialistLink = ({
  company,
}: {
  readonly company: PlaceBidSuccessModalBidListingCompanyFragment;
}) => {
  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <Text as="span" fontWeight="medium">
      <MailtoLink
        email={securitySpecialistEmail}
        subject={`Contact ${company.name} Security Specialist`}
      >
        {`${company.name} Security Specialist`}
      </MailtoLink>
    </Text>
  );
};

const SubmitBidTranslations = {
  title: `submit_bid_success_in_review_title`,
  heading: `submit_bid_success_in_review_heading`,
  body: `submit_bid_success_in_review_body`,
  contactUs: `submit_bid_success_in_review_contact_us`,
} as const;

const PlaceBidTranslations = {
  title: `place_bid_success_title`,
  heading: `place_bid_success_heading`,
  body: `place_bid_success_body`,
  contactUs: `place_bid_success_contact_us`,
} as const;

const PlaceBidSPVTranslations = {
  title: `place_bid_success_title`,
  heading: `place_bid_success_heading`,
  body: `place_bid_spv_success_body`,
  contactUs: `place_bid_spv_success_contact_us`,
} as const;

const getPlaceBidSuccessModalTranslationKeys = (
  bid: PlaceBidSuccessModalBidFragment,
  isSPVEnabled: boolean,
) =>
  match({ state: bid.state, isSPVEnabled, isSPV: bid.listing.isHiiveSpv })
    .with({ isSPVEnabled: true, isSPV: true }, () => PlaceBidSPVTranslations)
    .with({ state: BidState.InReview }, () => SubmitBidTranslations)
    .otherwise(() => PlaceBidTranslations);

const getContactUsComponent = (
  bid: PlaceBidSuccessModalBidFragment,
  isSPVEnabled: boolean,
) =>
  match({ state: bid.state, isSPVEnabled, isSPV: bid.listing.isHiiveSpv })
    .with({ isSPVEnabled: true, isSPV: true }, () => (
      <MailtoLink fontWeight="medium" email={constants.email_funds} />
    ))
    .otherwise(() => (
      <SecuritySpecialistLink
        key={bid.listing.displayId}
        company={bid.listing.company}
      />
    ));

const PlaceBidSuccessModal = ({
  bid,
  actor,
}: {
  readonly bid: PlaceBidSuccessModalBidFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const {
    closeModal,
    addModalCloseHandler,
    removeModalCloseHandler,
  } = useModal();
  const { t } = useTranslation();
  const { showFlow } = useFlowLauncher();
  const isSPVEnabled = useSPVEnabled();

  const translationKeys = getPlaceBidSuccessModalTranslationKeys(
    bid,
    Boolean(isSPVEnabled),
  );

  const contactUsComponent = getContactUsComponent(bid, Boolean(isSPVEnabled));

  const isBroker = getIsBroker(actor);

  const { canPlaceStandingBid } = getAvailableCompanyActions(
    bid.listing.company,
  );

  const isSPV = bid.listing.isHiiveSpv;

  useEffect(() => {
    addModalCloseHandler(ModalKind.PlaceBidSuccess, () => {
      // skip standing bid CTA for SPV
      if (canPlaceStandingBid && !isSPV) {
        showFlow(
          isBroker
            ? FlowKind.BrokerSubmitBidSuccessStandingBidCTA
            : FlowKind.BuyerPlaceBidSuccessStandingBidCTA,
        );
      }
    });

    return () => {
      removeModalCloseHandler(ModalKind.PlaceBidSuccess);
    };
  }, [isBroker]);

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>{t(translationKeys.title)}</HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="start">
          <Text textStyle="heading-lg">
            {t(translationKeys.heading, {
              companyName: bid.listing.company.name,
              displayId: bid.listing.displayId,
            })}
          </Text>
          <Text>
            <Trans
              i18nKey={translationKeys.body}
              values={{
                companyName: bid.listing.company.name,
                displayId: bid.listing.displayId,
              }}
            />
          </Text>
          <Text>
            <Trans
              i18nKey={translationKeys.contactUs}
              t={t}
              components={[contactUsComponent]}
            />
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          sentryLabel="[PlaceBidSuccess/Submit]"
          submitText={t(`okay`)}
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default withCurrentActor(PlaceBidSuccessModal);
