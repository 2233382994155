import { Form } from "formik";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  SimpleGrid,
  GridItem,
  Text,
  VStack,
  ModalBody,
  Show,
} from "@chakra-ui/react";

import {
  HiiveAdvancedOptionsDropdown,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormikQL, CheckboxInput } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  Company,
  WithdrawListingModalListingFragment,
  useWithdrawListingMutation,
  MarketActivityDocument,
  ListingPageListingByIdDocument,
  BidPageMyActivityDocument,
  UserActivityMyActivityDocument,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import { makeUrl } from "@/utils";

interface WithdrawListingModalProps {
  readonly listing: WithdrawListingModalListingFragment;
  readonly actor: UserWithInstitutionFragment;
}

interface WithdrawListingModalFormValues {
  readonly listingId: string;
  readonly muteNotifications: boolean;
}

const createInitialValues = (listing: WithdrawListingModalListingFragment) => ({
  listingId: listing.id,
  muteNotifications: false,
});

const validationSchema = Yup.object().shape({
  listingId: Yup.string().nullable().required(`Required`),
  muteNotifications: Yup.boolean().nullable(),
});

const mapVariables = ({
  listingId,
  ...values
}: WithdrawListingModalFormValues) => ({
  listingId,
  input: {
    ...values,
  },
});

const WithdrawListingModal = withCurrentActor(
  ({ listing, actor }: WithdrawListingModalProps) => {
    const mutation = useWithdrawListingMutation({
      refetchQueries: [
        ListingPageListingByIdDocument,
        BidPageMyActivityDocument,
        UserActivityMyActivityDocument,
        MarketActivityDocument,
      ],
    });

    const router = useRouter();

    const { closeModal } = useModal();

    const { successToast } = useCustomToast();

    const onSuccess = () => {
      closeModal();
      successToast(`Listing withdrawn.`);

      const company = {
        __typename: `Company`,
        id: listing.companyId,
      } as Company;

      router.push(makeUrl(company));
    };

    const initialValues = createInitialValues(listing);

    return (
      <HiiveModalContentWrapper>
        <FormikQL
          mutation={mutation}
          mutationNames={[`withdrawListing`]}
          initialValues={initialValues}
          validationSchema={validationSchema}
          mapVariables={mapVariables}
          onSuccess={onSuccess}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off" data-testid="withdraw-listing-modal">
              <HiiveModalHeader>
                <Text as="span">Withdraw Listing{` `}</Text>
                <Text as="span">{listing.displayId}</Text>
              </HiiveModalHeader>
              <ModalBody>
                <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
                  <GridItem colSpan={2}>
                    <Text textStyle="text-md">
                      If you withdraw this listing any bids on it will be
                      cancelled and the corresponding bidders will be notified.
                    </Text>
                  </GridItem>
                  {actor.isHiiveUser && (
                    <GridItem colSpan={2}>
                      <HiiveAdvancedOptionsDropdown
                        validationSchema={validationSchema}
                        fieldNames={[`muteNotifications`]}
                      >
                        <VStack spacing={4} alignItems="flex-start">
                          <CheckboxInput
                            name="muteNotifications"
                            label="Do not send notifications about this change."
                          />
                        </VStack>
                      </HiiveAdvancedOptionsDropdown>
                    </GridItem>
                  )}
                </SimpleGrid>
              </ModalBody>
              <HiiveModalFooter>
                <Show above="md" ssr={false}>
                  <HiiveCancelButton
                    sentryLabel="[WithdrawListing/Cancel]"
                    onCancel={closeModal}
                  />
                </Show>
                <HiiveSubmitButton
                  sentryLabel="[WithdrawListing/Submit]"
                  submitText="Withdraw"
                  type="submit"
                  isLoading={isSubmitting}
                />
              </HiiveModalFooter>
            </Form>
          )}
        </FormikQL>
      </HiiveModalContentWrapper>
    );
  },
);

export default WithdrawListingModal;
