import { ReactNode } from "react";

import { ModalFooter, ModalFooterProps } from "@chakra-ui/react";

import { ButtonFooterWrapper } from "@/components/common";

interface HiiveModalFooterProps extends ModalFooterProps {
  readonly children: ReactNode;
}

const HiiveModalFooter = ({
  children,
  ...footerProps
}: HiiveModalFooterProps) => (
  <ModalFooter {...footerProps}>
    <ButtonFooterWrapper>{children}</ButtonFooterWrapper>
  </ModalFooter>
);

export default HiiveModalFooter;
