import { CaretLeft } from "@phosphor-icons/react";

import { HiiveButton, HiiveButtonProps } from "@/components/common";

const SectionNavBackButton = ({ ...hiiveButtonProps }: HiiveButtonProps) => (
  <HiiveButton
    variant="text-grey"
    leftIcon={<CaretLeft size={16} weight="bold" />}
    position="absolute"
    transform="auto"
    translateY="-100%"
    top={-4}
    {...hiiveButtonProps}
  >
    Back
  </HiiveButton>
);

export default SectionNavBackButton;
