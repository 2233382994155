import { Text } from "@chakra-ui/react";

import { toTimestamp } from "@/utils";

const ExpiryDate = ({ expireAt }: { readonly expireAt: Date }) => (
  <Text color="grey.700" textStyle="text-sm">
    Expires {toTimestamp(expireAt)}
  </Text>
);

export default ExpiryDate;
