import { VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

const TextAreaInputSkeleton = () => (
  <VStack alignItems="flex-start" pb={1} w="full">
    <Skeleton h="18px" w="112px" py={0.5} />
    <Skeleton h="132px" />
  </VStack>
);

export default TextAreaInputSkeleton;
