import { ReactNode } from "react";

import { Box, Flex, Text, VStack } from "@chakra-ui/react";

import {
  BidPageAlternateSignerDetailsAlternateSignerFragment,
  BidPageAlternateSignerDetailsBidFragment,
  BidPageAlternateSignerDetailsDocumentFragment,
} from "@/gql";
import { useCurrentActor, useDocumentSigners } from "@/hooks";
import { getLongDocumentTitleByDocumentType, getIsBroker } from "@/utils";

const SignerInfoWrapper = ({ children }: { readonly children: ReactNode }) => (
  <VStack
    py={5}
    px={5}
    bg="white"
    borderColor="grey.100"
    borderWidth="1px"
    borderRadius="md"
    alignItems="flex-start"
    spacing={6}
    w="full"
  >
    {children}
  </VStack>
);

const AlternateSignerSigned = ({
  alternateSigner,
  document,
}: {
  readonly alternateSigner: BidPageAlternateSignerDetailsAlternateSignerFragment;
  readonly document: BidPageAlternateSignerDetailsDocumentFragment;
}) => {
  const actor = useCurrentActor();

  const haveAllSignersSigned = document.signers.length === 2;

  const isBroker = getIsBroker(actor);

  return (
    <SignerInfoWrapper>
      <VStack spacing={3} alignItems="flex-start">
        {haveAllSignersSigned ? (
          <>
            <Text textStyle="heading-lg">
              The {getLongDocumentTitleByDocumentType(document.type)} is
              executed
            </Text>
            <Text>
              Both your {isBroker ? `customer` : `alternate signer`} and the
              counterparty have signed the{` `}
              {getLongDocumentTitleByDocumentType(document.type)}.
            </Text>
          </>
        ) : (
          <>
            <Text textStyle="heading-lg">
              Your {isBroker ? `customer` : `alternate signer`} has signed this
              {` `}
              {getLongDocumentTitleByDocumentType(document.type)}
            </Text>
            <Text>Awaiting counterparty signature.</Text>
          </>
        )}
      </VStack>
      <Box h="1px" w="full" bg="grey.100" />
      <Flex columnGap={8} rowGap={4} wrap="wrap">
        <Box>
          <Text textStyle="heading-xs" mb={2}>
            {isBroker ? `Customer` : `Alternate Signer`} Name
          </Text>
          <Text>{alternateSigner.name}</Text>
        </Box>
        <Box>
          <Text textStyle="heading-xs" mb={2}>
            {isBroker ? `Customer` : `Alternate Signer`} Email
          </Text>
          <Text>{alternateSigner.email}</Text>
        </Box>
      </Flex>
    </SignerInfoWrapper>
  );
};

const AlternateSignerDetails = ({
  bid,
}: {
  readonly bid: BidPageAlternateSignerDetailsBidFragment;
}) => {
  const actor = useCurrentActor();

  const { alternateSigner, invitedAlternateSigner } = useDocumentSigners(
    bid.transaction,
  );

  if (!bid.transaction?.document || !invitedAlternateSigner) {
    return null;
  }

  const isBroker = getIsBroker(actor);

  return alternateSigner ? (
    <AlternateSignerSigned
      document={bid.transaction?.document}
      alternateSigner={alternateSigner}
    />
  ) : (
    <SignerInfoWrapper>
      <VStack spacing={3} alignItems="flex-start">
        <Text textStyle="heading-lg">
          {isBroker ? `Your customer` : `Alternate Signer`} has been invited to
          sign
        </Text>
        <Text>
          {actor.id === invitedAlternateSigner.senderId
            ? `You have`
            : `${invitedAlternateSigner.sender.name} has`}
          {` `}
          invited{` `}
          {isBroker
            ? `your customer to sign `
            : `an alternate signer to execute `}
          this{` `}
          {getLongDocumentTitleByDocumentType(bid.transaction.document.type)}.
        </Text>
      </VStack>
      <Box h="1px" w="full" bg="grey.100" />
      <Flex columnGap={8} rowGap={4} wrap="wrap">
        <Box>
          <Text textStyle="heading-xs" mb={2}>
            {isBroker ? `Customer` : `Alternate Signer`} Name
          </Text>
          <Text>{invitedAlternateSigner.name}</Text>
        </Box>
        <Box>
          <Text textStyle="heading-xs" mb={2}>
            {isBroker ? `Customer` : `Alternate Signer`} Email
          </Text>
          <Text>{invitedAlternateSigner.email}</Text>
        </Box>
      </Flex>
    </SignerInfoWrapper>
  );
};

export default AlternateSignerDetails;
