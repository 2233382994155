import { CornerAnnouncement } from "@/components/frigade";
import { FlowKind } from "@/components/providers";

const UnaccreditedSellerTransactionCelebrationFlow = ({
  flowKind,
}: {
  readonly flowKind: FlowKind;
}) => <CornerAnnouncement flowKind={flowKind} />;

export default UnaccreditedSellerTransactionCelebrationFlow;
