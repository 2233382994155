import { FlexProps, Text } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";

import InfoRowWrapper from "./InfoRowWrapper";
import SensitiveText from "./SensitiveText";

interface InfoRowProps extends FlexProps {
  readonly title: string;
  readonly isSensitive?: boolean;
}

const InfoRow = ({
  title,
  children,
  isSensitive = false,
  ...flexProps
}: InfoRowProps & { readonly actor: UserWithInstitutionFragment }) => (
  <InfoRowWrapper {...flexProps}>
    <Text
      w={{ base: `auto`, sm: `64` }}
      minW={{ base: `auto`, sm: `64` }}
      textStyle="deprecated-heading-xs"
      color="grey.900"
      mb={{ base: 3, lg: 0 }}
      textTransform="uppercase"
    >
      {title}
    </Text>
    <SensitiveText isSensitive={isSensitive}>{children}</SensitiveText>
  </InfoRowWrapper>
);

export default withCurrentActor(InfoRow);
