import { isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";

import Link from "next/link";

import { VStack, Spinner, Text, Center } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { OnboardingContainer } from "@/components/onboarding";
import { CurrentContextDocument, useVerifyEmailTokenMutation } from "@/gql";
import {
  useCheckAuthenticationToken,
  useMutationWithError,
  useRouteToNextOnboardingStep,
  OnboardingRoutes,
} from "@/hooks";

interface VerifyEmailPageProps {
  readonly verifyEmailToken?: string;
}

const ErrorMessage = () => (
  <VStack mt={7} spacing={7}>
    <Text align="center" textStyle="deprecated-text-xl" color="h-dark-grey">
      <Trans i18nKey="email_verification_expired" />
    </Text>
    <HiiveButton
      size="md"
      variant="rounded-outline-salmon"
      sentryLabel="[VerifyEmailPage] Try Again"
    >
      <Link href={OnboardingRoutes.RequestEmailVerification}>
        <Trans i18nKey="try_again" />
      </Link>
    </HiiveButton>
  </VStack>
);

const useVerifyEmailToken = (verifyEmailToken?: string) => {
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isVerifyingEmailToken, setIsVerifyingEmailToken] = useState<boolean>(
    true,
  );
  const [verifyEmailTokenMutation] = useMutationWithError(
    useVerifyEmailTokenMutation(),
    `verifyEmailToken`,
  );
  const authToken = useCheckAuthenticationToken();

  const onSuccess = () => {
    setIsVerified(true);
  };

  useEffect(() => {
    if (!verifyEmailToken) return;

    verifyEmailTokenMutation({
      variables: { token: verifyEmailToken },
      refetchQueries: authToken ? [CurrentContextDocument] : [],
    })
      .then(onSuccess)
      .finally(() => setIsVerifyingEmailToken(false));
  }, [verifyEmailToken]);

  return [isVerified, isVerifyingEmailToken];
};

const AuthenticatedVerifyEmailPage = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  useEffect(() => {
    setTimeout(() => {
      // Need to specify the onboarding path to use
      routeToNextStep(OnboardingRoutes.VerifyEmail);
    }, 2500);
  }, []);

  return (
    <VStack mt={7} spacing={7}>
      <Text align="center" textStyle="deprecated-heading-lg">
        <Trans i18nKey="thank_you" />
      </Text>
      <Text align="center" textStyle="deprecated-heading-lg">
        <Trans i18nKey="one_moment_redirect" />
      </Text>
    </VStack>
  );
};

const NonAuthenticatedVerifyEmailPage = () => (
  <VStack mt={7} spacing={7}>
    <Text align="center" textStyle="deprecated-text-xl" color="h-dark-grey">
      <Trans i18nKey="thank_you_sign_in" />
    </Text>
    <Link href="/login">
      <HiiveButton
        size="md"
        variant="rounded-outline-salmon"
        sentryLabel="[NonAuthenticatedVerifyEmailPage] Sign In"
      >
        <Trans i18nKey="sign_in" />
      </HiiveButton>
    </Link>
  </VStack>
);

const VerifyEmailPage = ({ verifyEmailToken }: VerifyEmailPageProps) => {
  const authToken = useCheckAuthenticationToken();

  const isLoggedIn = !isNil(authToken);

  const [isVerified, isVerifyingEmailToken] = useVerifyEmailToken(
    verifyEmailToken,
  );

  if (isVerifyingEmailToken)
    return (
      <Center m={20}>
        <Spinner />
      </Center>
    );
  if (!isVerified)
    return (
      <OnboardingContainer heading="Error">
        <ErrorMessage />
      </OnboardingContainer>
    );

  return (
    <OnboardingContainer heading="Email Verified">
      {isLoggedIn ? (
        <AuthenticatedVerifyEmailPage />
      ) : (
        <NonAuthenticatedVerifyEmailPage />
      )}
    </OnboardingContainer>
  );
};

export default VerifyEmailPage;
