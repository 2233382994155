import { useContext } from "react";

import { AnalyticsContext } from "@/components/providers";

const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error(`useAnalytics must be used within an AnalyticsProvider`);
  }

  return context;
};

export default useAnalytics;
