import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Spacer,
  Text,
} from "@chakra-ui/react";

import { HiiveButton, WithQuery } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import {
  FormRadioTile,
  FormTextAreaInput,
  FormTextInput,
} from "@/components/react-hook-form";
import {
  BrokerIntermediaryInfoPageCurrentActorDocument,
  BrokerIntermediaryInfoPageUserFragment,
  useBrokerIntermediaryInfoPageCurrentActorQuery,
  useBrokerIntermediaryInfoPageUpsertBrokerInfoMutation,
  useTransitionCurrentStepMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

const getInitialValues = (actor: BrokerIntermediaryInfoPageUserFragment) => ({
  firstName: actor.firstName,
  lastName: actor.lastName,
  brokerRegistrationNumber: actor.brokerInfo?.brokerRegistrationNumber || ``,
  firmName: actor.brokerInfo?.firmName || ``,
  intermediaryPermission: actor.brokerInfo?.intermediaryPermission ?? null,
  intermediaryNotes: actor.brokerInfo?.intermediaryNotes || ``,
});

export interface BrokerIntermediaryInfoFormValues {
  firstName: string;
  lastName: string;
  brokerRegistrationNumber: string;
  firmName: string;
  intermediaryPermission: boolean | null;
  intermediaryNotes: string;
}

const useValidationSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    firstName: Yup.string()
      .nullable()
      .required(t(`validation_required`, { field: t(`first_name`) })),
    lastName: Yup.string()
      .nullable()
      .required(t(`validation_required`, { field: t(`last_name`) })),
    brokerRegistrationNumber: Yup.string().nullable(),
    firmName: Yup.string()
      .nullable()
      .required(t(`validation_required`, { field: t(`firm_name`) })),
    intermediaryPermission: Yup.boolean().nullable().required(t(`required`)),
    intermediaryNotes: Yup.string().when(`intermediaryPermission`, {
      is: false,
      then: Yup.string().nullable().required(t(`required`)),
      otherwise: Yup.string().nullable(),
    }),
  });
};

const mapVariables = (values: BrokerIntermediaryInfoFormValues) => ({
  input: { ...values },
});

const BrokerIntermediaryInfoFormContent = ({
  actor,
}: {
  actor: BrokerIntermediaryInfoPageUserFragment;
}) => {
  const { t } = useTranslation();

  const mutation = useBrokerIntermediaryInfoPageUpsertBrokerInfoMutation({
    refetchQueries: [BrokerIntermediaryInfoPageCurrentActorDocument],
  });

  const initialValues = getInitialValues(actor);
  const validationSchema = useValidationSchema();

  const [
    transitionCurrentStepMutation,
    isTransitioningCurrentStep,
  ] = useMutationWithError(
    useTransitionCurrentStepMutation(),
    `transitionCurrentStep`,
  );

  const onSuccess = () =>
    transitionCurrentStepMutation({
      // TODO: remove on SUP completion
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { onboardingId: actor.onboardingV2?.id! },
    });

  const { control, handleSubmit, isLoading, watch } = useFormQL({
    initialValues,
    mapVariables,
    mutation,
    onSuccess,
    validationSchema,
    shouldFocusError: false,
  });

  const {
    fieldState: { error: intermediaryPermissionError },
  } = useController({
    control,
    name: `intermediaryPermission`,
  });

  const intermediaryPermission = watch(`intermediaryPermission`);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Flex direction="column" gap={8}>
        <SlideAnimation>
          <Flex
            direction="column"
            gap={14}
            bgColor="white"
            borderRadius="md"
            borderColor="grey.200"
            borderWidth={0.5}
            boxShadow="card"
            px={7}
            py={8}
          >
            <Flex direction="column" gap={5}>
              <Flex
                direction={{ base: `column`, md: `row` }}
                rowGap={4}
                columnGap={5}
              >
                <FormTextInput
                  control={control}
                  name="firstName"
                  label={t(`your_full_name`)}
                  placeholder={t(`first`)}
                  bg="white"
                  autoFocus
                />
                <Box w="full">
                  <Spacer display={{ base: `none`, md: `block` }} h={6} />
                  <FormTextInput
                    mt={{ base: 0, md: 1.5 }}
                    control={control}
                    name="lastName"
                    placeholder={t(`last`)}
                    label={t(`last_name`)}
                    labelSrOnly
                  />
                </Box>
              </Flex>
              <FormTextInput
                control={control}
                name="brokerRegistrationNumber"
                label={t(`dealer_intermediary_registration_number_optional`)}
                placeholder={t(`number`)}
              />
              <FormTextInput
                control={control}
                name="firmName"
                label={t(`firm_name`)}
                placeholder={t(`name`)}
              />
            </Flex>
            <Flex direction="column" gap={5}>
              <FormLabel m={0} p={0}>
                {t(`are_you_permitted_by_the_laws`)}
              </FormLabel>
              <FormControl isInvalid={!!intermediaryPermissionError?.message}>
                <FormRadioTile.Group
                  size="sm"
                  p={0}
                  gridTemplateColumns={{ base: `1fr`, sm: `repeat(3, 1fr)` }}
                >
                  <FormRadioTile.Tile
                    value
                    control={control}
                    name="intermediaryPermission"
                  >
                    <Text>{t(`yes`)}</Text>
                  </FormRadioTile.Tile>
                  <FormRadioTile.Tile
                    value={false}
                    control={control}
                    name="intermediaryPermission"
                  >
                    <Text>{t(`no`)}</Text>
                  </FormRadioTile.Tile>
                </FormRadioTile.Group>
                <FormErrorMessage
                  as={GridItem}
                  colSpan={{ base: 1, md: 3 }}
                  mt={2}
                >
                  {intermediaryPermissionError?.message}
                </FormErrorMessage>
              </FormControl>
              {intermediaryPermission === false && (
                <FormTextAreaInput
                  name="intermediaryNotes"
                  control={control}
                  rows={2}
                  label={t(`reason_for_your_answer`)}
                />
              )}
            </Flex>
          </Flex>
        </SlideAnimation>
        <Flex w="full" justifyContent="flex-end">
          <HiiveButton
            isLoading={isLoading || isTransitioningCurrentStep}
            sentryLabel="[BrokerIntermediaryInfoPage] Next"
            size="xl"
            maxW="auto"
            w={{ base: `full`, sm: `auto` }}
            type="submit"
            variant="rounded-solid-salmon"
          >
            {t(`next`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </form>
  );
};

export const BrokerIntermediaryInfoForm = () => {
  const query = useBrokerIntermediaryInfoPageCurrentActorQuery();

  return (
    <WithQuery query={query}>
      {({ data: { currentActor } }) => (
        <BrokerIntermediaryInfoFormContent actor={currentActor} />
      )}
    </WithQuery>
  );
};
