import { defineStyleConfig } from "@chakra-ui/react";

export const CallOut = defineStyleConfig({
  baseStyle: {
    display: `flex`,
    alignItems: `center`,
    gap: 3,
    px: 3,
    py: 4,
    borderRadius: `md`,
    borderWidth: 0.5,
    boxShadow: `card`,
    w: `full`,
  },
  variants: {
    olive: {
      bg: `olive.25`,
      borderColor: `olive.500`,
    },
    plum: {
      bg: `plum.25`,
      borderColor: `plum.400`,
    },
    yellow: {
      bg: `yellow.100`,
      borderColor: `yellow.600`,
    },
    red: {
      bg: `salmon.50`,
      borderColor: `red.700`,
    },
  },
});
