import {
  GetAvailableListingActionsListingFragment,
  ListingPermission,
  ListingState,
} from "@/gql";
import { iHaveEntityPermission } from "@/hooks";

const getIsListingOpen = (listing: GetAvailableListingActionsListingFragment) =>
  listing.state === ListingState.Open;

const getCanWithdrawListing = (
  listing: GetAvailableListingActionsListingFragment,
) =>
  getIsListingOpen(listing) &&
  iHaveEntityPermission(listing, ListingPermission.WithdrawListing);

const getCanModifyListing = (
  listing: GetAvailableListingActionsListingFragment,
) =>
  getIsListingOpen(listing) &&
  iHaveEntityPermission(listing, ListingPermission.ModifyListing);

export const getAvailableListingActions = (
  listing: GetAvailableListingActionsListingFragment,
) => {
  const canWithdrawListing = getCanWithdrawListing(listing);
  const canModifyListing = getCanModifyListing(listing);

  return {
    canWithdrawListing,
    canModifyListing,
    canActOnListing: canModifyListing || canWithdrawListing,
  };
};
