import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";

import { UnaccreditedSellerCustomerAgreement } from "@/components/common";

export const UnaccreditedSellerCustomerAgreementCard = () => (
  <Card w="full" flex="1">
    <CardHeader>
      <Text textStyle="heading-sm">Customer Agreement</Text>
    </CardHeader>
    <CardBody>
      <VStack spacing={4} alignItems="flex-start">
        <Box>
          <Text mb={4}>You have agreed to the Customer Agreement.</Text>
          <Text>
            By signing the Customer Agreement, you have acknowledged and
            accepted that it incorporates Hiive&apos;s{` `}
            <Link
              href="/terms-and-conditions"
              textStyle="heading-md"
              target="_blank"
            >
              Terms and Conditions
            </Link>
            , and consent to be bound by them.
          </Text>
        </Box>
        <UnaccreditedSellerCustomerAgreement />
      </VStack>
    </CardBody>
  </Card>
);
