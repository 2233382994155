import { useTranslation } from "react-i18next";

import { CardFooter, Stack, Spacer } from "@chakra-ui/react";

import {
  calculateTotalOpenBids,
  ExpiryDate,
  ConditionallySoldOutBadge,
  PartiallySoldBadge,
  OpenBidsBadge,
} from "@/components/companies";
import {
  ActivityCardListingCardFooterListingFragment,
  ListingState,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  getIsBroker,
  getShowListingSolicitedState,
  toTimestamp,
} from "@/utils";

import ActivityCardFooterWrapper from "./ActivityCardFooterWrapper";

const ActivityCardListingCardFooter = ({
  listing,
}: {
  readonly listing: ActivityCardListingCardFooterListingFragment;
}) => {
  const {
    acceptedBidPrices,
    bids,
    expireAt,
    insertedAt,
    transferMethod,
    state,
    numActiveBids,
    numCounteredBids,
  } = listing;

  const actor = useCurrentActor();
  const { t } = useTranslation();
  const hasExpirationDate = !!expireAt;

  const isConditonallySold = state === ListingState.ConditionallySold;

  const isPartiallySold =
    state === ListingState.Open && acceptedBidPrices.length >= 1;

  const totalOpenBids = calculateTotalOpenBids(numActiveBids, numCounteredBids);

  const isInReview = state === ListingState.InReview;

  const isPlaced = getIsBroker(actor) && state === ListingState.Open;

  const hasOpenBids = totalOpenBids >= 1;

  const showListingSolicitedState = getShowListingSolicitedState(
    listing,
    actor,
  );

  const hasFooter =
    hasExpirationDate ||
    isPartiallySold ||
    isConditonallySold ||
    hasOpenBids ||
    isInReview ||
    isPlaced;

  if (!hasFooter || showListingSolicitedState) return null;

  return (
    <CardFooter>
      <ActivityCardFooterWrapper>
        {isInReview &&
          t(`listing_submitted_in_review`, { date: toTimestamp(insertedAt) })}
        {isPlaced &&
          t(`listing_placed_status`, { date: toTimestamp(insertedAt) })}
        {hasExpirationDate ? <ExpiryDate expireAt={expireAt} /> : <Spacer />}
        <Stack
          direction={{ base: `column`, md: `row` }}
          wrap="wrap"
          align="flex-end"
          spacing={{ base: 2, md: 3 }}
        >
          {isConditonallySold && (
            <ConditionallySoldOutBadge transferMethod={transferMethod} />
          )}
          {isPartiallySold && (
            <PartiallySoldBadge transferMethod={transferMethod} />
          )}
          {hasOpenBids && (
            <OpenBidsBadge totalOpenBids={totalOpenBids} bids={bids} />
          )}
        </Stack>
      </ActivityCardFooterWrapper>
    </CardFooter>
  );
};

export default ActivityCardListingCardFooter;
