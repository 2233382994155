import { useState } from "react";

const useTabRouter = <TTabKeys extends string>({
  initialTab,
}: {
  readonly initialTab: TTabKeys;
}) => {
  const [currentTabKey, setCurrentTabKey] = useState<TTabKeys>(initialTab);

  const tabControls = {
    jumpToTab: (tabKey: TTabKeys) => {
      setCurrentTabKey(tabKey);
    },
  };

  const tabsInfo = {
    currentTabKey,
  };

  return {
    tabControls,
    tabsInfo,
  };
};

export default useTabRouter;
