import { VStack } from "@chakra-ui/react";

import { CompanyActionsSkeleton } from "./CompanyActions";
import { MarketActivitySkeleton } from "./MarketActivity";
import { YourActivitySkeleton } from "./YourActivitySkeleton";

export const CompanyActivitySkeleton = () => (
  <VStack alignItems="flex-start" spacing={8} w="full">
    <CompanyActionsSkeleton />
    <YourActivitySkeleton />
    <MarketActivitySkeleton />
  </VStack>
);
