import {
  GetTransactionDisplayIdTransactionFragment,
  GetTransactionExpirationDateTransactionFragment,
  GetTransactionTitleTransactionFragment,
  GetTransactionCounterpartyNameTransactionFragment,
  UserWithInstitutionFragment,
  TransactionState,
  BidState,
  UserRole,
  GetShowDocumentActionsBidFragment,
} from "@/gql";
import { getIsInstitutionUser, hasUserRole } from "@/utils";
import * as datetime from "@/utils/datetime";

export const getTransactionTitle = (
  transaction: GetTransactionTitleTransactionFragment,
) =>
  transaction.bid.standingBid
    ? `Transaction on Standing Bid`
    : `Transaction on Bid`;

export const getTransactionDisplayId = (
  transaction: GetTransactionDisplayIdTransactionFragment,
) => transaction.bid.standingBid?.displayId || transaction.bid.displayId;

export const getTransactionExpirationDate = (
  transaction: GetTransactionExpirationDateTransactionFragment,
) =>
  !!transaction.expireAt
    ? datetime.format(`h:mm A z on MMM D, YYYY`, transaction.expireAt)
    : null;

export const getTransactionCounterpartyName = (
  transaction: GetTransactionCounterpartyNameTransactionFragment,
) => {
  const { counterparty, document } = transaction;

  if (counterparty?.institution) {
    return counterparty.institution.legalName;
  }

  if (document && document?.signers?.length > 0) {
    const signer = document.signers.find(
      (signer) => signer.userId === counterparty?.id,
    );
    if (signer?.alternateSigner) {
      return signer.alternateSigner.name;
    }
  }

  return counterparty?.name || `Counterparty`;
};

export const getShowDocumentActions = ({
  bid,
  actor,
  canSignTransactionDocument,
  canInviteAlternateSigner,
  canRevokeAlternateSignerInvitation,
  haveISigned,
}: {
  readonly bid: GetShowDocumentActionsBidFragment;
  readonly actor: UserWithInstitutionFragment;
  readonly canSignTransactionDocument: boolean;
  readonly canInviteAlternateSigner: boolean;
  readonly canRevokeAlternateSignerInvitation: boolean;
  readonly haveISigned: boolean;
}) => {
  const hasDocument = !!bid.transaction?.document;
  const isCancelled = bid.transaction?.state === TransactionState.Cancelled;
  const isAcceptedOrRejected =
    bid.state === BidState.Accepted || bid.state === BidState.Rejected;

  const canViewDocumentActions =
    canRevokeAlternateSignerInvitation ||
    canSignTransactionDocument ||
    canInviteAlternateSigner ||
    // if actor is an institutional user without a signer role
    (getIsInstitutionUser(actor) && hasUserRole(actor, UserRole.Viewer)) ||
    (haveISigned &&
      (!bid.transaction?.rofr || bid.counterpartyId === actor.id));
  return (
    hasDocument &&
    !isCancelled &&
    isAcceptedOrRejected &&
    canViewDocumentActions
  );
};
