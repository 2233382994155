/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import { useFormikContext } from "formik";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { GridItem, ModalBody, Show, SimpleGrid } from "@chakra-ui/react";

import {
  FullDivider,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveNextButton,
} from "@/components/common";
import {
  ConditionalInput,
  FormListbox,
  FormNumberInput,
  MoneyInput,
  StepPropsV2,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { StandingBidAcceptedTransferMethods } from "@/components/postings";
import {
  BrokerStandingBidAllowPartialAcceptTooltip,
  StandingBidAllowPartialAcceptTooltip,
} from "@/components/tooltip";
import {
  PlaceStandingBidSelectCompanyInputCompanyFragment,
  StandingBidAcceptsSharesOptions,
  UserWithInstitutionFragment,
} from "@/gql";
import { useIsDesktop, useModal, useStepValidator } from "@/hooks";
import {
  acceptsSharesToString,
  constants,
  getIsBroker,
  Nullable,
  resetTouchedState,
} from "@/utils";

import { PlaceStandingBidSelectCompanyInput } from "./PlaceStandingBidSelectCompanyInput";
import { PlaceStandingBidSequenceModalStepFormContext } from "./PlaceStandingBidSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { PlaceStandingBidSequenceModalFormValues } from "./types";

export const validationSchema = Yup.object().shape({
  company: Yup.object().nullable().required(`Required`),
  numShares: Yup.number().nullable().required(`Required`),
  pricePerShare: Yup.number()
    .nullable()
    .moreThan(0, `Must be a positive number.`)
    .required(`Required`)
    .test({
      name: `checkBidMeetsMinValue`,
      params: {},
      message: `Bid must be a minimum value of ${constants.min_standing_bid_lot.text}. Try adjusting the minimum lot size and/or price per share.`,
      test: function (pricePerShare: number) {
        return (
          pricePerShare * this.parent.numShares >=
          constants.min_standing_bid_lot.number
        );
      },
    }),
  acceptsShares: Yup.string().nullable().required(`Required`),
  allowPartialAccept: Yup.boolean().required(`Required`),
  minPartialAcceptNumShares: Yup.number()
    .min(1, `Must be at least 1`)
    .nullable()
    .test({
      name: `checkRequired`,
      message: `Required when partial acceptance is allowed`,
      test: function (minPartialAcceptNumShares: number | null) {
        return (
          !this.parent.allowPartialAccept || !isNil(minPartialAcceptNumShares)
        );
      },
    })
    .test({
      name: `checkPartialAcceptMeetsMinValue`,
      message: `Partial accept amount for a Bid must have a minimum value of ${constants.min_standing_bid_lot.text}`,
      test: function (minPartialAcceptNumShares: number) {
        return (
          !this.parent.allowPartialAccept ||
          this.parent.pricePerShare * minPartialAcceptNumShares >=
            constants.min_standing_bid_lot.number
        );
      },
    })
    .test({
      name: `checkLessThanNumShares`,
      message: `Must be less than or equal to the total number of shares`,
      test: function (minPartialAcceptNumShares: number) {
        return (
          !this.parent.allowPartialAccept ||
          minPartialAcceptNumShares <= this.parent.numShares
        );
      },
    }),
  acceptsTransferMethods: Yup.array()
    .of(Yup.string())
    .min(1, `Must select at least one`)
    .nullable()
    .required(`Required`),
});

interface BidDetailsProps
  extends StepPropsV2<
    StepKeys,
    Nullable<PlaceStandingBidSequenceModalFormValues>
  > {
  readonly initialCompany?: PlaceStandingBidSelectCompanyInputCompanyFragment;
  readonly actor: UserWithInstitutionFragment;
}

export const BidDetails = withCurrentActor(
  ({ values, initialCompany, stepRouter, actor }: BidDetailsProps) => {
    const { stepControls } = stepRouter;
    const { allowPartialAccept } = values;

    const { t } = useTranslation();

    const { closeModal } = useModal();
    const { touched, setTouched } = useFormikContext();

    const isDesktop = useIsDesktop();

    const onSuccess = () => {
      stepControls.nextStep();
      // Workaround for Formik setting every field in the form to touched
      // on submit. Get rid of me when we move to react-hook-form :'(
      resetTouchedState<PlaceStandingBidSequenceModalFormValues>({
        fields: touched,
        setTouched,
      });
    };

    useStepValidator({
      Context: PlaceStandingBidSequenceModalStepFormContext,
      stepKey: stepKeys.placeStandingBidBidDetails,
      validator: {
        validationSchema,
        onSuccess,
      },
      values,
    });

    const actorIsBroker = getIsBroker(actor);

    return (
      <>
        <ModalBody>
          <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
            {!initialCompany && (
              <>
                <GridItem colSpan={2}>
                  <PlaceStandingBidSelectCompanyInput
                    name="company"
                    label="Which company?"
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FullDivider />
                </GridItem>
              </>
            )}
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormNumberInput name="numShares" label="Number of shares" />
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <MoneyInput name="pricePerShare" label="Price per share" />
            </GridItem>
            <GridItem colSpan={2}>
              <FormListbox
                name="acceptsShares"
                label="Share type"
                itemToString={(item: StandingBidAcceptsSharesOptions) =>
                  acceptsSharesToString(item)
                }
                getItemKey={(item) => item}
                items={[
                  StandingBidAcceptsSharesOptions.Both,
                  StandingBidAcceptsSharesOptions.Common,
                  StandingBidAcceptsSharesOptions.Pref,
                ]}
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <ConditionalInput
                name="allowPartialAccept"
                label="Allow partial acceptance?"
                tooltipContent={
                  actorIsBroker ? (
                    <BrokerStandingBidAllowPartialAcceptTooltip />
                  ) : (
                    <StandingBidAllowPartialAcceptTooltip />
                  )
                }
                horizontal={isDesktop}
              />
            </GridItem>
            {allowPartialAccept && (
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormNumberInput
                  name="minPartialAcceptNumShares"
                  label="Minimum number of shares"
                />
              </GridItem>
            )}
            <GridItem colSpan={2}>
              <StandingBidAcceptedTransferMethods
                label={
                  actorIsBroker
                    ? t(`submit_standing_bid_accepted_transfer_methods_label`)
                    : t(`place_standing_bid_accepted_transfer_methods_label`)
                }
                name="acceptsTransferMethods"
              />
            </GridItem>
          </SimpleGrid>
        </ModalBody>
        <HiiveModalFooter>
          <Show above="md" ssr={false}>
            <HiiveCancelButton
              sentryLabel="[PlaceStandingBid/BidDetails/Cancel]"
              onCancel={closeModal}
            />
          </Show>
          <HiiveNextButton
            type="submit"
            sentryLabel="[PlaceStandingBid/BidDetails/Next]"
          />
        </HiiveModalFooter>
      </>
    );
  },
);
