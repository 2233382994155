import { HiiveWelcomeFlow } from "@/components/frigade";
import { FlowKind } from "@/components/providers";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";

export const DashboardWelcomeGuides = () => {
  const isSSBPEnabled = useSellSideBrokerPortalEnabled();

  const flows = [
    FlowKind.IndividualTraderNoIVNoCAWelcome,
    FlowKind.IndividualTraderNoIVYesCAWelcome,
    FlowKind.IndividualTraderYesIVNoCAWelcome,
    FlowKind.IndividualTraderYesIVYesCAWelcome,
    FlowKind.InstitutionTraderYesCAWelcome,
    FlowKind.InstitutionTraderNoCAWelcome,
    ...(isSSBPEnabled
      ? [FlowKind.BrokerWelcomeSSBP]
      : [FlowKind.BrokerWelcome]),
  ];

  return (
    <>
      {flows.map((flowKind) => (
        <HiiveWelcomeFlow key={flowKind} flowKind={flowKind} />
      ))}
    </>
  );
};
