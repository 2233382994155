import { useState } from "react";
import { useTranslation } from "react-i18next";

import Link from "next/link";

import { Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { withGuestRoute } from "@/components/hoc";
import { OnboardingContainer } from "@/components/onboarding";

import ResetPasswordForm from "./ResetPasswordForm";

const PasswordResetMessage = () => {
  const { t } = useTranslation();

  return (
    <VStack spacing={14} mt={7}>
      <Text align="center" textStyle="deprecated-text-xl" color="h-dark-grey">
        {t(`can_sign_in_with_new_password`)}
      </Text>
      <Link href="/login">
        <HiiveButton
          variant="rounded-outline-salmon"
          size="md"
          sentryLabel="[ResetPasswordPage] Sign In"
        >
          {t(`sign_in`)}
        </HiiveButton>
      </Link>
    </VStack>
  );
};

const ResetPasswordPage = ({ token }: { readonly token: string }) => {
  const [passwordReset, setPasswordReset] = useState<boolean>(false);
  const { t } = useTranslation();

  const onSuccess = () => {
    setPasswordReset(true);
  };

  return (
    <OnboardingContainer heading={t(`reset_password`)}>
      {passwordReset ? (
        <PasswordResetMessage />
      ) : (
        <>
          <Text
            my={7}
            align="center"
            textStyle="deprecated-text-xl"
            color="h-dark-grey"
          >
            {t(`enter_new_password`)}
          </Text>
          <ResetPasswordForm token={token} onSuccess={onSuccess} />
        </>
      )}
    </OnboardingContainer>
  );
};

export default withGuestRoute(ResetPasswordPage);
