import { Maybe } from "@/types";
import { constants } from "@/utils";

export const getAreFeesHighForStandingBid = ({
  pricePerShare,
  numberOfShares,
}: {
  readonly pricePerShare?: Maybe<number>;
  readonly numberOfShares: number;
}) => {
  const isWithinWarningThreshold = pricePerShare
    ? pricePerShare * numberOfShares < constants.high_fees_warning_threshold
    : false;

  return isWithinWarningThreshold;
};
