import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Text, VStack } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { canAccessPlatform, useRouteToNextOnboardingStep } from "@/hooks";
import { constants } from "@/utils";

const IndividualNeedsFurtherVerificationPage = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();
  const { t } = useTranslation();

  useEffect(() => {
    if (!canAccessPlatform(actor)) return;
    routeToNextStep();
  }, []);

  if (canAccessPlatform(actor)) return null;

  return (
    <VStack spacing={6} textStyle="deprecated-text-xl" textAlign="center">
      <Text>We are excited to welcome you to the Hiive platform.</Text>
      <Text>
        {t(`identity_submitted_for_review`)}
        <MailtoLink
          email={constants.email_accounts}
          whiteSpace="nowrap"
          fontWeight="medium"
          display="block"
        >
          {constants.email_accounts}
        </MailtoLink>
        .
      </Text>
    </VStack>
  );
};

export default withCurrentActor(IndividualNeedsFurtherVerificationPage);
