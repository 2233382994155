import { Flex } from "@chakra-ui/react";

import {
  CompanyHistoricalPricingCardSkeleton,
  CompanyLastRoundDetailsCardSkeleton,
} from "@/components/companies";

import { DescriptionCardSkeleton } from "./DescriptionCardSkeleton";

export const InfoSkeleton = () => (
  <Flex direction="column" gap={4}>
    <DescriptionCardSkeleton />
    <CompanyHistoricalPricingCardSkeleton />
    <CompanyLastRoundDetailsCardSkeleton />
  </Flex>
);
