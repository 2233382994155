import { useTranslation } from "react-i18next";

import Link from "next/link";

import { Box, Text } from "@chakra-ui/react";

import { AuthContainer } from "@/components/auth";
import { withGuestRoute } from "@/components/hoc";
import { useSuitabilityUpfront } from "@/hooks/featureFlags";

import { LoginForm } from "./LoginForm";
import { LoginFormV2 } from "./LoginFormV2";

const LoginPage = () => {
  const isSUPEnabled = useSuitabilityUpfront();
  const { t } = useTranslation();

  return (
    <AuthContainer title={t(`sign_in`)}>
      <Text
        my={7}
        align="center"
        maxW="md"
        textStyle="deprecated-text-xl"
        color="h-dark-grey"
      >
        <Box as="span" mr={1}>
          {t(`no_account`)}
        </Box>
        <Box as="span" fontWeight="medium" color="h-dark-grey">
          <Link href="/signup">{t(`sign_up`)}</Link>
        </Box>
      </Text>
      {isSUPEnabled ? <LoginFormV2 /> : <LoginForm />}
    </AuthContainer>
  );
};

export default withGuestRoute(LoginPage);
