const CheckmarkSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M10.6665 0.961548C8.68869 0.961548 6.7553 1.54804 5.11081 2.64685C3.46631 3.74567 2.18459 5.30745 1.42771 7.13471C0.670837 8.96197 0.472803 10.9726 0.858656 12.9124C1.24451 14.8523 2.19692 16.6341 3.59544 18.0326C4.99396 19.4311 6.77579 20.3835 8.7156 20.7694C10.6554 21.1552 12.6661 20.9572 14.4933 20.2003C16.3206 19.4435 17.8824 18.1617 18.9812 16.5172C20.08 14.8728 20.6665 12.9394 20.6665 10.9615C20.6637 8.31024 19.6092 5.76833 17.7345 3.89357C15.8597 2.01881 13.3178 0.964348 10.6665 0.961548ZM15.0569 9.19808L9.67228 14.5827C9.60083 14.6542 9.516 14.711 9.42261 14.7497C9.32923 14.7884 9.22913 14.8083 9.12805 14.8083C9.02696 14.8083 8.92686 14.7884 8.83348 14.7497C8.74009 14.711 8.65525 14.6542 8.58381 14.5827L6.27612 12.275C6.13178 12.1307 6.05069 11.9349 6.05069 11.7308C6.05069 11.5267 6.13178 11.3309 6.27612 11.1865C6.42046 11.0422 6.61623 10.9611 6.82035 10.9611C7.02448 10.9611 7.22025 11.0422 7.36458 11.1865L9.12805 12.951L13.9684 8.10962C14.0399 8.03815 14.1247 7.98146 14.2181 7.94278C14.3115 7.9041 14.4116 7.8842 14.5127 7.8842C14.6137 7.8842 14.7138 7.9041 14.8072 7.94278C14.9006 7.98146 14.9854 8.03815 15.0569 8.10962C15.1284 8.18109 15.1851 8.26594 15.2237 8.35932C15.2624 8.4527 15.2823 8.55278 15.2823 8.65385C15.2823 8.75493 15.2624 8.85501 15.2237 8.94839C15.1851 9.04177 15.1284 9.12662 15.0569 9.19808Z"
      fill="#424242"
    />
  </svg>
);
export default CheckmarkSvg;
