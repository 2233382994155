import { useTranslation } from "react-i18next";

import { HStack, Text, Badge } from "@chakra-ui/react";

import { Logo } from "@/components/common";

const PoweredByBadge = () => {
  const { t } = useTranslation();

  return (
    <Badge variant="grey" px={2} py={1} as={HStack} spacing={1.5}>
      <Text textStyle="text-sm" textTransform="none">
        {t(`powered_by`)}
      </Text>
      <Logo.Grey width={14} height="auto" />
    </Badge>
  );
};

export default PoweredByBadge;
