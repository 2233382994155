import { ForwardedRef } from "react";

import { forwardRef, Flex, FlexProps } from "@chakra-ui/react";

interface FullContentWrapperProps extends FlexProps {
  readonly children: React.ReactNode;
}

const FullContentWrapper = forwardRef(
  (props: FullContentWrapperProps, ref: ForwardedRef<HTMLDivElement>) => (
    <Flex
      ref={ref}
      boxSize="full"
      direction="column"
      align="center"
      flex="1 1 auto"
      margin={0}
      pb={14}
      pt={7}
      {...props}
    />
  ),
);

export default FullContentWrapper;
