import { WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Image, ModalBody, Text, VStack } from "@chakra-ui/react";

import { GqlError } from "@/apollo-client";
import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CurrentContextDocument,
  useRecordUserMultiFactorAuthenticationMutation,
  UserMultiFactorAuthenticationMethod,
  UserMultiFactorAuthenticationStatus,
} from "@/gql";
import { useModal } from "@/hooks";
import { useVerifyAppMfa } from "@/hooks/frontegg";
import { GqlErrorCodes } from "@/types";

const EnrollAppMfaModal = ({ qrCode }: { readonly qrCode: string }) => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();
  const [verifyMfa, { loading, error }] = useVerifyAppMfa();
  const [
    recordUserMultiFactorAuthentication,
  ] = useRecordUserMultiFactorAuthenticationMutation({
    refetchQueries: [CurrentContextDocument],
  });
  const [pinValue, setPinValue] = useState<string>(``);

  const handleComplete = async (value: string) => {
    const variables = {
      input: {
        method: UserMultiFactorAuthenticationMethod.App,
        status: UserMultiFactorAuthenticationStatus.PendingActive,
      },
    };

    const result = await recordUserMultiFactorAuthentication({
      variables,
      errorPolicy: `all`,
    });

    const [mutationError] = result.errors || [];

    const mfaEnrollmentPending =
      result.data?.recordUserMultiFactorAuthentication
        ?.userMultiFactorAuthentication ||
      (mutationError as GqlError).code === GqlErrorCodes.MFA_ENROLLMENT_PENDING;

    if (mfaEnrollmentPending) {
      const { data, ok } = await verifyMfa({ token: value });

      if (data || ok) {
        onOpenModal(modals.enrollAppMfaSuccess(data?.recoveryCode))();
      }
    }
  };

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`set_up_authenticator_app`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="center" gap={4}>
          <Text>{t(`set_up_authenticator_app_description`)}</Text>
          {qrCode && <Image src={qrCode} />}
          <CodeInput
            disabled={loading}
            onChange={(value: string) => setPinValue(value)}
            onComplete={handleComplete}
          />
          {error && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{t(`incorrect_code`)}</Text>
            </CallOut>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            isDisabled={pinValue.length !== 6}
            sentryLabel="[EnrollAppMfa/Submit]"
            submitText={t(`verify`)}
            type="submit"
            onClick={() => handleComplete(pinValue)}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default EnrollAppMfaModal;
