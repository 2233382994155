import { useEffect } from "react";

import { Box, SimpleGrid } from "@chakra-ui/react";

import {
  FullContentWrapper,
  MobileTab,
  MobileTabList,
  MobileTabPanel,
  MobileTabPanels,
  MobileTabs,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  StandingBidPageContentStandingBidFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useIsDesktop, useKnockClient, useNotificationFeed } from "@/hooks";
import { maybeViewNewStandingBidNotification } from "@/services/knock";

import { StandingBidActivity } from "./StandingBidActivity";
import StandingBidInfo from "./StandingBidInfo";

const StandingBidPageContent = withCurrentActor(
  ({
    actor,
    userNotificationToken,
    standingBid,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly userNotificationToken: string;
    readonly standingBid: StandingBidPageContentStandingBidFragment;
  }) => {
    const isDesktopView = useIsDesktop();

    const knockClient = useKnockClient({ actor, userNotificationToken });
    const notificationFeed = useNotificationFeed({
      knockClient,
    });

    useEffect(() => {
      maybeViewNewStandingBidNotification({
        notificationFeed,
        standingBidId: standingBid.id,
      });
    }, []);

    return (
      <FullContentWrapper px={{ base: 4, lg: 8 }}>
        <Box w="full" maxW="max-width-md">
          {isDesktopView ? (
            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
              <StandingBidInfo standingBid={standingBid} />
              <StandingBidActivity standingBid={standingBid} />
            </SimpleGrid>
          ) : (
            <MobileTabs>
              <MobileTabList>
                <MobileTab>Overview</MobileTab>
                <MobileTab>Activity</MobileTab>
              </MobileTabList>

              <MobileTabPanels>
                <MobileTabPanel>
                  <StandingBidInfo standingBid={standingBid} />
                </MobileTabPanel>
                <MobileTabPanel>
                  <StandingBidActivity standingBid={standingBid} />
                </MobileTabPanel>
              </MobileTabPanels>
            </MobileTabs>
          )}
        </Box>
      </FullContentWrapper>
    );
  },
);

export default StandingBidPageContent;
