import { ChakraProps, Text } from "@chakra-ui/react";

import { BidHighFeesWarningBidFragment } from "@/gql";
import { getCanCounterNumberOfShares } from "@/utils";

const BidHighFeesWarning = ({
  bid,
  textStyle,
}: {
  readonly bid: BidHighFeesWarningBidFragment;
  readonly textStyle?: ChakraProps["textStyle"];
}) => {
  const isCounteringSuggestionVisible = getCanCounterNumberOfShares(bid);

  return (
    <Text textStyle={textStyle} color="salmon.900">
      Note: Due to the small size of this potential sale, total fees will be
      high as a percentage of transaction value.{` `}
      {isCounteringSuggestionVisible && (
        <>Consider countering the Buyer at a higher number of shares.</>
      )}
    </Text>
  );
};

export default BidHighFeesWarning;
