import { t } from "i18next";

import {
  InvestorStatus,
  InvestorType,
  UserPermissionV2,
  UserWithInstitutionFragment,
} from "@/gql";

import { useIHavePermission } from "./myPermissions";

type UseObscureCompanyInfo = {
  readonly showInfo: boolean;
  readonly message: string;
};

const useObscureCompanyInfo = ({
  investorType,
  identityVerified,
  institution,
  investorStatus,
}: UserWithInstitutionFragment): UseObscureCompanyInfo => {
  const canSeeSensitiveText = useIHavePermission(
    UserPermissionV2.SeeSensitiveText,
  );

  const message = () => {
    if (investorType === InvestorType.UnaccreditedSeller && !identityVerified) {
      return t(`verify_identity_to_view`);
    }

    if (investorStatus === InvestorStatus.Individual && !identityVerified) {
      return t(`complete_profile_to_view`);
    }

    if (
      investorStatus === InvestorStatus.Institutional &&
      !!institution &&
      !institution.membershipAgreementSigned
    ) {
      return t(`sign_agreement_to_view`);
    }

    return ``;
  };

  return { showInfo: canSeeSensitiveText, message: message() };
};

export default useObscureCompanyInfo;
