import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { match, P } from "ts-pattern";

import { StackProps, Text, VStack } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  DiscussionMessageListDiscussionFragment,
  DiscussionMessageListDiscussionMessageFragment,
  DiscussionState,
  MyDiscussionListDiscussionsDocument,
  useMarkDiscussionAsReadMutation,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  useKnockClient,
  useMutationWithError,
  useNotificationFeed,
} from "@/hooks";
import { viewInquiryNewMessageNotifications } from "@/services/knock";

import { DiscussionMessage } from "./DiscussionMessage";

interface DiscussionMessageListProps extends StackProps {
  readonly allMessages: readonly DiscussionMessageListDiscussionMessageFragment[];
  readonly discussion: DiscussionMessageListDiscussionFragment;
  readonly actor: UserWithInstitutionFragment;
  readonly userNotificationToken: string;
}

const getDiscussionRequestedText = (
  actor: UserWithInstitutionFragment,
  discussion: DiscussionMessageListDiscussionFragment,
) =>
  match(discussion)
    .with({ initiatorId: P.not(actor.id) }, () => null)
    .with({ state: P.not(DiscussionState.Pending) }, () => null)
    .with({ counterpartyHasReplied: true }, () => null)
    .with(
      { topic: { fromHiive: true } },
      () =>
        `Your message has been sent and a Hiive Security Specialist will get back to you shortly.`,
    )
    .with(
      {
        topic: { __typename: `Listing`, sellerId: actor.id },
      },
      () => `Your message request has been sent to the buyer.`,
    )
    .with(
      {
        topic: { __typename: `Listing`, sellerId: P.not(actor.id) },
      },
      () => `Your message request has been sent to the seller.`,
    )
    .with(
      { topic: { __typename: `StandingBid`, buyerId: P.not(actor.id) } },
      () => `Your message request has been sent to the buyer.`,
    )
    .otherwise(() => {
      throw new Error(`Unhandled discussion in getDiscussionRequestedText`);
    });

export const DiscussionMessageList = withCurrentActor(
  ({
    allMessages,
    discussion,
    actor,
    userNotificationToken,
    ...rest
  }: DiscussionMessageListProps) => {
    const [markDiscussionAsReadMutation] = useMutationWithError(
      useMarkDiscussionAsReadMutation(),
      `markDiscussionAsRead`,
    );

    const knockClient = useKnockClient({ actor, userNotificationToken });
    const notificationFeed = useNotificationFeed({
      knockClient,
    });

    const hasUnreadMessages = discussion.numUnreadMessages > 0;

    useEffect(() => {
      viewInquiryNewMessageNotifications({
        notificationFeed,
        inquiryId: discussion.id,
      });

      if (!hasUnreadMessages) return;
      markDiscussionAsReadMutation({
        variables: { discussionId: discussion.id },
        refetchQueries: [MyDiscussionListDiscussionsDocument],
      });
    }, []);

    const discussionRequestedText = getDiscussionRequestedText(
      actor,
      discussion,
    );

    return (
      <VStack
        scrollMarginTop={100}
        scrollPaddingTop={100}
        direction="column-reverse"
        flex="1"
        justifyContent="flex-end"
        alignItems="start"
        w="full"
        {...rest}
      >
        <AnimatePresence initial={false}>
          {allMessages.map((message, index) => {
            const showSenderName = !!allMessages[index - 1]
              ? allMessages[index - 1].senderId !== message.senderId
              : true;

            return (
              <DiscussionMessage
                key={message.id}
                index={index}
                message={message}
                showSenderName={showSenderName}
                discussion={discussion}
              />
            );
          })}
        </AnimatePresence>
        {!!discussionRequestedText && (
          <Text pl={5} textStyle="deprecated-text-sm" py={1}>
            {discussionRequestedText}
          </Text>
        )}
      </VStack>
    );
  },
);
