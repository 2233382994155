import { Card, CardBody, GridItem, SimpleGrid, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

export const StandingBidInfoSkeleton = () => (
  <Card variant="darkened-footer">
    <CardBody>
      <VStack gap="28px" spacing={0} alignItems="left">
        <Skeleton h="40px" w="full" maxW="40%" />
        <Skeleton h="124px" />
        <Skeleton h="62px" />
        <Skeleton h="108px" />
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
          {[...Array(2).keys()].map((index) => (
            <GridItem key={index} colSpan={1}>
              <Skeleton h="58px" w="full" />
            </GridItem>
          ))}
          {[...Array(4).keys()].map((index) => (
            <GridItem key={index} colSpan={{ base: 1, md: 2 }}>
              <Skeleton h="58px" w="full" />
            </GridItem>
          ))}
        </SimpleGrid>
      </VStack>
    </CardBody>
  </Card>
);
