import { motion } from "framer-motion";
import { ReactNode } from "react";

import { Box } from "@chakra-ui/react";

const AnimatedCardStep = ({ children }: { readonly children: ReactNode }) => {
  const duration = 0.2;
  return (
    <Box
      w="full"
      as={motion.div}
      exit={{
        opacity: 0,
        transition: {
          duration,
        },
      }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration,
        },
      }}
    >
      {children}
    </Box>
  );
};

export default AnimatedCardStep;
