import xor from "lodash/xor";
import { useMemo } from "react";

import { Flex, Text } from "@chakra-ui/react";

import { CustomCheckbox } from "@/components/common";
import { FilterTypes } from "@/components/companies";
import { useBrowseCompaniesPageCountriesQuery } from "@/gql";
import { useCountryList } from "@/hooks";

type CountryOption = {
  readonly id: string;
  readonly code: string;
  readonly name: string;
};

interface CountryListProps {
  readonly search: string;
  readonly onFilterChange: (
    field: keyof FilterTypes,
    value: string | readonly string[],
  ) => void;
  readonly filters: FilterTypes;
}

const useCountryOptions = () => {
  const countryList = useCountryList();
  const { data } = useBrowseCompaniesPageCountriesQuery();

  const countryOptions = useMemo(
    () =>
      data?.allCompanyCountries?.map((country) => ({
        id: country.id,
        code: country.name,
        name: countryList.getName(country.name),
      })) || [],
    [data?.allCompanyCountries, countryList],
  );

  return countryOptions;
};

export const CountryList = ({
  search,
  filters,
  onFilterChange,
}: CountryListProps) => {
  const countries = useCountryOptions();

  const getIsCountrySelected = (countryId: string) =>
    filters.countries.includes(countryId);

  const toggleCountrySelected = (countryId: string) => {
    const updatedCountries = xor(filters.countries, [countryId]);
    onFilterChange(`countries`, updatedCountries);
  };

  const displayedCountries = useMemo(
    () =>
      countries.filter((country) =>
        country.name.toLowerCase().includes(search.toLowerCase()),
      ) || [],
    [countries, search],
  );

  return displayedCountries.length > 0 ? (
    <Flex direction="column" gap={2} maxH={36} overflowY="scroll" px={1}>
      <Flex justifyContent="center" direction="column" gap={1}>
        {displayedCountries.map((country: CountryOption) => (
          <CustomCheckbox
            alignItems="center"
            spacing={3}
            key={country.id}
            isChecked={getIsCountrySelected(country.id)}
            onChange={() => toggleCountrySelected(country.id)}
          >
            <Text textStyle="heading-md" mt={2}>
              {country.name}
            </Text>
          </CustomCheckbox>
        ))}
      </Flex>
    </Flex>
  ) : (
    <Text>No results found.</Text>
  );
};
