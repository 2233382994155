import { useField } from "formik";
import { ReactNode } from "react";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  useRadioGroup,
} from "@chakra-ui/react";

import { RadioGroup } from "./types";

function FormCustomRadioInput<T = string>({
  name,
  label,
  children,
  mapper,
}: {
  readonly name: string;
  readonly label?: string;
  readonly children: (radioGroup: RadioGroup) => ReactNode;
  readonly mapper?: {
    readonly to: (value?: string | null) => T;
    readonly from: (value: T) => string | null | undefined;
  };
}) {
  const [field, { touched, error }, { setValue }] = useField(name);

  const handleChange = (value: string) => {
    if (!mapper) {
      setValue(value);
      return;
    }
    setValue(mapper.to(value));
  };

  const value = !!mapper ? mapper.from(field.value) : field.value;

  const radioGroup = useRadioGroup({
    ...field,
    value,
    onChange: handleChange,
  });

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <FormLabel>{label}</FormLabel>
      {children(radioGroup)}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}

export default FormCustomRadioInput;
