import { ReactNode } from "react";

import { ModalBody } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { useModal } from "@/hooks";

const UnauthorizedModalBodyWrapper = ({
  children,
  sentryLabelPrefix,
  title,
}: {
  readonly children: ReactNode;
  readonly sentryLabelPrefix: string;
  readonly title: string;
}) => {
  const { closeModal } = useModal();

  return (
    <>
      <HiiveModalHeader>{title}</HiiveModalHeader>
      <ModalBody>{children}</ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          sentryLabel={`[${sentryLabelPrefix}/Submit]`}
          submitText="Okay"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default UnauthorizedModalBodyWrapper;
