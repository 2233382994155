import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  StackProps,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";

import { CTACard, MailtoLink } from "@/components/common";
import { BidPageBrokerBidderActivityBidFragment, BidState } from "@/gql";
import { useModal } from "@/hooks";
import {
  getAvailableCompanyActions,
  getSecuritySpecialistContact,
} from "@/utils";

const SubmitStandingBidCTACard = ({
  bid,
}: {
  readonly bid: BidPageBrokerBidderActivityBidFragment;
}) => {
  const { t } = useTranslation();
  const { modals, onOpenModal } = useModal();

  const onClick = () => {
    onOpenModal(
      modals.placeStandingBid(bid.listing.company, {
        numShares: bid.numShares,
        pricePerShare: bid.pricePerShare / 100,
      }),
    )();
  };

  const direction: StackProps["direction"] = useBreakpointValue(
    { base: `column`, sm: `row` },
    { ssr: false },
  );

  return (
    <CTACard
      heading={t(`submit_standing_bid_after_bid_prompt`)}
      description={t(`submit_standing_bid_after_bid_description`, {
        companyName: bid.listing.company.name,
      })}
      direction={direction}
      action={{
        ctaText: t(`submit_standing_bid`),
        onClick,
      }}
    />
  );
};

const LiveBidSecuritySpecialistCard = ({
  bid,
}: {
  readonly bid: BidPageBrokerBidderActivityBidFragment;
}) => {
  const { t } = useTranslation();

  const contactEmail = bid.buyerEmail;

  if (!contactEmail) return null;

  const companyName = bid.listing.company.name;

  return (
    <Card>
      <CardBody>
        <VStack spacing={4} alignItems="flex-start">
          <Text>
            {t(`broker_live_bid_security_specialist_card`, { companyName })}
          </Text>
          <MailtoLink
            email={contactEmail}
            subject={t(`broker_bidder_bid_email_security_specialist_subject`, {
              companyName,
            })}
            fontWeight="medium"
          >
            {contactEmail}
          </MailtoLink>
        </VStack>
      </CardBody>
    </Card>
  );
};

const InReviewBidSecuritySpecialistCard = ({
  bid,
}: {
  readonly bid: BidPageBrokerBidderActivityBidFragment;
}) => {
  const { t } = useTranslation();

  const contactEmail = getSecuritySpecialistContact(bid.listing.company);
  const companyName = bid.listing.company.name;

  return (
    <Card>
      <CardBody>
        <VStack spacing={4} alignItems="flex-start">
          <Text>
            {t(`broker_in_review_bid_security_specialist_card`, {
              companyName,
            })}
          </Text>
          <MailtoLink
            email={contactEmail}
            subject={t(`broker_bidder_bid_email_security_specialist_subject`, {
              companyName,
            })}
            fontWeight="medium"
          >
            {contactEmail}
          </MailtoLink>
        </VStack>
      </CardBody>
    </Card>
  );
};

export const BrokerBidderActivity = ({
  bid,
}: {
  readonly bid: BidPageBrokerBidderActivityBidFragment;
}) => {
  const { canPlaceStandingBid } = getAvailableCompanyActions(
    bid.listing.company,
  );

  const showStandingBidCTA =
    canPlaceStandingBid &&
    [BidState.InReview, BidState.Active, BidState.Countered].includes(
      bid.state,
    );

  return (
    <VStack spacing={6}>
      {showStandingBidCTA && <SubmitStandingBidCTACard bid={bid} />}
      {bid.state === BidState.InReview ? (
        <InReviewBidSecuritySpecialistCard bid={bid} />
      ) : (
        <LiveBidSecuritySpecialistCard bid={bid} />
      )}
    </VStack>
  );
};
