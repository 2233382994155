/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomEvents } from "./types";

export const useCustomEvent = () => {
  const dispatch = (event: CustomEvents, data?: any) => {
    dispatchEvent(new CustomEvent(event, { detail: data }));
  };

  return { dispatch };
};
