import { ReactNode } from "react";

import { HStack, ListItem, ListItemProps } from "@chakra-ui/react";

import { ComboboxItemProps } from "@/components/form";
import { CompanyComboboxCompanyFragment } from "@/gql";

interface ItemProps
  extends ListItemProps,
    ComboboxItemProps<CompanyComboboxCompanyFragment> {
  children: ReactNode;
}

export const Item = ({
  children,
  getItemProps,
  isDisabled,
  index,
  ...listItemProps
}: ItemProps) => (
  <ListItem
    {...getItemProps({ index, isDisabled })}
    minH={12}
    alignItems="center"
    px={5}
    {...listItemProps}
  >
    <HStack spacing={3} alignItems="center">
      {children}
    </HStack>
  </ListItem>
);
