import { ReactNode } from "react";

import { Td, Text } from "@chakra-ui/react";

export const StyledTd = ({ children }: { readonly children: ReactNode }) => (
  <Td>
    <Text textStyle="text-sm" color="grey.900">
      {children}
    </Text>
  </Td>
);
