import { Box, Link, Text } from "@chakra-ui/react";

import { constants } from "@/utils";

const UnaccreditedSellerCustomerAgreement = () => (
  <Box
    borderWidth="0.5px"
    borderColor="grey.200"
    borderRadius="md"
    p={4}
    bg="white"
    overflowX="auto"
  >
    <Text>
      I have read the{` `}
      <Link fontWeight="medium" href="/terms-and-conditions" target="_blank">
        Hiive (Seller Only) Terms and Conditions
      </Link>
      {` `}(the &quot;SO Terms&quot;), the{` `}
      <Link
        fontWeight="medium"
        href={`${constants.marketing_website_url}/privacy`}
        target="_blank"
      >
        Privacy Policy
      </Link>
      , and these{` `}
      <Link
        fontWeight="medium"
        href={`${constants.marketing_website_url}/disclosures`}
        target="_blank"
      >
        Disclaimers & Disclosures
      </Link>
      , and understand that these documents, along with the terms below, govern
      my use of Hiive&apos;s services and this platform and set out terms and
      conditions of Hiive’s provision of its services and this platform to me.
    </Text>
    <Text mt={2}>
      I understand that I can access Hiive&apos;s platform, review pricing
      information about the stock I hold or have the right to hold (&quot;My
      Stock&quot;), and post a listing for My Stock, without paying anything to
      Hiive. I understand that if I meet a buyer through the platform or Hiive
      or through the referral by another buyer on the platform, and close a
      transaction with that buyer, or a different buyer substituted for that
      buyer by operation of a &quot;right of first refusal&quot; or similar
      pre-emptive right of an issuer or another shareholder, I will be
      responsible to pay a transaction fee to Hiive, as detailed in the SO
      Terms.
    </Text>
    <Text mt={2}>
      I realize that my activities on the Hiive platform, and my interactions
      with potential buyers on the Hiive platform, are to be kept confidential,
      and that I am not permitted to share the name of any potential buyer with
      anyone else, including my colleagues and friends who may hold the same
      kind of stock, as detailed in the SO Terms.
    </Text>
    <Text mt={2}>
      I understand that Hiive is not an investment advisor and does not and will
      not provide recommendations or advice to me. I understand that selling
      stock through Hiive is a self-directed activity, and that it is entirely
      up to me to determine at what price to list, and ultimately sell my stock.
      I understand that I am not obligated to sell my stock, until I sign a
      share transfer notice with a buyer.
    </Text>
    <Text mt={2}>
      I understand that most private stock transactions are subject to the
      approval of the issuer (i.e. the company that issued my stock) and that
      some issuers will decline to approve proposed transactions, or some
      proposed transactions, and in some cases will do so without providing any
      reason for doing so.
    </Text>
  </Box>
);

export default UnaccreditedSellerCustomerAgreement;
