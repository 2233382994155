/* eslint-disable functional/prefer-readonly-type */
import { useEffect } from "react";

import { Center, Spinner } from "@chakra-ui/react";

import { useOnboardingCurrentActorQuery } from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

import { CanadaInstitutionAccreditationForm } from "./CanadaInstitutionAccreditationForm";
import { IndiaInstitutionAccreditationForm } from "./IndiaInstitutionAccreditationForm";
import { OtherInstitutionAccreditationForm } from "./OtherInstitutionAccreditationForm";
import { SwitzerlandInstitutionAccreditationForm } from "./SwitzerlandInstitutionAccreditationForm";
import { UKInstitutionAccreditationForm } from "./UKInstitutionAccreditationForm";
import { USInstitutionAccreditationForm } from "./USInstitutionAccreditationForm";

const useHasCompletedAccreditation = () => {
  const { data, loading } = useOnboardingCurrentActorQuery();

  if (loading || !data?.currentActor) return false;

  if (data?.currentActor?.institution?.country?.name === `CH`) {
    return data?.currentActor?.accreditationQuestions.length === 2;
  }

  return data?.currentActor?.accreditationQuestions?.length > 0;
};

const InstitutionAccreditationForm = () => {
  const hasCompletedAccreditation = useHasCompletedAccreditation();
  const routeToNextStep = useRouteToNextOnboardingStep();

  const { data, loading } = useOnboardingCurrentActorQuery();

  // Redirect the user if they've already answered questions, we don't want them to be able to answer again
  useEffect(() => {
    if (!hasCompletedAccreditation) return;
    routeToNextStep();
  }, []);

  if (loading || !data) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const { institution } = data.currentActor;

  if (!institution) return null;

  switch (institution.country.name) {
    case `US`:
      return <USInstitutionAccreditationForm />;
    case `CA`:
      return <CanadaInstitutionAccreditationForm />;
    case `IN`:
      return <IndiaInstitutionAccreditationForm />;
    case `CH`:
      return <SwitzerlandInstitutionAccreditationForm />;
    case `UK`:
      return <UKInstitutionAccreditationForm />;
    default:
      return (
        <OtherInstitutionAccreditationForm country={institution.country} />
      );
  }
};

export default InstitutionAccreditationForm;
