import { Text } from "@chakra-ui/react";

const TransactionDisclaimer = () => (
  <Text textStyle="text-sm" mb={8}>
    <Text as="strong" textTransform="uppercase">
      Disclaimer:{` `}
    </Text>
    {` `}
    This is a template on which transactions originated through Hiive are
    documented. Once both parties sign this document Hiive may provide this
    document to the issuer of the Securities in order to seek the issuer’s
    approval, as contemplated by the Customer Agreement and this document may
    create obligations to the parties with respect to the transaction
    contemplated herein. This document does not constitute legal advice to
    either party, and by providing this document to its customers, Hiive is not
    making any representations as to its suitability, enforceability, or
    completeness. Prior to executing this agreement, you should seek and retain
    professional legal counsel to represent you with respect to the transaction
    contemplated herein, and to review, and advise you on the effect of, this
    agreement.
  </Text>
);

export default TransactionDisclaimer;
