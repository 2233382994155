import { Text } from "@chakra-ui/react";

import IndividualAccreditationForm from "./IndividualAccreditationForm";

const IndividualAccreditationPage = () => (
  <>
    <Text
      my={7}
      align="center"
      textStyle="deprecated-text-xl"
      color="h-dark-grey"
    >
      What is your legal place of residence?
    </Text>
    <IndividualAccreditationForm />
  </>
);

export default IndividualAccreditationPage;
