import { ReactNode } from "react";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { InternalLink, Skeleton, WithQuery } from "@/components/common";
import {
  UnaccreditedSellerActiveBidNotificationsCount,
  UnaccreditedSellerInquiryNotificationsCount,
  UnaccreditedSellerPastBidNotificationsCount,
  UnaccreditedSellerStandingBidNotificationsCount,
  UnaccreditedSellerTransactionNotificationsCount,
} from "@/components/unaccredited-seller";
import { usePostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery } from "@/gql";

import { UnaccreditedDashboardRoutes } from "./types";

interface NavItemProps {
  readonly href: string;
  readonly children: ReactNode;
}

const NavItem = ({ href, children }: NavItemProps) => {
  const { asPath } = useRouter();
  const isActive = asPath === href;

  return (
    <InternalLink
      scroll={false}
      href={href}
      bg={isActive ? `grey.50` : `white`}
      borderColor={isActive ? `grey.100` : `white`}
      variant="menu"
    >
      {children}
    </InternalLink>
  );
};

const NavigationCardSkeleton = () => (
  <Card w="full">
    <CardHeader>
      <Skeleton h={4} maxW="40%" />
    </CardHeader>
    <CardBody>
      <VStack w="full" spacing={2}>
        <Skeleton h="41px" w="full" count={6} borderRadius="lg" />
      </VStack>
    </CardBody>
  </Card>
);

export const NavigationCard = () => {
  const query = usePostActivityUnaccreditedSellerDashboardPageSidebarNavigationCardQuery();

  return (
    <WithQuery query={query} fallback={<NavigationCardSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myCompany, myListing },
        },
      }) => (
        <Card w="full">
          <CardHeader>
            <Text textStyle="heading-sm">Activity</Text>
          </CardHeader>
          <CardBody>
            <VStack spacing={2} alignItems="flex-start">
              {!!myListing && (
                <NavItem href={UnaccreditedDashboardRoutes.ActiveBids}>
                  <HStack justifyContent="space-between">
                    <Text>Active Bids</Text>
                    <UnaccreditedSellerActiveBidNotificationsCount />
                  </HStack>
                </NavItem>
              )}
              {!!myListing && (
                <NavItem href={UnaccreditedDashboardRoutes.Inquiries}>
                  <HStack justifyContent="space-between">
                    <Text>Inquiries</Text>
                    <UnaccreditedSellerInquiryNotificationsCount />
                  </HStack>
                </NavItem>
              )}
              <NavItem href={UnaccreditedDashboardRoutes.StandingBids}>
                <HStack justifyContent="space-between">
                  <Text>{myCompany.name} Standing Bids</Text>
                  <UnaccreditedSellerStandingBidNotificationsCount />
                </HStack>
              </NavItem>
              <NavItem href={UnaccreditedDashboardRoutes.Transactions}>
                <HStack justifyContent="space-between">
                  <Text>Transactions</Text>
                  <UnaccreditedSellerTransactionNotificationsCount />
                </HStack>
              </NavItem>
              {!!myListing && (
                <NavItem href={UnaccreditedDashboardRoutes.PastBids}>
                  <HStack justifyContent="space-between">
                    <Text>Past Bids</Text>
                    <UnaccreditedSellerPastBidNotificationsCount />
                  </HStack>
                </NavItem>
              )}
              <NavItem href={UnaccreditedDashboardRoutes.Overview}>
                {myCompany.name} Overview
              </NavItem>
            </VStack>
          </CardBody>
        </Card>
      )}
    </WithQuery>
  );
};
