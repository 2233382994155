import { SensitiveText } from "@/components/common";
import {
  findHighestAcceptedBidSharePrice,
  findLowestAcceptedBidSharePrice,
  hasMultipleAcceptedBidSharePrices,
  hasSingleAcceptedBidSharePrice,
} from "@/components/companies";
import { formatPricePerShare, formatShares } from "@/utils";

const AcceptedBidSharePrices = ({
  acceptedBidPrices,
  numSharesSold,
}: {
  readonly acceptedBidPrices: readonly number[];
  readonly numSharesSold: number;
}) => {
  switch (true) {
    case hasSingleAcceptedBidSharePrice(acceptedBidPrices):
      return (
        <SensitiveText textStyle="text-xs" color="grey.700">
          {formatShares(numSharesSold)} shares accepted at
          {` `}
          <SensitiveText as="span" textStyle="heading-3xs" color="grey.900">
            {formatPricePerShare(acceptedBidPrices[0])}
            /sh
          </SensitiveText>
        </SensitiveText>
      );
    case hasMultipleAcceptedBidSharePrices(acceptedBidPrices):
      return (
        <SensitiveText textStyle="text-xs" color="grey.700">
          {formatShares(numSharesSold)} shares accepted from
          {` `}
          <SensitiveText as="span" textStyle="heading-3xs" color="grey.900">
            {formatPricePerShare(
              findLowestAcceptedBidSharePrice(acceptedBidPrices),
            )}
            /sh
          </SensitiveText>
          {` `}
          to{` `}
          <SensitiveText as="span" textStyle="heading-3xs" color="grey.900">
            {formatPricePerShare(
              findHighestAcceptedBidSharePrice(acceptedBidPrices),
            )}
            /sh
          </SensitiveText>
        </SensitiveText>
      );
    default:
      return null;
  }
};

export default AcceptedBidSharePrices;
