import { FormikContextType, FormikTouched } from "formik";

import { getTopOffset } from "./dims";

/**
 * Get all .chakra-form-control elements that have a descendant with `aria-invalid="true"`
 * e.g a field with an error
 */
export const getInvalidFormControlElements = () => {
  const fieldElements = [...document.querySelectorAll(`[aria-invalid="true"]`)];

  const formControlElements = fieldElements.flatMap((fieldElement) => {
    const formControlElement = fieldElement.closest(`.chakra-form-control`);
    if (!formControlElement) return [];

    return [formControlElement];
  });

  const sortedFormControlElements = formControlElements
    .slice()
    .sort((a, b) => getTopOffset(a) - getTopOffset(b));

  if (sortedFormControlElements.length === 0) return null;

  return sortedFormControlElements;
};

/*
 * You probably shouldn't be using this. Created to work around Formik's
 * submit behaviour of marking every field as touched. Remove on migration to RHF.
 */
export const resetTouchedState = <T>({
  fields,
  setTouched,
}: {
  readonly fields: FormikTouched<T>;
  readonly setTouched: FormikContextType<T>["setTouched"];
}) => {
  const updatedTouchedState = Object.keys(fields).reduce(
    (soFar, currentField) => ({ ...soFar, [currentField]: false }),
    {},
  );
  setTouched(updatedTouchedState);
};
