import React from "react";
import { useTranslation } from "react-i18next";

import { HStack, Image, Text } from "@chakra-ui/react";

const PoweredByModernTreasury = () => {
  const { t } = useTranslation();

  return (
    <HStack w="full" pt={4} justifyContent="center" alignItems="center">
      <Text textStyle="text-xs" color="grey.500">{t`powered_by`}</Text>
      <Image src="/assets/powered-by-mt.png" w={130} opacity={0.5} />
    </HStack>
  );
};

export default PoweredByModernTreasury;
