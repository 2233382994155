import { Th, Text, TableColumnHeaderProps } from "@chakra-ui/react";

interface StyledThProps extends TableColumnHeaderProps {
  readonly children?: string;
}

export const StyledTh = ({ children, ...props }: StyledThProps) => (
  <Th {...props}>
    <Text textStyle="heading-sm" color="grey.900">
      {children}
    </Text>
  </Th>
);
