import { Flex, Text, TextProps, VStack } from "@chakra-ui/react";

import { LongHyphen, SensitiveText } from "@/components/common";
import { CustomTooltip } from "@/components/form";
import { BrowseCompaniesPagePricingBlockCompanyFragment } from "@/gql";
import { Maybe } from "@/types";
import { formatPricePerShare } from "@/utils";

const PriceHeading = ({
  children,
  tooltip,
}: TextProps & {
  readonly children: React.ReactNode;
  readonly tooltip: React.ReactNode;
}) => (
  <CustomTooltip tooltipContent={tooltip} placement="top">
    <Text
      onClick={(event) => event.preventDefault()}
      display="inline-block"
      textStyle="heading-3xs"
      lineHeight="unset"
      textTransform="uppercase"
      borderBottom="0.5px dashed"
    >
      {children}
    </Text>
  </CustomTooltip>
);

const PriceValue = ({
  value,
  ...rest
}: TextProps & {
  readonly value: Maybe<number> | undefined;
}) => (
  <SensitiveText textStyle="heading-xs" {...rest}>
    {value ? formatPricePerShare(value) : <LongHyphen />}
  </SensitiveText>
);

const PricingBlock = ({
  company,
}: {
  readonly company: BrowseCompaniesPagePricingBlockCompanyFragment;
}) => (
  <Flex
    w="full"
    px={4}
    py={2}
    borderColor="grey.100"
    borderRadius="md"
    borderWidth={0.5}
    bg="grey.50"
    alignItems="flex-end"
    textAlign="center"
    justifyContent="space-between"
    gap={{ base: 2, md: 4 }}
  >
    <VStack minW="25%">
      <PriceHeading
        tooltip={`Bid is the highest priced bid on ${company.name} listings or ${company.name} standing bids that was open this week.`}
      >
        Highest Bid
      </PriceHeading>
      <PriceValue value={company.currentPrices.highestBid} />
    </VStack>

    <VStack minW="25%">
      <PriceHeading
        tooltip={`Ask is the lowest priced listing for ${company.name} that was open this week.`}
      >
        Lowest Ask
      </PriceHeading>
      <PriceValue value={company.currentPrices.lowestAsk} />
    </VStack>

    <VStack minW="25%">
      <PriceHeading
        tooltip={`The most recent price at which a bid was accepted for ${company.name}.`}
      >
        Last Transaction
      </PriceHeading>
      <PriceValue value={company.currentPrices.lastTransaction} />
    </VStack>
  </Flex>
);

export default PricingBlock;
