import { useField } from "formik";
import { FocusEvent, ReactNode, isValidElement } from "react";

import {
  FormControl,
  Input,
  InputProps,
  FormErrorMessage,
  FormHelperText,
  Flex,
  Box,
} from "@chakra-ui/react";

import { TooltipFormLabel } from ".";

export interface TextInputProps extends InputProps {
  /** The field name for the form */
  readonly name: string;
  /** The field label displayed above the input */
  readonly label?: ReactNode;
  /** Non-error text displayed below the input */
  readonly helperText?: string;
  /** Content for a tooltip that displays when hovering over a question mark icon */
  readonly tooltipContent?: ReactNode;
  /** The size of the tooltip that displays when hovering over the question mark icon */
  readonly tooltipSize?: string;
  /** A link to open on clicking the question mark tooltip trigger */
  readonly href?: string;
  /** A function to be called before setting the field value */
  readonly preSetValue?: (value: string) => string;
  /** An element to display on the right side of the input */
  readonly rightElement?: ReactNode;
  /** Placeholder text to display when the input is empty */
  readonly placeholder?: string;
}

const TextInput = ({
  name,
  label,
  helperText,
  tooltipContent,
  tooltipSize,
  isRequired,
  href,
  preSetValue,
  rightElement,
  ...props
}: TextInputProps) => {
  const [field, { touched, error }, { setValue }] = useField(name);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (!event?.target?.value) {
      setValue(``);
      return;
    }

    const value = preSetValue
      ? preSetValue(event.target.value)
      : event.target.value;

    setValue(value);
  };

  const value = !field?.value ? `` : field.value;

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      {isValidElement(label) ? (
        <Box pb={3}>{label}</Box>
      ) : (
        <TooltipFormLabel
          htmlFor={name}
          tooltipContent={tooltipContent}
          tooltipSize={tooltipSize}
          href={href}
        >
          {label}
          {isRequired ? ` *` : ``}
        </TooltipFormLabel>
      )}
      <Flex>
        <Input
          {...field}
          id={name}
          value={value}
          onBlur={handleBlur}
          maxLength={255}
          {...props}
        />
        {!!rightElement && rightElement}
      </Flex>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default TextInput;
