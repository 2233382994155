import { useTranslation } from "react-i18next";

import { CardFooter, Flex, Stack, Spacer, Text } from "@chakra-ui/react";

import {
  ConditionallyCompletedBadge,
  PartiallyAcceptedBadge,
  ExpiryDate,
  InquiriesBadge,
} from "@/components/companies";
import {
  CompanyYourActivityStandingBidCardStandingBidFragment,
  StandingBidState,
} from "@/gql";
import { toTimestamp } from "@/utils";

const InReviewState = ({
  standingBid,
}: {
  readonly standingBid: CompanyYourActivityStandingBidCardStandingBidFragment;
}) => {
  const { t } = useTranslation();

  return (
    <Text color="grey.700" textStyle="text-sm">
      {t(`standing_bid_in_review_status`, {
        date: toTimestamp(standingBid.insertedAt),
      })}
    </Text>
  );
};

const ExpireAtState = ({
  standingBid,
}: {
  readonly standingBid: CompanyYourActivityStandingBidCardStandingBidFragment;
}) =>
  !!standingBid.expireAt ? (
    <ExpiryDate expireAt={standingBid.expireAt} />
  ) : (
    <Spacer />
  );

const CompanyStandingBidCardFooter = ({
  standingBid,
}: {
  readonly standingBid: CompanyYourActivityStandingBidCardStandingBidFragment;
}) => {
  const {
    expireAt,
    state,
    acceptedBidPrices,
    numDiscussions,
    numUnreadMessages,
  } = standingBid;

  const hasExpirationDate = !!expireAt;

  const isPartiallyAccepted =
    state === StandingBidState.Open && acceptedBidPrices.length >= 1;

  const isConditionallyCompleted =
    state === StandingBidState.ConditionallyCompleted;

  const hasDiscussions = numDiscussions >= 1;

  const isInReview = state === StandingBidState.InReview;

  const hasFooter =
    hasExpirationDate ||
    isPartiallyAccepted ||
    isConditionallyCompleted ||
    hasDiscussions ||
    isInReview;

  if (!hasFooter) return null;

  return (
    <CardFooter>
      <Flex
        direction="row"
        justify="space-between"
        align="center"
        py={1}
        w="full"
      >
        {isInReview ? (
          <InReviewState standingBid={standingBid} />
        ) : (
          <ExpireAtState standingBid={standingBid} />
        )}
        <Stack
          direction={{ base: `column`, md: `row` }}
          wrap="wrap"
          align="flex-end"
          spacing={{ base: 2, md: 3 }}
        >
          {isPartiallyAccepted && <PartiallyAcceptedBadge />}
          {isConditionallyCompleted && <ConditionallyCompletedBadge />}
          {hasDiscussions && (
            <InquiriesBadge
              numInquiries={numDiscussions}
              numUnreadMessages={numUnreadMessages}
            />
          )}
        </Stack>
      </Flex>
    </CardFooter>
  );
};

export default CompanyStandingBidCardFooter;
