import { ShareDetails, ShareDetailsStats } from "@/components/common";
import { BidSellerRoundingDisclaimer } from "@/components/postings";
import { CounterBidModalBidFragment } from "@/gql";
import { getBidNumSharesActual, getPricePerShare } from "@/utils";

export const CounterBidModalBidShareDetails = ({
  bid,
}: {
  readonly bid: CounterBidModalBidFragment;
}) => {
  const actualNumberOfShares = getBidNumSharesActual(bid);
  const pricePerShare = getPricePerShare(bid);
  const isDisclaimerVisible = actualNumberOfShares !== bid.numShares;

  return (
    <ShareDetails variant="bid" title="Bid Details">
      <ShareDetailsStats
        numberOfShares={actualNumberOfShares}
        pricePerShare={pricePerShare}
      />
      {isDisclaimerVisible && (
        <BidSellerRoundingDisclaimer numSharesOriginal={bid.numShares} />
      )}
    </ShareDetails>
  );
};
