import { Button, ButtonProps, forwardRef } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";
import { useIsDesktop } from "@/hooks";

export interface HiiveButtonProps extends ButtonProps {
  /** A label for Sentry, to be set as the element's data-sentry attribute */
  readonly sentryLabel: string;
  readonly tooltipContent?: string;
}

const HiiveButton = forwardRef<HiiveButtonProps, typeof Button>(
  ({ sentryLabel, tooltipContent, ...props }: HiiveButtonProps) => {
    const isDesktop = useIsDesktop();

    return tooltipContent && isDesktop ? (
      <CustomTooltip tooltipContent={tooltipContent}>
        <Button data-sentry={sentryLabel} {...props} />
      </CustomTooltip>
    ) : (
      <Button data-sentry={sentryLabel} {...props} />
    );
  },
);

HiiveButton.displayName = `HiiveButton`;

export default HiiveButton;
