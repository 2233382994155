import en from "./en";
import account from "./en/account";
import { LocaleKey, TranslationKey, Resources } from "./types";

export const locales: Record<LocaleKey, Resources> = {
  en: {
    account,
    translation: en,
  },
};

export type { TranslationKey, Resources };
