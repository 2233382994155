import { useFlows } from "@frigade/react";
import { X } from "@phosphor-icons/react";
import { useEffect } from "react";

import {
  Box,
  Flex,
  IconButton,
  Link,
  LinkProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FlowKind } from "@/components/providers";
import { useColors, useFlowLauncher } from "@/hooks";

const UnstyledLink = (props: LinkProps) => (
  <Link {...props} variant="unstyled" />
);

interface UnaccreditedSellerOverviewVideoFlowSchema {
  readonly title: string;
  readonly subtitle: string;
  readonly primaryButtonTitle: string;
  readonly primaryButtonUri: string;
  readonly primaryButtonTarget: string;
  readonly loomVideoUrl: string;
}

const UnaccreditedSellerOverviewVideoFlow = ({
  flowKind,
}: {
  readonly flowKind: FlowKind;
}) => {
  const {
    getFlowData,
    isLoading,
    markFlowNotStarted,
    markFlowCompleted,
    getFlowStatus,
  } = useFlows();

  const { dismissFlows, getFlowID } = useFlowLauncher();

  const flowId = getFlowID(flowKind);
  const flowStatus = getFlowStatus(flowId);

  const [grey900] = useColors([`grey.900`]);

  useEffect(() => {
    if (!isLoading && [`STARTED_FLOW`, `COMPLETED_FLOW`].includes(flowStatus)) {
      markFlowNotStarted(flowId);
    }
  }, [isLoading]);

  const onClose = () => {
    markFlowCompleted(flowId);
    dismissFlows();
  };

  if (isLoading) {
    return null;
  }

  const {
    title,
    subtitle,
    primaryButtonTitle,
    primaryButtonUri,
    primaryButtonTarget,
    loomVideoUrl,
  } = getFlowData<UnaccreditedSellerOverviewVideoFlowSchema>(flowId);

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minW={{ sm: 500, lg: 800 }} borderRadius="md">
        <ModalBody
          borderTopRadius="md"
          px={{ base: 8, md: 12 }}
          pt={{ base: 8, md: 10 }}
          pb={8}
        >
          <IconButton
            position="absolute"
            aria-label="Close modal"
            onClick={onClose}
            variant="icon-close"
            right={4}
            top={6}
            icon={<X size={24} color={grey900} />}
          />

          <Flex direction="column" gap={{ base: 4, md: 6 }}>
            <VStack alignItems="flex-start" spacing={4}>
              <Text
                color="grey.900"
                as="h1"
                pr={4}
                textStyle={{ base: `heading-3xl`, lg: `heading-4xl` }}
              >
                {title}
              </Text>
              {/* Loom video embed - common padding workaround for responsive iframe */}
              <Box position="relative" paddingBottom="62.5%" h={0} w="full">
                <Box
                  as="iframe"
                  w="full"
                  h="full"
                  title={title}
                  allowFullScreen
                  position="absolute"
                  top={0}
                  left={0}
                  frameBorder={0}
                  src={loomVideoUrl}
                />
              </Box>
              <Text textStyle="text-md">{subtitle}</Text>
            </VStack>
            <HiiveButton
              as={UnstyledLink}
              variant="rounded-solid-salmon"
              size="xl"
              maxW="fit-content"
              target={primaryButtonTarget}
              href={primaryButtonUri}
              sentryLabel="[UnaccreditedSellerOverviewVideoFlow] CTA"
            >
              {primaryButtonTitle}
            </HiiveButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UnaccreditedSellerOverviewVideoFlow;
