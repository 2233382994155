import { useTranslation } from "react-i18next";

import { Flex } from "@chakra-ui/react";

import { FocusedShareDetails, ShareDetailsStats } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  BidInfoCardStatusTile,
  AcceptedBidDetails,
  DocumentActions,
  BidSellerRoundingDisclaimer,
} from "@/components/postings";
import {
  BrokerTransactionInfoCardBidFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCurrentActor, useDocumentSigners } from "@/hooks";
import { getIsBrokerForBid, getShowDocumentActions } from "@/utils";
import { getBidNumSharesActual, getIsBuyerForBid } from "@/utils/bid";

import {
  BidBuyerRoundingDisclaimer,
  BrokerSellerTransactionFeeBreakdown,
} from "./shared";

const RoundingDisclaimer = withCurrentActor(
  ({
    bid,
    actor,
  }: {
    readonly bid: BrokerTransactionInfoCardBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isBuyer = getIsBuyerForBid(actor, bid);
    const isBuySideBroker = getIsBrokerForBid({ user: actor, bid });

    return isBuyer || isBuySideBroker ? (
      <BidBuyerRoundingDisclaimer numSharesOriginal={bid.numShares} />
    ) : (
      <BidSellerRoundingDisclaimer numSharesOriginal={bid.numShares} />
    );
  },
);

const BrokerTransactionInfoCard = ({
  bid,
}: {
  readonly bid: BrokerTransactionInfoCardBidFragment;
}) => {
  const actor = useCurrentActor();

  const { transaction } = bid;

  const isBuySideBroker = getIsBrokerForBid({ user: actor, bid });

  const { t } = useTranslation();

  const {
    canSignTransactionDocument,
    canInviteAlternateSigner,
    canRevokeAlternateSignerInvitation,
    haveISigned,
  } = useDocumentSigners(transaction);

  if (!transaction) return null;

  const { company, numShares, pricePerShare } = transaction;

  const shouldRenderDocumentsFooterActions = getShowDocumentActions({
    bid,
    actor,
    haveISigned,
    canInviteAlternateSigner,
    canSignTransactionDocument,
    canRevokeAlternateSignerInvitation,
  });

  const isRoundingDisclaimerVisible =
    getBidNumSharesActual(bid) !== bid.numShares;

  return (
    <Flex direction="column" gap={7} data-testid="bid-info-card">
      <FocusedShareDetails.Card variant="transaction">
        <FocusedShareDetails.Header
          title={t(`transaction_title`, { transactionId: bid.displayId })}
          company={company}
        >
          <FocusedShareDetails.HeaderCard>
            <ShareDetailsStats
              numberOfShares={numShares}
              pricePerShare={pricePerShare}
            />
          </FocusedShareDetails.HeaderCard>
          {isRoundingDisclaimerVisible && <RoundingDisclaimer bid={bid} />}
        </FocusedShareDetails.Header>
        <FocusedShareDetails.Content>
          <FocusedShareDetails.ContentSection>
            <Flex direction="column" gap={6}>
              <BidInfoCardStatusTile bid={bid} showCounterBidDetails={false} />
              {!isBuySideBroker && (
                <BrokerSellerTransactionFeeBreakdown
                  transaction={transaction}
                />
              )}
              <AcceptedBidDetails bid={bid} />
            </Flex>
          </FocusedShareDetails.ContentSection>
          {shouldRenderDocumentsFooterActions && (
            <FocusedShareDetails.ContentFooter
              flexDirection="column"
              justifyContent="flex-end"
            >
              <DocumentActions bid={bid} />
            </FocusedShareDetails.ContentFooter>
          )}
        </FocusedShareDetails.Content>
      </FocusedShareDetails.Card>
    </Flex>
  );
};

export default BrokerTransactionInfoCard;
