import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Flex, Text, VStack } from "@chakra-ui/react";

import {
  ActivityCardSkeleton,
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  Skeleton,
  WithQuery,
} from "@/components/common";
import {
  PostActivityUnaccreditedSellerDashboardPageInquiriesPageDiscussionFragment,
  usePostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery,
} from "@/gql";

import { InquiryCard } from "./InquiryCard";

const InquiriesEmptyState = () => {
  const { t } = useTranslation();

  return <ActivityGroupEmptyState message={t(`no_active_discussions`)} />;
};

const Inquiries = ({
  discussions,
}: {
  readonly discussions: readonly PostActivityUnaccreditedSellerDashboardPageInquiriesPageDiscussionFragment[];
}) => (
  <VStack alignItems="flex-start" spacing={4}>
    <Text textStyle="heading-sm" textTransform="uppercase">
      Inquiries
    </Text>
    <Text textStyle="text-md">
      Click on the inquiry to open the message and respond.
    </Text>
    <ActivityGroup emptyState={<InquiriesEmptyState />}>
      {discussions.map((discussion) => (
        <InquiryCard key={discussion.id} discussion={discussion} />
      ))}
    </ActivityGroup>
  </VStack>
);

const InquiriesPageSkeleton = () => (
  <Flex direction="column" gap={4} w="full" pt={{ base: 4, lg: 8 }}>
    <Skeleton h="20px" maxW="20%" />
    <Skeleton h="16px" maxW="40%" />
    <ActivityCardSkeleton />
    <ActivityCardSkeleton />
  </Flex>
);

const InquiriesPage = () => {
  const query = usePostActivityUnaccreditedSellerDashboardPageInquiriesPageQuery();

  const router = useRouter();

  return (
    <WithQuery query={query} fallback={<InquiriesPageSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myListing },
          unaccreditedSellerMyListingActivity: { preBidDiscussions },
        },
      }) => {
        if (!myListing) {
          router.replace(`/dashboard`);
          return null;
        }

        return (
          <ActivitySection pt={{ base: 4, lg: 8 }}>
            <Inquiries discussions={preBidDiscussions} />
          </ActivitySection>
        );
      }}
    </WithQuery>
  );
};

export default InquiriesPage;
