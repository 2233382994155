import {
  CreateTransactingEntityModal,
  CreateTransactingEntityModalProps,
  CreateTransactingEntityModalTypes,
} from "@/components/modals";

type CreateTransactingEntitySellerModalProps = Omit<
  CreateTransactingEntityModalProps,
  "type"
>;

const CreateTransactingEntitySellerModal = (
  props: CreateTransactingEntitySellerModalProps,
) => (
  <CreateTransactingEntityModal
    type={CreateTransactingEntityModalTypes.Seller}
    {...props}
  />
);

export default CreateTransactingEntitySellerModal;
