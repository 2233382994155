import { HStack, VStack, Text, Image, StackProps } from "@chakra-ui/react";

import { ActivityCardCompanyFragment } from "@/gql";

interface ActivityCardCompanyProps extends StackProps {
  readonly company: ActivityCardCompanyFragment;
}

const ActivityCardCompany = ({
  company,
  ...stackProps
}: ActivityCardCompanyProps) => (
  <HStack {...stackProps}>
    {company.logoUrl && (
      <VStack h="6" minW="6">
        <Image h="full" src={company.logoUrl} alt={company.name} />
      </VStack>
    )}
    <Text textStyle={{ base: `heading-md`, sm: `heading-lg` }}>
      {company.name}
    </Text>
  </HStack>
);

export default ActivityCardCompany;
