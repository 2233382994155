import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";

import { InvestorAccreditationForm } from "./InvestorAccreditationForm";

const InvestorAccreditationPageV2 = () => {
  const { t } = useTranslation();

  return (
    <OnboardingContainerV2 canGoBack metaTitle="Investor Accreditation">
      <Flex direction="column">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`investor_accreditation_title`)}*
          </Text>
          <VStack gap={1} alignItems="flex-start">
            <Text>{t`investor_accreditation_description`}</Text>
            <Text>{t`investor_accreditation_description_two`}</Text>
          </VStack>
        </VStack>

        <InvestorAccreditationForm />
      </Flex>
    </OnboardingContainerV2>
  );
};

export default InvestorAccreditationPageV2;
