import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Link,
  LinkProps,
  Text,
} from "@chakra-ui/react";

import { MailtoLink, Skeleton, WithQuery } from "@/components/common";
import {
  PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesCompanyFragment,
  usePostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery,
} from "@/gql";
import { getCC, getMarketAnalystContact } from "@/utils";

const InformativeArticlesSkeleton = () => (
  <Card w="full" variant="darkened-footer">
    <CardHeader>
      <Skeleton h={4} maxW="40%" />
    </CardHeader>
    <CardBody>
      <Flex direction="column" gap={4}>
        <Flex direction="column" gap={2}>
          <Skeleton h={4} />
          <Skeleton h={4} maxW="25%" />
        </Flex>
        <Skeleton h={4} maxW="45%" />
        <Skeleton h={4} maxW="80%" />
      </Flex>
    </CardBody>
    <CardFooter>
      <Flex direction="column" gap={4} w="full">
        <Skeleton h={4} />
        <Skeleton h={4} maxW="25%" />
        <Skeleton h={4} maxW="65%" />
      </Flex>
    </CardFooter>
  </Card>
);

const InformativeArticlesCardContent = ({
  company,
  children,
}: {
  readonly company: PostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesCompanyFragment;
  readonly children: ReactNode;
}) => {
  const { t } = useTranslation();
  const marketAnalystEmail = getMarketAnalystContact(company);
  const cc = getCC(company, true);

  return (
    <Card w="full" variant="darkened-footer">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`informative_articles`)}</Text>
      </CardHeader>
      <CardBody>
        <Flex direction="column" gap={4}>
          {children}
        </Flex>
      </CardBody>
      <CardFooter>
        <Flex direction="column" gap={4}>
          <Text>{t(`for_assistance`)}:</Text>
          <MailtoLink
            email={marketAnalystEmail}
            cc={cc}
            subject={`Contact ${company.name} Security Specialist`}
            fontWeight="medium"
          >
            {marketAnalystEmail}
          </MailtoLink>
        </Flex>
      </CardFooter>
    </Card>
  );
};

const InformativeArticlesCard = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const query = usePostActivityUnaccreditedSellerDashboardPageSidebarInformativeArticlesQuery();

  return (
    <WithQuery query={query} fallback={<InformativeArticlesSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myCompany },
        },
      }) => {
        if (!myCompany) return null;

        return (
          <InformativeArticlesCardContent company={myCompany}>
            {children}
          </InformativeArticlesCardContent>
        );
      }}
    </WithQuery>
  );
};

const InformativeArticleLink = ({
  href,
  children,
  ...linkProps
}: LinkProps) => (
  <Link target="_blank" textDecoration="underline" href={href} {...linkProps}>
    {children}
  </Link>
);

export default {
  Card: InformativeArticlesCard,
  Link: InformativeArticleLink,
  Skeleton: InformativeArticlesSkeleton,
};
