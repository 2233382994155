/* eslint-disable no-console */
import { WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, ModalBody, Text, VStack } from "@chakra-ui/react";

import { GqlError } from "@/apollo-client";
import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CurrentContextDocument,
  useUpdateUserMultiFactorAuthenticationMutation,
  UserMultiFactorAuthenticationMethod,
  UserMultiFactorAuthenticationStatus,
} from "@/gql";
import { useModal } from "@/hooks";
import { useDisableAppMfa } from "@/hooks/frontegg";
import { GqlErrorCodes } from "@/types";

const DisableAppMfaModal = () => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();
  const [disableMfa, { loading, error }] = useDisableAppMfa();
  const [
    updateUserMultiFactorAuthentication,
  ] = useUpdateUserMultiFactorAuthenticationMutation({
    refetchQueries: [CurrentContextDocument],
  });
  const [pinValue, setPinValue] = useState<string>(``);

  const handleComplete = async (value: string) => {
    const variables = {
      input: {
        method: UserMultiFactorAuthenticationMethod.App,
        status: UserMultiFactorAuthenticationStatus.PendingInactive,
      },
    };

    const result = await updateUserMultiFactorAuthentication({
      variables,
      errorPolicy: `all`,
    });

    const [mutationError] = result.errors || [];

    const mfaUnenrollmentPending =
      result.data?.updateUserMultiFactorAuthentication
        ?.userMultiFactorAuthentication ||
      (mutationError as GqlError).code ===
        GqlErrorCodes.MFA_UNENROLLMENT_PENDING;

    if (mfaUnenrollmentPending) {
      const ok = await disableMfa(value);

      if (ok) {
        onOpenModal(modals.disableAppMfaSuccess())();
      }
    }
  };

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`remove_authenticator_app`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="flex-start" gap={4}>
          <Text>
            <Trans
              i18nKey="remove_authenticator_app_description"
              t={t}
              components={{
                a: (
                  <Button
                    as="span"
                    cursor="pointer"
                    fontWeight={400}
                    textDecoration="underline"
                    variant="text-ghost"
                    whiteSpace="none"
                    onClick={() => console.log(`open account recovery modal`)}
                  />
                ),
              }}
            />
          </Text>

          <CodeInput
            disabled={loading}
            onChange={(value: string) => setPinValue(value)}
            onComplete={handleComplete}
          />
          {error && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{t(`incorrect_code`)}</Text>
            </CallOut>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveSubmitButton
            isDisabled={pinValue.length !== 6}
            sentryLabel="[DisableAppMfa/Submit]"
            submitText={t(`verify_and_remove`)}
            type="submit"
            onClick={() => handleComplete(pinValue)}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default DisableAppMfaModal;
