import { Text, HStack } from "@chakra-ui/react";

import {
  ShareDetailsStatsWrapper,
  ShareDetailsPricePerShareStat,
  ShareDetailsStat,
  ShareDetailsStats,
} from "@/components/common";
import { NumShareAndPriceDetailsListingFragment } from "@/gql";
import {
  formatShares,
  lot,
  getIsPartiallySoldListing,
  getIsConditionallySoldListing,
  getListingNumSharesAvailable,
  getListingNumSharesOriginal,
} from "@/utils";

const ListingNumShareAndPriceDetails = ({
  listing,
}: {
  readonly listing: NumShareAndPriceDetailsListingFragment;
}) => {
  const { listingPricePerShare: pricePerShare } = listing;

  const numSharesOriginal = getListingNumSharesOriginal(listing);
  const numSharesAvailable = getListingNumSharesAvailable(listing);

  switch (true) {
    case getIsPartiallySoldListing(listing):
      return (
        <ShareDetailsStatsWrapper>
          <ShareDetailsStat title="Shares">
            <HStack>
              <Text as="del" color="grey.200">
                {formatShares(numSharesOriginal)}
              </Text>
              <Text>{formatShares(numSharesAvailable)}</Text>
            </HStack>
          </ShareDetailsStat>
          <ShareDetailsPricePerShareStat pricePerShare={pricePerShare} />
          <ShareDetailsStat title="Total">
            {pricePerShare && numSharesAvailable
              ? lot(numSharesAvailable, pricePerShare)
              : `-`}
          </ShareDetailsStat>
        </ShareDetailsStatsWrapper>
      );
    case getIsConditionallySoldListing(listing):
      return (
        <ShareDetailsStatsWrapper>
          <ShareDetailsStat title="Shares">
            <Text as="del" color="grey.200">
              {formatShares(numSharesOriginal)}
            </Text>
          </ShareDetailsStat>
          <ShareDetailsPricePerShareStat
            strikethrough
            pricePerShare={pricePerShare}
          />
          <ShareDetailsStat title="Total">
            <Text as="del" color="grey.200">
              {pricePerShare ? lot(numSharesOriginal, pricePerShare) : `-`}
            </Text>
          </ShareDetailsStat>
        </ShareDetailsStatsWrapper>
      );

    default:
      return (
        <ShareDetailsStats
          numberOfShares={numSharesOriginal}
          pricePerShare={pricePerShare}
        />
      );
  }
};

export default ListingNumShareAndPriceDetails;
