import { CheckboxProps, Checkbox } from "@chakra-ui/react";

import styles from "./CustomCheckbox.module.css";

const CustomCheckbox = ({ children, spacing, ...props }: CheckboxProps) => (
  <Checkbox
    size="lg"
    className={styles.checkbox}
    spacing={spacing}
    alignItems="flex-start"
    colorScheme="white"
    iconColor="h-dark-grey"
    {...props}
  >
    {children}
  </Checkbox>
);

export default CustomCheckbox;
