import { useField } from "formik";
import React from "react";

import { VStack, HStack } from "@chakra-ui/react";

import YesNoRadioInput, { YesNoRadioInputProps } from "./YesNoRadioInput";

interface ConditionalInputProps extends YesNoRadioInputProps {
  readonly name: string;
  readonly label: string;
  readonly horizontal?: boolean;
}

/**
 * @deprecated use <FormRadioInput/>
 *
 * A Yes/No Radio Input, rendered with details component, when the value is true
 */
const ConditionalInput = ({
  name,
  label,
  horizontal = false,
  ...props
}: ConditionalInputProps) => {
  const [field] = useField(name);

  return horizontal ? (
    <HStack width="full" spacing="0">
      <YesNoRadioInput name={name} label={label} {...props} />
      {field.value}
    </HStack>
  ) : (
    <VStack width="full" spacing="3">
      <YesNoRadioInput name={name} label={label} {...props} />
      {field.value}
    </VStack>
  );
};

export default ConditionalInput;
