import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CardBody,
  Text,
  VStack,
  SimpleGrid,
  FormLabel,
  GridItem,
  CardFooter,
  HStack,
  Button,
  CardHeader,
  Card,
} from "@chakra-ui/react";

import { EMAIL_REGEX } from "@/components/auth";
import {
  FormPhoneNumberInput,
  FormRadioTile,
  FormTextInput,
  TileCheckboxInput,
} from "@/components/react-hook-form";
import {
  useCreateTrustedContactPersonMutation,
  useUpdateTrustedContactPersonMutation,
  CreateTrustedContactPersonInput,
  TrustedContactPerson,
  UpdateTrustedContactPersonInput,
  CurrentContextDocument,
} from "@/gql";
import { useCustomToast } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { validPhoneNumber } from "@/utils";

type TrustedContactPersonFormValues = {
  readonly collectTrustedContactPerson: boolean | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly phoneNumber: string;
  readonly email: string;
  readonly relationship: string;
};

type TrustedContactPersonFormProps = {
  readonly isUpdating?: boolean;
  readonly trustedContactPerson?: TrustedContactPerson;
  readonly onGoBack?: () => void;
};

const mapVariables = (
  values: TrustedContactPersonFormValues,
): {
  input: CreateTrustedContactPersonInput | UpdateTrustedContactPersonInput;
} => ({
  input: {
    isContactProvided: !!values.collectTrustedContactPerson,
    contactInfo: !!values.collectTrustedContactPerson
      ? {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          relationship: values.relationship,
        }
      : null,
  },
});

export const TrustedContactPersonForm = ({
  isUpdating,
  trustedContactPerson,
  onGoBack,
}: TrustedContactPersonFormProps) => {
  const { t } = useTranslation();
  const { successToast } = useCustomToast();
  const createMutation = useCreateTrustedContactPersonMutation({
    refetchQueries: [CurrentContextDocument],
  });
  const updateMutation = useUpdateTrustedContactPersonMutation({
    refetchQueries: [CurrentContextDocument],
  });
  const mutation = isUpdating ? updateMutation : createMutation;

  const validationSchema = Yup.object().shape({
    collectTrustedContactPerson: Yup.boolean().required(t`required`),
    firstName: Yup.string()
      .when(`collectTrustedContactPerson`, {
        is: true,
        then: (schema) => schema.required(t`required`),
        otherwise: (schema) => schema.optional(),
      })
      .typeError(t`required`),
    lastName: Yup.string()
      .when(`collectTrustedContactPerson`, {
        is: true,
        then: (schema) => schema.required(t`required`),
        otherwise: (schema) => schema.optional(),
      })
      .typeError(t`required`),
    phoneNumber: Yup.string()
      .test({
        name: `isPhoneNumberRequired`,
        message: t`enter_valid_phone_number`,
        // eslint-disable-next-line func-names, object-shorthand
        test: function (value) {
          // eslint-disable-next-line react/no-this-in-sfc
          const isCollectingTrustedContactPerson = this.parent
            .collectTrustedContactPerson;

          return isCollectingTrustedContactPerson
            ? !!value && validPhoneNumber(value)
            : true;
        },
      })
      .typeError(t`enter_valid_phone_number`),
    email: Yup.string()
      .when(`collectTrustedContactPerson`, {
        is: true,
        then: (schema) =>
          schema
            .matches(EMAIL_REGEX, t`invalid_email_address`)
            .required(t`required`),
        otherwise: (schema) => schema.optional(),
      })
      .typeError(t`invalid_email_address`),
    relationship: Yup.string()
      .when(`collectTrustedContactPerson`, {
        is: true,
        then: (schema) => schema.required(t`required`),
        otherwise: (schema) => schema.optional(),
      })
      .typeError(t`required`),
  });

  const { handleSubmit, control, watch, formState } = useFormQL({
    // @ts-expect-error due to dynamic mutation
    mutation,
    initialValues:
      trustedContactPerson && isUpdating
        ? {
            collectTrustedContactPerson: trustedContactPerson.isContactProvided,
            ...trustedContactPerson.contactInfo,
          }
        : {
            collectTrustedContactPerson: null,
          },
    mapVariables,
    validationSchema,
    mode: `onBlur`,
    onSuccess: () => {
      if (isUpdating) {
        successToast(t`updated_trusted_contact_person`);
        onGoBack?.();
        return;
      }
      successToast(t`saved_trusted_contact_person`);
    },
  });

  const shouldShowTrustedPersonForm = !!watch(`collectTrustedContactPerson`);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Card variant="darkened-footer" w="full" flex="1">
        <CardHeader>
          <Text textStyle="heading-sm">{t(`trusted_contact_person`)}</Text>
        </CardHeader>

        <CardBody p={{ base: 4, lg: 10 }}>
          <VStack spacing={4} alignItems="flex-start">
            <Text textStyle="heading-lg">
              <Trans
                i18nKey="add_trusted_contact_person"
                components={{ italic: <i /> }}
              />
              {` `}*
            </Text>
            <TileCheckboxInput.Tooltip>
              <VStack spacing={4} alignItems="flex-start" px={2}>
                <Text>{t`add_trusted_contact_person_disclaimer`}</Text>
                <Text>{t`add_trusted_contact_person_disclaimer_continued`}</Text>
              </VStack>
            </TileCheckboxInput.Tooltip>
            <SimpleGrid
              gridTemplateColumns="1fr 1fr"
              rowGap={6}
              width={{ base: `full`, lg: 144 }}
            >
              <GridItem colSpan={2}>
                <FormRadioTile.Group
                  size="md"
                  gridTemplateColumns={{ base: `1fr`, lg: `1fr 1fr` }}
                >
                  <FormRadioTile.Tile
                    control={control}
                    name="collectTrustedContactPerson"
                    value
                    alignItems="center"
                  >
                    <Text textStyle="text-md">{t`yes`}</Text>
                  </FormRadioTile.Tile>
                  <FormRadioTile.Tile
                    control={control}
                    name="collectTrustedContactPerson"
                    value={false}
                    alignItems="center"
                  >
                    <Text textStyle="text-md">{t`no`}</Text>
                  </FormRadioTile.Tile>
                </FormRadioTile.Group>
              </GridItem>
              {shouldShowTrustedPersonForm && (
                <>
                  <GridItem colSpan={2}>
                    <FormLabel>{t`name`} *</FormLabel>
                    <SimpleGrid
                      gridTemplateColumns={{ base: `1fr`, lg: `1fr 1fr` }}
                      rowGap={{ base: 2, lg: 0 }}
                      columnGap={2}
                    >
                      <GridItem colSpan={1}>
                        <FormTextInput
                          control={control}
                          name="firstName"
                          placeholder={t`first`}
                          label=""
                          labelSrOnly
                        />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <FormTextInput
                          control={control}
                          name="lastName"
                          placeholder={t`last`}
                          label=""
                          labelSrOnly
                        />
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>

                  <GridItem colSpan={2}>
                    <FormPhoneNumberInput
                      control={control}
                      isRequired
                      name="phoneNumber"
                      label={t(`telephone`)}
                    />
                  </GridItem>

                  <GridItem colSpan={2}>
                    <FormTextInput
                      type="email"
                      isRequired
                      name="email"
                      label={t(`email`)}
                      placeholder={t(`email_address`)}
                      control={control}
                    />
                  </GridItem>

                  <GridItem colSpan={2}>
                    <FormTextInput
                      isRequired
                      name="relationship"
                      label={t(`relationship`)}
                      placeholder={t(`relationship_placeholder`)}
                      control={control}
                    />
                  </GridItem>
                </>
              )}
            </SimpleGrid>
          </VStack>
        </CardBody>
        <CardFooter>
          <HStack gap={4} justifyContent="flex-end" w="full">
            {isUpdating && (
              <Button
                onClick={onGoBack}
                variant="rounded-outline-grey"
                size="xl"
                w={{ base: `full`, lg: 180 }}
                minW="auto"
              >
                {t(`cancel`)}
              </Button>
            )}
            <Button
              type="submit"
              variant="rounded-solid-salmon"
              size="xl"
              isDisabled={!formState?.isValid}
              w={{ base: `full`, lg: 180 }}
              minW="auto"
            >
              {t(`save`)}
            </Button>
          </HStack>
        </CardFooter>
      </Card>
    </form>
  );
};
