import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(radioAnatomy.keys);

const variants = {
  base: definePartsStyle({
    control: {
      cursor: `pointer`,
      borderColor: `grey.200`,
      borderWidth: 2,
      transition: `.1s all`,
      bg: `white`,
      _invalid: {
        color: `red.500`,
      },
      _hover: {
        borderColor: `grey.100`,

        _checked: {
          borderColor: `grey.900`,
        },
      },
      _checked: {
        bg: `grey.900`,
        borderColor: `grey.900`,
        color: `white`,
        _hover: {
          bg: `grey.900`,
        },
      },
    },
    container: {
      textStyle: `text-md`,
    },
  }),
};

export const Radio = defineMultiStyleConfig({
  baseStyle: {},
  defaultProps: {
    size: `lg`,
  },
  variants,
});
