import { Flex } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import {
  CompanyLastRoundDetailsCard,
  PricingChartWrapper,
} from "@/components/companies";
import { useCompanyPageInfoCompanyByIdQuery } from "@/gql";

import { DescriptionCardV2 } from "./DescriptionCardV2";
import { InfoSkeleton } from "./InfoSkeleton";

const InfoV2 = ({ id }: { id: string }) => {
  const query = useCompanyPageInfoCompanyByIdQuery({
    variables: { id },
  });

  return (
    <WithQuery query={query} fallback={<InfoSkeleton />}>
      {({ data: { companyById: company } }) => (
        <Flex direction="column" gap={4}>
          <DescriptionCardV2 company={company} />
          {company.priceChartConfig.displayChart && (
            <PricingChartWrapper company={company} />
          )}
          <CompanyLastRoundDetailsCard company={company} />
        </Flex>
      )}
    </WithQuery>
  );
};

export default InfoV2;
