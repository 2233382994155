import { useField } from "formik";
import { DependencyList, useEffect } from "react";

const useResetField = (name: string, deps?: DependencyList | undefined) => {
  const [_field, _, { setValue, setError, setTouched }] = useField(name);
  useEffect(() => {
    setValue([]);
    setError(null);
    setTouched(false);
  }, deps);
};

export default useResetField;
