import { ReactNode } from "react";

import { Box } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { useObscureCompanyInfo } from "@/hooks";

/**
 * Children wrapped in `KYCSensitive` will be blurred depending on the actors KYC status
 *
 * Rules for determining whether or not to blur the content are defined in `useObscureCompanyInfo`
 */
const KYCSensitive = withCurrentActor(
  ({
    children,
    actor,
  }: {
    readonly children: ReactNode;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { showInfo, message } = useObscureCompanyInfo(actor);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (showInfo) return <>{children}</>;

    return (
      <CustomTooltip tooltipContent={message}>
        <Box cursor="default" layerStyle="sensitive" data-testid="blurred-text">
          {children}
        </Box>
      </CustomTooltip>
    );
  },
);

export default KYCSensitive;
