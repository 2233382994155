import { useField } from "formik";
import { useState } from "react";

import { VStack } from "@chakra-ui/react";

import { CustomRadio } from "@/components/common";
import {
  AccreditationRadioInput,
  AccreditationTextAreaInput,
} from "@/components/form";
import { QuestionFormCard } from "@/components/onboarding";

export const IndiaInstitutionAccreditationFields = () => {
  const [_field, _, { setValue: setAnswers }] = useField(`answers`);

  const [radioValue, setRadioValue] = useState<string | undefined>();
  const [textAreaValue, setTextAreaValue] = useState<string>(``);

  const onChangeRadioValue = (value?: string) => {
    setRadioValue(value);
    if (!value) {
      setAnswers([]);
      return;
    }
    if (value === `in-institutional-other`) {
      setAnswers([{ key: value, text: textAreaValue }]);
      return;
    }

    setAnswers([{ key: value }]);
  };

  const onChangeTextAreaValue = (value: string) => {
    setTextAreaValue(value);
    if (radioValue !== `in-institutional-other`) return;
    setAnswers([{ key: `in-institutional-other`, text: value }]);
  };

  return (
    <VStack>
      <QuestionFormCard
        text="How is the firm / fund accredited?
      "
      >
        <AccreditationRadioInput
          value={radioValue}
          onChange={onChangeRadioValue}
        >
          <VStack alignItems="flex-start">
            <CustomRadio value="in-institutional-qualified-buyer">
              The firm/fund is a qualified institutional buyer or managed by a
              qualified institutional buyer.
            </CustomRadio>
            <CustomRadio value="in-institutional-partnership-firm">
              The firm/fund is a partnership firm set up under the Indian
              Partnership Act, 1932, in which each partner independently meets
              the accredited investor criteria for individuals.
            </CustomRadio>
            <CustomRadio value="in-institutional-trust">
              The firm/fund is a trust with assets under management greater than
              or equal to INR 50 Crore.
            </CustomRadio>
            <CustomRadio value="in-institutional-body-corporate">
              The firm/fund is a body corporate with a net worth greater than or
              equal to INR 50 Crore.
            </CustomRadio>
            <CustomRadio value="in-institutional-other">
              Other (explain below).
            </CustomRadio>
            <CustomRadio value="in-institutional-not-accredited">
              The firm/fund is not an accredited investor.
            </CustomRadio>
          </VStack>
        </AccreditationRadioInput>
      </QuestionFormCard>
      {radioValue === `in-institutional-other` && (
        <AccreditationTextAreaInput
          placeholder="Description"
          label="How are you accredited?"
          value={textAreaValue}
          onChange={onChangeTextAreaValue}
        />
      )}
    </VStack>
  );
};
