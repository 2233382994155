import { Button, VStack, Text, Box, HStack } from "@chakra-ui/react";

import { chakraColor } from "@/utils";

import TrendLineSvg from "./TrendLineSvg";
import { TrendLine } from "./types";

export const TrendLineButton = ({
  option,
  isSelected,
  onClick,
  color,
  label,
  description,
}: {
  readonly option: TrendLine;
  readonly isSelected: boolean;
  readonly onClick: (option: TrendLine) => void;
  readonly color: string;
  readonly label: JSX.Element;
  readonly description: JSX.Element;
}) => (
  <Box
    as={Button}
    key={option}
    variant="boxed-grey-mini"
    maxW="full"
    w="full"
    size="xl"
    px={2}
    minH={14}
    h="auto"
    borderColor={isSelected ? `salmon.800` : `grey.100`}
    borderWidth={isSelected ? 2 : 1}
    onClick={() => onClick(option)}
    whiteSpace="initial"
  >
    <HStack spacing={3} w="full" alignItems="start">
      <Box position="relative" top={1}>
        <TrendLineSvg fill={chakraColor(color)} />
      </Box>
      <VStack alignItems="start" spacing={0}>
        <Text textStyle="heading-xs">{label}</Text>
        <Text
          textStyle="text-xs"
          textAlign="left"
          lineHeight={{ base: `shorter`, md: `inherit` }}
        >
          {description}
        </Text>
      </VStack>
    </HStack>
  </Box>
);
