import { StackProps, useBreakpointValue } from "@chakra-ui/react";

export const useCompanyActionsCTACardDirection = (
  numActionsAvailable: number,
) => {
  const singleAction = numActionsAvailable === 1;

  const direction: StackProps["direction"] = useBreakpointValue(
    {
      base: `column`,
      sm: singleAction ? `row` : `column`,
      md: `row`,
      xl: singleAction ? `row` : `column`,
    },
    { fallback: `column`, ssr: false },
  );

  return direction;
};
