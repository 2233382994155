import React from "react";

import { Box, BoxProps, keyframes } from "@chakra-ui/react";

const pulsate = keyframes`
  0% { transform: scale(0.1, 0.1); opacity: 0.0; }
  50% { opacity: 1.0; }
  100% { transform: scale(1.2, 1.2); opacity: 0.0; }
`;

export const Pulse = (props: BoxProps) => {
  const outer = `12px`;
  const inner = `6px`;
  return (
    <Box position="relative" width={outer} height={outer} {...props}>
      <Box
        position="absolute"
        width={inner}
        height={inner}
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        bgColor="salmon.900"
        borderRadius="50%"
      />
      <Box
        position="absolute"
        width={outer}
        height={outer}
        borderWidth="2px"
        borderColor="salmon.900"
        borderRadius="50%"
        animation={`${pulsate} 2s ease-out infinite`}
        opacity="0"
      />
    </Box>
  );
};
