import { Badge, HStack, Text } from "@chakra-ui/react";

import { CompanyMarketActivityCompanyFragment } from "@/gql";

const CompanyMarketActivity = ({
  company: {
    activity: { numOpenListings, numOpenStandingBidsAndActiveBids },
  },
}: {
  readonly company: CompanyMarketActivityCompanyFragment;
}) => (
  <HStack alignItems="flex-start" w="full">
    <Badge variant="grey">
      <Text as="span" textStyle="heading-md" mr={4}>
        {numOpenListings}
      </Text>
      {` `}
      Listings
    </Badge>
    <Badge variant="grey">
      <Text as="span" textStyle="heading-md" mr={4}>
        {numOpenStandingBidsAndActiveBids}
      </Text>
      {` `}
      Bids
    </Badge>
  </HStack>
);

export default CompanyMarketActivity;
