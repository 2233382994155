import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Grid } from "@chakra-ui/react";

import { FormTextInput } from "@/components/react-hook-form";

import { InstitutionInfoFormValues } from "./InstitutionInfoForm";

const USAAddressFields = ({
  control,
}: {
  control: Control<InstitutionInfoFormValues>;
}) => {
  const { t } = useTranslation();

  return (
    <Grid gridTemplateColumns={{ base: `1fr`, md: `2fr 1fr 1fr` }} gap={5}>
      <FormTextInput name="city" label={t(`city`)} control={control} />
      <FormTextInput name="region" label={t(`state`)} control={control} />
      <FormTextInput
        name="postalCode"
        label={t(`zip_code`)}
        control={control}
      />
    </Grid>
  );
};

const UKAddressFields = ({
  control,
}: {
  control: Control<InstitutionInfoFormValues>;
}) => {
  const { t } = useTranslation();

  return (
    <Grid gridTemplateColumns={{ base: `1fr`, md: `3fr 1fr` }} gap={5}>
      <FormTextInput name="city" label={t(`city`)} control={control} />
      <FormTextInput
        name="postalCode"
        label={t(`postal_code`)}
        control={control}
      />
    </Grid>
  );
};

const SwissAddressFields = ({
  control,
}: {
  control: Control<InstitutionInfoFormValues>;
}) => {
  const { t } = useTranslation();

  return (
    <Grid gridTemplateColumns={{ base: `1fr`, md: `3fr 1fr` }} gap={5}>
      <FormTextInput name="city" label={t(`city`)} control={control} />
      <FormTextInput
        name="postalCode"
        label={t(`postal_code`)}
        control={control}
      />
    </Grid>
  );
};

const IndiaAddressFields = ({
  control,
}: {
  control: Control<InstitutionInfoFormValues>;
}) => {
  const { t } = useTranslation();

  return (
    <Grid gridTemplateColumns={{ base: `1fr`, md: `2fr 1fr 1fr` }} gap={5}>
      <FormTextInput name="city" label={t(`city`)} control={control} />
      <FormTextInput name="region" label={t(`state`)} control={control} />
      <FormTextInput
        name="postalCode"
        label={t(`pin_code`)}
        control={control}
      />
    </Grid>
  );
};

const DefaultAddressFields = ({
  control,
}: {
  control: Control<InstitutionInfoFormValues>;
}) => {
  const { t } = useTranslation();

  return (
    <Grid gridTemplateColumns={{ base: `1fr`, md: `2fr 1fr 1fr` }} gap={5}>
      <FormTextInput name="city" label={t(`city`)} control={control} />
      <FormTextInput name="region" label={t(`province`)} control={control} />
      <FormTextInput
        name="postalCode"
        label={t(`postal_code`)}
        control={control}
      />
    </Grid>
  );
};

export const AddressFields = ({
  control,
  country,
}: {
  control: Control<InstitutionInfoFormValues>;
  country: string;
}) =>
  match(country)
    .with(`US`, () => <USAAddressFields control={control} />)
    .with(`UK`, () => <UKAddressFields control={control} />)
    .with(`IN`, () => <IndiaAddressFields control={control} />)
    .with(`CH`, () => <SwissAddressFields control={control} />)
    .otherwise(() => <DefaultAddressFields control={control} />);
