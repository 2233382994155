import { ReactNode } from "react";

import { Flex, FlexProps } from "@chakra-ui/react";

interface ActivityCardFooterWrapperProps extends FlexProps {
  readonly children: ReactNode;
}

const ActivityCardFooterWrapper = ({
  children,
  ...flexProps
}: ActivityCardFooterWrapperProps) => (
  <Flex
    direction="row"
    justify="space-between"
    align="center"
    py={1}
    w="full"
    {...flexProps}
  >
    {children}
  </Flex>
);

export default ActivityCardFooterWrapper;
