const number_of_latest_postings = 50;
const number_of_latest_listings = number_of_latest_postings;
const number_of_latest_standing_bids = number_of_latest_postings;
const listing_shares_rounding_factor = 2;
const high_fees_warning_threshold = 50_000;

// IO Debouncing
const commission_calculation_debounce_time = 500;
const search_debounce_time = 275;

// Listing
const max_share_classes_for_listing = 3;
const default_listing_expire_after_weeks = 4;
const min_listing_size = {
  number: 25_000,
  text: `$25,000`,
};
const min_listing_size_for_partial_bids = {
  number: 25_000,
  text: `$25,000`,
};

// Standing Bid
const default_standing_bid_expire_after_days = 45;
const max_standing_bid_expire_after_days = 90;
const min_standing_bid_lot = {
  number: 25_000,
  text: `$25,000`,
};

// Bid
const min_bid_lot = {
  number: 25_000,
  text: `$25,000`,
};

// U16R
const max_holdings = 4;

// Metabase
const metabase_production_host = `metabase.hiive.com`;
const metabase_development_host = `metabase.hiive.dev`;

// Accounts & support
const email_legal = `legal@hiive.com`;
const email_connect = `connect@hiive.com`;
const email_accounts = `accounts@hiive.com`;
const email_support = `support@hiive.com`;
const email_security_specialist = `securityspecialist@hiive.com`;
const email_funds = `funds@hiive.com`;

// Marketing website
const marketing_website_url = `https://hiive.com`;

const back_office_url_production = `https://back-office.hiive.com`;
const back_office_url_staging = `https://back-office.hiive.dev`;
const back_office_url_development = `http://localhost:3001`;

const back_office_url = {
  [`local`]: back_office_url_development,
  [`usertesting`]: back_office_url_staging,
  [`development`]: back_office_url_staging,
  [`preview`]: back_office_url_staging,
  [`production`]: back_office_url_production,
};

const spv_faq_url = `/articles/hiive-spv-faq`;

const marketing_website_links = {
  calculate_price_of_shares: {
    title: `4 ways to calculate the price of your private start-up shares`,
    url: `${marketing_website_url}/market-articles/4-ways-to-calculate-the-price-of-your-private-start-up-shares`,
  },
  dive_into_vc_secondaries: {
    title: `A dive into VC secondaries`,
    url: `${marketing_website_url}/market-articles/what-are-vc-secondary-transactions`,
  },
  how_to_sell_private_shares: {
    title: `How to sell your shares in a private company`,
    url: `${marketing_website_url}/market-articles/selling-pre-ipo-shares`,
  },
};

const frontegg_routes = {
  logout: `/account/logout`,
};

const ROUTES = {
  ACCOUNT_REQUEST_UPDATED_EMAIL_VERIFICATION: `/account/request-updated-email-verification`,
  ACCOUNT_PASSWORD_UPDATE_CONFIRMED: `/account/password-update-confirmed`,
  ACCOUNT_SETTINGS_CONTACT: `/account/settings/contact`,
  ACCOUNT_SETTINGS_CHANGE_EMAIL: `/account/settings/change-email`,
};

const MFA_METHODS = {
  APP: `authenticator app`,
  SMS: `SMS authentication`,
};

export default {
  max_share_classes_for_listing,
  number_of_latest_listings,
  number_of_latest_standing_bids,
  listing_shares_rounding_factor,
  high_fees_warning_threshold,
  min_listing_size,
  min_listing_size_for_partial_bids,
  search_debounce_time,
  commission_calculation_debounce_time,
  default_listing_expire_after_weeks,
  min_standing_bid_lot,
  default_standing_bid_expire_after_days,
  max_standing_bid_expire_after_days,
  min_bid_lot,
  metabase_production_host,
  metabase_development_host,
  email_legal,
  email_connect,
  email_accounts,
  email_support,
  email_security_specialist,
  email_funds,

  marketing_website_url,
  marketing_website_links,
  back_office_url,

  spv_faq_url,

  max_holdings,

  frontegg_routes,
  ROUTES,
  MFA_METHODS,
};
