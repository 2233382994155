/* eslint-disable functional/immutable-data */
import Knock, { Feed, FeedClientOptions } from "@knocklabs/client";
import { useMemo, useRef } from "react";

const useNotificationFeed = ({
  knockClient,
  options = {},
}: {
  readonly knockClient: Knock;
  readonly options?: FeedClientOptions;
}) => {
  const notificationFeedRef = useRef<Feed | null>();

  return useMemo(() => {
    if (notificationFeedRef.current) notificationFeedRef.current.teardown();

    const notificationFeed = knockClient.feeds.initialize(
      process.env.NEXT_PUBLIC_KNOCK_FEED_ID || ``,
      options,
    );

    notificationFeed.listenForUpdates();
    notificationFeedRef.current = notificationFeed;

    return notificationFeed;
  }, [
    knockClient,
    options.source,
    options.tenant,
    options.has_tenant,
    options.archived,
  ]);
};

export default useNotificationFeed;
