import { MagnifyingGlass } from "@phosphor-icons/react";
import {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

import {
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  MenuList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FilterTypes } from "@/components/companies";
import { useHorizontalScrollToActiveTab } from "@/hooks";

import { CountryList } from "./CountryList";
import { IndustryList } from "./IndustryList";
import { InvestorList } from "./InvestorList";
import { LastRoundValuation } from "./LastRoundValuation";

enum TabType {
  Industries = 0,
  Investors,
  Countries,
  LastRoundSeries,
  LastRoundValuation,
}

const MenuTab = ({ children }: { readonly children: ReactNode }) => (
  <Tab whiteSpace="nowrap">{children}</Tab>
);

const MenuTabPanel = ({ children }: { readonly children: ReactNode }) => (
  <TabPanel as={Flex} flexDirection="column" gap={4} py={4}>
    {children}
  </TabPanel>
);

const FilterTagSearchBar = ({
  value,
  onChange,
  placeholder,
}: {
  readonly value: string;
  readonly onChange: Dispatch<SetStateAction<string>>;
  readonly selectedTab: TabType;
  readonly placeholder: string;
}) => (
  <InputGroup>
    <InputLeftElement pointerEvents="none">
      <MagnifyingGlass size={20} color="var(--chakra-colors-grey-200)" />
    </InputLeftElement>
    <Input
      value={value}
      type="text"
      placeholder={placeholder}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
    />
  </InputGroup>
);

const FilterMenu = ({
  onFilterChange,
  filters,
  onResetFilters,
  onApplyFilters,
  onClose,
}: {
  readonly onFilterChange: (
    field: keyof FilterTypes,
    value: string | readonly string[] | number | null,
  ) => void;
  readonly filters: FilterTypes;
  readonly onResetFilters: (action: "ALL" | "INDUSTRIES" | "INVESTORS") => void;
  readonly onApplyFilters: () => void;
  readonly onClose: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.Industries);
  const [search, setSearch] = useState<string>(``);

  const { tabListRef } = useHorizontalScrollToActiveTab(selectedTab);

  const onChangeTab = (tabType: TabType) => {
    setSearch(``);

    setSelectedTab(tabType);
  };

  const onApply = () => {
    onApplyFilters();
    onClose();
  };

  return (
    <MenuList
      boxShadow="card"
      borderRadius="md"
      px={5}
      pt={6}
      pb={5}
      bg="white"
      minW={{ lg: 138 }}
      maxW={{ base: `calc(100vw - var(--chakra-sizes-8))`, md: `min-content` }}
    >
      <Tabs variant="underline" onChange={onChangeTab}>
        <TabList
          ref={tabListRef}
          overflowX="auto"
          pb={1}
          sx={{
            "&::-webkit-scrollbar": {
              display: `none`,
            },
          }}
        >
          <MenuTab>Industries</MenuTab>
          <MenuTab>Investors</MenuTab>
          <MenuTab>Countries</MenuTab>
          <MenuTab>Last Round Valuation</MenuTab>
        </TabList>

        <TabPanels>
          <MenuTabPanel>
            <FilterTagSearchBar
              placeholder="Search industry"
              value={search}
              onChange={setSearch}
              selectedTab={selectedTab}
            />

            <Divider />

            <IndustryList
              search={search}
              filters={filters}
              onFilterChange={onFilterChange}
            />
          </MenuTabPanel>

          <MenuTabPanel>
            <FilterTagSearchBar
              placeholder="Search investor"
              value={search}
              onChange={setSearch}
              selectedTab={selectedTab}
            />

            <Divider />

            <InvestorList
              search={search}
              filters={filters}
              onFilterChange={onFilterChange}
            />
          </MenuTabPanel>

          <MenuTabPanel>
            <FilterTagSearchBar
              placeholder="Search country"
              value={search}
              onChange={setSearch}
              selectedTab={selectedTab}
            />

            <Divider />

            <CountryList
              search={search}
              filters={filters}
              onFilterChange={onFilterChange}
            />
          </MenuTabPanel>

          <MenuTabPanel>
            <Divider />
            <LastRoundValuation
              filters={filters}
              onFilterChange={onFilterChange}
            />
          </MenuTabPanel>
        </TabPanels>
      </Tabs>

      <Flex
        w="full"
        justify="flex-end"
        pt={5}
        borderTopWidth="1px"
        borderTopColor="grey.50"
      >
        <HStack spacing={4}>
          <HiiveButton
            minW={{ base: 32, sm: 44 }}
            w="full"
            size="xl"
            onClick={() => onResetFilters(`ALL`)}
            variant="rounded-outline-salmon"
            sentryLabel="[BrowseCompaniesPage/Header] Reset"
          >
            Reset
          </HiiveButton>
          <HiiveButton
            minW={{ base: 32, sm: 44 }}
            w="full"
            size="xl"
            onClick={onApply}
            variant="rounded-solid-salmon"
            sentryLabel="[BrowseCompaniesPage/Header] Apply"
          >
            Apply
          </HiiveButton>
        </HStack>
      </Flex>
    </MenuList>
  );
};

export default FilterMenu;
