import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  Skeleton,
  TextInputSkeleton,
} from "@/components/common";

const PlaceBidCardSkeleton = () => (
  <Card variant="darkened-footer">
    <CardHeader py={4}>
      <Skeleton h="26px" w="112px" />
    </CardHeader>
    <CardBody>
      <Skeleton h="14px" mb={6} maxW="75%" />
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        columnGap={9}
        rowGap={6}
        w="full"
      >
        <GridItem colSpan={1}>
          <TextInputSkeleton />
        </GridItem>
        <GridItem colSpan={1}>
          <TextInputSkeleton />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 2 }}>
          <TextInputSkeleton />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 2 }}>
          <Skeleton h="18px" />
        </GridItem>
      </SimpleGrid>
    </CardBody>
    <CardFooter>
      <ButtonFooterWrapper>
        <Skeleton
          h="48px"
          borderRadius="full"
          maxW={{ base: `unset`, md: `170px` }}
        />
      </ButtonFooterWrapper>
    </CardFooter>
  </Card>
);

export default PlaceBidCardSkeleton;
