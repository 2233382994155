import { Fade, Image } from "@chakra-ui/react";

import { CompanyComboboxCompanyFragment } from "@/gql";

export const SelectedIcon = ({
  company,
}: {
  readonly company: CompanyComboboxCompanyFragment;
}) =>
  company.logoUrl ? (
    <Fade
      in
      transition={{
        enter: { duration: 0.2 },
      }}
    >
      <Image
        m="0 auto"
        h={6}
        maxW={6}
        objectFit="contain"
        src={company.logoUrl}
        alt={company.name}
        userSelect="none"
      />
    </Fade>
  ) : null;
