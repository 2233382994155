import { ReactNode } from "react";

import {
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderProps,
  HStack,
  Spacer,
  StackProps,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardDisplayId,
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTimestamp,
  BrokerBidActivityCardTimestamps,
} from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  BidState,
  ListingPageBidCardBidFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useMultipleHoldings } from "@/hooks/featureFlags";
import {
  appendSellerCompanyIdToUrl,
  getBidNumSharesActual,
  getBidUrl,
  getIsBroker,
  getIsUnaccreditedSeller,
} from "@/utils";

const ListingPageBidCardHeader = ({
  bid,
  opacity,
}: CardHeaderProps & {
  readonly bid: ListingPageBidCardBidFragment;
  readonly opacity?: StackProps["opacity"];
}) => (
  <CardHeader>
    <HStack opacity={opacity}>
      <ActivityCardBadge variant="bid" title="Bid" />
      <ActivityCardDisplayId displayId={bid.displayId} />
    </HStack>
  </CardHeader>
);

const ListingPageBidCardDetails = ({
  bid,
}: {
  readonly bid: ListingPageBidCardBidFragment;
}) => {
  const numShares = getBidNumSharesActual(bid);
  const price = bid.pricePerShare;

  return (
    <ActivityCardPricingWrapper>
      <ActivityCardPricingNumSharesAndPrice
        numShares={numShares}
        price={price}
      />
      <ActivityCardPricingTotal numShares={numShares} price={price} />
    </ActivityCardPricingWrapper>
  );
};

const ListingPageBidCardFooter = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <CardFooter>
    <ActivityCardFooterWrapper>
      <ActivityCardFooterStatusWrapper>
        {children}
      </ActivityCardFooterStatusWrapper>
    </ActivityCardFooterWrapper>
  </CardFooter>
);

const ListingPageWithdrawnBidCard = withCurrentActor(
  ({
    bid,
    actor,
  }: {
    readonly bid: ListingPageBidCardBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const bidUrl = getBidUrl(bid.id);

    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
    const isMultipleHoldingsEnabled = useMultipleHoldings();

    return (
      <ActivityCard
        href={
          isUnaccreditedSeller && isMultipleHoldingsEnabled
            ? appendSellerCompanyIdToUrl(bidUrl, bid.companyId)
            : bidUrl
        }
      >
        <ListingPageBidCardHeader opacity="50%" bid={bid} />
        <CardBody>
          <ListingPageBidCardDetails bid={bid} />
        </CardBody>
        <ListingPageBidCardFooter>
          <ActivityCardTimestamp title="Withdrawn" date={bid.withdrawnAt} />
        </ListingPageBidCardFooter>
      </ActivityCard>
    );
  },
);
const ListingPageDefaultBidCard = withCurrentActor(
  ({
    bid,
    actor,
  }: {
    readonly bid: ListingPageBidCardBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isExpired = bid.state === BidState.Expired;
    const bidUrl = getBidUrl(bid.id);

    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
    const isMultipleHoldingsEnabled = useMultipleHoldings();
    const hasFooter =
      !!bid.expireAt ||
      (!!bid.discussion && bid.discussion.totalNumMessages > 0);

    return (
      <ActivityCard
        href={
          isUnaccreditedSeller && isMultipleHoldingsEnabled
            ? appendSellerCompanyIdToUrl(bidUrl, bid.companyId)
            : bidUrl
        }
      >
        <ListingPageBidCardHeader
          opacity={isExpired ? `50%` : `100%`}
          bid={bid}
        />
        <CardBody>
          <ListingPageBidCardDetails bid={bid} />
        </CardBody>
        {hasFooter && (
          <CardFooter>
            <ActivityCardFooterWrapper>
              {!!bid.expireAt ? (
                <ActivityCardTimestamp
                  title={isExpired ? `Expired` : `Expires`}
                  date={bid.expireAt}
                />
              ) : (
                <Spacer />
              )}
              {!!bid.discussion && bid.discussion.totalNumMessages > 0 && (
                <MessagesBadge
                  totalNumMessages={bid.discussion.totalNumMessages}
                  numUnreadMessages={bid.discussion.numUnreadMessages}
                />
              )}
            </ActivityCardFooterWrapper>
          </CardFooter>
        )}
      </ActivityCard>
    );
  },
);

const ListingPageBrokerBidCard = ({
  bid,
}: {
  readonly bid: ListingPageBidCardBidFragment;
}) => {
  const bidUrl = getBidUrl(bid.id);

  return (
    <ActivityCard href={bidUrl}>
      <ListingPageBidCardHeader bid={bid} />
      <CardBody>
        <ListingPageBidCardDetails bid={bid} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            <BrokerBidActivityCardTimestamps bid={bid} />
          </ActivityCardFooterStatusWrapper>
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};

const ListingPageBidCard = ({
  bid,
}: {
  readonly bid: ListingPageBidCardBidFragment;
}) => {
  const actor = useCurrentActor();

  if (getIsBroker(actor)) {
    return <ListingPageBrokerBidCard bid={bid} />;
  }

  if (bid.state === BidState.Withdrawn) {
    return <ListingPageWithdrawnBidCard bid={bid} />;
  }
  return <ListingPageDefaultBidCard bid={bid} />;
};

export default ListingPageBidCard;
