import { Flex, Text } from "@chakra-ui/react";

import { Logo } from "@/components/common";

const NoCompaniesFound = () => (
  <Flex
    w="full"
    direction="column"
    boxShadow="card"
    borderRadius="md"
    py={16}
    justify="center"
    align="center"
    bg="white"
    h="full"
    minH="60vh"
  >
    <Logo.GreyIcon />
    <Text textStyle="text-sm" color="grey.900" mt={4}>
      No matches found.
    </Text>
  </Flex>
);

export default NoCompaniesFound;
