/* eslint-disable func-names */

/* eslint-disable object-shorthand */
import { Form, FormikProps } from "formik";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { FullContentWrapper, WithQuery } from "@/components/common";
import { FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  ScrollSection,
  SectionNavBackButton,
  SectionNavButton,
  SectionNavWrapper,
  transferTypeChoiceToTransferMethod,
} from "@/components/listings";
import {
  DefaultUnaccreditedSellerDashboardPageDocument,
  InvestorType,
  UnaccreditedSellerCreateListingV2Mutation,
  UnaccreditedSellerCreateListingPageListingFragment,
  UserRole,
  UserWithInstitutionFragment,
  useUnaccreditedSellerCreateListingV2Mutation,
  useUnaccreditedSellerCreateListingPageQuery,
} from "@/gql";
import {
  useBasicSectionScrollTracking,
  useModal,
  useScrollToErrorsOnSubmitEffect,
  UseSectionScrollTrackingGetSectionProps,
} from "@/hooks";
import { useModifyListingNumSharesEnabled } from "@/hooks/featureFlags";
import {
  constants,
  currencyValue,
  hasInvestorType,
  hasUserRole,
  Nullable,
} from "@/utils";

import { InformativeArticlesCard } from "./InformativeArticlesCard";
import { ListingNotesCard } from "./ListingNotesCard";
import { SharePriceCard } from "./SharePriceCard";
import { SummaryAndConfirmationCard } from "./SummaryAndConfirmationCard";
import { TransferTypeCard } from "./TransferTypeCard";
import { UnaccreditedSellerCreateListingPageContentSkeleton } from "./UnaccreditedSellerCreateListingPageSkeleton";
import { CreateListingFormValues } from "./types";

const useValidationSchema = () => {
  const { t } = useTranslation();
  const isModifyListingNumSharesFlagEnabled = useModifyListingNumSharesEnabled();

  const minTransactionSizeError = t(`min_transaction_size_error`, {
    minSize: constants.min_listing_size.text,
  });

  return isModifyListingNumSharesFlagEnabled
    ? Yup.object().shape({
        otherDetails: Yup.string().nullable(),
        pricePerShare: Yup.number()
          .nullable()
          .test({
            name: `checkListingMeetsMinValue`,
            params: {},
            message: minTransactionSizeError,
            test: function (pricePerShare: number) {
              const { numShares } = this.parent;

              return (
                pricePerShare * numShares >= constants.min_listing_size.number
              );
            },
          })
          .required(`Required`),
        transferTypeChoice: Yup.string().nullable().required(`Required`),
        numShares: Yup.number()
          .nullable()
          .required(`Required`)
          .test({
            name: `checkListingMeetsMinValue`,
            params: {},
            message: minTransactionSizeError,
            test: function (numShares: number) {
              const { pricePerShare } = this.parent;

              return (
                pricePerShare * numShares >= constants.min_listing_size.number
              );
            },
          }),
        confirmed: Yup.boolean().nullable().oneOf([true], `Required`),
        shareSeries: Yup.string().nullable().required(`Required`),
      })
    : Yup.object().shape({
        otherDetails: Yup.string().nullable(),
        pricePerShare: Yup.number()
          .nullable()
          .test({
            name: `checkListingMeetsMinValue`,
            params: {},
            message: `Listing price must be a minimum value of ${constants.min_listing_size.text}. Try adjusting the number of shares and/or price per share.`,
            test: function (pricePerShare: number) {
              const { numShares } = this.parent;

              return (
                pricePerShare * numShares >= constants.min_listing_size.number
              );
            },
          })
          .required(`Required`),
        transferTypeChoice: Yup.string().nullable().required(`Required`),
        numShares: Yup.number().nullable().required(`Required`),
        confirmed: Yup.boolean().nullable().oneOf([true], `Required`),
      });
};

const initialValues: Nullable<CreateListingFormValues> = {
  otherDetails: ``,
  pricePerShare: null,
  transferTypeChoice: null,
  numShares: null,
  confirmedEligibilityRequirements: false,
  confirmedCompanyInformationDisclosure: false,
  confirmed: false,
  shareSeries: null,
};

const mapVariables = ({
  confirmed: _confirmed,
  confirmedEligibilityRequirements: _confirmedEligibilityRequirements,
  confirmedCompanyInformationDisclosure: _confirmedCompanyInformationDisclosure,
  transferTypeChoice,
  ...values
}: CreateListingFormValues) => ({
  input: {
    ...values,
    transferMethod: transferTypeChoiceToTransferMethod(transferTypeChoice),
    pricePerShare: currencyValue(values.pricePerShare),
  },
});

const sectionKeys = {
  SharePrice: `SharePrice`,
  TransferType: `TransferType`,
  ListingNotes: `ListingNotes`,
  SummaryAndConfirmation: `SummaryAndConfirmation`,
} as const;

type SectionKeys = keyof typeof sectionKeys;

interface UnaccreditedSellerCreateListingFormContentProps
  extends FormikProps<Nullable<CreateListingFormValues>> {
  readonly getSectionProps: UseSectionScrollTrackingGetSectionProps<
    SectionKeys,
    HTMLDivElement
  >;
  readonly containerElement: Element | null;
}

const UnaccreditedSellerCreateListingFormContent = ({
  getSectionProps,
  containerElement,
  isSubmitting,
}: UnaccreditedSellerCreateListingFormContentProps) => {
  useScrollToErrorsOnSubmitEffect({
    isSubmitting,
    containerElement,
  });

  return (
    <VStack as={Form} spacing={4} alignItems="flex-start">
      <ScrollSection {...getSectionProps(`SharePrice`)}>
        <SharePriceCard />
      </ScrollSection>
      <ScrollSection {...getSectionProps(`TransferType`)}>
        <TransferTypeCard />
      </ScrollSection>
      <ScrollSection {...getSectionProps(`ListingNotes`)}>
        <ListingNotesCard />
      </ScrollSection>
      <ScrollSection {...getSectionProps(`SummaryAndConfirmation`)}>
        <SummaryAndConfirmationCard company={null} />
      </ScrollSection>
    </VStack>
  );
};

interface UnaccreditedSellerCreateListingPageContentProps {
  readonly actor: UserWithInstitutionFragment;
  readonly listing?: UnaccreditedSellerCreateListingPageListingFragment | null;
}

const UnaccreditedSellerCreateListingPageContent = withCurrentActor(
  ({ listing, actor }: UnaccreditedSellerCreateListingPageContentProps) => {
    const router = useRouter();
    if (listing) {
      router.replace(`/dashboard/active-bids`);
    }

    if (
      !hasInvestorType(actor, InvestorType.UnaccreditedSeller) ||
      !hasUserRole(actor, UserRole.Seller)
    ) {
      router.replace(`/page-not-found`);
      return null;
    }

    const mutation = useUnaccreditedSellerCreateListingV2Mutation({
      refetchQueries: [DefaultUnaccreditedSellerDashboardPageDocument],
    });

    const containerRef = useRef<HTMLDivElement>(null);

    const {
      getSectionProps,
      getNavButtonProps,
    } = useBasicSectionScrollTracking({
      keys: [
        sectionKeys.SharePrice,
        sectionKeys.TransferType,
        sectionKeys.ListingNotes,
        sectionKeys.SummaryAndConfirmation,
      ],
      containerElement: containerRef.current,
    });

    const { modals, onOpenModal } = useModal();
    const validationSchema = useValidationSchema();

    const handleSuccess = (
      response: UnaccreditedSellerCreateListingV2Mutation,
    ) => {
      const {
        unaccreditedSellerCreateListingV2: { listing },
      } = response;

      if (listing) {
        onOpenModal(modals.unaccreditedSellerCreateListingSuccess(listing))();
      }

      router.push(`/dashboard/active-bids`);
    };

    return (
      <FullContentWrapper px={{ base: 4, lg: 8 }}>
        <SimpleGrid
          columnGap={6}
          rowGap={4}
          maxW="max-width-lg"
          gridTemplateColumns={{ base: `1fr`, lg: `416px 1fr` }}
          w="full"
        >
          <GridItem gridColumn={{ base: 1, lg: 2 }}>
            <Text textStyle="heading-3xl">List Your Shares for Sale</Text>
          </GridItem>
          <GridItem display={{ base: `none`, lg: `grid` }}>
            <SectionNavWrapper containerElement={containerRef.current}>
              <SectionNavBackButton
                onClick={() => router.push(`/dashboard`)}
                sentryLabel="[UnaccreditedSellerCreateListingPage/Back]"
              />
              <Flex direction="column" gap={3}>
                <Card w="full">
                  <CardBody>
                    <VStack spacing={2}>
                      <SectionNavButton {...getNavButtonProps(`SharePrice`)}>
                        1. Share Price
                      </SectionNavButton>
                      <SectionNavButton {...getNavButtonProps(`TransferType`)}>
                        2. Transfer Type
                      </SectionNavButton>
                      <SectionNavButton {...getNavButtonProps(`ListingNotes`)}>
                        3. Listing Notes
                      </SectionNavButton>
                      <SectionNavButton
                        {...getNavButtonProps(`SummaryAndConfirmation`)}
                      >
                        4. Summary & Confirmation
                      </SectionNavButton>
                    </VStack>
                  </CardBody>
                </Card>

                <InformativeArticlesCard />
              </Flex>
            </SectionNavWrapper>
          </GridItem>
          <GridItem ref={containerRef}>
            <FormikQL
              mutation={mutation}
              mutationNames={[`unaccreditedSellerCreateListingV2`]}
              initialValues={initialValues}
              validationSchema={validationSchema}
              mapVariables={mapVariables}
              onSuccess={handleSuccess}
            >
              {(formikProps) => (
                <UnaccreditedSellerCreateListingFormContent
                  getSectionProps={getSectionProps}
                  containerElement={containerRef.current}
                  {...formikProps}
                />
              )}
            </FormikQL>
          </GridItem>
        </SimpleGrid>
      </FullContentWrapper>
    );
  },
);

const UnaccreditedSellerCreateListingPage = () => {
  const query = useUnaccreditedSellerCreateListingPageQuery();

  return (
    <WithQuery
      query={query}
      fallback={<UnaccreditedSellerCreateListingPageContentSkeleton />}
    >
      {({
        data: {
          unaccreditedSellerMyActivity: { myListing: listing },
        },
      }) => <UnaccreditedSellerCreateListingPageContent listing={listing} />}
    </WithQuery>
  );
};

export default UnaccreditedSellerCreateListingPage;
