import isNil from "lodash/isNil";
import { ReactNode } from "react";

import { Box, Text, VStack } from "@chakra-ui/react";

const ActivityGroup = ({
  title,
  children,
  emptyState,
}: {
  /** Text displayed above the children */
  readonly title?: string;
  /** The content of the group */
  readonly children: readonly ReactNode[];
  /** UI to display when no children are present */
  readonly emptyState?: ReactNode;
}) => {
  if (children.length === 0 && !emptyState) return null;

  return (
    <Box w="full">
      {!isNil(title) && (
        <Text textStyle="heading-sm" textTransform="uppercase" mb={4}>
          {title}
        </Text>
      )}
      {children.length > 0 ? (
        <VStack spacing={4}>{children}</VStack>
      ) : (
        emptyState
      )}
    </Box>
  );
};

export default ActivityGroup;
