import React from "react";
import { useTranslation } from "react-i18next";

import { VStack } from "@chakra-ui/react";

import { FormikQL } from "@/components/form";
import {
  HoldingsFormValues,
  createValidationSchema,
  mapToVariables,
} from "@/components/unaccredited-seller";
import { CurrentContextDocument, useAddHoldingsV2Mutation } from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

import HoldingDetailsDescription from "./HoldingDetailsDescription";
import HoldingsForm from "./HoldingsForm";

const MultipleHoldingDetailsPage = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();
  const onSuccess = () => routeToNextStep();
  const { t } = useTranslation();
  const validationSchema = createValidationSchema(t);

  const initialValues: HoldingsFormValues = {
    selectedCompany: null,
    holdingBundles: [],
    sellerLotDisclaimer: false,
  };
  const mutation = useAddHoldingsV2Mutation({
    refetchQueries: [CurrentContextDocument],
  });

  return (
    <VStack spacing={7} w="full" align="stretch">
      <HoldingDetailsDescription />

      <FormikQL
        initialValues={initialValues}
        mutation={mutation}
        mutationNames={[`addHoldingsV2`]}
        mapVariables={mapToVariables}
        onSuccess={onSuccess}
        validationSchema={validationSchema}
      >
        {() => <HoldingsForm />}
      </FormikQL>
    </VStack>
  );
};

export default MultipleHoldingDetailsPage;
