import { Center, Spinner } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { withGuestRoute } from "@/components/hoc";
import { OnboardingContainer } from "@/components/onboarding";
import { useInvitationByTokenQuery } from "@/gql";

import InvitationForm from "./InvitationForm";

const InvitationPageWithToken = ({ token }: { readonly token: string }) => (
  <OnboardingContainer heading="Accept invitation">
    <WithQuery
      query={useInvitationByTokenQuery({
        variables: { token },
      })}
    >
      {({ data: { invitationByToken: invitation } }) =>
        invitation ? (
          <InvitationForm token={token} invitation={invitation} />
        ) : (
          <>No invitation found</>
        )
      }
    </WithQuery>
  </OnboardingContainer>
);

const InvitationPage = ({ token }: { readonly token?: string }) =>
  token ? (
    <InvitationPageWithToken token={token} />
  ) : (
    <Center m={20}>
      <Spinner />
    </Center>
  );

export default withGuestRoute(InvitationPage);
