import { useRouter } from "next/router";

import { WithQuery } from "@/components/common";
import { StandingBidPageContent } from "@/components/postings";
import {
  StandingBidPageStandingBidFragment,
  useStandingBidPageStandingBidByIdQuery,
} from "@/gql";

import { StandingBidPageSkeleton } from "./StandingBidPageSkeleton";

const StandingBidPage = ({ id }: { readonly id: string }) => {
  const router = useRouter();

  return (
    <WithQuery
      query={useStandingBidPageStandingBidByIdQuery({
        variables: { id: id as string },
        skip: !router.isReady,
      })}
      fallback={<StandingBidPageSkeleton />}
    >
      {({
        data: { standingBidById: standingBid },
      }: {
        readonly data: {
          readonly standingBidById: StandingBidPageStandingBidFragment;
        };
      }) => <StandingBidPageContent standingBid={standingBid} />}
    </WithQuery>
  );
};

export default StandingBidPage;
