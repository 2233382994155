import capitalize from "lodash/capitalize";
import { match } from "ts-pattern";

import { ShareSeries } from "@/gql";

export const getShareSeriesText = (shareSeries: string) =>
  match(shareSeries)
    .with(ShareSeries.CommonOptions, () => `Common/Options`)
    .with(ShareSeries.Common, () => `Common Stock`)
    .with(ShareSeries.Options, () => `Unexercised Options`)
    .with(ShareSeries.RestrictedStockUnits, () => `Restricted Stock Units`)
    .otherwise(() => `Preferred ${capitalize(shareSeries)}`);
