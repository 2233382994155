import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";

import { BrokerInfoForm } from "./BrokerInfoForm";

const BrokerInfoPage = () => {
  const { t } = useTranslation();

  return (
    <OnboardingContainerV2 metaTitle={t(`broker_dealer_information`)}>
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`broker_dealer_information`)} *
          </Text>
        </VStack>

        <BrokerInfoForm />
      </Flex>
    </OnboardingContainerV2>
  );
};

export default BrokerInfoPage;
