import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { PlaceBidCardPlaceBidMutation } from "@/gql";

import { StepKeys } from "./steps";

export const PlaceBidCardStepFormContext = createStepFormContext<
  StepKeys,
  PlaceBidCardPlaceBidMutation
>();

export const usePlaceBidCardStepFormContext = () =>
  useContext(PlaceBidCardStepFormContext);
