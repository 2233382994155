import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@/state";
import { reset as resetAuth } from "@/state/auth";

const useClearAuth = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch();
  const client = useApolloClient();
  useEffect(() => {
    if (!token) return;
    // Clearing Apollo Client cache and redux persist on mount
    dispatch(resetAuth());
    client.clearStore();
  }, []);
};

export default useClearAuth;
