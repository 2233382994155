import { t } from "i18next";
import { useSelector } from "react-redux";
import { match } from "ts-pattern";

import { Card, CardBody, CardHeader, Text, VStack } from "@chakra-ui/react";

import { ActionNeeded, HiiveButton, MailtoLink } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { IdentityVerificationStatus, UserWithInstitutionFragment } from "@/gql";
import { useIdentityVerificationModal } from "@/hooks";
import { RootState } from "@/state";
import { constants } from "@/utils";

const VerificationComplete = () => (
  <VStack alignItems="flex-start" spacing={4}>
    <Text>{t(`identity_verified`)}</Text>
    <Text>
      {t(`iv_change`)}
      {` `}
      <MailtoLink
        email={constants.email_accounts}
        subject={t(`contact_hiive`)}
        textStyle="heading-md"
      >
        {constants.email_accounts}
      </MailtoLink>
    </Text>
  </VStack>
);

const VerificationIncomplete = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { isOpen, onOpen } = useIdentityVerificationModal({
      actor,
    });

    return (
      <VStack spacing={4} alignItems="flex-start">
        <ActionNeeded>{t(`complete_iv`)}</ActionNeeded>
        <Text>{t(`complete_iv_description`)}</Text>
        <Text>{t(`complete_iv_tech`)}</Text>
        <HiiveButton
          variant="rounded-solid-salmon"
          size="xl"
          isDisabled={isOpen}
          onClick={onOpen}
          data-testid="verify-identity-button"
          sentryLabel="[IdentityVerificationButton] Start Identity Verification"
        >
          {t(`start_iv`)}
        </HiiveButton>
      </VStack>
    );
  },
);

const VerificationPending = () => (
  <VStack spacing={4} alignItems="flex-start">
    <Text>{t(`identity_verification_being_processed`)}</Text>
  </VStack>
);

export const IdentityVerificationCard = withCurrentActor(
  ({
    actor: { identityVerificationStatus },
  }: {
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const hasCompletedPersona = useSelector(
      (state: RootState) => state.persona.isComplete,
    );

    const component = match({
      hasCompletedPersona,
      identityVerificationStatus,
    })
      .with(
        { identityVerificationStatus: IdentityVerificationStatus.Approved },
        () => <VerificationComplete />,
      )
      .with(
        { identityVerificationStatus: IdentityVerificationStatus.Pending },
        () => <VerificationPending />,
      )
      .with({ hasCompletedPersona: true }, () => <VerificationPending />)
      .otherwise(() => <VerificationIncomplete />);

    return (
      <Card w="full" flex="1">
        <CardHeader>
          <Text textStyle="heading-sm">{t(`iv`)}</Text>
        </CardHeader>
        <CardBody>{component}</CardBody>
      </Card>
    );
  },
);
