import { CounterBidInfoCardFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import { getIsBroker } from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";

import BrokerSellerCounterBidInfoCard from "./BrokerSellerCounterBidInfoCard";
import BuyerCounterBidInfo from "./BuyerCounterBidInfoCard";
import SellerCounterBidInfo from "./SellerCounterBidInfoCard";

const CounterBidInfoCard = ({
  bid,
}: {
  readonly bid: CounterBidInfoCardFragment;
}) => {
  const actor = useCurrentActor();

  const isBuyer = getIsBuyerForBid(actor, bid);
  const isBroker = getIsBroker(actor);
  const isSSBPEnabled = useSellSideBrokerPortalEnabled();

  if (isBuyer) return <BuyerCounterBidInfo bid={bid} />;

  if (!isBuyer && isBroker && isSSBPEnabled)
    return <BrokerSellerCounterBidInfoCard bid={bid} />;

  return <SellerCounterBidInfo bid={bid} />;
};

export default CounterBidInfoCard;
