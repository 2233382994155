import { CardBody, HStack, Spacer, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

const ShareDetailsHeaderSkeleton = () => (
  <CardBody>
    <VStack gap="28px" spacing={0} alignItems="left">
      <HStack>
        <Skeleton h={10} w="full" />
        <Spacer flexGrow={1} />
        <Skeleton w="180px" h={12} />
      </HStack>
      <Skeleton h="135px" px={4} />
      <Skeleton h="62px" />
    </VStack>
  </CardBody>
);

export default ShareDetailsHeaderSkeleton;
