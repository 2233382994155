import { t } from "i18next";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { SimpleGrid, StackProps, useBreakpointValue } from "@chakra-ui/react";

import { CTACard } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  InvestorType,
  UserPermissionV2,
  UserRole,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCurrentActor, useIHavePermission, useModal } from "@/hooks";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import {
  getIsBroker,
  getIsInstitutionUser,
  hasInvestorType,
  hasUserRole,
} from "@/utils";

const PlaceStandingBidCTACard = ({
  direction,
}: {
  readonly direction: StackProps["direction"];
}) => {
  const { modals, onOpenModal } = useModal();
  const actor = useCurrentActor();
  const sellSideBrokerPortalFeatureEnabled = useSellSideBrokerPortalEnabled();
  const { t } = useTranslation();

  if (getIsBroker(actor) && sellSideBrokerPortalFeatureEnabled) {
    return (
      <CTACard
        direction={direction}
        heading={t(`submit_standing_bid_cta_broker_heading`)}
        description={t(`submit_standing_bid_cta_broker_description`)}
        action={{
          ctaText: t(`buy`),
          onClick: onOpenModal(modals.placeStandingBid()),
        }}
      />
    );
  }

  if (getIsBroker(actor)) {
    return (
      <CTACard
        direction={direction}
        heading="Submit a Standing Bid"
        description="Submit an independent order to buy stock at a specific price on your customer's behalf. Your order will be reviewed and may then be placed on the Hiive marketplace."
        action={{
          ctaText: `Bid`,
          onClick: onOpenModal(modals.placeStandingBid()),
        }}
      />
    );
  }

  return (
    <CTACard
      direction={direction}
      heading="Place Standing Bid"
      description="Create an independent offer to buy stock at a certain price. It may be withdrawn/modified by the bidder but may not be negotiated. It can be accepted by a seller at anytime."
      action={{
        ctaText: `Bid`,
        onClick: onOpenModal(modals.placeStandingBid()),
      }}
    />
  );
};

const ListForSaleCTACard = ({
  direction,
}: {
  readonly direction: StackProps["direction"];
}) => {
  const { modals, onOpenModal } = useModal();
  const { t } = useTranslation();
  const actor = useCurrentActor();
  const sellSideBrokerPortalFeatureEnabled = useSellSideBrokerPortalEnabled();

  if (getIsBroker(actor) && sellSideBrokerPortalFeatureEnabled) {
    return (
      <CTACard
        direction={direction}
        heading={t(`submit_listing_cta_broker_heading`)}
        description={t(`submit_listing_cta_broker_description`)}
        action={{
          ctaText: t(`sell`),
          onClick: onOpenModal(modals.brokerSubmitListing()),
        }}
      />
    );
  }

  return (
    <CTACard
      direction={direction}
      heading="List for Sale"
      description={t(`create_listing_cta`)}
      action={{
        ctaText: `Sell`,
        onClick: onOpenModal(modals.createListing()),
      }}
    />
  );
};

const getKnowYourCustomerCTADescription = (
  actor: UserWithInstitutionFragment,
) => {
  if (
    hasInvestorType(actor, InvestorType.Trader) &&
    hasUserRole(actor, UserRole.Seller)
  ) {
    return t(`step_must_be_completed_before_buy`);
  }
  return t(`step_must_be_completed_before_buy_or_sell`);
};

const IdentityVerificationCTACard = ({
  actor,
  direction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly direction: StackProps["direction"];
}) => {
  const router = useRouter();
  return (
    <CTACard
      testId="identity-verification-cta"
      direction={direction}
      heading="Identity Verification"
      description={getKnowYourCustomerCTADescription(actor)}
      action={{
        ctaText: `Verify`,
        onClick: () => router.push(`/account/profile/identity-verification`),
      }}
    />
  );
};

const CustomerAgreementCTACard = ({
  actor,
  direction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly direction: StackProps["direction"];
}) => {
  const router = useRouter();

  return (
    <CTACard
      testId="customer-agreement-cta"
      direction={direction}
      heading="Customer Agreement"
      description={getKnowYourCustomerCTADescription(actor)}
      action={{
        ctaText: `Sign`,
        onClick: () => router.push(`account/profile/customer-agreement`),
      }}
    />
  );
};

const useCTACardDirection = (numActionsAvailable: number) => {
  const singleAction = numActionsAvailable === 1;

  const direction: StackProps["direction"] = useBreakpointValue(
    {
      base: `column`,
      sm: singleAction ? `row` : `column`,
      md: `column`,
      lg: singleAction ? `row` : `column`,
      "2xl": `row`,
      "3xl": singleAction ? `row` : `column`,
    },
    { fallback: `column`, ssr: false },
  );

  return direction;
};

const DashboardCTAGrid = ({
  children,
  numActionsAvailable,
}: {
  readonly children: ReactNode;
  readonly numActionsAvailable: number;
}) => (
  <SimpleGrid
    className="cta-grid"
    columns={{
      base: 1,
      sm: numActionsAvailable === 1 ? 1 : 2,
      md: 1,
      lg: numActionsAvailable === 1 ? 1 : 2,
      "2xl": 1,
      "3xl": numActionsAvailable === 1 ? 1 : 2,
    }}
    gap={4}
    w="full"
  >
    {children}
  </SimpleGrid>
);

const KnowYourCustomerCTA = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { roles, institution, identityVerified } = actor;

    const isInstitutionUser = getIsInstitutionUser(actor);

    const hasSignedCustomerAgreement = isInstitutionUser
      ? institution?.membershipAgreementSigned
      : actor.agreedToCustomerAgreement;

    const hasVerifiedIdentity = identityVerified;

    // Can this be replaced with backend permission?
    const canSignCustomerAgreement =
      !hasSignedCustomerAgreement &&
      (!isInstitutionUser || roles.includes(UserRole.Signer));

    const canVerifyIdentity = isInstitutionUser ? false : !hasVerifiedIdentity;

    const numActionsAvailable = [
      canSignCustomerAgreement,
      canVerifyIdentity,
    ].filter((value) => value).length;

    const direction = useCTACardDirection(numActionsAvailable);

    return (
      <DashboardCTAGrid numActionsAvailable={numActionsAvailable}>
        {canVerifyIdentity && (
          <IdentityVerificationCTACard actor={actor} direction={direction} />
        )}
        {canSignCustomerAgreement && (
          <CustomerAgreementCTACard actor={actor} direction={direction} />
        )}
      </DashboardCTAGrid>
    );
  },
);

const ActivityCTA = () => {
  const canCreateListing = useIHavePermission(UserPermissionV2.CreateListing);
  const canPlaceStandingBid = useIHavePermission(
    UserPermissionV2.PlaceStandingBid,
  );

  const numActionsAvailable = [canCreateListing, canPlaceStandingBid].filter(
    (value) => value,
  ).length;

  const direction = useCTACardDirection(numActionsAvailable);

  return (
    <DashboardCTAGrid numActionsAvailable={numActionsAvailable}>
      {canCreateListing && <ListForSaleCTACard direction={direction} />}
      {canPlaceStandingBid && <PlaceStandingBidCTACard direction={direction} />}
    </DashboardCTAGrid>
  );
};

const getHasCompletedRequiredActions = (actor: UserWithInstitutionFragment) => {
  switch (true) {
    case getIsBroker(actor):
      return true;
    case getIsInstitutionUser(actor):
      return actor.institution?.membershipAgreementSigned;
    case hasInvestorType(actor, InvestorType.Trader) &&
      !getIsInstitutionUser(actor):
      return actor.identityVerified && actor.agreedToCustomerAgreement;
    default:
      return actor.identityVerified && actor.membershipAgreementSigned;
  }
};

export const DashboardCTA = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { roles } = actor;

    const isInstitutionUser = getIsInstitutionUser(actor);
    const isInstitutionSigner = roles.includes(UserRole.Signer);

    const hasCompletedRequiredActions = getHasCompletedRequiredActions(actor);

    const showKYC =
      !hasCompletedRequiredActions &&
      (isInstitutionUser ? isInstitutionSigner : true);

    return (
      <>
        {showKYC && <KnowYourCustomerCTA />}
        {hasCompletedRequiredActions && <ActivityCTA />}
      </>
    );
  },
);
