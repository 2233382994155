import {
  Card,
  CardBody,
  Divider,
  Flex,
  Grid,
  HStack,
  SimpleGrid,
  Spacer,
  GridItem,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

const SkeletonCard = () => (
  <Card h={575} w="full">
    <CardBody gap={5} display="flex" flexDirection="column">
      <SimpleGrid columns={3} gap={4}>
        <GridItem>
          <Skeleton h={8} />
        </GridItem>
        <GridItem>
          <Spacer />
        </GridItem>
        <GridItem>
          <Skeleton h={8} />
        </GridItem>
      </SimpleGrid>

      <Skeleton h={70} />

      <Grid rowGap={6} columnGap={8} gridTemplateColumns="130px 1fr">
        <Skeleton h={4} count={7} />
        <HStack spacing={2} maxW="60%">
          <Skeleton h={6} count={2} />
        </HStack>
      </Grid>

      <Divider />

      <Flex justifyContent="center" direction="column" gap={2}>
        <Skeleton h={4} />
        <Skeleton h={4} maxW="75%" />
      </Flex>

      <Skeleton h={4} w={14} />
      <HStack spacing={2} maxW="75%">
        <Skeleton h={5} count={4} />
      </HStack>

      <Skeleton h={4} w={14} />
      <HStack spacing={2} maxW="75%">
        <Skeleton h={5} count={3} />
      </HStack>
    </CardBody>
  </Card>
);

const BrowseCompaniesPageSkeleton = () => (
  <Grid
    w="full"
    margin="auto"
    templateColumns={{ base: `1fr`, md: `1fr 1fr`, lg: `1fr 1fr 1fr` }}
    gap={{ base: 4, lg: 5, xl: 9 }}
  >
    {[...Array(9).keys()].map((index) => (
      <SkeletonCard key={index} />
    ))}
  </Grid>
);

export default BrowseCompaniesPageSkeleton;
