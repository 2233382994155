import { useAuthUserOrNull } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { BaseOperationState } from "./types";

interface EnrollMfaResponse {
  readonly errors: string[];
  readonly otcToken: string;
  readonly phoneNumber: string;
}

interface OperationState extends BaseOperationState {
  readonly data: EnrollMfaResponse | null;
}

const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/sms/enroll`;

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const useEnrollSmsMfa = () => {
  const { t } = useTranslation();
  const user = useAuthUserOrNull();
  const [state, setState] = useState<OperationState>(initialState);
  const genericErrorMessage = t(`failed_to_enroll_mfa`);

  const enrollMfa = useCallback(
    async (phoneNumber: string) => {
      setState({ loading: true, error: null, data: null });

      try {
        if (!user) {
          throw new Error(t(`invalid_request`));
        }

        const response = await fetch(API_URL, {
          method: `POST`,
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify({ phoneNumber }),
        });

        const data: EnrollMfaResponse = await response.json();

        if (!response.ok) {
          if (data.errors.length > 0) {
            const [message] = data.errors;
            const errorMessage = message || genericErrorMessage;
            throw new Error(errorMessage);
          }
        }

        setState({ loading: false, error: null, data });
        return { data, error: null };
      } catch (error) {
        Sentry.captureException(error);
        setState({ loading: false, error, data: null });
        return { data: null, error };
      }
    },
    [t, user],
  );

  return [enrollMfa, state] as const;
};

export default useEnrollSmsMfa;
