import { FrigadeAnnouncement, useFlows } from "@frigade/react";
import { useEffect } from "react";

import Head from "next/head";

import { useBreakpointValue } from "@chakra-ui/react";

import { FlowKind } from "@/components/providers";
import useFlowLauncher from "@/hooks/useFlowLauncher";

export const ChartAnnouncement = () => {
  const { getFlowID } = useFlowLauncher();
  const { getFlowStatus } = useFlows();

  const flowId = getFlowID(FlowKind.CompanyPriceCharts);
  const flowStatus = getFlowStatus(flowId);

  const isMobileView = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    { ssr: false },
  );

  useEffect(() => {
    if (!isMobileView) {
      setTimeout(() => {
        // eslint-disable-next-line functional/immutable-data
        document.body.style.overflow = ``; // guardrails-disable-line
      }, 500);
    }
  }, [isMobileView, flowStatus]);

  return (
    <>
      <Head>
        <link rel="preload" as="image" href="/guide/chart-announcement.png" />
      </Head>
      <FrigadeAnnouncement
        flowId={flowId}
        modalPosition={isMobileView ? `center` : `bottom-right`}
        appearance={{
          styleOverrides: {
            announcementTitle: {
              display: `block`,
              textAlign: `left`,
              fontSize: `1.5rem`,
              marginBottom: `1rem`,
            },
            announcementSubtitle: {
              display: `block`,
              textAlign: `left`,
              fontSize: `1rem`,
            },
            announcementMediaContainer: {
              order: `-1`,
              maxHeight: `200px`,
            },
            image: {
              minHeight: `auto`,
            },
            announcementCTAContainer: {
              justifyContent: `flex-start`,
            },
          },
        }}
      />
    </>
  );
};
