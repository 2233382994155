import { CardFooter, Spacer } from "@chakra-ui/react";

import {
  ConditionallyCompletedBadge,
  ExpiryDate,
  PartiallyAcceptedBadge,
} from "@/components/companies";
import {
  ActivityCardStandingBidCardFooterStandingBidFragment,
  StandingBidState,
} from "@/gql";

import ActivityCardFooterWrapper from "./ActivityCardFooterWrapper";

const ActivityCardStandingBidCardFooter = ({
  standingBid,
}: {
  readonly standingBid: ActivityCardStandingBidCardFooterStandingBidFragment;
}) => {
  const { expireAt, state, acceptedBidPrices } = standingBid;

  const hasExpirationDate = !!expireAt;

  const isPartiallyAccepted =
    state === StandingBidState.Open && acceptedBidPrices.length >= 1;

  const isConditonallyCompleted =
    state === StandingBidState.ConditionallyCompleted;

  const hasFooter =
    hasExpirationDate || isPartiallyAccepted || isConditonallyCompleted;

  if (!hasFooter) {
    return null;
  }

  return (
    <CardFooter>
      <ActivityCardFooterWrapper>
        {hasExpirationDate ? <ExpiryDate expireAt={expireAt} /> : <Spacer />}
        {isPartiallyAccepted && <PartiallyAcceptedBadge />}
        {isConditonallyCompleted && <ConditionallyCompletedBadge />}
      </ActivityCardFooterWrapper>
    </CardFooter>
  );
};

export default ActivityCardStandingBidCardFooter;
