import { match } from "ts-pattern";

import {
  BrokerTransactionInfoCard,
  BuyerTransactionInfoCard,
  InitialTransactionCard,
  SellerTransactionInfoCard,
} from "@/components/postings";
import { TransactionInfoCardBidFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import { getIsBroker } from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";

const TransactionInfoCard = ({
  bid,
}: {
  readonly bid: TransactionInfoCardBidFragment;
}) => {
  const actor = useCurrentActor();
  const isBroker = getIsBroker(actor);
  const isBuyer = getIsBuyerForBid(actor, bid);
  const isSSBPEnabled = useSellSideBrokerPortalEnabled();

  const isModifiedTransaction =
    !!bid.transaction && bid.transaction.history.length > 0;

  const initialTransaction =
    isModifiedTransaction && bid.transaction?.history[0];

  return (
    <>
      {match({ isBuyer, isBroker, isSSBPEnabled })
        .with({ isBuyer: true, isBroker: false }, () => (
          <BuyerTransactionInfoCard bid={bid} />
        ))
        .with({ isBroker: true, isSSBPEnabled: true }, () => (
          <BrokerTransactionInfoCard bid={bid} />
        ))
        .otherwise(() => (
          <SellerTransactionInfoCard bid={bid} />
        ))}
      {!!initialTransaction && (
        <InitialTransactionCard
          feeDiscountApplications={bid.transaction?.feeDiscountApplications}
          initialTransaction={initialTransaction}
          buyerId={bid.buyerId}
          buyerBrokerId={bid.brokerId}
          brokerCounterpartyId={bid.brokerCounterpartyId}
        />
      )}
    </>
  );
};

export default TransactionInfoCard;
