import { Box, Text } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  OffPlatformReminderDiscussionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { getDiscussionDocumentText, getDiscussionIsSeller } from "@/utils";

const OffPlatformReminder = ({
  discussion,
  actor,
}: {
  readonly discussion: OffPlatformReminderDiscussionFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const documentText = getDiscussionDocumentText(discussion);
  const topicType =
    discussion.topic.__typename === `StandingBid` ? `standing bid` : `bid`;
  const isSeller = getDiscussionIsSeller(actor, discussion);

  return (
    <Box textStyle="deprecated-text-2xs" lineHeight="shorter" flex="1">
      <Text fontWeight="medium" as="span">
        Reminder:
      </Text>
      {isSeller ? (
        <Text as="span">
          {` `}If you take your conversation with the buyer to email or phone,
          ensure you ultimately accept their {topicType} and conclude the
          transaction via the platform, for the lowest possible fees. Otherwise,
          you will be charged at a higher off-platform rate.
        </Text>
      ) : (
        <Text as="span">
          {` `}If you take your conversation with the seller to email or phone,
          please ensure that they ultimately accept your {topicType} on the
          platform. If you negotiate a price change, modify your{` `}
          {topicType} to the agreed upon price and then have the seller accept
          the bid. Once they accept the bid, Hiive will auto-generate the{` `}
          {documentText}. If you do not sign the {documentText} on-platform the
          seller will be charged transaction fees at a higher rate.
        </Text>
      )}
    </Box>
  );
};

export default withCurrentActor(OffPlatformReminder);
