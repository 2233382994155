import { FocusedShareDetails, ShareDetailsStats } from "@/components/common";
import { BidSellerRoundingDisclaimer } from "@/components/postings";
import { BidInfoCardBidShareDetailsBidFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  getBidNumSharesActual,
  getNumOfShares,
  getPricePerShare,
} from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";

const BuyerShareDetails = ({
  bid,
}: {
  readonly bid: BidInfoCardBidShareDetailsBidFragment;
}) => {
  const numberOfShares = getNumOfShares(bid, true);
  const pricePerShare = getPricePerShare(bid);

  return (
    <FocusedShareDetails.HeaderCard>
      <ShareDetailsStats
        numberOfShares={numberOfShares}
        pricePerShare={pricePerShare}
      />
    </FocusedShareDetails.HeaderCard>
  );
};

const SellerShareDetails = ({
  bid,
}: {
  readonly bid: BidInfoCardBidShareDetailsBidFragment;
}) => {
  const isDisclaimerVisible = getBidNumSharesActual(bid) !== bid.numShares;

  const numberOfShares = getBidNumSharesActual(bid);
  const pricePerShare = getPricePerShare(bid);

  return (
    <>
      <FocusedShareDetails.HeaderCard>
        <ShareDetailsStats
          numberOfShares={numberOfShares}
          pricePerShare={pricePerShare}
        />
      </FocusedShareDetails.HeaderCard>
      {isDisclaimerVisible && (
        <BidSellerRoundingDisclaimer numSharesOriginal={bid.numShares} />
      )}
    </>
  );
};

export const BidInfoCardBidShareDetails = ({
  bid,
}: {
  readonly bid: BidInfoCardBidShareDetailsBidFragment;
}) => {
  const actor = useCurrentActor();
  const isBuyer = getIsBuyerForBid(actor, bid);

  return isBuyer ? (
    <BuyerShareDetails bid={bid} />
  ) : (
    <SellerShareDetails bid={bid} />
  );
};
