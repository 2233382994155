import { Trans, useTranslation } from "react-i18next";

import { StackProps, useBreakpointValue, Text } from "@chakra-ui/react";

import { CTACard } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  AcceptStandingBidCtaCardStandingBidFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useModal } from "@/hooks";
import { getIsUnaccreditedSeller } from "@/utils";

export const AcceptStandingBidCTACard = withCurrentActor(
  ({
    standingBid,
    actor,
  }: {
    readonly standingBid: AcceptStandingBidCtaCardStandingBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { modals, onOpenModal } = useModal();

    const { t } = useTranslation();

    const direction: StackProps["direction"] = useBreakpointValue(
      {
        base: `column`,
        sm: `row`,
        md: `row`,
        lg: `column`,
        xl: `row`,
      },
      { ssr: false },
    );

    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    const handleClick = () => {
      if (isUnaccreditedSeller) {
        onOpenModal(modals.unaccreditedSellerAcceptStandingBid(standingBid))();
        return;
      }

      onOpenModal(modals.acceptStandingBid(standingBid))();
    };

    return (
      <CTACard
        direction={direction}
        heading={t(`accept_standing_bid`)}
        description={
          <Text>
            <Trans
              i18nKey={
                standingBid.allowPartialAccept
                  ? `accepting_buyer_bid_partial_notice`
                  : `accepting_buyer_bid_notice`
              }
            />
          </Text>
        }
        action={{
          ctaText: t(`next`),
          onClick: handleClick,
        }}
      />
    );
  },
);
