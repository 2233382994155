/* eslint-disable functional/immutable-data */
import debounce from "lodash/debounce";
import { useEffect, useMemo, useRef } from "react";

type Callback = (...args: readonly unknown[]) => unknown;

/**
 * Creates a stable reference to a debounced function only once,
 * at initialization. The passed callback itself is stored in a mutable ref,
 * and always refers to the latest version -- so it is able to reference
 * any outer dependencies it needs (state, props, etc.) while avoiding
 * stale closures.
 * */
const useDebouncedCallback = (callback: Callback, timeout: number) => {
  const ref = useRef<Callback>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(
    () => debounce(() => ref.current?.(), timeout),
    [],
  );

  return debouncedCallback;
};

export default useDebouncedCallback;
