import { ReactNode } from "react";

import { Stack } from "@chakra-ui/react";

const ActivityCardFooterStatusWrapper = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <Stack spacing={0} columnGap={3} rowGap={1} direction="row" flexWrap="wrap">
    {children}
  </Stack>
);

export default ActivityCardFooterStatusWrapper;
