import { useTranslation } from "react-i18next";

import { Text, TextProps, VStack } from "@chakra-ui/react";

import { CheckboxInput } from "@/components/form";

interface ConfirmIntentInputProps {
  readonly textProps?: TextProps;
}

const ConfirmIntentInput = ({ textProps }: ConfirmIntentInputProps) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="flex-start" spacing={4}>
      <CheckboxInput
        name="confirmed"
        label={t(`create_listing_intent_for_sale`)}
      />
      <Text {...textProps}>{t(`create_listing_confirm_genuine_interest`)}</Text>
    </VStack>
  );
};

export default ConfirmIntentInput;
