import { useMemo } from "react";

import {
  AutomatedLOIBuyerSteps,
  AutomatedLOIStatesMap,
  AutomatedLOITransactionState,
} from "@/components/postings";
import { AutomatedLoiBuyerStatesBidFragment, TransactionState } from "@/gql";
import { useDocumentSigners } from "@/hooks";

import BidAcceptedStatusItem from "./BidAcceptedStatusItem";
import { TransferStatusItem } from "./TransferStatusItem";

const AutomatedLOIBuyerStates = ({
  state,
  bid,
}: {
  readonly state: AutomatedLOITransactionState;
  readonly bid: AutomatedLoiBuyerStatesBidFragment;
}) => {
  const states: AutomatedLOIStatesMap<AutomatedLOIBuyerSteps> = {
    [TransactionState.BidAccepted]: { awaitingClosing: null, closed: null },
    [TransactionState.Expired]: { awaitingClosing: null, closed: null },
    [TransactionState.AwaitingClosing]: {
      awaitingClosing: `PENDING`,
      closed: null,
    },
    [TransactionState.ClosedFeePending]: {
      awaitingClosing: `SUCCESS`,
      closed: `SUCCESS`,
    },
    [TransactionState.ClosedFeePaid]: {
      awaitingClosing: `SUCCESS`,
      closed: `SUCCESS`,
    },
  };

  const bidState = useDocumentSigners(bid.transaction);

  const [loiSignedSubtitle] = useMemo(
    () => [
      bidState.haveBothSigned && states[state].awaitingClosing === `PENDING`
        ? `Awaiting closing`
        : undefined,
    ],
    [states, state, bidState],
  );

  return (
    <>
      {!!bid.transaction && (
        <BidAcceptedStatusItem transaction={bid.transaction} />
      )}
      <TransferStatusItem
        isCompleted={bidState.haveBothSigned}
        title="Letter of Intent signed"
        subtitle={loiSignedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].closed === `SUCCESS`}
        title="Transaction completed"
      />
    </>
  );
};

export default AutomatedLOIBuyerStates;
