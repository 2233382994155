import { Flex } from "@chakra-ui/react";

import { ActivityCardSkeleton, Skeleton } from "@/components/common";

export const OpenBidsSkeleton = () => (
  <Flex direction="column" gap={4}>
    <Skeleton h="20px" w="124px" />
    <ActivityCardSkeleton includeBody />

    <Skeleton h="20px" w="132px" />
    <ActivityCardSkeleton includeBody />
    <ActivityCardSkeleton includeBody />
  </Flex>
);
