import { WarningCircle } from "@phosphor-icons/react";

import { Badge, HStack, Text } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";
import { TransferMethod } from "@/gql";
import { useColors } from "@/hooks";
import {
  getLongDocumentTitleByTransferMethod,
  getShortDocumentTitleByTransferMethod,
} from "@/utils";

const ConditionallySoldOutBadge = ({
  transferMethod,
}: {
  readonly transferMethod: TransferMethod;
}) => {
  const [grey100] = useColors([`grey.100`]);

  const longDocumentTitle = getLongDocumentTitleByTransferMethod(
    transferMethod,
  );
  const shortDocumentTitle = getShortDocumentTitleByTransferMethod(
    transferMethod,
  );

  return (
    <CustomTooltip
      placement="bottom"
      tooltipContent={`This lot is sold pending execution of the ${longDocumentTitle}. If the ${shortDocumentTitle} is not signed within 14 days, then this lot will be re-listed.`}
    >
      <Badge variant="rounded-grey">
        <HStack spacing={1.5}>
          <WarningCircle color={grey100} weight="bold" size={14} />
          <Text>Conditionally sold out</Text>
        </HStack>
      </Badge>
    </CustomTooltip>
  );
};

export default ConditionallySoldOutBadge;
