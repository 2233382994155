import useEventListener from "@use-it/event-listener";

import { Box } from "@chakra-ui/react";

import { PandadocDocFrameProps, PandadocEvent } from "./types";

const PandadocDocFrame = ({
  url,
  title,
  onComplete,
  ...boxProps
}: PandadocDocFrameProps) => {
  useEventListener(`message`, (event: PandadocEvent) => {
    const type = event.data && event.data.type;

    if (onComplete && type === `session_view.document.completed`) {
      onComplete(event.data);
    }
  });

  return (
    <Box {...boxProps}>
      <iframe
        title={title}
        src={url}
        width="100%"
        height="100%"
        frameBorder={0}
        allowFullScreen
        style={{ position: `relative`, display: `block`, border: 0 }}
      />
    </Box>
  );
};
export default PandadocDocFrame;
