import dayjs from "dayjs";
import entries from "lodash/entries";
import fromPairs from "lodash/fromPairs";
import isNil from "lodash/isNil";
import keys from "lodash/keys";
import { useEffect } from "react";

import Head from "next/head";

import { SignupAttributionInput } from "@/gql";
import { useAnalytics } from "@/hooks";
import { getUTMCookieData, utmCookieDataToSegmentOption } from "@/utils";

const useSegmentPageTracking = (
  pageTitle: string | undefined,
  shouldTrackPageEvent: boolean,
) => {
  const analytics = useAnalytics();

  useEffect(() => {
    if (shouldTrackPageEvent && pageTitle) {
      const attribution = getUTMCookieData();
      const option = utmCookieDataToSegmentOption(attribution);

      analytics.page(pageTitle, option);
    }
  }, [pageTitle, shouldTrackPageEvent]);
};
const hostName = () => {
  const { host } = window.location;
  const domains = [
    ...(process.env.NEXT_PUBLIC_HIIVE_DOMAINS || ``)
      .split(`,`)
      .filter((domain) => !!domain),
    `localhost`,
  ];

  const [hostname] = domains.filter((domain) => host.includes(domain));
  return hostname || null;
};

const setCookie = (cookieName: string, value: SignupAttributionInput) => {
  const host = hostName();
  if (isNil(host)) return;

  const d = dayjs().add(400, `days`);
  const expires = `expires=${d.utc()}`;
  const cookie = `${cookieName}=${encodeURIComponent(
    JSON.stringify(value),
  )};${expires};domain=.${host};path=/`;
  // eslint-disable-next-line functional/immutable-data
  document.cookie = cookie;
};

const getUTMParams = (urlString: string) => {
  const url = new URL(urlString);
  const searchParams = new URLSearchParams(url.search);
  const recognized_params = [
    `utm_source`,
    `utm_medium`,
    `utm_campaign`,
    `utm_term`,
    `utm_content`,
  ];
  const params = fromPairs(
    entries(new Map(searchParams))
      .filter(([key]) => recognized_params.includes(key))
      .map(([key, value]) => [key.replace(`utm_`, ``), value]),
  );
  return keys(params).length === 0
    ? null
    : {
        ...params,
        ts: dayjs().toISOString(),
        v: `1`,
      };
};

const updateAttributionCookie = () => {
  const utm_params = getUTMParams(window.location.href);
  if (isNil(utm_params)) return;
  const cookie = getUTMCookieData();

  if (cookie === null) {
    setCookie(`hiive_utm`, {
      firstTouch: utm_params,
      lastTouch: utm_params,
    });
  } else {
    setCookie(`hiive_utm`, {
      firstTouch: cookie.firstTouch,
      lastTouch: utm_params,
    });
  }
};

export default function Meta({
  title,
  shouldTrackPageEvent = false,
}: {
  readonly title?: string;
  readonly shouldTrackPageEvent?: boolean;
}) {
  updateAttributionCookie();
  useSegmentPageTracking(title, shouldTrackPageEvent);

  return (
    <Head>
      {!!title && <title>{title} | Hiive</title>}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="theme-color" content="#000" />
      <link rel="alternate" type="application/rss+xml" href="/feed.xml" />
      <meta name="description" content="Meta description" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Head>
  );
}
