import { useField } from "formik";

import {
  FormControl,
  FormLabel,
  FormLabelProps,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";

interface FormTextareaProps extends TextareaProps {
  readonly name: string;
  readonly label: string;
  readonly placeholder?: string;
  readonly labelSrOnly?: FormLabelProps[`srOnly`];
}

const FormTextarea = ({
  name,
  label,
  placeholder,
  labelSrOnly,
  ...textareaProps
}: FormTextareaProps) => {
  const [field, { touched, error }, { setValue: _setValue }] = useField(name);

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <FormLabel srOnly={labelSrOnly}>{label}</FormLabel>
      <Textarea placeholder={placeholder} {...field} {...textareaProps} />
    </FormControl>
  );
};

export default FormTextarea;
