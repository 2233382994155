import { Form, FormikProps } from "formik";
import { t } from "i18next";
import { match, P } from "ts-pattern";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { AutosizeTextAreaInput, FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  MessageTopicType,
  useStartDiscussionMutation,
  StartDiscussionMutationVariables,
  StandingBidPageStandingBidByIdDocument,
  StandingBidSellerDiscussionListDiscussionDocument,
  ListingPageListingByIdDocument,
  MyDiscussionListDiscussionsDocument,
  UserWithInstitutionFragment,
  BidPageBidByIdDocument,
  MessageTopic,
  MessageCounterpartyCardListingFragment,
  MessageCounterpartyCardStandingBidFragment,
} from "@/gql";
import {
  getDiscussionTopicCounterpartyShortText,
  getDiscussionTopicCounterpartyText,
} from "@/utils";

interface MessageCounterpartyFormValues {
  readonly messageBody: string;
}

const initialValues: MessageCounterpartyFormValues = {
  messageBody: ``,
};

const validationSchema = Yup.object().shape({
  messageBody: Yup.string().min(0).nullable().required(`Required`),
});

const getMessageTopic = (
  topic:
    | MessageCounterpartyCardListingFragment
    | MessageCounterpartyCardStandingBidFragment,
  bidId?: string,
): MessageTopic =>
  match({ topic, bidId })
    .with({ bidId: P.string }, () => ({
      type: MessageTopicType.Bid,
      id: !!bidId ? bidId : ``,
    }))
    .with(
      { topic: { __typename: `Listing`, id: P._ } },
      ({ topic: { id } }) => ({
        type: MessageTopicType.Listing,
        id,
      }),
    )
    .with(
      { topic: { __typename: `StandingBid`, id: P._ } },
      ({ topic: { id } }) => ({
        type: MessageTopicType.StandingBid,
        id,
      }),
    )
    .with(P._, (topic) => {
      throw new Error(`Unknown topic in getMessageTopic: ${topic}`);
    })
    .exhaustive();

const mapVariables = (
  topic:
    | MessageCounterpartyCardListingFragment
    | MessageCounterpartyCardStandingBidFragment,
  bidId?: string,
) => (
  values: MessageCounterpartyFormValues,
): StartDiscussionMutationVariables => ({
  input: {
    ...values,
    messageTopic: getMessageTopic(topic, bidId),
  },
});

const getMessage = (
  topic:
    | MessageCounterpartyCardListingFragment
    | MessageCounterpartyCardStandingBidFragment,
  actor: UserWithInstitutionFragment,
  bidId?: string,
) => {
  if (topic.fromHiive) {
    return t(`message_description_from_hive`);
  }

  const messageTopic = getMessageTopic(topic, bidId);
  if (messageTopic.type === MessageTopicType.StandingBid) {
    return t(`message_description_standing_bid`);
  }
  return t(`message_description_actor`, {
    actor: getDiscussionTopicCounterpartyShortText(actor, topic),
    defaultValue: ``,
  });
};

const MessageCounterpartyForm = withCurrentActor(
  ({
    topic,
    bidId,
    isSubmitting,
    actor,
  }: {
    readonly topic:
      | MessageCounterpartyCardListingFragment
      | MessageCounterpartyCardStandingBidFragment;
    readonly bidId?: string;
    readonly actor: UserWithInstitutionFragment;
  } & FormikProps<MessageCounterpartyFormValues>) => (
    <Form>
      <Card variant="darkened-footer">
        <CardHeader py={4}>
          <Text textStyle={{ base: `heading-xl`, md: `heading-2xl` }}>
            {getDiscussionTopicCounterpartyText(actor, topic)}
          </Text>
        </CardHeader>
        <CardBody>
          <Text mb={4}>{getMessage(topic, actor, bidId)}</Text>
          <AutosizeTextAreaInput
            label="Message"
            name="messageBody"
            rows={5}
            minRows={5}
            maxRows={6}
            placeholder="Type message..."
          />
        </CardBody>
        <CardFooter py={6}>
          <HStack justifyContent="flex-end" w="full">
            <HiiveButton
              type="submit"
              sentryLabel="[MessageCounterpartyCard] Send"
              variant="rounded-solid-salmon"
              size="xl"
              maxW="unset"
              w={{ base: `full`, md: `170px` }}
              isLoading={isSubmitting}
            >
              Send
            </HiiveButton>
          </HStack>
        </CardFooter>
      </Card>
    </Form>
  ),
);

const MessageCounterpartyCard = ({
  topic,
  bidId,
}: {
  readonly topic:
    | MessageCounterpartyCardListingFragment
    | MessageCounterpartyCardStandingBidFragment;
  readonly bidId?: string;
}) => {
  const mutation = useStartDiscussionMutation({
    refetchQueries: [
      ListingPageListingByIdDocument,
      MyDiscussionListDiscussionsDocument,
      BidPageBidByIdDocument,
      StandingBidPageStandingBidByIdDocument,
      StandingBidSellerDiscussionListDiscussionDocument,
    ],
  });

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`startDiscussionV2`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables(topic, bidId)}
    >
      {(formikProps) => (
        <MessageCounterpartyForm topic={topic} bidId={bidId} {...formikProps} />
      )}
    </FormikQL>
  );
};

export default MessageCounterpartyCard;
