import { ReactElement, ReactNode } from "react";

import { NumberInputProps } from "@chakra-ui/react";

import { FormFieldError } from "@/components/form";

import FormNumberInputControl from "./FormNumberInputControl";
import FormNumberInputField from "./FormNumberInputField";

export interface FormNumberInputProps extends Omit<NumberInputProps, "max"> {
  /** Whether to disable the input */
  readonly readOnly?: boolean;
  /** The label displayed above the input */
  readonly label?: string;
  /** Non-error text displayed below the input */
  readonly subLabel?: string;
  /** Whether the label should be visually hidden, but still available to screen readers */
  readonly labelSrOnly?: true | "focusable";
  /** Content that displays on the right side of the label */
  readonly info?: ReactElement;
  /** The field name for a form */
  readonly name: string;
  /** A function to be called on field value change */
  readonly onChange?: (value: number | string | null) => void;
  /** A function to transform the value before the input receives it */
  readonly fromValue?: (value: number | string) => string;
  /** A function to transform the value after user input */
  readonly toValue?: (value: number | string) => string | number;
  /** The value displayed when using as a controlled input */
  readonly controlledValue?: number | null;
  /** An element to display on the right side of the input */
  readonly rightElement?: ReactNode;
  /** Whether the max input value should be extra large (1e15) */
  readonly big?: boolean;
  /** Placeholder text to display when the input is empty */
  readonly placeholder?: string;
}

const FormNumberInput = ({
  name,
  ...formNumberInputProps
}: FormNumberInputProps) => (
  <FormNumberInputControl name={name}>
    <FormNumberInputField name={name} {...formNumberInputProps} />
    <FormFieldError name={name} />
  </FormNumberInputControl>
);

export default FormNumberInput;
