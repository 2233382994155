import * as React from "react";

import { Box, BoxProps } from "@chakra-ui/react";

/**
 * @deprecated - use { Card } from "@chakra-ui/react"
 */
const Card = (props: BoxProps) => (
  <Box
    bg="white"
    my={4}
    py={4}
    px={{ base: 4, md: 6 }}
    borderRadius="md"
    w="full"
    {...props}
  />
);

export default Card;
