import { useTranslation } from "react-i18next";

import { Radio, Text, Wrap } from "@chakra-ui/react";

import { CustomTooltip, FormRadioInput } from "@/components/form";
import { ShareSeries } from "@/gql";
import { getShareSeriesText } from "@/utils";

const ShareTypeChoiceInput = () => {
  const { t } = useTranslation();

  return (
    <FormRadioInput label={t(`how_do_you_hold_shares`)} name="shareSeries">
      <Wrap spacing={6} pt={5} data-testid="share-series-input">
        <Radio variant="base" value={ShareSeries.Common}>
          <CustomTooltip tooltipContent={t(`share_type_choice_common`)}>
            <Text variant="tooltip">
              {getShareSeriesText(ShareSeries.Common)}
            </Text>
          </CustomTooltip>
        </Radio>
        <Radio variant="base" value={ShareSeries.Options}>
          <CustomTooltip tooltipContent={t(`share_type_choice_options`)}>
            <Text variant="tooltip">
              {getShareSeriesText(ShareSeries.Options)}
            </Text>
          </CustomTooltip>
        </Radio>
        <Radio variant="base" value={ShareSeries.RestrictedStockUnits}>
          <CustomTooltip tooltipContent={t(`share_type_choice_rsu`)}>
            <Text variant="tooltip">
              {getShareSeriesText(ShareSeries.RestrictedStockUnits)}
            </Text>
          </CustomTooltip>
        </Radio>
      </Wrap>
    </FormRadioInput>
  );
};

export default ShareTypeChoiceInput;
