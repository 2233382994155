import { t } from "i18next";

import { ActivityGroup, ActivitySection, WithQuery } from "@/components/common";
import {
  CompanyMarketActivityListingCard,
  CompanyMarketActivityStandingBidCard,
} from "@/components/companies";
import {
  UnaccreditedSellerCompanyByIdMarketActivityCompanyFragment,
  UnaccreditedSellerCompanyByIdMarketActivityListingFragment,
  UnaccreditedSellerCompanyByIdMarketActivityStandingBidFragment,
  useUnaccreditedSellerCompanyByIdMarketActivityQuery,
} from "@/gql";

import { ListingsEmptyState } from "./ListingsEmptyState";
import { MarketActivitySkeleton } from "./MarketActivitySkeleton";
import { StandingBidsEmptyState } from "./StandingBidsEmptyState";

const Listings = ({
  listings,
  standingBids,
  company,
}: {
  readonly listings: readonly UnaccreditedSellerCompanyByIdMarketActivityListingFragment[];
  readonly standingBids: readonly UnaccreditedSellerCompanyByIdMarketActivityStandingBidFragment[];
  readonly company: UnaccreditedSellerCompanyByIdMarketActivityCompanyFragment;
}) => (
  <ActivityGroup
    title={t(`other_sellers`)}
    emptyState={
      <ListingsEmptyState
        company={company}
        numberOfStandingBids={standingBids.length}
      />
    }
  >
    {listings.map((listing) => (
      <CompanyMarketActivityListingCard
        key={listing.id}
        listing={listing}
        company={company}
      />
    ))}
  </ActivityGroup>
);

const StandingBids = ({
  standingBids,
  listings,
  company,
}: {
  readonly standingBids: readonly UnaccreditedSellerCompanyByIdMarketActivityStandingBidFragment[];
  readonly listings: readonly UnaccreditedSellerCompanyByIdMarketActivityListingFragment[];
  readonly company: UnaccreditedSellerCompanyByIdMarketActivityCompanyFragment;
}) => (
  <ActivityGroup
    title={t(`available_standing_bids`)}
    emptyState={
      <StandingBidsEmptyState
        company={company}
        numberOfListings={listings.length}
      />
    }
  >
    {standingBids.map((standingBid) => (
      <CompanyMarketActivityStandingBidCard
        key={standingBid.id}
        company={company}
        standingBid={standingBid}
      />
    ))}
  </ActivityGroup>
);

const MarketActivityV2 = ({ id }: { id: string }) => {
  const query = useUnaccreditedSellerCompanyByIdMarketActivityQuery({
    variables: { id },
  });
  return (
    <WithQuery query={query} fallback={<MarketActivitySkeleton />}>
      {({ data: { companyById: company } }) => {
        const { othersListings, othersStandingBids } = company.activity;

        return (
          <ActivitySection title={t(`market_activity`)}>
            <StandingBids
              standingBids={othersStandingBids}
              listings={othersListings}
              company={company}
            />
            <Listings
              listings={othersListings}
              standingBids={othersStandingBids}
              company={company}
            />
          </ActivitySection>
        );
      }}
    </WithQuery>
  );
};

export default MarketActivityV2;
