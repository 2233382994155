import { ReactNode } from "react";

import { List, ListProps } from "@chakra-ui/react";

import { ItemList } from "@/components/form";

import { SelectMenuEmptyState } from "./SelectMenuEmptyState";
import { SelectMenuSkeleton } from "./SelectMenuSkeleton";
import { UseComboboxGetMenuProps } from "./types";

const ComboboxMenuEmptyState = () => <SelectMenuEmptyState />;
const ComboboxMenuSkeleton = () => <SelectMenuSkeleton />;

const ComboboxMenuContent = <TItem,>({
  children,
  items,
  isLoading,
  fallback,
}: {
  readonly children: ReactNode;
  readonly items: ItemList<TItem>;
  readonly isLoading?: boolean;
  readonly fallback?: ReactNode;
}) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isLoading) return fallback ? <>{fallback}</> : <ComboboxMenuSkeleton />;

  if (items.length === 0) return <ComboboxMenuEmptyState />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

interface ComboboxMenuProps<TItem> extends ListProps {
  readonly children: ReactNode;
  readonly isOpen: boolean;
  readonly items: ItemList<TItem>;
  readonly isLoading?: boolean;
  readonly isLazy?: boolean;
  readonly getMenuProps: UseComboboxGetMenuProps;
  readonly fallback?: ReactNode;
}

export const ComboboxMenu = <TOptionValue,>({
  isOpen,
  children,
  items,
  isLoading,
  isLazy = true,
  getMenuProps,
  fallback,
  ...listProps
}: ComboboxMenuProps<TOptionValue>) => (
  <List
    variant="menu"
    display={isOpen ? `block` : `none`}
    {...getMenuProps()}
    {...listProps}
  >
    {(isOpen || !isLazy) && (
      <ComboboxMenuContent
        items={items}
        isLoading={isLoading}
        fallback={fallback}
      >
        {children}
      </ComboboxMenuContent>
    )}
  </List>
);
