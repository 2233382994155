import { Text } from "@chakra-ui/react";

import { constants } from "@/utils";

const RepresentedStandingBidExpiryTooltip = () => (
  <Text>
    By default, Hiive sets a {constants.default_standing_bid_expire_after_days}
    {` `}
    day expiry for standing bids. After this period, your standing bid will be
    removed. You have the option to extend the duration up to{` `}
    {constants.max_standing_bid_expire_after_days} days, but it is advisable to
    select a shorter timeline to prevent your standing bid from becoming stale
    or outdated.
  </Text>
);

export default RepresentedStandingBidExpiryTooltip;
