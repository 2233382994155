import { useApolloClient } from "@apollo/client";
import { useAuthUserOrNull, useLoginActions } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";

import { useRouter } from "next/router";

import { reset as resetAuth } from "@/state/auth";
import { reset as resetPersona } from "@/state/persona";
import { getIsValidRedirectURL } from "@/utils";

const buildUnauthenticatedRedirectURL = () => {
  const asPath = window.location.pathname; // router.asPath doesn't work here

  const redirectPath =
    getIsValidRedirectURL(asPath) && ![`/`, `/dashboard`].includes(asPath)
      ? asPath
      : null;

  if (redirectPath) return `/login?redirect=${redirectPath}`;
  return `/login`;
};

export default function useSignOut() {
  const dispatch = useDispatch();
  const router = useRouter();
  const client = useApolloClient();
  const ldClient = useLDClient();
  const fronteggUser = useAuthUserOrNull();
  const { logout } = useLoginActions();

  return useCallback(
    (signOutTo?: string) => {
      const redirectUrl = buildUnauthenticatedRedirectURL();
      const path = signOutTo || redirectUrl;
      router.prefetch(path);

      ReactDOM.unstable_batchedUpdates(() => {
        client.clearStore();
        ldClient?.identify({
          key: undefined,
          anonymous: true,
        });
        dispatch(resetAuth());
        dispatch(resetPersona());
        sessionStorage.clear();
        Sentry.configureScope((scope) => scope.setUser(null));

        if (fronteggUser) {
          return logout(() => {
            router.push(path);
          });
        }

        return router.push(path);
      });
    },
    [dispatch],
  );
}
