/* eslint-disable functional/immutable-data */
const useLockBodyScroll = () => {
  const onLock = () => {
    document.body.style.overflow = `hidden`;

    const mainEl = document.getElementById(`main-element`);
    if (!mainEl) return;
    mainEl.style.overflow = `hidden`;
  };

  const onUnlock = () => {
    document.body.style.overflow = `visible`;

    const mainEl = document.getElementById(`main-element`);
    if (!mainEl) return;
    mainEl.style.overflow = `visible`;
  };

  return { onLock, onUnlock };
};

export default useLockBodyScroll;
