import { Control, FieldValues, Path, useController } from "react-hook-form";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  InputProps,
} from "@chakra-ui/react";

import { PasswordInput } from "./PasswordInput";

interface FormPasswordInputProps<TFieldValues extends FieldValues>
  extends InputProps {
  readonly control: Control<TFieldValues>;
  readonly name: Path<TFieldValues>;
  readonly label: string;
  readonly labelSrOnly?: FormLabelProps["srOnly"];
}

const FormPasswordInput = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  labelSrOnly,
  isRequired,
  ...inputProps
}: FormPasswordInputProps<TFieldValues>) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel srOnly={labelSrOnly}>
        {isRequired ? `${label} *` : label}
      </FormLabel>
      <PasswordInput {...inputProps} {...field} />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormPasswordInput;
