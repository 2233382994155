import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Fade,
  Show,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveButton,
  UnaccreditedSellerComplianceReminder,
} from "@/components/common";
import { UnaccreditedSellerAcceptAStandingBidToSellNowSvg } from "@/components/svg";
import { useCurrentActor } from "@/hooks";

export const SellNowCard = ({ id }: { id: string }) => {
  const actor = useCurrentActor();
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <Card position="relative" overflow="hidden" h="full">
      <CardBody h="full" position="relative">
        <VStack
          alignItems="flex-start"
          justifyContent="space-between"
          h="full"
          spacing={5}
        >
          <VStack alignItems="flex-start" h="full" spacing={3}>
            <Text textStyle="heading-3xl" zIndex="1">
              {t(`accept_standing_bid_to_sell`)}
            </Text>
            <Text
              textStyle="text-md"
              maxW={{ base: `unset`, xl: `55%` }}
              zIndex="1"
            >
              {t(`accept_standing_bid_to_sell_description`)}
            </Text>
          </VStack>
          {actor.identityVerified && (
            <Box>
              <HiiveButton
                variant="rounded-solid-salmon"
                size="xl"
                boxShadow="card"
                px={16}
                onClick={() => router.push(`/companies/${id}`)}
                sentryLabel="[UnaccreditedSellerDashboardPage/SellNowCard] See Available Standing Bids"
              >
                {t(`see_available_standing_bids`)}
              </HiiveButton>
            </Box>
          )}
        </VStack>
        <Show above="xl" ssr={false}>
          <Fade in>
            <Box
              position="absolute"
              zIndex="0"
              h="full"
              w="50%"
              right="0"
              top="0"
              preserveAspectRatio="xMinYMid slice"
              as={UnaccreditedSellerAcceptAStandingBidToSellNowSvg}
            />
          </Fade>
        </Show>
      </CardBody>

      {!actor.identityVerified && (
        <CardFooter bg="grey.25">
          <UnaccreditedSellerComplianceReminder />
        </CardFooter>
      )}
    </Card>
  );
};
