import { HiiveModalContentWrapper } from "@/components/common";
import { AcceptStandingBidModalStandingBidFragment } from "@/gql";

import { AcceptStandingBidSequenceModalAuthorization } from "./AcceptStandingBidSequenceModalAuthorization";

interface AcceptStandingBidSequenceModalProps {
  readonly standingBid: AcceptStandingBidModalStandingBidFragment;
}

const AcceptStandingBidSequenceModal = ({
  standingBid,
}: AcceptStandingBidSequenceModalProps) => (
  <HiiveModalContentWrapper>
    <AcceptStandingBidSequenceModalAuthorization standingBid={standingBid} />
  </HiiveModalContentWrapper>
);

export default AcceptStandingBidSequenceModal;
