import { match } from "ts-pattern";

import { UserPermissionV2, UserWithInstitutionFragment } from "@/gql";
import { useIHavePermission } from "@/hooks";
import { getAvailableInstitutionActions, getIsInstitutionUser } from "@/utils";

const useSuitabilityPageStatus = (actor: UserWithInstitutionFragment) => {
  const isInstitutionUser = getIsInstitutionUser(actor);

  const { canUpdateInstitutionSuitability } = getAvailableInstitutionActions(
    actor.institution,
  );

  const canView = useIHavePermission(UserPermissionV2.CanViewSuitability);
  const canUpdateIndividualSuitability = useIHavePermission(
    UserPermissionV2.CanUpdateSuitability,
  );

  const canUpdate = isInstitutionUser
    ? canUpdateInstitutionSuitability
    : canUpdateIndividualSuitability;

  return match({ canView, canUpdate })
    .with({ canView: false }, () => `hidden`)
    .with({ canView: true, canUpdate: false }, () => `view-only`)
    .with({ canView: true, canUpdate: true }, () => `modify`)
    .exhaustive();
};

export default useSuitabilityPageStatus;
