/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import { useFormikContext } from "formik";
import find from "lodash/find";
import isNil from "lodash/isNil";
import pluralize from "pluralize";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { GridItem, ModalBody, Show, SimpleGrid } from "@chakra-ui/react";

import {
  CompanyEligibilityCriteriaAccordion,
  CompanyEligibilityCriteriaAlert,
  FocusedShareDetails,
  FullDivider,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveNextButton,
  ShareDetailsStats,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import { CreateListingSelectCompanyInput } from "@/components/listings";
import {
  ListingSellerRoundingDisclaimer,
  ListingTransactionSummary,
  ShareSeriesMakeupInput,
} from "@/components/postings";
import {
  CreateListingSelectCompanyInputCompanyFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useModal, useStepValidator } from "@/hooks";
import {
  useManagedMarketsEnabled,
  useManagedMarketsFastFollowEnabled,
  useModifyListingNumSharesEnabled,
} from "@/hooks/featureFlags";
import {
  constants,
  getAreFeesHighForListing,
  getIsInstitutionUser,
  resetTouchedState,
  roundUp,
  sumShareSeriesMakeup,
} from "@/utils";

import {
  CreateListingSequenceModalFormValues,
  CreateListingLotFields,
} from ".";
import { CreateListingSequenceModalStepFormContext } from "./CreateListingSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";

export const useValidationSchema = () => {
  const { t } = useTranslation();
  const isModifyListingNumSharesFlagEnabled = useModifyListingNumSharesEnabled();

  const minTransactionSizeError = t(`min_transaction_size_error`, {
    minSize: constants.min_listing_size.text,
  });

  return isModifyListingNumSharesFlagEnabled
    ? Yup.object().shape({
        company: Yup.object().nullable().required(`Required`),
        pricePerShare: Yup.number()
          .nullable()
          .test({
            name: `checkListingMeetsMinValue`,
            params: {},
            message: minTransactionSizeError,
            test: function (pricePerShare: number) {
              const { company, shareSeriesMakeup } = this.parent;

              const highestCompanyBidPrice = company?.currentPrices.highestBid
                ? company.currentPrices.highestBid / 100
                : null;
              const sharePrice = pricePerShare || highestCompanyBidPrice;

              const numShares = sumShareSeriesMakeup(shareSeriesMakeup);

              return !sharePrice
                ? true
                : sharePrice * numShares >= constants.min_listing_size.number;
            },
          }),
        shareSeriesMakeup: Yup.array()
          .max(
            constants.max_share_classes_for_listing,
            `Can specify at most ${
              constants.max_share_classes_for_listing
            } ${pluralize(`class`, constants.max_share_classes_for_listing)}`,
          )
          .of(
            Yup.object().shape({
              shareSeries: Yup.string().nullable().required(`Required`),
              numShares: Yup.number()
                .nullable()
                .required(`Required`)
                .min(1, `Must have at least 1 share`)
                .test({
                  name: `checkListingMeetsMinValue`,
                  params: {},
                  message: minTransactionSizeError,
                  test: function () {
                    const shareSeriesMakeupValue: CreateListingSequenceModalFormValues = find(
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      // yup doesn't fully type the context object for version < 1.0, we will need to migrate
                      this.from,
                      `value.shareSeriesMakeup`,
                    )?.value;

                    const {
                      company,
                      pricePerShare,
                      shareSeriesMakeup,
                    } = shareSeriesMakeupValue;

                    const numShares = sumShareSeriesMakeup(shareSeriesMakeup);

                    const highestCompanyBidPrice = company?.currentPrices
                      .highestBid
                      ? company.currentPrices.highestBid / 100
                      : null;
                    const sharePrice = pricePerShare || highestCompanyBidPrice;

                    return !sharePrice
                      ? true
                      : sharePrice * numShares >=
                          constants.min_listing_size.number;
                  },
                }),
            }),
          ),
      })
    : Yup.object().shape({
        company: Yup.object().nullable().required(`Required`),
        pricePerShare: Yup.number()
          .nullable()
          .test({
            name: `checkListingMeetsMinValue`,
            params: {},
            message: `Listing price must be a minimum value of ${constants.min_listing_size.text}. Try adjusting the number of shares and/or price per share.`,
            test: function (pricePerShare: number) {
              const selectedCompany = this.parent.company;

              const highestCompanyBidPrice = selectedCompany?.currentPrices
                .highestBid
                ? selectedCompany.currentPrices.highestBid / 100
                : null;
              const sharePrice = pricePerShare || highestCompanyBidPrice;

              const numShares = sumShareSeriesMakeup(
                this.parent.shareSeriesMakeup,
              );

              return !sharePrice
                ? true
                : sharePrice * numShares >= constants.min_listing_size.number;
            },
          }),
        shareSeriesMakeup: Yup.array()
          .max(
            constants.max_share_classes_for_listing,
            `Can specify at most ${
              constants.max_share_classes_for_listing
            } ${pluralize(`class`, constants.max_share_classes_for_listing)}`,
          )
          .of(
            Yup.object().shape({
              shareSeries: Yup.string().nullable().required(`Required`),
              numShares: Yup.number()
                .nullable()
                .min(1, `Must have at least 1 share`)
                .required(`Required`),
            }),
          ),
      });
};

const ListingShareDetails = ({
  formValues,
}: {
  readonly formValues: CreateListingSequenceModalFormValues;
}) => {
  const { t } = useTranslation();
  const numShares = sumShareSeriesMakeup(formValues.shareSeriesMakeup);
  const pricePerShareCents = !isNil(formValues.pricePerShare)
    ? formValues.pricePerShare * 100
    : null;

  const displayedNumberOfShares = roundUp(numShares);

  return (
    <FocusedShareDetails.Header
      title={t(`listing_preview`)}
      variant="listing-preview"
    >
      <FocusedShareDetails.HeaderCard>
        <ShareDetailsStats
          numberOfShares={displayedNumberOfShares}
          pricePerShare={pricePerShareCents}
        />
      </FocusedShareDetails.HeaderCard>
      <ListingSellerRoundingDisclaimer />
    </FocusedShareDetails.Header>
  );
};

interface ShareDetailsProps
  extends StepPropsV2<StepKeys, CreateListingSequenceModalFormValues> {
  readonly initialCompany?: CreateListingSelectCompanyInputCompanyFragment;
  readonly actor: UserWithInstitutionFragment;
}

const CreateListingShareDetails = ({
  initialCompany,
  actor,
  values,
  stepRouter,
  errors,
}: ShareDetailsProps) => {
  const { company, shareSeriesMakeup } = values;
  const { stepControls } = stepRouter;

  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { touched, setTouched } = useFormikContext();

  const validationSchema = useValidationSchema();

  const onSuccess = () => {
    stepControls.nextStep();
    // Workaround for Formik setting every field in the form to touched
    // on submit. Get rid of me when we move to react-hook-form :'(
    resetTouchedState<CreateListingSequenceModalFormValues>({
      fields: touched,
      setTouched,
    });
  };

  useStepValidator({
    Context: CreateListingSequenceModalStepFormContext,
    stepKey: stepKeys.createListingShareDetails,
    validator: {
      validationSchema,
      onSuccess,
    },
    values,
  });

  const minTransactionSizeError = t(`min_transaction_size_error`, {
    minSize: constants.min_listing_size.text,
  });

  const pricePerShareDollars = !isNil(values.pricePerShare)
    ? values.pricePerShare
    : null;

  const isHighFeesWarningVisible =
    !isNil(values.pricePerShare) &&
    !errors.pricePerShare &&
    getAreFeesHighForListing({
      highestBid: company?.currentPrices.highestBid,
      pricePerShare: values.pricePerShare,
      numberOfShares: sumShareSeriesMakeup(values.shareSeriesMakeup),
    });

  const managedMarketsEnabled = !!useManagedMarketsEnabled();
  const managedMarketsFastFollow = !!useManagedMarketsFastFollowEnabled();
  const isInstitutionUser = getIsInstitutionUser(actor);
  const eligibilityCriterionText =
    values.company?.companyEligibilityCriterion?.text ||
    initialCompany?.companyEligibilityCriterion?.text;

  const showEligibilityCriterionText =
    managedMarketsEnabled &&
    !isInstitutionUser &&
    eligibilityCriterionText &&
    !managedMarketsFastFollow;

  const showEligibilityCriterionAccordion =
    managedMarketsFastFollow && eligibilityCriterionText;

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          {!initialCompany && (
            <>
              <GridItem colSpan={2}>
                <CreateListingSelectCompanyInput
                  name="company"
                  label="Which company?"
                />
              </GridItem>
              <GridItem colSpan={2}>
                <FullDivider />
              </GridItem>
            </>
          )}

          {showEligibilityCriterionText && (
            <GridItem colSpan={2}>
              <CompanyEligibilityCriteriaAlert
                markdown={eligibilityCriterionText}
              />
            </GridItem>
          )}
          {showEligibilityCriterionAccordion && company && (
            <GridItem colSpan={2}>
              <CompanyEligibilityCriteriaAccordion
                companyName={company.name}
                markdown={eligibilityCriterionText}
              />
            </GridItem>
          )}

          <GridItem colSpan={2}>
            <CreateListingLotFields />
          </GridItem>

          <GridItem colSpan={2}>
            <ListingTransactionSummary
              isHighFeesWarningVisible={isHighFeesWarningVisible}
              pricePerShare={pricePerShareDollars}
              shareSeriesMakeup={shareSeriesMakeup}
              companyId={company?.id}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <ListingShareDetails formValues={values} />
          </GridItem>
          <GridItem colSpan={2}>
            <FullDivider />
          </GridItem>
          <GridItem colSpan={2}>
            <ShareSeriesMakeupInput
              excludedErrorMessages={[minTransactionSizeError]}
            />
          </GridItem>
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[CreateListing/ShareDetails/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveNextButton
          type="submit"
          sentryLabel="[CreateListing/ShareDetails/Submit]"
        />
      </HiiveModalFooter>
    </>
  );
};

export default CreateListingShareDetails;
