import currency from "currency.js";
import { Form } from "formik";
import { AnimatePresence } from "framer-motion";
import isNil from "lodash/isNil";

import { AnimatedModalStep, StepFormikQL } from "@/components/form";
import {
  BidPageMyActivityDocument,
  CompanyWatchlistActorDocument,
  ListingPageListingByIdDocument,
  PlaceBidSequenceModalContentCompanyFragment,
  PlaceBidSequenceModalContentListingFragment,
  usePlaceBidSequenceModalPlaceBidMutation,
  UserActivityMyActivityDocument,
} from "@/gql";
import { useStepRouter } from "@/hooks";

import {
  PlaceBid,
  PlaceBidConfirmation,
  PlaceBidSequenceModalFormValues,
  PlaceBidSequenceModalStepFormContext,
  PlaceBidSuccess,
  stepKeys,
  StepKeys,
} from "./steps";

const createInitialValues = (
  initialListing?: PlaceBidSequenceModalContentListingFragment,
) => ({
  listingId: initialListing ? initialListing.id : ``,
  numShares: 0,
  pricePerShare:
    initialListing && !isNil(initialListing.listingPricePerShare)
      ? initialListing.listingPricePerShare / 100
      : ``,
  timeLimit: 7,
  confirmed: false,
  muteNotifyWatchers: false,
  representedEmail: ``,
  representedFirstName: ``,
  representedLastName: ``,
  notifyRepresentingEmail: true,
});

const mapVariables = ({
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  confirmed,
  pricePerShare,
  timeLimit,
  unsolicited,
  representedEmail,
  representedFirstName,
  representedLastName,
  ...values
}: PlaceBidSequenceModalFormValues) => ({
  input: {
    ...values,
    // Parse unsolicited to solicited, would be undefined for non broker users
    solicited: unsolicited === undefined ? undefined : !unsolicited,
    pricePerShare: currency(pricePerShare).intValue,
    timeLimit: timeLimit * 24,
    representedUser: {
      email: representedEmail,
      firstName: representedFirstName,
      lastName: representedLastName,
    },
  },
});

export const PlaceBidSequenceModalContent = ({
  company,
  initialListing,
}: {
  readonly company: PlaceBidSequenceModalContentCompanyFragment;
  readonly initialListing?: PlaceBidSequenceModalContentListingFragment;
}) => {
  const mutation = usePlaceBidSequenceModalPlaceBidMutation({
    refetchQueries: [
      ListingPageListingByIdDocument,
      BidPageMyActivityDocument,
      UserActivityMyActivityDocument,
      CompanyWatchlistActorDocument,
    ],
  });

  const initialValues = createInitialValues(initialListing);

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.placeBid,
      stepKeys.placeBidConfirmation,
      stepKeys.placeBidSuccess,
    ],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  return (
    <StepFormikQL
      stepRouter={stepRouter}
      mutation={mutation}
      mutationNames={[`placeBid`]}
      mapVariables={mapVariables}
      initialValues={initialValues}
      context={PlaceBidSequenceModalStepFormContext}
    >
      {({ formikProps }) => (
        <Form>
          <AnimatePresence mode="wait" initial={false}>
            {stepKeys.placeBid === currentStepKey && (
              <AnimatedModalStep key={stepKeys.placeBid}>
                <PlaceBid
                  initialListing={initialListing}
                  stepRouter={stepRouter}
                  company={company}
                  {...formikProps}
                />
              </AnimatedModalStep>
            )}
            {stepKeys.placeBidConfirmation === currentStepKey && (
              <AnimatedModalStep key={stepKeys.placeBidConfirmation}>
                <PlaceBidConfirmation
                  stepRouter={stepRouter}
                  {...formikProps}
                />
              </AnimatedModalStep>
            )}
            {stepKeys.placeBidSuccess === currentStepKey && (
              <AnimatedModalStep key={stepKeys.placeBidSuccess}>
                <PlaceBidSuccess />
              </AnimatedModalStep>
            )}
          </AnimatePresence>
        </Form>
      )}
    </StepFormikQL>
  );
};
