import i18next from "i18next";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ModalBody, Text } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormPhoneNumberInput } from "@/components/react-hook-form";
import { useUpdateUserPhoneNumberMutation } from "@/gql";
import { useCurrentActor, useCustomToast, useModal } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { ToastDuration } from "@/types/toast";
import { validPhoneNumber } from "@/utils";
import { normalizePhoneNumber } from "@/utils/format";

const getValidationSchema = (t: typeof i18next.t) =>
  Yup.object().shape({
    phoneNumber: Yup.string()
      .nullable()
      .required(t(`phone_number_is_required`))
      .test(
        t(`valid_phone_number`),
        t(`please_enter_a_valid_phone_number`),
        validPhoneNumber,
      ),
  });

interface ChangePhoneNumberFormValues {
  readonly phoneNumber: string;
}

const createInitialValues = (phoneNumber: string) => ({
  phoneNumber,
});

const mapVariables = (values: ChangePhoneNumberFormValues) => ({
  phoneNumber: normalizePhoneNumber(values.phoneNumber),
});

const ChangePhoneModal = () => {
  const actor = useCurrentActor();
  const { t } = useTranslation();
  const { errorToast, successToast } = useCustomToast();
  const mutation = useUpdateUserPhoneNumberMutation();
  const { closeModal } = useModal();

  const { phoneNumber } = actor;

  const validationSchema = getValidationSchema(t);
  const initialValues = createInitialValues(phoneNumber);

  const onSuccess = () => {
    closeModal();
    successToast(t(`phone_change_success_message`), {
      duration: ToastDuration.Long,
    });
  };

  const onError = () => {
    errorToast(t(`phone_change_error_message`), {
      duration: ToastDuration.Long,
    });
  };

  const { handleSubmit, isLoading, control, watch } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
    onError,
  });

  const phoneNumberValue = watch(`phoneNumber`);

  const phoneNumberMatches =
    normalizePhoneNumber(phoneNumber) ===
    normalizePhoneNumber(phoneNumberValue);

  return (
    <HiiveModalContentWrapper>
      <form onSubmit={handleSubmit}>
        <HiiveModalHeader>
          <Text>{t(`change_phone`)}</Text>
        </HiiveModalHeader>
        <ModalBody>
          <FormPhoneNumberInput
            control={control}
            label={t(`telephone`)}
            name="phoneNumber"
          />
        </ModalBody>
        <HiiveModalFooter>
          <ButtonFooterWrapper>
            <HiiveCancelButton
              onCancel={closeModal}
              sentryLabel="[ChangePhone/Cancel]"
            />
            <HiiveSubmitButton
              isDisabled={phoneNumberMatches}
              isLoading={isLoading}
              sentryLabel="[ChangePhone/Submit]"
              submitText={t(`update_phone`)}
              type="submit"
            />
          </ButtonFooterWrapper>
        </HiiveModalFooter>
      </form>
    </HiiveModalContentWrapper>
  );
};

export default ChangePhoneModal;
