import { Show, SimpleGrid, VStack } from "@chakra-ui/react";

import { FullContentWrapper } from "@/components/common";
import { useHiive50Enabled } from "@/hooks/featureFlags";

import { DashboardActions } from "./DashboardActions";
import { DashboardHiive50Card } from "./DashboardHiive50Card";
import { DashboardLatestMarketActivity } from "./DashboardLatestMarketActivity";
import { DashboardMostActiveCompanies } from "./DashboardMostActiveCompanies";
import { DashboardWatchlist } from "./DashboardWatchlist";
import { DashboardYourActivity } from "./DashboardYourActivity";

export const DesktopAccreditedDashboardPage = () => {
  const isHiive50Enabled = useHiive50Enabled();

  return (
    <FullContentWrapper px={{ md: 4, lg: 8 }}>
      <SimpleGrid
        w="full"
        h="full"
        maxW={{
          base: `max-width-md`,
          "2xl": `max-width-lg`,
        }}
        gridRowGap={4}
        gridColumnGap={6}
        gridTemplateColumns={{ base: `416px 1fr`, "2xl": `416px 1fr 416px` }}
      >
        <VStack w="full" spacing={4}>
          <DashboardActions />
          <DashboardWatchlist />
          <DashboardMostActiveCompanies />
          <Show below="2xl" ssr={false}>
            <DashboardLatestMarketActivity />
          </Show>
        </VStack>
        <VStack w="full" spacing={8}>
          {isHiive50Enabled && <DashboardHiive50Card />}
          <DashboardYourActivity />
        </VStack>
        <Show above="2xl" ssr={false}>
          <DashboardLatestMarketActivity />
        </Show>
      </SimpleGrid>
    </FullContentWrapper>
  );
};
