import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { CreateListingMutation } from "@/gql";

import { StepKeys } from "./steps";

export const BrokerSubmitListingSequenceModalStepFormContext = createStepFormContext<
  StepKeys,
  CreateListingMutation
>();

export const useBrokerSubmitListingSequenceModalStepFormContext = () =>
  useContext(BrokerSubmitListingSequenceModalStepFormContext);
