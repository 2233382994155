import { isNegativeTrend, isNeutralTrend } from "./rounding";

export const chakraColor = (color: string) =>
  `var(--chakra-colors-${color.replace(`.`, `-`)})`;

export const getTrendPercentageColor = (percentage: string | number) => {
  const negativeTrend = isNegativeTrend(percentage);
  const neutralTrend = isNeutralTrend(percentage);

  const [positiveColor, negativeColor, neutralColor] = [
    `chart.positiveTrend`,
    `chart.negativeTrend`,
    `grey.500`,
  ];

  if (neutralTrend) {
    return neutralColor;
  }
  if (negativeTrend) {
    return negativeColor;
  }

  return positiveColor;
};
