import { Box, HStack, Text } from "@chakra-ui/react";

const ActivityCardNewBadge = () => (
  <HStack px={2} py={1} bg="grey.50" textStyle="heading-xs" borderRadius="md">
    <Box w="3" h="3" bg="salmon.900" borderRadius="full" />
    <Text>New</Text>
  </HStack>
);

export default ActivityCardNewBadge;
