import { match, P } from "ts-pattern";

import {
  SensitiveText,
  Skeleton,
  Tile,
  TileHeader,
  TileRow,
} from "@/components/common";
import { withConfig } from "@/components/hoc";
import {
  ListingPageListingStatusTileListingFragment,
  ListingState,
  RootConfigFragment,
} from "@/gql";
import {
  getLongDocumentTitleByTransferMethod,
  getShortDocumentTitleByTransferMethod,
  hoursToDays,
} from "@/utils";
import { format, getHasExpired } from "@/utils/datetime";

const ListingStatusTileSkeleton = () => <Skeleton h="5rem" w="full" />;

interface ListingStatusTileProps {
  readonly listing: ListingPageListingStatusTileListingFragment;
  readonly config: RootConfigFragment;
}

const ListingStatusTile = withConfig(
  ({ listing, config }: ListingStatusTileProps) => {
    const documentTypeLongText = getLongDocumentTitleByTransferMethod(
      listing.transferMethod,
    );
    const documentTypeShortText = getShortDocumentTitleByTransferMethod(
      listing.transferMethod,
    );

    const statusText = match(listing)
      .with(
        { state: ListingState.ConditionallySold },
        () =>
          `Conditionally Sold - This lot is sold pending execution of the ${documentTypeLongText}.
      If the ${documentTypeShortText} is not signed within ${hoursToDays(
            config.stnLoiHoursValidHours,
          )} days,
      then this lot will be re-listed.`,
      )
      .with(
        {
          state: P.union(ListingState.Closed, ListingState.Withdrawn),
        },
        () => `Closed - This listing is closed and no longer accepting bids.`,
      )
      .otherwise(() => null);

    const showExpiry =
      !!listing.expireAt &&
      [ListingState.Open, ListingState.Expired].includes(listing.state);

    return (
      <Tile
        py={2}
        gap={4}
        display="grid"
        gridTemplateColumns={{ base: `1fr`, md: `auto 1fr` }}
        alignItems="baseline"
        data-testid="listing-status-tile"
      >
        {showExpiry && (
          <TileRow>
            <TileHeader>
              {getHasExpired(listing.expireAt) ? `Expired` : `Expires`}
            </TileHeader>
            <SensitiveText>
              {format(`Do MMM (h:mm A z)`, listing.expireAt)}
            </SensitiveText>
          </TileRow>
        )}
        {statusText && (
          <TileRow>
            <TileHeader>Status</TileHeader>
            <SensitiveText>{statusText}</SensitiveText>
          </TileRow>
        )}
      </Tile>
    );
  },
  { fallback: <ListingStatusTileSkeleton /> },
);

export default ListingStatusTile;
