import { Box, SimpleGrid, VStack } from "@chakra-ui/react";

import {
  FeeDiscountAlert,
  FullContentWrapper,
  MobileTab,
  MobileTabList,
  MobileTabPanel,
  MobileTabPanels,
  MobileTabs,
  WithQuery,
} from "@/components/common";
import {
  CompanyPageContentCompanyFragment,
  useCompanyPageContentQuery,
} from "@/gql";
import { useIsDesktop } from "@/hooks";

import { CompanyActions } from "./CompanyActions";
import { CompanyActivity } from "./CompanyActivity";
import { CompanyInfo } from "./CompanyInfo";
import { MarketActivity } from "./MarketActivity";
import { YourActivity } from "./YourActivity";
import useFeeDiscountAlert from "./useFeeDiscountAlert";

const DesktopCompanyPageContent = ({
  companyId,
}: {
  readonly companyId: string;
}) => (
  <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
    <CompanyInfo companyId={companyId} />
    <CompanyActivity companyId={companyId} />
  </SimpleGrid>
);

interface MobileCompanyPageContentProps {
  readonly company: CompanyPageContentCompanyFragment;
}

function MobileCompanyPageContent({ company }: MobileCompanyPageContentProps) {
  const { id: companyId } = company;

  const { feeDiscount, feeDiscountAlertType } = useFeeDiscountAlert({
    company,
  });

  return (
    <>
      {feeDiscountAlertType && (
        <Box mb={4}>
          <FeeDiscountAlert
            companyName={company.name}
            feeDiscount={feeDiscount}
            type={feeDiscountAlertType}
          />
        </Box>
      )}
      <MobileTabs>
        <MobileTabList>
          <MobileTab>Overview</MobileTab>
          <MobileTab>Your Activity</MobileTab>
          <MobileTab>Market Activity</MobileTab>
        </MobileTabList>
        <MobileTabPanels>
          <MobileTabPanel>
            <CompanyInfo companyId={companyId} />
          </MobileTabPanel>
          <MobileTabPanel>
            <VStack spacing={8}>
              <CompanyActions companyId={companyId} />
              <YourActivity companyId={companyId} />
            </VStack>
          </MobileTabPanel>
          <MobileTabPanel>
            <MarketActivity companyId={companyId} />
          </MobileTabPanel>
        </MobileTabPanels>
      </MobileTabs>
    </>
  );
}

interface MobileCompanyPageProps {
  readonly companyId: string;
}

function MobileCompanyPage({ companyId }: MobileCompanyPageProps) {
  const query = useCompanyPageContentQuery({
    variables: {
      id: companyId,
    },
    fetchPolicy: `cache-and-network`,
  });

  return (
    <WithQuery query={query}>
      {({ data: { companyById: company } }) => (
        <MobileCompanyPageContent company={company} />
      )}
    </WithQuery>
  );
}

export const CompanyPageContent = ({
  companyId,
}: {
  readonly companyId: string;
}) => {
  const isDesktopView = useIsDesktop();

  return (
    <FullContentWrapper px={{ base: 4, lg: 8 }}>
      <Box w="full" maxW="max-width-md">
        {isDesktopView ? (
          <DesktopCompanyPageContent companyId={companyId} />
        ) : (
          <MobileCompanyPage companyId={companyId} />
        )}
      </Box>
    </FullContentWrapper>
  );
};
