import { ImageSquare } from "@phosphor-icons/react";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { DashboardModal } from "@uppy/react";
import Transloadit from "@uppy/transloadit";
import { useState } from "react";

import { Box, Flex, Image } from "@chakra-ui/react";

import { WithMutation, HiiveButton } from "@/components/common";
import {
  TransloaditParams,
  TransloaditTemplateKind,
  useGenerateTransloaditParamsMutation,
} from "@/gql";

const defaultRestrictions = {
  allowedFileTypes: [`image/*`],
  maxFileSize: 1024 * 1024 * 200,
  maxNumberOfFiles: 1,
};

const UppyDashboard = ({
  transloaditParams,
  open,
  onClose,
  onComplete,
}: {
  readonly transloaditParams: TransloaditParams;
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onComplete: (url: string) => void;
}) => {
  const [uppy] = useState(() => {
    const uppyInstance = new Uppy({
      restrictions: defaultRestrictions,
      autoProceed: true,
    }).use(Transloadit, {
      // eslint-disable-next-line
      // @ts-ignore
      params: transloaditParams.params,
      waitForEncoding: true,
      signature: transloaditParams.signature,
      fields: {},
      limit: 0,
    });

    uppyInstance.on(`transloadit:result`, (stepName, result) => {
      if (stepName === `resized` && result?.ssl_url) {
        onComplete(result?.ssl_url);
      }
    });

    return uppyInstance;
  });

  return <DashboardModal uppy={uppy} open={open} onRequestClose={onClose} />;
};

const ImageUploader = ({
  currentImageUrl,
  templateKind,
  onUploadComplete,
}: {
  readonly currentImageUrl?: string;
  readonly templateKind: TransloaditTemplateKind;
  readonly onUploadComplete: (url: string) => void;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <WithMutation
      mutation={useGenerateTransloaditParamsMutation({
        variables: { templateKind },
      })}
    >
      {({ data }) => (
        <div>
          <Flex align="center">
            {currentImageUrl ? (
              <Image
                src={currentImageUrl}
                alt="Company logo"
                h="64px"
                objectFit="cover"
              />
            ) : (
              <ImageSquare size={64} />
            )}
            <Box w="10px" />

            <HiiveButton
              size="md"
              variant="rounded-outline-salmon"
              onClick={() => setOpen(true)}
              sentryLabel="[ImageUploader] Upload"
            >
              Upload
            </HiiveButton>
          </Flex>

          <UppyDashboard
            transloaditParams={data.generateTransloaditParams}
            open={open}
            onClose={() => setOpen(false)}
            onComplete={onUploadComplete}
          />
        </div>
      )}
    </WithMutation>
  );
};
export default ImageUploader;
