import { useMemo } from "react";

import {
  Maybe,
  TransactionPermission,
  UseDocumentSignersTransactionFragment,
} from "@/gql";
import { getIsUnaccreditedSeller } from "@/utils";
import * as datetime from "@/utils/datetime";

import { iHaveEntityPermission } from "./myPermissions";
import useCurrentActor from "./useCurrentActor";
import useHaveISignedDocument from "./useHaveISignedDocument";

const useDocumentSigners = (
  transaction: Maybe<UseDocumentSignersTransactionFragment> | undefined,
) => {
  const { document } = transaction || {};
  const actor = useCurrentActor();
  const { id: myId, institutionId: myInstitutionId } = actor;

  const { invitedAlternateSigners = [] } = document || {};

  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  const alternateSignerId = useMemo(
    () =>
      document?.signers.find(
        (signer) =>
          signer.alternateSignerId &&
          (signer.userId === myId ||
            (signer.institutionId && signer.institutionId === myInstitutionId)),
      )?.alternateSignerId,
    [document?.signers, myId, myInstitutionId],
  );

  const invitedAlternateSigner = useMemo(
    () =>
      invitedAlternateSigners
        .slice()
        .sort((signerA, signerB) =>
          datetime.getIsBefore(signerA.insertedAt, signerB.insertedAt) ? 1 : -1,
        )[0],
    [invitedAlternateSigners],
  );

  const hasInvitedAlternateSigner = !!invitedAlternateSigner;

  const alternateSigner = useMemo(
    () =>
      invitedAlternateSigners?.find(
        (signer) => signer.id === alternateSignerId,
      ),
    [invitedAlternateSigners, alternateSignerId],
  );

  const hasNoSigner = !document?.signers?.length;

  const haveISigned = useHaveISignedDocument(transaction?.document);

  const hasCounterSigned = !haveISigned && document?.signers.length === 1;

  const haveBothSigned = document?.signers.length === 2;

  const canViewTransactionDocument = haveISigned && !alternateSigner;

  const canRevokeAlternateSignerInvitation =
    hasInvitedAlternateSigner &&
    (invitedAlternateSigner.senderId === myId ||
      (!!invitedAlternateSigner.institutionId &&
        invitedAlternateSigner.institutionId === myInstitutionId)) &&
    !haveISigned;

  const canInviteAlternateSigner =
    iHaveEntityPermission(
      transaction,
      TransactionPermission.InviteAlternateSigner,
    ) &&
    !haveISigned &&
    !isUnaccreditedSeller &&
    !hasInvitedAlternateSigner;

  const canSignTransactionDocument = iHaveEntityPermission(
    transaction,
    TransactionPermission.SignTransactionDocument,
  );

  const canSignForInstitution =
    Boolean(myInstitutionId) && canSignTransactionDocument;

  return {
    alternateSigner,
    canRevokeAlternateSignerInvitation,
    invitedAlternateSigner,
    haveISigned,
    canInviteAlternateSigner,
    canSignTransactionDocument,
    canViewTransactionDocument,
    canSignForInstitution,
    hasCounterSigned,
    haveBothSigned,
    hasNoSigner,
  };
};

export default useDocumentSigners;
