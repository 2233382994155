import { Plus } from "@phosphor-icons/react";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  VStack,
  Text,
  useDisclosure,
  Divider,
  Switch,
  HStack,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";
import { Indicator } from "@/gql";
import { useColors } from "@/hooks";

import { PricingChartContext } from "./PricingChartProvider";
import { TrendLineButton } from "./TrendLineButton";
import { TREND_LINES_CONTENT, TREND_LINES_TO_OPTIONS } from "./constants";
import { TrendLine, OptionsState } from "./types";
import { isAggregateOnlyOptionAvailable } from "./utils";

const TREND_LINES = [
  Indicator.IndexPrice,
  Indicator.IndexPriceTransferTypeDirect,
  Indicator.IndexPriceTransferTypeIndirect,
  Indicator.IndexPriceShareTypeCommon,
  Indicator.IndexPriceShareTypePreferred,
];

export const AdvancedOptions = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const [grey900] = useColors([`grey.900`]);

  const {
    optionsState,
    selectedTrendLinesCount,
    handleOptionToggle,
    handleOptionToggleOff,
    handleResetOptions,
    handleApplyOptions,
  } = useContext(PricingChartContext);

  const bidsAsksTogglesDisabled = selectedTrendLinesCount > 1;
  const hideOptions = isAggregateOnlyOptionAvailable(optionsState);

  const isSelectedTrendLine = (option: TrendLine) => {
    const trendLineOption = TREND_LINES_TO_OPTIONS[
      option
    ] as keyof OptionsState;
    return optionsState[trendLineOption].selected;
  };

  const isTrendLineAvailable = (option: TrendLine) => {
    const trendLineOption = TREND_LINES_TO_OPTIONS[
      option
    ] as keyof OptionsState;
    return optionsState[trendLineOption].available;
  };

  const isOnlySelectedTrendLine = (option: TrendLine) =>
    selectedTrendLinesCount === 1 && isSelectedTrendLine(option);

  const handleTrendLineChange = (option: TrendLine) => {
    // prevent deselection of the last selected trend line
    if (isOnlySelectedTrendLine(option)) return;
    const trendLineOption = TREND_LINES_TO_OPTIONS[
      option
    ] as keyof OptionsState;
    handleOptionToggle(trendLineOption);
  };

  const handleApply = useCallback(() => {
    handleApplyOptions();
    onClose();
  }, [handleApplyOptions]);

  const handleReset = useCallback(() => {
    handleResetOptions();
    onClose();
  }, []);

  useEffect(() => {
    // toggle off bids/asks if multiple trend lines are selected
    if (bidsAsksTogglesDisabled) {
      handleOptionToggleOff(`postedBidsAsks`);
      handleOptionToggleOff(`acceptedBids`);
    }
  }, [bidsAsksTogglesDisabled]);

  if (hideOptions) return null;

  return (
    <Menu
      placement="bottom-end"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <MenuButton
        as={Button}
        variant="outline"
        leftIcon={<Plus size={12} color={grey900} />}
        onClick={onToggle}
        px={4}
        minW="auto"
      >
        <Text textStyle="heading-xs">{t(`advanced_options`)}</Text>
      </MenuButton>
      <MenuList
        w={{ base: `calc(100vw - var(--chakra-sizes-16))`, md: 138 }}
        p={{ base: 3, md: 4 }}
      >
        <VStack
          alignItems="start"
          mb={{ base: 2, md: 4 }}
          spacing={{ base: 0, md: 2 }}
        >
          <Text textStyle="heading-lg">{t(`advanced_options`)}</Text>
          <Text textStyle="text-sm">{t(`chart_options_description`)}</Text>
        </VStack>
        <VStack alignItems="start" w="full" mb={{ base: 2, md: 4 }}>
          {TREND_LINES.map((option: TrendLine) => {
            const isSelected = isSelectedTrendLine(option);
            const isAvailable = isTrendLineAvailable(option);
            const { color, label, description } = TREND_LINES_CONTENT[option];
            return (
              isAvailable && (
                <TrendLineButton
                  key={option}
                  option={option}
                  isSelected={isSelected}
                  onClick={handleTrendLineChange}
                  color={color}
                  label={label}
                  description={description}
                />
              )
            );
          })}
        </VStack>
        <VStack alignItems="start" mt={2}>
          {optionsState.postedBidsAsks.available && (
            <CustomTooltip
              tooltipContent={
                bidsAsksTogglesDisabled
                  ? t(`multiple_trend_lines_unavailable`)
                  : ``
              }
            >
              <HStack>
                <Switch
                  size="md"
                  isChecked={optionsState.postedBidsAsks.selected}
                  disabled={bidsAsksTogglesDisabled}
                  colorScheme="salmonPink"
                  onChange={() =>
                    handleOptionToggle(
                      `postedBidsAsks` as keyof typeof optionsState,
                    )
                  }
                />
                <Text
                  textStyle="text-sm"
                  color={bidsAsksTogglesDisabled ? `grey.500` : `grey.900`}
                >
                  {t(`show_individual_bids_asks`)}
                </Text>
              </HStack>
            </CustomTooltip>
          )}
          {optionsState.acceptedBids.available && (
            <CustomTooltip
              tooltipContent={
                bidsAsksTogglesDisabled
                  ? t(`multiple_trend_lines_unavailable`)
                  : ``
              }
            >
              <HStack>
                <Switch
                  size="md"
                  isChecked={optionsState.acceptedBids.selected}
                  disabled={bidsAsksTogglesDisabled}
                  colorScheme="salmonPink"
                  onChange={() =>
                    handleOptionToggle(
                      `acceptedBids` as keyof typeof optionsState,
                    )
                  }
                />
                <Text
                  textStyle="text-sm"
                  color={bidsAsksTogglesDisabled ? `grey.500` : `grey.900`}
                >
                  {t(`show_accepted_bids`)}
                </Text>
              </HStack>
            </CustomTooltip>
          )}
          {optionsState.lastRoundPps.available && (
            <HStack>
              <Switch
                size="md"
                isChecked={optionsState.lastRoundPps.selected}
                colorScheme="salmonPink"
                onChange={() =>
                  handleOptionToggle(
                    `lastRoundPps` as keyof typeof optionsState,
                  )
                }
              />
              <Text textStyle="text-sm">
                {t(`show_last_primary_fundraise`)}
              </Text>
            </HStack>
          )}
        </VStack>
        <Divider my={4} />
        <HStack justifyContent="end">
          <Button
            variant="rounded-outline-salmon"
            onClick={handleReset}
            size={{ base: `xs`, sm: `md` }}
          >
            {t(`reset`)}
          </Button>
          <Button
            variant="rounded-solid-salmon"
            onClick={handleApply}
            size={{ base: `xs`, sm: `md` }}
          >
            {t(`apply`)}
          </Button>
        </HStack>
      </MenuList>
    </Menu>
  );
};
