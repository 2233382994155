import { match } from "ts-pattern";

import { ComplianceReminderV2 } from "@/components/common";
import { TradeIntentType } from "@/types";

const ComplianceReminder = ({
  tradeIntent,
}: {
  readonly tradeIntent?: TradeIntentType;
}) => (
  <ComplianceReminderV2>
    {match<TradeIntentType | undefined>(tradeIntent)
      .with(TradeIntentType.Buy, () => <ComplianceReminderV2.BuyMessage />)
      .with(TradeIntentType.Sell, () => <ComplianceReminderV2.SellMessage />)
      .otherwise(() => (
        <ComplianceReminderV2.Message />
      ))}
  </ComplianceReminderV2>
);

export default ComplianceReminder;
