import { ReactNode } from "react";

import { Button } from "@chakra-ui/react";

interface SectionNavButtonProps {
  readonly children: ReactNode;
  readonly isActive: boolean;
  readonly isDisabled?: boolean;
  readonly onClick?: () => void;
}

const SectionNavButton = ({
  children,
  isActive,
  isDisabled,
  onClick,
}: SectionNavButtonProps) => (
  <Button
    variant="unstyled"
    pl={5}
    pr={5}
    justifyContent="flex-start"
    alignItems="flex-start"
    textAlign="start"
    w="full"
    maxW="unset"
    borderWidth={1}
    borderColor={isActive ? `grey.100` : `transparent`}
    bg={isActive ? `grey.50` : `white`}
    _hover={{ bg: `grey.50` }}
    _active={{ bg: `grey.75` }}
    color={isDisabled ? `grey.300` : `grey.900`}
    transition=".2s all ease-in-out"
    py={2}
    h="auto"
    isDisabled={isDisabled}
    onClick={onClick}
    {...(isActive && {
      "aria-current": `step`,
    })}
    textTransform="capitalize"
  >
    {children}
  </Button>
);

export default SectionNavButton;
