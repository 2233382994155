import { Text, VStack } from "@chakra-ui/react";

import { HiiveButton, MailtoLink } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  CurrentContextDocument,
  UserWithInstitutionFragment,
  useUpdateInstitutionMutation,
} from "@/gql";
import { useMutationWithError, useResumeOnboarding } from "@/hooks";
import { constants } from "@/utils";

const InstitutionalNeedsFurtherVerificationPage = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { routeToFirstIncompleteStep } = useResumeOnboarding();

  const [updateInstitutionMutation, loading] = useMutationWithError(
    useUpdateInstitutionMutation(),
    `updateInstitution`,
  );

  const onSuccess = () => routeToFirstIncompleteStep();

  const onSubmit = () => {
    if (actor.institutionId) {
      updateInstitutionMutation({
        variables: {
          institutionId: actor.institutionId,
          input: {
            isBrokerDealer: false,
          },
        },
        refetchQueries: [CurrentContextDocument],
      }).then(onSuccess);
    }
  };

  return (
    <VStack
      spacing={6}
      textStyle="deprecated-text-xl"
      textAlign="center"
      pt={6}
    >
      <Text>
        Thank you for your interest in Hiive. We will be in touch with you
        shortly to discuss how we can work together.
      </Text>
      <Text>
        If you have any questions in the meantime, please reach out to us at:
        {` `}
        <MailtoLink
          email={constants.email_accounts}
          whiteSpace="nowrap"
          fontWeight="medium"
        >
          {constants.email_accounts}
        </MailtoLink>
        .
      </Text>
      <Text>
        <Text fontWeight="medium" as="span">
          If you are not a broker dealer, agent, or other intermediary acting on
          behalf of a third party
        </Text>
        {` `}
        you may return to the onboarding process.
      </Text>
      <HiiveButton
        size="md"
        variant="rounded-solid-salmon"
        onClick={onSubmit}
        isDisabled={loading}
        isLoading={loading}
        sentryLabel="[InstitutionNeedsFurtherVerificationPage] Return"
      >
        Return
      </HiiveButton>
    </VStack>
  );
};

export default withCurrentActor(InstitutionalNeedsFurtherVerificationPage);
