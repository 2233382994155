import { useContext } from "react";

import { LDStatusContext } from "@/components/async-with-ld-provider";

export type FlagValue = boolean | string | string[];

export type FeatureFlags = {
  readonly "hiive.trace_full_graphql": FlagValue;
  readonly "hiive.enable_maintenance": FlagValue;
  readonly "hiive.modify_listing_num_shares": FlagValue;
  readonly "hiive.preqin_integration": FlagValue;
  readonly "hiive.marketplace.react_hook_form_refactor": FlagValue;
  readonly "hiive.change_transaction_transfer_type": FlagValue;
  readonly "marketplace.frigade": FlagValue;
  readonly "hiive.marketplace.listing_info_refactor": FlagValue;
  readonly "marketplace.hiive_50": FlagValue;
  readonly "marketplace.multiple_holdings": FlagValue;
  readonly "marketplace.sell_side_broker_portal": FlagValue;
  readonly "issuer.fee_discount.new_proceeds_section": FlagValue;
  readonly "issuer.managed_markets.enable": FlagValue;
  readonly "issuer.managed_markets.fast_follow": FlagValue;
  readonly "execution.seller_external_account": FlagValue;
  readonly "marketplace.spv": FlagValue;
  readonly "execution.transaction_execution_automation_system": FlagValue;
  readonly "execution.multi_entity_experience": FlagValue;
  readonly "marketplace.suitability_upfront": FlagValue;
  readonly "issuer.auth_system.marketplace_login": FlagValue;
  readonly "marketplace.suppress_notifications": FlagValue;
  readonly "issuer.auth_system.marketplace": FlagValue;
  readonly "marketplace.trusted-contact-person": FlagValue;
  readonly "marketplace.suitability_v3": FlagValue;
  readonly "marketplace.front_poc": FlagValue;
};

export const useFeatureFlags = (): FeatureFlags => {
  const { flags } = useContext(LDStatusContext);

  return (flags as FeatureFlags) || {};
};

export const useMaintenanceMode = () =>
  useFeatureFlags()[`hiive.enable_maintenance`];

export const useModifyListingNumSharesEnabled = () =>
  useFeatureFlags()[`hiive.modify_listing_num_shares`];

export const usePreqinIntegrationEnabled = () =>
  useFeatureFlags()[`hiive.preqin_integration`];

export const useListingInfoRefactorEnabled = () =>
  useFeatureFlags()[`hiive.marketplace.listing_info_refactor`];

export const useMultipleHoldings = () =>
  useFeatureFlags()[`marketplace.multiple_holdings`];

export const useSellSideBrokerPortalEnabled = () =>
  useFeatureFlags()[`marketplace.sell_side_broker_portal`];

export const useHiive50Enabled = () =>
  useFeatureFlags()[`marketplace.hiive_50`];

export const useFeeDiscountNewProceedsSectionEnabled = () =>
  useFeatureFlags()[`issuer.fee_discount.new_proceeds_section`];

export const useManagedMarketsEnabled = () =>
  useFeatureFlags()[`issuer.managed_markets.enable`];

export const useManagedMarketsFastFollowEnabled = () =>
  useFeatureFlags()[`issuer.managed_markets.fast_follow`];

export const useSellerExternalAccountEnabled = () =>
  useFeatureFlags()[`execution.seller_external_account`];

export const useSPVEnabled = () => useFeatureFlags()[`marketplace.spv`];

export const useTEXASEnabled = () =>
  useFeatureFlags()[`execution.transaction_execution_automation_system`];

export const useMultiEntityExperienceEnabled = () =>
  useFeatureFlags()[`execution.multi_entity_experience`];

export const useSuitabilityUpfront = () =>
  useFeatureFlags()[`marketplace.suitability_upfront`];

export const useMarketplaceLogin = () =>
  useFeatureFlags()[`issuer.auth_system.marketplace_login`];

export const useSuppressNotifications = () =>
  useFeatureFlags()[`marketplace.suppress_notifications`];

export const useMarketplaceAuthSystem = () =>
  useFeatureFlags()[`issuer.auth_system.marketplace`];

export const useTrustedContactPerson = () =>
  useFeatureFlags()[`marketplace.trusted-contact-person`];

export const useSuitabilityV3 = () =>
  useFeatureFlags()[`marketplace.suitability_v3`];

export const useFrontPOC = () => useFeatureFlags()[`marketplace.front_poc`];
