import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { Flex, GridItem, SimpleGrid, Text, chakra } from "@chakra-ui/react";

import { SlideAnimation } from "@/components/auth-v2";
import { HiiveButton, InternalLink } from "@/components/common";
import { FormPasswordInput, FormTextInput } from "@/components/react-hook-form";
import { LoginMutation, Session, useLoginMutation } from "@/gql";
import { useAnalytics } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { setAuthFromSession } from "@/state/auth";
import { shadows } from "@/styles/theme";

const buildPayloadFromSession = (session: Session) => ({
  userId: session.userId,
  token: session.token,
  refreshToken: session.refreshToken,
});

const validationSchema = Yup.object({
  email: Yup.string().required(`Email is required`),
  password: Yup.string().nullable().required(`Password is required`),
});

interface LoginFormFieldValues {
  readonly email: string;
  readonly password: string;
}

const initialValues = {
  email: ``,
  password: ``,
};

const mapVariables = (values: LoginFormFieldValues) => ({
  input: {
    ...values,
  },
});

export const LoginFormV2 = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { t } = useTranslation();

  const mutation = useLoginMutation();

  const onSuccess = (data: LoginMutation) => {
    if (!data.login?.session) return;

    analytics.identify(data.login.session.userId);

    const sessionPayload = buildPayloadFromSession(data.login.session);
    dispatch(setAuthFromSession(sessionPayload));
  };

  const { handleSubmit, isLoading, control } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
  });

  return (
    <chakra.form onSubmit={handleSubmit}>
      <SlideAnimation>
        <SimpleGrid
          rowGap={6}
          w="full"
          bg="white"
          p={6}
          borderRadius="md"
          borderColor="grey.200"
          borderWidth={0.5}
          boxShadow={shadows.card}
        >
          <GridItem colSpan={1}>
            <FormTextInput
              name="email"
              label={t`email`}
              type="email"
              placeholder={t`email_address`}
              bg="h-white"
              autoFocus
              control={control}
              isRequired
            />
          </GridItem>
          <GridItem>
            <FormPasswordInput
              name="password"
              label={t`password`}
              placeholder={t`enter_password`}
              autoComplete="current-password"
              control={control}
              isRequired
            />
          </GridItem>
        </SimpleGrid>
      </SlideAnimation>
      <Flex align="center" justify="space-between" mt={6} px={1}>
        <Text
          textStyle="lozenge-text"
          fontWeight="medium"
          textDecorationLine="underline"
        >
          <InternalLink href="/forgot-password">{t`forgotten_password`}</InternalLink>
        </Text>
        <HiiveButton
          type="submit"
          p={6}
          variant="rounded-solid-salmon"
          size="md"
          isLoading={isLoading}
          sentryLabel="[LoginForm] Sign in"
        >
          {t`sign_in`}
        </HiiveButton>
      </Flex>
    </chakra.form>
  );
};
