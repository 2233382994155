import { Star } from "@phosphor-icons/react";
import { Trans } from "react-i18next";

import { Box, Text, Badge } from "@chakra-ui/react";

import { CompanyMarketActivityBuyersBadgeCompanyFragment } from "@/gql";

const CompanyMarketActivityBuyersBadge = ({
  variant,
  company,
}: {
  readonly variant: "rounded-olive" | "rounded-plum";
  readonly company: CompanyMarketActivityBuyersBadgeCompanyFragment;
}) => (
  <Badge
    variant={variant}
    maxW="fit-content"
    display="flex"
    alignItems={{ base: `flex-start`, sm: `center` }}
    px={4}
    gap={3}
  >
    <Box as={Star} size={24} flex="none" />
    <Text textStyle="text-lg" whiteSpace="normal" alignSelf="center">
      <Trans
        i18nKey="potential_buyers_count"
        values={{
          count: company.numWatchers,
          companyName: company.name,
        }}
        components={{
          bold: <Text as="span" textStyle="heading-lg" />,
        }}
      />
    </Text>
  </Badge>
);

export default CompanyMarketActivityBuyersBadge;
