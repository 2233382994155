export { InvestorTypePage } from "./InvestorTypePage";
export { InvestorStatusPage } from "./InvestorStatusPage";
export {
  SellerInfoPage,
  SellerResidencePage,
  SellerLotDetailsPage,
  SellerAgreementPage,
  CustomerAgreementDeclinedPage,
} from "./seller";
export { InstitutionCustomerAgreementPage } from "./institutional/InstitutionCustomerAgreementPage";
export { IdentityVerificationPage } from "./IdentityVerificationPage";
export { RequestEmailVerificationPage } from "./RequestEmailVerificationPage";
export { VerifyEmailPage } from "./VerifyEmailPage";
export { WatchlistPage } from "./WatchlistPage";
export {
  IndividualNeedsFurtherVerificationPage,
  IndividualAccreditationPage,
  IndividualConsentPage,
  IndividualTraderCustomerAgreementPage,
} from "./individual";
export {
  InstitutionAccreditationPage,
  InstitutionBuildProfilePage,
  InstitutionDetailsPage,
  InstitutionNeedsFurtherVerificationPage,
  InstitutionSuitabilityPage,
  InstitutionApprovalWaitPage,
} from "./institutional";
export { default as OnboardingPage } from "./OnboardingPage";
export { default as OnboardingContainer } from "./OnboardingContainer";

export { default as QuestionFormCard } from "./QuestionFormCard";

export {
  CaIsPermittedClientInput,
  CaAgreedToInvestmentRiskInput,
  OnboardingWatchlistForm,
} from "./shared";
