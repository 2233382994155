import { useMemo } from "react";

import { Maybe, UseHaveISignedDocumentDocumentFragment } from "@/gql";

import useCurrentActor from "./useCurrentActor";

const useHaveISignedDocument = (
  document: Maybe<UseHaveISignedDocumentDocumentFragment> | undefined,
) => {
  const actor = useCurrentActor();

  return useMemo(
    () =>
      Boolean(
        document?.signers?.some(
          (signer) =>
            signer?.userId === actor.id ||
            (signer?.institutionId &&
              signer.institutionId === actor.institutionId),
        ),
      ),
    [document?.signers, actor.id, actor.institutionId],
  );
};

export default useHaveISignedDocument;
