import { ActivityGroup } from "@/components/common";
import { BidState, StandingBidTransactionsStandingBidFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { getIsBuyerForStandingBid } from "@/utils/standing-bid";

import StandingBidTransactionCard from "./StandingBidTransactionCard";

const StandingBidTransactions = ({
  standingBid,
}: {
  readonly standingBid: StandingBidTransactionsStandingBidFragment;
}) => {
  const actor = useCurrentActor();
  const isBuyer = getIsBuyerForStandingBid(actor, standingBid);
  const bidsWithTransactions = standingBid.bids.filter(
    (bid) =>
      (bid.state === BidState.Accepted || bid.state === BidState.Countered) &&
      Boolean(bid.transaction),
  );

  return bidsWithTransactions.length ? (
    <ActivityGroup title={isBuyer ? `Transactions` : `Your Transactions`}>
      {bidsWithTransactions.map((bid) => (
        <StandingBidTransactionCard
          key={bid.id}
          bid={bid}
          company={standingBid.company}
        />
      ))}
    </ActivityGroup>
  ) : null;
};

export default StandingBidTransactions;
