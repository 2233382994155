import { useAuthUserOrNull } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { BaseOperationState } from "./types";
import useMfaDevices from "./useMfaDevices";

interface DisbleMfaParams {
  readonly code: string;
  readonly otcToken: string;
}

const initialState = {
  loading: false,
  error: null,
  phoneNumber: ``,
};

const useDisableSmsMfa = () => {
  const { t } = useTranslation();
  const user = useAuthUserOrNull();
  const getMfaDevices = useMfaDevices();
  const genericErrorMessage = t(`incorrect_code`);

  const [state, setState] = useState<BaseOperationState>(initialState);

  const disableMfa = useCallback(
    async (body?: DisbleMfaParams) => {
      setState({ loading: true, error: null });

      try {
        if (!user) {
          throw new Error(t(`invalid_request`));
        }

        const mfaDevices = await getMfaDevices();

        if (mfaDevices instanceof Error) {
          throw new Error(mfaDevices.message);
        }

        const { phones } = mfaDevices;

        if (phones.length === 0) {
          throw new Error(t(`no_mfa_device_found`));
        }

        const [{ id: deviceId }] = phones;

        const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/sms/${deviceId}/disable/verify`;

        const response = await fetch(API_URL, {
          method: `POST`,
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
          // if user already has an App MFA, body is not required
          ...(body && { body: JSON.stringify(body) }),
        });

        if (!response.ok) {
          const data = await response.json();
          if (data.errors.length > 0) {
            const [message] = data.errors;
            const errorMessage = message || genericErrorMessage;
            throw new Error(errorMessage);
          }
        }

        setState({ loading: false, error: null });
        return true;
      } catch (error) {
        Sentry.captureException(error);
        setState({ loading: false, error });
        return false;
      }
    },
    [getMfaDevices, t, user],
  );

  return [disableMfa, state] as const;
};

export default useDisableSmsMfa;
