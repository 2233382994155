import { Text } from "@chakra-ui/react";

const ListingSellerRoundedSharesTooltip = () => (
  <Text>
    In order to protect your identity, the number of shares displayed to other
    users is rounded from the actual number of shares you listed. If a buyer
    makes a bid for the rounded number of shares, and you accept the bid, the
    transaction will proceed based on your actual number of shares.
  </Text>
);

export default ListingSellerRoundedSharesTooltip;
