import { CaretDown, CaretRight } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";

import { MarkdownDocument } from "@/components/common";
import styles from "@/styles/company-eligibility-criteria-markdown-styles.module.css";

interface CompanyEligibiltyCriteriaAccordionProps {
  readonly markdown: string;
  readonly companyName: string;
}

const CompanyEligibilityCriteriaAccordion = ({
  markdown,
  companyName,
}: CompanyEligibiltyCriteriaAccordionProps) => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Alert alignItems="flex-start" status="info" variant="infoGrey">
      <AlertIcon />
      <Box>
        <Flex flexDir="row" onClick={onToggle} cursor="pointer">
          <AlertTitle>
            {t(`transfer_eligibility_requirements_v2`, { companyName })}
          </AlertTitle>
          <Box>
            {isOpen ? (
              <CaretDown size={24} weight="bold" />
            ) : (
              <CaretRight size={24} weight="bold" />
            )}
          </Box>
        </Flex>
        {isOpen && (
          <AlertDescription>
            <div className={styles[`company-eligibility-criteria-markdown`]}>
              <MarkdownDocument markdown={markdown} />
            </div>
          </AlertDescription>
        )}
      </Box>
    </Alert>
  );
};

export default CompanyEligibilityCriteriaAccordion;
