import { Center, ModalBody, Spinner } from "@chakra-ui/react";

const ModalBodyLoader = () => (
  <ModalBody>
    <Center m={20}>
      <Spinner />
    </Center>
  </ModalBody>
);

export default ModalBodyLoader;
