/* eslint-disable functional/immutable-data */
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/nextjs";
import { GraphQLError } from "graphql";

import toastr, { NotificationDuration } from "@/libs/toastr";

export interface GqlError extends GraphQLError {
  readonly code: string;
  readonly metadata: readonly string[];
}

const notFoundCodes = [`NOT_FOUND`, `UNAUTHORIZED`, `EXPIRED`, `FORBIDDEN`];
const somethingWentWrongCodes = [`INVALID_STATE`, `INTERNAL_SERVER_ERROR`];

const sentryErrorCodes = [
  `UNAUTHORIZED`,
  `FORBIDDEN`,
  `INVALID_STATE`,
  `INTERNAL_SERVER_ERROR`,
];

const IGNORED_CODES = [`MFA_ENROLLMENT_PENDING`, `MFA_UNENROLLMENT_PENDING`];

const captureSentryErrors = async (gqlErrors: readonly GqlError[]) => {
  gqlErrors
    .filter((error) => sentryErrorCodes.includes(error.code))
    .forEach((error) => {
      Sentry.captureMessage(`GraphQL error`, {
        extra: { error },
        tags: { "error.code": error.code },
      });
    });

  await Sentry.flush(2000);
};

const maybeRedirect = (gqlErrors: readonly GqlError[]) => {
  const apiUrl = localStorage.getItem(`api`);
  switch (true) {
    case gqlErrors.some((err) => err.code === `UNAUTHENTICATED`):
      window.localStorage.clear();

      if (apiUrl) localStorage.setItem(`api`, apiUrl);

      window.sessionStorage.clear();
      return;
    case gqlErrors.some((err) => notFoundCodes.includes(err.code)):
      window.location.href = `/page-not-found`;
      return;
    case gqlErrors.some((err) => somethingWentWrongCodes.includes(err.code)):
      window.location.href = `/something-went-wrong`;
      return;
    case gqlErrors.some((err) => err.code === `INPUT_ERROR`):
      gqlErrors.forEach((err) => {
        toastr().error(
          err.message,
          err.metadata[0],
          NotificationDuration.NEVER,
        );
      });
      return;
    case gqlErrors.some((err) => IGNORED_CODES.includes(err.code)):
      return;
    default:
      throw new Error(
        `Unhandled GraphQl Error Code: ${JSON.stringify(gqlErrors)}`,
      );
  }
};

const errorLink = onError((params) => {
  const gqlErrors = params.graphQLErrors as readonly GqlError[];
  const { networkError } = params;

  // Since inside of the /something-went-wrong page, we make a useBasicCurrentContext network request
  // this prevents an infinite loop of redirects in case the server is down
  if (
    window.location.pathname === `/something-went-wrong` ||
    window.location.pathname === `/page-not-found`
  )
    return;

  if (networkError || !gqlErrors) {
    window.location.href = `/something-went-wrong`;
    return;
  }

  // we don't want to redirect immediately because the Sentry client needs to finish sending the data first
  captureSentryErrors(gqlErrors).then(() => maybeRedirect(gqlErrors));
});

export default errorLink;
