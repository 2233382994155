import { useField } from "formik";
import difference from "lodash/difference";
import { ChangeEvent } from "react";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
} from "@chakra-ui/react";

import { CustomCheckbox } from "@/components/common";
import { StandingBidAcceptsTransferMethodsOptions } from "@/gql";

const includesAll = (superset: readonly string[], subset: readonly string[]) =>
  difference(subset, superset).length === 0;

const StandingBidAcceptedTransferMethods = ({
  name,
  label = `I am also willing to purchase shares indirectly via:`,
}: {
  readonly name: string;
  readonly label?: string;
}) => {
  const [field, { touched, error }, { setValue }] = useField(name);

  const value = field.value as readonly string[];

  const getIsChecked = (options: readonly string[]) =>
    includesAll(value, options);

  const onChange = (options: readonly string[]) => (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      setValue([...value, ...options]);

      return;
    }

    setValue(value.filter((x) => !options.includes(x)));
  };

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Stack spacing={2}>
        <CustomCheckbox
          spacing={2}
          isChecked={getIsChecked([
            StandingBidAcceptsTransferMethodsOptions.ForwardContract,
          ])}
          onChange={onChange([
            StandingBidAcceptsTransferMethodsOptions.ForwardContract,
          ])}
        >
          Forward contract
        </CustomCheckbox>
        <CustomCheckbox
          spacing={2}
          isChecked={getIsChecked([
            StandingBidAcceptsTransferMethodsOptions.SpvLister,
            StandingBidAcceptsTransferMethodsOptions.SpvThirdparty,
          ])}
          onChange={onChange([
            StandingBidAcceptsTransferMethodsOptions.SpvLister,
            StandingBidAcceptsTransferMethodsOptions.SpvThirdparty,
          ])}
        >
          Seller&apos;s SPV
        </CustomCheckbox>
      </Stack>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default StandingBidAcceptedTransferMethods;
