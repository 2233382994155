import { useEffect, useState } from "react";

import { FrontChat, FrontChatParams, UseFrontChatReturn } from "./types";

enum FrontChatStatusesEnum {
  IDLE = `idle`,
  READY = `ready`,
  INITIALIZED = `initialized`,
}

export function useFrontChatRef(): UseFrontChatReturn {
  const [status, setStatus] = useState<FrontChatStatusesEnum>(
    FrontChatStatusesEnum.IDLE,
  );

  useEffect(() => {
    setStatus(FrontChatStatusesEnum.READY);
  }, []);

  const frontChat: FrontChat = (cmdType, params) => {
    if (!window.FrontChat) {
      return undefined;
    }

    if (cmdType === `init`) {
      const onInitCompleted = () => {
        setStatus(FrontChatStatusesEnum.INITIALIZED);
      };

      return window.FrontChat(cmdType, { ...params, onInitCompleted });
    }

    if (cmdType === `shutdown`) {
      setStatus(FrontChatStatusesEnum.READY);
    }

    return window.FrontChat(cmdType, params);
  };

  const initialize = (params?: FrontChatParams) => {
    if (status === FrontChatStatusesEnum.INITIALIZED) {
      return;
    }

    frontChat(`init`, params);
  };

  return {
    frontChat,
    isInitialized: status === FrontChatStatusesEnum.INITIALIZED,
    initialize,
  };
}
