import { useEffect, useRef } from "react";

const useHorizontalScrollToActiveTab = (activeTab: number | string) => {
  const parentTabListRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!parentTabListRef.current) return;

    const selectedTab: HTMLElement | null = parentTabListRef.current.querySelector(
      `[role=tab][aria-selected=true]`,
    );

    if (selectedTab) {
      parentTabListRef.current.scrollTo({
        behavior: `smooth`,
        left:
          selectedTab.offsetLeft -
          parentTabListRef.current.offsetLeft -
          selectedTab.scrollWidth,
      });
    }
  }, [activeTab]);

  return {
    tabListRef: parentTabListRef,
  };
};

export default useHorizontalScrollToActiveTab;
