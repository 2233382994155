import { useFormikContext } from "formik";
import { useEffect } from "react";

import { Stack } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  CreateTransactingEntityModalTypes,
  TRANSACTING_ENTITY_DEFAULT_VALUES,
} from "@/components/modals";
import {
  AddEntityInput,
  UserWithInstitutionFragment,
  EntityType,
  InvestorType,
} from "@/gql";

import { TransactingEntityJurisdictionOfFormationInput } from "./TransactingEntityJurisdictionOfFormationInput";
import { TransactingEntityLegalNameInput } from "./TransactingEntityLegalNameInput";
import { TransactingEntityTypeInput } from "./TransactingEntityTypeInput";
import { TransactingEntityTypeSelect } from "./TransactingEntityTypeSelect";

type TransactingEntityFormFieldsProps = {
  actor: UserWithInstitutionFragment;
  filterIndividualEntityType?: boolean;
  transactionId?: string;
  type?: CreateTransactingEntityModalTypes;
};

const TransactingEntityFormFields = withCurrentActor(
  ({
    actor,
    filterIndividualEntityType = false,
    transactionId,
    type,
  }: TransactingEntityFormFieldsProps) => {
    const { setValues, values } = useFormikContext<AddEntityInput>();
    const showEntityTypeInput = values.type === EntityType.Other;
    const isIndividualEntity = values.type === EntityType.Individual;
    const isInstitution = !!actor.institutionId;

    useEffect(() => {
      if (!isInstitution) {
        if (isIndividualEntity) {
          setValues({
            ...TRANSACTING_ENTITY_DEFAULT_VALUES,
            jurisdictionOfFormationId: actor.country?.id ?? ``,
            legalName: actor.name,
            type: values.type,
            transactionId,
          });

          return;
        }
        const shouldClearFields = values.legalName === actor.name;

        if (shouldClearFields) {
          setValues({
            ...TRANSACTING_ENTITY_DEFAULT_VALUES,
            type: values.type,
            transactionId,
          });
        }
      }
    }, [
      actor.country?.id,
      actor.name,
      isInstitution,
      setValues,
      values.type,
      transactionId,
    ]);

    const isDisabled = !isInstitution && isIndividualEntity;

    return (
      <Stack gap="6">
        <TransactingEntityTypeSelect
          isInstitution={isInstitution}
          filterIndividualEntityType={
            !isInstitution && filterIndividualEntityType
          }
        />
        {showEntityTypeInput && <TransactingEntityTypeInput />}
        <TransactingEntityLegalNameInput
          disabled={isDisabled}
          type={type}
          isUnaccredittedSeller={
            actor.investorType === InvestorType.UnaccreditedSeller
          }
        />
        <TransactingEntityJurisdictionOfFormationInput disabled={isDisabled} />
      </Stack>
    );
  },
);

export default TransactingEntityFormFields;
