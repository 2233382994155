import { HiiveModalContentWrapper } from "@/components/common";
import { PlaceStandingBidModalCompanyFragment } from "@/gql";

import { PlaceStandingBidSequenceModalAuthorization } from "./PlaceStandingBidSequenceModalAuthorization";
import { PlaceStandingBidSequenceModalFormValues } from "./steps";

const PlaceStandingBidSequenceModal = ({
  initialCompany,
  initialValues,
}: {
  readonly initialCompany?: PlaceStandingBidModalCompanyFragment;
  readonly initialValues?: Partial<PlaceStandingBidSequenceModalFormValues>;
}) => (
  <HiiveModalContentWrapper>
    <PlaceStandingBidSequenceModalAuthorization
      initialCompany={initialCompany}
      initialValues={initialValues}
    />
  </HiiveModalContentWrapper>
);

export default PlaceStandingBidSequenceModal;
