import { SimpleGrid } from "@chakra-ui/react";

import { FullContentWrapper } from "@/components/common";

import { AccountPageNavigation } from "./AccountPageNavigation";
import { AccountPageSetting } from "./AccountPageSetting";

export const DesktopAccountPage = () => (
  <FullContentWrapper px={{ md: 4, lg: 8 }}>
    <SimpleGrid
      w="full"
      maxW="max-width-lg"
      gridRowGap={4}
      gridColumnGap={6}
      gridTemplateColumns="416px 1fr"
    >
      <AccountPageNavigation />
      <AccountPageSetting />
    </SimpleGrid>
  </FullContentWrapper>
);
