import { SVGProps } from "react";

const Hiive50BadgeShort = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="24"
    viewBox="0 0 36 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.333252" width="35" height="24" rx="4.8" fill="#19434F" />
    <g filter="url(#filter0_d_4462_28329)">
      <path
        d="M6.73291 16.1947V7.7998H7.92062V11.448H11.8451V7.7998H13.0366V16.1947H11.8451V12.5343H7.92062V16.1947H6.73291Z"
        fill="white"
      />
      <path
        d="M17.9952 16.1947C17.466 16.1947 16.9923 16.0936 16.5742 15.8915C16.1561 15.6866 15.824 15.4062 15.578 15.0503C15.3345 14.6945 15.2048 14.2874 15.189 13.8291H16.6179C16.6444 14.1688 16.7886 14.4465 17.0505 14.6621C17.3125 14.8751 17.6274 14.9816 17.9952 14.9816C18.2836 14.9816 18.5403 14.9142 18.7652 14.7794C18.9902 14.6446 19.1675 14.4573 19.2971 14.2173C19.4268 13.9774 19.4903 13.7038 19.4877 13.3964C19.4903 13.0837 19.4255 12.806 19.2932 12.5634C19.1608 12.3208 18.9796 12.1307 18.7494 11.9932C18.5192 11.853 18.2545 11.783 17.9555 11.783C17.7121 11.7803 17.4726 11.8261 17.2371 11.9204C17.0016 12.0148 16.815 12.1388 16.6774 12.2925L15.3477 12.0701L15.7724 7.7998H20.4879V9.05338H16.991L16.7568 11.2492H16.8044C16.9553 11.0685 17.1683 10.9189 17.4435 10.8003C17.7187 10.679 18.0204 10.6183 18.3485 10.6183C18.8407 10.6183 19.2799 10.737 19.6663 10.9742C20.0526 11.2087 20.3569 11.5322 20.5792 11.9447C20.8015 12.3572 20.9126 12.8289 20.9126 13.36C20.9126 13.9073 20.7882 14.3952 20.5395 14.8239C20.2934 15.2498 19.9507 15.5855 19.5115 15.8308C19.0748 16.0734 18.5694 16.1947 17.9952 16.1947Z"
        fill="#F15F61"
      />
      <path
        d="M25.5973 16.1947C24.9437 16.1947 24.3827 16.0294 23.9143 15.6986C23.4486 15.3652 23.0901 14.8849 22.8387 14.2578C22.5899 13.628 22.4656 12.8698 22.4656 11.9834C22.4682 11.0969 22.5939 10.3428 22.8427 9.72092C23.094 9.09642 23.4526 8.62011 23.9183 8.29199C24.3867 7.96387 24.9463 7.7998 25.5973 7.7998C26.2483 7.7998 26.8079 7.96387 27.2763 8.29199C27.7447 8.62011 28.1032 9.09642 28.352 9.72092C28.6033 10.3454 28.729 11.0996 28.729 11.9834C28.729 12.8725 28.6033 13.6319 28.352 14.2617C28.1032 14.8889 27.7447 15.3678 27.2763 15.6986C26.8106 16.0294 26.2509 16.1947 25.5973 16.1947ZM25.5973 14.9524C26.1054 14.9524 26.5063 14.7023 26.8 14.2022C27.0964 13.6994 27.2445 12.9598 27.2445 11.9834C27.2445 11.3377 27.1771 10.7953 27.0421 10.356C26.9072 9.91673 26.7166 9.58596 26.4705 9.36369C26.2244 9.13876 25.9334 9.0263 25.5973 9.0263C25.0919 9.0263 24.6923 9.27768 24.3986 9.78045C24.1049 10.2806 23.9567 11.0149 23.954 11.9834C23.9514 12.6317 24.0162 13.1768 24.1485 13.6187C24.2835 14.0606 24.474 14.394 24.7201 14.619C24.9662 14.8412 25.2586 14.9524 25.5973 14.9524Z"
        fill="#F15F61"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4462_28329"
        x="5.53291"
        y="7.7998"
        width="24.6002"
        height="10.8004"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.2" />
        <feGaussianBlur stdDeviation="0.6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.101961 0 0 0 0 0.168627 0 0 0 0 0.231373 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4462_28329"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4462_28329"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Hiive50BadgeShort;
