import { useTranslation } from "react-i18next";

import { StackProps, useBreakpointValue } from "@chakra-ui/react";

import { CTACard } from "@/components/common";
import { ListingPageBrokerCounterpartyActivityPlaceStandingBidCtaCardCompanyFragment } from "@/gql";
import { useModal } from "@/hooks";

export const PlaceStandingBidCTACard = ({
  company,
}: {
  readonly company: ListingPageBrokerCounterpartyActivityPlaceStandingBidCtaCardCompanyFragment;
}) => {
  const { modals, onOpenModal } = useModal();
  const direction: StackProps["direction"] = useBreakpointValue(
    {
      base: `column`,
      sm: `row`,
      md: `row`,
      xl: `row`,
    },
    { fallback: `column`, ssr: false },
  );
  const { t } = useTranslation();

  return (
    <CTACard
      direction={direction}
      heading={t(`broker_place_standing_bid_heading`)}
      description={t(`broker_place_standing_bid_description`, {
        companyName: company.name,
      })}
      action={{
        ctaText: t(`submit_a_bid`),
        onClick: onOpenModal(modals.placeStandingBid(company)),
      }}
    />
  );
};
