import React from "react";

import { ValuationRangeUnit } from "@/components/companies";
import { ItemList, Listbox } from "@/components/form";
import { useListbox } from "@/hooks";

type Units = ItemList<ValuationRangeUnit>;

export const LastRoundValuationDropdown = ({
  value,
  onChange,
}: {
  readonly value: ValuationRangeUnit;
  readonly onChange: (unit: ValuationRangeUnit) => void;
}) => {
  const units: Units = [`million`, `billion`];

  const { buttonProps, menuProps, itemProps } = useListbox({
    onClickItem: (unit) => onChange(unit),
    items: units,
    itemToString: (unit) => unit,
    selectedItem: value,
  });

  return (
    <Listbox.Container h="auto">
      <Listbox.Button {...buttonProps} />
      <Listbox.Menu {...menuProps}>
        {units.map((unit, index) => (
          <Listbox.Item key={unit} item={unit} index={index} {...itemProps}>
            {unit}
          </Listbox.Item>
        ))}
      </Listbox.Menu>
    </Listbox.Container>
  );
};
