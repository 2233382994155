import { Box, BoxProps, Center, Flex, Text } from "@chakra-ui/react";

import Meta from "@/components/Meta";
import { Logo } from "@/components/common";
import { useBasicCurrentContext, useSignOut } from "@/hooks";
import { getIsBroker } from "@/utils";

interface OnboardingContainerProps extends BoxProps {
  readonly title?: string;
  readonly heading: string;
  readonly children: JSX.Element | readonly JSX.Element[];
}

const Header = () => {
  const { actor } = useBasicCurrentContext();
  const signOut = useSignOut();

  if (!actor) return null;
  return (
    <Flex
      pos="absolute"
      justify="space-between"
      w="full"
      left={0}
      top={0}
      pt={{ base: 4, md: 7 }}
      px={{ base: 5, md: 8 }}
    >
      <Flex direction="column">
        <Text textStyle="deprecated-text-sm">Logged in as:</Text>
        <Text textStyle="deprecated-heading-sm" color="grey.400">
          {actor.email}
        </Text>
      </Flex>
      <Text
        textStyle="deprecated-heading-sm"
        cursor="pointer"
        onClick={() => signOut(`/login`)}
        color="grey.400"
      >
        Log out
      </Text>
    </Flex>
  );
};

const OnboardingContainer = ({
  heading,
  children,
  title,
  ...boxProps
}: OnboardingContainerProps) => {
  const { actor } = useBasicCurrentContext();

  return (
    <Box
      bg="grey.50"
      minH="100vh"
      w="full"
      pt={{ base: 24, md: 14 }}
      pb={14}
      px={{ base: 4, lg: 8 }}
      pos="relative"
    >
      {!!title && <Meta title={`Onboarding | ${title}`} shouldTrackPageEvent />}
      <Header />
      <Box maxW="30rem" mx="auto" pos="relative" {...boxProps}>
        {actor && (
          <Center>
            {getIsBroker(actor) ? (
              <Logo.ConnectGrey
                mb={{ base: 9, md: 14 }}
                width={40}
                height="auto"
              />
            ) : (
              <Logo.Grey mb={{ base: 9, md: 14 }} width={40} />
            )}
          </Center>
        )}
        <Text
          textAlign="center"
          textStyle="deprecated-heading-4xl"
          color="h-dark-grey"
          data-testid="onboarding-page-title"
        >
          {heading}
        </Text>
        {children}
      </Box>
    </Box>
  );
};

export default OnboardingContainer;
