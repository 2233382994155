import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Card, CardBody, Flex, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  OnboardingContainerV2,
  SlideAnimation,
} from "@/components/onboarding-v2";
import {
  UserWithInstitutionFragment,
  useRequestEmailVerificationMutation,
} from "@/gql";
import { useCustomToast, useMutationWithError } from "@/hooks";

const pageViewSessionStorageKey = `REQUEST_EMAIL_VERIFICATION_PAGE_VIEWED_KEY`;

const useRedirectOnEmailVerified = ({
  actor,
}: {
  readonly actor?: UserWithInstitutionFragment;
}) => {
  const router = useRouter();

  useEffect(() => {
    if (actor?.emailVerified) {
      router.replace(`/dashboard`);
    }
  }, [actor?.emailVerified]);
};

const RequestEmailVerificationPage = ({
  actor,
}: {
  readonly actor?: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();
  const { successToast } = useCustomToast();

  const [requestEmailVerificationMutation] = useMutationWithError(
    useRequestEmailVerificationMutation({
      onCompleted: () => {
        successToast(t(`verification_email_sent`));
      },
    }),
    `requestEmailVerification`,
  );

  const onClickResend = () => {
    requestEmailVerificationMutation();
  };

  useRedirectOnEmailVerified({ actor });

  useEffect(() => {
    const pageViewed = sessionStorage.getItem(pageViewSessionStorageKey);
    if (pageViewed === `true`) return;

    sessionStorage.setItem(pageViewSessionStorageKey, `true`);
    requestEmailVerificationMutation();
  }, []);

  return (
    <OnboardingContainerV2 metaTitle={t(`verify_your_email`)} canGoBack={false}>
      <Flex direction="column" w="full" maxW="45rem">
        <Flex direction="column" gap={8} mb={8}>
          <Text textStyle="heading-3xl">{t(`verify_your_email`)} *</Text>
          <SlideAnimation>
            <Card>
              <CardBody>
                <Flex direction="column" gap={{ base: 6, md: 4 }}>
                  <Flex direction="column" gap={{ base: 2, md: 0 }}>
                    <Text>{t(`please_check_your_inbox`)}</Text>
                    <Text>{t(`it_may_take_up_to_5_minutes`)}</Text>
                  </Flex>

                  <Text alignItems="center" gap={1}>
                    <Text as="span">{t(`didnt_receive_anything`)} </Text>
                    <HiiveButton
                      variant="unstyled"
                      textDecoration="underline"
                      sentryLabel="[RequestEmailVerificationPage] Resend Email"
                      onClick={onClickResend}
                    >
                      {t(`resend_verification_email`)}
                    </HiiveButton>
                  </Text>
                </Flex>
              </CardBody>
            </Card>
          </SlideAnimation>
        </Flex>
      </Flex>
    </OnboardingContainerV2>
  );
};

export default withCurrentActor(RequestEmailVerificationPage);
