import { HStack, SimpleGrid, VStack } from "@chakra-ui/react";

import {
  PricingBlockDescription,
  PricingBlockHeading,
  PricingBlockValue,
} from "@/components/companies";
import { PricingBlockCompanyFragment } from "@/gql";
import { useIsDesktop } from "@/hooks";

const DesktopPricingBlock = ({
  company,
}: {
  readonly company: PricingBlockCompanyFragment;
}) => (
  <SimpleGrid
    w="full"
    p={4}
    borderColor="grey.100"
    borderRadius="md"
    bg="grey.50"
    borderWidth={0.5}
    textAlign="center"
    columns={3}
    columnGap={4}
    rowGap={2}
  >
    <PricingBlockHeading>Highest Bid</PricingBlockHeading>
    <PricingBlockHeading>Lowest ask</PricingBlockHeading>
    <PricingBlockHeading>Last transaction</PricingBlockHeading>
    <PricingBlockDescription>
      The highest priced bid that was open at any time this week
    </PricingBlockDescription>
    <PricingBlockDescription>
      The lowest priced listing that was open at any time this week
    </PricingBlockDescription>
    <PricingBlockDescription>
      The most recent price at which a bid was accepted
    </PricingBlockDescription>
    <PricingBlockValue>{company.currentPrices.highestBid}</PricingBlockValue>
    <PricingBlockValue>{company.currentPrices.lowestAsk}</PricingBlockValue>
    <PricingBlockValue>
      {company.currentPrices.lastTransaction}
    </PricingBlockValue>
  </SimpleGrid>
);

const MobilePricingBlockItem = ({
  title,
  price,
  description,
}: {
  readonly title: string;
  readonly price?: number | null;
  readonly description: string;
}) => (
  <VStack w="full" alignItems="flex-start" spacing={1}>
    <HStack w="full" justifyContent="space-between">
      <PricingBlockHeading>{title}</PricingBlockHeading>
      <PricingBlockValue>{price}</PricingBlockValue>
    </HStack>
    <PricingBlockDescription>{description}</PricingBlockDescription>
  </VStack>
);

const MobilePricingBlock = ({
  company,
}: {
  readonly company: PricingBlockCompanyFragment;
}) => (
  <VStack
    alignItems="flex-start"
    w="full"
    p={4}
    borderColor="grey.100"
    borderRadius="md"
    bg="grey.50"
    borderWidth={0.5}
    textAlign="left"
    spacing={5}
  >
    <MobilePricingBlockItem
      title="Highest Bid"
      price={company.currentPrices.highestBid}
      description="The highest priced bid that was open at any time this week"
    />
    <MobilePricingBlockItem
      title="Lowest ask"
      price={company.currentPrices.lowestAsk}
      description="The lowest priced listing that was open at any time this week"
    />
    <MobilePricingBlockItem
      title="Last transaction"
      price={company.currentPrices.lastTransaction}
      description="The most recent price at which a bid was accepted"
    />
  </VStack>
);

const PricingBlock = ({
  company,
}: {
  readonly company: PricingBlockCompanyFragment;
}) => {
  const isDesktopView = useIsDesktop();

  return isDesktopView ? (
    <DesktopPricingBlock company={company} />
  ) : (
    <MobilePricingBlock company={company} />
  );
};

export default PricingBlock;
