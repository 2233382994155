import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

const MarkdownDocument = ({ markdown }: { readonly markdown: string }) => (
  <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
    {markdown}
  </ReactMarkdown>
);

export default MarkdownDocument;
