import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Text } from "@chakra-ui/react";

import { ActivityGroupEmptyState, HiiveButton } from "@/components/common";
import { CompanyMarketActivityBuyersBadge } from "@/components/companies";
import { CompanyPageMarketActivityCompanyFragment } from "@/gql";
import { useModal, useCurrentActor } from "@/hooks";
import { getAvailableCompanyActions, getIsBroker } from "@/utils";

const EmptyState = ({
  company,
  numberOfListings,
}: {
  readonly company: CompanyPageMarketActivityCompanyFragment;
  readonly numberOfListings: number;
}) => {
  const { t } = useTranslation();
  const { modals, onOpenModal } = useModal();

  const { canCreateListing } = getAvailableCompanyActions(company);

  const showListingCTA =
    canCreateListing && company.numWatchers > 0 && numberOfListings === 0;

  const actor = useCurrentActor();

  const copy = getIsBroker(actor)
    ? `submit_listing_cta_broker_description`
    : `place_listing_to_notify_buyers`;

  const buttonCopy = getIsBroker(actor)
    ? `submit_a_listing`
    : `place_a_listing`;

  const openModal = getIsBroker(actor)
    ? () => onOpenModal(modals.brokerSubmitListing())
    : () => onOpenModal(modals.createListingOnCompany(company));

  return showListingCTA ? (
    <Card w="full">
      <CardBody>
        <Flex direction="column" gap={5}>
          <Flex direction="column" gap={4}>
            <CompanyMarketActivityBuyersBadge
              variant="rounded-plum"
              company={company}
            />
            <Text>
              {t(`potential_buyers_but_no_standing_bids`, {
                count: company.numWatchers,
                companyName: company.name,
              })}
              {` `}
              <Trans
                i18nKey={copy}
                values={{ count: company.numWatchers }}
                components={{
                  bold: <Text as="span" textStyle="heading-md" />,
                }}
              />
            </Text>
          </Flex>
          <HiiveButton
            flex="none"
            p={0}
            maxWidth="fit-content"
            variant="text-salmon"
            onClick={openModal()}
            sentryLabel="[StandingBidsEmptyState] Place a Listing"
          >
            {t(buttonCopy)}
          </HiiveButton>
        </Flex>
      </CardBody>
    </Card>
  ) : (
    <ActivityGroupEmptyState
      message={t(`no_standing_bids`, {
        companyName: company.name,
      })}
    />
  );
};

export const StandingBidsEmptyState = ({
  company,
  numberOfListings,
}: {
  readonly company: CompanyPageMarketActivityCompanyFragment;
  readonly numberOfListings: number;
}) => <EmptyState company={company} numberOfListings={numberOfListings} />;
