import { Flex } from "@chakra-ui/react";

import { ActivityCardSkeleton, Skeleton } from "@/components/common";

export const StandingBidBuyerDiscussionsSkeleton = () => (
  <Flex direction="column" gap={4} w="full">
    <Skeleton h="20px" maxW="20%" />
    <ActivityCardSkeleton includeBody />
    <ActivityCardSkeleton />
  </Flex>
);
