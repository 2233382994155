import { useMemo } from "react";

import {
  CardBody,
  CardFooter,
  CardHeader,
  Fade,
  HStack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardFooterCountsWrapper,
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardMostRecentMessage,
  ActivityCardNewBadge,
} from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import {
  PostActivityUnaccreditedSellerDashboardPageInquiriesPageDiscussionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useMultipleHoldings } from "@/hooks/featureFlags";
import {
  appendSellerCompanyIdToUrl,
  getDiscussionHasCounterpartyMessage,
  getDiscussionStatusText,
} from "@/utils";

interface InquiryCardProps {
  readonly actor: UserWithInstitutionFragment;
  readonly discussion: PostActivityUnaccreditedSellerDashboardPageInquiriesPageDiscussionFragment;
  readonly companyId?: string;
}

export const InquiryCard = withCurrentActor(
  ({ actor, discussion, companyId }: InquiryCardProps) => {
    const {
      getIsNewInquiry,
      getInquiryNumNewMessages,
    } = useUnaccreditedSellerInlineNotifications();
    const isMultipleHoldingsEnabled = useMultipleHoldings();

    const hasCounterpartyMessage = getDiscussionHasCounterpartyMessage(
      actor,
      discussion,
    );
    const discussionStatus = useMemo(
      () => getDiscussionStatusText(discussion),
      [discussion.state],
    );

    const numNewMessages = getInquiryNumNewMessages(discussion);

    const hasMessages = discussion.totalNumMessages > 0;
    const isNewInquiry = getIsNewInquiry(discussion);

    const showFooterCounts = isNewInquiry || hasMessages;

    const url = `/discussions/${discussion.id}`;

    return (
      <ActivityCard
        href={
          isMultipleHoldingsEnabled
            ? appendSellerCompanyIdToUrl(url, companyId || ``)
            : url
        }
      >
        <CardHeader>
          <HStack justifyContent="space-between">
            <ActivityCardBadge variant="discussion" title="Inquiry" />
            <Text textStyle="text-lg">{discussion.displayId}</Text>
          </HStack>
        </CardHeader>
        {hasCounterpartyMessage && !!discussion.mostRecentMessage && (
          <CardBody>
            <ActivityCardMostRecentMessage discussion={discussion} />
          </CardBody>
        )}
        <CardFooter>
          <ActivityCardFooterWrapper>
            <ActivityCardFooterStatusWrapper>
              <Text textStyle="text-sm">{discussionStatus}</Text>
            </ActivityCardFooterStatusWrapper>
            {showFooterCounts && (
              <ActivityCardFooterCountsWrapper>
                <HStack>
                  {isNewInquiry && (
                    <Fade in>
                      <ActivityCardNewBadge />
                    </Fade>
                  )}
                  {hasMessages && (
                    <MessagesBadge
                      numUnreadMessages={numNewMessages}
                      totalNumMessages={discussion.totalNumMessages}
                    />
                  )}
                </HStack>
              </ActivityCardFooterCountsWrapper>
            )}
          </ActivityCardFooterWrapper>
        </CardFooter>
      </ActivityCard>
    );
  },
);
