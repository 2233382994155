import {
  ChatCenteredDots,
  CheckCircle,
  DeviceMobileCamera,
  ShieldStar,
} from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ActionCard, ActionCardAction } from "@/components/common";
import { UserMultiFactorAuthenticationStatus } from "@/gql";
import { useCurrentActor, useModal } from "@/hooks";
import {
  useEnrollAppMfa,
  useMfaDevices,
  usePreDisableSmsMfa,
  MfaDevices,
} from "@/hooks/frontegg";

const EnrolledBadge = () => {
  const { t } = useTranslation();

  return (
    <Badge variant="status-active">
      <HStack gap={1}>
        <CheckCircle size={12} weight="fill" color="#00924F" />
        <Text textStyle="text-xs" fontWeight="normal">
          {t(`enrolled`)}
        </Text>
      </HStack>
    </Badge>
  );
};

const LoginCard = () => {
  const { t } = useTranslation();

  const { onOpenModal, modals } = useModal();

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`password`)}</Text>
      </CardHeader>
      <CardBody p={{ base: 6, lg: 10 }}>
        <VStack spacing={6}>
          <ActionCard
            action={{
              label: t(`update`),
              onClick: onOpenModal(modals.changePassword()),
            }}
            icon={<ShieldStar size={24} />}
            text={t(`password`)}
          />
        </VStack>
      </CardBody>
    </Card>
  );
};

interface AppMfaProps {
  readonly mfaStatus: UserMultiFactorAuthenticationStatus;
}

const AppMfa = ({ mfaStatus }: AppMfaProps) => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();
  const [enrollAppMfa] = useEnrollAppMfa();

  const handleEnroll = async () => {
    const { data } = await enrollAppMfa();

    if (data) {
      onOpenModal(modals.enrollAppMfa(data.qrCode))();
    } else {
      onOpenModal(modals.enrollAppMfaError())();
    }
  };

  const action: ActionCardAction =
    mfaStatus === UserMultiFactorAuthenticationStatus.Active ||
    mfaStatus === UserMultiFactorAuthenticationStatus.PendingInactive
      ? {
          label: t(`remove`),
          onClick: onOpenModal(modals.disableAppMfa()),
          variant: `rounded-outline-red`,
        }
      : {
          label: t(`enroll`),
          onClick: handleEnroll,
        };

  const showBadge = mfaStatus === UserMultiFactorAuthenticationStatus.Active;

  return (
    <ActionCard
      action={action}
      badge={showBadge ? <EnrolledBadge /> : null}
      icon={<DeviceMobileCamera size={24} />}
      subText={t(`security_mfa_authenticator_app_action_card_sub_text`)}
      text={t(`authenticator_app`)}
    />
  );
};

interface SmsMfaProps {
  readonly mfaStatus: UserMultiFactorAuthenticationStatus;
}

const SmsMfa = ({ mfaStatus }: SmsMfaProps) => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();
  const getMfaDevices = useMfaDevices();
  const preDisableSmsMfa = usePreDisableSmsMfa();

  const getLastFourDigitsOrOpenModal = async () => {
    const mfaDevices: MfaDevices = await getMfaDevices();

    if (!mfaDevices || mfaDevices.phones.length === 0) {
      return onOpenModal(modals.disableSmsMfaError())();
    }

    const { authenticators, phones } = mfaDevices;
    const [phone] = phones;
    const lastFourDigits = phone.phoneNumber.slice(-4);

    // If user is already enrolled into App MFA, pre-disabling is not required
    if (authenticators.length > 0) {
      return onOpenModal(modals.disableSmsMfa(lastFourDigits))();
    }

    return lastFourDigits;
  };

  const callPreDisableSmsMfa = async (lastFourDigits: string) => {
    const { data, error } = await preDisableSmsMfa();

    if (error || !data) {
      return onOpenModal(modals.disableSmsMfaError())();
    }

    const { otcToken } = data;

    return onOpenModal(modals.disableSmsMfa(lastFourDigits, otcToken))();
  };

  const handleRemove = async () => {
    const lastFourDigits = await getLastFourDigitsOrOpenModal();

    if (lastFourDigits) {
      await callPreDisableSmsMfa(lastFourDigits);
    }
  };

  const action: ActionCardAction =
    mfaStatus === UserMultiFactorAuthenticationStatus.Active ||
    mfaStatus === UserMultiFactorAuthenticationStatus.PendingInactive
      ? {
          label: t(`remove`),
          onClick: handleRemove,
          variant: `rounded-outline-red`,
        }
      : {
          label: t(`enroll`),
          onClick: onOpenModal(modals.enrollSmsMfa()),
        };

  const showBadge = mfaStatus === UserMultiFactorAuthenticationStatus.Active;

  return (
    <ActionCard
      action={action}
      badge={showBadge ? <EnrolledBadge /> : null}
      icon={<ChatCenteredDots size={24} />}
      subText={t(`security_mfa_sms_action_card_sub_text`)}
      text={t(`sms_text_message`)}
    />
  );
};

const MfaCard = () => {
  const { t } = useTranslation();
  const actor = useCurrentActor();

  const { multiFactorAuthentications } = actor;
  const { app, sms } = multiFactorAuthentications;

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`multifactor_authentication`)}</Text>
      </CardHeader>
      <CardBody p={{ base: 6, lg: 10 }}>
        <VStack spacing={6}>
          <AppMfa mfaStatus={app} />
          <SmsMfa mfaStatus={sms} />
        </VStack>
      </CardBody>
    </Card>
  );
};

export const SecurityCard = () => (
  <SimpleGrid w="full" height="fit-content" rowGap={6}>
    <GridItem>
      <LoginCard />
    </GridItem>
    <GridItem>
      <MfaCard />
    </GridItem>
  </SimpleGrid>
);
