import { match, P } from "ts-pattern";

export const toBool = (value?: string | null): boolean | null =>
  match(value)
    .with(null, (_) => null)
    .with(`true`, (_) => true)
    .with(`false`, (_) => false)
    .otherwise(() => {
      throw new Error(`Unhandled value`);
    });

export const fromBool = (value?: boolean | null) =>
  match(value)
    .with(P.nullish, (_) => undefined)
    .with(true, (_) => `true`)
    .with(false, (_) => `false`)
    .exhaustive();

export const hoursToDays = (hours: number) => Math.ceil(hours / 24);

export const getParsedAmount = (value?: string) =>
  value ? parseInt(value, 10) : 0;
