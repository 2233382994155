import { match } from "ts-pattern";

import { Text } from "@chakra-ui/react";

import { PleaseNote } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { User, UserRole, UserWithInstitutionFragment } from "@/gql";

type Action =
  | "PLACE_BID"
  | "CREATE_LISTING"
  | "PLACE_STANDING_BID"
  | "ACCEPT_STANDING_BID"
  | "START_DISCUSSION";

const actionToStringMap: { readonly [key in Action]: string } = {
  PLACE_BID: `creating bids`,
  CREATE_LISTING: `listing`,
  PLACE_STANDING_BID: `creating bids`,
  ACCEPT_STANDING_BID: `accepting standing bids`,
  START_DISCUSSION: `you can message another user`,
};

const getMessage = (
  type: Action,
  actor: Pick<User, "identityVerified" | "membershipAgreementSigned">,
) =>
  match(actor)
    .with(
      { identityVerified: false, membershipAgreementSigned: false },
      () =>
        `Before ${actionToStringMap[type]} on Hiive you must sign the Customer Agreement and verify your identity. Please go to the Account section to complete these steps.`,
    )
    .with(
      { identityVerified: true, membershipAgreementSigned: false },
      () =>
        `Before ${actionToStringMap[type]} on Hiive you must sign the Customer Agreement. Please go to the Account section to access and execute your Customer Agreement.`,
    )
    .with(
      { identityVerified: false, membershipAgreementSigned: true },
      () =>
        `Before ${actionToStringMap[type]} on Hiive you must verify your identity. Please go to the Account section to complete identity verification.`,
    )
    .otherwise(() => {
      throw new Error(`Unknown actor case`);
    });

const institutionViewerActionToStringMap: {
  readonly [key in Action]: string;
} = {
  PLACE_BID: `create bids`,
  CREATE_LISTING: `list`,
  PLACE_STANDING_BID: `create bids`,
  ACCEPT_STANDING_BID: `accept standing bids`,
  START_DISCUSSION: `message other users`,
};

const InstitutionInsufficientTradingPermissions = withCurrentActor(
  ({
    type,
    actor,
  }: {
    readonly type: Action;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isViewer = actor.roles.includes(UserRole.Viewer);
    const hasSignedCustomerAgreement =
      !!actor.institution && actor.institution.membershipAgreementSigned;
    return (
      <>
        {!hasSignedCustomerAgreement && (
          <PleaseNote>
            <Text textStyle="deprecated-text-lg">
              Before{` `}
              {actionToStringMap[type]}
              {` `}on Hiive your firm must sign the Customer Agreement. Please
              go to the Account section to execute your Customer Agreement or to
              invite the person from your firm with signing authority.
            </Text>
          </PleaseNote>
        )}
        {hasSignedCustomerAgreement && isViewer && (
          <PleaseNote>
            <Text textStyle="deprecated-text-lg">
              You must be a trader for your institution in order to{` `}
              {institutionViewerActionToStringMap[type]} on Hiive.
            </Text>
          </PleaseNote>
        )}
      </>
    );
  },
);

const IndividualInsufficientTradingPermissions = withCurrentActor(
  ({
    type,
    actor,
  }: {
    readonly type: Action;
    readonly actor: UserWithInstitutionFragment;
  }) => (
    <PleaseNote>
      <Text textStyle="deprecated-text-lg" mb={3}>
        {getMessage(type, actor)}
      </Text>
    </PleaseNote>
  ),
);

const InsufficientTradingPermissions = withCurrentActor(
  ({
    type,
    actor,
  }: {
    readonly type: Action;
    readonly actor: UserWithInstitutionFragment;
  }) =>
    !!actor.institution ? (
      <InstitutionInsufficientTradingPermissions type={type} />
    ) : (
      <IndividualInsufficientTradingPermissions type={type} />
    ),
);

export default InsufficientTradingPermissions;
