import currency from "currency.js";
import { Form } from "formik";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  GridItem,
  ModalBody,
  Show,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  FullDivider,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  ShareDetails,
  ShareDetailsStats,
} from "@/components/common";
import {
  FixedValueInput,
  FormikQL,
  FormNumberInput,
  MoneyInput,
} from "@/components/form";
import {
  bidTimeLimitSchema,
  ListingBuyerRoundingDisclaimer,
} from "@/components/postings";
import {
  BidPageBidByIdDocument,
  BidPageMyActivityDocument,
  CompletedTransfersCardMyActivityDocument,
  ListingPageListingByIdDocument,
  ModifyBidModalBidFragment,
  ModifyBidModalListingFragment,
  ModifyBidMutation,
  SignDocumentAlertMessagesMyActivityDocument,
  useModifyBidMutation,
  UserActivityMyActivityDocument,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import {
  constants,
  formatShares,
  getNumOfShares,
  getNumSharesAvailableRounded,
  getPricePerShare,
  hoursFromNow,
} from "@/utils";

interface ModifyBidModalFormValues {
  readonly numShares: number;
  readonly pricePerShare: number;
  readonly timeLimit: number;
}

const createInitialValues = (
  bid: ModifyBidModalBidFragment,
): ModifyBidModalFormValues => ({
  numShares: bid.numShares,
  pricePerShare: bid.pricePerShare / 100,
  timeLimit:
    hoursFromNow(bid.expireAt) < 24
      ? 1
      : Math.ceil(hoursFromNow(bid.expireAt) / 24),
});

const createValidationSchema = ({
  listing,
}: {
  readonly listing?: ModifyBidModalListingFragment;
}) =>
  Yup.object().shape({
    pricePerShare: Yup.number()
      .nullable()
      .test(
        `min-bid-size`,
        `The bid size can't be less than ${currency(
          constants.min_bid_lot.number,
        ).format()}`,
        function test(value: number) {
          const { numShares } = this.parent;

          return value * numShares >= constants.min_bid_lot.number;
        },
      )
      .required(`Price per share is required`),
    numShares: Yup.number()
      .max(
        listing ? getNumSharesAvailableRounded(listing) : 0,
        `Can't request more shares than a listing has`,
      )
      .required(`Required`),
    timeLimit: bidTimeLimitSchema,
    confirmed: Yup.boolean(),
  });

const createMapVariables = (bidId: string) => ({
  timeLimit,
  ...values
}: ModifyBidModalFormValues) => ({
  bidId,
  input: {
    ...values,
    pricePerShare: currency(values.pricePerShare).intValue,
    timeLimit: timeLimit * 24,
  },
});

const ModifyBidModalShareDetails = ({
  listing,
}: {
  readonly listing: ModifyBidModalListingFragment;
}) => {
  const numberOfShares = getNumOfShares(listing, true);
  const pricePerShare = getPricePerShare(listing);

  return (
    <ShareDetails variant="listing" title="Listing Details">
      <ShareDetailsStats
        numberOfShares={numberOfShares}
        pricePerShare={pricePerShare}
      />
      <ListingBuyerRoundingDisclaimer />
    </ShareDetails>
  );
};

interface ModifyBidModalProps {
  readonly bid: ModifyBidModalBidFragment;
}

const ModifyBidModal = ({ bid }: ModifyBidModalProps) => {
  const { successToast } = useCustomToast();
  const router = useRouter();
  const { closeModal } = useModal();

  const mutation = useModifyBidMutation({
    refetchQueries: [
      BidPageBidByIdDocument,
      ListingPageListingByIdDocument,
      BidPageMyActivityDocument,
      UserActivityMyActivityDocument,
      SignDocumentAlertMessagesMyActivityDocument,
      CompletedTransfersCardMyActivityDocument,
    ],
  });

  const onSuccess = (response: ModifyBidMutation) => {
    successToast(`Bid modified.`);
    closeModal();
    router.push(`/listings/bids/${response?.modifyBid?.bid?.id}`);
  };

  const validationSchema = createValidationSchema({
    listing: bid.listing,
  });

  const initialValues = createInitialValues(bid);

  const mapVariables = createMapVariables(bid.id);

  const listingNumShares = formatShares(
    getNumSharesAvailableRounded(bid.listing),
  );

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        mutation={mutation}
        mutationNames={[`modifyBid`]}
        mapVariables={mapVariables}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSuccess={onSuccess}
      >
        {({ isSubmitting }) => (
          <Form>
            <HiiveModalHeader>
              Modify bid on {` `}
              {bid.listing.company.name}
            </HiiveModalHeader>
            <ModalBody>
              <SimpleGrid columns={2} columnGap={9} rowGap={6} w="full">
                <GridItem colSpan={2}>
                  <VStack w="full" alignItems="flex-start" spacing={5}>
                    <Text textStyle="heading-md">
                      Offer at, below or above the Seller&apos;s ask.
                    </Text>
                    <ModifyBidModalShareDetails listing={bid.listing} />
                  </VStack>
                </GridItem>
                <GridItem colSpan={2}>
                  <FullDivider />
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  {!bid.listing.acceptPartialBid ? (
                    <FixedValueInput
                      label="Number of shares"
                      name="numShares"
                      fixedValue={listingNumShares}
                    />
                  ) : (
                    <FormNumberInput
                      name="numShares"
                      label="Number of shares"
                    />
                  )}
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <MoneyInput name="pricePerShare" label="Price per share" />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormNumberInput
                    name="timeLimit"
                    label="Bid valid for (days)"
                  />
                </GridItem>
              </SimpleGrid>
            </ModalBody>

            <HiiveModalFooter>
              <Show above="md" ssr={false}>
                <HiiveCancelButton
                  sentryLabel="[ModifyBid/Cancel]"
                  onCancel={closeModal}
                />
              </Show>
              <HiiveSubmitButton
                sentryLabel="[ModifyBid/Submit]"
                submitText="Modify"
                type="submit"
                isLoading={isSubmitting}
              />
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default ModifyBidModal;
