import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { FlowKind, ModalKind } from "@/components/providers";
import {
  AcceptBidSuccessModalBidListingCompanyFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useFlowLauncher, useModal } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getBidNumSharesActual,
  getIsUnaccreditedSeller,
  getSecuritySpecialistContact,
  getShortDocumentTitleByTransferMethod,
} from "@/utils";

import { useAcceptBidSequenceModalStepFormContext } from "./AcceptBidSequenceModalStepFormContext";

const SecuritySpecialistLink = ({
  company,
}: {
  readonly company: AcceptBidSuccessModalBidListingCompanyFragment;
}) => {
  const { t } = useTranslation();

  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <Text as="span" fontWeight="medium">
      <MailtoLink
        email={securitySpecialistEmail}
        subject={t(`contact_company_security_specialist`, {
          companyName: company.name,
        })}
      >
        {t(`company_security_specialist`, { companyName: company.name })}
      </MailtoLink>
    </Text>
  );
};

const AcceptBidSuccessModal = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { mutationData } = useAcceptBidSequenceModalStepFormContext();
  const { showFlow } = useFlowLauncher();
  const {
    closeModal,
    addModalCloseHandler,
    removeModalCloseHandler,
  } = useModal();
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  useEffect(() => {
    if (isUnaccreditedSeller) {
      addModalCloseHandler(ModalKind.AcceptBid, () => {
        showFlow(FlowKind.U16RTransactionCelebration);
      });
    }

    return () => {
      removeModalCloseHandler(ModalKind.AcceptBid);
    };
  }, [isUnaccreditedSeller]);

  const bid = mutationData?.acceptBid.bid;
  const listing = bid?.listing;

  if (!bid || !listing) return null;

  const numShares = formatShares(getBidNumSharesActual(bid));
  const pricePerShare = formatPricePerShare(bid.pricePerShare);
  const documentTitle = getShortDocumentTitleByTransferMethod(
    bid.listing.transferMethod,
  );

  return (
    <>
      <HiiveModalHeader>Bid accepted!</HiiveModalHeader>
      <ModalBody>
        <Text textStyle="heading-xl" mb={4}>
          {`You accepted ${listing.company.name} Bid ${bid.shortId} for ${numShares} shares at ${pricePerShare}/sh!`}
        </Text>
        <VStack mb={4} spacing={6} alignItems="start">
          <Text>
            The next step is for you and the buyer to execute the{` `}
            {documentTitle}
            {` `}for this transaction. You may message the bidder directly with
            any questions or comments.
          </Text>
          <Text>
            You may also reach out anytime to the{` `}
            <SecuritySpecialistLink company={listing.company} />
            {` `}
            for assistance on your transaction.
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          sentryLabel="[AcceptBidSuccess/Submit]"
          submitText="Okay"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default withCurrentActor(AcceptBidSuccessModal);
