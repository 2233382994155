import { Stack, StackProps } from "@chakra-ui/react";

type ButtonFooterWrapperProps = StackProps;

const ButtonFooterWrapper = ({
  children,
  ...otherProps
}: ButtonFooterWrapperProps) => (
  <Stack
    direction={{ base: `column-reverse`, md: `row` }}
    spacing={0}
    gap={2}
    w="full"
    alignItems="flex-end"
    justifyContent="flex-end"
    {...otherProps}
  >
    {children}
  </Stack>
);

export default ButtonFooterWrapper;
