import dayjs from "dayjs";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CardBody,
  CardFooter,
  Stack,
  Text,
} from "@chakra-ui/react";

import { ActionNeeded, Loader, MarkdownDocument } from "@/components/common";
import { SuitabilityModifyFieldDescription } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment, useCurrentSuitabilityQuery } from "@/gql";
import { useSuitabilityPageStatus } from "@/hooks";
import styles from "@/styles/suitability-markdown-styles.module.css";
import { getIsInstitutionUser } from "@/utils";

const SuitabilityCardContent = withCurrentActor(
  ({
    actor,
    onOpen,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly onOpen: () => void;
  }) => {
    const { t } = useTranslation();
    const isInstitutionUser = getIsInstitutionUser(actor);
    const investmentGoalAnswers = isInstitutionUser
      ? actor.institution?.investmentGoalAnswers
      : actor.investmentGoalAnswers;

    const suitabilityAnswers = investmentGoalAnswers ?? [];

    const {
      data: suitabilityStatusData,
      loading,
    } = useCurrentSuitabilityQuery();
    const suitabilityOutdated =
      !suitabilityStatusData?.investmentGoalAnswersCurrent?.current || false;

    const suitabilityPageStatus = useSuitabilityPageStatus(actor);

    if (isInstitutionUser && !actor.institution)
      throw new Error(
        `Actor is an institution but does not contain any institution data`,
      );

    const { suitabilityLastUpdatedAt } = isInstitutionUser
      ? // This check isn't necessary but ts throws a type error if it isnt here. Case is handled above
        actor.institution ?? actor
      : actor;

    if (loading)
      return (
        <CardBody p={{ base: 4, lg: 10 }}>
          <Loader />
        </CardBody>
      );

    return (
      <>
        <CardBody p={{ base: 4, lg: 10 }}>
          {suitabilityOutdated && (
            <Box mb={4}>
              <ActionNeeded heading={t(`update_required_suitability`)}>
                {t(
                  isInstitutionUser
                    ? `update_required_institutional`
                    : `update_required_individual`,
                  { companyName: actor.institution?.legalName },
                )}
              </ActionNeeded>
            </Box>
          )}
          <Stack
            direction={{ base: `column-reverse`, lg: `row` }}
            alignItems={{ base: `flex-end`, lg: `normal` }}
            gap={4}
          >
            <Box w="full">
              {suitabilityAnswers
                .slice()
                .sort(
                  (a, b) => a.option.question.order - b.option.question.order,
                )
                .map(({ option, text }, index) => (
                  <Box
                    key={option.id}
                    mb={12}
                    mt={index === 0 ? `0px !important` : 12}
                  >
                    <div
                      className={styles[`suitability-question-text-markdown`]}
                    >
                      <MarkdownDocument markdown={option.question.text} />
                    </div>
                    {option.question.description && (
                      <SuitabilityModifyFieldDescription
                        markdown={option.question.description}
                      />
                    )}
                    <Text mt={6}>
                      {t(`answer`)}:{` `}
                      <Text as="span" fontWeight={500}>
                        {text || option.text}
                      </Text>
                    </Text>
                  </Box>
                ))}
            </Box>
            <Box pb={{ base: 4, md: 0 }}>
              <Button
                variant="boxed-grey"
                size="xl"
                disabled={suitabilityPageStatus !== `modify`}
                onClick={onOpen}
              >
                <Text as="span" px={6}>
                  {t(`modify`)}
                </Text>
              </Button>
            </Box>
          </Stack>
        </CardBody>
        {!isNil(suitabilityLastUpdatedAt) && (
          <CardFooter>
            {t(`submitted_on`, {
              date: `${dayjs(suitabilityLastUpdatedAt).format(
                `Do MMM YYYY`,
              )} (${dayjs(suitabilityLastUpdatedAt).format(`hh:mm A z`)})`,
            })}
          </CardFooter>
        )}
      </>
    );
  },
);

export default SuitabilityCardContent;
