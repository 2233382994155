import dayjs from "dayjs";
import { ReactNode } from "react";

import { Box, Text, Center, BoxProps, VStack, Link } from "@chakra-ui/react";

import { Logo } from "@/components/common";
import { useIsHiiveConnect } from "@/hooks";
import { constants } from "@/utils";

interface AuthContainerProps extends BoxProps {
  readonly title: string;
  readonly children: JSX.Element | readonly JSX.Element[];
}

const FooterLink = ({
  href,
  children,
}: {
  readonly href: string;
  readonly children: ReactNode;
}) => (
  <Link href={href} textDecoration="underline" target="_blank">
    {children}
  </Link>
);

const AuthFooter = () => {
  const currentYear = dayjs().year();

  return (
    <VStack py={8} maxW="50rem" px={4} spacing={4}>
      <Text textAlign="center" textStyle="deprecated-text-sm">
        Securities are offered by Hiive Markets Limited, member{` `}
        <FooterLink href="https://www.finra.org/">FINRA</FooterLink> /{` `}
        <FooterLink href="https://www.sipc.org/">SIPC</FooterLink>. Find Hiive
        on
        {` `}
        <FooterLink href="https://brokercheck.finra.org/firm/summary/316580">
          BrokerCheck
        </FooterLink>
        .
      </Text>
      <Text textAlign="center" textStyle="deprecated-text-sm">
        © The Hiive Company Limited {currentYear}. All rights reserved. By using
        this site, you accept our
        {` `}
        <FooterLink href={`${constants.marketing_website_url}/terms`}>
          Terms of Use
        </FooterLink>
        {` `}
        and{` `}
        <FooterLink href={`${constants.marketing_website_url}/privacy`}>
          Privacy Policy
        </FooterLink>
        . Before engaging with this broker-dealer, review these important{` `}
        <FooterLink href={`${constants.marketing_website_url}/disclosures`}>
          Disclosures
        </FooterLink>
        .
      </Text>
    </VStack>
  );
};

const AuthContainer = ({
  title,
  children,
  ...boxProps
}: AuthContainerProps) => {
  const hiiveConnect = useIsHiiveConnect();

  return (
    <VStack
      pt={14}
      px={{ base: 4, lg: 8 }}
      bg="grey.50"
      minH="100vh"
      w="full"
      justifyContent="space-between"
    >
      <Box maxW="30rem" mx="auto" position="relative" w="full" {...boxProps}>
        <Center>
          {hiiveConnect ? (
            <Logo.ConnectGrey
              mb={{ base: 9, md: 14 }}
              width={40}
              height="auto"
            />
          ) : (
            <Logo.Grey mb={{ base: 9, md: 14 }} width={40} />
          )}
        </Center>
        <Text
          textAlign="center"
          textStyle="deprecated-heading-4xl"
          color="h-dark-grey"
        >
          {title}
        </Text>
        {children}
      </Box>
      <AuthFooter />
    </VStack>
  );
};

export default AuthContainer;
