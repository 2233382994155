import {
  Card,
  CardBody,
  CardHeader,
  CardProps,
  HStack,
  VStack,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

export const DashboardLatestMarketActivitySkeleton = ({
  ...cardProps
}: CardProps) => (
  <Card w="full" h="fit-content" {...cardProps}>
    <CardHeader>
      <Skeleton h={4} maxW="40%" />
    </CardHeader>
    <HStack
      w="full"
      spacing={4}
      px={{ base: 4, md: 6 }}
      pt={{ base: 4, md: 6 }}
    >
      <Skeleton h={7} maxW="25%" />
      <Skeleton h={7} maxW="30%" />
    </HStack>
    <CardBody h="auto" overflowY="auto" maxH="1408px">
      <VStack spacing={4}>
        <Skeleton h="116px" count={10} />
      </VStack>
    </CardBody>
  </Card>
);
