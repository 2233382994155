import { useMediaQuery } from "@chakra-ui/react";

const useIsDesktop = () => {
  useMediaQuery(`(min-width: 768px)`);

  // There is an apparent bug in useMediaQuery that was causing some flickering
  // isDesktop would be false for a split second before resolving to the correct value
  // supposedly useMediaQuery uses window.matchMedia under the hood, but they seem to yield different results
  // window.matchMedia gives me what I would expect
  const { matches } = window.matchMedia(`(min-width: 768px)`);

  return matches;
};

export default useIsDesktop;
