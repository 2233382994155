import { CircleDashed } from "@phosphor-icons/react";

import { Badge, HStack, Text } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";
import { TransferMethod } from "@/gql";
import { useColors } from "@/hooks";
import { getLongDocumentTitleByTransferMethod } from "@/utils";

const PartiallySoldBadge = ({
  transferMethod,
}: {
  readonly transferMethod: TransferMethod;
}) => {
  const [teal800] = useColors([`teal.800`]);

  const longDocumentTitle = getLongDocumentTitleByTransferMethod(
    transferMethod,
  );

  return (
    <CustomTooltip
      placement="bottom"
      tooltipContent={`A portion of this lot has been sold or is pending execution of the ${longDocumentTitle}. Buyers can bid on the remaining shares.`}
    >
      <Badge variant="rounded-teal">
        <HStack spacing={1.5}>
          <CircleDashed color={teal800} weight="bold" size={14} />
          <Text>Partially sold</Text>
        </HStack>
      </Badge>
    </CustomTooltip>
  );
};

export default PartiallySoldBadge;
