import currency from "currency.js";
import dayjs from "dayjs";
import { Form } from "formik";
import { AnimatePresence } from "framer-motion";
import uniqueId from "lodash/uniqueId";

import { AnimatedModalStep, StepFormikQL } from "@/components/form";
import {
  CompanyWatchlistActorDocument,
  CreateListingSelectCompanyInputCompanyFragment,
  ListingInput,
  MarketActivityDocument,
  SigningProcedure,
  TransferMethod,
  useCreateListingMutation,
  UserActivityMyActivityDocument,
} from "@/gql";
import { useStepRouter } from "@/hooks";
import { constants, sumShareSeriesMakeup } from "@/utils";
import * as datetime from "@/utils/datetime";

import {
  CreateListingSequenceModalFormValues,
  CreateListingModal,
  CreateListingSuccessModal,
  StepKeys,
  stepKeys,
  CreateListingSequenceModalStepFormContext,
} from "./steps";

interface CreateListingSequenceModalContentProps {
  readonly initialCompany?: CreateListingSelectCompanyInputCompanyFragment;
}

const createInitialValues = (
  initialCompany?: CreateListingSelectCompanyInputCompanyFragment,
) => ({
  company: initialCompany || null,
  pricePerShare: null,
  transferMethod: TransferMethod.Direct,
  expireAt: datetime
    .add(`week`, constants.default_listing_expire_after_weeks, dayjs())
    .toDate(),
  otherDetails: null,
  confirmedEligibilityRequirements: false,
  confirmedInformationDisclosure: false,
  confirmed: false,
  muteNotifyWatchers: false,
  manualSigningProcedure: false,
  shareSeriesMakeup: [{ shareSeries: null, numShares: null, key: uniqueId() }],
  representedEmail: ``,
  representedFirstName: ``,
  representedLastName: ``,
  notifyRepresentingEmail: true,
  hasExpirationDate: false,
});

const mapVariables = ({
  confirmedEligibilityRequirements: _confirmedEligibilityRequirements,
  confirmedInformationDisclosure: _confirmedInformationDisclosure,
  confirmed: _confirmed,
  manualSigningProcedure,
  shareSeriesMakeup,
  hasExpirationDate,
  company,
  pricePerShare,
  expireAt,
  representedEmail,
  representedFirstName,
  representedLastName,
  ...values
}: CreateListingSequenceModalFormValues) => ({
  input: {
    ...values,
    companyId: company?.id,
    numShares: sumShareSeriesMakeup(shareSeriesMakeup),
    shareSeriesMakeup: shareSeriesMakeup.map(({ key: _key, ...rest }) => ({
      ...rest,
    })),
    pricePerShare: !!pricePerShare ? currency(pricePerShare).intValue : null,
    expireAt:
      hasExpirationDate && !!expireAt
        ? datetime.endOf(`day`, expireAt).format()
        : null,
    signingProcedure: manualSigningProcedure
      ? SigningProcedure.Manual
      : SigningProcedure.Automated,
    representedUser: {
      email: representedEmail,
      firstName: representedFirstName,
      lastName: representedLastName,
    },
  } as ListingInput,
});

export const CreateListingSequenceModalContent = ({
  initialCompany,
}: CreateListingSequenceModalContentProps) => {
  const mutation = useCreateListingMutation({
    refetchQueries: [
      MarketActivityDocument,
      UserActivityMyActivityDocument,
      CompanyWatchlistActorDocument,
    ],
  });

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.createListingShareDetails,
      stepKeys.createListingAdditionalDetails,
      stepKeys.createListingSuccess,
    ],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  const createListingModalStepKeys: readonly StepKeys[] = [
    stepKeys.createListingShareDetails,
    stepKeys.createListingAdditionalDetails,
  ];

  return (
    <StepFormikQL
      stepRouter={stepRouter}
      mutation={mutation}
      mutationNames={[`createListing`]}
      initialValues={createInitialValues(initialCompany)}
      mapVariables={mapVariables}
      context={CreateListingSequenceModalStepFormContext}
    >
      {({ formikProps }) => (
        <Form>
          <AnimatePresence mode="wait" initial={false}>
            {createListingModalStepKeys.includes(currentStepKey) && (
              <AnimatedModalStep key={createListingModalStepKeys.join()}>
                <CreateListingModal
                  initialCompany={initialCompany}
                  stepRouter={stepRouter}
                  {...formikProps}
                />
              </AnimatedModalStep>
            )}
            {stepKeys.createListingSuccess === currentStepKey && (
              <AnimatedModalStep key={stepKeys.createListingSuccess}>
                <CreateListingSuccessModal />
              </AnimatedModalStep>
            )}
          </AnimatePresence>
        </Form>
      )}
    </StepFormikQL>
  );
};
