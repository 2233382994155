import { useFormikContext } from "formik";
import isNil from "lodash/isNil";
import { forwardRef, useDeferredValue } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { FixedValueInput, FormField, MoneyInput } from "@/components/form";
import {
  AskPriceComparisonChart,
  AskPriceComparisonChartDisclaimer,
  BidCancellationWarning,
  FeesDisclaimer,
  getHasAskPriceChart,
  HighFeesWarning,
  ListingFeeBreakdown,
} from "@/components/listings";
import {
  BidState,
  UnaccreditedSellerModifyListingByIdPageBidFragment,
  UnaccreditedSellerModifyListingByIdPageListingFragment,
  UnaccreditedSellerModifyListingByIdPageCompanyFragment,
} from "@/gql";
import { useDebouncedCallback } from "@/hooks";
import { useModifyListingNumSharesEnabled } from "@/hooks/featureFlags";
import { formatShares, getAreFeesHigh, Nullable } from "@/utils";

import { ModifyListingFormValues } from "./types";

const getNumberOfBidsToCancel = (
  bids: readonly UnaccreditedSellerModifyListingByIdPageBidFragment[],
  maxShares: number,
) =>
  bids.filter(({ state, numSharesActual, counterBid }) => {
    if (state === BidState.Active) {
      return numSharesActual > maxShares;
    }

    if (state === BidState.Countered && !!counterBid?.numShares) {
      return counterBid?.numShares > maxShares;
    }

    return false;
  }).length;

const LotFields = ({
  listing,
}: {
  readonly listing: UnaccreditedSellerModifyListingByIdPageListingFragment;
}) => {
  const { t } = useTranslation();
  const {
    values: { numShares, pricePerShare },
    errors,
    touched,
    setFieldTouched,
  } = useFormikContext<Nullable<ModifyListingFormValues>>();
  const isModifyListingNumSharesFlagEnabled = useModifyListingNumSharesEnabled();

  const hasTransactionSizeError = !isNil(errors.numShares);

  const numberOfBidsToCancel = !isNil(numShares)
    ? getNumberOfBidsToCancel(listing.bids, numShares)
    : 0;

  const showBidCancellationWarning =
    numberOfBidsToCancel > 0 && !(touched.numShares && hasTransactionSizeError);

  const formattedShares = formatShares(numShares);

  const areFeesHigh = getAreFeesHigh({ pricePerShare, numShares });

  const onChangeLotFields = useDebouncedCallback(() => {
    if (!touched.numShares && areFeesHigh) {
      setFieldTouched(`numShares`, true);
      setFieldTouched(`pricePerShare`, true);
    }
  }, 500);

  return (
    <VStack spacing={7} alignItems="flex-start">
      <VStack w="full" spacing={3}>
        {isModifyListingNumSharesFlagEnabled ? (
          <FormField.Control name="numShares">
            <FormField.NumberInput
              label={t(`number_of_shares`)}
              onChange={onChangeLotFields}
            />
            <FormField.Error />
          </FormField.Control>
        ) : (
          <FixedValueInput
            label={t(`how_many_shares_wish_to_sell`)}
            name="numShares"
            fixedValue={formattedShares}
            subLabel={t(`how_many_shares_wish_to_sell_disabled`)}
          />
        )}
        {showBidCancellationWarning && (
          <BidCancellationWarning numberOfBidsToCancel={numberOfBidsToCancel} />
        )}
      </VStack>
      <MoneyInput
        label={t(`at_what_price`)}
        name="pricePerShare"
        onChange={onChangeLotFields}
      />
    </VStack>
  );
};

export const SharePriceCardV2 = forwardRef<
  HTMLDivElement,
  {
    readonly listing: UnaccreditedSellerModifyListingByIdPageListingFragment;
    readonly company: UnaccreditedSellerModifyListingByIdPageCompanyFragment;
  }
>(({ listing, company }, ref) => {
  const { t } = useTranslation();

  const {
    values: { pricePerShare, numShares },
    touched,
  } = useFormikContext<Nullable<ModifyListingFormValues>>();

  const showHighFeesWarning =
    !!touched.numShares && getAreFeesHigh({ pricePerShare, numShares });

  const deferredPricePerShare = useDeferredValue(pricePerShare);

  const askPriceInDollars = !isNil(deferredPricePerShare)
    ? deferredPricePerShare
    : 0;

  const hasAskPriceChart = getHasAskPriceChart(company);

  return (
    <Card w="full" ref={ref}>
      <CardHeader>
        <Text
          textStyle="heading-sm"
          _before={{
            content: `counter(section) ". "`,
          }}
        >
          {t(`share_price`)}
        </Text>
      </CardHeader>
      <CardBody>
        <SimpleGrid columns={12} columnGap={6} rowGap={4}>
          <GridItem colSpan={12}>
            <Text textStyle="heading-2xs">{t(`share_details`)}</Text>
          </GridItem>
          <GridItem colSpan={{ base: 12, xl: 5 }}>
            <LotFields listing={listing} />
          </GridItem>
          <GridItem
            colStart={{ base: 0, xl: 6, "2xl": 7 }}
            colSpan={{ base: 12, xl: 7, "2xl": 6 }}
          >
            <VStack w="full" spacing={4}>
              <ListingFeeBreakdown
                pricePerShare={pricePerShare}
                numShares={numShares}
                listingId={listing.id}
              />
              {showHighFeesWarning && <HighFeesWarning />}
            </VStack>
          </GridItem>
          <GridItem colSpan={12}>
            <FeesDisclaimer />
          </GridItem>
        </SimpleGrid>
      </CardBody>
      {hasAskPriceChart && (
        <CardBody>
          <VStack alignItems="flex-start" spacing={4}>
            <Text textStyle="heading-2xs">
              {t(`how_your_ask_price_compares`)}
            </Text>
            <AskPriceComparisonChart
              company={company}
              askPriceInDollars={askPriceInDollars}
            />
            <AskPriceComparisonChartDisclaimer />
          </VStack>
        </CardBody>
      )}
    </Card>
  );
});
