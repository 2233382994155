import { useField } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { VStack } from "@chakra-ui/react";

import { CustomRadio } from "@/components/common";
import {
  AccreditationRadioInput,
  AccreditationTextAreaInput,
} from "@/components/form";
import { QuestionFormCard } from "@/components/onboarding";

export const CanadaInstitutionAccreditationFields = () => {
  const { t } = useTranslation();

  const [_field, _, { setValue: setAnswers }] = useField(`answers`);

  const [radioValue, setRadioValue] = useState<string | undefined>();
  const [textAreaValue, setTextAreaValue] = useState<string>(``);

  const onChangeRadioValue = (value?: string) => {
    setRadioValue(value);

    if (!value) {
      setAnswers([]);
      return;
    }

    if (value === `ca-institutional-other-criteria`) {
      setAnswers([{ key: value, text: textAreaValue }]);
      return;
    }

    setAnswers([{ key: value }]);
  };

  const onChangeTextAreaValue = (value: string) => {
    setTextAreaValue(value);

    if (radioValue !== `ca-institutional-other-criteria`) return;

    setAnswers([{ key: `ca-institutional-other-criteria`, text: value }]);
  };

  return (
    <VStack>
      <QuestionFormCard text={t(`firm_fund_must_be_accredited_and_how`)}>
        <AccreditationRadioInput
          value={radioValue}
          onChange={onChangeRadioValue}
        >
          <VStack alignItems="flex-start">
            <CustomRadio value="ca-institutional-investment-fund">
              {t(`ca_institutional_investment_fund`)}
            </CustomRadio>
            <CustomRadio value="ca-institutional-corporation-net-assets-v2">
              {t(`ca_institutional_corporation_net_assets`)}
            </CustomRadio>
            <CustomRadio value="ca-institutional-corporation-owners-accredited">
              {t(`ca_institutional_corporation_owners_accredited`)}
            </CustomRadio>
            <CustomRadio value="ca-institutional-financial-institution">
              {t(`ca_institutional_financial_institution`)}
            </CustomRadio>
            <CustomRadio value="ca-institutional-municipality">
              {t(`ca_institutional_municipality`)}
            </CustomRadio>
            <CustomRadio value="ca-institutional-pension-fund">
              {t(`ca_institutional_pension_fund`)}
            </CustomRadio>
            <CustomRadio value="ca-institutional-not-accredited">
              {t(`ca_institutional_not_accredited`)}
            </CustomRadio>
            <CustomRadio value="ca-institutional-other-criteria">
              {t(`ca_institutional_other_criteria`)}
            </CustomRadio>
          </VStack>
        </AccreditationRadioInput>
      </QuestionFormCard>
      {radioValue === `ca-institutional-other-criteria` && (
        <AccreditationTextAreaInput
          value={textAreaValue}
          onChange={onChangeTextAreaValue}
          placeholder={t(`description`)}
          label={t(`please_describe`)}
        />
      )}
    </VStack>
  );
};
