import { WarningCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import { GqlError } from "@/apollo-client";
import {
  ButtonFooterWrapper,
  CallOut,
  CodeInput,
  HiiveBackButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CurrentContextDocument,
  useRecordUserMultiFactorAuthenticationMutation,
  UserMultiFactorAuthenticationMethod,
  UserMultiFactorAuthenticationStatus,
} from "@/gql";
import { useModal } from "@/hooks";
import { useVerifySmsMfa } from "@/hooks/frontegg";
import { GqlErrorCodes } from "@/types";

const EnrollSmsMfaVerifyModal = ({
  otcToken,
  phoneNumber,
}: {
  readonly otcToken: string;
  readonly phoneNumber: string;
}) => {
  const { t } = useTranslation();
  const { onOpenModal, modals } = useModal();
  const [verifyMfa, { loading, error }] = useVerifySmsMfa();
  const [
    recordUserMultiFactorAuthentication,
  ] = useRecordUserMultiFactorAuthenticationMutation({
    refetchQueries: [CurrentContextDocument],
  });
  const [pinValue, setPinValue] = useState<string>(``);

  const handleComplete = async (value: string) => {
    const variables = {
      input: {
        method: UserMultiFactorAuthenticationMethod.Sms,
        status: UserMultiFactorAuthenticationStatus.PendingActive,
      },
    };

    const result = await recordUserMultiFactorAuthentication({
      variables,
      errorPolicy: `all`,
    });

    const [mutationError] = result.errors || [];

    const mfaEnrollmentPending =
      result.data?.recordUserMultiFactorAuthentication
        ?.userMultiFactorAuthentication ||
      (mutationError as GqlError).code === GqlErrorCodes.MFA_ENROLLMENT_PENDING;

    if (mfaEnrollmentPending) {
      const { data, ok } = await verifyMfa({ otcToken, code: value });

      if (data || ok) {
        onOpenModal(modals.enrollSmsMfaSuccess(data?.recoveryCode))();
      }
    }
  };

  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>
        <Text>{t(`verify_phone_number`)}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="center" gap={4}>
          <Text>
            <Trans
              i18nKey="verify_phone_number_description"
              t={t}
              values={{ phoneNumber }}
            />
          </Text>

          <CodeInput
            disabled={loading}
            onChange={(value: string) => setPinValue(value)}
            onComplete={handleComplete}
          />
          {error && (
            <CallOut variant="red" w={{ base: `full`, lg: 340 }}>
              <WarningCircle color="#C4383A" size={14} weight="fill" />
              <Text textStyle="text-sm">{t(`incorrect_code`)}</Text>
            </CallOut>
          )}
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveBackButton
            sentryLabel="[EnrollSmsMfaVerify/Back]"
            onClick={() => onOpenModal(modals.enrollSmsMfa())()}
          >
            {t(`cancel`)}
          </HiiveBackButton>
          <HiiveSubmitButton
            isDisabled={pinValue.length !== 6}
            sentryLabel="[EnrollSmsMfaVerify/Submit]"
            submitText={t(`verify`)}
            type="submit"
            onClick={() => handleComplete(pinValue)}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default EnrollSmsMfaVerifyModal;
