import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  MailtoLink,
} from "@/components/common";
import { ListingPageBidCard } from "@/components/postings";
import {
  ListingPageBrokerListerActivityListingFragment,
  ListingState,
} from "@/gql";
import { getSecuritySpecialistContact } from "@/utils";
import { filterOpenBids, filterPastBids } from "@/utils/bid";

/**
 * Shows bids on the broker's listing which are `active` or `countered`
 */
const OpenBids = ({
  listing,
}: {
  readonly listing: ListingPageBrokerListerActivityListingFragment;
}) => {
  const { t } = useTranslation();

  const openBids = filterOpenBids(listing.bids);

  const hasOpenBids = openBids.length > 0;

  return hasOpenBids ? (
    <ActivitySection data-testid="active-bids">
      <ActivityGroup title={t(`looking_to_buy`)}>
        {openBids.map((bid) => (
          <ListingPageBidCard bid={bid} key={bid.id} />
        ))}
      </ActivityGroup>
    </ActivitySection>
  ) : (
    <ActivityGroupEmptyState
      data-testid="no-active-bids"
      message={t(`no_active_bids_on_listing`)}
    />
  );
};

/**
 * Shows "past" bids on the broker's listing
 */
const PastBids = ({
  listing,
}: {
  readonly listing: ListingPageBrokerListerActivityListingFragment;
}) => {
  const { t } = useTranslation();

  const pastBids = filterPastBids(listing.bids);

  const hasPastBids = pastBids.length > 0;

  return hasPastBids ? (
    <VStack spacing={4} w="full">
      {pastBids.map((bid) => (
        <ListingPageBidCard bid={bid} key={bid.id} />
      ))}
    </VStack>
  ) : (
    <ActivityGroupEmptyState message={t(`no_past_bids`)} />
  );
};

const InReviewSecuritySpecialistCard = ({
  listing,
}: {
  readonly listing: ListingPageBrokerListerActivityListingFragment;
}) => {
  const { t } = useTranslation();

  const contactEmail = getSecuritySpecialistContact(listing.company);
  const companyName = listing.company.name;

  return (
    <Card>
      <CardBody>
        <VStack spacing={4} alignItems="flex-start">
          <Text>
            {t(`broker_lister_security_specialist_will_manage_bids`, {
              companyName,
            })}
          </Text>
          <Text>
            {t(`broker_lister_questions_email_security_specialist`, {
              companyName,
            })}
          </Text>
          <MailtoLink
            email={contactEmail}
            subject={t(`broker_lister_email_security_specialist_subject`, {
              companyName,
            })}
            fontWeight="medium"
          >
            {contactEmail}
          </MailtoLink>
        </VStack>
      </CardBody>
    </Card>
  );
};

const PlacedSecuritySpecialistCard = ({
  listing,
}: {
  readonly listing: ListingPageBrokerListerActivityListingFragment;
}) => {
  const { t } = useTranslation();

  const contactEmail = listing.sellerEmail;

  if (!contactEmail) return null;

  const companyName = listing.company.name;

  return (
    <Card>
      <CardBody>
        <VStack spacing={4} alignItems="flex-start">
          <Text>
            {t(`broker_lister_listing_email_representing_security_specialist`)}
          </Text>
          <MailtoLink
            email={contactEmail}
            subject={t(`broker_lister_email_security_specialist_subject`, {
              companyName,
            })}
            fontWeight="medium"
          >
            {contactEmail}
          </MailtoLink>
        </VStack>
      </CardBody>
    </Card>
  );
};

/**
 * Broker's activity view on a listing they've submitted
 */
const BrokerListerActivity = ({
  listing,
}: {
  readonly listing: ListingPageBrokerListerActivityListingFragment;
}) => {
  const { t } = useTranslation();

  const isInReview = listing.state === ListingState.InReview;

  return (
    <VStack alignItems="flex-start" spacing={6}>
      {isInReview ? (
        <InReviewSecuritySpecialistCard listing={listing} />
      ) : (
        <>
          <PlacedSecuritySpecialistCard listing={listing} />
          <Tabs variant="underline" w="full">
            <TabList>
              <Tab>{t(`open_bids`)}</Tab>
              <Tab>{t(`past_bids`)}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <OpenBids listing={listing} />
              </TabPanel>
              <TabPanel>
                <PastBids listing={listing} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </VStack>
  );
};

export default BrokerListerActivity;
