import { Form } from "formik";
import { useEffect } from "react";
import { match } from "ts-pattern";
import * as Yup from "yup";

import { Stack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FormikQL, RadioCard, RadioCardInput } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  CurrentContextDocument,
  InvestorStatus,
  UserWithInstitutionFragment,
  useUpdateUserMutation,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

type InvestorStatusOption = "Institutional" | "IndividualTrader";

const initialValues = {
  selectedOption: null,
};

const mapVariables = ({
  selectedOption,
}: {
  readonly selectedOption: InvestorStatusOption | null;
}) => ({
  input: match(selectedOption)
    .with(`Institutional`, () => ({
      investorStatus: InvestorStatus.Institutional,
    }))
    .with(`IndividualTrader`, () => ({
      investorStatus: InvestorStatus.Individual,
    }))
    .run(),
});

const validationSchema = Yup.object().shape({
  selectedOption: Yup.mixed().oneOf(
    [`Institutional`, `IndividualTrader`],
    `Required`,
  ),
});

const SelectInput = () => (
  <RadioCardInput
    name="selectedOption"
    textAlign="left"
    options={[
      {
        value: `IndividualTrader`,
        label: `Individual`,
        text: `You will use Hiive on your own behalf to sell or buy private stock and monitor market data about your personal portfolio.`,
      },
      {
        value: `Institutional`,
        label: `Institutional`,
        text: `You will use Hiive on behalf of a firm, fund, entity or SPV sponsor, to sell or buy private stock and monitor market data about your institutional portfolio.`,
      },
    ]}
    renderOptions={(options, { getRootProps, getRadioProps }) => (
      <Stack
        {...getRootProps()}
        alignItems="stretch"
        direction={{ base: `column`, md: `row` }}
        spacing={5}
      >
        {options.map(({ value, label, text }) => {
          const radio = getRadioProps({ value });

          return (
            <RadioCard
              alignItems="start"
              display="flex"
              justifyContent="start"
              textAlign="left"
              p={5}
              w="full"
              key={value}
              {...radio}
            >
              <Text mb={2}>{label}</Text>
              <Text textStyle="text-md">{text}</Text>
            </RadioCard>
          );
        })}
      </Stack>
    )}
    cardSize="lg"
  />
);

const InvestorStatusPage = ({
  actor: { investorStatus },
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  useEffect(() => {
    if (!investorStatus) return;
    routeToNextStep();
  }, []);

  const mutation = useUpdateUserMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => routeToNextStep();

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`updateUser`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <Form>
          <VStack
            maxW={{ base: `none`, md: `40rem` }}
            mx={{ base: `auto`, md: `calc((100% - 40rem) / 2)` }}
            spacing={5}
          >
            <Text mt={7} align="center" maxW="md" color="grey.900">
              In what capacity will you be using Hiive?
            </Text>
            <SelectInput />
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[InvestorStatusPage] Next"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

export default withCurrentActor(InvestorStatusPage);
