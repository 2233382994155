import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  CardBody,
  CardFooter,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { SimpleListbox } from "@/components/form";
import { ModernTreasuryVerifyAccountCardBankAccountFragment } from "@/gql";
import { useColors } from "@/hooks";
import { toShortDate } from "@/utils/datetime";

import BankAccountCard, { LastTransactionBadge } from "./BankAccountCard";
import ModernTreasuryVeryAccountCardContainer from "./ModernTreasuryVerifyAccountCardContainer";
import PoweredByModernTreasury from "./PoweredByModernTreasury";

const BankAccountSimpleListboxElement = ({
  bankAccount,
  displayLastTransaction,
}: {
  readonly bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment;
  readonly displayLastTransaction: boolean;
}) => {
  const { t } = useTranslation();
  const [grey900, grey700, grey500] = useColors([
    `grey.900`,
    `grey.700`,
    `grey.500`,
  ]);

  return (
    <HStack justifyContent="space-between" w="full" minH={10}>
      <HStack gap={2}>
        <Text textStyle="heading-md" color={grey900}>{t`account`}</Text>
        <Text textStyle="text-md" color={grey700}>
          &#x2022;&#x2022;&#x2022;&#x2022; {bankAccount.last4}
        </Text>
      </HStack>
      <HStack>
        {displayLastTransaction && bankAccount.lastUsedTransaction ? (
          <>
            <Text
              textStyle="text-sm"
              color={grey500}
            >{t`last_used_on_transaction`}</Text>
            <LastTransactionBadge
              transaction={{
                friendlyId: bankAccount.lastUsedTransaction.bid.displayId,
                company: {
                  logoUrl:
                    bankAccount.lastUsedTransaction.company.logoUrl || ``,
                  name: bankAccount.lastUsedTransaction.company.name,
                },
              }}
            />
          </>
        ) : (
          <Text textStyle="text-sm" color={grey500}>{`${t`added`} ${toShortDate(
            bankAccount.insertedAt,
          )}`}</Text>
        )}
      </HStack>
    </HStack>
  );
};

/**
 * Shown when user already has a verified bank account used in a previous transaction.
 */
const SelectBankAccountCard = ({
  bankAccounts,
  onAddNewAccount,
  onUseAccount,
}: {
  readonly bankAccounts: ModernTreasuryVerifyAccountCardBankAccountFragment[];
  readonly onAddNewAccount: () => void;
  readonly onUseAccount: (
    bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment,
  ) => void;
}) => {
  const { t } = useTranslation();
  const [
    selectedAccount,
    setSelectedAccount,
  ] = useState<ModernTreasuryVerifyAccountCardBankAccountFragment>();
  const hasOnlyOneAccount = bankAccounts.length === 1;

  useEffect(() => {
    if (hasOnlyOneAccount) {
      setSelectedAccount(bankAccounts[0]);
    }
  }, [bankAccounts]);

  return (
    <ModernTreasuryVeryAccountCardContainer>
      <CardBody>
        <VStack gap={2} w="full">
          <Text
            textStyle="text-sm"
            color="grey.900"
          >{t`confirm_verified_account_description`}</Text>
          <Box w="full">
            {hasOnlyOneAccount ? (
              <BankAccountCard
                bankAccount={bankAccounts[0]}
                displayInsertedAt={!bankAccounts[0].lastUsedTransaction}
                displayLastTransaction={!!bankAccounts[0].lastUsedTransaction}
              />
            ) : (
              <SimpleListbox
                placeholder={t`select_account`}
                value={selectedAccount}
                items={bankAccounts}
                itemToString={(
                  bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment,
                ) => bankAccount.last4}
                getItemKey={(
                  bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment,
                ) => bankAccount.id}
                onItemSelected={(
                  bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment,
                ) => setSelectedAccount(bankAccount)}
                inputContainerStyle={{ minHeight: 64 }}
                itemsContainerStyle={{ top: `unset`, marginTop: 4 }}
                /* eslint-disable-next-line react/no-unstable-nested-components */
                itemRenderer={(
                  bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment,
                ) => (
                  <BankAccountSimpleListboxElement
                    bankAccount={bankAccount}
                    displayLastTransaction={!!bankAccount.lastUsedTransaction}
                  />
                )}
              />
            )}
            <PoweredByModernTreasury />
          </Box>
        </VStack>
      </CardBody>
      <CardFooter as={HStack} justifyContent="flex-end" py={4} bg="grey.25">
        <HiiveButton
          variant="rounded-outline-grey"
          sentryLabel="[ModernTreasuryVerifyAccountCard] addNewAccount"
          size="xl"
          onClick={onAddNewAccount}
        >{t`add_new_account`}</HiiveButton>
        <HiiveButton
          variant="rounded-solid-salmon"
          sentryLabel="[ModernTreasuryVerifyAccountCard] useAccount"
          size="xl"
          isDisabled={!selectedAccount}
          onClick={async () => onUseAccount(selectedAccount!)}
        >{t`use_account`}</HiiveButton>
      </CardFooter>
    </ModernTreasuryVeryAccountCardContainer>
  );
};

export default SelectBankAccountCard;
