import { match } from "ts-pattern";

import { Flex, Text } from "@chakra-ui/react";

import {
  BidInfoCard,
  CounterBidInfoCard,
  ListingInfoCard,
  StandingBidInfoCard,
  TransactionInfoCard,
} from "@/components/postings";
import { BidInfoBidFragment, BidState } from "@/gql";

const BidInfo = ({ bid }: { readonly bid: BidInfoBidFragment }) => (
  <Flex direction="column" gap={7}>
    {match(bid.state)
      .with(BidState.Accepted, () => <TransactionInfoCard bid={bid} />)
      .with(BidState.Countered, () => <CounterBidInfoCard bid={bid} />)
      .otherwise(() => (
        <BidInfoCard bid={bid} />
      ))}
    {bid.standingBid ? (
      <StandingBidInfoCard standingBid={bid.standingBid} />
    ) : (
      <Flex direction="column" gap={7}>
        <Text textStyle="heading-sm">
          {bid.transaction ? `Transaction for Listing` : `Bid for Listing`}
        </Text>
        <ListingInfoCard listing={bid.listing} />
      </Flex>
    )}
  </Flex>
);
export default BidInfo;
