import { MagnifyingGlass } from "@phosphor-icons/react";
import { useField } from "formik";
import { useRef, useState } from "react";

import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useOutsideClick,
  usePopper,
  chakra,
  Flex,
} from "@chakra-ui/react";

import { useKeyDown } from "@/hooks";

import styles from "./AutocompleteInput.module.css";

type Option = {
  readonly id: string;
  readonly value: string;
};

const SearchDropdownItem = ({
  children,
  onClick,
  search,
  selected,
}: {
  readonly children: string;
  readonly onClick: () => void;
  readonly search: string;
  readonly selected: boolean;
}) => {
  const getText = (children: string) => {
    if (!search) return children;

    const regEx = new RegExp(search, `ig`);
    const child = children.replaceAll(
      regEx,
      `<span class=${styles.underline}>${search}</span>`,
    );
    // eslint-disable-next-line react/no-danger
    return (
      <Text
        className={styles.capitalize}
        dangerouslySetInnerHTML={{ __html: child }}
      />
    );
  };

  return (
    <chakra.li
      tabIndex={-1}
      cursor="pointer"
      onClick={onClick}
      textStyle="deprecated-text-md"
      backgroundColor={selected ? `grey.50` : `transparent`}
      _hover={{
        bg: `grey.50`,
      }}
      px={5}
      py={2.5}
      borderBottom="light-grey-border"
    >
      {getText(children)}
    </chakra.li>
  );
};

const SearchDropdownMenu = <OptionType extends Option>({
  options,
  search,
  onClickOption,
}: {
  readonly options: readonly OptionType[];
  readonly search: string;
  readonly onClickOption: (id: string) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dropdownRef = useRef(null);

  useKeyDown({
    key: `ArrowDown`,
    handler: () => {
      if (selectedIndex === options.length - 1) {
        setSelectedIndex(0);
        return;
      }
      setSelectedIndex(selectedIndex + 1);
    },
  });

  useKeyDown({
    key: `ArrowUp`,
    handler: () => {
      if (selectedIndex === 0) {
        setSelectedIndex(options.length - 1);
        return;
      }
      setSelectedIndex(selectedIndex - 1);
    },
  });

  useKeyDown({
    key: `Enter`,
    handler: () => {
      if (options.length === 0) return;
      onClickOption(options[selectedIndex].id);
    },
  });

  const { getPopperProps } = usePopper({
    enabled: true,
    gutter: 2,
  });

  return (
    <chakra.div
      top="40px !important"
      bg="h-white"
      zIndex="sticky"
      width="100%"
      border="1px solid"
      borderColor="grey.500"
      overflow="scroll"
      borderRadius="md"
      {...getPopperProps()}
    >
      <chakra.ul
        ref={dropdownRef}
        overflow="auto"
        borderColor="grey.500"
        color="grey.900"
      >
        {options.length === 0 ? (
          <Center p={12}>
            <Text>No results found.</Text>
          </Center>
        ) : (
          <>
            {options.map((option: OptionType, index) => (
              <SearchDropdownItem
                key={option.id}
                selected={index === selectedIndex}
                search={search}
                onClick={() => onClickOption(option.id)}
              >
                {option.value}
              </SearchDropdownItem>
            ))}
          </>
        )}
      </chakra.ul>
    </chakra.div>
  );
};

const SelectedOptionsList = ({
  selectedOptions,
  onRemoveOption,
}: {
  readonly selectedOptions: readonly Option[];
  readonly onRemoveOption: (optionId: string) => void;
}) => {
  if (selectedOptions.length === 0) return null;

  return (
    <Flex direction="column" borderTop="light-grey-border" w="full" mt={7}>
      {selectedOptions.map(({ id, value }) => (
        <Flex
          key={id}
          w="full"
          px={5}
          py={2}
          borderBottom="light-grey-border"
          align="center"
          justify="space-between"
        >
          <Text textStyle="deprecated-heading-sm">{value}</Text>
          <Text
            color="h-salmon-pink"
            textStyle="deprecated-heading-sm"
            onClick={() => onRemoveOption(id)}
            cursor="pointer"
          >
            Remove
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

type AutoCompleteMultiSelectInputProps = {
  readonly name: string;
  readonly label: string;
  readonly selectedOptionIds: readonly string[];
  readonly options: readonly Option[];
  readonly maxOptions?: number;
};

const AutocompleteMultiSelectInput = ({
  name,
  label,
  selectedOptionIds,
  options,
  maxOptions,
}: AutoCompleteMultiSelectInputProps) => {
  const [search, setSearch] = useState<string>(``);

  const [
    _field,
    { touched, error },
    { setValue: setSelectedOptionIds },
  ] = useField(name);

  const ref = useRef(null);

  useOutsideClick({
    ref,
    handler: () => setSearch(``),
  });

  const sanitizeOptions = (options: readonly Option[]): readonly Option[] =>
    options
      .slice()
      .sort((a: Option, b: Option) => (a.value > b.value ? 1 : -1))
      .filter((option) =>
        option.value.toLowerCase().startsWith(search.toLowerCase()),
      )
      .filter(
        (option) =>
          !selectedOptionIds.some(
            (selectedOptionId) => selectedOptionId === option.id,
          ),
      )
      .slice(0, maxOptions || 5);

  const onAddOption = (optionId: string) => {
    setSearch(``);
    const newSelectedOptionIds = [...selectedOptionIds, optionId];

    setSelectedOptionIds(newSelectedOptionIds);
  };
  const onRemoveOption = (optionId: string) => {
    const newSelectedOptionIds = selectedOptionIds
      .slice()
      .filter((selectedOptionId) => selectedOptionId !== optionId);

    setSelectedOptionIds(newSelectedOptionIds);
  };

  const selectedOptions = options.filter((option) =>
    selectedOptionIds.some(
      (selectedOptionId) => selectedOptionId === option.id,
    ),
  );

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Box
        ref={ref}
        position="relative"
        onBlur={(e) => {
          if (
            (e.target === e.currentTarget ||
              e.currentTarget.contains(e.target)) &&
            !e.currentTarget.contains(e.relatedTarget as Node)
          ) {
            setSearch(``);
          }
        }}
      >
        <InputGroup>
          <Input
            name={name}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <InputRightElement>
            <MagnifyingGlass size={20} color="white" />
          </InputRightElement>
        </InputGroup>
        {search !== `` && (
          <SearchDropdownMenu
            search={search}
            options={sanitizeOptions(options)}
            onClickOption={onAddOption}
          />
        )}
        <SelectedOptionsList
          onRemoveOption={onRemoveOption}
          selectedOptions={selectedOptions}
        />
      </Box>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default AutocompleteMultiSelectInput;
