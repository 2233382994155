import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Spacer,
} from "@chakra-ui/react";

import { ButtonSkeleton, Skeleton } from "@/components/common";

const DiscussionMessageSkeleton = () => (
  <Flex direction="column" gap={2}>
    <Flex gap={2}>
      <Skeleton h="1rem" w="1rem" borderRadius="full" />
      <Flex direction="column" w="full" gap={2}>
        <Skeleton h="1rem" w="10rem" />
        <Skeleton h="3rem" />
      </Flex>
    </Flex>
  </Flex>
);

const DiscussionThreadCardSkeleton = () => (
  <Card w="full">
    <CardHeader py={4}>
      <Skeleton h="1.5rem" w="10rem" />
    </CardHeader>
    <CardBody h={{ base: `96`, md: `120` }} flex="none" alignItems="flex-start">
      <Flex h="full" w="full" direction="column" gap={8}>
        <Spacer />
        <DiscussionMessageSkeleton />
        <DiscussionMessageSkeleton />
      </Flex>
    </CardBody>
    <CardFooter>
      <Flex w="full" direction="column" alignItems="flex-end" gap={4}>
        <Skeleton h="8rem" />
        <Flex
          direction={{ base: `column-reverse`, md: `row` }}
          w="full"
          gap={4}
          alignItems="center"
        >
          <Flex direction="column" w="full" gap={1}>
            <Skeleton h="12px" count={6} />
          </Flex>
          <ButtonSkeleton />
        </Flex>
      </Flex>
    </CardFooter>
  </Card>
);

export default DiscussionThreadCardSkeleton;
