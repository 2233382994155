import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { useBreakpointValue } from "@chakra-ui/react";

import {
  CTACard,
  UnaccreditedSellerComplianceReminder,
} from "@/components/common";
import { UnaccreditedSellerCompanyByIdActionsFragment } from "@/gql";
import { getAvailableCompanyActions } from "@/utils";

export const ListForSaleCTACardV2 = ({
  company,
}: {
  readonly company: UnaccreditedSellerCompanyByIdActionsFragment;
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  const { canCreateListing } = getAvailableCompanyActions(company);

  const action = {
    ctaText: `${t(`sell`)}`,
    disabled: !canCreateListing,
    onClick: () => router.push(`/create-listing/${company.id}`),
  };

  const isMobileView = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    { ssr: false },
  );

  return (
    <CTACard
      // direction is not only passed directly to style the card
      direction={isMobileView ? `column` : `row`}
      heading={t(`list_for_sale`)}
      description={t(`create_listing_cta`)}
      action={action}
      footer={
        canCreateListing ? null : <UnaccreditedSellerComplianceReminder />
      }
    />
  );
};
