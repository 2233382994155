import { X } from "@phosphor-icons/react";

import { Center, Flex, Text } from "@chakra-ui/react";

export const FilterTag = ({
  id,
  name,
  category,
  selected,
  onClick,
  showCloseIcon = false,
}: {
  readonly id: string;
  readonly name: string;
  readonly category?: string;
  readonly selected: boolean;
  readonly onClick: (id: string) => void;
  readonly showCloseIcon?: boolean;
}) => (
  <Flex
    bg={selected ? `teal.900` : `skyBlue.50`}
    px={1.5}
    h={7}
    align="center"
    borderRadius="sm"
    w="fit-content"
    cursor="pointer"
    onClick={() => onClick(id)}
    transition="all 0.1s"
    _hover={{
      bg: selected ? `skyBlue.1100` : `skyBlue.200`,
    }}
  >
    <Text
      whiteSpace="nowrap"
      textStyle="heading-3xs"
      color={selected ? `white` : `skyBlue.1300`}
    >
      {category && (
        <Text as="span" textStyle="text-xs">
          {category}:{` `}
        </Text>
      )}
      {name}
    </Text>
    {showCloseIcon && (
      <Center ml={3} pb={0.5}>
        <X color="white" weight="bold" size={16} />
      </Center>
    )}
  </Flex>
);
