import { Text, VStack } from "@chakra-ui/react";

import { OnboardingWatchlistForm } from "@/components/onboarding";

const WatchlistPage = () => (
  <>
    <VStack mt={7} mb={8} spacing={6}>
      <Text align="center" textStyle="deprecated-heading-lg">
        Watchlist
      </Text>
      <Text align="center" maxW="md" textStyle="deprecated-text-xl">
        Please add all companies of interest. You will be notified of new
        listings, bids, pricing and other developments relating to securities on
        your watchlist.
      </Text>
      <Text align="center" maxW="md" textStyle="deprecated-text-xs">
        *You must select at least one company.
      </Text>
    </VStack>
    <OnboardingWatchlistForm />
  </>
);

export default WatchlistPage;
