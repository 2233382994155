import { Note } from "@/components/common";

const AskPriceComparisonChartDisclaimer = () => (
  <Note>
    The red dot is your chosen price per share. The lowest ask price is the
    lowest priced listing that was open at any time this week. The last accepted
    bid is the price of the latest listing to be accepted by a buyer. The last
    round PPS shows the unverified price of the latest funding round (where
    available). The highest bid is the highest priced bid that was open at any
    time this week
  </Note>
);

export default AskPriceComparisonChartDisclaimer;
