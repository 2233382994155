import { CTACardSkeleton } from "@/components/common";

import { CompanyActionsCTACardGrid } from "./CompanyActionsCTACardGrid";
import { useCompanyActionsCTACardDirection } from "./util";

const CompanyActionsSkeleton = () => {
  const direction = useCompanyActionsCTACardDirection(2);

  return (
    <CompanyActionsCTACardGrid numActionsAvailable={2}>
      <CTACardSkeleton direction={direction} />
      <CTACardSkeleton direction={direction} />
    </CompanyActionsCTACardGrid>
  );
};
export default CompanyActionsSkeleton;
