import { Box, GridItem, SimpleGrid } from "@chakra-ui/react";

import {
  FullContentWrapper,
  MobileTab,
  MobileTabList,
  MobileTabPanel,
  MobileTabPanels,
  MobileTabs,
} from "@/components/common";
import { useIsDesktop } from "@/hooks";

import { BidInfoSkeleton } from "./BidInfoSkeleton";
import { BidActivitySkeleton } from "./activity/BidActivitySkeleton";

const BidPageSkeleton = () => {
  const isDesktopView = useIsDesktop();

  return (
    <FullContentWrapper px={{ base: 4, lg: 8 }}>
      <Box w="full" maxW="max-width-md">
        {isDesktopView ? (
          <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
            <GridItem>
              <BidInfoSkeleton />
            </GridItem>
            <GridItem>
              <BidActivitySkeleton />
            </GridItem>
          </SimpleGrid>
        ) : (
          <MobileTabs>
            <MobileTabList>
              <MobileTab>Overview</MobileTab>
              <MobileTab>Activity</MobileTab>
            </MobileTabList>

            <MobileTabPanels>
              <MobileTabPanel>
                <BidInfoSkeleton />
              </MobileTabPanel>
              <MobileTabPanel>
                <BidActivitySkeleton />
              </MobileTabPanel>
            </MobileTabPanels>
          </MobileTabs>
        )}
      </Box>
    </FullContentWrapper>
  );
};

export default BidPageSkeleton;
