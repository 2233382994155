import { Box, GridItem, SimpleGrid } from "@chakra-ui/react";

import { FullContentWrapper } from "@/components/common";
import { useIsDesktop } from "@/hooks";

import { ListingInfoCardSkeleton } from "./ListingInfoCardSkeleton";

export const ListingPageSkeleton = () => {
  const isDesktopView = useIsDesktop();

  return (
    <FullContentWrapper px={{ base: 4, lg: 8 }}>
      <Box w="full" maxW="max-width-md">
        {isDesktopView ? (
          <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
            <GridItem>
              <ListingInfoCardSkeleton />
            </GridItem>
          </SimpleGrid>
        ) : (
          <ListingInfoCardSkeleton />
        )}
      </Box>
    </FullContentWrapper>
  );
};
