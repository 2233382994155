import { useCurrentContextQuery, UserPermissionV2 } from "@/gql";

interface EntityWithMyPermissions<T> {
  readonly myPermissionsV2: readonly T[];
}

/**
 * Returns true if the current actor has the given permission.
 */
export const useIHavePermission = (permission: UserPermissionV2) => {
  const { data, loading } = useCurrentContextQuery();

  if (loading) return false;

  return (
    data?.currentContext?.currentActor?.myPermissionsV2.includes(permission) ||
    false
  );
};

/**
 * Returns true if the current actor has permission to act on the provided entity.
 */
export const iHaveEntityPermission = <T>(
  entity: EntityWithMyPermissions<T> | null | undefined,
  permission: T,
) => entity?.myPermissionsV2?.includes(permission) || false;

export const canAccessPlatform = (
  user: EntityWithMyPermissions<UserPermissionV2>,
) => iHaveEntityPermission(user, UserPermissionV2.AccessPlatform);
