import { GlyphCross, GlyphDot, GlyphTriangle } from "@visx/glyph";
import { GlyphTriangleProps } from "@visx/glyph/lib/glyphs/GlyphTriangle";
import { DataContext, GlyphProps } from "@visx/xychart";
import dayjs from "dayjs";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { VStack, Text, HStack, Flex } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import {
  chartSeriesSetting,
  ChartDailyPriceData,
  getSeriesColor,
  ChartSeriesKey,
} from "@/components/companies";
import { formatPricePerShare } from "@/utils";

import { GlyphTypes } from "./types";

const GlyphInvertedTriangle = (
  props: GlyphTriangleProps<ChartDailyPriceData>,
) => <GlyphTriangle {...props} transform={`rotate(180, ${0}, ${0})`} />;

const glyphType = {
  [GlyphTypes.Dot]: GlyphDot,
  [GlyphTypes.Triangle]: GlyphTriangle,
  [GlyphTypes.InvertedTriangle]: GlyphInvertedTriangle,
  [GlyphTypes.Cross]: GlyphCross,
};

const selectGlyphComponent = (type: GlyphTypes) => glyphType[type];

export const GlyphRenderer = ({
  dataKey,
  data,
  glyphType,
}: {
  readonly dataKey: ChartSeriesKey;
  readonly data: GlyphProps<ChartDailyPriceData>;
  readonly glyphType: GlyphTypes;
}) => {
  const { yScale } = useContext(DataContext);
  const { datum, x } = data;
  const color = getSeriesColor(dataKey);
  const GlyphComponent = selectGlyphComponent(glyphType);

  const values = (Array.isArray(datum[dataKey])
    ? datum[dataKey]
    : [datum[dataKey]]) as readonly number[];

  return (
    <>
      {values.map((value: number, index: number) => {
        const yValue = yScale ? yScale(value) : 0;
        const y = typeof yValue === `number` ? yValue : 0;
        const key = `${value}-${dataKey}-${datum.day}-${index}`;

        return glyphType === GlyphTypes.Dot ? (
          <GlyphComponent
            key={key}
            cy={y}
            cx={x}
            r={3.8}
            fill="white"
            stroke={color}
            strokeWidth={2}
          />
        ) : (
          <GlyphComponent
            key={key}
            top={y}
            left={x}
            size={46}
            fill={color}
            stroke="white"
            strokeWidth={1}
          />
        );
      })}
    </>
  );
};

const getDisplayValue = (valueArray: readonly number[]) => {
  if (valueArray.length > 2) {
    const sorted = [...valueArray].sort((a, b) => a - b);
    const first = formatPricePerShare(sorted[0], false);
    const last = formatPricePerShare(sorted[sorted.length - 1], false);
    if (first === last) {
      return [`${first}`];
    }
    return [`${first} - ${last}`];
  }
  return valueArray.map((val) => val && formatPricePerShare(val, false));
};

export const TooltipContent = ({
  tooltipData,
}: {
  readonly tooltipData: ChartDailyPriceData;
}) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="start" spacing={0.5}>
      {Object.keys(tooltipData).map((key) => {
        if (key === `day`) return null;

        const seriesKey = key as ChartSeriesKey;
        const value = tooltipData[seriesKey];

        const values = Array.isArray(value) ? value : [value];

        if (!values[0]) return null;

        const filteredValues = values.filter(
          (val): val is number => val !== null && val !== undefined,
        );

        const displayValues = getDisplayValue(filteredValues);
        // this is a little trick for i18n to not show 0s
        const count = values.length > 2 ? values.length : 1;

        return displayValues.map((val, index) => (
          <HStack
            // eslint-disable-next-line react/no-array-index-key
            key={`${val}-${key}-${tooltipData.day}-${index}`}
            width="full"
            justifyContent="space-between"
            spacing={4}
          >
            <HStack>
              {chartSeriesSetting[seriesKey].indicator}
              <Text color="grey.900" textStyle="text-sm">
                {t(chartSeriesSetting[seriesKey].translation, {
                  count,
                  defaultValue: ``,
                })}
              </Text>
            </HStack>
            <Text color="grey.900" textStyle="heading-xs">
              {val}
            </Text>
          </HStack>
        ));
      })}
      <Text color="grey.800" textStyle="text-xs">
        {dayjs(tooltipData.day).format(`Do MMMM, YYYY`)}
      </Text>
    </VStack>
  );
};

export const TooltipGlyphs = ({
  tooltipData,
  x,
}: {
  readonly tooltipData: ChartDailyPriceData;
  readonly x: number;
}) => {
  const { xScale, yScale } = useContext(DataContext);

  if (!xScale || !yScale) return null;

  return (
    <>
      {Object.keys(tooltipData).map((key) => {
        if (key === `day`) return null;

        const seriesKey = key as ChartSeriesKey;
        const value = tooltipData[seriesKey];
        const color = getSeriesColor(seriesKey);
        const values = Array.isArray(value) ? value : [value];

        if (!values[0]) return null;

        return values.map((v: number, i: number) => {
          const yValue = yScale ? yScale(v) : 0;
          const y = typeof yValue === `number` ? yValue : 0;
          const key = `${seriesKey}-${tooltipData.day}-${i}-tooltip-glyph`;

          return <GlyphDot key={key} cy={y} cx={x} r={3} fill={color} />;
        });
      })}
    </>
  );
};

export const SkeletonChart = () => (
  <Flex h="full" direction="column" gap={6}>
    <Skeleton h="100%" />
  </Flex>
);
