type QuestionOption = { readonly label: string; readonly value: string };

type InvestmentGoalQuestion = {
  readonly text: string;
  readonly key: string;
  readonly options: readonly QuestionOption[];
};

export const institutionalInvestmentGoalQuestions: readonly InvestmentGoalQuestion[] = [
  {
    text: `Can the institution tolerate significant risk in its investments?`,
    key: `institution-risk-tolerance`,
    options: [
      {
        label: `Yes`,
        value: `yes`,
      },
      {
        label: `No`,
        value: `no`,
      },
    ],
  },
  {
    text: `Can the institution tolerate near-term illiquidity in the investments that it may purchase using Hiive?`,
    key: `institution-liquidity-needs`,
    options: [
      {
        label: `Yes`,
        value: `yes`,
      },
      {
        label: `No`,
        value: `no`,
      },
    ],
  },
  {
    text: `Does the institution have at least 5 years of investment experience with stocks and/or bonds?`,
    key: `institution-overall-investment-experience`,
    options: [
      {
        label: `Yes`,
        value: `yes`,
      },
      {
        label: `No`,
        value: `no`,
      },
    ],
  },
  {
    text: `Has the institution previously purchased or sold private unregistered securities?`,
    key: `institution-previously-purchased`,
    options: [
      {
        label: `Yes`,
        value: `yes`,
      },
      {
        label: `No`,
        value: `no`,
      },
    ],
  },
  {
    text: `Does the institution’s funds available for investment, net of all liabilities, exceed $1 million?`,
    key: `institution-funds-available`,
    options: [
      {
        label: `Yes`,
        value: `yes`,
      },
      {
        label: `No`,
        value: `no`,
      },
    ],
  },
];
