import { defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
  baseStyle: {
    _disabled: {
      pointerEvents: `none`,
    },
  },
  sizes: {
    xs: {
      fontWeight: `medium`,
      fontSize: `0.938rem`,
      h: `1.875rem`,
      minW: `130px`,
      w: `full`,
      maxW: `140px`,
    },
    sm: {
      fontWeight: `medium`,
      fontSize: `md`,
      h: 9,
    },
    md: {
      fontWeight: `medium`,
      fontSize: `md`,
      minW: `140px`,
      px: 6,
      maxW: 80,
      h: 9,
    },
    lg: {
      fontSize: `.938rem`,
      fontWeight: `medium`,
      w: `full`,
      maxW: { base: `full`, md: 52 },
      h: 9,
    },
    xl: {
      fontWeight: `medium`,
      maxW: `17.875rem`,
      minW: `10rem`,
      h: 12,
      px: 6,
    },
  },
  variants: {
    "rounded-solid-salmon": {
      color: `white`,
      bg: `h-salmon-pink`,
      borderRadius: `50px`,
      _loading: {
        pointerEvents: `none`,
        opacity: `.7`,
        _hover: {
          pointerEvents: `none`,
          opacity: `.7`,
        },
      },
      _hover: {
        bg: `red.600`,
      },
      _focus: {
        boxShadow: `0px 0px 5px 0.5px var(--chakra-colors-skyBlue-900)`,
      },
    },
    "rounded-outline-salmon": {
      bg: `white`,
      color: `h-salmon-pink`,
      border: `1px solid #F15F61`,
      borderRadius: `50px`,
      _loading: {
        pointerEvents: `none`,
        opacity: `.7`,
        _hover: {
          pointerEvents: `none`,
          opacity: `.7`,
        },
      },
      _hover: {
        bg: `salmon.50`,
      },
      _focus: {
        boxShadow: `focus`,
      },
    },
    "rounded-solid-grey": {
      color: `white`,
      bg: `grey.900`,
      borderRadius: `50px`,
      _loading: {
        pointerEvents: `none`,
        opacity: `.7`,
        _hover: {
          pointerEvents: `none`,
          opacity: `.7`,
        },
      },
      _hover: {
        bg: `grey.800`,
      },
      _focus: {
        boxShadow: `0px 0px 5px 0.5px var(--chakra-colors-skyBlue-900)`,
      },
    },
    "rounded-outline-grey": {
      bg: `white`,
      color: `grey.900`,
      borderWidth: `1px`,
      borderColor: `grey.600`,
      borderRadius: `50px`,
      _hover: {
        bg: `grey.50`,
      },
      _focus: {
        boxShadow: `focus`,
      },
    },
    "rounded-outline-red": {
      bg: `white`,
      color: `red.700`,
      borderWidth: `1px`,
      borderColor: `grey.600`,
      borderRadius: `50px`,
      _hover: {
        bg: `grey.50`,
      },
      _focus: {
        boxShadow: `focus`,
      },
    },
    "text-salmon": {
      px: 2,
      py: 1,
      h: `auto`,
      minW: 0,
      color: `salmon.900`,
      bg: `transparent`,
      _hover: {
        textDecoration: `underline`,
      },
      _active: {
        color: `red.600`,
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    "text-grey": {
      px: 2,
      py: 1,
      h: `auto`,
      minW: 0,
      w: `fit-content`,
      color: `grey.900`,
      bg: `transparent`,
      _hover: {
        textDecoration: `underline`,
        color: `grey.800`,
      },
      _focus: {
        boxShadow: `focus`,
      },
      _active: {
        color: `grey.700`,
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    "text-ghost": {
      px: 0,
      py: 0,
      h: `auto`,
      minW: 0,
      display: `inline`,
      color: `grey.900`,
      bg: `transparent`,
      _hover: {
        textDecoration: `underline`,
      },
      _active: {
        color: `grey.600`,
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    "boxed-salmon": {
      minW: 10,
      minH: 10,
      fontSize: `xs`,
      bg: `white`,
      color: `salmon.900`,
      p: 4,
      borderWidth: 1,
      borderColor: `grey.100`,
      borderRadius: `md`,
      transition: `all 0.2s`,
      _hover: {
        bg: `grey.50`,
      },
    },
    "boxed-grey": {
      minW: 10,
      minH: 10,
      fontSize: `xs`,
      bg: `white`,
      color: `grey.800`,
      borderWidth: 1,
      borderColor: `grey.100`,
      borderRadius: `md`,
      p: 4,
      transition: `all 0.2s`,
      _hover: {
        bg: `grey.50`,
      },
    },
    "boxed-grey-mini": {
      minW: 8,
      minH: 8,
      h: 8,
      fontSize: `xs`,
      bg: `white`,
      color: `grey.800`,
      borderWidth: 1,
      borderColor: `grey.100`,
      borderRadius: `md`,
      p: 2,
      transition: `all 0.2s`,
      _hover: {
        bg: `grey.50`,
      },
    },
    outline: {
      borderColor: `grey.100`,
      _hover: {
        bg: `grey.100`,
        color: `grey.900`,
      },
      _active: {
        bg: `grey.50`,
      },
    },
    "icon-close": {
      minW: 10,
      minH: 10,
      h: `unset`,
      bg: `transparent`,
      _hover: {
        bg: `transparent`,
        color: `grey.50`,
      },
      _active: {
        bg: `transparent`,
        color: `grey.200`,
      },
    },
    "icon-card-close": {
      minW: 10,
      minH: 10,
      h: `unset`,
      bg: `transparent`,
      _hover: {
        bg: `transparent`,
        color: `grey.200`,
      },
      _active: {
        bg: `transparent`,
        color: `grey.900`,
      },
    },
    icon: {
      bg: `unset`,
      minW: `unset`,
      _hover: {
        bg: `unset`,
      },
      _focus: {
        boxShadow: `focus`,
      },
    },
  },
});
