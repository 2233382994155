import { useTranslation } from "react-i18next";

import { Card, CardHeader, Text, useDisclosure } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { getIsInstitutionUser } from "@/utils";

import SuitabilityCardContent from "./SuitabilityCardContent";
import SuitabilityForm from "./SuitabilityForm";

const SuitabilityCardBody = ({
  hasSuitabilityAnswers = false,
  isOpen,
  onClose,
  onOpen,
}: {
  hasSuitabilityAnswers?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) => {
  if (!hasSuitabilityAnswers) {
    return <SuitabilityForm onClose={onClose} />;
  }

  return isOpen ? (
    <SuitabilityForm onClose={onClose} />
  ) : (
    <SuitabilityCardContent onOpen={onOpen} />
  );
};

const SuitabilityCard = withCurrentActor(({ actor }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: `suitability-card-modify`,
  });

  const isInstitutionUser = getIsInstitutionUser(actor);

  const suitabilityAnswers = isInstitutionUser
    ? actor.institution?.suitabilityAnswers
    : actor.suitabilityAnswers;

  const hasSuitabilityAnswers =
    suitabilityAnswers && suitabilityAnswers.length > 0;

  return (
    <Card variant="darkened-footer" w="full" flex="1">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`suitability`)}</Text>
      </CardHeader>
      <SuitabilityCardBody
        hasSuitabilityAnswers={hasSuitabilityAnswers}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </Card>
  );
});

export default SuitabilityCard;
