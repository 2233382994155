import { ReactNode } from "react";

import { Placement, Tooltip, TooltipProps } from "@chakra-ui/react";

interface CustomTooltipProps extends TooltipProps {
  readonly tooltipContent: ReactNode;
  readonly tooltipSize?: string;
  readonly children: ReactNode;
  readonly placement?: Placement;
}

const CustomTooltip = ({
  tooltipContent,
  tooltipSize,
  children,
  placement = `auto`,
}: CustomTooltipProps) => (
  <Tooltip
    placement={placement}
    label={tooltipContent}
    size={tooltipSize}
    bg="grey.900"
    color="h-white"
    py={3}
    px={4}
    maxW={80}
    borderRadius="md"
    gutter={16}
    style={{ maxWidth: `700px` }}
    shouldWrapChildren
    hasArrow
  >
    {children}
  </Tooltip>
);

export default CustomTooltip;
