import { Box, Link, Text } from "@chakra-ui/react";

const IndividualTraderCustomerAgreement = () => (
  <Box
    borderWidth="0.5px"
    borderColor="grey.200"
    borderRadius="md"
    p={4}
    bg="white"
    overflowX="auto"
  >
    <Text>
      I have read the{` `}
      <Link fontWeight="medium" href="/terms-and-conditions" target="_blank">
        Hiive Customer Terms and Conditions
      </Link>
      {` `}(the &quot;Terms&quot;), the{` `}
      <Link
        fontWeight="medium"
        href="https://www.hiive.com/privacy"
        target="_blank"
      >
        Privacy Policy
      </Link>
      , and these{` `}
      <Link
        fontWeight="medium"
        href="https://www.hiive.com/disclosures"
        target="_blank"
      >
        Disclaimers & Disclosures
      </Link>
      , and understand that these documents, along with the terms below, govern
      my use of Hiive&apos;s services and this platform and set out terms and
      conditions of Hiive’s provision of its services and this platform to me.
    </Text>
    <Text mt={2}>
      I understand that I can access Hiive&apos;s platform, browse bids and
      listings and pricing information, and post a listing, bid or standing bid,
      without paying anything to Hiive. I understand that if I{` `}
      <Text as="span" textDecoration="underline">
        sell
      </Text>
      {` `}
      stock to a buyer that I met through the platform or was otherwise
      introduced to me through Hiive or through the referral by another buyer on
      the platform, or to a different buyer substituted for that buyer by
      operation of a &quot;right of first refusal&quot; or similar pre-emptive
      right of an issuer or another shareholder, I will be responsible to pay a
      transaction fee to Hiive, as detailed in section 18 of the Terms.
    </Text>
    <Text mt={2}>
      I understand that my activities on the Hiive platform, and my interactions
      with potential counterparties introduced by Hiive or through the Hiive
      platform, are to be kept confidential, and that I am not permitted to
      share the name of any potential counterparty with anyone else, including,
      for example, colleagues and friends who may hold the same kind of stock as
      I do, as detailed in sections 19 and 31 of the Terms.
    </Text>
    <Text mt={2}>
      I understand that Hiive is not an investment advisor and does not and will
      not provide recommendations or advice to me. I understand that buying and
      selling stock through the Hiive platform is a self-directed activity, and
      that it is entirely up to me to determine what stocks to buy or sell, and
      at what volume and price. I understand that I am not obligated to sell or
      buy stock, until I sign a share transfer notice or other execution
      document with a counterparty.
    </Text>
    <Text mt={2}>
      I understand that most private stock transactions are subject to the
      approval of the issuer (i.e. the company that issued the stock) and that
      some issuers will decline to approve proposed transactions, or some
      proposed transactions, and in some cases will do so without providing any
      reason for doing so.
    </Text>
  </Box>
);

export default IndividualTraderCustomerAgreement;
