import { Box, Text, VStack } from "@chakra-ui/react";

const RepresentedListingAdditionalListingNotesTooltip = () => (
  <VStack alignItems="start">
    <Box>
      Include any additional details for potential buyers, such as:
      <ul style={{ paddingLeft: `1.2em` }}>
        <li>Information on the company (e.g. financial statements)</li>
        <li>Details on the structure of the sale (e.g. terms of SPV)</li>
        <li>If you hold vested options and need help funding the exercise</li>
        <li>
          If there are any timing limitations on closing (e.g. must be before or
          after a specific date)
        </li>
        <li>If you are flexible on the price</li>
      </ul>
    </Box>
    <Text>
      It is important not to share your contact information or identity in this
      section.
    </Text>
  </VStack>
);

export default RepresentedListingAdditionalListingNotesTooltip;
