import { useField } from "formik";
import { ReactNode } from "react";

import {
  FormControl,
  FormErrorMessage,
  RadioGroup,
  RadioGroupProps,
} from "@chakra-ui/react";

import { TooltipFormLabel } from ".";

function FormRadioInput<T = string>({
  name,
  label,
  children,
  mapper,
  tooltipContent,
  ...restProps
}: {
  readonly name: string;
  readonly label?: string | null;
  readonly children: ReactNode;
  readonly mapper?: {
    readonly to: (value?: string | null) => T;
    readonly from: (value: T) => string | null | undefined;
  };
  readonly tooltipContent?: ReactNode | string | null;
} & RadioGroupProps) {
  const to = mapper?.to;
  const from = mapper?.from;

  const [field, { touched, error }, { setValue }] = useField(name);

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <TooltipFormLabel tooltipContent={tooltipContent} htmlFor={name}>
        {label}
      </TooltipFormLabel>
      <RadioGroup
        {...field}
        {...restProps}
        onChange={(value) => (to ? setValue(to(value)) : setValue(value))}
        value={from ? from(field.value) : field.value}
      >
        {children}
      </RadioGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}

export default FormRadioInput;
