import { ReactNode } from "react";

import { ListItem, ListItemProps } from "@chakra-ui/react";

import { UseListboxGetItemProps } from "./types";

interface ListboxItemProps<TItem> extends ListItemProps {
  readonly highlightedIndex: number;
  readonly index: number;
  readonly getItemProps: UseListboxGetItemProps<TItem>;
  readonly item: TItem;
  readonly children: ReactNode;
}

export const ListboxItem = <TItem,>({
  children,
  getItemProps,
  item,
  index,
  highlightedIndex,
  ...otherProps
}: ListboxItemProps<TItem>) => (
  <ListItem
    bg={highlightedIndex === index && `grey.50`}
    {...getItemProps({ item, index })}
    {...otherProps}
  >
    {children}
  </ListItem>
);
