import { ReactNode } from "react";
import { match } from "ts-pattern";

import { useRouter } from "next/router";

import { GridItem, SimpleGrid, useMediaQuery } from "@chakra-ui/react";

import { ContentWrapper } from "@/components/dashboard";
import { withCurrentActor } from "@/components/hoc";
import { UnaccreditedSellerInlineNotificationsProvider } from "@/components/unaccredited-seller";
import { UserWithInstitutionFragment } from "@/gql";
import { getIsUnaccreditedSeller } from "@/utils";

import { ActiveBidsPage } from "./ActiveBidsPage";
import { HeroBanner } from "./HeroBanner";
import { InquiriesPage } from "./InquiriesPage";
import { OverviewPage } from "./OverviewPage";
import { PastBidsPage } from "./PastBidsPage";
import { StandingBidsPage } from "./StandingBidsPage";
import { TransactionsPage } from "./TransactionsPage";
import { Sidebar, UnaccreditedDashboardRoutes } from "./sidebar";

interface PostActivityUnaccreditedSellerDashboardPageContainerProps {
  readonly children: ReactNode;
}

const PostActivityUnaccreditedSellerDashboardPageContainer = ({
  children,
}: PostActivityUnaccreditedSellerDashboardPageContainerProps) => {
  const { asPath } = useRouter();
  const [isDesktopView] = useMediaQuery(`(min-width: 992px)`, { ssr: false });
  const showHeroBanner =
    isDesktopView || asPath === UnaccreditedDashboardRoutes.ActiveBids;

  return (
    <>
      {showHeroBanner && <HeroBanner />}

      <ContentWrapper transform={{ base: `none`, lg: `translateY(-3rem)` }}>
        <SimpleGrid columns={12} gap={4}>
          {isDesktopView && (
            <GridItem colSpan={4}>
              <Sidebar />
            </GridItem>
          )}

          <GridItem
            colSpan={{ base: 12, lg: 8 }}
            pb={14}
            transform={{ base: `none`, lg: `translateY(3rem)` }}
          >
            {children}
          </GridItem>
        </SimpleGrid>
      </ContentWrapper>
    </>
  );
};

interface PostActivityUnaccreditedSellerDashboardPageProps {
  readonly actor: UserWithInstitutionFragment;
}

const PostActivityUnaccreditedSellerDashboardPage = ({
  actor,
}: PostActivityUnaccreditedSellerDashboardPageProps) => {
  const router = useRouter();

  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  if (!isUnaccreditedSeller) {
    router.replace(`/page-not-found`);
    return null;
  }

  const page = match({ asPath: router.asPath })
    .with({ asPath: UnaccreditedDashboardRoutes.ActiveBids }, () => (
      <ActiveBidsPage />
    ))
    .with({ asPath: UnaccreditedDashboardRoutes.Inquiries }, () => (
      <InquiriesPage />
    ))
    .with({ asPath: UnaccreditedDashboardRoutes.StandingBids }, () => (
      <StandingBidsPage />
    ))
    .with({ asPath: UnaccreditedDashboardRoutes.Transactions }, () => (
      <TransactionsPage />
    ))
    .with({ asPath: UnaccreditedDashboardRoutes.PastBids }, () => (
      <PastBidsPage />
    ))
    .with({ asPath: UnaccreditedDashboardRoutes.Overview }, () => (
      <OverviewPage />
    ))
    .otherwise(() => <ActiveBidsPage />);

  return (
    <UnaccreditedSellerInlineNotificationsProvider>
      <PostActivityUnaccreditedSellerDashboardPageContainer>
        {page}
      </PostActivityUnaccreditedSellerDashboardPageContainer>
    </UnaccreditedSellerInlineNotificationsProvider>
  );
};

export default withCurrentActor(PostActivityUnaccreditedSellerDashboardPage);
