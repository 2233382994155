import { forwardRef } from "react";

import { Card, CardFooter, CardHeader, VStack, Text } from "@chakra-ui/react";

import {
  TransferTypeChoiceInput,
  ShareSeriesInput,
} from "@/components/listings";

export const TransferTypeCard = forwardRef<HTMLDivElement>((_, ref) => (
  <Card w="full" ref={ref}>
    <CardHeader>
      <Text textStyle="heading-sm">2. Transfer Type</Text>
    </CardHeader>
    <CardFooter>
      <VStack spacing={10}>
        <TransferTypeChoiceInput />
        <ShareSeriesInput />
      </VStack>
    </CardFooter>
  </Card>
));
