import pluralize from "pluralize";

import { Badge, Circle, Text } from "@chakra-ui/react";

const InquiriesBadge = ({
  numInquiries,
  numUnreadMessages,
}: {
  readonly numInquiries: number;
  readonly numUnreadMessages: number;
}) => {
  const hasUnreadMessages = numUnreadMessages >= 1;

  return (
    <Badge
      bg="grey.50"
      color="grey.900"
      borderRadius="md"
      p={2}
      textTransform="none"
      pos="relative"
    >
      {`${numInquiries} ${pluralize(`Inquiry`, numInquiries)}`}
      {hasUnreadMessages && (
        <Circle
          bg="salmon.900"
          pos="absolute"
          size={3.5}
          top={-1.5}
          right={-1.5}
        >
          <Text textStyle="text-xs" color="white">
            {numUnreadMessages}
          </Text>
        </Circle>
      )}
    </Badge>
  );
};

export default InquiriesBadge;
