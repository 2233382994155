import { Form } from "formik";
import * as Yup from "yup";

import { EMAIL_REGEX } from "@/components/auth";
import { FormikQL, FormFooter, EmailInput } from "@/components/form";
import {
  RequestResetPasswordMutation,
  useRequestResetPasswordMutation,
} from "@/gql";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .required(`Email is required`)
    .matches(EMAIL_REGEX, `Invalid email address`),
});

interface ForgotPasswordFormValues {
  readonly email: string;
}

const initialValues = {
  email: ``,
};

const mapVariables = (values: ForgotPasswordFormValues) => ({
  email: values.email,
});

const ForgotPasswordForm = ({
  onSuccess,
}: {
  readonly onSuccess: (data: RequestResetPasswordMutation) => void;
}) => (
  <FormikQL
    mutation={useRequestResetPasswordMutation()}
    mutationNames={[`requestResetPassword`]}
    initialValues={initialValues}
    validationSchema={validationSchema}
    mapVariables={mapVariables}
    onSuccess={onSuccess}
  >
    {({ isSubmitting }) => (
      <Form autoComplete="off">
        <EmailInput
          name="email"
          label="Email"
          placeholder="Email address"
          bg="h-white"
        />
        <FormFooter
          isSubmitting={isSubmitting}
          submitText="Submit"
          formName="ForgotPasswordForm"
        />
      </Form>
    )}
  </FormikQL>
);

export default ForgotPasswordForm;
