import { ReactNode } from "react";

import { FormLabel, FormLabelProps } from "@chakra-ui/react";

import { UseComboboxGetLabelProps } from "./types";

interface ComboboxLabelProps extends FormLabelProps {
  readonly children: ReactNode;
  readonly getLabelProps: UseComboboxGetLabelProps;
}

export const ComboboxLabel = ({
  children,
  getLabelProps,
  ...formLabelProps
}: ComboboxLabelProps) => (
  <FormLabel {...formLabelProps} {...getLabelProps()}>
    {children}
  </FormLabel>
);
