import {
  useKnockClient,
  useNotifications,
  useNotificationStore,
} from "@knocklabs/react";
import { useEffect } from "react";

export function useSubscribeToNotifications() {
  const knockClient = useKnockClient();
  const feedClient = useNotifications(
    knockClient,
    process.env.NEXT_PUBLIC_KNOCK_FEED_ID || ``,
  );

  useEffect(() => {
    feedClient.fetch();
  }, [feedClient]);

  const { items, metadata } = useNotificationStore(feedClient);

  return {
    notifications: items,
    unreadNotifications: items.filter((item) => !item.seen_at),
    metadata,
  };
}
