import * as React from "react";
import { SVGProps } from "react";

const UnaccreditedSellerListForSaleSvgLarge = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 491 252"
    fill="none"
    {...props}
  >
    <mask
      id="u16r_bg_wide_svg__a"
      width={491}
      height={252}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: `alpha`,
      }}
    >
      <path fill="#D9D9D9" d="M0 0h491v252H0z" />
    </mask>
    <g mask="url(#u16r_bg_wide_svg__a)">
      <rect
        width={488.679}
        height={532.553}
        x={4}
        y={160.055}
        fill="#F9F9F9"
        opacity={0.7}
        rx={110}
        transform="rotate(-38.478 4 160.055)"
      />
      <mask
        id="u16r_bg_wide_svg__b"
        width={349}
        height={248}
        x={141}
        y={29}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: `alpha`,
        }}
      >
        <path fill="#D9D9D9" d="M141 29.992h349v247H141z" />
      </mask>
      <g mask="url(#u16r_bg_wide_svg__b)">
        <g filter="url(#u16r_bg_wide_svg__c)">
          <rect
            width={289.272}
            height={183.656}
            x={232.099}
            y={39.27}
            fill="#F9F9F9"
            stroke="silver"
            strokeWidth={0.15}
            rx={8.314}
          />
          <path
            fill="#fff"
            stroke="silver"
            strokeWidth={0.15}
            d="M240.389 39.067h272.646a8.314 8.314 0 0 1 8.314 8.315v47.003H232.075V47.382a8.314 8.314 0 0 1 8.314-8.315Z"
          />
          <g filter="url(#u16r_bg_wide_svg__d)">
            <path
              fill="#fff"
              d="M363.088 141.226a4.047 4.047 0 0 1-4.048 4.047H255.605a4.047 4.047 0 0 1-4.048-4.047V72.109a4.857 4.857 0 0 1 4.857-4.858h101.817a4.857 4.857 0 0 1 4.857 4.858v69.117Z"
            />
            <path
              stroke="#D5D5D5"
              strokeWidth={0.3}
              d="M359.04 145.124H255.605a3.898 3.898 0 0 1-3.898-3.898V72.109c0-2.6 2.107-4.708 4.707-4.708h101.817a4.708 4.708 0 0 1 4.707 4.708v69.117a3.898 3.898 0 0 1-3.898 3.898Z"
            />
          </g>
          <g filter="url(#u16r_bg_wide_svg__e)">
            <path
              fill="#fff"
              d="M363.088 179.288a4.048 4.048 0 0 1-4.048 4.048H255.605a4.047 4.047 0 0 1-4.047-4.048v-22.676a4.857 4.857 0 0 1 4.857-4.858h101.816a4.858 4.858 0 0 1 4.857 4.858v22.676Z"
            />
            <path
              stroke="#D5D5D5"
              strokeWidth={0.3}
              d="M359.04 183.186H255.605a3.898 3.898 0 0 1-3.898-3.898v-22.676c0-2.6 2.108-4.708 4.708-4.708h101.816a4.708 4.708 0 0 1 4.707 4.708v22.676a3.898 3.898 0 0 1-3.898 3.898Z"
            />
          </g>
          <rect
            width={91.084}
            height={6.502}
            x={259.932}
            y={91.359}
            fill="#F6F6F6"
            rx={3.251}
          />
          <rect
            width={91.084}
            height={6.502}
            x={259.932}
            y={78.359}
            fill="#F6F6F6"
            rx={3.251}
          />
          <rect
            width={91.084}
            height={6.502}
            x={259.932}
            y={104.398}
            fill="#F6F6F6"
            rx={3.251}
          />
          <rect
            width={91.084}
            height={6.502}
            x={259.932}
            y={117.375}
            fill="#F6F6F6"
            rx={3.251}
          />
        </g>
        <path
          fill="#F2F2EC"
          stroke="#E4E4D9"
          strokeWidth={0.3}
          d="M498.642 107.975H370.681V72.112c0-2.6 2.108-4.707 4.708-4.707h118.545c2.6 0 4.708 2.107 4.708 4.707v35.863Z"
        />
        <path
          fill="#fff"
          stroke="#D5D5D5"
          strokeWidth={0.3}
          d="M370.677 106.415h127.962v9.857c0 2.6-2.108 4.708-4.708 4.708H375.384a4.707 4.707 0 0 1-4.707-4.708v-9.857Z"
        />
        <g filter="url(#u16r_bg_wide_svg__f)">
          <path
            fill="#fff"
            d="M491.356 95.671a4.048 4.048 0 0 1-4.048 4.048H382.944a4.048 4.048 0 0 1-4.048-4.048V80.426a4.858 4.858 0 0 1 4.858-4.857h102.745a4.857 4.857 0 0 1 4.857 4.858V95.67Z"
          />
          <path
            stroke="#E4E4D9"
            strokeWidth={0.3}
            d="M487.308 99.569H382.944a3.898 3.898 0 0 1-3.898-3.898V80.426c0-2.6 2.108-4.707 4.708-4.707h102.745c2.6 0 4.707 2.108 4.707 4.708V95.67a3.898 3.898 0 0 1-3.898 3.898Z"
          />
        </g>
        <g filter="url(#u16r_bg_wide_svg__g)">
          <rect
            width={127.962}
            height={10.848}
            x={370.69}
            y={150.15}
            fill="#F5ECF4"
            stroke="#EBD8EA"
            strokeWidth={0.3}
            rx={5.424}
          />
          <rect
            width={128.261}
            height={11.148}
            x={370.54}
            y={132.352}
            fill="#D5D5D5"
            rx={5.574}
          />
          <rect
            width={127.962}
            height={10.848}
            x={370.69}
            y={166.861}
            fill="#F5ECF4"
            stroke="#EBD8EA"
            strokeWidth={0.3}
            rx={5.424}
          />
          <rect
            width={127.962}
            height={10.848}
            x={370.69}
            y={183.556}
            fill="#F5ECF4"
            stroke="#EBD8EA"
            strokeWidth={0.3}
            rx={5.424}
          />
        </g>
        <path
          fill="#F6F6F6"
          stroke="#D5D5D5"
          strokeWidth={0.405}
          d="M248.565 52.08a3.05 3.05 0 1 1-6.1-.003 3.05 3.05 0 0 1 6.1.002ZM257.872 52.08a3.05 3.05 0 1 1-6.102-.002 3.05 3.05 0 0 1 6.102.001ZM267.148 52.08a3.05 3.05 0 1 1-6.1-.003 3.05 3.05 0 0 1 6.1.002Z"
        />
        <g filter="url(#u16r_bg_wide_svg__h)">
          <path
            fill="#F8F8F4"
            d="M185 127.306c0-.726.588-1.314 1.313-1.314h189.131c.725 0 1.313.588 1.313 1.314v51.007a6.567 6.567 0 0 1-6.567 6.567H191.567a6.567 6.567 0 0 1-6.567-6.567v-51.007Z"
            shapeRendering="crispEdges"
          />
          <path
            fill="#878552"
            d="M192.136 135.125v-3.678h.736v3.069h1.691v.609h-2.427Zm3.011 0v-2.732h.714v2.732h-.714Zm-.058-3.447c0-.236.173-.415.415-.415s.415.179.415.415a.403.403 0 0 1-.415.416.403.403 0 0 1-.415-.416Zm2.555 3.51c-.693 0-1.176-.289-1.176-.872v-.047h.63v.036c0 .237.215.379.552.379.299 0 .52-.121.52-.331 0-.132-.069-.21-.226-.247l-.809-.184c-.41-.095-.625-.305-.625-.72 0-.562.457-.872 1.103-.872.657 0 1.082.331 1.082.872v.042h-.625v-.047c0-.21-.131-.357-.447-.357-.289 0-.446.105-.446.299 0 .137.068.2.21.231l.83.205c.405.1.61.315.61.699 0 .531-.505.914-1.183.914Zm2.63-.063c-.526 0-.772-.294-.772-.778v-1.376h-.389v-.578h.389v-.746h.719v.746h.584v.578h-.584v1.324c0 .179.069.252.258.252h.289v.578h-.494Zm1.081 0v-2.732h.715v2.732h-.715Zm-.058-3.447c0-.236.174-.415.415-.415.242 0 .415.179.415.415a.403.403 0 0 1-.415.416.404.404 0 0 1-.415-.416Zm1.494 3.447v-2.732h.683v.326c.137-.252.415-.389.804-.389.594 0 .967.389.967 1.045v1.75h-.715v-1.634c0-.315-.152-.541-.499-.541-.32 0-.525.189-.525.557v1.618h-.715Zm4.323.977c-.641 0-1.172-.299-1.282-.814h.693c.095.189.31.273.589.273.362 0 .593-.2.593-.504v-.431c-.115.189-.43.373-.782.373-.736 0-1.125-.536-1.125-1.135v-.399c0-.61.384-1.135 1.13-1.135.42 0 .714.21.809.394v-.331h.683v2.674c0 .583-.547 1.035-1.308 1.035Zm-.021-1.676c.336 0 .614-.215.614-.599v-.315c0-.368-.252-.609-.593-.609-.4 0-.605.236-.605.609v.315c0 .347.195.599.584.599Z"
          />
          <g filter="url(#u16r_bg_wide_svg__i)">
            <rect
              width={172.822}
              height={26.379}
              x={193.375}
              y={142.305}
              fill="#fff"
              stroke="#53532D"
              strokeWidth={0.109}
              rx={1.259}
            />
            <rect
              width={34.297}
              height={5.322}
              x={211.27}
              y={156.008}
              fill="#D5D5D5"
              rx={0.499}
            />
            <rect
              width={16.325}
              height={2.303}
              x={220.745}
              y={149.477}
              fill="#969696"
              rx={1.152}
            />
            <rect
              width={16.325}
              height={2.303}
              x={317.926}
              y={149.477}
              fill="#969696"
              rx={1.152}
            />
            <rect
              width={33.705}
              height={5.322}
              x={260.941}
              y={156.008}
              fill="#D5D5D5"
              rx={0.499}
            />
            <rect
              width={34.297}
              height={5.322}
              x={307.662}
              y={156.008}
              fill="#D5D5D5"
              rx={0.499}
            />
          </g>
          <path
            stroke="#BCBB9F"
            strokeWidth={0.109}
            d="M185.055 127.306c0-.696.563-1.259 1.258-1.259h189.131c.695 0 1.258.563 1.258 1.259v51.007a6.511 6.511 0 0 1-6.512 6.512H191.567a6.512 6.512 0 0 1-6.512-6.512v-51.007Z"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="u16r_bg_wide_svg__c"
        width={309.751}
        height={204.312}
        x={221.848}
        y={32.532}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.923}
          result="effect1_dropShadow_7432_107451"
        />
        <feOffset dy={3.692} />
        <feGaussianBlur stdDeviation={4.615} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7432_107451"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7432_107451"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_wide_svg__d"
        width={129.341}
        height={95.834}
        x={242.652}
        y={61.583}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.81}
          result="effect1_dropShadow_7432_107451"
        />
        <feOffset dy={3.238} />
        <feGaussianBlur stdDeviation={4.048} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7432_107451"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7432_107451"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_wide_svg__e"
        width={129.34}
        height={49.388}
        x={242.653}
        y={146.091}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.81}
          result="effect1_dropShadow_7432_107451"
        />
        <feOffset dy={3.238} />
        <feGaussianBlur stdDeviation={4.048} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7432_107451"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7432_107451"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_wide_svg__f"
        width={130.27}
        height={41.959}
        x={369.991}
        y={69.903}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.81}
          result="effect1_dropShadow_7432_107451"
        />
        <feOffset dy={3.238} />
        <feGaussianBlur stdDeviation={4.048} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7432_107451"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7432_107451"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_wide_svg__g"
        width={146.072}
        height={80.013}
        x={361.635}
        y={126.685}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.81}
          result="effect1_dropShadow_7432_107451"
        />
        <feOffset dy={3.238} />
        <feGaussianBlur stdDeviation={4.048} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7432_107451"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7432_107451"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_wide_svg__h"
        width={196.573}
        height={63.707}
        x={182.592}
        y={124.46}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.219}
          result="effect1_dropShadow_7432_107451"
        />
        <feOffset dy={0.876} />
        <feGaussianBlur stdDeviation={1.095} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7432_107451"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7432_107451"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_wide_svg__i"
        width={177.748}
        height={31.308}
        x={190.912}
        y={140.718}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.219}
          result="effect1_dropShadow_7432_107451"
        />
        <feOffset dy={0.876} />
        <feGaussianBlur stdDeviation={1.095} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7432_107451"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7432_107451"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default UnaccreditedSellerListForSaleSvgLarge;
