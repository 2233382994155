export const getTopOffset = (
  elementA: Element,
  elementB: Element = document.body,
) =>
  elementA.getBoundingClientRect().top - elementB.getBoundingClientRect().top;

export const getBottomOffset = (
  elementA: Element,
  elementB: Element = document.body,
) =>
  elementA.getBoundingClientRect().bottom -
  elementB.getBoundingClientRect().bottom;
