import { Grid, GridItem, Text } from "@chakra-ui/react";

import { LongHyphen, SensitiveText } from "@/components/common";
import { CustomTooltip } from "@/components/form";
import { CompanyPricingCompanyFragment } from "@/gql";
import { formatPricePerShare } from "@/utils";

const PriceHeading = ({
  children,
  tooltip,
}: {
  readonly children: React.ReactNode;
  readonly tooltip: React.ReactNode;
}) => (
  <CustomTooltip tooltipContent={tooltip} placement="top">
    <Text
      maxW={{ base: `min-content`, lg: `unset` }}
      display="inline-block"
      textStyle="heading-2xs"
      borderBottom="0.5px dashed"
      pb={1}
    >
      {children}
    </Text>
  </CustomTooltip>
);

const PriceValue = ({ value }: { readonly value?: number | null }) =>
  value ? (
    <SensitiveText textStyle={{ base: `heading-md`, md: `heading-lg` }}>
      {formatPricePerShare(value)}
    </SensitiveText>
  ) : (
    <LongHyphen />
  );

const CompanyPricing = ({
  company: {
    currentPrices: { highestBid, lowestAsk, lastTransaction },
    name,
  },
}: {
  readonly company: CompanyPricingCompanyFragment;
}) => (
  <Grid
    w="full"
    p={4}
    borderColor="grey.100"
    borderRadius="md"
    borderWidth={0.5}
    bg="grey.50"
    gridTemplateColumns="repeat(3, 1fr)"
    gridTemplateRows="repeat(2, 1fr)"
    alignItems="flex-end"
    textAlign="center"
    justifyContent={{ base: `space-around`, sm: `center` }}
    rowGap={{ base: 0, lg: 2 }}
    columnGap={2}
  >
    <GridItem>
      <PriceHeading
        tooltip={`Bid is the highest priced bid on ${name} listings or ${name} standing bids that was open this week.`}
      >
        Highest Bid
      </PriceHeading>
    </GridItem>
    <GridItem>
      <PriceHeading
        tooltip={`Ask is the lowest priced listing for ${name} that was open this week.`}
      >
        Lowest Ask
      </PriceHeading>
    </GridItem>
    <GridItem>
      <PriceHeading
        tooltip={`The most recent price at which a bid was accepted for ${name}.`}
      >
        Last Transaction
      </PriceHeading>
    </GridItem>
    <GridItem alignSelf="end">
      <PriceValue value={highestBid} />
    </GridItem>
    <GridItem alignSelf="end">
      <PriceValue value={lowestAsk} />
    </GridItem>
    <GridItem alignSelf="end">
      <PriceValue value={lastTransaction} />
    </GridItem>
  </Grid>
);

export default CompanyPricing;
