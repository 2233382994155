import { X } from "@phosphor-icons/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalProps,
  ModalBody,
  useBreakpointValue,
  Flex,
  Center,
  Box,
} from "@chakra-ui/react";

import { useIsDesktop } from "@/hooks";
import { getIsAppleChromeBrowser, getIsAndroidDevice } from "@/utils";

type HeaderStyle = "LIGHT" | "DARK";

interface FormModalProps extends ModalProps {
  readonly headerStyle?: HeaderStyle;
  readonly title?: string | JSX.Element;
}

const FormModal = ({
  title,
  headerStyle = `DARK`,
  children,
  onClose,
  ...modalProps
}: FormModalProps) => {
  const isDesktop = useIsDesktop();
  const modalSize = useBreakpointValue(
    { base: `full`, md: `2xl` },
    { ssr: false },
  );

  const isAppleChromeBrowser = getIsAppleChromeBrowser();
  const isAndroidDevice = getIsAndroidDevice();

  const mobileModalContextPos =
    isAppleChromeBrowser || isAndroidDevice ? `relative` : `fixed`;
  const mobileModalScrollBehavior =
    isAppleChromeBrowser || isAndroidDevice ? `outside` : `inside`;

  return (
    <Modal
      {...modalProps}
      onClose={onClose}
      size={modalSize}
      isCentered={isDesktop}
      scrollBehavior={isDesktop ? `outside` : mobileModalScrollBehavior}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: `0`, md: `md` }}
        pos={{ base: mobileModalContextPos, md: `relative` }}
        my={{ base: 0, md: `auto` }}
        mx={{ base: 0, md: `auto` }}
        inset={{ base: 0, md: `auto` }}
      >
        {!!title && (
          <ModalHeader
            bg={headerStyle === `LIGHT` ? `grey.50` : `grey.800`}
            py={{ base: 4, md: 6 }}
            px={{ base: 5, md: 10 }}
            borderTopRadius={{ base: `0`, md: `md` }}
          >
            <Flex
              justify="space-between"
              align="center"
              textStyle={
                headerStyle === `LIGHT`
                  ? `modal-header-dark`
                  : `modal-header-light`
              }
            >
              {title}
              <Center onClick={onClose} display={{ base: `flex`, md: `none` }}>
                <X
                  size={32}
                  color={headerStyle === `LIGHT` ? `grey.800` : `white`}
                />
              </Center>
            </Flex>
          </ModalHeader>
        )}
        <ModalBody pt={6} px={{ base: 5, md: 10 }} pos="relative">
          {children}
          <Box display={{ base: `block`, md: `none` }} h={14} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FormModal;
