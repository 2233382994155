import { useTranslation } from "react-i18next";

import { ShareDetailsDisclaimer } from "@/components/common";
import { DefaultBuyerRoundedSharesTooltip } from "@/components/tooltip";

const ListingBuyerRoundingDisclaimer = () => {
  const { t } = useTranslation();
  return (
    <ShareDetailsDisclaimer tooltip={<DefaultBuyerRoundedSharesTooltip />}>
      {t(`listing_buyer_rounding_disclaimer`)}
    </ShareDetailsDisclaimer>
  );
};

export default ListingBuyerRoundingDisclaimer;
