import { motion } from "framer-motion";
import { ReactNode } from "react";

import { Box, BoxProps, HStack, Text, VStack } from "@chakra-ui/react";

import { ObscureText } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  DiscussionMessageDiscussionFragment,
  DiscussionMessageDiscussionMessageFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useMessageSenderDisplayName } from "@/hooks";
import {
  getIsSender,
  getIsSenderInstitution,
  getIsSensitiveMessage,
} from "@/utils";
import * as datetime from "@/utils/datetime";

const MessageTimestamp = ({ date }: { readonly date: string }) => (
  <Text textStyle="deprecated-text-2xs" whiteSpace="nowrap">
    {datetime.format(`Do MMM (h:mm A z)`, date)}
  </Text>
);

interface MessageWrapperProps extends BoxProps {
  readonly children: ReactNode;
}

const MessageWrapper = ({ children, ...boxProps }: MessageWrapperProps) => (
  <Box
    borderRadius="md"
    px={4}
    py={3}
    w="full"
    borderWidth={0.5}
    borderColor="grey.200"
    {...boxProps}
  >
    {children}
  </Box>
);

export const DiscussionMessage = withCurrentActor(
  ({
    message,
    discussion,
    actor,
    showSenderName,
    index,
  }: {
    readonly message: DiscussionMessageDiscussionMessageFragment;
    readonly discussion: DiscussionMessageDiscussionFragment;
    readonly actor: UserWithInstitutionFragment;
    readonly showSenderName: boolean;
    readonly index: number;
  }) => {
    const isSender = getIsSender(actor, message);
    const isSenderInstitution = getIsSenderInstitution(actor, message);

    const senderDisplayName = useMessageSenderDisplayName({
      discussion,
      message,
    });

    const isSensitiveMessage = getIsSensitiveMessage(
      actor,
      message,
      discussion,
    );

    return (
      <motion.div
        style={{ width: `100%` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.15 } }}
      >
        <VStack alignItems="flex-start" w="full" spacing={1}>
          <HStack
            w="full"
            justifyContent="space-between"
            mt={showSenderName && index !== 0 ? 5 : 0}
            alignItems="flex-end"
          >
            <Box>
              {showSenderName && (
                <HStack spacing={2}>
                  <Box
                    w="3"
                    h="3"
                    borderRadius="100%"
                    bg={
                      isSender || isSenderInstitution
                        ? `grey.900`
                        : `salmon.900`
                    }
                  />
                  <Text
                    textStyle="heading-xs"
                    textTransform="uppercase"
                    flex="1"
                    data-testid={`discussion-message-${index}-sender-name`}
                  >
                    {message.sender.isHiiveUser && `Hiive Representative: `}
                    {senderDisplayName}
                  </Text>
                </HStack>
              )}
            </Box>
            <MessageTimestamp date={message.insertedAt} />
          </HStack>
          <HStack spacing={0} w="full">
            <Box w="5" />
            <MessageWrapper
              bg={isSender || isSenderInstitution ? `grey.50` : `white`}
            >
              <ObscureText
                obscure={isSensitiveMessage}
                whiteSpace="pre-wrap"
                dataTestId={`discussion-message-${index}-body`}
              >
                {message.messageBody}
              </ObscureText>
            </MessageWrapper>
          </HStack>
        </VStack>
      </motion.div>
    );
  },
);
