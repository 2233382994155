import { ComponentType, ReactNode } from "react";

import { Spinner, Center } from "@chakra-ui/react";

import { RootConfigFragment, useConfigQuery } from "@/gql";

interface WithConfigProps {
  readonly config: RootConfigFragment;
}

/**
 * A higher-order component that waits for config to be loaded from server.
 */
function withConfig<TProps extends WithConfigProps>(
  WrappedComponent: ComponentType<TProps>,
  { fallback }: { readonly fallback?: ReactNode } = {},
) {
  return (hocProps: Omit<TProps, "config">) => {
    const { data, loading, error } = useConfigQuery();

    if (error || !data) return null;

    const config = data?.config;

    if (loading || !config)
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{fallback}</> || (
          <Center m={20}>
            <Spinner />
          </Center>
        )
      );

    return <WrappedComponent {...(hocProps as TProps)} config={config} />;
  };
}

export default withConfig;
