import { Card, CardBody, CardProps, Text } from "@chakra-ui/react";

interface ActivityGroupEmptyStateProps extends CardProps {
  readonly message: string;
}

const ActivityGroupEmptyState = ({
  message,
  ...cardProps
}: ActivityGroupEmptyStateProps) => (
  <Card w="full" {...cardProps}>
    <CardBody>
      <Text>{message}</Text>
    </CardBody>
  </Card>
);

export default ActivityGroupEmptyState;
