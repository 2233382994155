import { Flex, Text } from "@chakra-ui/react";

import { HiiveButton, MailtoLink } from "@/components/common";
import { constants } from "@/utils";

const NotFoundError = () => (
  <Flex direction="column" alignItems="center" pt={{ base: 10, md: 20 }}>
    <Text textStyle="deprecated-heading-7xl" textAlign="center">
      Page not found
    </Text>
    <Text textStyle="deprecated-text-4xl" textAlign="center" mb={5}>
      We&apos;re sorry, the page you requested could not be found.
      <Text>Please try again later.</Text>
    </Text>
    <MailtoLink email={constants.email_support} subject="Contact Hiive">
      <HiiveButton
        size="md"
        variant="rounded-solid-salmon"
        sentryLabel="[NotFoundError] Contact Support"
      >
        Contact Support
      </HiiveButton>
    </MailtoLink>
  </Flex>
);

export default NotFoundError;
