import { useMemo, useState } from "react";

import { Center, Flex, Spinner, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FilterTypes, getFilterValue } from "@/components/companies";
import { Investor, useCompaniesInvestorsQuery } from "@/gql";

import { FilterTag } from "./FilterTag";

export const InvestorList = ({
  search,
  filters,
  onFilterChange,
}: {
  readonly search: string;
  readonly onFilterChange: (
    field: keyof FilterTypes,
    value: string | readonly string[],
  ) => void;
  readonly filters: FilterTypes;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { data, loading, error } = useCompaniesInvestorsQuery();

  const getIsInvestorSelected = (investorId: string) =>
    filters.investors.includes(investorId);

  const toggleInvestorSelected = (investorId: string) => {
    const currentInvestors = getFilterValue(filters.investors);

    if (getIsInvestorSelected(investorId)) {
      const nextInvestors = currentInvestors.filter(
        (investor) => investor !== investorId,
      );
      onFilterChange(`investors`, nextInvestors);
    } else {
      const nextInvestors = [...currentInvestors, investorId];
      onFilterChange(`investors`, nextInvestors);
    }
  };

  const displayedInvestors = useMemo(() => {
    const investorsFilteredBySearch =
      data?.companiesInvestors.filter((investor) =>
        investor.name.toLowerCase().includes(search.toLowerCase()),
      ) || [];
    return isExpanded
      ? investorsFilteredBySearch
      : investorsFilteredBySearch.slice(0, 20);
  }, [data?.companiesInvestors, isExpanded, search]);

  if (error) return null;
  if (loading || !data?.companiesInvestors) {
    return (
      <Center boxSize="full">
        <Spinner />
      </Center>
    );
  }

  const isListExpandable = displayedInvestors.length >= 20;

  return displayedInvestors.length > 0 ? (
    <Flex direction="column" gap={2}>
      <Flex gap={1} flexWrap="wrap" maxW="lg" maxH="sm" overflowY="auto">
        {displayedInvestors.map((investor: Investor) => (
          <FilterTag
            key={investor.id}
            id={investor.id}
            name={investor.name}
            selected={getIsInvestorSelected(investor.id)}
            onClick={toggleInvestorSelected}
          />
        ))}
      </Flex>
      {isListExpandable && (
        <HiiveButton
          px={0}
          alignSelf="flex-start"
          variant="text-salmon"
          onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
          sentryLabel="[InvestorList] Toggle"
        >
          {isExpanded ? `Collapse` : `Expand`}
        </HiiveButton>
      )}
    </Flex>
  ) : (
    <Text>No results found.</Text>
  );
};
