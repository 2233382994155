import * as pluralize from "pluralize";
import { Trans, useTranslation } from "react-i18next";

import { Badge, Flex, Link, Text } from "@chakra-ui/react";

import {
  FeeBreakdown,
  FocusedShareDetails,
  KYCSensitive,
  ListingNumShareAndPriceDetails,
  MailtoLink,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  ListingPageListingInfoCardDiscussionFragment,
  ListingPageListingInfoCardListingFragment,
  ListingState,
  UserWithInstitutionFragment,
} from "@/gql";
import { useSPVEnabled } from "@/hooks/featureFlags";
import {
  constants,
  getAvailableListingActions,
  getIsSellerForListing,
  getListingBidCount,
  getListingHasAcceptedBidPrices,
  getListingHasBids,
  getListingNumOfShares,
  lot,
  shareTypeToString,
  transferMethodToLongString,
} from "@/utils";

import { ListingActionsTile } from "./ListingActionsTile";
import ListingHiiveUserTile from "./ListingHiiveUserTile";
import {
  AcceptedBidSharePriceDetails,
  checkListingTerminalState,
  RoundingDisclaimer,
} from "./ListingInfoCard";
import { ListingPartialBidDetails } from "./ListingPartialBidDetails";
import ListingSellerRoundingDisclaimer from "./ListingSellerRoundingDisclaimer";
import ListingStatusTile from "./ListingStatusTile";
import PropertyGrid from "./PropertyGrid";

const OtherDetailsSection = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const { t } = useTranslation();

  return (
    <FocusedShareDetails.ContentSection
      p={{ base: 4, md: 6 }}
      pb={5}
      data-testid="listing-notes"
    >
      <Flex gap={3} direction="column">
        <Text textStyle="heading-sm">{t(`listing_notes`)}</Text>
        <KYCSensitive>
          <Text>{listing.otherDetails}</Text>
        </KYCSensitive>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const FromHiiveSection = () => {
  const { t } = useTranslation();

  return (
    <FocusedShareDetails.ContentSection
      p={{ base: 4, md: 6 }}
      pb={5}
      data-testid="hiive-listing-text"
    >
      <Flex gap={3} direction="column">
        <Text textStyle="heading-sm">{t(`placed_by_hiive`)}</Text>
        <Text>{t(`listing_placed_by_hiive_description`)}</Text>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const HiiveSPVSection = () => {
  const { t } = useTranslation();

  return (
    <FocusedShareDetails.ContentSection
      p={{ base: 4, md: 6 }}
      pb={5}
      data-testid="hiive-spv-listing-text"
    >
      <Flex gap={3} direction="column">
        <Text textStyle="heading-sm">{t(`hiive_organized_spv`)}</Text>
        <Text>{t(`spv_listing_description`)}</Text>
        <Text>
          <Trans
            i18nKey="spv_more_information"
            t={t}
            components={[
              <Link
                key="faq"
                href={constants.spv_faq_url}
                fontWeight="medium"
                target="_blank"
              />,
              <MailtoLink
                key="contact"
                fontWeight="medium"
                email={constants.email_funds}
              />,
            ]}
          />
        </Text>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const BidCountSection = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const { t } = useTranslation();

  const bidCount = getListingBidCount(listing);

  return (
    <FocusedShareDetails.ContentSection>
      <Flex gap={4} direction="column" align="start">
        <Text textStyle="heading-sm">{t(`activity`)}</Text>
        <Badge variant="grey" as={Text} gap={4}>
          <KYCSensitive>
            <Text as="strong" textStyle="heading-lg">
              {bidCount}
            </Text>
          </KYCSensitive>
          {` `}
          {pluralize(`Bid`, bidCount)}
        </Badge>
      </Flex>
    </FocusedShareDetails.ContentSection>
  );
};

const TraderSharedPropertyGridItems = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const { t } = useTranslation();

  const isListingInTerminalState = checkListingTerminalState(listing.state);

  const actualNumberOfShares = getListingNumOfShares(listing, false);

  const formattedShareType = shareTypeToString(listing.shareTypeV2);

  const formattedTransferMethod = transferMethodToLongString(
    listing.transferMethod,
  );

  const { commissionAmount, flatFeeAmount, feeDiscountAmount, netFees } =
    listing.commission || {};

  return (
    <>
      <PropertyGrid.Item>
        <PropertyGrid.ItemHeader>{t(`share_type`)}</PropertyGrid.ItemHeader>
        <KYCSensitive>
          <PropertyGrid.ItemValue>{formattedShareType}</PropertyGrid.ItemValue>
        </KYCSensitive>
      </PropertyGrid.Item>
      <PropertyGrid.Item>
        <PropertyGrid.ItemHeader>{t(`structure`)}</PropertyGrid.ItemHeader>
        <KYCSensitive>
          <PropertyGrid.ItemValue>
            {formattedTransferMethod}
          </PropertyGrid.ItemValue>
        </KYCSensitive>
      </PropertyGrid.Item>
      {!isListingInTerminalState && (
        <PropertyGrid.GridItem colSpan={2}>
          <FeeBreakdown
            numShares={actualNumberOfShares}
            pricePerShare={listing.listingPricePerShare}
            feeDiscountApplications={listing.feeDiscountApplications}
            flatFeeAmount={flatFeeAmount}
            commissionAmount={commissionAmount}
            feeDiscountAmount={feeDiscountAmount}
            netFees={netFees}
            disclaimerVariant="listingSeller"
          />
        </PropertyGrid.GridItem>
      )}
    </>
  );
};

const TraderCounterpartyPropertyGrid = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => (
  <PropertyGrid.Grid>
    <TraderSharedPropertyGridItems listing={listing} />
  </PropertyGrid.Grid>
);

const TraderListerPropertyGrid = ({
  listing,
}: {
  readonly listing: ListingPageListingInfoCardListingFragment;
}) => {
  const { t } = useTranslation();

  const actualNumberOfShares = getListingNumOfShares(listing, false);
  const actualValueOfShares =
    actualNumberOfShares && listing.listingPricePerShare
      ? lot(actualNumberOfShares, listing.listingPricePerShare)
      : `-`;

  return (
    <PropertyGrid.Grid>
      <TraderSharedPropertyGridItems listing={listing} />

      <PropertyGrid.GridItem colSpan={{ base: 1, md: 2 }} mt={3}>
        <PropertyGrid.Tile gridTemplateColumns="repeat(2, 1fr)">
          <PropertyGrid.ItemHeader>
            {t(`actual_amount_of_shares`)}
          </PropertyGrid.ItemHeader>
          <KYCSensitive>
            <PropertyGrid.ItemValue>
              {actualNumberOfShares}
            </PropertyGrid.ItemValue>
          </KYCSensitive>
        </PropertyGrid.Tile>
      </PropertyGrid.GridItem>
      <PropertyGrid.GridItem colSpan={{ base: 1, md: 2 }}>
        <PropertyGrid.Tile gridTemplateColumns="repeat(2, 1fr)">
          <PropertyGrid.ItemHeader>{t(`actual_value`)}</PropertyGrid.ItemHeader>
          <KYCSensitive>
            <PropertyGrid.ItemValue>
              {actualValueOfShares}
            </PropertyGrid.ItemValue>
          </KYCSensitive>
        </PropertyGrid.Tile>
      </PropertyGrid.GridItem>
    </PropertyGrid.Grid>
  );
};

const TraderCounterpartyInfoCard = withCurrentActor(
  ({
    listing,
    actor,
    discussion,
  }: {
    readonly listing: ListingPageListingInfoCardListingFragment;
    readonly actor: UserWithInstitutionFragment;
    readonly discussion?: ListingPageListingInfoCardDiscussionFragment;
  }) => {
    const { t } = useTranslation();
    const isSPVEnabled = useSPVEnabled();

    const hasBids = getListingHasBids(listing);
    const hasAcceptedBidPrices = getListingHasAcceptedBidPrices(listing);
    const { canActOnListing } = getAvailableListingActions(listing);

    const showStatusTile =
      !!listing.expireAt || listing.state !== ListingState.Open;

    const showActionsTile = canActOnListing || showStatusTile;

    const showFromHiive =
      listing.fromHiive && (isSPVEnabled ? !listing.isHiiveSpv : true);
    const showSPV = isSPVEnabled && listing.isHiiveSpv;

    const isListingV1 = listing.version === 1;

    const title = !!discussion
      ? t(`inquiry_for_listing_display_id`, { displayId: listing.displayId })
      : t(`listing_display_id`, { displayId: listing.displayId });

    return (
      <FocusedShareDetails.Card
        variant={!!discussion ? `discussion` : `listing`}
      >
        <FocusedShareDetails.Header title={title} company={listing.company}>
          <FocusedShareDetails.HeaderCard>
            <ListingNumShareAndPriceDetails listing={listing} />
            {hasAcceptedBidPrices && (
              <AcceptedBidSharePriceDetails listing={listing} />
            )}
          </FocusedShareDetails.HeaderCard>
          <RoundingDisclaimer listing={listing} />
        </FocusedShareDetails.Header>
        <FocusedShareDetails.Content>
          {showActionsTile && (
            <FocusedShareDetails.ContentSection>
              <Flex direction="column" gap={7}>
                {canActOnListing && <ListingActionsTile listing={listing} />}
                {showStatusTile && <ListingStatusTile listing={listing} />}
                {actor.isHiiveUser && (
                  <ListingHiiveUserTile listing={listing} />
                )}
              </Flex>
            </FocusedShareDetails.ContentSection>
          )}
          <FocusedShareDetails.ContentSection p={{ base: 4, md: 6 }} pb={5}>
            <Flex direction="column" gap={8}>
              <TraderCounterpartyPropertyGrid listing={listing} />
              {isListingV1 && <ListingPartialBidDetails listing={listing} />}
            </Flex>
          </FocusedShareDetails.ContentSection>
          {!!listing.otherDetails && <OtherDetailsSection listing={listing} />}
          {showFromHiive && <FromHiiveSection />}
          {showSPV && <HiiveSPVSection />}
          {hasBids && <BidCountSection listing={listing} />}
        </FocusedShareDetails.Content>
      </FocusedShareDetails.Card>
    );
  },
);

const TraderListerInfoCard = withCurrentActor(
  ({
    listing,
    actor,
    discussion,
  }: {
    readonly listing: ListingPageListingInfoCardListingFragment;
    readonly actor: UserWithInstitutionFragment;
    readonly discussion?: ListingPageListingInfoCardDiscussionFragment;
  }) => {
    const { t } = useTranslation();

    const hasAcceptedBidPrices = getListingHasAcceptedBidPrices(listing);
    const hasBids = getListingHasBids(listing);

    const { canActOnListing } = getAvailableListingActions(listing);

    const showStatusTile =
      !!listing.expireAt || listing.state !== ListingState.Open;

    const showActionsTile = canActOnListing || showStatusTile;

    const isListingV1 = listing.version === 1;

    const title = !!discussion
      ? t(`inquiry_for_listing_display_id`, { displayId: listing.displayId })
      : t(`listing_display_id`, { displayId: listing.displayId });

    return (
      <FocusedShareDetails.Card
        variant={!!discussion ? `discussion` : `listing`}
      >
        <FocusedShareDetails.Header title={title} company={listing.company}>
          <FocusedShareDetails.HeaderCard>
            <ListingNumShareAndPriceDetails listing={listing} />
            {hasAcceptedBidPrices && (
              <AcceptedBidSharePriceDetails listing={listing} />
            )}
          </FocusedShareDetails.HeaderCard>
          <ListingSellerRoundingDisclaimer />
        </FocusedShareDetails.Header>
        <FocusedShareDetails.Content>
          {showActionsTile && (
            <FocusedShareDetails.ContentSection>
              <Flex direction="column" gap={7}>
                {canActOnListing && <ListingActionsTile listing={listing} />}
                {showStatusTile && <ListingStatusTile listing={listing} />}
                {actor.isHiiveUser && (
                  <ListingHiiveUserTile listing={listing} />
                )}
              </Flex>
            </FocusedShareDetails.ContentSection>
          )}
          <FocusedShareDetails.ContentSection p={{ base: 4, md: 6 }} pb={5}>
            <Flex direction="column" gap={8}>
              <TraderListerPropertyGrid listing={listing} />
              {isListingV1 && <ListingPartialBidDetails listing={listing} />}
            </Flex>
          </FocusedShareDetails.ContentSection>
          {!!listing.otherDetails && <OtherDetailsSection listing={listing} />}
          {listing.fromHiive && <FromHiiveSection />}
          {hasBids && <BidCountSection listing={listing} />}
        </FocusedShareDetails.Content>
      </FocusedShareDetails.Card>
    );
  },
);

export const TraderInfoCard = withCurrentActor(
  ({
    listing,
    actor,
    discussion,
  }: {
    readonly listing: ListingPageListingInfoCardListingFragment;
    readonly actor: UserWithInstitutionFragment;
    readonly discussion?: ListingPageListingInfoCardDiscussionFragment;
  }) => {
    const isSellerForListing = getIsSellerForListing(actor, listing);

    return isSellerForListing ? (
      <TraderListerInfoCard listing={listing} discussion={discussion} />
    ) : (
      <TraderCounterpartyInfoCard listing={listing} discussion={discussion} />
    );
  },
);
