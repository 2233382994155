import { useEffect } from "react";

import { getInvalidFormControlElements, getTopOffset } from "@/utils";

const useScrollToErrorsOnSubmitEffect = ({
  isSubmitting,
  containerElement,
}: {
  readonly isSubmitting: boolean;
  readonly containerElement: Element | null;
}) => {
  useEffect(() => {
    const invalidElements = getInvalidFormControlElements();
    if (!containerElement || !invalidElements) return;

    window.scrollTo({
      behavior: `smooth`,
      top: getTopOffset(invalidElements[0], containerElement),
    });
  }, [isSubmitting]);
};
export default useScrollToErrorsOnSubmitEffect;
