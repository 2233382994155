import { Line } from "@visx/shape";

import useColors from "@/hooks/useColors";

export const TooltipCrosshair = ({
  fromX,
  fromY,
  toX,
  toY,
}: {
  readonly fromX: number;
  readonly fromY: number;
  readonly toX: number;
  readonly toY: number;
}) => {
  const [grey800] = useColors([`grey.800`]);

  return (
    <Line
      from={{ x: fromX, y: fromY }}
      to={{ x: toX, y: toY }}
      stroke={grey800}
      strokeWidth={1}
      pointerEvents="none"
      strokeDasharray="2,2"
    />
  );
};
