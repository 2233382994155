import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  `group`,
  `tile`,
]);

export const RadioTile = defineMultiStyleConfig({
  baseStyle: {
    group: {
      display: `grid`,
      alignItems: `stretch`,
    },
    tile: {
      alignItems: `flex-start`,
      w: `full`,
    },
  },
  sizes: {
    sm: {
      group: {},
      tile: {
        px: 3,
        py: 2.5,
        alignItems: `center`,
      },
    },
    md: {
      group: {},
      tile: {
        alignItems: `flex-start`,
        p: 3,
      },
    },
    lg: {
      group: {
        gap: { base: 4, lg: 6 },
      },
      tile: {
        maxW: { base: `full`, lg: 80 },
      },
    },
  },
});
