import { Minus, Plus } from "@phosphor-icons/react";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  Flex,
  IconButton,
  Text,
} from "@chakra-ui/react";

import {
  BasicUserEntityFragment,
  BidPageBuyerActivityBidFragment,
} from "@/gql";

import TransactingEntityForm from "./TransactingEntityForm";

export enum TransactingEntityCardTypes {
  Buyer,
  Seller,
}

export type TransactingEntityCardProps = {
  children?: React.ReactNode;
  displayId: string;
  entities?: BasicUserEntityFragment[];
  isInstitution: boolean;
  transaction: BidPageBuyerActivityBidFragment["transaction"];
  type: TransactingEntityCardTypes;
};
const TransactingEntityCard = ({
  children,
  displayId,
  entities = [],
  isInstitution,
  transaction,
  type,
}: TransactingEntityCardProps) => {
  const { t } = useTranslation();

  const isBuyer = type === TransactingEntityCardTypes.Buyer;
  const transactionHasEntity = isBuyer
    ? !!transaction?.buyerEntity?.id
    : !!transaction?.sellerEntity?.id;

  return (
    <Card variant="darkened-footer" w="full">
      <Accordion allowToggle borderColor="transparent">
        <AccordionItem w="full">
          {({ isExpanded }) => (
            <>
              <CardHeader
                py={4}
                borderBottom={
                  isExpanded || !transactionHasEntity
                    ? `1px solid gray.200`
                    : `none`
                }
              >
                <Flex
                  w="full"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text textStyle={{ base: `heading-xl`, md: `heading-2xl` }}>
                    {t(`transacting_entity`)}
                  </Text>
                  {transactionHasEntity && (
                    <AccordionButton as="div" w="initial" p={0} rounded="full">
                      <IconButton
                        background="transparent"
                        h={8}
                        icon={
                          isExpanded ? <Minus size={16} /> : <Plus size={16} />
                        }
                        isRound
                        minW="fit-content"
                        variant="ghost"
                        aria-label={
                          isExpanded
                            ? `hide transacting entity`
                            : `expand transacting entity`
                        }
                        w={8}
                      />
                    </AccordionButton>
                  )}
                </Flex>
              </CardHeader>
              <AccordionPanel p={0}>
                {children && <CardBody>{children}</CardBody>}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      {!transactionHasEntity && (
        <TransactingEntityForm
          displayId={displayId}
          entities={entities}
          isInstitution={isInstitution}
          transactionId={transaction!.id}
          type={type}
        />
      )}
    </Card>
  );
};

export default TransactingEntityCard;
