import { useRef, useEffect } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

type EmbedDataKeys = "recipients" | "metadata" | "variables" | "fields";

// Reference https://developers.pandadoc.com/docs/javascript-form-embed

type FormEmbedData = {
  readonly recipients?: {
    readonly [key: string]: {
      readonly FirstName: string;
      readonly LastName: string;
      readonly Email: string;
    };
  };
  readonly metadata?: { readonly [key: string]: string };
  readonly variables?: { readonly [key: string]: string };
  readonly fields?: { readonly [key: string]: string };
};

interface PandadocFormFrameProps extends BoxProps {
  readonly title: string;
  readonly formId: string;
  readonly data?: FormEmbedData;
  readonly onLoaded?: () => void;
  readonly onStarted?: (data: {
    readonly recipients: readonly [
      {
        readonly first_name: string;
        readonly last_name: string;
        readonly email: string;
      },
    ];
  }) => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  readonly onCompleted?: (data: { readonly document: any }) => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  readonly onException?: (data: { readonly uuid: any }) => void;
}

const embedUrl = (formId: string, data?: FormEmbedData) => {
  const queryString = data
    ? Object.keys(data)
        .map(
          (key: EmbedDataKeys) =>
            `${`&${key}` as string}=${encodeURIComponent(
              JSON.stringify(data[key]),
            )}`,
        )
        .join(``)
    : ``;
  return `https://eform.pandadoc.com/?eform=${formId}${queryString}`;
};

const PandadocFormFrame = ({
  data,
  title,
  formId,
  onLoaded,
  onStarted,
  onCompleted,
  onException,
  ...boxProps
}: PandadocFormFrameProps) => {
  const url = embedUrl(formId, data);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMessageEvent = (e: any) => {
    if (
      e &&
      e.data &&
      typeof e.data === `string` &&
      e.source === iframeRef?.current?.contentWindow
    ) {
      const message = JSON.parse(e.data);
      if (message && message.event) {
        const event = message.event.replace(`embed.form.`, ``);
        if (event === `loaded` && onLoaded) onLoaded();
        if (event === `started` && onStarted) onStarted(message.data);
        if (event === `completed` && onCompleted) onCompleted(message.data);
        if (event === `exception` && onException) onException(message.data);
      }
    }
  };

  useEffect(() => {
    if (iframeRef && iframeRef.current) {
      window.addEventListener(`message`, handleMessageEvent, false);
    }

    return () => {
      window.removeEventListener(`message`, handleMessageEvent);
    };
  }, [iframeRef.current]);

  return (
    <Box {...boxProps}>
      <iframe
        ref={iframeRef}
        title={title}
        src={url}
        width="100%"
        height="100%"
        frameBorder={0}
        allowFullScreen
        style={{ position: `relative`, display: `block`, border: 0 }}
      />
    </Box>
  );
};
export default PandadocFormFrame;
