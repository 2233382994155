/* eslint-disable func-names */

/* eslint-disable object-shorthand */
import { Form, FormikProps } from "formik";
import isNil from "lodash/isNil";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { FullContentWrapper, WithQuery } from "@/components/common";
import { InformativeArticlesCardV2 } from "@/components/dashboard";
import { FormikQL } from "@/components/form";
import {
  ScrollSection,
  SectionNavBackButton,
  SectionNavButton,
  SectionNavWrapper,
  transferTypeChoiceToTransferMethod,
} from "@/components/listings";
import {
  useUnaccreditedSellerCreateListingByIdPageQuery,
  UnaccreditedSellerCreateListingPageByIdCompanyFragment,
  useCreateListingMutation,
  CreateListingMutation,
  ListingInput,
  ShareSeries,
} from "@/gql";
import {
  useBasicSectionScrollTracking,
  useModal,
  useScrollToErrorsOnSubmitEffect,
  UseSectionScrollTrackingGetSectionProps,
} from "@/hooks";
import {
  useManagedMarketsEnabled,
  useManagedMarketsFastFollowEnabled,
  useModifyListingNumSharesEnabled,
} from "@/hooks/featureFlags";
import {
  constants,
  currencyValue,
  getTransferMethodByUnaccreditedSellerTransferMethodInput,
  Nullable,
} from "@/utils";

import { ListingNotesCard } from "./ListingNotesCard";
import { SharePriceCardV2 } from "./SharePriceCardV2";
import { SummaryAndConfirmationCard } from "./SummaryAndConfirmationCard";
import { TransferTypeCard } from "./TransferTypeCard";
import { UnaccreditedSellerCreateListingPageContentSkeleton } from "./UnaccreditedSellerCreateListingPageSkeleton";
import { CreateListingFormValues } from "./types";

const useValidationSchema = (
  showConfirmEligibilityRequirementsCheckbox: boolean,
  showConfirmCompanyInformationDisclosureCheckbox: boolean,
) => {
  const { t } = useTranslation();
  const isModifyListingNumSharesFlagEnabled = useModifyListingNumSharesEnabled();

  const minTransactionSizeError = t(`min_transaction_size_error`, {
    minSize: constants.min_listing_size.text,
  });

  const requiredError = t(`required`);

  return isModifyListingNumSharesFlagEnabled
    ? Yup.object().shape({
        otherDetails: Yup.string().nullable(),
        pricePerShare: Yup.number()
          .nullable()
          .test({
            name: `checkListingMeetsMinValue`,
            params: {},
            message: minTransactionSizeError,
            test: function (pricePerShare: number) {
              const { numShares } = this.parent;

              return (
                pricePerShare * numShares >= constants.min_listing_size.number
              );
            },
          })
          .required(requiredError),
        transferTypeChoice: Yup.string().nullable().required(requiredError),
        numShares: Yup.number()
          .nullable()
          .required(requiredError)
          .test({
            name: `checkListingMeetsMinValue`,
            params: {},
            message: minTransactionSizeError,
            test: function (numShares: number) {
              const { pricePerShare } = this.parent;

              return (
                pricePerShare * numShares >= constants.min_listing_size.number
              );
            },
          }),
        confirmedEligibilityRequirements: Yup.boolean().when(`shareSeries`, {
          is: (shareSeries: ShareSeries) =>
            showConfirmEligibilityRequirementsCheckbox &&
            shareSeries === ShareSeries.Common,
          then: Yup.boolean().oneOf([true], requiredError),
        }),
        confirmedCompanyInformationDisclosure: Yup.boolean().when([], {
          is: () => showConfirmCompanyInformationDisclosureCheckbox,
          then: Yup.boolean().oneOf([true], requiredError),
        }),
        confirmed: Yup.boolean().nullable().oneOf([true], requiredError),
        shareSeries: Yup.string().nullable().required(requiredError),
      })
    : Yup.object().shape({
        otherDetails: Yup.string().nullable(),
        pricePerShare: Yup.number()
          .nullable()
          .test({
            name: `checkListingMeetsMinValue`,
            params: {},
            message: minTransactionSizeError,
            test: function (pricePerShare: number) {
              const { numShares } = this.parent;

              return (
                pricePerShare * numShares >= constants.min_listing_size.number
              );
            },
          })
          .required(requiredError),
        transferTypeChoice: Yup.string().nullable().required(requiredError),
        numShares: Yup.number().nullable().required(requiredError),
        confirmed: Yup.boolean().nullable().oneOf([true], requiredError),
      });
};

const initialValues: Nullable<CreateListingFormValues> = {
  otherDetails: ``,
  pricePerShare: null,
  transferTypeChoice: null,
  numShares: null,
  confirmedEligibilityRequirements: false,
  confirmedCompanyInformationDisclosure: false,
  confirmed: false,
  shareSeries: null,
};

const mapVariables = (
  {
    confirmedEligibilityRequirements: _confirmedEligibilityRequirements,
    confirmedCompanyInformationDisclosure: _confirmedCompanyInformationDisclosure,
    confirmed: _confirmed,
    transferTypeChoice,
    shareSeries,
    numShares,
    ...values
  }: CreateListingFormValues,
  companyId: string,
) => ({
  input: {
    ...values,
    numShares,
    companyId,
    transferMethod: getTransferMethodByUnaccreditedSellerTransferMethodInput(
      transferTypeChoiceToTransferMethod(transferTypeChoice),
    ),
    pricePerShare: currencyValue(values.pricePerShare),
    shareSeriesMakeup: [
      {
        shareSeries: shareSeries,
        numShares: numShares,
      },
    ],
  } as ListingInput,
});

const sectionKeys = {
  SharePrice: `SharePrice`,
  TransferType: `TransferType`,
  ListingNotes: `ListingNotes`,
  SummaryAndConfirmation: `SummaryAndConfirmation`,
} as const;

type SectionKeys = keyof typeof sectionKeys;

interface UnaccreditedSellerCreateListingFormContentProps
  extends FormikProps<Nullable<CreateListingFormValues>> {
  readonly getSectionProps: UseSectionScrollTrackingGetSectionProps<
    SectionKeys,
    HTMLDivElement
  >;
  readonly containerElement: Element | null;
  readonly company: UnaccreditedSellerCreateListingPageByIdCompanyFragment;
}

const UnaccreditedSellerCreateListingFormContent = ({
  getSectionProps,
  containerElement,
  isSubmitting,
  company,
}: UnaccreditedSellerCreateListingFormContentProps) => {
  useScrollToErrorsOnSubmitEffect({
    isSubmitting,
    containerElement,
  });

  return (
    <VStack as={Form} spacing={4} alignItems="flex-start">
      <ScrollSection {...getSectionProps(`SharePrice`)}>
        <SharePriceCardV2 company={company} />
      </ScrollSection>
      <ScrollSection {...getSectionProps(`TransferType`)}>
        <TransferTypeCard />
      </ScrollSection>
      <ScrollSection {...getSectionProps(`ListingNotes`)}>
        <ListingNotesCard />
      </ScrollSection>
      <ScrollSection {...getSectionProps(`SummaryAndConfirmation`)}>
        <SummaryAndConfirmationCard company={company} />
      </ScrollSection>
    </VStack>
  );
};

const UnaccreditedSellerCreateListingPageContent = ({
  company,
}: {
  company: UnaccreditedSellerCreateListingPageByIdCompanyFragment;
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  const mutation = useCreateListingMutation();

  const containerRef = useRef<HTMLDivElement>(null);

  const { getSectionProps, getNavButtonProps } = useBasicSectionScrollTracking({
    keys: [
      sectionKeys.SharePrice,
      sectionKeys.TransferType,
      sectionKeys.ListingNotes,
      sectionKeys.SummaryAndConfirmation,
    ],
    containerElement: containerRef.current,
  });

  const { modals, onOpenModal } = useModal();

  const managedMarketsEnabled = !!useManagedMarketsEnabled();
  const showConfirmCompanyInformationDisclosureCheckbox =
    managedMarketsEnabled && company.hasActiveInformationDisclosure;
  const eligibilityCriterionText = company?.companyEligibilityCriterion?.text;
  const showConfirmEligibilityRequirementsCheckbox =
    !!useManagedMarketsFastFollowEnabled() && !isNil(eligibilityCriterionText);

  const validationSchema = useValidationSchema(
    showConfirmEligibilityRequirementsCheckbox,
    showConfirmCompanyInformationDisclosureCheckbox,
  );

  const handleSuccess = (response: CreateListingMutation) => {
    const {
      createListing: { listing },
    } = response;

    if (listing) {
      onOpenModal(modals.unaccreditedSellerCreateListingSuccess(listing))();
    }

    router.push(`/dashboard/${company.id}/active-bids`);
  };

  return (
    <FullContentWrapper px={{ base: 4, lg: 8 }}>
      <SimpleGrid
        columnGap={6}
        rowGap={4}
        maxW="max-width-lg"
        gridTemplateColumns={{ base: `1fr`, lg: `416px 1fr` }}
        w="full"
      >
        <GridItem gridColumn={{ base: 1, lg: 2 }}>
          <Text textStyle="heading-3xl">{t(`list_your_shares_for_sale`)}</Text>
        </GridItem>
        <GridItem display={{ base: `none`, lg: `grid` }}>
          <SectionNavWrapper containerElement={containerRef.current}>
            <SectionNavBackButton
              onClick={() => router.push(`/dashboard/${company.id}`)}
              sentryLabel="[UnaccreditedSellerCreateListingPage/Back]"
            />
            <Flex direction="column" gap={3}>
              <Card w="full">
                <CardBody>
                  <VStack spacing={2}>
                    <SectionNavButton {...getNavButtonProps(`SharePrice`)}>
                      1. {t(`share_price`)}
                    </SectionNavButton>
                    <SectionNavButton {...getNavButtonProps(`TransferType`)}>
                      2. {t(`transfer_type`)}
                    </SectionNavButton>
                    <SectionNavButton {...getNavButtonProps(`ListingNotes`)}>
                      3. {t(`listing_notes`)}
                    </SectionNavButton>
                    <SectionNavButton
                      {...getNavButtonProps(`SummaryAndConfirmation`)}
                    >
                      4. {t(`summary_confirmation`)}
                    </SectionNavButton>
                  </VStack>
                </CardBody>
              </Card>

              <InformativeArticlesCardV2 company={company} />
            </Flex>
          </SectionNavWrapper>
        </GridItem>
        <GridItem ref={containerRef}>
          <FormikQL
            mutation={mutation}
            mutationNames={[`createListing`]}
            initialValues={initialValues}
            validationSchema={validationSchema}
            mapVariables={(variables) =>
              mapVariables(variables as CreateListingFormValues, company.id)
            }
            onSuccess={handleSuccess}
          >
            {(formikProps) => (
              <UnaccreditedSellerCreateListingFormContent
                getSectionProps={getSectionProps}
                containerElement={containerRef.current}
                company={company}
                {...formikProps}
              />
            )}
          </FormikQL>
        </GridItem>
      </SimpleGrid>
    </FullContentWrapper>
  );
};

const UnaccreditedSellerCreateListingPageV2 = ({ id }: { id: string }) => {
  const query = useUnaccreditedSellerCreateListingByIdPageQuery({
    variables: { id },
  });
  const router = useRouter();

  return (
    <WithQuery
      query={query}
      fallback={<UnaccreditedSellerCreateListingPageContentSkeleton />}
    >
      {({ data: { companyById: company } }) => {
        const {
          activity: { myListings },
        } = company;

        if (myListings.length > 0) {
          router.replace(`/dashboard/${company.id}/active-bids`);
          return null;
        }

        return <UnaccreditedSellerCreateListingPageContent company={company} />;
      }}
    </WithQuery>
  );
};

export default UnaccreditedSellerCreateListingPageV2;
