import { ReactNode } from "react";
import { FieldError } from "react-hook-form";

import {
  Box,
  Text,
  HStack,
  StackProps,
  GridItem,
  Stack,
  GridItemProps,
} from "@chakra-ui/react";

interface ItemProps<T> {
  readonly onClick: (item: T) => void;
  readonly isSelected: boolean;
  readonly value: T;
  readonly children: ReactNode;
}

interface ContainerProps {
  readonly error?: FieldError;
  readonly children: ReactNode;
}

const Item = <T,>({
  onClick,
  isSelected,
  value,
  children,
  ...otherProps
}: ItemProps<T> & Omit<GridItemProps, "onClick">) => (
  <GridItem
    as={HStack}
    bg="white"
    borderRadius="md"
    p={2}
    onClick={() => {
      onClick(value);
    }}
    cursor="pointer"
    outlineOffset={isSelected ? -2 : -1}
    outline={isSelected ? `2px solid` : `1px solid`}
    outlineColor={isSelected ? `teal.1000` : `grey.100`}
    transition="outline-color 0.2s ease"
    _hover={{ bg: `grey.25` }}
    {...otherProps}
  >
    <Box
      boxSize={4}
      minW={4}
      minH={4}
      borderRadius="lg"
      borderColor={isSelected ? `teal.1000` : `grey.100`}
      borderWidth={isSelected ? 6 : 2}
    />
    {children}
  </GridItem>
);

const Container = ({
  error,
  children,
  ...otherProps
}: ContainerProps & StackProps) => (
  <Stack
    bg={!!error ? `red.10` : `unset`}
    w="full"
    p={2}
    alignItems="flex-start"
    {...otherProps}
  >
    {children}
  </Stack>
);

const ErrorMessage = ({ error }: { readonly error: FieldError }) => (
  <Text textStyle="text-xs" color="red.900">
    {error.message}
  </Text>
);

const Tooltip = ({ children }: { readonly children: ReactNode }) => (
  <Box p={2} bg="grey.50" borderRadius="sm" w="full">
    {children}
  </Box>
);

export default { Item, Container, ErrorMessage, Tooltip };
