import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Text, TextProps } from "@chakra-ui/react";

interface NoteProps extends TextProps {
  readonly children?: ReactNode;
}

const Note = ({ children, ...props }: NoteProps) => {
  const { t } = useTranslation();

  return (
    <Text textStyle="text-xs" {...props}>
      <Text as="span" fontWeight="medium">
        {t(`note`)}:{` `}
      </Text>
      {children}
    </Text>
  );
};

export default Note;
