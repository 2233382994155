import { TransactingEntityItem } from "@/components/account";

import TransactingEntityCard, {
  TransactingEntityCardProps,
  TransactingEntityCardTypes,
} from "./TransactingEntityCard";

type TransactingEntityBuyerCardProps = Omit<
  TransactingEntityCardProps,
  "children" | "type"
>;

const TransactingEntityBuyerCard = (props: TransactingEntityBuyerCardProps) => {
  const { transaction } = props;
  const transactingEntity = transaction?.buyerEntity;
  const hasTransactingEntity = !!transactingEntity;

  return (
    <TransactingEntityCard type={TransactingEntityCardTypes.Buyer} {...props}>
      {hasTransactingEntity && (
        <TransactingEntityItem
          entity={transactingEntity}
          showDate={false}
          showRemoveButton={false}
          showStatus={false}
        />
      )}
    </TransactingEntityCard>
  );
};

export default TransactingEntityBuyerCard;
