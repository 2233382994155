import { useFlows } from "@frigade/react";
import { useEffect } from "react";

import { useRouter } from "next/router";

import { CornerAnnouncement } from "@/components/frigade";
import { FlowKind } from "@/components/providers";
import { useFlowLauncher } from "@/hooks";

const AlsoPlaceStandingBidFlow = ({
  flowKind,
}: {
  readonly flowKind: FlowKind;
}) => {
  const router = useRouter();

  const { activeFlow, dismissFlows, getFlowID } = useFlowLauncher();
  const { markFlowSkipped } = useFlows();

  const flowID = getFlowID(flowKind);
  const allowedRoutePrefix = `/listings/bids`;

  useEffect(() => {
    if (
      activeFlow === flowKind &&
      !router.asPath.startsWith(allowedRoutePrefix)
    ) {
      markFlowSkipped(flowID);
      dismissFlows();
    }
  }, [activeFlow, router.asPath]);

  return <CornerAnnouncement flowKind={flowKind} />;
};

export default AlsoPlaceStandingBidFlow;
