import { useEffect } from "react";

const useRouteHashChange = (callback: (hash: string) => void) => {
  useEffect(() => {
    const handleHashChange = () => {
      callback(window.location.hash);
    };

    window.addEventListener(`hashchange`, handleHashChange);

    return () => {
      window.removeEventListener(`hashchange`, handleHashChange);
    };
  }, []);
};

export default useRouteHashChange;
