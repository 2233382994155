import { useField } from "formik";
import {
  ComponentType,
  createContext,
  ReactNode,
  useContext,
  useMemo,
} from "react";

import { FormControl, FormControlProps } from "@chakra-ui/react";

import FormFieldError, { FormFieldErrorProps } from "./FormFieldError";
import {
  FormNumberInputField,
  FormNumberInputFieldProps,
} from "./FormNumberInput";
import { MoneyInputField, MoneyInputFieldProps } from "./MoneyInput";

const FormFieldContext = createContext<{
  readonly name: string;
}>({ name: `` });

function withFieldContext<Props>(WrappedComponent: ComponentType<Props>) {
  const ComponentWithFieldContext: React.ComponentType<Omit<Props, "name">> = (
    props: Props,
  ) => {
    const { name } = useContext(FormFieldContext);

    return <WrappedComponent {...props} name={name} />;
  };

  return ComponentWithFieldContext;
}

const Control = ({
  name,
  children,
  ...props
}: FormControlProps & {
  readonly name: string;
  readonly children: ReactNode;
}) => {
  const [_field, { touched, error }] = useField(name);

  const contextValue = useMemo(() => ({ name }), [name]);

  return (
    <FormFieldContext.Provider value={contextValue}>
      <FormControl id={name} isInvalid={(error && touched) || false} {...props}>
        {children}
      </FormControl>
    </FormFieldContext.Provider>
  );
};

const Error = withFieldContext<FormFieldErrorProps>(FormFieldError);
const NumberInput = withFieldContext<FormNumberInputFieldProps>(
  FormNumberInputField,
);
const MoneyInput = withFieldContext<MoneyInputFieldProps>(MoneyInputField);

export default {
  Control,
  NumberInput,
  MoneyInput,
  Error,
};
