import { Control, FieldValues, Path, useController } from "react-hook-form";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  InputProps,
} from "@chakra-ui/react";

import { TextInput } from "./TextInput";

interface FormTextInputProps<TFieldValues extends FieldValues>
  extends InputProps {
  readonly control: Control<TFieldValues>;
  readonly name: Path<TFieldValues>;
  readonly label: string;
  readonly labelSrOnly?: FormLabelProps["srOnly"];
  readonly displayError?: boolean;
}

const FormTextInput = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  labelSrOnly,
  isRequired,
  displayError = true,
  ...inputProps
}: FormTextInputProps<TFieldValues>) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel srOnly={labelSrOnly}>
        {isRequired ? `${label} *` : label}
      </FormLabel>
      <TextInput {...inputProps} {...field} />
      {displayError && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormTextInput;
