import { useOrganization, useUser } from "@frigade/react";
import { useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ActionNeeded } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  IndividualCustomerAgreement,
  InstitutionalCustomerAgreement,
  SignedCustomerAgreement,
} from "@/components/pandadoc";
import { useCurrentContextQuery, UserWithInstitutionFragment } from "@/gql";
import {
  getAvailableUserPermissions,
  getIsMembershipAgreementSigned,
  getIsInstitutionUser,
} from "@/utils";

const TermsAndConditions = () => (
  <Text>
    This Customer Agreement incorporates by reference the{` `}
    <Link href="/terms-and-conditions" textStyle="heading-md" target="_blank">
      Hiive Markets Limited Customer Terms and Conditions
    </Link>
    , and by signing the Customer Agreement you agree to be bound by them.
  </Text>
);

const CustomerAgreement = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { startPolling, stopPolling } = useCurrentContextQuery();
    const { addPropertiesToUser } = useUser();
    const { addPropertiesToOrganization } = useOrganization();

    const membershipAgreementSigned = getIsMembershipAgreementSigned(actor);

    const isInstitutionUser = getIsInstitutionUser(actor);

    const onFormSigning = () => {
      startPolling(1000);

      if (isInstitutionUser) {
        addPropertiesToOrganization({
          membershipAgreementSigned: true,
        });
      } else {
        addPropertiesToUser({
          membershipAgreementSigned: true,
        });
      }
    };

    useEffect(() => {
      if (!membershipAgreementSigned) return;
      stopPolling();
    }, [membershipAgreementSigned]);

    return isInstitutionUser ? (
      <InstitutionalCustomerAgreement w="full" onFormSigning={onFormSigning} />
    ) : (
      <IndividualCustomerAgreement w="full" onFormSigning={onFormSigning} />
    );
  },
);

const UnsignedAgreementContent = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const isInstitutionUser = getIsInstitutionUser(actor);

    const { canSignUnsignedCustomerAgreement } = getAvailableUserPermissions(
      actor,
    );

    return (
      <>
        <ActionNeeded>
          {isInstitutionUser
            ? `Before you can complete buy or sell orders on the platform, your institution needs to complete the Customer Agreement.`
            : `Before you can complete buy or sell orders on the platform you will
            need to complete the Customer Agreement.`}
        </ActionNeeded>
        <TermsAndConditions />
        {canSignUnsignedCustomerAgreement && <CustomerAgreement />}
      </>
    );
  },
);

const SignedAgreementContent = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const isInstitutionUser = getIsInstitutionUser(actor);

    return (
      <>
        <Text>
          {isInstitutionUser
            ? `Your institution has signed the Customer Agreement.`
            : `You have signed the Customer Agreement.`}
        </Text>
        <TermsAndConditions />
        <SignedCustomerAgreement w="full" />
      </>
    );
  },
);

export const CustomerAgreementCard = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const membershipAgreementSigned = getIsMembershipAgreementSigned(actor);

    return (
      <Card w="full" flex="1">
        <CardHeader>
          <Text textStyle="heading-sm">Customer Agreement</Text>
        </CardHeader>
        <CardBody>
          <VStack spacing={4} alignItems="flex-start">
            {membershipAgreementSigned ? (
              <SignedAgreementContent />
            ) : (
              <UnsignedAgreementContent />
            )}
          </VStack>
        </CardBody>
      </Card>
    );
  },
);
