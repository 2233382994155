import { CardHeader, HStack, Text } from "@chakra-ui/react";

import { ActivityCardBadge } from "@/components/common";
import { CompanyStandingBidCardHeaderStandingBidFragment } from "@/gql";

const CompanyStandingBidCardHeader = ({
  standingBid,
}: {
  readonly standingBid: CompanyStandingBidCardHeaderStandingBidFragment;
}) => {
  const { displayId, fromHiive } = standingBid;

  return (
    <CardHeader>
      <HStack justifyContent="space-between">
        <HStack>
          <ActivityCardBadge variant="bid" title="Standing Bid" />
          <Text textStyle="text-lg" color="grey.900">
            {displayId}
          </Text>
        </HStack>
        {fromHiive && (
          <Text textStyle="text-sm" color="grey.900">
            PLACED BY HIIVE
          </Text>
        )}
      </HStack>
    </CardHeader>
  );
};

export default CompanyStandingBidCardHeader;
