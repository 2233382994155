import { SimpleGrid, GridItem } from "@chakra-ui/react";

import { FullContentWrapper, Skeleton } from "@/components/common";

const ModernTreasuryConnectAccountSkeleton = () => (
  <FullContentWrapper px={{ base: 4, lg: 8 }}>
    <SimpleGrid
      columnGap={6}
      rowGap={4}
      maxW="max-width-lg"
      gridTemplateColumns={{ base: `1fr`, lg: `416px 1fr` }}
      w="full"
    >
      <GridItem gridColumn={{ base: 1, lg: 2 }} w="30%">
        <Skeleton h={10} />
      </GridItem>
      <GridItem display={{ base: `none`, lg: `grid` }}>
        <Skeleton h={200} />
      </GridItem>
      <GridItem gridColumn={{ base: 1, lg: 2 }}>
        <Skeleton h={250} />
      </GridItem>
    </SimpleGrid>
  </FullContentWrapper>
);
export default ModernTreasuryConnectAccountSkeleton;
