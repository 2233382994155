import currency from "currency.js";

import { Flex, Text } from "@chakra-ui/react";

import { LongHyphen, SensitiveText } from "@/components/common";
import { formatShares } from "@/utils";

interface ActivityCardPricingNumSharesAndPriceProps {
  readonly numShares?: number | null;
  readonly price?: number | null;
  /** Whether to obscure the number values */
  readonly isSensitive?: boolean;
}

const ActivityCardPricingNumSharesAndPrice = ({
  numShares,
  price,
  isSensitive = true,
}: ActivityCardPricingNumSharesAndPriceProps) => (
  <Flex wrap="wrap" textStyle="heading-lg">
    <SensitiveText isSensitive={isSensitive} textStyle="heading-lg">
      {formatShares(numShares)}
    </SensitiveText>
    {!!price ? (
      <Text textStyle="heading-lg">
        <Text as="span">&nbsp;@{` `}</Text>
        <SensitiveText isSensitive={isSensitive}>
          {currency(price, {
            fromCents: true,
          }).format()}
        </SensitiveText>
        <Text as="span">/sh</Text>
      </Text>
    ) : (
      <LongHyphen />
    )}
  </Flex>
);

export default ActivityCardPricingNumSharesAndPrice;
