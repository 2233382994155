export { default as WelcomePage } from "./WelcomePage";
export {
  InvestorStatusPageV2,
  InnerTileContainer,
} from "./InvestorStatusPageV2";
export { InvestorSuitabilityPageV2 } from "./InvestorSuitabilityPageV2";
export { InvestorTypePage } from "./InvestorTypePage";
export { SellerInfoGuidePage } from "./SellerInfoGuidePage";
export { ResidencePage, BrokerResidencePage } from "./ResidencePage";
export { WatchlistPage } from "./WatchlistPage";
export { HoldingDetailsPage } from "./HoldingDetailsPage";
export { InstitutionInfoPage } from "./InstitutionInfoPage";
export { InstitutionIsBrokerPage } from "./InstitutionIsBrokerPage";
export { VerifyEmailPage } from "./VerifyEmailPage";
export { RequestEmailVerificationPage } from "./RequestEmailVerificationPage";
export { BrokerInfoPage } from "./BrokerInfoPage";
export { BrokerIntermediaryInfoPage } from "./BrokerIntermediaryInfoPage";
export { SlideAnimation } from "./SlideAnimation";
export { InvestorAccreditationPageV2 } from "./InvestorAccreditationPageV2";
export { default as OnboardingContainerV2 } from "./OnboardingContainerV2";
export { ClickwrapAgreementPage } from "./ClickwrapAgreementPage";
