import { WarningCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { match, P } from "ts-pattern";

import { Box, Flex, Text } from "@chakra-ui/react";

import { HiiveButton, MailtoLink } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { IdentityVerificationStatus, UserWithInstitutionFragment } from "@/gql";
import {
  useColors,
  useCustomToast,
  useIdentityVerificationModal,
} from "@/hooks";
import { RootState } from "@/state";
import { constants } from "@/utils";

interface UnaccreditedSellerComplianceReminderMessageProps {
  readonly actor: UserWithInstitutionFragment;
}

const UnaccreditedSellerComplianceReminderMessage = ({
  actor,
}: UnaccreditedSellerComplianceReminderMessageProps) => {
  const { infoToast } = useCustomToast();
  const [red600] = useColors([`red.600`]);
  const { t } = useTranslation();

  const handleOnComplete = () => {
    infoToast(t(`iv_in_progress`), {
      description: t(`identity_verification_being_processed`),
      icon: <WarningCircle color={red600} size={26} />,
    });
  };

  const { isOpen, onOpen } = useIdentityVerificationModal({
    actor,
    onComplete: handleOnComplete,
  });

  const hasCompletedPersona = useSelector(
    (state: RootState) => state.persona.isComplete,
  );

  const { identityVerificationStatus } = actor;

  const statusMessage = match({
    hasCompletedPersona,
    identityVerificationStatus,
  })
    .with(
      {
        identityVerificationStatus: P.when(
          (value: IdentityVerificationStatus) =>
            value === IdentityVerificationStatus.Pending ||
            value === IdentityVerificationStatus.Approved,
        ),
      },
      () => <Text as="span">{t(`identity_verification_being_processed`)}</Text>,
    )
    .with(
      { identityVerificationStatus: IdentityVerificationStatus.NeedsReview },
      () => (
        <Text as="span">
          {t(`identity_submitted_for_review`)}
          <MailtoLink
            email={constants.email_accounts}
            subject={t(`inquiry_iv_status`)}
            fontWeight="medium"
          >
            {constants.email_accounts}
          </MailtoLink>
        </Text>
      ),
    )
    .with(
      { identityVerificationStatus: IdentityVerificationStatus.Declined },
      () => (
        <Text as="span">
          {t(`identity_verification_declined`)}
          <MailtoLink
            email={constants.email_accounts}
            subject={t(`inquiry_iv_status`)}
            fontWeight="medium"
          >
            {constants.email_accounts}
          </MailtoLink>
        </Text>
      ),
    )
    .with({ hasCompletedPersona: true }, () => (
      <Text as="span">{t(`identity_verification_being_processed`)}</Text>
    ))
    .otherwise(() => (
      <>
        <Text as="span">
          {t(`before_sell_shares`)}
          {` `}
        </Text>
        <HiiveButton
          onClick={onOpen}
          isDisabled={isOpen}
          px={0}
          py={0}
          variant="text-salmon"
          display="inline"
          fontSize="inherit"
          sentryLabel="[UnaccreditedSellerComplianceReminder] Verify Identity"
        >
          {t(`verify_your_identity`)}
        </HiiveButton>
      </>
    ));

  return statusMessage;
};

interface UnaccreditedSellerComplianceReminderProps {
  readonly actor: UserWithInstitutionFragment;
}

const UnaccreditedSellerComplianceReminder = withCurrentActor(
  ({ actor }: UnaccreditedSellerComplianceReminderProps) => {
    const [red600] = useColors([`red.600`]);
    const { identityVerified } = actor;

    if (identityVerified) {
      return null;
    }

    return (
      <Flex gap="2">
        <Box flexShrink={0}>
          <WarningCircle color={red600} size={20} weight="fill" />
        </Box>
        <Box textStyle="text-sm">
          <UnaccreditedSellerComplianceReminderMessage actor={actor} />
        </Box>
      </Flex>
    );
  },
);

export default UnaccreditedSellerComplianceReminder;
