import { FrigadeAnnouncement, useFlows } from "@frigade/react";
import { ComponentProps, useEffect } from "react";

import { FlowKind } from "@/components/providers";
import { useFlowLauncher } from "@/hooks";

type RecurringAnnouncementProps = ComponentProps<typeof FrigadeAnnouncement>;

const RecurringAnnouncement = ({
  flowId,
  ...announcementProps
}: RecurringAnnouncementProps) => {
  const { isLoading, getFlowStatus, markFlowNotStarted } = useFlows();

  const { dismissFlows, getFlowID } = useFlowLauncher();

  const flowStatus = getFlowStatus(flowId);

  useEffect(() => {
    if (!isLoading && [`STARTED_FLOW`, `COMPLETED_FLOW`].includes(flowStatus)) {
      markFlowNotStarted(flowId);
    }
  }, [isLoading]);

  useEffect(() => {
    const flowsRequiringPatch = [
      FlowKind.BuyerPlaceBidSuccessStandingBidCTA,
      FlowKind.U16RTransactionCelebration,
    ];
    const shouldPatchFlow = flowsRequiringPatch.map(getFlowID).includes(flowId);

    if (shouldPatchFlow) {
      setTimeout(() => {
        // eslint-disable-next-line functional/immutable-data
        document.body.style.overflow = ``; // guardrails-disable-line
      }, 0);
    }
  }, [flowStatus]);

  const onDismiss = () => {
    dismissFlows();
  };

  const onComplete = () => {
    dismissFlows();
  };

  return (
    <FrigadeAnnouncement
      flowId={flowId}
      onDismiss={onDismiss}
      onComplete={onComplete}
      {...announcementProps}
    />
  );
};

export default RecurringAnnouncement;
