import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import useLocalStorage from "use-local-storage";

import { useRouter } from "next/router";

import { Fade, GridItem, SimpleGrid, VStack } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { useFeeDiscountAlert } from "@/components/companies";
import {
  ContentWrapper,
  HeroBannerSkeleton,
  PreListingHeroBanner,
} from "@/components/dashboard";
import { UnaccreditedSellerWelcomeFlow } from "@/components/frigade";
import { FlowKind } from "@/components/providers";
import {
  DefaultUnaccreditedSellerDashboardPageMyCompanyFragment,
  UserWithInstitutionFragment,
  useDefaultUnaccreditedSellerDashboardPageQuery,
} from "@/gql";
import { RootState } from "@/state";
import { getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard } from "@/utils";

import { CompanyOverviewCard } from "./CompanyOverviewCard";
import { GettingStartedWithYourSharesBanner } from "./GettingStartedWithYourSharesBanner";
import { ListSharesSellNowBlock } from "./ListSharesSellNowBlock";
import { VerifyYourIdentityCard } from "./VerifyYourIdentityCard";

const UnaccreditedSellerDashboardPageContent = ({
  company,
  actor,
}: {
  readonly company: DefaultUnaccreditedSellerDashboardPageMyCompanyFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const hasCompletedPersona = useSelector(
    (state: RootState) => state.persona.isComplete,
  );

  const [
    showGettingStartedBanner,
    setShowGettingStartedBanner,
  ] = useLocalStorage(`show-getting-started-banner`, true);

  const { feeDiscountAlertType } = useFeeDiscountAlert({
    company,
  });

  const { identityVerified } = actor;
  const showVerificationCard = !identityVerified && !hasCompletedPersona;

  const contentWrapperTransformValues = !!feeDiscountAlertType
    ? { base: `translateY(-0.5rem)`, lg: `translateY(-8rem)` }
    : { base: `translateY(-1.3rem)`, lg: `translateY(-12.5rem)` };

  return (
    <>
      <UnaccreditedSellerWelcomeFlow flowKind={FlowKind.U16RWelcome} />
      <PreListingHeroBanner />

      <ContentWrapper transform={contentWrapperTransformValues}>
        <SimpleGrid columns={2} gap={4}>
          {showVerificationCard && (
            <GridItem colSpan={2}>
              <Fade in>
                <VerifyYourIdentityCard />
              </Fade>
            </GridItem>
          )}
          <AnimatePresence>
            {showGettingStartedBanner && (
              <GridItem
                as={motion.div}
                colSpan={2}
                initial={{ translateY: -10 }}
                animate={{ translateY: 0 }}
                exit={{
                  opacity: 0,
                  height: 0,
                  transition: { duration: 0.25, ease: `easeInOut` },
                }}
              >
                <GettingStartedWithYourSharesBanner
                  company={company}
                  onClose={() => setShowGettingStartedBanner(false)}
                />
              </GridItem>
            )}
          </AnimatePresence>
          <ListSharesSellNowBlock />
          <GridItem colSpan={2}>
            <Fade in>
              <CompanyOverviewCard company={company} />
            </Fade>
          </GridItem>
        </SimpleGrid>
      </ContentWrapper>
    </>
  );
};

const DefaultUnaccreditedSellerDashboardPageSkeleton = () => (
  <VStack w="full" spacing={3} bg={{ base: `transparent`, lg: `white` }}>
    <HeroBannerSkeleton />
  </VStack>
);

const DefaultUnaccreditedSellerDashboardPage = () => {
  const router = useRouter();
  const query = useDefaultUnaccreditedSellerDashboardPageQuery({
    fetchPolicy: `cache-and-network`,
  });

  return (
    <WithQuery
      query={query}
      fallback={<DefaultUnaccreditedSellerDashboardPageSkeleton />}
    >
      {({
        data: {
          unaccreditedSellerMyActivity,
          unaccreditedSellerMyCompanyActivity,
          currentContext,
        },
      }) => {
        const { myListing, myCompany } = unaccreditedSellerMyActivity;

        if (!!myListing) {
          router.replace(`/dashboard/active-bids`);
          return null;
        }

        if (
          getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard(
            unaccreditedSellerMyActivity,
            unaccreditedSellerMyCompanyActivity,
          )
        ) {
          router.replace(`/dashboard/standing-bids`);
          return null;
        }
        const actor = currentContext?.currentActor;

        if (!actor) return null;

        return (
          <UnaccreditedSellerDashboardPageContent
            company={myCompany}
            actor={actor}
          />
        );
      }}
    </WithQuery>
  );
};

export default DefaultUnaccreditedSellerDashboardPage;
