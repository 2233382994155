import { match } from "ts-pattern";

import { withCurrentActor } from "@/components/hoc";
import {
  InvestorType,
  ListingPageListingActivityListingFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  getIsSellerForListing,
  getIsBrokerForListing,
  getIsInstitutionViewer,
} from "@/utils";

import { BrokerCounterpartyActivity } from "./BrokerCounterpartyActivity";
import { BrokerListerActivity } from "./BrokerListerActivity";
import { BuyerActivity } from "./BuyerActivity";
import { SellerActivity } from "./SellerActivity";

/**
 * Listing page activity for the `broker` user type
 *
 * Returns `<BrokerCounterpartyActivity/>` if the broker actor is a counterparty to the listing
 *
 * Returns `<BrokerListerActivity/>`
 */
const BrokerActivity = withCurrentActor(
  ({
    actor,
    listing,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly listing: ListingPageListingActivityListingFragment;
  }) => {
    const isBrokerForListing = getIsBrokerForListing(actor, listing);

    return isBrokerForListing ? (
      <BrokerListerActivity listing={listing} />
    ) : (
      <BrokerCounterpartyActivity listing={listing} />
    );
  },
);

/**
 * Listing page activity for the `trader` user type
 *
 * Returns `<SellerActivity/>` if the actor placed the listing
 *
 * Returns `<BuyerActivity/>` if the actor is a counterparty to the listing
 *
 * Returns null if the actor is an institution viewer
 */
const TraderActivity = withCurrentActor(
  ({
    listing,
    actor,
  }: {
    readonly listing: ListingPageListingActivityListingFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isInstitutionViewer = getIsInstitutionViewer(actor);
    if (isInstitutionViewer) return null;

    const isSellerForListing = getIsSellerForListing(actor, listing);

    return isSellerForListing ? (
      <SellerActivity listing={listing} />
    ) : (
      <BuyerActivity listing={listing} />
    );
  },
);

/**
 * Listing page activity for the `unaccredited_seller` user type
 *
 * Returns `<SellerActivity/>` if the actor placed the listing
 *
 * Returns null otherwise
 */
const UnaccreditedSellerActivity = withCurrentActor(
  ({
    listing,
    actor,
  }: {
    readonly listing: ListingPageListingActivityListingFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isSellerForListing = getIsSellerForListing(actor, listing);

    return isSellerForListing ? <SellerActivity listing={listing} /> : null;
  },
);

/**
 * @deprecated Remove when all legacy sellers are off platform
 *
 * Listing page activity for the legacy `seller` user type
 *
 * Returns `<SellerActivity/>` if the actor placed the listing
 *
 * Returns null otherwise
 */
const LegacySellerActivity = withCurrentActor(
  ({
    listing,
    actor,
  }: {
    readonly listing: ListingPageListingActivityListingFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isSellerForListing = getIsSellerForListing(actor, listing);

    return isSellerForListing ? <SellerActivity listing={listing} /> : null;
  },
);

export const ListingActivity = withCurrentActor(
  ({
    actor,
    listing,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly listing: ListingPageListingActivityListingFragment;
  }) =>
    match(actor.investorType)
      .with(InvestorType.Broker, () => <BrokerActivity listing={listing} />)
      .with(InvestorType.Trader, () => <TraderActivity listing={listing} />)
      .with(InvestorType.UnaccreditedSeller, () => (
        <UnaccreditedSellerActivity listing={listing} />
      ))
      .with(InvestorType.Seller, () => (
        <LegacySellerActivity listing={listing} />
      ))
      .otherwise(() => {
        throw new Error(`Invalid investor type in <ListingActivity/>`);
      }),
);
