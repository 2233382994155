import { Card, CardBody, CardFooter, CardHeader, Text } from "@chakra-ui/react";

import { ComplianceReminder } from "@/components/common";
import { TradeIntentType } from "@/types";

const MockPlaceBidCard = () => (
  <Card w="full" variant="darkened-footer">
    <CardHeader py={4}>
      <Text textStyle="heading-2xl">Place a Bid</Text>
    </CardHeader>
    <CardBody>
      <Text>
        Make an offer on this listing at, below, or above the seller&apos;s ask.
      </Text>
    </CardBody>
    <CardFooter>
      <ComplianceReminder tradeIntent={TradeIntentType.Buy} />
    </CardFooter>
  </Card>
);

export default MockPlaceBidCard;
