import { curveLinear } from "@visx/curve";
import { GlyphDot } from "@visx/glyph";
import { ParentSize } from "@visx/responsive";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import {
  XYChart,
  Axis,
  DataProvider,
  AreaSeries,
  Grid,
  DataContext,
} from "@visx/xychart";
import dayjs from "dayjs";
import { useCallback, useContext, useRef, useState } from "react";

import { Box, useBreakpointValue, VStack, Text } from "@chakra-ui/react";

import {
  AreaGradient,
  formatDateTick,
  isDateNotInPresentYear,
  SkeletonChart,
  TooltipCrosshair,
  useTooltipHandler,
} from "@/components/common";
import { Hiive50IndexPriceDateRange, Hiive50IndexPrice } from "@/gql";
import { useColors } from "@/hooks";
import { chakraColor, toFixedDecimal } from "@/utils";

const margin = { top: 20, right: 42, bottom: 20, left: 16 };

const getRange = (data: readonly Hiive50IndexPrice[] | null) => {
  if (!data) return [0, 0];

  const allValues = data.map((d) => d.avg);

  const minRange = Math.min(...allValues) - 3;
  const maxRange = Math.max(...allValues) + 3;

  return [minRange < 0 ? 0 : minRange, maxRange];
};

const TooltipGlyph = ({
  value,
  x,
}: {
  readonly value: number;
  readonly x: number;
}) => {
  const { xScale, yScale } = useContext(DataContext);

  if (!xScale || !yScale) return null;

  const yValue = yScale ? yScale(value) : 0;
  const y = typeof yValue === `number` ? yValue : 0;

  return <GlyphDot cy={y} cx={x} r={3} fill={chakraColor(`skyBlue.1100`)} />;
};

export const H50Chart = ({
  range,
  data,
  loading,
}: {
  readonly range?: Hiive50IndexPriceDateRange;
  readonly data: readonly Hiive50IndexPrice[];
  readonly loading: boolean;
}) => {
  const [xPosition, setXPosition] = useState<number>(0);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [grey50, grey500] = useColors([`grey.50`, `grey.500`]);

  const isMobileView = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    { ssr: false },
  );

  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal(
    {
      scroll: true,
      detectBounds: true,
    },
  );

  const showYearInXTick = isDateNotInPresentYear(data[0]?.priceDay);

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<Hiive50IndexPrice | null>({
    // initial tooltip state
    tooltipOpen: false,
    tooltipLeft: 0,
    tooltipTop: 0,
    tooltipData: null,
  });

  const chartRange = getRange(data);

  const xAccessor = useCallback(
    (d: Hiive50IndexPrice) => new Date(d.priceDay),
    [],
  );

  const handleTooltip = useTooltipHandler<Hiive50IndexPrice>({
    tooltipRef,
    containerBounds,
    showTooltip,
    setXPosition,
  });

  return (
    <ParentSize>
      {({ width, height }) =>
        loading ? (
          <Box boxSize="full" pr={4}>
            <SkeletonChart />
          </Box>
        ) : (
          <Box ref={containerRef}>
            <DataProvider
              xScale={{ type: `time`, nice: false }}
              yScale={{
                type: `linear`,
                domain: chartRange,
                zero: false,
                nice: true,
              }}
            >
              <XYChart
                height={height}
                width={width}
                margin={margin}
                onPointerMove={handleTooltip}
                onPointerDown={handleTooltip}
                onPointerOut={() => (isMobileView ? null : hideTooltip())}
                pointerEventsDataKey="all" // no events triggered without this
              >
                <Grid numTicks={6} lineStyle={{ stroke: grey50 }} />
                <Axis
                  orientation="bottom"
                  numTicks={
                    range === Hiive50IndexPriceDateRange.ThreeMonths ? 3 : 6
                  }
                  stroke={grey50}
                  hideTicks
                  tickFormat={(value) => formatDateTick(value, showYearInXTick)}
                  tickLabelProps={() => ({
                    fontSize: isMobileView ? 8 : 10,
                    fill: grey500,
                    textAnchor: `middle`,
                  })}
                />
                <Axis
                  orientation="right"
                  numTicks={6}
                  stroke={grey50}
                  hideTicks
                  tickLabelProps={() => ({
                    fontSize: isMobileView ? 8 : 10,
                    fill: grey500,
                  })}
                />
                <AreaGradient id="area-gradient" />
                <AreaSeries
                  // eslint-disable-next-line functional/prefer-readonly-type
                  data={data as Hiive50IndexPrice[]}
                  dataKey="avg"
                  xAccessor={xAccessor}
                  yAccessor={(d) => d.avg}
                  curve={curveLinear}
                  fill="url(#area-gradient)"
                  lineProps={{
                    stroke: chakraColor(`skyBlue.1100`),
                    strokeWidth: 2,
                  }}
                />
                {tooltipOpen && (
                  <>
                    <TooltipCrosshair
                      fromX={xPosition}
                      fromY={margin.top}
                      toX={xPosition}
                      toY={height - margin.bottom}
                    />
                    {tooltipData && (
                      <TooltipGlyph value={tooltipData.avg} x={xPosition} />
                    )}
                    <TooltipInPortal
                      left={tooltipLeft}
                      top={tooltipTop}
                      style={{
                        ...defaultStyles,
                        backgroundColor: `rgba(255,255,255, 0.85)`,
                      }}
                    >
                      <Box ref={tooltipRef}>
                        {tooltipData && (
                          <VStack alignItems="start" spacing={0.5}>
                            <Text textStyle="heading-lg">
                              {toFixedDecimal(2, tooltipData.avg)}
                            </Text>
                            <Text color="grey.800" textStyle="text-xs">
                              {dayjs(tooltipData.priceDay).format(
                                `Do MMMM, YYYY`,
                              )}
                            </Text>
                          </VStack>
                        )}
                      </Box>
                    </TooltipInPortal>
                  </>
                )}
              </XYChart>
            </DataProvider>
          </Box>
        )
      }
    </ParentSize>
  );
};
