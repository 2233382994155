import { SensitiveText } from "@/components/common";
import { lot } from "@/utils";

interface ActivityCardPricingTotalProps {
  readonly numShares: number;
  readonly price: number;
  /** Whether to obscure the number values */
  readonly isSensitive?: boolean;
}

const ActivityCardPricingTotal = ({
  numShares,
  price,
  isSensitive = true,
}: ActivityCardPricingTotalProps) => (
  <SensitiveText isSensitive={isSensitive}>
    {lot(numShares, price)}
  </SensitiveText>
);

export default ActivityCardPricingTotal;
