import { ArrowUp } from "@phosphor-icons/react";
import useEventListener from "@use-it/event-listener";
import { useState } from "react";

import { Flex } from "@chakra-ui/react";

const BackToTop = () => {
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);

  useEventListener(`scroll`, () => {
    if (window.scrollY >= 500) return setShowBackToTop(true);
    return setShowBackToTop(false);
  });

  const handleClick = () => window.scrollTo({ top: 0, behavior: `smooth` });

  return showBackToTop ? (
    <Flex
      cursor="pointer"
      pos="fixed"
      zIndex="popover"
      justify="center"
      align="center"
      borderRadius="full"
      p={2.5}
      bottom={8}
      right={7}
      onClick={handleClick}
      bg="grey.900"
      opacity={0.3}
      _hover={{
        opacity: 0.7,
      }}
    >
      <ArrowUp size={32} color="white" />
    </Flex>
  ) : null;
};

export default BackToTop;
