import { Text } from "@chakra-ui/react";

const DefaultBuyerRoundedSharesTooltip = () => (
  <Text>
    The number of shares in listings are rounded to help protect the identity of
    Hiive users. You will see displayed here the actual number of shares you
    have agreed to purchase along with the number of shares in your original
    bid.
  </Text>
);

export default DefaultBuyerRoundedSharesTooltip;
