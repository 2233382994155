import { FormikValues } from "formik";
import { SetStateAction } from "react";
import { useTranslation, Trans } from "react-i18next";
import { match } from "ts-pattern";

import {
  Box,
  Button,
  CardBody,
  CardFooter,
  HStack,
  Text,
} from "@chakra-ui/react";

import {
  AccreditationInstitutionUSModifyFields,
  AccreditationModifyFields,
} from "@/components/form";
import { InvestorStatus, UserWithInstitutionFragment } from "@/gql";
import { getUserCountry, getIsInstitutionUser } from "@/utils";

import AccreditationCardModifyForm from "./AccreditationCardModifyForm";

const AccreditationCardModifyContentBody = ({
  values,
  setValues,
  isInstitutionUser,
  country,
  actor,
}: {
  readonly values: FormikValues;
  readonly setValues: (values: SetStateAction<FormikValues>) => void;
  readonly isInstitutionUser: boolean;
  readonly country: string;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();

  return (
    <CardBody p={{ base: 4, lg: 10 }}>
      <Box maxW={740}>
        {match({
          isInstitutionUser,
          country,
        })
          .with(
            {
              country: `CA`,
              isInstitutionUser: false,
            },
            () => (
              <>
                <Text textStyle="heading-lg" mb={6}>
                  {t(`must_be_accredited`)}
                </Text>
                <AccreditationModifyFields
                  investorType={InvestorStatus.Individual}
                  countryId={actor.country?.id ?? ``}
                  resolver={(values) => values.slice(0, -1)}
                  version={1}
                />
                <Text textStyle="text-md" mt={8}>
                  <Trans
                    i18nKey="no_longer_qualify"
                    components={{ bold: <strong /> }}
                  />
                </Text>
              </>
            ),
          )
          .with(
            {
              country: `US`,
              isInstitutionUser: false,
            },
            () => (
              <>
                <Text textStyle="heading-lg" mb={6}>
                  {t(`must_be_accredited`)}
                </Text>
                <AccreditationModifyFields
                  investorType={InvestorStatus.Individual}
                  countryId={actor.country?.id ?? ``}
                  resolver={(values) => values.slice(0, -1)}
                  version={2}
                />
                <Text textStyle="text-md" mt={8}>
                  <Trans
                    i18nKey="no_longer_qualify"
                    components={{ bold: <strong /> }}
                  />
                </Text>
              </>
            ),
          )
          .with(
            {
              country: `CA`,
              isInstitutionUser: true,
            },
            () => (
              <>
                <Text textStyle="heading-lg" mb={6}>
                  {t(`firm_fund_must_be_accredited_and_how`)}
                </Text>
                <AccreditationModifyFields
                  investorType={InvestorStatus.Institutional}
                  countryId={actor.institution?.country.id ?? ``}
                  resolver={(values) => values.slice(0, -2)}
                  version={2}
                />
                <Text textStyle="text-md" mt={8}>
                  <Trans
                    i18nKey="inst_no_longer_qualify"
                    components={{ bold: <strong /> }}
                    values={{ company: actor.institution?.legalName }}
                  />
                </Text>
              </>
            ),
          )
          .with(
            {
              country: `US`,
              isInstitutionUser: true,
            },
            () => (
              <>
                <AccreditationInstitutionUSModifyFields
                  answer={values.answer}
                  setValues={setValues}
                  countryId={actor.institution?.country.id ?? ``}
                />
                <Text textStyle="text-md" mt={8}>
                  <Trans
                    i18nKey="inst_no_longer_qualify"
                    components={{ bold: <strong /> }}
                    values={{ company: actor.institution?.legalName }}
                  />
                </Text>
              </>
            ),
          )
          .otherwise(() => null)}
      </Box>
    </CardBody>
  );
};

const AccreditationCardModifyContent = ({
  actor,
  onClose,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly onClose: () => void;
}) => {
  const { t } = useTranslation();

  const { institution: institutionUser } = actor;

  const isInstitutionUser = getIsInstitutionUser(actor);
  const country = getUserCountry(actor);

  const [accreditationAnswerInstitution] =
    actor.institution?.accreditationAnswers ?? [];
  const [accreditationAnswer] = actor.accreditationAnswers ?? [];

  const initialAnswer = institutionUser
    ? accreditationAnswerInstitution?.question?.id
    : accreditationAnswer?.question?.id;

  return (
    <AccreditationCardModifyForm
      onSuccess={onClose}
      initialValues={{
        answer: initialAnswer || ``,
      }}
    >
      {({ values, setValues, initialValues }) => (
        <>
          <AccreditationCardModifyContentBody
            values={values}
            setValues={setValues}
            isInstitutionUser={isInstitutionUser}
            country={country.name}
            actor={actor}
          />
          <CardFooter h={113}>
            <HStack gap={4} flexDirection="row-reverse" w="full">
              <Button
                type="submit"
                variant="rounded-solid-salmon"
                size="xl"
                w={{ base: `full`, lg: 180 }}
                minW="auto"
                disabled={values.answer === initialValues.answer}
              >
                {t(`modify`)}
              </Button>
              <Button
                variant="rounded-outline-grey"
                size="xl"
                onClick={onClose}
                w={{ base: `full`, lg: 180 }}
                minW="auto"
              >
                {t(`cancel`)}
              </Button>
            </HStack>
          </CardFooter>
        </>
      )}
    </AccreditationCardModifyForm>
  );
};

export default AccreditationCardModifyContent;
