import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Spacer,
  VStack,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

export const DashboardWatchlistSkeleton = () => (
  <Card w="full">
    <CardHeader py={4}>
      <HStack>
        <Skeleton h={4} maxW="40%" />
        <Spacer flexGrow={1} />
        <Skeleton h={10} maxW="35%" />
      </HStack>
    </CardHeader>
    <CardBody>
      <VStack spacing={4}>
        <Skeleton h="158px" count={3} />
      </VStack>
    </CardBody>
  </Card>
);
