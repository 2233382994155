import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Card, CardBody, Flex, VStack, Text } from "@chakra-ui/react";

import { PASSWORD_REGEX } from "@/components/auth";
import { SlideAnimation } from "@/components/auth-v2";
import { HiiveButton } from "@/components/common";
import { FormPasswordInput } from "@/components/react-hook-form";
import { useResetPasswordMutation } from "@/gql";
import { useFormQL } from "@/hooks/react-hook-form";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(`Password is required`)
    .matches(
      PASSWORD_REGEX,
      `Must contain at least 8 characters, one uppercase, one lowercase, one number or punctuation character`,
    ),
  passwordConfirmation: Yup.string()
    .required(`Password confirmation is required`)
    .oneOf([Yup.ref(`password`)], `Passwords must match`),
});

interface ResetPasswordFormValues {
  readonly password: string;
  readonly passwordConfirmation: string;
}

const initialValues: ResetPasswordFormValues = {
  password: ``,
  passwordConfirmation: ``,
};

const mapVariables = (token: string) => (values: ResetPasswordFormValues) => ({
  input: {
    ...values,
    token,
  },
});

const ResetPasswordForm = ({
  token,
  onSuccess,
}: {
  readonly token: string;
  readonly onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const mutation = useResetPasswordMutation();

  const { handleSubmit, isLoading, control } = useFormQL({
    mutation,
    mapVariables: mapVariables(token),
    initialValues,
    validationSchema,
    onSuccess,
  });

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Flex direction="column" gap={8}>
        <SlideAnimation>
          <Card>
            <CardBody p={{ base: 6, md: 7 }}>
              <VStack spacing={6}>
                <VStack spacing={1}>
                  <FormPasswordInput
                    name="password"
                    label={t(`password`)}
                    placeholder={t(`reset_password_placeholder`)}
                    control={control}
                    isRequired
                    autoFocus
                  />
                  <Text textStyle="text-sm">{t(`signup_password_policy`)}</Text>
                </VStack>
                <FormPasswordInput
                  name="passwordConfirmation"
                  label={t(`reset_password_confirm_password_placeholder`)}
                  placeholder={t(`reset_password_confirm_password_placeholder`)}
                  control={control}
                  labelSrOnly
                  isRequired
                />
              </VStack>
            </CardBody>
          </Card>
        </SlideAnimation>
        <Flex direction="column" alignItems="flex-end" w="full">
          <HiiveButton
            sentryLabel="[ResetPasswordPage/Submit]"
            variant="rounded-solid-salmon"
            type="submit"
            size="xl"
            w={{ base: `full`, sm: `auto` }}
            maxW="auto"
            isLoading={isLoading}
          >
            {t(`reset_password`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </form>
  );
};

export default ResetPasswordForm;
