import { RadioProps, Radio } from "@chakra-ui/react";

import styles from "./CustomRadio.module.css";

const CustomRadio = ({ children, ...props }: RadioProps) => (
  <Radio
    size="lg"
    className={styles.radio}
    alignItems="flex-start"
    spacing={3}
    cursor="pointer"
    borderColor="grey.200"
    data-testid={props.value}
    borderWidth={2}
    textStyle="text-md"
    transition=".1s all"
    _hover={{
      borderColor: `grey.100`,
      _checked: {
        borderColor: `grey.900`,
      },
    }}
    _invalid={{
      borderColor: `red`,
    }}
    _checked={{
      bg: `grey.900`,
      borderColor: `grey.900`,
      color: `white`,
    }}
    _before={{
      content: `""`,
      display: `inline-block`,
      position: `relative`,
      width: `50%`,
      height: `50%`,
      borderRadius: `50%`,
      background: `currentColor`,
    }}
    {...props}
  >
    {children}
  </Radio>
);

export default CustomRadio;
