import { WithQuery } from "@/components/common";
import {
  PostListingHeroBanner,
  PreListingHeroBanner,
  HeroBannerSkeleton,
} from "@/components/dashboard";
import { usePostActivityUnaccreditedSellerDashboardPageHeroBannerQuery } from "@/gql";

export const HeroBanner = () => {
  const query = usePostActivityUnaccreditedSellerDashboardPageHeroBannerQuery();

  return (
    <WithQuery query={query} fallback={<HeroBannerSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myListing },
        },
      }) =>
        !!myListing ? <PostListingHeroBanner /> : <PreListingHeroBanner />
      }
    </WithQuery>
  );
};
