import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";

import { HoldingDetailsForm } from "./HoldingDetailsForm";

const HoldingDetailsPage = () => {
  const { t } = useTranslation();

  return (
    <OnboardingContainerV2 metaTitle={t(`holding_details`)}>
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text textStyle="heading-3xl">{t(`holding_details`)} *</Text>
          <Text>{t(`tell_us_about_stock_or_options`)}</Text>
          <Text as="strong" textStyle="heading-xs">
            {t(`you_can_add_up_to_max_holdings`)}
          </Text>
        </VStack>

        <HoldingDetailsForm />
      </Flex>
    </OnboardingContainerV2>
  );
};

export default HoldingDetailsPage;
