import { ReactNode, useState } from "react";

import { HiiveButton, HiiveButtonProps } from "@/components/common";

import AlertDialog from "./AlertDialog";

interface ButtonWithConfirmationProps
  extends Omit<HiiveButtonProps, "onClick"> {
  /** The button content */
  readonly children?: ReactNode;
  /** The header of the displayed alert */
  readonly confirmationTitle: string;
  /** The body of the displayed alert */
  readonly confirmationBody: string | JSX.Element;
  /** The function called on "Confirm" click in the alert */
  readonly onConfirm: () => void;
  /** Whether the confirm button should display a loading state */
  readonly isLoading?: boolean;
}

const ButtonWithConfirmation = ({
  children,
  confirmationTitle,
  confirmationBody,
  onConfirm,
  isLoading,
  ...buttonProps
}: ButtonWithConfirmationProps) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  return (
    <>
      <AlertDialog
        title={confirmationTitle}
        body={confirmationBody}
        onClose={() => setIsConfirmationOpen(false)}
        isOpen={isConfirmationOpen}
        onConfirm={onConfirm}
        isLoading={isLoading}
      />
      <HiiveButton onClick={() => setIsConfirmationOpen(true)} {...buttonProps}>
        {children}
      </HiiveButton>
    </>
  );
};

export default ButtonWithConfirmation;
