import { useTranslation } from "react-i18next";

import { Card, CardBody, CardFooter, Text, Wrap } from "@chakra-ui/react";

import { ComplianceReminder, HiiveButton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { InvestorType, UserWithInstitutionFragment } from "@/gql";
import { useModal } from "@/hooks";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import {
  getAvailableUserPermissions,
  hasInvestorType,
  getIsInstitutionUser,
  getIsBroker,
  getIsInstitutionViewer,
} from "@/utils";

const UserDashboardActionsCard = ({
  isTrader,
  canCreateListing,
  canPlaceStandingBid,
  isActorInstitutionUser,
  isLegacySeller,
}: {
  readonly isTrader: boolean;
  readonly canCreateListing: boolean;
  readonly canPlaceStandingBid: boolean;
  readonly isActorInstitutionUser: boolean;
  readonly isLegacySeller: boolean;
}) => {
  const { modals, onOpenModal } = useModal();

  const showComplianceReminder =
    !canCreateListing || (!canPlaceStandingBid && !isLegacySeller);
  // remove legacy seller check when all are off platform

  return (
    <Card variant="darkened-footer" w="full">
      <CardBody>
        <Text textTransform="uppercase" textStyle="heading-sm" mb={6}>
          Trade on Hiive
        </Text>
        <Wrap w="full" gap={2}>
          <HiiveButton
            onClick={onOpenModal(modals.createListing())}
            variant="rounded-solid-salmon"
            size="xl"
            maxW="unset"
            flex="1"
            sentryLabel="[DashboardActions] List for Sale"
            isDisabled={!canCreateListing}
          >
            List for Sale
          </HiiveButton>
          {(isTrader || isActorInstitutionUser) && (
            <HiiveButton
              onClick={onOpenModal(modals.placeStandingBid())}
              variant="rounded-solid-salmon"
              size="xl"
              maxW="unset"
              flex="1"
              sentryLabel="[DashboardActions] Place Bid"
              isDisabled={!canPlaceStandingBid}
            >
              Place Bid
            </HiiveButton>
          )}
        </Wrap>
      </CardBody>
      {showComplianceReminder ? (
        <CardFooter bg="grey.25">
          <ComplianceReminder />
        </CardFooter>
      ) : null}
    </Card>
  );
};

const BrokerDashboardActionsCard = () => {
  const { modals, onOpenModal } = useModal();
  const sellSideBrokerPortalFeatureEnabled = useSellSideBrokerPortalEnabled();
  const { t } = useTranslation();

  return (
    <Card variant="darkened-footer" w="full">
      <CardBody>
        <Text textTransform="uppercase" textStyle="heading-sm" mb={6}>
          Submit an order to hiive
        </Text>
        <Wrap w="full" gap={2}>
          <HiiveButton
            onClick={onOpenModal(modals.placeStandingBid())}
            variant="rounded-solid-salmon"
            size="xl"
            maxW="unset"
            flex="1"
            sentryLabel="[DashboardActions] Place Bid"
          >
            Submit a Bid
          </HiiveButton>
          {sellSideBrokerPortalFeatureEnabled && (
            <HiiveButton
              onClick={onOpenModal(modals.brokerSubmitListing())}
              variant="rounded-solid-salmon"
              size="xl"
              maxW="unset"
              flex="1"
              sentryLabel="[DashboardActions] List for Sale"
            >
              {t(`submit_a_listing`)}
            </HiiveButton>
          )}
        </Wrap>
      </CardBody>
    </Card>
  );
};

export const DashboardActions = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const isInstitutionViewer = getIsInstitutionViewer(actor);
    const {
      canCreateListing,
      canPlaceStandingBid,
    } = getAvailableUserPermissions(actor);

    const isLegacySeller = actor.investorType === InvestorType.Seller;

    if (isInstitutionViewer) {
      return null;
    }

    if (getIsBroker(actor)) {
      return <BrokerDashboardActionsCard />;
    }

    return (
      <UserDashboardActionsCard
        isTrader={hasInvestorType(actor, InvestorType.Trader)}
        canCreateListing={canCreateListing}
        canPlaceStandingBid={canPlaceStandingBid}
        isActorInstitutionUser={getIsInstitutionUser(actor)}
        isLegacySeller={isLegacySeller}
      />
    );
  },
);
