import { Text } from "@chakra-ui/react";

import { ObscureText } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  ActivityCardMostRecentMessageDiscussionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { getIsSensitiveMessage } from "@/utils";

const ActivityCardMostRecentMessage = withCurrentActor(
  ({
    discussion,
    actor,
  }: {
    readonly discussion: ActivityCardMostRecentMessageDiscussionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    if (!discussion.mostRecentMessage) return null;

    const isSensitiveMessage = getIsSensitiveMessage(
      actor,
      discussion.mostRecentMessage,
      discussion,
    );

    return (
      <>
        <ObscureText obscure={isSensitiveMessage} textStyle="text-sm" mb={1}>
          {discussion.mostRecentMessage.messageBody}
        </ObscureText>
        <Text textStyle="heading-xs">Reply</Text>
      </>
    );
  },
);

export default ActivityCardMostRecentMessage;
