import { BoxProps } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { useCurrentMembershipAgreementQuery } from "@/gql";

import { PandadocDocFrame } from "./PandadocDocFrame";

const SignedCustomerAgreement = ({ ...boxProps }: BoxProps) => {
  const query = useCurrentMembershipAgreementQuery();
  return (
    <WithQuery hideLoadingSpinner query={query}>
      {({
        data: { currentMembershipAgreement },
      }: {
        readonly data: { readonly currentMembershipAgreement: string };
      }) => (
        <PandadocDocFrame
          title="Customer Agreement Embed"
          url={currentMembershipAgreement}
          border="grey-border"
          w={{ base: `100%`, md: `900px` }}
          h="754px"
          {...boxProps}
        />
      )}
    </WithQuery>
  );
};

export default SignedCustomerAgreement;
