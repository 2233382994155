import * as CountryList from "country-list";

CountryList.overwrite([
  {
    code: `UK`,
    name: `United Kingdom`,
  },
  {
    code: `VN`,
    name: `Vietnam`,
  },
  {
    code: `XK`,
    name: `Kosovo`,
  },
]);

const useCountryList = () => CountryList;

export default useCountryList;
