import { Box, SimpleGrid, Text, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

const StandingBidFeeBreakdownSkeleton = () => (
  <Box borderColor="grey.200" borderWidth={0.5} borderRadius="md">
    <SimpleGrid
      display="grid"
      gridTemplateColumns="1fr minmax(85px, min-content)"
      gap={4}
      alignItems="baseline"
      px={6}
      py={6}
    >
      <Skeleton h="18px" w="128px" />
      <Skeleton h="18px" w="80px" />
      <Skeleton h="18px" w="148px" />
      <Skeleton h="18px" w="80px" />
      <Skeleton h="18px" w="128px" />
      <Skeleton h="18px" w="80px" />
      <Skeleton h="18px" w="128px" />
      <Skeleton h="18px" w="80px" />
    </SimpleGrid>
    <SimpleGrid
      bg="grey.25"
      display="grid"
      alignItems="baseline"
      borderBottomRadius="md"
      borderTopWidth={0.5}
      borderTopColor="grey.200"
      gridTemplateColumns="1fr min-content"
      px={7}
      py="18px"
    >
      <Skeleton h="18px" w="80px" py={2} />
      <Text whiteSpace="nowrap" textAlign="right">
        <Skeleton h="18px" w="80px" py={2} />
      </Text>
    </SimpleGrid>

    <Box p={4} borderTopColor="grey.200" borderTopWidth={0.5}>
      <VStack alignItems="left">
        <Skeleton h="12px" />
        <Skeleton h="12px" />
      </VStack>
    </Box>
  </Box>
);

export default StandingBidFeeBreakdownSkeleton;
