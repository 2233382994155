import currency from "currency.js";
import dayjs from "dayjs";
import { Form } from "formik";
import { AnimatePresence } from "framer-motion";

import { AnimatedModalStep, StepFormikQL } from "@/components/form";
import {
  CompanyWatchlistActorDocument,
  MarketActivityDocument,
  PlaceStandingBidSelectCompanyInputCompanyFragment,
  PlaceStandingBidMutationVariables,
  StandingBidAcceptsTransferMethodsOptions,
  usePlaceStandingBidMutation,
  UserActivityMyActivityDocument,
} from "@/gql";
import { useStepRouter } from "@/hooks";
import { constants, Nullable } from "@/utils";
import * as datetime from "@/utils/datetime";

import {
  PlaceStandingBidSequenceModalFormValues,
  PlaceStandingBidModal,
  StepKeys,
  stepKeys,
} from "./steps";
import { PlaceStandingBidSequenceModalStepFormContext } from "./steps/PlaceStandingBidSequenceModalStepFormContext";
import PlaceStandingBidSuccessModal from "./steps/PlaceStandingBidSuccessModal";

const createInitialValues = (
  initialCompany?: PlaceStandingBidSelectCompanyInputCompanyFragment,
  initialValues?: Partial<PlaceStandingBidSequenceModalFormValues>,
): Nullable<PlaceStandingBidSequenceModalFormValues> => ({
  affiliate: null,
  company: initialCompany || null,
  numShares: null,
  acceptsShares: null,
  minPartialAcceptNumShares: null,
  allowPartialAccept: true,
  acceptsTransferMethods: [StandingBidAcceptsTransferMethodsOptions.Direct],
  pricePerShare: null,
  otherDetails: ``,
  expireAt: datetime
    .add(`day`, constants.default_standing_bid_expire_after_days, dayjs())
    .toDate(),
  confirmed: false,
  muteNotifyWatchers: false,
  representedEmail: ``,
  representedFirstName: ``,
  representedLastName: ``,
  notifyRepresentingEmail: true,
  solicited: null,
  ...initialValues,
});

const mapVariables = ({
  confirmed: _confirmed,
  company,
  expireAt,
  pricePerShare,
  representedEmail,
  representedFirstName,
  representedLastName,
  ...values
}: PlaceStandingBidSequenceModalFormValues): PlaceStandingBidMutationVariables => ({
  input: {
    ...values,
    companyId: company?.id || ``,
    expireAt: dayjs(expireAt).endOf(`day`).format(),
    pricePerShare: currency(pricePerShare).intValue,
    representedUser: {
      email: representedEmail,
      firstName: representedFirstName,
      lastName: representedLastName,
    },
  },
});

interface PlaceStandingBidSequenceModalProps {
  readonly initialCompany?: PlaceStandingBidSelectCompanyInputCompanyFragment;
  readonly initialValues?: Partial<PlaceStandingBidSequenceModalFormValues>;
}

export const PlaceStandingBidSequenceModalContent = ({
  initialCompany,
  initialValues,
}: PlaceStandingBidSequenceModalProps) => {
  const mutation = usePlaceStandingBidMutation({
    refetchQueries: [
      MarketActivityDocument,
      UserActivityMyActivityDocument,
      CompanyWatchlistActorDocument,
    ],
  });

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.placeStandingBidBidDetails,
      stepKeys.placeStandingBidAdditionalDetails,
      stepKeys.placeStandingBidSuccess,
    ],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  const _initialValues = createInitialValues(initialCompany, initialValues);

  const placeStandingBidModalStepKeys: readonly StepKeys[] = [
    stepKeys.placeStandingBidBidDetails,
    stepKeys.placeStandingBidAdditionalDetails,
  ];

  return (
    <StepFormikQL
      stepRouter={stepRouter}
      mutation={mutation}
      mutationNames={[`placeStandingBid`]}
      initialValues={_initialValues}
      mapVariables={mapVariables}
      context={PlaceStandingBidSequenceModalStepFormContext}
    >
      {({ formikProps }) => (
        <Form>
          <AnimatePresence mode="wait" initial={false}>
            {placeStandingBidModalStepKeys.includes(currentStepKey) && (
              <AnimatedModalStep key={placeStandingBidModalStepKeys.join()}>
                <PlaceStandingBidModal
                  stepRouter={stepRouter}
                  initialCompany={initialCompany}
                  {...formikProps}
                />
              </AnimatedModalStep>
            )}
            {stepKeys.placeStandingBidSuccess === currentStepKey && (
              <AnimatedModalStep key={stepKeys.placeStandingBidSuccess}>
                <PlaceStandingBidSuccessModal
                  stepRouter={stepRouter}
                  {...formikProps}
                />
              </AnimatedModalStep>
            )}
          </AnimatePresence>
        </Form>
      )}
    </StepFormikQL>
  );
};
