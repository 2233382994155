import { AnimatePresence } from "framer-motion";

import { Box, Circle, Text, VStack } from "@chakra-ui/react";

import FadeInOut from "./FadeInOut";

const formatCount = (count: number) => (count > 9 ? `9+` : count.toString());

const TotalCount = ({
  hasNewNotifications = false,
  totalCount,
}: {
  readonly hasNewNotifications?: boolean;
  readonly totalCount: number;
}) => {
  const formattedTotalCount = formatCount(totalCount);

  return (
    <Box position="relative">
      <VStack
        w="5"
        h="5"
        bg="grey.75"
        rounded="full"
        borderColor="grey.200"
        borderWidth="0.5px"
        justifyContent="center"
      >
        <AnimatePresence mode="wait" initial={false}>
          <FadeInOut key={formattedTotalCount}>
            <Text
              lineHeight="5"
              color="grey.900"
              fontWeight={hasNewNotifications ? `medium` : `normal`}
              textStyle="heading-3xs"
            >
              {formattedTotalCount}
            </Text>
          </FadeInOut>
        </AnimatePresence>
      </VStack>
      <AnimatePresence mode="wait">
        {hasNewNotifications && (
          <FadeInOut>
            <Circle
              bg="salmon.900"
              pos="absolute"
              size={2.5}
              top="0"
              transform="translate(40%, -40%)"
              right="0"
            />
          </FadeInOut>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default TotalCount;
