import { Warning } from "@phosphor-icons/react";
import { useController } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Box, Flex, Text, VStack } from "@chakra-ui/react";

import { CallOut, HiiveButton, InternalLink } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import { FormRadioTile } from "@/components/react-hook-form";
import {
  AccreditationQuestion,
  CurrentContextDocument,
  InvestorStatus,
  InvestorType,
  useCurrentAccreditationQuestionGroupQuery,
  useTransitionCurrentStepMutation,
  useUpdateIndividualAccreditationAnswerMutation,
  useUpdateInstitutionAccreditationAnswerMutation,
  useUpdateUserMutation,
} from "@/gql";
import { useColors, useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { shadows } from "@/styles/theme";

interface InvestorAccreditationFormValues {
  readonly questionId?: string | null;
}

const initialValues = { questionId: null };

export const InvestorAccreditationForm = () => {
  const actor = useCurrentActor();
  const investorStatus = actor.investorStatus!;
  const [updateUserMutation] = useUpdateUserMutation({
    refetchQueries: [CurrentContextDocument],
  });
  const individualMutation = useUpdateIndividualAccreditationAnswerMutation({
    refetchQueries: [CurrentContextDocument],
  });
  // TODO: check once institution flow is working (MKT-1212)
  const institutionMutation = useUpdateInstitutionAccreditationAnswerMutation();
  const mutation =
    investorStatus === InvestorStatus.Individual
      ? individualMutation
      : institutionMutation;
  const { t } = useTranslation();
  const [
    transitionCurrentStepMutation,
    isTransitioningCurrentStep,
  ] = useMutationWithError(
    useTransitionCurrentStepMutation(),
    `transitionCurrentStep`,
  );

  const [yellow600] = useColors([`yellow.600`]);

  const { data } = useCurrentAccreditationQuestionGroupQuery({
    variables: {
      investorType: investorStatus,
      countryId: actor.country?.id || ``,
    },
  });

  const accQuestionGroup = data?.currentAccreditationQuestionGroup;
  const accQuestions = accQuestionGroup?.questions || [];
  const unaccreditedQuestion = accQuestions.find(
    (question: AccreditationQuestion) => !question.accredited,
  );

  const validationSchema = Yup.object().shape({
    questionId: Yup.string()
      .oneOf(accQuestions.map((question: AccreditationQuestion) => question.id))
      .nullable()
      .required(t`required`),
  });

  const mapVariables = ({ questionId }: InvestorAccreditationFormValues) => ({
    input: { questionId },
  });

  const onSuccess = () => {
    transitionCurrentStepMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { onboardingId: actor.onboardingV2?.id! },
    });
  };
  const { handleSubmit, isLoading, control, formState } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
  });

  const { field } = useController({
    name: `questionId`,
    control,
  });

  const shouldRenderUnaccreditedWarning =
    unaccreditedQuestion &&
    unaccreditedQuestion?.id &&
    field.value === unaccreditedQuestion.id;

  const onContinueAsSeller = async () => {
    await updateUserMutation({
      variables: { input: { investorType: InvestorType.UnaccreditedSeller } },
    });
    await transitionCurrentStepMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { onboardingId: actor.onboardingV2?.id! },
    });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <SlideAnimation>
        <FormRadioTile.Group
          size="md"
          bg="white"
          p={{ base: 4, lg: 6 }}
          borderRadius="md"
          borderColor="grey.200"
          borderWidth={0.5}
          boxShadow={shadows.card}
          gap={6}
          w={{ base: `full`, lg: 160 }}
        >
          <VStack gap={4} w="full">
            {accQuestions
              .toSorted(
                (a: AccreditationQuestion, b: AccreditationQuestion) => {
                  if (a.order < b.order) return -1;
                  if (a.order > b.order) return 1;
                  return 0;
                },
              )
              .map((question: AccreditationQuestion) => (
                <FormRadioTile.Tile
                  key={question.id}
                  name="questionId"
                  control={control}
                  value={question.id}
                >
                  <Text>{question.text}</Text>
                </FormRadioTile.Tile>
              ))}
          </VStack>
          {shouldRenderUnaccreditedWarning && (
            <CallOut variant="yellow">
              <Box
                as={Warning}
                size={20}
                color={yellow600}
                weight="fill"
                flex="none"
                alignSelf="flex-start"
              />
              <VStack gap={1}>
                <Text textStyle="text-md">
                  <Trans
                    i18nKey="investor_accreditation_disclaimer"
                    components={{
                      bold: (
                        <InternalLink
                          href=""
                          onClick={onContinueAsSeller}
                          style={{
                            fontWeight: 500,
                            textDecorationLine: `underline`,
                          }}
                        />
                      ),
                    }}
                  />
                </Text>
                <Text textStyle="text-md">{t`investor_accreditation_disclaimer_2`}</Text>
              </VStack>
            </CallOut>
          )}
        </FormRadioTile.Group>
      </SlideAnimation>

      <Flex w="full" justify="flex-end" mt={8}>
        <HiiveButton
          sentryLabel="[InvestorStatusPageV2/Submit]"
          variant="rounded-solid-salmon"
          type="submit"
          size="xl"
          maxW="unset"
          w={{ base: `full`, lg: `unset` }}
          isLoading={isLoading || isTransitioningCurrentStep}
          isDisabled={!formState.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </Flex>
    </form>
  );
};
