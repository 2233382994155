import { Center, Flex, Grid, Spinner } from "@chakra-ui/react";

import { BackToTop, LazyLoadObserver } from "@/components/common";
import {
  BrowseCompaniesPageCompaniesListCompanyFragment,
  PageInfo,
} from "@/gql";

import CompanyCard from "./CompanyCard";
import NoCompaniesFound from "./NoCompaniesFound";

const CompaniesList = ({
  companies,
  onFetchMoreCompanies,
  rootElement,
  pageInfo,
  isLoadingMore,
}: {
  readonly companies: readonly BrowseCompaniesPageCompaniesListCompanyFragment[];
  readonly onFetchMoreCompanies: () => void;
  readonly pageInfo: PageInfo;
  readonly rootElement: HTMLDivElement;
  readonly isLoadingMore: boolean;
}) => {
  const shouldPlaceObserver = (
    pageInfo: PageInfo,
    totalFetched: number,
    index: number,
  ) => {
    // position observer before the last company
    // is reached so that we start to fetch more
    // companies as the user is scrolling
    const positionBeforeLast = 10;

    return pageInfo.hasNextPage && index === totalFetched - positionBeforeLast;
  };

  return companies.length > 0 ? (
    <>
      <Grid
        w="full"
        margin="auto"
        templateColumns={{ base: `1fr`, md: `1fr 1fr`, lg: `1fr 1fr 1fr` }}
        gap={{ base: 4, lg: 5, xl: 9 }}
      >
        {companies.map((company, index: number) =>
          company ? (
            <Flex key={company.id}>
              <CompanyCard company={company} />

              {shouldPlaceObserver(pageInfo, companies.length, index) && (
                <LazyLoadObserver
                  triggerOnce
                  key={`observer-at-${company.id}`}
                  root={rootElement as Element}
                  onIntersection={onFetchMoreCompanies}
                  threshold={1.0}
                />
              )}
            </Flex>
          ) : null,
        )}
        <BackToTop />
      </Grid>

      {isLoadingMore && (
        <Center>
          <Spinner />
        </Center>
      )}
    </>
  ) : (
    <NoCompaniesFound />
  );
};

export default CompaniesList;
