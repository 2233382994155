const useIsHiiveConnect = () => {
  const { host, hash } = window.location;
  const connectSubdomains = (
    process.env.NEXT_PUBLIC_HIIVE_CONNECT_SUBDOMAINS || ``
  )
    .split(`,`)
    .filter((subdomain) => !!subdomain);

  return (
    connectSubdomains.some((connectSubdomain) =>
      host.startsWith(connectSubdomain),
    ) || hash === `#connect`
  );
};

export default useIsHiiveConnect;
