import xor from "lodash/xor";
import { Trans } from "react-i18next";

import { Card, CardBody, Flex, Grid, GridItem, Text } from "@chakra-ui/react";

import { HiiveButton, InternalLink } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { BidActionsBidFragment, UserWithInstitutionFragment } from "@/gql";
import { useModal } from "@/hooks";
import {
  getAvailableBidActions,
  getIsUnaccreditedSeller,
  getShortDocumentTitleByTransferMethod,
} from "@/utils";

type Action = {
  readonly ctaText: string;
  readonly onClick: () => void;
};

const BidActionCard = ({
  title,
  description,
  action: { onClick, ctaText },
}: {
  readonly title: string;
  readonly description: React.ReactNode;
  readonly action: Action;
}) => (
  <Card h="full">
    <CardBody py={7}>
      <Flex
        wrap="wrap"
        justify="center"
        alignContent="space-between"
        h="full"
        gap={{
          base: 4,
          sm: 5,
        }}
      >
        <Flex direction="column" gap={3} flex="1 1 60%">
          <Text
            w="full"
            as="h5"
            textTransform="uppercase"
            textStyle="heading-sm"
          >
            {title}
          </Text>
          <Text textStyle="text-normal">{description}</Text>
        </Flex>
        <HiiveButton
          my="auto"
          flex="1 1 200px"
          variant="rounded-solid-salmon"
          size="xl"
          onClick={onClick}
          sentryLabel="[BidActions] CTA"
        >
          {ctaText}
        </HiiveButton>
      </Flex>
    </CardBody>
  </Card>
);

const BidActionTOS = withCurrentActor(
  ({
    bid,
    isBuyer,
    onClick,
    actor,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly bid: BidActionsBidFragment;
    readonly isBuyer: boolean;
    readonly onClick: () => void;
  }) => {
    const documentTitle = getShortDocumentTitleByTransferMethod(
      bid.listing.transferMethod,
    );

    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    const getKey = () => {
      if (isBuyer) {
        return `accept_bid_buyer_terms_and_conditions`;
      }
      if (isUnaccreditedSeller) {
        return `accept_bid_seller_only_terms_and_conditions`;
      }
      return `accept_bid_seller_terms_and_conditions`;
    };

    return (
      <BidActionCard
        title="Accept Bid"
        description={
          <Trans
            i18nKey={getKey()}
            values={{ documentTitle }}
            components={{
              italic: (
                <InternalLink
                  target="_blank"
                  textDecorationLine="underline"
                  href="/terms-and-conditions"
                />
              ),
            }}
          />
        }
        action={{
          ctaText: `Accept Bid`,
          onClick,
        }}
      />
    );
  },
);

const BidActionCounterBid = ({
  description,
  actionCTAText,
  onClick,
}: {
  readonly description: string;
  readonly actionCTAText: string;
  readonly onClick: () => void;
}) => (
  <BidActionCard
    title="Counter Bid Offer"
    description={description}
    action={{
      ctaText: actionCTAText,
      onClick,
    }}
  />
);

const BidActions = ({
  bid,
  isBuyer,
}: {
  readonly bid: BidActionsBidFragment;
  readonly isBuyer: boolean;
}) => {
  const { modals, onOpenModal } = useModal();

  const {
    canAcceptBid,
    canCounterBid,
    canAcceptCounterBid,
    hasCounterBid,
  } = getAvailableBidActions(bid);

  const isLimitedToOneAction = xor([canAcceptBid], [canCounterBid]).length;

  if (!canAcceptBid && !canAcceptCounterBid && !canCounterBid) return null;

  return (
    <Grid
      templateColumns="repeat(auto-fit, minmax(18rem, 1fr))"
      gap={isLimitedToOneAction ? 0 : 4}
    >
      <GridItem>
        {(canAcceptBid || canAcceptCounterBid) && (
          <>
            {isBuyer && (
              <BidActionTOS
                bid={bid}
                isBuyer={isBuyer}
                onClick={onOpenModal(modals.acceptCounterBid(bid))}
              />
            )}
            {!isBuyer &&
              !hasCounterBid && ( // seller can accept original bid from original bid card, this removes CTA duplication
                <BidActionTOS
                  bid={bid}
                  isBuyer={isBuyer}
                  onClick={onOpenModal(modals.acceptBid(bid))}
                />
              )}
          </>
        )}
      </GridItem>

      {(canCounterBid || canAcceptCounterBid) && (
        <GridItem>
          {isBuyer ? (
            <BidActionCounterBid
              description="Make a counter offer to the seller to negotiate an alternative price."
              actionCTAText="Counter Seller's Offer"
              onClick={onOpenModal(
                modals.modifyBid(bid.listing.company, bid.listing, bid),
              )}
            />
          ) : (
            <BidActionCounterBid
              description="Make a counter offer to the buyer to negotiate an alternative price."
              actionCTAText="Counter Bid"
              onClick={onOpenModal(modals.counterBid(bid))}
            />
          )}
        </GridItem>
      )}
    </Grid>
  );
};

export default BidActions;
