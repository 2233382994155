import isNil from "lodash/isNil";

import { FeeBreakdownCommissionInfo } from "@/components/common";
import { getAreFeesHighForStandingBid } from "@/utils";

interface StandingBidFeeBreakdownProps {
  readonly companyId: string;
  readonly numShares: number | null;
  readonly pricePerShare: number | null;
}

const StandingBidFeeBreakdown = ({
  companyId,
  numShares,
  pricePerShare,
}: StandingBidFeeBreakdownProps) => {
  const isHighFeesWarningVisible =
    !isNil(numShares) &&
    !isNil(pricePerShare) &&
    getAreFeesHighForStandingBid({
      numberOfShares: numShares,
      pricePerShare: Math.round(pricePerShare),
    });

  return (
    <FeeBreakdownCommissionInfo
      numShares={numShares}
      pricePerShare={pricePerShare}
      companyId={companyId}
      disclaimerVariant="createListingSeller"
      isHighFeesWarningVisible={isHighFeesWarningVisible}
    />
  );
};

export default StandingBidFeeBreakdown;
