import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { TileHeader, Skeleton } from "@/components/common";
import { formatPricePerShare, formatShares, formatCurrency } from "@/utils";

const ListingBrokerTotalPriceBreakdown = ({
  isLoading,
  totalShares,
  pricePerShare,
  totalValue,
}: {
  readonly isLoading: boolean;
  readonly totalShares: number;
  readonly pricePerShare: number | null | undefined;
  readonly totalValue: string | number;
}) => {
  const { t } = useTranslation();
  const pricePerShareText = pricePerShare
    ? formatPricePerShare(pricePerShare * 100)
    : 0;
  const totalSharesText = formatShares(totalShares);

  const breakdownText = pricePerShare
    ? t(`broker_listing_share_price_summary`, {
        total_shares: totalSharesText,
        price_per_share: pricePerShareText,
      })
    : t(`broker_listing_share_price_summary_partial`, {
        total_shares: totalSharesText,
      });
  return (
    <>
      <TileHeader alignSelf="center">{breakdownText}</TileHeader>
      {isLoading ? (
        <Skeleton h={6} w={16} />
      ) : (
        <Text>
          {formatCurrency(totalValue, {
            precision: 2,
            symbol: `$`,
            fromCents: true,
          })}
        </Text>
      )}
    </>
  );
};

export default ListingBrokerTotalPriceBreakdown;
