import { CurrentActorContext } from "@/components/providers";
import { SuitabilityQuestionGroupsQuery } from "@/gql";
import { getIsInstitutionUser } from "@/utils";

export const getSuitabilityAnswersForActor = (actor: CurrentActorContext) => {
  const isInstitutionUser = getIsInstitutionUser(actor);
  return (
    (isInstitutionUser
      ? actor.institution?.suitabilityAnswers
      : actor.suitabilityAnswers) || []
  );
};

export const areSuitabilityAnswersCurrent = (
  actor: CurrentActorContext,
  questionSet: SuitabilityQuestionGroupsQuery["currentSuitabilityQuestionGroup"],
) => {
  const suitabilityAnswers = getSuitabilityAnswersForActor(actor);
  const currentAnsweredVersion =
    (suitabilityAnswers &&
      suitabilityAnswers[0]?.suitabilityOption?.suitabilityQuestion
        ?.suitabilityQuestionGroup?.version) ||
    0;

  const currentVersion = questionSet?.version;

  return currentVersion === currentAnsweredVersion;
};
