import { useField } from "formik";
import { ReactNode } from "react";

import {
  FormControl,
  FormErrorMessage,
  HStack,
  Switch,
} from "@chakra-ui/react";

import TooltipFormLabel from "./TooltipFormLabel";

interface FormSwitchProps {
  readonly colorScheme?: string;
  readonly name: string;
  readonly label?: string;
  readonly onChangeInput?: (value: boolean) => void;
  readonly size?: string;
  readonly tooltipContent?: ReactNode;
}

const FormSwitch = ({
  colorScheme = `salmonPink`,
  name,
  label,
  onChangeInput,
  size = `md`,
  tooltipContent,
  ...props
}: FormSwitchProps) => {
  const [field, { touched, error }, { setValue }] = useField(name);
  const isInvalid = (error && touched) || false;

  return (
    <FormControl id={name} isInvalid={isInvalid}>
      <HStack alignItems="center">
        <Switch
          colorScheme={colorScheme}
          isChecked={field.value}
          onChange={(e) => {
            setValue(e.target.checked);
            if (!!onChangeInput) onChangeInput(e.target.checked);
          }}
          size={size}
          value={field.value}
          {...props}
        />
        <TooltipFormLabel
          fontWeight="normal"
          htmlFor={name}
          pb={0}
          tooltipContent={tooltipContent}
        >
          {label}
        </TooltipFormLabel>
      </HStack>

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default FormSwitch;
