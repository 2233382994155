import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      /* Colfax Web Thin */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebThin/ColfaxWebThin.eot');
      src: url('/assets/fonts/ColfaxWebThin/ColfaxWebThin.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebThin/ColfaxWebThin.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebThin/ColfaxWebThin.woff') format('woff');
      font-weight: 200;
      font-style: normal;
      }

      /* Colfax Web Thin Italic */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebThinItalic/ColfaxWebThinItalic.eot');
      src: url('/assets/fonts/ColfaxWebThinItalic/ColfaxWebThinItalic.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebThinItalic/ColfaxWebThinItalic.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebThinItalic/ColfaxWebThinItalic.woff') format('woff');
      font-weight: 200;
      font-style: italic;
      }

      /* Colfax Web Light */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebLight/ColfaxWebLight.eot');
      src: url('/assets/fonts/ColfaxWebLight/ColfaxWebLight.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebLight/ColfaxWebLight.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebLight/ColfaxWebLight.woff') format('woff');
      font-weight: 300;
      font-style: normal;
      }

      /* Colfax Web Light Italic */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebLightItalic/ColfaxWebLightItalic.eot');
      src: url('/assets/fonts/ColfaxWebLightItalic/ColfaxWebLightItalic.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebLightItalic/ColfaxWebLightItalic.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebLightItalic/ColfaxWebLightItalic.woff') format('woff');
      font-weight: 300;
      font-style: italic;
      }

      /* Colfax Web Regular */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebRegular/ColfaxWebRegular.eot');
      src: url('/assets/fonts/ColfaxWebRegular/ColfaxWebRegular.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebRegular/ColfaxWebRegular.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebRegular/ColfaxWebRegular.woff') format('woff');
      font-weight: 400;
      font-style: normal;
      }

      /* Colfax Web Regular Italic */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebRegularItalic/ColfaxWebRegularItalic.eot');
      src: url('/assets/fonts/ColfaxWebRegularItalic/ColfaxWebRegularItalic.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebRegularItalic/ColfaxWebRegularItalic.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebRegularItalic/ColfaxWebRegularItalic.woff') format('woff');
      font-weight: 400;
      font-style: italic;
      }

      /* Colfax Web Medium */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebMedium/ColfaxWebMedium.eot');
      src: url('/assets/fonts/ColfaxWebMedium/ColfaxWebMedium.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebMedium/ColfaxWebMedium.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebMedium/ColfaxWebMedium.woff') format('woff');
      font-weight: 500;
      font-style: normal;
      }

      /* Colfax Web Medium Italic */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebMediumItalic/ColfaxWebMediumItalic.eot');
      src: url('/assets/fonts/ColfaxWebMediumItalic/ColfaxWebMediumItalic.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebMediumItalic/ColfaxWebMediumItalic.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebMediumItalic/ColfaxWebMediumItalic.woff') format('woff');
      font-weight: 500;
      font-style: italic;
      }

      /* Colfax Web Bold */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebBold/ColfaxWebBold.eot');
      src: url('/assets/fonts/ColfaxWebBold/ColfaxWebBold.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebBold/ColfaxWebBold.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebBold/ColfaxWebBold.woff') format('woff');
      font-weight: 700;
      font-style: normal;
      }

      /* Colfax Web Bold Italic */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebBoldItalic/ColfaxWebBoldItalic.eot');
      src: url('/assets/fonts/ColfaxWebBoldItalic/ColfaxWebBoldItalic.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebBoldItalic/ColfaxWebBoldItalic.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebBoldItalic/ColfaxWebBoldItalic.woff') format('woff');
      font-weight: 700;
      font-style: italic;
      }

      /* Colfax Web Black */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebBlack/ColfaxWebBlack.eot');
      src: url('/assets/fonts/ColfaxWebBlack/ColfaxWebBlack.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebBlack/ColfaxWebBlack.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebBlack/ColfaxWebBlack.woff') format('woff');
      font-weight: 900;
      font-style: normal;
      }

      /* Colfax Web Black Italic */
      @font-face {
      font-family: 'ColfaxWeb';
      src: url('/assets/fonts/ColfaxWebBlackItalic/ColfaxWebBlackItalic.eot');
      src: url('/assets/fonts/ColfaxWebBlackItalic/ColfaxWebBlackItalic.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/ColfaxWebBlackItalic/ColfaxWebBlackItalic.woff2') format('woff2'),
            url('/assets/fonts/ColfaxWebBlackItalic/ColfaxWebBlackItalic.woff') format('woff');
      font-weight: 900;
      font-style: italic;
      }

      @font-face {
        font-family: 'Plantin';
        src: url('/assets/fonts/PlantinMTProLight/PlantinMTProLight.woff') format('woff');
        font-weight: 300;
        font-style: normal;
      }
    `}
  />
);
export default Fonts;
