import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import { Input, InputProps } from "@chakra-ui/react";

type MoneyInputProps<TFieldValues extends FieldValues> = InputProps &
  ControllerRenderProps<TFieldValues, Path<TFieldValues>>;

export const MoneyInput = <TFieldValues extends FieldValues>({
  ...moneyInputProps
}: MoneyInputProps<TFieldValues>) => (
  <Input
    as={NumericFormat}
    prefix="$"
    thousandSeparator=","
    decimalSeparator="."
    {...moneyInputProps}
    onChange={(ev) => {
      // eslint-disable-next-line functional/immutable-data, no-param-reassign
      ev.target.value = ev.target.value.replace(`$`, ``).replace(`,`, ``);
      moneyInputProps.onChange(ev);
    }}
  />
);
