/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Form } from "formik";
import { AnimatePresence } from "framer-motion";

import { HiiveModalContentWrapper } from "@/components/common";
import { AnimatedModalStep, StepFormikQL } from "@/components/form";
import {
  useAcceptCounteredBidMutation,
  AcceptCounterBidModalBidFragment,
} from "@/gql";
import { useStepRouter } from "@/hooks";

import {
  AcceptCounterBidModal,
  AcceptCounterBidSequenceModalFormValues,
  AcceptCounterBidSequenceModalStepFormContext,
  AcceptCounterBidSuccessModal,
  stepKeys,
  StepKeys,
} from "./steps";

const mapVariables = ({ bidId }: AcceptCounterBidSequenceModalFormValues) => ({
  bidId,
});

const createInitialValues = (bid: AcceptCounterBidModalBidFragment) => ({
  bidId: bid.id,
});

interface AcceptCounterBidModalProps {
  readonly bid: AcceptCounterBidModalBidFragment;
}

const AcceptCounterBidSequenceModal = ({ bid }: AcceptCounterBidModalProps) => {
  const mutation = useAcceptCounteredBidMutation();

  const initialValues = createInitialValues(bid);

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [stepKeys.acceptCounterBid, stepKeys.acceptCounterBidSuccess],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  return (
    <HiiveModalContentWrapper>
      <StepFormikQL
        stepRouter={stepRouter}
        mutation={mutation}
        mutationNames={[`acceptCounteredBid`]}
        initialValues={initialValues}
        mapVariables={mapVariables}
        context={AcceptCounterBidSequenceModalStepFormContext}
      >
        {({ formikProps }) => (
          <Form>
            <AnimatePresence mode="wait" initial={false}>
              {stepKeys.acceptCounterBid === currentStepKey && (
                <AnimatedModalStep key={stepKeys.acceptCounterBid}>
                  <AcceptCounterBidModal
                    stepRouter={stepRouter}
                    bid={bid}
                    {...formikProps}
                  />
                </AnimatedModalStep>
              )}
              {stepKeys.acceptCounterBidSuccess === currentStepKey && (
                <AnimatedModalStep key={stepKeys.acceptCounterBidSuccess}>
                  <AcceptCounterBidSuccessModal />
                </AnimatedModalStep>
              )}
            </AnimatePresence>
          </Form>
        )}
      </StepFormikQL>
    </HiiveModalContentWrapper>
  );
};

export default AcceptCounterBidSequenceModal;
