import { ReactNode } from "react";

import { Stack } from "@chakra-ui/react";

import { Tile } from "@/components/common";

const ActionsTile = ({ children }: { readonly children: ReactNode }) => (
  <Tile py={3} pr={3}>
    <Stack
      justifyContent="space-between"
      direction={{ base: `column`, sm: `row` }}
      alignItems={{ base: `flex-start`, sm: `center` }}
      w="full"
      spacing={0}
      gap={3}
      wrap="wrap"
    >
      {children}
    </Stack>
  </Tile>
);

export default ActionsTile;
