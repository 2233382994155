import {
  AspectRatio,
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";

import { Skeleton, TextInputSkeleton } from "@/components/common";
import { ListingFeeBreakdownSkeleton } from "@/components/listings";

export const SharePriceCardSkeleton = () => (
  <Card w="full">
    <CardHeader>
      <Skeleton h={4} w="128px" />
    </CardHeader>
    <CardBody>
      <SimpleGrid columns={12} columnGap={6} rowGap={4}>
        <GridItem colSpan={12}>
          <Skeleton h="14px" w="112px" mb={0.5} />
        </GridItem>
        <GridItem colSpan={{ base: 12, xl: 5 }}>
          <VStack spacing={7}>
            <TextInputSkeleton />
            <TextInputSkeleton />
          </VStack>
        </GridItem>
        <GridItem
          colStart={{ base: 0, xl: 6, "2xl": 7 }}
          colSpan={{ base: 12, xl: 7, "2xl": 6 }}
        >
          <ListingFeeBreakdownSkeleton />
        </GridItem>
        <GridItem colSpan={12}>
          <VStack w="full" alignItems="flex-start" mb={4} pt={0.5}>
            <Skeleton h="12px" w="95%" />
            <Skeleton h="12px" w="100%" />
            <Skeleton h="12px" w="75%" />
          </VStack>
          <Skeleton h="12px" w="50%" />
        </GridItem>
      </SimpleGrid>
    </CardBody>
    <CardBody>
      <VStack alignItems="flex-start" spacing={4}>
        <Skeleton h="14px" w="112px" mb={0.5} />
        <AspectRatio w="full" ratio={8 / 3}>
          <Skeleton w="full" h="full" />
        </AspectRatio>
        <VStack w="full" alignItems="flex-start" pt={0.5} pb={1}>
          <Skeleton h="12px" w="100%" />
          <Skeleton h="12px" w="95%" />
        </VStack>
      </VStack>
    </CardBody>
  </Card>
);
