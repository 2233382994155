import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from "@chakra-ui/react";

const multiStyleConfigHelpers = createMultiStyleConfigHelpers([
  `tabs`,
  `tablist`,
  `tab`,
  `tabpanels`,
  `tabpanel`,
]);

export const Tabs = defineStyleConfig(
  multiStyleConfigHelpers.defineMultiStyleConfig({
    variants: {
      black: {
        tablist: {
          p: 0,
        },
        tab: {
          borderColor: `grey.15`,
          textStyle: `heading-xs`,
          px: 6,
          position: `relative`,
          color: `grey.500`,
          _hover: {
            bg: `grey.25`,
            borderColor: `grey.25`,
          },
          _active: {
            bg: `grey.50`,
          },
          _selected: {
            color: `grey.900`,
            _after: {
              content: `""`,
              position: `absolute`,
              left: `50%`,
              transform: `translateX(-50%)`,
              bottom: `0px`,
              width: `100%`,
              height: `4px`,
              backgroundColor: `grey.900`,
              borderTopLeftRadius: `4px`,
              borderTopRightRadius: `4px`,
            },
          },
        },
        tabpanel: {
          p: 0,
        },
      },
      underline: {
        tablist: {
          gap: 4,
          pb: 9,
        },

        tab: {
          textStyle: `deprecated-text-md`,
          color: `grey.600`,

          _focus: {
            boxShadow: `none`,
          },
          _selected: {
            color: `grey.900`,
            borderBottomColor: `salmon.900`,
            borderBottomWidth: `3px`,
            mb: `-3px`,
          },
          px: 0,
          pt: 0,
          pb: 1,
        },
        tabpanel: {
          p: 0,
        },
      },
    },
  }),
);
