import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import { Flex, Text } from "@chakra-ui/react";

import { BidInfoCardBidFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";

const PlacedByHiive = ({ bid }: { readonly bid: BidInfoCardBidFragment }) => {
  const { t } = useTranslation();
  const actor = useCurrentActor();

  const getIsMyBrokerBid = (brokerId: BidInfoCardBidFragment["brokerId"]) =>
    !!brokerId && actor.id === brokerId;

  return (
    <Flex gap={3} direction="column">
      <Text textStyle="heading-sm">{t(`placed_by_hiive`)}</Text>
      <Text>
        {match(bid.brokerId)
          .with(P.when(getIsMyBrokerBid), () =>
            t(`broker_bid_placed_by_hiive_broker_perspective`),
          )
          .with(P.string, () => t(`broker_bid_placed_by_hiive`))
          .otherwise(() => t(`bid_placed_by_hiive`))}
      </Text>
    </Flex>
  );
};

export default PlacedByHiive;
