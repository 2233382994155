import { ReactNode } from "react";

import { Stack } from "@chakra-ui/react";

const ActionsTileButtonWrapper = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <Stack
    wrap="wrap"
    direction={{ base: `column`, sm: `row` }}
    spacing={0}
    columnGap={3}
    rowGap={2}
    w={{ base: `full`, sm: `auto` }}
  >
    {children}
  </Stack>
);
export default ActionsTileButtonWrapper;
