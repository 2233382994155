import { Form, useFormikContext } from "formik";
import { useRef } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Button,
  CardBody,
  CardFooter,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { InternalLink } from "@/components/common";
import { CheckboxInput, FormCompaniesControls } from "@/components/form";
import {
  HoldingsFormValues,
  HoldingCompanySelect,
  ADD_UNLISTED_INPUT_ACTION,
  HoldingsInput,
} from "@/components/unaccredited-seller";
import {
  Company,
  HoldingType,
  ListedHoldingFragment,
  UnlistedHoldingFragment,
} from "@/gql";
import constants from "@/utils/constants";

// dealing with TS is sometimes a pain
const isListedHolding = (
  holding: ListedHoldingFragment | UnlistedHoldingFragment,
): holding is ListedHoldingFragment => holding.__typename === `Holding`;

export const AddHoldingsForm = ({
  holdings,
  onClose,
}: {
  holdings?: (ListedHoldingFragment | UnlistedHoldingFragment)[] | null;
  onClose: () => void;
}) => {
  const count = holdings?.length || 0;

  const { values, setFieldValue } = useFormikContext<HoldingsFormValues>();

  const { holdingBundles } = values;

  const dropdownRef = useRef<FormCompaniesControls>(null);

  const existingHoldingCompanyIds =
    holdings?.filter(isListedHolding).map((holding) => holding.company.id) ||
    [];

  const newHoldingCompanyIds = holdingBundles
    .filter(({ holding }) => holding.type === HoldingType.Listed)
    .map(({ holding }) => holding.value);

  const selectedHoldingCompanyIds = [
    ...existingHoldingCompanyIds,
    ...newHoldingCompanyIds,
  ];

  const { t } = useTranslation();

  const handleRemove = (index: number) => () => {
    setFieldValue(
      `holdingBundles`,
      holdingBundles.filter((_holding, cardIndex) => cardIndex !== index),
    );
  };

  const addUnlistedHoldingInput = ({ name }: { name?: string }) => {
    const holding = {
      value: name,
      type: HoldingType.Unlisted,
      numShares: null,
    };

    setFieldValue(`holdingBundles`, [
      ...values.holdingBundles,
      { company: null, holding },
    ]);
  };

  const addListedHoldingInput = (company: Company) => {
    const alreadyIncluded = holdingBundles.find(
      ({ holding }) => holding.value === company.id,
    );

    if (!alreadyIncluded) {
      const holding = {
        value: company?.id,
        type: HoldingType.Listed,
        numShares: null,
      };

      setFieldValue(`holdingBundles`, [
        ...values.holdingBundles,
        { company, holding },
      ]);
    }
  };

  const onSelectInput = (company: Company | null) => {
    dropdownRef?.current?.handleFocus(false);
    dropdownRef?.current?.handleClear();

    if (company?.id === ADD_UNLISTED_INPUT_ACTION) {
      addUnlistedHoldingInput({ name: company?.name });
      return;
    }
    if (company?.__typename === `Company`) {
      addListedHoldingInput(company);
    }
  };

  return (
    <Form style={{ display: `contents` }}>
      <CardBody
        as={VStack}
        spacing={4}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <VStack alignItems="start" maxW={144} spacing={4}>
          <Text>{t(`add_holding_description`)}</Text>
          <Text>
            <Trans
              i18nKey="current_holdings"
              count={count}
              components={{ bold: <strong style={{ fontWeight: `500` }} /> }}
              values={{ count, remaining: constants.max_holdings - count }}
            />
          </Text>
          <HoldingCompanySelect
            name="selectedCompany"
            isDisabled={count + holdingBundles.length >= constants.max_holdings}
            selectedHoldingCompanyIds={selectedHoldingCompanyIds}
            ref={dropdownRef}
            manualActionName={ADD_UNLISTED_INPUT_ACTION}
            onSelectInput={onSelectInput}
          />
          <HoldingsInput handleRemove={handleRemove} />
        </VStack>
        <CheckboxInput
          name="sellerLotDisclaimer"
          label={
            <Text textStyle="text-md" fontWeight={400}>
              <Trans
                i18nKey="seller_lot_details_terms_and_conditions"
                components={{
                  italic: (
                    <InternalLink
                      target="_blank"
                      textDecorationLine="underline"
                      href="/terms-and-conditions"
                    />
                  ),
                }}
              />
            </Text>
          }
          align="flex-start"
        />
      </CardBody>
      <CardFooter h={113}>
        <HStack gap={4} flexDirection="row-reverse" w="full">
          <Button
            type="submit"
            variant="rounded-solid-salmon"
            size="xl"
            w={{ base: `full`, lg: 180 }}
            minW="auto"
            isDisabled={holdingBundles.length === 0}
          >
            {t(`submit`)}
          </Button>
          <Button
            variant="rounded-outline-grey"
            size="xl"
            onClick={onClose}
            w={{ base: `full`, lg: 180 }}
            minW="auto"
          >
            {t(`cancel`)}
          </Button>
        </HStack>
      </CardFooter>
    </Form>
  );
};
