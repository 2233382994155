import { HStack, Switch, Text } from "@chakra-ui/react";

export const NotificationOption = ({
  title,
  value,
  onChange,
}: {
  readonly title: string;
  readonly value?: boolean;
  readonly onChange: (value: boolean) => void;
}) => (
  <HStack
    align="center"
    justify="space-between"
    w="full"
    py={2.5}
    borderBottomWidth="0.5px"
    borderBottomColor="grey.50"
  >
    <Text>{title}</Text>
    <Switch
      size="lg"
      isChecked={value}
      colorScheme="salmonPink"
      onChange={() => onChange(!value)}
    />
  </HStack>
);
