import { Box } from "@chakra-ui/react";

import { FeeBreakdownCommissionInfo } from "@/components/common";

interface ListingFeeBreakdownProps {
  readonly companyId?: string;
  readonly listingId?: string;
  readonly numShares?: number | null;
  readonly pricePerShare?: number | null;
}

const ListingFeeBreakdown = ({
  companyId,
  listingId,
  numShares,
  pricePerShare,
}: ListingFeeBreakdownProps) => (
  <Box w="full">
    <FeeBreakdownCommissionInfo
      companyId={companyId}
      listingId={listingId}
      numShares={numShares}
      pricePerShare={pricePerShare}
    />
  </Box>
);

export default ListingFeeBreakdown;
