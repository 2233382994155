import { ListItem } from "@chakra-ui/react";

import { ComboboxItemProps } from "./types";

export const ComboboxItem = <TItem,>({
  children,
  getItemProps,
  isDisabled,
  index,
}: ComboboxItemProps<TItem>) => (
  <ListItem {...getItemProps({ index, isDisabled })}>{children}</ListItem>
);
