import { CardBody, CardFooter, CardHeader, Flex, Text } from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardDisplayId,
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
} from "@/components/common";
import { PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment } from "@/gql";
import { useMultipleHoldings } from "@/hooks/featureFlags";
import {
  getTransactionDisplayId,
  getTransactionTitle,
  makeUrl,
  appendSellerCompanyIdToUrl,
} from "@/utils";

export const CompletedSaleCard = ({
  completedSale,
}: {
  readonly completedSale: PostActivityUnaccreditedSellerDashboardPageTransactionsPageTransactionFragment;
}) => {
  const isMultipleHoldingsEnabled = useMultipleHoldings();

  const title = getTransactionTitle(completedSale);

  const displayId = getTransactionDisplayId(completedSale);

  const { numShares, pricePerShare } = completedSale;

  return (
    <ActivityCard
      href={
        isMultipleHoldingsEnabled
          ? appendSellerCompanyIdToUrl(
              makeUrl(completedSale),
              completedSale.company.id,
            )
          : makeUrl(completedSale)
      }
    >
      <CardHeader>
        <Flex alignItems="center" gap={2}>
          <ActivityCardBadge variant="transaction" title={title} />
          <ActivityCardDisplayId displayId={displayId} />
        </Flex>
      </CardHeader>
      <CardBody>
        <ActivityCardPricingWrapper>
          <ActivityCardPricingNumSharesAndPrice
            numShares={numShares}
            price={pricePerShare}
          />
          <ActivityCardPricingTotal
            numShares={numShares}
            price={pricePerShare}
          />
        </ActivityCardPricingWrapper>
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            <Text textStyle="text-sm" textAlign="left">
              Status - Closed
            </Text>
          </ActivityCardFooterStatusWrapper>
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};
