import { useDisclosure } from "@chakra-ui/react";

import PricingChartCard from "./PricingChartCard";
import { PricingChartModal } from "./PricingChartModal";
import { PricingChartProvider } from "./PricingChartProvider";
import { PricingChartCompany } from "./types";

export const PricingChartWrapper = ({
  company,
}: {
  readonly company: PricingChartCompany;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { id, priceChartConfig } = company;

  return (
    <PricingChartProvider
      id={id}
      maxDate={priceChartConfig.graphDataStart}
      priceLines={priceChartConfig.priceLines}
      graphIndicators={priceChartConfig.graphIndicators}
    >
      <PricingChartModal company={company} isOpen={isOpen} onClose={onClose} />
      <PricingChartCard company={company} onOpen={onOpen} />
    </PricingChartProvider>
  );
};
