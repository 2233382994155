import { useTranslation, Trans } from "react-i18next";

import { Text, VStack, StackProps } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { ActivityCardStandingBidSolicitedCardBodyStandingBidFragment } from "@/gql";
import { getSecuritySpecialistContact } from "@/utils";

interface ActivityCardStandingBidSolicitedCardBody extends StackProps {
  readonly standingBid: ActivityCardStandingBidSolicitedCardBodyStandingBidFragment;
}

const ActivityCardStandingBidSolicitedCardBody = ({
  standingBid,
  ...stackProps
}: ActivityCardStandingBidSolicitedCardBody) => {
  const { t } = useTranslation();

  const contactEmail = getSecuritySpecialistContact(standingBid.company);

  return (
    <VStack spacing={0} {...stackProps}>
      <Text textStyle="heading-md">
        {t(`solicited_standing_bid_activity_card_body`)}
      </Text>
      <Text>
        <Trans
          i18nKey="solicited_standing_bid_activity_card_contact"
          t={t}
          components={[
            <MailtoLink
              key="contact"
              textDecoration="underline"
              email={contactEmail}
              subject="Broker Interest in Solicited Standing Bid"
            />,
          ]}
          values={{ email: contactEmail }}
        />
      </Text>
    </VStack>
  );
};

export default ActivityCardStandingBidSolicitedCardBody;
