import { useState } from "react";

import { Box, Checkbox, HStack, Text, VStack } from "@chakra-ui/react";

import { Card, HiiveButton } from "@/components/common";
// eslint-disable-next-line no-restricted-imports
import styles from "@/components/form/CheckboxInput.module.css";
import { withCurrentActor } from "@/components/hoc";
import { useRouteToNextOnboardingStep } from "@/hooks";

const IndividualConsentPage = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();
  const [acknowledged, setAcknowledged] = useState(false);
  return (
    <VStack py={7} spacing={4}>
      <Card w="xl" py={6} px={7}>
        <VStack alignItems="start">
          <Text textStyle="heading-md">
            By selecting “I agree” below, you are acknowledging as follows:
          </Text>
          <ul style={{ paddingLeft: `1.2em` }}>
            <li>
              <Text textStyle="text-md">
                I am an accredited investor and wish to avail regulatory
                benefits linked to my accredited status.
              </Text>
            </li>
            <li>
              <Text textStyle="text-md">
                I understand, and can bear, the financial risks associated with
                unregistered securities in private companies.
              </Text>
            </li>
            <li>
              <Text textStyle="text-md">
                I am aware that I am expected to have the necessary knowledge or
                means to understand the features and risks associated with
                unregistered securities in private companies.
              </Text>
            </li>
            <li>
              <Text textStyle="text-md">
                I am aware that unregistered securities in private companies may
                have a relaxed and flexible regulatory framework and may not be
                subject to the same regulatory oversight as retail/regular
                investment products.
              </Text>
            </li>
          </ul>
        </VStack>
      </Card>

      <HStack spacing={4}>
        <Checkbox
          className={`${styles.checkbox} ${styles.checkboxValid}`}
          size="lg"
          alignItems="flex-start"
          colorScheme="white"
          iconColor="grey.900"
          isChecked={acknowledged}
          data-testid="acknowledgement-checkbox"
          onChange={(e) => {
            setAcknowledged(e.target.checked);
          }}
          id="acknowledgement"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="acknowledgement">
          <Text>I agree</Text>
        </label>
      </HStack>

      <Box py={2}>
        <HiiveButton
          type="submit"
          onClick={() => {
            routeToNextStep();
          }}
          size="xl"
          isDisabled={!acknowledged}
          variant="rounded-solid-salmon"
          sentryLabel="[IndividualAccreditationForm] Next"
        >
          Next
        </HiiveButton>
      </Box>
    </VStack>
  );
};

export default withCurrentActor(IndividualConsentPage);
