import isNil from "lodash/fp/isNil";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import {
  AccreditationQuestion,
  InvestorStatus,
  useAccreditationQuestionGroupsQuery,
  UserWithInstitutionFragment,
} from "@/gql";

const AccreditationCardModifyContentIsQIB = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();

  const [qibQuestionId, setQibQuestionId] = useState<string>();

  const questionsResponse = useAccreditationQuestionGroupsQuery({
    variables: {
      version: 1,
      investorType: InvestorStatus.Institutional,
      countryId: actor.institution?.country.id ?? ``,
    },
  });

  useEffect(() => {
    if (
      questionsResponse.data &&
      questionsResponse.data.accreditationQuestionGroups
    ) {
      const [group] = questionsResponse.data.accreditationQuestionGroups;

      const [qibQ] = group.questions
        .filter((question): question is AccreditationQuestion => !!question)
        .filter(({ order }) => order === 1);

      setQibQuestionId(qibQ.id);
    }
  }, [questionsResponse]);

  const actorIsQIB = useMemo(() => {
    if (qibQuestionId && actor.institution) {
      const accreditationQuestion =
        actor.institution.accreditationAnswers[0]?.question;

      if (!accreditationQuestion) return false;

      const { id } = accreditationQuestion;

      if (qibQuestionId === id) return true;

      return false;
    }

    return undefined;
  }, [actor, qibQuestionId]);

  const actorQIBText = useMemo(() => {
    if (isNil(actorIsQIB)) return `–`;
    return actorIsQIB ? t(`yes`) : t(`no`);
  }, [actorIsQIB, t]);

  return (
    <Text as="span" fontWeight={500}>
      {actorQIBText}
    </Text>
  );
};

export default AccreditationCardModifyContentIsQIB;
