import { ComponentType } from "react";

import { Spinner, Center } from "@chakra-ui/react";

import { UserWithInstitutionFragment, useCurrentContextQuery } from "@/gql";

export interface WithCurrentActorProps {
  readonly actor: UserWithInstitutionFragment;
  readonly userNotificationToken?: string;
}

/**
 * A higher-order component that waits for the institution current actor to be loaded.
 */
function withCurrentActor<TProps extends WithCurrentActorProps>(
  WrappedComponent: ComponentType<TProps>,
) {
  return (hocProps: Omit<TProps, "actor" | "userNotificationToken">) => {
    const { data, loading, error } = useCurrentContextQuery();

    if (error) return null;

    const actor = data?.currentContext?.currentActor;
    const userNotificationToken = data?.currentContext?.userNotificationToken;

    if (loading || !actor || !userNotificationToken)
      return (
        <Center m={20}>
          <Spinner />
        </Center>
      );

    return (
      <WrappedComponent
        {...(hocProps as TProps)}
        actor={actor}
        userNotificationToken={userNotificationToken}
      />
    );
  };
}

export default withCurrentActor;
