/* eslint-disable functional/prefer-readonly-type */
import { Form } from "formik";
import * as Yup from "yup";

import { VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FormCountriesCombobox, FormikQL } from "@/components/form";
import {
  AnswerAccreditationQuestionsInput,
  BasicCountryFragment,
  Country,
  CurrentContextDocument,
  useAnswerAccreditationQuestionsNewMutation,
  useCountriesQuery,
} from "@/gql";
import {
  OnboardingRoutes,
  useOnboardingStepCompleteGuard,
  useResetField,
  useRouteToNextOnboardingStep,
} from "@/hooks";

import { CanadaIndividualAccreditationFields } from "./CanadaIndividualAccreditationFields";
import { INIndividualAccreditationFields } from "./INIndividualAccreditationFields";
import { USIndividualAccreditationFields } from "./USIndividualAccreditationFields";
import { IndividualAccreditationPageFormValues } from "./types";

const getIsSupportedCountry = (country: string) =>
  [`US`, `IN`, `CA`].includes(country);

const initialValues = {
  countryId: ``,
  answers: [],
};

const createValidationSchema = () => ({
  countries,
}: {
  readonly countries: readonly Country[];
}) =>
  Yup.object().shape({
    countryId: Yup.string().required(`Country is required`),
    answers: Yup.array()
      .of(
        Yup.object({
          key: Yup.string().required(`Must select an answer`),
          text: Yup.string(),
        }),
      )
      .test({
        message: `Must select at least one option`,
        // eslint-disable-next-line object-shorthand
        test: function test(arr) {
          const countryName = countries.find(
            ({ id }: { id: string }) => id === this.parent.countryId,
          )?.name;

          // unsupported countries pass validation
          if (!!countryName && !getIsSupportedCountry(countryName)) {
            return true;
          }

          return arr ? arr.length > 0 : false;
        },
      }),
  });

const mapVariables = (
  values: IndividualAccreditationPageFormValues,
): { input: AnswerAccreditationQuestionsInput } => ({
  input: {
    ...values,
  },
});

const CountryAccreditationFields = ({
  country,
}: {
  readonly country: BasicCountryFragment;
}) => {
  // Reset answers when country changes
  useResetField(`answers`, [country]);

  switch (country.name) {
    case `US`:
      return <USIndividualAccreditationFields />;
    case `IN`:
      return <INIndividualAccreditationFields />;
    case `CA`:
      return <CanadaIndividualAccreditationFields />;
    default:
      return null;
  }
};

const IndividualAccreditationForm = () => {
  useOnboardingStepCompleteGuard(OnboardingRoutes.IndividualAccreditation);

  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useAnswerAccreditationQuestionsNewMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => {
    routeToNextStep();
  };

  const validationSchema = createValidationSchema();

  return (
    <FormikQL
      query={useCountriesQuery()}
      mutation={mutation}
      mutationNames={[`answerAccreditationQuestionsNew`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, values, data }) => {
        const selectedCountry = data?.countries.find(
          (country) => country?.id === values.countryId,
        );

        return (
          <Form autoComplete="off">
            <VStack spacing={9}>
              <FormCountriesCombobox
                name="countryId"
                label="Select Country"
                labelSrOnly
              />
              {!!selectedCountry && (
                <CountryAccreditationFields country={selectedCountry} />
              )}
              <HiiveButton
                type="submit"
                size="md"
                variant="rounded-solid-salmon"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                sentryLabel="[IndividualAccreditationForm] Next"
              >
                Next
              </HiiveButton>
            </VStack>
          </Form>
        );
      }}
    </FormikQL>
  );
};

export default IndividualAccreditationForm;
