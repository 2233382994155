// Country codes for countries to be filtered out
import { useMemo } from "react";

import { BasicCountryFragment } from "@/gql";

const disabledCountryCodes = [`UM`, `PR`];
// Country codes for countries that are accredited
const priorityCountryCodes = [`US`, `UK`, `CA`, `IN`, `CH`];

export const useMemoizedCountries = ({
  countries,
  countryList,
}: {
  readonly countries?: readonly BasicCountryFragment[];
  readonly countryList: {
    readonly getName: (code: string) => string;
  };
}) =>
  useMemo(() => {
    if (!countries) {
      return {};
    }
    const { priorityCountries, otherCountries } = countries.reduce(
      (prev, { id, name: code }) => {
        if (disabledCountryCodes.includes(code)) {
          return prev;
        }

        const name =
          countryList.getName(code) ||
          (code === `UK` ? `United Kingdom` : code);

        return priorityCountryCodes.includes(code)
          ? {
              ...prev,
              priorityCountries: {
                ...prev.priorityCountries,
                [id]: name,
              },
            }
          : {
              ...prev,
              otherCountries: {
                ...prev.otherCountries,
                [id]: name,
              },
            };
      },
      {
        priorityCountries: {},
        otherCountries: {},
      },
    );

    return { ...priorityCountries, ...otherCountries };
  }, [countries, countryList]);
