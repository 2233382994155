import { FrigadeProvider } from "@frigade/react";
import { ComponentProps } from "react";

import { chakraColor } from "@/utils";

export enum FlowKind {
  BuyerPlaceBidSuccessStandingBidCTA = `BuyerPlaceBidSuccessStandingBidCTA`,
  BrokerSubmitBidSuccessStandingBidCTA = `BrokerSubmitBidSuccessStandingBidCTA`,
  U16RWelcome = `U16RWelcome`,
  BrokerWelcome = `BrokerWelcome`,
  BrokerWelcomeSSBP = `BrokerWelcomeSSBP`,
  IndividualTraderNoIVYesCAWelcome = `IndividualTraderNoIVYesCAWelcome`,
  IndividualTraderNoIVNoCAWelcome = `IndividualTraderNoIVNoCAWelcome`,
  IndividualTraderYesIVNoCAWelcome = `IndividualTraderYesIVNoCAWelcome`,
  IndividualTraderYesIVYesCAWelcome = `IndividualTraderYesIVYesCAWelcome`,
  InstitutionTraderYesCAWelcome = `InstitutionTraderYesCAWelcome`,
  InstitutionTraderNoCAWelcome = `InstitutionTraderNoCAWelcome`,
  U16RTransactionCelebration = `U16RTransactionCelebration`,
  U16ROverview = `U16ROverview`,
  U16RMultipleHoldings = `U16RMultipleHoldings`,
  CompanyPriceCharts = `CompanyPriceCharts`,
  Hiive50 = `Hiive50`,
}

export type FlowIDs = {
  readonly [key in keyof typeof FlowKind]: string;
};

const devFlowIDs: FlowIDs = {
  [FlowKind.U16RWelcome]: `flow_EsZRLYfR2vqchATF`,
  [FlowKind.IndividualTraderNoIVYesCAWelcome]: `flow_aMEpQQKt1gDo3XqT`,
  [FlowKind.BuyerPlaceBidSuccessStandingBidCTA]: `flow_GxnlVBU2MrBS3i4V`,
  [FlowKind.BrokerSubmitBidSuccessStandingBidCTA]: `flow_XUlXbfVrg07D8v6a`,
  [FlowKind.IndividualTraderNoIVNoCAWelcome]: `flow_it7GJUIUDcTmdmvc`,
  [FlowKind.IndividualTraderYesIVNoCAWelcome]: `flow_l9887nbg6yKUCIOo`,
  [FlowKind.IndividualTraderYesIVYesCAWelcome]: `flow_R70S6rtOJWx2lkOO`,
  [FlowKind.InstitutionTraderYesCAWelcome]: `flow_dLCjzolCx6HQGIG3`,
  [FlowKind.InstitutionTraderNoCAWelcome]: `flow_yLjMdkwIU8xIXhke`,
  [FlowKind.BrokerWelcome]: `flow_717QrqJILDz6Csej`,
  [FlowKind.BrokerWelcomeSSBP]: `flow_cWtAH0ydOQKxDkZY`,
  [FlowKind.U16RTransactionCelebration]: `flow_41sRgogAzoUkvI3m`,
  [FlowKind.U16ROverview]: `flow_khh5HRt88xn7VVRt`,
  [FlowKind.U16RMultipleHoldings]: `flow_K6y5bbZd`,
  [FlowKind.CompanyPriceCharts]: `flow_qM4evHw7`,
  [FlowKind.Hiive50]: `flow_iDi4SuaS`,
};

const prodFlowIDs: FlowIDs = {
  [FlowKind.U16RWelcome]: `flow_CgbkZGnggTwQ2GfN`,
  [FlowKind.IndividualTraderNoIVYesCAWelcome]: `flow_Mi305y1GCF7ZxMok`,
  [FlowKind.BuyerPlaceBidSuccessStandingBidCTA]: `flow_hRTxcxdGVLNErSMg`,
  [FlowKind.BrokerSubmitBidSuccessStandingBidCTA]: `flow_fK8feLBvs13LSygQ`,
  [FlowKind.IndividualTraderNoIVNoCAWelcome]: `flow_qC2Vqi1TFN9vZRYp`,
  [FlowKind.IndividualTraderYesIVNoCAWelcome]: `flow_N8pWPSOJwYaZLWI6`,
  [FlowKind.IndividualTraderYesIVYesCAWelcome]: `flow_iNPR0iPT1niqj1an`,
  [FlowKind.InstitutionTraderYesCAWelcome]: `flow_eneHXDoSypgXQhfx`,
  [FlowKind.InstitutionTraderNoCAWelcome]: `flow_8Jd0LbZTxYiYJ5wX`,
  [FlowKind.BrokerWelcome]: `flow_e7KG1k106OOgyaFp`,
  [FlowKind.BrokerWelcomeSSBP]: `flow_Yr6w0JEaR0eg1JKZ`,
  [FlowKind.U16RTransactionCelebration]: `flow_a5wIGV3pTq0X6KN9`,
  [FlowKind.U16ROverview]: `flow_lW7Sba89stSyLIYI`,
  [FlowKind.U16RMultipleHoldings]: `flow_K6y5bbZd`,
  [FlowKind.CompanyPriceCharts]: `flow_qM4evHw7`,
  [FlowKind.Hiive50]: `flow_iDi4SuaS`,
};

export const flowIDs =
  process.env.NEXT_PUBLIC_INFRA_ENV === `production` ? prodFlowIDs : devFlowIDs;

export const providerConfig: ComponentProps<
  typeof FrigadeProvider
>["config"] = {
  defaultAppearance: {
    theme: {
      colorText: chakraColor(`grey.900`),
      colorTextOnPrimaryBackground: `white`,
      colorPrimary: chakraColor(`salmon.900`),
      borderRadius: 6,
    },
    styleOverrides: {
      modalContainer: {
        padding: `48px 24px 24px 24px`,
        maxWidth: `440px`,
        height: `auto`,
      },
      cornerModalContainer: {
        width: `100%`,
        maxWidth: `400px`,
        right: `48px`,
        bottom: `48px`,
        margin: 0,
      },
      announcementButton: {
        padding: `14px 20px`,
        width: `auto`,
        borderRadius: `50px`,
        minWidth: `10rem`,
        fontSize: `1rem`,
        fontFamily: `var(--chakra-fonts-body)`,
        fontWeight: 600,
      },
      announcementBackButtonSecondary: {
        padding: `14px 20px`,
        width: `auto`,
        borderRadius: `50px`,
        minWidth: `10rem`,
        fontSize: `1rem`,
        fontFamily: `var(--chakra-fonts-body)`,
        fontWeight: 600,
      },
      announcementCTAContainer: {
        flexWrap: `wrap-reverse`,
      },
      announcementTitle: {
        fontSize: `1.5rem`,
        marginBottom: `1rem`,
      },
      announcementSubtitle: {
        fontSize: `1rem`,
        marginBottom: `1rem`,
      },
    },
  },
};
