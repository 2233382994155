import React from "react";

import { Center, Flex, SimpleGrid } from "@chakra-ui/react";

import { LongHyphen } from "@/components/common";
import { FilterTypes, ValuationRangeUnit } from "@/components/companies";

import { LastRoundValuationDropdown } from "./LastRoundValuationDropdown";
import { LastRoundValuationInput } from "./LastRoundValuationInput";

export const LastRoundValuation = ({
  filters,
  onFilterChange,
}: {
  readonly onFilterChange: (
    field: keyof FilterTypes,
    value: number | ValuationRangeUnit,
  ) => void;
  readonly filters: FilterTypes;
}) => (
  <Flex justifyContent="center" alignItems="center" gap={4} py={2}>
    <SimpleGrid
      w="full"
      alignItems="end"
      gap={2}
      gridTemplateColumns={{
        md: `1fr auto 1fr`,
      }}
    >
      <Flex gap={2} alignItems="flex-end">
        <LastRoundValuationInput
          label="Min Valuation"
          placeholder="0"
          min={0}
          max={999}
          value={filters.valuationMin}
          onChange={(value) => onFilterChange(`valuationMin`, value)}
        />
        <LastRoundValuationDropdown
          value={filters.valuationMinUnit}
          onChange={(value) => onFilterChange(`valuationMinUnit`, value)}
        />
      </Flex>

      <Center mx={2}>
        <LongHyphen my={2} display={{ base: `none`, md: `block` }} />
      </Center>

      <Flex gap={2} alignItems="flex-end">
        <LastRoundValuationInput
          label="Max Valuation"
          placeholder="999"
          min={0}
          max={999}
          value={filters.valuationMax}
          onChange={(value) => onFilterChange(`valuationMax`, value)}
        />
        <LastRoundValuationDropdown
          value={filters.valuationMaxUnit}
          onChange={(value) => onFilterChange(`valuationMaxUnit`, value)}
        />
      </Flex>
    </SimpleGrid>
  </Flex>
);
