import { ShareDetailsDisclaimer } from "@/components/common";
import { TransactionRoundedSharesTooltip } from "@/components/tooltip";
import { formatShares } from "@/utils";

const BidBuyerRoundingDisclaimer = ({
  numSharesOriginal,
}: {
  readonly numSharesOriginal: number;
}) => (
  <ShareDetailsDisclaimer tooltip={<TransactionRoundedSharesTooltip />}>
    of your original bid of {formatShares(numSharesOriginal)} shares
  </ShareDetailsDisclaimer>
);

export default BidBuyerRoundingDisclaimer;
