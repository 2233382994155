import { ReactNode } from "react";

import { Link, LinkProps } from "@chakra-ui/react";

interface MailtoLinkProps extends LinkProps {
  readonly email: string;
  readonly subject?: string;
  readonly children?: ReactNode;
  readonly cc?: string;
}

const generateMailToLink = ({
  email,
  cc,
  subject,
}: {
  email: string;
  cc?: string;
  subject?: string;
}) => {
  if (cc && subject) {
    return `mailto:${email}?subject=${subject}&cc=${cc}`;
  }

  if (cc) {
    return `mailto:${email}?cc=${cc}`;
  }

  if (subject) {
    return `mailto:${email}?subject=${subject}`;
  }

  return `mailto:${email}`;
};

/**
 * Generic component for mailto email links
 *
 * @param params.email - the email to use in the mailto
 * @param params.subject - the subject of the email
 * @param params.cc - the email to cc
 * @param params.children - React children that an `<a/>` tag will wrap
 * @returns
 */
const MailtoLink = ({
  email,
  subject,
  cc,
  children,
  ...linkProps
}: MailtoLinkProps) => {
  const href = generateMailToLink({ email, cc, subject });

  return (
    <Link href={href} {...linkProps}>
      {children}
    </Link>
  );
};

export default MailtoLink;
