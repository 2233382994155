import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";

import { InstitutionInfoForm } from "./InstitutionInfoForm";

const InstitutionInfoPage = () => {
  const { t } = useTranslation();

  return (
    <OnboardingContainerV2 metaTitle={t(`firm_fund_information`)}>
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`firm_fund_information`)} *
          </Text>
        </VStack>

        <InstitutionInfoForm />
      </Flex>
    </OnboardingContainerV2>
  );
};

export default InstitutionInfoPage;
