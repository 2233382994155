import NextLink from "next/link";

import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";

interface InternalLinkProps extends ChakraLinkProps {
  readonly children?: React.ReactNode;
  readonly href: string;
  readonly scroll?: boolean;
}

const InternalLink = ({
  children,
  href,
  scroll = true,
  ...chakraLinkProps
}: InternalLinkProps) => (
  <NextLink legacyBehavior scroll={scroll} href={href} passHref>
    <ChakraLink {...chakraLinkProps}>{children}</ChakraLink>
  </NextLink>
);

export default InternalLink;
