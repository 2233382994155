import Skeleton, { SkeletonProps } from "./Skeleton";

const ButtonSkeleton = ({ ...skeletonProps }: Omit<SkeletonProps, "h">) => (
  <Skeleton
    h="48px"
    borderRadius="full"
    maxW={{ base: `unset`, md: `170px` }}
    {...skeletonProps}
  />
);

export default ButtonSkeleton;
