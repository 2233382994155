import { useEffect } from "react";

import { FormCompaniesCombobox } from "@/components/form";
import {
  CompanyPermission,
  ListCompaniesOrderBy,
  PlaceStandingBidSelectCompanyInputCompanyFragment,
  usePlaceStandingBidSelectCompanyInputListCompaniesLazyQuery,
} from "@/gql";
import { iHaveEntityPermission, useDebounce, useItemField } from "@/hooks";

const getVariables = (searchText: string) => ({
  first: 20,
  orderBy: ListCompaniesOrderBy.MarketActivity,
  searchText,
});

export const PlaceStandingBidSelectCompanyInput = ({
  name = `company`,
  label,
}: {
  readonly name: string;
  readonly label: string;
}) => {
  const { debounce, isDebouncing } = useDebounce();
  const {
    selectedItem,
  } = useItemField<PlaceStandingBidSelectCompanyInputCompanyFragment>(name);

  const [
    loadCompanies,
    { data, loading },
  ] = usePlaceStandingBidSelectCompanyInputListCompaniesLazyQuery({
    fetchPolicy: `no-cache`,
  });

  useEffect(() => {
    const initialSearchText = selectedItem?.name || ``;

    loadCompanies({
      variables: getVariables(initialSearchText),
    });
  }, []);

  const handleChangeSearch = (search: string) => {
    debounce(() =>
      loadCompanies({
        variables: getVariables(search),
      }),
    );
  };

  const getItems = () =>
    data?.listCompanies?.edges?.flatMap((edge) => {
      if (
        !edge ||
        !edge.node ||
        !iHaveEntityPermission(edge.node, CompanyPermission.PlaceStandingBid)
      )
        return [];

      return [edge.node];
    }) || [];

  const isLoading = loading || isDebouncing;

  return (
    <FormCompaniesCombobox
      getItems={getItems}
      placeholder="Search for a company"
      onChangeSearch={handleChangeSearch}
      name={name}
      label={label}
      isLoading={isLoading}
    />
  );
};
