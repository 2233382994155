import React from "react";
import { useTranslation } from "react-i18next";

import { VStack, Text } from "@chakra-ui/react";

const HoldingDetailsDescription = () => {
  const { t } = useTranslation();
  return (
    <VStack align="left" mb={0}>
      <Text align="center" color="grey.900" my={7} mx={{ base: 0, sm: 4 }}>
        {t(`seller_lot_details_details_prompt`)}
      </Text>
      <Text
        align="center"
        fontWeight={500}
        color="grey.900"
        mx={{ base: 0, sm: 4 }}
      >
        {t(`seller_lot_details_multiple_holdings_limit_prompt`)}
      </Text>
    </VStack>
  );
};

export default HoldingDetailsDescription;
