import { Form } from "formik";
import * as Yup from "yup";

import { VStack } from "@chakra-ui/react";

import { PasswordField, PASSWORD_REGEX } from "@/components/auth";
import { FormikQL, FormFooter } from "@/components/form";
import { useResetPasswordMutation } from "@/gql";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .nullable()
    .required(`Password is required`)
    .matches(
      PASSWORD_REGEX,
      `Must contain at least 8 characters, one uppercase, one lowercase, one number or punctuation character`,
    ),
  passwordConfirmation: Yup.string()
    .nullable()
    .required(`Password confirmation is required`)
    .oneOf([Yup.ref(`password`)], `Passwords must match`),
});

interface ResetPasswordFormValues {
  readonly password: string;
  readonly passwordConfirmation: string;
}

const initialValues: ResetPasswordFormValues = {
  password: ``,
  passwordConfirmation: ``,
};

const mapVariables = (token: string) => (values: ResetPasswordFormValues) => ({
  input: {
    ...values,
    token,
  },
});

const ResetPasswordForm = ({
  token,
  onSuccess,
}: {
  readonly token: string;
  readonly onSuccess: () => void;
}) => (
  <FormikQL
    mutation={useResetPasswordMutation()}
    mutationNames={[`resetPassword`]}
    initialValues={initialValues}
    validationSchema={validationSchema}
    mapVariables={mapVariables(token)}
    onSuccess={onSuccess}
  >
    {({ isSubmitting }) => (
      <Form autoComplete="off">
        <VStack spacing={3}>
          <PasswordField
            name="password"
            label="Password"
            placeholder="Enter a password"
          />
          <PasswordField
            name="passwordConfirmation"
            placeholder="Confirm password"
          />
        </VStack>
        <FormFooter
          isSubmitting={isSubmitting}
          submitText="Reset Password"
          formName="ResetPasswordForm"
        />
      </Form>
    )}
  </FormikQL>
);

export default ResetPasswordForm;
