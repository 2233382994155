import { XYChart, LineSeries } from "@visx/xychart";

import { useColors } from "@/hooks";

export const SparklineChart = ({
  indexPrices,
}: {
  readonly indexPrices: readonly number[];
}) => {
  const positiveTrend = indexPrices[0] < indexPrices[indexPrices.length - 1];
  const neutralTrend = indexPrices[0] === indexPrices[indexPrices.length - 1];
  const data = indexPrices.map((price, index) => ({
    x: index,
    y: price,
  }));

  const [positiveColor, negativeColor, neutralColor] = useColors([
    `chart.positiveTrend`,
    `chart.negativeTrend`,
    `grey.500`,
  ]);

  const getStrokeColor = () => {
    if (neutralTrend) {
      return neutralColor;
    }
    if (positiveTrend) {
      return positiveColor;
    }
    return negativeColor;
  };

  const strokeColor = getStrokeColor();

  return (
    <XYChart
      height={30}
      width={90}
      margin={{ top: 2, right: 2, bottom: 2, left: 2 }}
      xScale={{ type: `linear`, nice: false }}
      yScale={{ type: `linear`, zero: false, nice: true }}
    >
      <LineSeries
        dataKey="index"
        data={data}
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
        strokeWidth={1.5}
        stroke={strokeColor}
      />
    </XYChart>
  );
};
