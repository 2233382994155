import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  VStack,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

export const DescriptionCardSkeleton = () => (
  <Card variant="darkened-footer">
    <CardHeader>
      <Skeleton h="40px" w="full" maxW="40%" />
    </CardHeader>
    <CardBody>
      <VStack gap={[2, 4]} alignItems="left">
        <Skeleton h="18px" w="25%" />;
        <Skeleton h="92px" />
        <Skeleton h="18px" w="25%" />;
        <HStack maxW="45%" spacing={4}>
          <Skeleton h="40px" maxW="122px" />
          <Skeleton h="40px" maxW="90px" />
        </HStack>
      </VStack>
    </CardBody>
    <CardBody>
      <VStack gap={2} alignItems="left">
        <Skeleton h="18px" w="25%" />;
        <Skeleton h="14px" />
        <Skeleton h="14px" />
        <Skeleton h="14px" maxW="20%" />
      </VStack>
    </CardBody>
    <CardFooter flexDirection="column" gap={3}>
      <Skeleton h="16px" w="full" />
      <Skeleton h="16px" maxW="10%" />
    </CardFooter>
  </Card>
);
