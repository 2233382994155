import { useField } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { VStack } from "@chakra-ui/react";

import { CustomRadio } from "@/components/common";
import { AccreditationRadioInput } from "@/components/form";
import { QuestionFormCard } from "@/components/onboarding";

import { ContinueAsSellerCTA } from "./ContinueAsSellerCTA";

export const USIndividualAccreditationFields = () => {
  const { t } = useTranslation();

  const [_field, _, { setValue: setAnswers }] = useField(`answers`);

  const [radioValue, setRadioValue] = useState<string | undefined>();

  const onChangeRadioValue = (value?: string) => {
    setRadioValue(value);
    const answers = !!value ? [{ key: value }] : [];
    setAnswers(answers);
  };

  return (
    <>
      <QuestionFormCard text={t(`must_be_accredited`)}>
        <AccreditationRadioInput
          value={radioValue}
          onChange={onChangeRadioValue}
        >
          <VStack alignItems="flex-start">
            <CustomRadio value="us-individual-net-assets-v2">
              I have, on my own or jointly with my spouse/partner, a net worth
              of more than $1 million, excluding the value of my/our house but
              including the value of any private stock I/we own.
            </CustomRadio>
            <CustomRadio value="us-individual-income-v2">
              I have had a gross income of $200,000 (or $300,000 jointly with my
              spouse/partner) for the past 2 years and expect the same this
              year.
            </CustomRadio>
            <CustomRadio value="us-individual-license-active-v2">
              I am a Series 7, Series 65 or Series 82 holder and my license is
              active and in good standing.
            </CustomRadio>
            <CustomRadio value="us-individual-not-accredited-v2">
              I don&apos;t meet any of these criteria.
            </CustomRadio>
          </VStack>
        </AccreditationRadioInput>
      </QuestionFormCard>
      {radioValue === `us-individual-not-accredited-v2` && (
        <ContinueAsSellerCTA />
      )}
    </>
  );
};
