import { randomBytes } from "crypto";
import currency from "currency.js";
import dayjs from "dayjs";
import isNil from "lodash/isNil";
import numeral from "numeral";

import { Maybe } from "@/types";
import { isNegativeTrend, isNeutralTrend } from "@/utils";
import * as datetime from "@/utils/datetime";

export const formatShares = (numShares?: string | number | null) =>
  numeral(numShares).format(`0,0`);

export const formatPercentage = (
  percentage: string | number,
  decimalPlaces = 2,
) => {
  const negative = isNegativeTrend(percentage);
  const neutral = isNeutralTrend(percentage);
  const formatted = numeral(percentage).format(
    `0,0.${`0`.repeat(decimalPlaces)}`,
  );

  if (neutral || negative) {
    return `${formatted}%`;
  }

  return `+${formatted}%`;
};

export const currencyValue = (number: number, opts?: currency.Options) =>
  currency(number, opts).intValue;

export const formatCurrency: (
  number?: number | string | null,
  opts?: currency.Options,
) => string = (number, opts) =>
  !isNil(number) ? currency(number, opts).format() : formatCurrency(0, opts);

export const formatPricePerShare = (pricePerShare: number, fromCents = true) =>
  formatCurrency(pricePerShare, { fromCents });

export const toFixedDecimal = (decimals: number, number?: string) => {
  if (!number) return ``;
  // eslint-disable-next-line no-useless-escape
  const re = new RegExp(`^-?\\d+(?:\.\\d{0,${decimals || -1}})?`);

  const [fixedDecimalValue] = number.match(re) || [];
  return fixedDecimalValue || ``;
};

const formatValuation = (valuation: number) =>
  numeral(valuation).format(`$0[.]00a`);

export const formatLastRoundValue = ({
  lastRoundPostValuation,
  lastRoundPricePerShare,
}: {
  readonly lastRoundPostValuation: Maybe<number> | undefined;
  readonly lastRoundPricePerShare: Maybe<number> | undefined;
}) => {
  const lastRoundPostValuationText =
    lastRoundPostValuation && `${formatValuation(lastRoundPostValuation)} post`;

  const lastRoundPricePerShareText =
    lastRoundPricePerShare &&
    `${formatPricePerShare(lastRoundPricePerShare)}/sh`;

  const lastRoundText = [lastRoundPostValuationText, lastRoundPricePerShareText]
    .filter(Boolean)
    .join(` at `);

  return lastRoundText || `-`;
};

export const formatLastRoundSeries = ({
  lastRoundDate,
  lastRoundSeries,
}: {
  readonly lastRoundDate: Maybe<string> | undefined;
  readonly lastRoundSeries: Maybe<string> | undefined;
}) => {
  const lastRoundSeriesText = [
    lastRoundDate && datetime.format(`MMMM YYYY`, lastRoundDate),
    lastRoundSeries && `(${lastRoundSeries})`,
  ]
    .filter(Boolean)
    .join(` `);

  return lastRoundSeriesText || `-`;
};

export const formatLastRoundSize = ({
  lastRoundRaised,
  totalRaised,
}: {
  readonly lastRoundRaised: Maybe<number> | undefined;
  readonly totalRaised: Maybe<number> | undefined;
}) => {
  const lastRoundSizeText = [
    lastRoundRaised && formatValuation(lastRoundRaised),
    lastRoundRaised &&
      totalRaised &&
      `of ${formatValuation(totalRaised)} total`,
  ]
    .filter(Boolean)
    .join(` `);

  return lastRoundSizeText || `-`;
};

export const formatCapitalRaised = ({
  lastRoundRaised,
  totalRaised,
}: {
  readonly lastRoundRaised: Maybe<number> | undefined;
  readonly totalRaised: Maybe<number> | undefined;
}) => {
  const lastRoundText = lastRoundRaised && formatValuation(lastRoundRaised);
  const totalText = totalRaised && `${formatValuation(totalRaised)} total`;

  const capitalRaisedText = [lastRoundText, totalText]
    .filter(Boolean)
    .join(` of `);

  return capitalRaisedText || `-`;
};

export const formatSimulatedTimestamp = () => {
  const now = dayjs();
  const simulatedTimestamp =
    now.minute() >= 5 ? now.minute(5) : now.subtract(1, `hour`).minute(5);
  const formattedSimualatedTimestamp = simulatedTimestamp.format(`h:mm A z`);
  const date = now.format(`Do MMMM`);

  return `${date} ${formattedSimualatedTimestamp}`;
};

export const obscureText = (text: string) => {
  const hex = randomBytes(text.length).toString(`hex`);
  return hex.substring(hex.length / 2);
};

export const normalizePhoneNumber = (phone: string) => {
  const matchParentheses = /['(',')]/g;
  return phone
    .replace(matchParentheses, ``)
    .replaceAll(`-`, ``)
    .replaceAll(` `, ``);
};
