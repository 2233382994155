import { Box, Text, VStack } from "@chakra-ui/react";

const ListingOtherDetailsTooltip = () => (
  <VStack alignItems="start">
    <Box>
      Include any additional details for potential buyers, such as:
      <ul style={{ paddingLeft: `1.2em` }}>
        <li>
          whether you are able to share any information on the company, such as
          financial statements;
        </li>
        <li>
          any details about the structure of the sale (e.g. terms of SPV);
        </li>
        <li>
          if you hold vested options and not shares, whether you need help
          financing the exercise;
        </li>
        <li>anything else that might be relevant to a buyer.</li>
      </ul>
    </Box>
    <Text>
      It is important not to share your contact information or identity in this
      section.
    </Text>
  </VStack>
);

export default ListingOtherDetailsTooltip;
