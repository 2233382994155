import { Form } from "formik";
import { AnimatePresence } from "framer-motion";

import { HiiveModalContentWrapper } from "@/components/common";
import { AnimatedModalStep, StepFormikQL } from "@/components/form";
import {
  AcceptStandingBidModalStandingBidFragment,
  ShareSeries,
  StandingBidAcceptsTransferMethodsOptions,
  StandingBidPageStandingBidByIdDocument,
  useAcceptStandingBidMutation,
} from "@/gql";
import { useStepRouter } from "@/hooks";
import { getNumOfShares } from "@/utils";

import {
  AcceptStandingBidConfirmationModal,
  AcceptStandingBidModal,
  AcceptStandingBidSequenceModalFormValues,
  AcceptStandingBidSuccessModal,
  stepKeys,
  StepKeys,
} from "./steps";
import { AcceptStandingBidSequenceModalContext } from "./steps/AcceptStandingBidSequenceModalContext";

const initialValues = (
  standingBidId: string,
  pricePerShare: number,
  transferMethod: StandingBidAcceptsTransferMethodsOptions,
  numShares: number | null,
): AcceptStandingBidSequenceModalFormValues => ({
  standingBidId,
  numShares,
  shareSeries: null,
  transferMethod,
  otherListingDetails: null,
  representedEmail: ``,
  representedFirstName: ``,
  representedLastName: ``,
  notifyRepresentingEmail: true,
  pricePerShare: pricePerShare / 100,
  confirmedEligibilityRequirements: false,
});

const mapVariables = (values: AcceptStandingBidSequenceModalFormValues) => ({
  standingBidId: values.standingBidId,
  input: {
    shareSeries: values.shareSeries || ShareSeries.A, // TODO might want to find a way to change these defaults
    numShares: values.numShares || 0,
    transferMethod: values.transferMethod,
    representedUser: {
      email: values.representedEmail,
      firstName: values.representedFirstName,
      lastName: values.representedLastName,
    },
    notifyRepresentingEmail: values.notifyRepresentingEmail,
    otherListingDetails: values.otherListingDetails,
  },
});

interface AcceptStandingBidSequenceModalContentProps {
  readonly standingBid: AcceptStandingBidModalStandingBidFragment;
}

export const AcceptStandingBidSequenceModalContent = ({
  standingBid,
}: AcceptStandingBidSequenceModalContentProps) => {
  const mutation = useAcceptStandingBidMutation({
    refetchQueries: [StandingBidPageStandingBidByIdDocument],
  });

  const numShares = getNumOfShares(standingBid) ?? null;

  const transferMethodOptions = () => {
    const transferMethods = Object.values(
      StandingBidAcceptsTransferMethodsOptions,
    );
    const filteredTransferMethods = transferMethods.filter((transferMethod) =>
      standingBid.acceptsTransferMethods.includes(transferMethod),
    );

    return filteredTransferMethods;
  };

  const _initialValues = initialValues(
    standingBid.id,
    standingBid.pricePerShare,
    transferMethodOptions()[0],
    numShares,
  );

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.acceptStandingBid,
      stepKeys.acceptStandingBidConfirmation,
      stepKeys.acceptStandingBidSuccess,
    ],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  return (
    <HiiveModalContentWrapper>
      <StepFormikQL
        stepRouter={stepRouter}
        mutation={mutation}
        mutationNames={[`acceptStandingBid`]}
        mapVariables={mapVariables}
        initialValues={_initialValues}
        context={AcceptStandingBidSequenceModalContext}
      >
        {({ formikProps }) => (
          <Form>
            <AnimatePresence mode="wait" initial={false}>
              {stepKeys.acceptStandingBid === currentStepKey && (
                <AnimatedModalStep key={stepKeys.acceptStandingBid}>
                  <AcceptStandingBidModal
                    stepRouter={stepRouter}
                    standingBid={standingBid}
                    {...formikProps}
                  />
                </AnimatedModalStep>
              )}
              {stepKeys.acceptStandingBidConfirmation === currentStepKey && (
                <AnimatedModalStep key={stepKeys.acceptStandingBidConfirmation}>
                  <AcceptStandingBidConfirmationModal
                    stepRouter={stepRouter}
                    standingBid={standingBid}
                    {...formikProps}
                  />
                </AnimatedModalStep>
              )}
              {stepKeys.acceptStandingBidSuccess === currentStepKey && (
                <AnimatedModalStep key={stepKeys.acceptStandingBidSuccess}>
                  <AcceptStandingBidSuccessModal
                    stepRouter={stepRouter}
                    standingBid={standingBid}
                    {...formikProps}
                  />
                </AnimatedModalStep>
              )}
            </AnimatePresence>
          </Form>
        )}
      </StepFormikQL>
    </HiiveModalContentWrapper>
  );
};
