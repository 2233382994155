import { useContext } from "react";

import { UnaccreditedSellerInlineNotificationsContext } from "./UnaccreditedSellerInlineNotificationsProvider";

export const useUnaccreditedSellerInlineNotifications = () => {
  const context = useContext(UnaccreditedSellerInlineNotificationsContext);

  if (context === undefined) {
    throw new Error(
      `useUnaccreditedSellerInlineNotifications must be used within an UnaccreditedSellerInlineNotificationsProvider`,
    );
  }

  return context;
};
