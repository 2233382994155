import isNil from "lodash/isNil";
import { Trans } from "react-i18next";

import { VStack, Text, HStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import { Hiive50IndexPriceDateRange } from "@/gql";
import { formatPercentage, toFixedDecimal } from "@/utils";

const RANGE_LABELS = {
  [Hiive50IndexPriceDateRange.Max]: <Trans i18nKey="all_time" />,
  [Hiive50IndexPriceDateRange.OneYear]: <Trans i18nKey="past_year" />,
  [Hiive50IndexPriceDateRange.SixMonths]: <Trans i18nKey="past_six_months" />,
  [Hiive50IndexPriceDateRange.ThreeMonths]: (
    <Trans i18nKey="past_three_months" />
  ),
};

const isNegativeTrend = (trend: number) => trend < 0;

export const IndexStat = ({
  range,
  loading,
  currentPrice,
  percentageChange,
}: {
  readonly range: Hiive50IndexPriceDateRange;
  readonly loading: boolean;
  readonly currentPrice: string | null;
  readonly percentageChange?: number | null;
}) => {
  if (currentPrice === null) return null;

  const negative = percentageChange && isNegativeTrend(percentageChange);
  const value = toFixedDecimal(2, currentPrice);

  if (loading)
    return (
      <HStack h={8} w="full" spacing={8}>
        <Skeleton h="100%" maxW={24} />
      </HStack>
    );

  return (
    <VStack alignItems="start" spacing={1}>
      <HStack alignItems="end" spacing={1}>
        <Text textStyle="text-3xl" lineHeight="normal" pt={0}>
          {value}
        </Text>
        {!isNil(percentageChange) && (
          <Text
            textStyle="heading-xs"
            color={negative ? `chart.negativeTrend` : `chart.positiveTrend`}
          >
            {formatPercentage(percentageChange)}
          </Text>
        )}
        <Text textStyle="text-xs" color="grey.500">
          {RANGE_LABELS[range]}
        </Text>
      </HStack>
    </VStack>
  );
};
