import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bg: `white`,
  },
  track: {
    bg: `grey.600`,
    _checked: {
      bg: `salmon.900`,
      _hover: {
        bg: `salmon.800`,
      },
      _active: {
        bg: `salmon.700`,
      },
    },
    _hover: {
      bg: `grey.500`,
    },
    _active: {
      bg: `grey.400`,
    },
    _disabled: {
      opacity: 0.4,
      _hover: {
        bg: `grey.600`,
      },
      _active: {
        bg: `grey.600`,
      },
      _checked: {
        _hover: {
          bg: `salmon.900`,
        },
        _active: {
          bg: `salmon.900`,
        },
      },
    },
  },
});

export const Switch = defineMultiStyleConfig({ baseStyle });
