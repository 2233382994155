import { ReactNode } from "react";

import { Tile, TileHeader, TileRow } from "@/components/common";

const StatusTileTile = ({ children }: { readonly children: ReactNode }) => (
  <Tile
    py={2}
    gap={4}
    display="grid"
    gridTemplateColumns={{ base: `1fr`, md: `auto 1fr` }}
    alignItems="baseline"
    data-testid="listing-status-tile"
  >
    {children}
  </Tile>
);

const StatusTileTileRow = ({ children }: { readonly children: ReactNode }) => (
  <TileRow>{children}</TileRow>
);

const StatusTileTileHeader = ({
  children,
}: {
  readonly children: ReactNode;
}) => <TileHeader>{children}</TileHeader>;

export default {
  Tile: StatusTileTile,
  TileRow: StatusTileTileRow,
  TileHeader: StatusTileTileHeader,
};
