import { useRouter } from "next/router";

import { Flex, VStack } from "@chakra-ui/react";

import { FullContentWrapper } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { useHiive50Enabled } from "@/hooks/featureFlags";
import { getIsUnaccreditedSeller } from "@/utils";

import { ChartCard, MethodologyCard, TableCard } from "./components";

const Hiive50Page = withCurrentActor(({ actor }) => {
  const router = useRouter();
  const isHiive50Enabled = useHiive50Enabled();
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  if (!isHiive50Enabled || isUnaccreditedSeller) {
    router.push(`/404`);
    return null;
  }

  return (
    <FullContentWrapper px={{ base: 4, lg: 8 }}>
      <Flex direction="column" gap={6} w="full" maxW="max-width-md">
        <VStack gap={{ base: 3, md: 6 }}>
          <ChartCard />
          <TableCard />
          <MethodologyCard />
        </VStack>
      </Flex>
    </FullContentWrapper>
  );
});

export default Hiive50Page;
