import { FeedResponse, FetchFeedOptions, User } from "@knocklabs/client";
import { useEffect, useState } from "react";

import { UserWithInstitutionFragment } from "@/gql";
import { useNotificationFeed } from "@/hooks";

import useKnockClient from "./useKnockClient";

interface NotificationsState {
  readonly feedData?: FeedResponse | null;
  readonly loading: boolean;
  readonly user?: User;
}

const initialNotificationsState: NotificationsState = {
  feedData: null,
  loading: true,
  user: undefined,
};

const useNotifications = ({
  actor,
  userNotificationToken,
  options,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly userNotificationToken: string;
  readonly options?: FetchFeedOptions | undefined;
}) => {
  const knockClient = useKnockClient({ actor, userNotificationToken });
  const notificationFeed = useNotificationFeed({
    knockClient,
  });

  const [state, setState] = useState<NotificationsState>(
    initialNotificationsState,
  );

  const getKnockUser = async () => {
    try {
      return await knockClient.user.get();
    } catch (_e) {
      return undefined;
    }
  };

  const fetchNotifications = async () => {
    const response:
      | {
          readonly data: FeedResponse;
        }
      | undefined = await notificationFeed.fetch({
      status: `unread`,
      page_size: 12,
      ...options,
    });

    const knockUser = await getKnockUser();

    setState({
      feedData: response?.data,
      loading: false,
      user: knockUser,
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return { ...state, notificationFeed };
};

export default useNotifications;
