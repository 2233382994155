import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { OnboardingContainerV2 } from "@/components/onboarding-v2";

import { BrokerResidenceForm } from "./BrokerResidenceForm";

const ResidencePage = () => {
  const { t } = useTranslation();

  return (
    <OnboardingContainerV2 metaTitle="Name and residence">
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`name_and_residence`)} *
          </Text>
        </VStack>

        <BrokerResidenceForm />
      </Flex>
    </OnboardingContainerV2>
  );
};

export default ResidencePage;
