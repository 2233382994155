import { Form, useField } from "formik";
import { match } from "ts-pattern";
import * as Yup from "yup";

import {
  FormControl,
  FormErrorMessage,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  AccreditationRadioCardInput,
  CheckboxInput,
  QuestionTooltip,
  RadioCard,
  RadioCardInput,
  FormikQL,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { QuestionFormCard } from "@/components/onboarding";
import {
  BasicUserFragment,
  CurrentContextDocument,
  InstitutionSuitabilityMutationVariables,
  InvestmentGoalAnswerInput,
  useInstitutionSuitabilityMutation,
  UserWithInstitutionFragment,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

import { institutionalInvestmentGoalQuestions } from "./data";

const initialValues = {
  isFinraInstitutionAccount: `Yes`,
  hasAgreed: false,
  answers: [],
};

const validationSchema = Yup.object().shape({
  isFinraInstitutionAccount: Yup.string()
    .oneOf([`Yes`, `No`, `I don't know`], `Answer is required`)
    .required(`Answer is required`),
  hasAgreed: Yup.boolean().when(`isFinraInstitutionAccount`, {
    is: `Yes`,
    then: Yup.boolean().oneOf([true], `Must accept`),
  }),
  answers: Yup.array().when(`isFinraInstitutionAccount`, {
    is: (isFinraInstitutionAccount: string) =>
      [`No`, `I don't know`].includes(isFinraInstitutionAccount),
    then: Yup.array().test({
      message: `Please provide an answer for each question`,
      test: (arr) =>
        arr
          ? arr.length === institutionalInvestmentGoalQuestions.length
          : false,
    }),
  }),
});

type InstitutionSuitabilityFormValues = {
  readonly isFinraInstitutionAccount: string;
  readonly hasAgreed: boolean;
} & {
  // eslint-disable-next-line functional/prefer-readonly-type
  readonly answers: InvestmentGoalAnswerInput[];
};

const mapVariables = ({ institutionId }: BasicUserFragment) => ({
  isFinraInstitutionAccount: isFinraInstitutionAccountValue,
  answers,
}: InstitutionSuitabilityFormValues): InstitutionSuitabilityMutationVariables => {
  const isFinraInstitutionAccount = match(isFinraInstitutionAccountValue)
    .with(`Yes`, () => true)
    .with(`No`, () => false)
    .with(`I don't know`, () => null)
    .otherwise(() => null);

  if (isFinraInstitutionAccount) {
    return {
      institutionId: institutionId || ``,
      updateInstitutionInput: {
        isFinraInstitutionAccount,
      },
      answers: [],
    };
  }

  return {
    institutionId: institutionId || ``,
    updateInstitutionInput: {
      isFinraInstitutionAccount,
    },
    answers,
  };
};

const IsInstitutionAccountInput = () => (
  <RadioCardInput
    name="isFinraInstitutionAccount"
    tooltipContent=""
    options={[
      { label: `Yes`, value: `Yes` },
      { label: `No`, value: `No` },
      { label: `I don't know`, value: `I don't know` },
    ]}
    renderOptions={(options, { getRootProps, getRadioProps }) => (
      <VStack>
        <HStack
          {...getRootProps()}
          spacing={5}
          display={{ base: `none`, md: `flex` }}
          data-testid="is-finra-institution-account"
        >
          {options.map(({ value, label }) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard
                w="130px"
                h={20}
                key={value}
                {...radio}
                data-testid={value}
              >
                {label}
              </RadioCard>
            );
          })}
        </HStack>
        <VStack
          display={{ base: `flex`, md: `none` }}
          {...getRootProps()}
          spacing={{ base: 5 }}
          w="full"
        >
          {options.map(({ value, label }) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard flex="1" w="full" h={20} key={value} {...radio}>
                {label}
              </RadioCard>
            );
          })}
        </VStack>
      </VStack>
    )}
  />
);

const InstitutionAccountAcknowledgement = () => (
  <>
    <QuestionFormCard text="By selecting “Yes” you are acknowledging on behalf of your institution as follows:">
      <UnorderedList>
        <VStack alignItems="flex-start">
          <ListItem>
            <Text as="span">
              The institution is an “Institution Account” as defined in FINRA
              Rule 4512(c).
              <QuestionTooltip
                tooltipContent="Rule 4512(c): https://www.finra.org/rules-guidance/rulebooks/finra-rules/4512"
                href="https://www.finra.org/rules-guidance/rulebooks/finra-rules/4512"
              />
            </Text>
          </ListItem>
          <ListItem>
            The institution is capable of evaluating investment risks
            independently, both in general and with regard to all transactions
            and investment strategies involving a security or securities and
            will exercise independent judgment in evaluating the recommendations
            of any broker-dealer or its associated persons unless it has
            notified Hiive in writing.
          </ListItem>
          <ListItem>
            The institution will notify Hiive if the above ceases to be true.
          </ListItem>
        </VStack>
      </UnorderedList>
    </QuestionFormCard>
    <CheckboxInput
      name="hasAgreed"
      label="I agree on behalf of my institution"
      align="flex-start"
    />
  </>
);

type InvestmentGoalAnswer = {
  readonly questionKey: string;
  readonly key: string;
};

const InvestmentGoalsFields = () => {
  const [field, { error, touched }, { setValue: setAnswers }] = useField(
    `answers`,
  );

  const answers: readonly InvestmentGoalAnswer[] = field.value;

  const findAnswer = (questionKey: string) =>
    answers.find(
      (answer: InvestmentGoalAnswer) => answer.questionKey === questionKey,
    );

  const onChange = (questionKey: string, value: string) => {
    const hasQuestionAlreadyBeenAnswered = answers.some(
      (answer) => answer.questionKey === questionKey,
    );

    if (hasQuestionAlreadyBeenAnswered) {
      const nextAnswers = answers.map((answer) => {
        if (answer.questionKey === questionKey) {
          return { questionKey, key: value };
        }
        return answer;
      });

      setAnswers(nextAnswers);
      return;
    }

    const nextAnswers = [...answers, { questionKey, key: value }];
    setAnswers(nextAnswers);
  };

  return (
    <FormControl id="answers" isInvalid={(error && touched) || false}>
      <VStack spacing={5}>
        {institutionalInvestmentGoalQuestions.map((question) => {
          const value = findAnswer(question.key);
          return (
            <AccreditationRadioCardInput
              dataTestid={question.key}
              label={question.text}
              value={!!value ? value.key : ``}
              onChange={(value: string) => onChange(question.key, value)}
              options={question.options}
              key={question.key}
            />
          );
        })}
      </VStack>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

const InstitutionSuitabilityPage = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useInstitutionSuitabilityMutation({
    refetchQueries: [CurrentContextDocument],
  });

  if (!actor.institutionId) return null;

  const onSuccess = () => {
    routeToNextStep();
  };

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`updateInstitution`, `answerInvestmentGoals`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables(actor)}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <VStack spacing={5} mt={5}>
            <Text mb={3} textAlign="center">
              Is this institution an “Institution Account” as defined in FINRA
              Rule 4512(c)?
            </Text>
            <IsInstitutionAccountInput />
            {values.isFinraInstitutionAccount === `Yes` ? (
              <InstitutionAccountAcknowledgement />
            ) : (
              <InvestmentGoalsFields />
            )}
            <HiiveButton
              type="submit"
              size="md"
              variant="rounded-solid-salmon"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[InstitutionSuitabilityPage] Next"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

export default withCurrentActor(InstitutionSuitabilityPage);
