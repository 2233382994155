import { Flex, Center } from "@chakra-ui/react";

import {
  WithQuery,
  HiiveButton,
  WatchlistCombobox,
  WatchlistSelectedCompaniesList,
} from "@/components/common";
import {
  useAddToWatchlistMutation,
  useCompanyWatchlistActorQuery,
  CompanyWatchlistActorDocument,
  useRemoveFromWatchlistMutation,
} from "@/gql";
import { useMutationWithError, useRouteToNextOnboardingStep } from "@/hooks";

const OnboardingWatchlistForm = () => {
  const query = useCompanyWatchlistActorQuery();

  const routeToNextStep = useRouteToNextOnboardingStep();

  const handleDoneClick = () => {
    routeToNextStep();
  };

  const [addToWatchlistMutation] = useMutationWithError(
    useAddToWatchlistMutation({
      refetchQueries: [CompanyWatchlistActorDocument],
    }),
    `addToWatchlist`,
  );

  const handleSelectCompany = (companyId: string) => {
    addToWatchlistMutation({
      variables: {
        companyId,
      },
    });
  };
  const [removeFromWatchlistMutation] = useMutationWithError(
    useRemoveFromWatchlistMutation({
      refetchQueries: [CompanyWatchlistActorDocument],
    }),
    `removeFromWatchlist`,
  );

  const handleRemoveCompany = (companyId: string) => {
    removeFromWatchlistMutation({
      variables: {
        companyId,
      },
    });
  };

  return (
    <WithQuery query={query}>
      {({
        data: {
          currentActor: { watchlist },
        },
      }) => (
        <Flex direction="column" mt={7} mb={8} align="center">
          <WatchlistCombobox
            onSelectCompany={handleSelectCompany}
            watchlist={watchlist}
          />
          <WatchlistSelectedCompaniesList
            onRemoveCompany={handleRemoveCompany}
            watchlist={watchlist}
          />
          <Center mt={10}>
            <HiiveButton
              size="md"
              variant="rounded-solid-salmon"
              onClick={handleDoneClick}
              disabled={watchlist.length === 0}
              sentryLabel="[OnboardingWatchlistForm] Done"
            >
              Next
            </HiiveButton>
          </Center>
        </Flex>
      )}
    </WithQuery>
  );
};

export default OnboardingWatchlistForm;
