import {
  CreateTransactingEntityModalProps,
  CreateTransactingEntityModal,
  CreateTransactingEntityModalTypes,
} from "@/components/modals";

type CreateTransactingEntityBuyerModalProps = Omit<
  CreateTransactingEntityModalProps,
  "type"
>;

const CreateTransactingEntityBuyerModal = (
  props: CreateTransactingEntityBuyerModalProps,
) => (
  <CreateTransactingEntityModal
    type={CreateTransactingEntityModalTypes.Buyer}
    {...props}
  />
);

export default CreateTransactingEntityBuyerModal;
