import { Flex, HStack, OtherProps, VStack } from "@chakra-ui/react";

import { SensitiveText } from "@/components/common";
import { AcceptedBidSharePrices } from "@/components/companies";
import { ActivityCardStandingBidNumSharesAndPriceDetailsStandingBidFragment } from "@/gql";
import {
  formatPricePerShare,
  formatShares,
  lot,
  getIsPartiallyAcceptedStandingBid,
  getIsConditionallyCompletedStandingBid,
} from "@/utils";

const ActivityCardStandingBidNumShareAndPriceDetails = ({
  standingBid,
  textStyle = `heading-lg`,
}: {
  readonly standingBid: ActivityCardStandingBidNumSharesAndPriceDetailsStandingBidFragment;
  readonly textStyle?: OtherProps["textStyle"];
}) => {
  const {
    pricePerShare,
    numSharesOriginal,
    numSharesAvailable,
    acceptedBidPrices,
    numSharesAccepted,
  } = standingBid;

  // These fields are required for v2 StandingBids
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion
  const sharesOriginal = numSharesOriginal!!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion
  const sharesAvailable = numSharesAvailable!!;

  switch (true) {
    case getIsPartiallyAcceptedStandingBid(standingBid):
      return (
        <VStack align="flex-start">
          <Flex wrap="wrap" textStyle={textStyle} columnGap={1.5}>
            <SensitiveText as="del" color="grey.200">
              {formatShares(sharesOriginal)}
            </SensitiveText>
            <SensitiveText>{formatShares(sharesAvailable)}</SensitiveText>
            <HStack spacing={6}>
              <SensitiveText>
                @ {formatPricePerShare(pricePerShare)}/sh
              </SensitiveText>
              <SensitiveText>
                {lot(sharesAvailable, pricePerShare)}
              </SensitiveText>
            </HStack>
          </Flex>
          <AcceptedBidSharePrices
            acceptedBidPrices={acceptedBidPrices}
            numSharesSold={numSharesAccepted}
          />
        </VStack>
      );
    case getIsConditionallyCompletedStandingBid(standingBid):
      return (
        <VStack align="flex-start">
          <Flex as="del" color="grey.200" wrap="wrap" textStyle={textStyle}>
            <SensitiveText>{formatShares(sharesOriginal)}</SensitiveText>
            <HStack spacing={6}>
              <SensitiveText>
                &nbsp;@ {formatPricePerShare(pricePerShare)}/sh
              </SensitiveText>
              <SensitiveText>
                {lot(sharesOriginal, pricePerShare)}
              </SensitiveText>
            </HStack>
          </Flex>
          <AcceptedBidSharePrices
            acceptedBidPrices={acceptedBidPrices}
            numSharesSold={numSharesAccepted}
          />
        </VStack>
      );
    default:
      return (
        <Flex wrap="wrap" textStyle={textStyle} columnGap={1.5}>
          <SensitiveText>{formatShares(sharesOriginal)}</SensitiveText>
          <HStack spacing={6}>
            <SensitiveText>
              @ {formatPricePerShare(pricePerShare)}/sh
            </SensitiveText>
            <SensitiveText>{lot(sharesOriginal, pricePerShare)}</SensitiveText>
          </HStack>
        </Flex>
      );
  }
};

export default ActivityCardStandingBidNumShareAndPriceDetails;
