import { CaretLeft } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Card, CardBody, Flex, HStack, Text } from "@chakra-ui/react";

import Meta from "@/components/Meta";
import { SlideAnimation } from "@/components/auth-v2";
import { InternalLink } from "@/components/common";
import { withGuestRoute } from "@/components/hoc";
import { RequestResetPasswordMutation } from "@/gql";
import { useCustomToast } from "@/hooks";
import { ToastDuration } from "@/types/toast";

import ForgotPasswordForm from "./ForgotPasswordForm";

const RequestSentCard = () => {
  const { t } = useTranslation();

  return (
    <SlideAnimation>
      <Card>
        <CardBody p={{ base: 6, md: 7 }}>
          <Text align="center" textStyle="text-md">
            {t(`forgot_password_check_inbox`)}
          </Text>
        </CardBody>
      </Card>
    </SlideAnimation>
  );
};

const BackButton = () => {
  const { t } = useTranslation();

  return (
    <InternalLink href="/login" mb={4}>
      <HStack
        spacing={0.5}
        cursor="pointer"
        color="grey.900"
        alignItems="center"
      >
        <CaretLeft size={18} weight="bold" />
        <Text mt={0.5} textStyle="heading-md">
          {t(`back_button_text`)}
        </Text>
      </HStack>
    </InternalLink>
  );
};

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const { successToast } = useCustomToast();
  const [requestSent, setRequestSent] = useState<boolean>(false);

  const onSuccess = (data: RequestResetPasswordMutation) => {
    successToast(data.requestResetPassword, {
      duration: ToastDuration.Long,
    });
    setRequestSent(true);
  };

  return (
    <>
      <Meta title={t(`forgotten_password`)} shouldTrackPageEvent />
      <Flex
        direction="column"
        bg="grey.50"
        minH="100vh"
        w="full"
        py={14}
        px={{ base: 4, lg: 8 }}
        gap={9}
      >
        <BackButton />

        <Box mx="auto" textAlign="center" w="full" maxW="lg">
          <Flex direction="column" gap={8}>
            <Text as="h1" textStyle="heading-2xl">
              {t(`forgot_password_page_heading`)}
            </Text>
            {requestSent ? (
              <RequestSentCard />
            ) : (
              <>
                <Text align="center" textStyle="text-md">
                  {t(`forgot_password_enter_email_prompt`)}
                </Text>
                <ForgotPasswordForm onSuccess={onSuccess} />
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default withGuestRoute(ForgotPasswordPage);
