import {
  DiscussionPermission,
  GetAvailableDiscussionActionsDiscussionFragment,
} from "@/gql";
import { iHaveEntityPermission } from "@/hooks";

const getCanSendMessage = (
  discussion: GetAvailableDiscussionActionsDiscussionFragment,
) => iHaveEntityPermission(discussion, DiscussionPermission.SendMessage);

const getCanAcceptDiscussionRequest = (
  discussion: GetAvailableDiscussionActionsDiscussionFragment,
) =>
  iHaveEntityPermission(
    discussion,
    DiscussionPermission.AcceptDiscussionRequest,
  );

export const getAvailableDiscussionActions = (
  discussion: GetAvailableDiscussionActionsDiscussionFragment,
) => {
  const canSendMessage = getCanSendMessage(discussion);
  const canAcceptDiscussionRequest = getCanAcceptDiscussionRequest(discussion);

  return {
    canSendMessage,
    canAcceptDiscussionRequest,
  };
};
