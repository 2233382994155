import { StaticImageData } from "next/image";

import { Box, Flex, Text, VStack } from "@chakra-ui/react";

import { NextImage } from "@/components/common";

interface InfoCardProps {
  readonly description: string;
  readonly image: StaticImageData;
  readonly title: string;
}

const InfoCard = ({ description, image, title }: InfoCardProps) => (
  <Flex alignItems="center" direction={{ base: `column`, md: `row` }} gap={6}>
    <Box flexShrink={0}>
      <NextImage
        alt={title}
        borderColor="grey.200"
        borderRadius="md"
        borderWidth="0.5px"
        height="192px"
        placeholder="blur"
        src={image}
        width="335px"
      />
    </Box>
    <VStack align="flex-start" spacing={2}>
      <Text textStyle="heading-md">{title}</Text>
      <Text textStyle="text-sm">{description}</Text>
    </VStack>
  </Flex>
);

export default InfoCard;
