import { AnimatePresence, motion } from "framer-motion";

import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";

import { CompanyWatchlistCompanyFragment } from "@/gql";

const WatchlistSelectedCompaniesList = ({
  watchlist,
  onRemoveCompany,
}: {
  readonly watchlist: readonly CompanyWatchlistCompanyFragment[];
  readonly onRemoveCompany: (companyId: string) => void;
}) => (
  <Flex direction="column" w="full" mt={7} gap={2}>
    <AnimatePresence>
      {watchlist.map(({ id, name, logoUrl }) => (
        <motion.div
          key={id}
          initial={{ translateY: -10 }}
          animate={{ translateY: 0 }}
          exit={{
            opacity: 0,
            height: 0,
            transition: { duration: 0.05 },
            translateY: -20,
          }}
        >
          <Flex
            px={5}
            py={5}
            borderRadius="md"
            borderWidth={0.5}
            borderColor="grey.200"
            bg="white"
            align="center"
            gap={4}
          >
            <Box h={6} w={6}>
              {logoUrl && (
                <Image
                  m="0 auto"
                  h={6}
                  maxW={6}
                  objectFit="contain"
                  src={logoUrl}
                  alt={name}
                />
              )}
            </Box>

            <Text textStyle="heading-md">{name}</Text>

            <Button
              flex="none"
              ml="auto"
              onClick={() => onRemoveCompany(id)}
              variant="text-salmon"
              px={2}
            >
              Remove
            </Button>
          </Flex>
        </motion.div>
      ))}
    </AnimatePresence>
  </Flex>
);

export default WatchlistSelectedCompaniesList;
