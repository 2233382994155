import { HStack, VStack } from "@chakra-ui/react";

import { RadioCard, RadioCardInput } from "@/components/form";
import { CaIsPermittedClientAnswer } from "@/gql";

const CaIsPermittedClientInput = () => (
  <RadioCardInput
    name="caIsPermittedClient"
    options={[
      { label: `Yes`, value: CaIsPermittedClientAnswer.Yes },
      { label: `No`, value: CaIsPermittedClientAnswer.No },
      { label: `I don't know`, value: CaIsPermittedClientAnswer.DontKnow },
    ]}
    renderOptions={(options, { getRootProps, getRadioProps }) => (
      <VStack>
        <HStack
          {...getRootProps()}
          spacing={5}
          display={{ base: `none`, md: `flex` }}
          data-testid="ca-is-permitted-client"
        >
          {options.map(({ value, label }) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard
                w="130px"
                h={20}
                key={value}
                {...radio}
                data-testid={value}
              >
                {label}
              </RadioCard>
            );
          })}
        </HStack>
        <VStack
          display={{ base: `flex`, md: `none` }}
          {...getRootProps()}
          spacing={{ base: 5 }}
          w="full"
        >
          {options.map(({ value, label }) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard flex="1" w="full" h={20} key={value} {...radio}>
                {label}
              </RadioCard>
            );
          })}
        </VStack>
      </VStack>
    )}
  />
);

export default CaIsPermittedClientInput;
