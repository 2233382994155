import { useTranslation } from "react-i18next";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from "@chakra-ui/react";

import { MarkdownDocument } from "@/components/common";
import { useManagedMarketsFastFollowEnabled } from "@/hooks/featureFlags";
import styles from "@/styles/company-eligibility-criteria-markdown-styles.module.css";

interface CompanyEligibilityCriteriaAlertProps {
  readonly markdown: string;
  readonly companyName?: string;
}

const CompanyEligibilityCriteriaAlert = ({
  markdown,
  companyName,
}: CompanyEligibilityCriteriaAlertProps) => {
  const { t } = useTranslation();

  const managedMarketsFastFollow = useManagedMarketsFastFollowEnabled();

  const alertTitle =
    managedMarketsFastFollow && companyName
      ? t(`transfer_eligibility_requirements_v2`, { companyName })
      : t(`transfer_eligibility_requirements`);

  return (
    <Alert alignItems="flex-start" status="info" variant="infoGrey">
      <AlertIcon />
      <Box>
        <AlertTitle>{alertTitle}</AlertTitle>
        <AlertDescription>
          <div className={styles[`company-eligibility-criteria-markdown`]}>
            <MarkdownDocument markdown={markdown} />
          </div>
        </AlertDescription>
      </Box>
    </Alert>
  );
};

export default CompanyEligibilityCriteriaAlert;
