import isNil from "lodash/isNil";

import { AskPriceComparisonChartCompanyFragment } from "@/gql";

export const getHasAskPriceChart = ({
  currentPrices: { lastTransaction, lowestAsk, highestBid },
  hasAskPriceComparisonChart,
  lastRoundPricePerShare,
}: AskPriceComparisonChartCompanyFragment) =>
  [lowestAsk, highestBid, lastTransaction, lastRoundPricePerShare].some(
    (price) => !isNil(price),
  ) && hasAskPriceComparisonChart;
