import { withCurrentActor } from "@/components/hoc";
import {
  BidPageBrokerActivityBidFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import { getIsBrokerForBid, getIsBrokerForListing } from "@/utils";

import { BrokerBidderActivity } from "./BrokerBidderActivity";
import { BrokerListerActivity } from "./BrokerListerActivity";

export const BrokerActivity = withCurrentActor(
  ({
    bid,
    actor,
  }: {
    readonly bid: BidPageBrokerActivityBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isSSBPEnabled = useSellSideBrokerPortalEnabled();

    const isLister = getIsBrokerForListing(actor, bid.listing);
    const isBidder = getIsBrokerForBid({ user: actor, bid });

    return (
      <>
        {isBidder && <BrokerBidderActivity bid={bid} />}
        {isSSBPEnabled && isLister && <BrokerListerActivity bid={bid} />}
      </>
    );
  },
);
