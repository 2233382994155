import { VStack } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  StandingBidActivityStandingBidFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { getIsBrokerForStandingBid } from "@/utils";
import {
  getIsBrokerCounterpartyForStandingBid,
  getIsBuyerForStandingBid,
} from "@/utils/standing-bid";

import { StandingBidBrokerActivity } from "./StandingBidBrokerActivity";
import { StandingBidBrokerCounterpartyActivity } from "./StandingBidBrokerCounterpartyActivity";
import { StandingBidBuyerActivity } from "./StandingBidBuyerActivity";
import { StandingBidSellerActivity } from "./StandingBidSellerActivity";

const StandingBidActivityCards = ({
  standingBid,
  actor,
}: {
  readonly standingBid: StandingBidActivityStandingBidFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  if (getIsBrokerForStandingBid({ user: actor, standingBid }))
    return <StandingBidBrokerActivity standingBid={standingBid} />;

  if (getIsBrokerCounterpartyForStandingBid({ user: actor, standingBid }))
    return <StandingBidBrokerCounterpartyActivity standingBid={standingBid} />;

  if (getIsBuyerForStandingBid(actor, standingBid))
    return <StandingBidBuyerActivity standingBid={standingBid} />;

  return <StandingBidSellerActivity actor={actor} standingBid={standingBid} />;
};

export const StandingBidActivity = withCurrentActor(
  ({
    standingBid,
    actor,
  }: {
    readonly standingBid: StandingBidActivityStandingBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => (
    <VStack spacing={4}>
      <StandingBidActivityCards actor={actor} standingBid={standingBid} />
    </VStack>
  ),
);
