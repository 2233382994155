import { Trans } from "react-i18next";

import { Link, Text, VStack } from "@chakra-ui/react";

export const SellerAgreement = () => (
  <VStack spacing={4} alignItems="flex-start">
    <Text>
      <Trans
        i18nKey="seller_ca_first_paragraph"
        components={{
          a1: (
            <Link
              fontWeight={500}
              textDecoration="underline"
              href="/terms-and-agreements"
              target="_blank"
            />
          ),
          a2: (
            <Link
              fontWeight={500}
              textDecoration="underline"
              href="https://www.hiive.com/privacy"
              target="_blank"
            />
          ),
          a3: (
            <Link
              fontWeight={500}
              textDecoration="underline"
              href="https://www.hiive.com/disclosures"
              target="_blank"
            />
          ),
        }}
      />
    </Text>
    <Text>
      <Trans i18nKey="seller_ca_second_paragraph" />
    </Text>
    <Text>
      <Trans i18nKey="seller_ca_third_paragraph" />
    </Text>
    <Text>
      <Trans i18nKey="seller_ca_fourth_paragraph" />
    </Text>
    <Text>
      <Trans i18nKey="seller_ca_fifth_paragraph" />
    </Text>
    <Text>
      <Trans i18nKey="seller_ca_sixth_paragraph" />
    </Text>
  </VStack>
);
