import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PersonaState {
  readonly isComplete: boolean;
}

const initialState: PersonaState = {
  isComplete: false,
};

const personaSlice = createSlice({
  name: `persona`,
  initialState,
  reducers: {
    setComplete: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isComplete: action.payload,
    }),
    reset: (_state) => initialState,
  },
});

export const { reset, setComplete } = personaSlice.actions;

export default personaSlice;
