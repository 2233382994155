import { Text, VStack } from "@chakra-ui/react";

const PricingBlockDescription = ({
  children,
}: {
  readonly children: string;
}) => (
  <VStack>
    <Text textStyle="text-xs">{children}</Text>
  </VStack>
);

export default PricingBlockDescription;
