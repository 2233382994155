import { useTranslation } from "react-i18next";

import { FeeBreakdownCommissionInfo } from "@/components/common";
import { ShareSeriesMakeupElement } from "@/components/postings";
import { Nullable, sumShareSeriesMakeup } from "@/utils";

const ListingTransactionBrokerSummary = ({
  pricePerShare,
  shareSeriesMakeup,
  listingId,
  companyId,
}: {
  readonly pricePerShare?: number | null;
  readonly shareSeriesMakeup: readonly Nullable<ShareSeriesMakeupElement>[];
  readonly listingId?: string;
  readonly companyId?: string;
}) => {
  const { t } = useTranslation();

  const totalShares = sumShareSeriesMakeup(shareSeriesMakeup);

  return (
    <FeeBreakdownCommissionInfo
      numShares={totalShares}
      pricePerShare={pricePerShare}
      listingId={listingId}
      companyId={companyId}
      combineHiiveFeesLabel={t(`hiive_co_broker_fee`)}
      combineHiiveFeesTooltip={t(`hiive_co_broker_fee_tooltip`)}
      netProceedsTitle={t(`broker_seller_proceeds`)}
      netProceedsSubtitle={t(`gross_of_your_brokerage_fees`)}
      netProceedsTooltip={t(`broker_seller_proceeds_tooltip`)}
      disclaimerVariant="brokerSeller"
    />
  );
};

export default ListingTransactionBrokerSummary;
