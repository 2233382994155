import { Box, Text, VStack } from "@chakra-ui/react";

const StandingBidOtherDetailsTooltip = () => (
  <VStack alignItems="start">
    <Box>
      Include any additional details to potential sellers, such as:
      <ul style={{ paddingLeft: `1.2em` }}>
        <li>Any diligence requirements or information requests</li>
        <li>If your offer is firm and fully funded</li>
        <li>If you are flexible on the price and willing to negotiate</li>
        <li>
          If you would consider other series of stock at a different price
        </li>
      </ul>
      <Text>
        It is important not to share your contact information or identity in
        this section.
      </Text>
    </Box>
  </VStack>
);

export default StandingBidOtherDetailsTooltip;
