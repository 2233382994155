import { Flex } from "@chakra-ui/react";

import { FocusedShareDetails, ShareDetailsStats } from "@/components/common";
import {
  BidInfoCardStatusTile,
  AcceptedBidDetails,
  DocumentActions,
} from "@/components/postings";
import { SellerTransactionInfoCardBidFragment } from "@/gql";
import { useCurrentActor, useDocumentSigners } from "@/hooks";
import { getIsBroker, getShowDocumentActions } from "@/utils";
import { getBidNumSharesActual } from "@/utils/bid";

import { BidSellerRoundingDisclaimer, TransactionFeeBreakdown } from "./shared";

const SellerTransactionInfoCard = ({
  bid,
}: {
  readonly bid: SellerTransactionInfoCardBidFragment;
}) => {
  const actor = useCurrentActor();

  const { transaction } = bid;

  const {
    canSignTransactionDocument,
    canInviteAlternateSigner,
    canRevokeAlternateSignerInvitation,
    haveISigned,
  } = useDocumentSigners(transaction);

  if (!transaction) return null;

  const { company, numShares, pricePerShare } = transaction;

  const showFeeBreakdown = !getIsBroker(actor);

  const shouldRenderDocumentsFooterActions = getShowDocumentActions({
    bid,
    actor,
    haveISigned,
    canInviteAlternateSigner,
    canSignTransactionDocument,
    canRevokeAlternateSignerInvitation,
  });

  const isRoundingDisclaimerVisible =
    getBidNumSharesActual(bid) !== bid.numShares;

  return (
    <Flex direction="column" gap={7} data-testid="bid-info-card">
      <FocusedShareDetails.Card variant="transaction">
        <FocusedShareDetails.Header
          title={`Transaction ${bid.displayId}`}
          company={company}
        >
          <FocusedShareDetails.HeaderCard>
            <ShareDetailsStats
              numberOfShares={numShares}
              pricePerShare={pricePerShare}
            />
          </FocusedShareDetails.HeaderCard>
          {isRoundingDisclaimerVisible && (
            <BidSellerRoundingDisclaimer numSharesOriginal={bid.numShares} />
          )}
        </FocusedShareDetails.Header>
        <FocusedShareDetails.Content>
          <FocusedShareDetails.ContentSection>
            <Flex direction="column" gap={6}>
              <BidInfoCardStatusTile bid={bid} showCounterBidDetails={false} />
              {showFeeBreakdown && (
                <TransactionFeeBreakdown transaction={transaction} />
              )}
              <AcceptedBidDetails bid={bid} />
            </Flex>
          </FocusedShareDetails.ContentSection>
          {shouldRenderDocumentsFooterActions && (
            <FocusedShareDetails.ContentFooter
              flexDirection="column"
              justifyContent="flex-end"
            >
              <DocumentActions bid={bid} />
            </FocusedShareDetails.ContentFooter>
          )}
        </FocusedShareDetails.Content>
      </FocusedShareDetails.Card>
    </Flex>
  );
};

export default SellerTransactionInfoCard;
