import { useEffect } from "react";

import { Text, VStack } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { canAccessPlatform, useRouteToNextOnboardingStep } from "@/hooks";
import { constants } from "@/utils";

const InstitutionApprovalWaitPage = ({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  useEffect(() => {
    if (!canAccessPlatform(actor)) return;
    routeToNextStep();
  }, []);

  if (canAccessPlatform(actor)) return null;

  return (
    <VStack
      spacing={6}
      textStyle="deprecated-text-xl"
      textAlign="center"
      pt={6}
    >
      <Text>
        Thank you for completing the steps to onboard your institution. Your
        institution is now being reviewed by our team.
      </Text>
      <Text>
        You will receive an email when your institution&apos;s approval status
        is updated.
      </Text>
      <Text>
        If you have any questions in the meantime, please reach out to us at:
        {` `}
        <MailtoLink email={constants.email_accounts} fontWeight="medium">
          {constants.email_accounts}.
        </MailtoLink>
      </Text>
    </VStack>
  );
};

export default withCurrentActor(InstitutionApprovalWaitPage);
