import {
  DiscussionThreadCard,
  MessageCounterpartyCard,
  WithQuery,
} from "@/components/common";
import {
  StandingBidSellerMessagingStandingBidFragment,
  useStandingBidSellerDiscussionListDiscussionQuery,
} from "@/gql";
import { getAvailableStandingBidActions } from "@/utils";

const MessageBuyerCard = ({
  standingBid,
}: {
  readonly standingBid: StandingBidSellerMessagingStandingBidFragment;
}) => <MessageCounterpartyCard topic={standingBid} />;

export const StandingBidSellerMessaging = ({
  standingBid,
}: {
  readonly standingBid: StandingBidSellerMessagingStandingBidFragment;
}) => {
  const { canRequestDiscussion } = getAvailableStandingBidActions(standingBid);

  const query = useStandingBidSellerDiscussionListDiscussionQuery({
    variables: {
      standingBidId: standingBid.id,
    },
  });

  if (canRequestDiscussion) {
    return <MessageBuyerCard standingBid={standingBid} />;
  }

  if (!standingBid.hasRequestedDiscussion) return null;

  return (
    <WithQuery query={query}>
      {({ data: { myDiscussions } }) => {
        const discussion = myDiscussions[0];
        if (!discussion) return null;

        return <DiscussionThreadCard discussion={discussion} />;
      }}
    </WithQuery>
  );
};
