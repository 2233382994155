import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box, Center, Flex, Text } from "@chakra-ui/react";

import Meta from "@/components/Meta";
import { Logo, WithQuery } from "@/components/common";
import { withGuestRoute } from "@/components/hoc";
import { useInvitationByTokenQuery } from "@/gql";

import InvitationForm from "./InvitationForm";

const ContentWrapper = ({ children }: { readonly children: JSX.Element }) => {
  const { t } = useTranslation();

  return (
    <Box bg="grey.50" minH="100vh" w="full" py={14} px={{ base: 4, lg: 8 }}>
      <Meta title={t(`accept_invitation`)} shouldTrackPageEvent />

      <Box maxW="30rem" mx="auto">
        <Center>
          <Logo.Grey mb={{ base: 9, md: 14 }} width={40} />
        </Center>
        <Flex direction="column" gap={8}>
          <Text textAlign="center" textStyle="heading-2xl" color="grey.900">
            {t(`accept_invitation`)}
          </Text>
          {children}
        </Flex>
      </Box>
    </Box>
  );
};

const InvitationPageWithToken = ({ token }: { readonly token: string }) => {
  const { t } = useTranslation();
  const query = useInvitationByTokenQuery({
    variables: { token },
  });

  return (
    <ContentWrapper>
      <WithQuery query={query}>
        {({ data: { invitationByToken: invitation } }) =>
          invitation ? (
            <InvitationForm token={token} invitation={invitation} />
          ) : (
            <>{t(`no_invitation_found`)}</>
          )
        }
      </WithQuery>
    </ContentWrapper>
  );
};

const InvitationPage = ({ token }: { readonly token?: string }) => {
  useEffect(() => {
    if (!token) {
      throw new Error(`Missing token for invitation`);
    }
  }, [token]);

  return token ? <InvitationPageWithToken token={token} /> : null;
};

export default withGuestRoute(InvitationPage);
