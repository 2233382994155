import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  `container`,
  `header`,
  `footer`,
]);

export const ShareDetails = defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderWidth: 0.5,
      borderRadius: `md`,
      w: `full`,
    },
    header: {
      textStyle: `heading-2xs`,
      color: `grey.900`,
      borderBottomWidth: 0.5,
      borderTopRadius: `md`,
      px: 4,
      py: 3,
      pb: 2.5,
    },
    footer: {
      bg: `grey.25`,
      borderBottomRadius: `md`,
      borderRadius: `md`,
      display: `flex`,
      justifyContent: `flex-end`,
      minW: 0,
      p: 5,
      paddingInline: 6,
      py: 7,
    },
  },
  variants: {
    listing: {
      container: {
        borderColor: `olive.900`,
      },
      header: {
        color: `olive.900`,
        borderBottomColor: `olive.900`,
        bg: `olive.50`,
      },
    },
    bid: {
      container: {
        borderColor: `plum.900`,
      },
      header: {
        color: `plum.900`,
        borderBottomColor: `plum.900`,
        bg: `plum.50`,
      },
    },
    transaction: {
      container: {
        borderColor: `teal.900`,
      },
      header: {
        color: `teal.900`,
        borderBottomColor: `teal.900`,
        bg: `skyBlue.50`,
      },
    },
    discussion: {
      container: {
        borderColor: `grey.900`,
      },
      header: {
        color: `grey.900`,
        borderBottomColor: `grey.900`,
        bg: `grey.25`,
      },
    },
  },
  defaultProps: {
    variant: `listing`,
  },
});
