import { CaretDown, SlidersHorizontal } from "@phosphor-icons/react";
import { capitalCase } from "change-case";
import { useState, ReactNode, MouseEventHandler } from "react";

import {
  Box,
  Button,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";

import { FilterTypes } from "@/components/companies";
import { SearchInput } from "@/components/form";
import { ListCompaniesOrderBy } from "@/gql";
import { useCurrentActor, useObscureCompanyInfo } from "@/hooks";

import FilterMenu from "./FilterMenu";
import { SelectedFilters } from "./SelectedFilters";

const SortOption = ({
  children,
  onClick,
}: {
  readonly children: ReactNode;
  readonly onClick: MouseEventHandler<HTMLButtonElement>;
}) => (
  <MenuItem
    textStyle="heading-xs"
    py={2.5}
    px={5}
    _notLast={{
      borderBottomWidth: 1,
      borderBottomColor: `grey.50`,
    }}
    _hover={{ bg: `grey.50` }}
    _focus={{ bg: `grey.50` }}
    _first={{ borderTopRadius: `md` }}
    _last={{ borderBottomRadius: `md` }}
    onClick={onClick}
  >
    {children}
  </MenuItem>
);

const FilterButton = ({
  onFilterChange,
  filters,
  onResetFilters,
  onApplyFilters,
}: {
  readonly onFilterChange: (
    field: keyof FilterTypes,
    value: string | readonly string[],
  ) => void;
  readonly filters: FilterTypes;
  readonly onResetFilters: (action: "ALL" | "INDUSTRIES" | "INVESTORS") => void;
  readonly onApplyFilters: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Menu
      placement="bottom-end"
      isOpen={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <MenuButton
        as={Button}
        bg="white"
        w="full"
        maxW="unset"
        borderColor="grey.600"
        borderWidth={1}
        color="grey.900"
        borderRadius="md"
        leftIcon={<SlidersHorizontal size={22} />}
        rightIcon={<CaretDown weight="bold" size={22} />}
        textStyle="heading-xs"
        h={10}
        _hover={{
          bg: `grey.50`,
        }}
        _active={{
          bg: `grey.50`,
        }}
        px={2.5}
      >
        Filter
      </MenuButton>
      <FilterMenu
        onClose={() => setOpen(false)}
        onFilterChange={onFilterChange}
        filters={filters}
        onResetFilters={onResetFilters}
        onApplyFilters={onApplyFilters}
      />
    </Menu>
  );
};

const SortByButton = ({
  onOrderByChange,
  orderBy,
}: {
  readonly onOrderByChange: (
    field: "orderBy",
    orderBy: ListCompaniesOrderBy,
    forceApply: true,
  ) => void;
  readonly orderBy: ListCompaniesOrderBy;
}) => (
  <Menu>
    <MenuButton
      as={Button}
      textAlign="start"
      bg="white"
      borderColor="grey.600"
      borderWidth={1}
      color="grey.900"
      w="full"
      maxW="unset"
      borderRadius="md"
      rightIcon={<CaretDown weight="bold" size={22} />}
      textStyle="heading-3xs"
      h={10}
      _hover={{
        bg: `grey.50`,
      }}
      _active={{
        bg: `grey.50`,
      }}
      px={2.5}
    >
      <Box as="span" color="grey.500">
        Sort by:
      </Box>
      {` `}
      {capitalCase(orderBy)}
    </MenuButton>
    <MenuList
      boxShadow="card"
      p={0}
      color="grey.900"
      w={{ base: `full`, md: `lg` }}
    >
      <SortOption
        onClick={() =>
          onOrderByChange(`orderBy`, ListCompaniesOrderBy.Alphabetically, true)
        }
      >
        Alphabetically
      </SortOption>
      <SortOption
        onClick={() =>
          onOrderByChange(`orderBy`, ListCompaniesOrderBy.LastRoundValue, true)
        }
      >
        Last Round Value
      </SortOption>
      <SortOption
        onClick={() =>
          onOrderByChange(`orderBy`, ListCompaniesOrderBy.MarketActivity, true)
        }
      >
        Market Activity
      </SortOption>
      <SortOption
        onClick={() =>
          onOrderByChange(
            `orderBy`,
            ListCompaniesOrderBy.NumberOfListings,
            true,
          )
        }
      >
        Number Of Listings
      </SortOption>
      <SortOption
        onClick={() =>
          onOrderByChange(`orderBy`, ListCompaniesOrderBy.NumberOfBids, true)
        }
      >
        Number Of Bids
      </SortOption>
    </MenuList>
  </Menu>
);

const Header = ({
  onFilterChange,
  filters,
  onResetFilters,
  onApplyFilters,
}: {
  readonly onFilterChange: (
    field: keyof FilterTypes,
    value: string | readonly string[] | ListCompaniesOrderBy,
    forceApply?: boolean,
  ) => void;
  readonly filters: FilterTypes;
  readonly onResetFilters: (action: "ALL" | "INDUSTRIES" | "INVESTORS") => void;
  readonly onApplyFilters: () => void;
}) => {
  const actor = useCurrentActor();
  const { showInfo } = useObscureCompanyInfo(actor);

  const handleSearchTextChange = (searchText: string) =>
    onFilterChange(`searchText`, searchText);

  return (
    <>
      <Stack
        direction={{ base: `column-reverse`, md: `row` }}
        justify="flex-end"
        align="flex-start"
        spacing={3}
        w="full"
      >
        <SimpleGrid
          gridTemplateColumns={{
            base: `auto`,
            md: `240px auto auto`,
            lg: `320px auto auto`,
          }}
          w={{ base: `full`, md: `auto` }}
          gap={3}
        >
          <GridItem colSpan={1}>
            <SearchInput
              placeholder="Search by company"
              searchText={filters.searchText}
              onChange={handleSearchTextChange}
            />
          </GridItem>
          {showInfo && (
            <GridItem colSpan={1}>
              <FilterButton
                filters={filters}
                onFilterChange={onFilterChange}
                onResetFilters={onResetFilters}
                onApplyFilters={onApplyFilters}
              />
            </GridItem>
          )}
          <GridItem colSpan={1}>
            <SortByButton
              onOrderByChange={onFilterChange}
              orderBy={filters.orderBy}
            />
          </GridItem>
        </SimpleGrid>
      </Stack>
      <SelectedFilters
        onResetFilters={onResetFilters}
        onFilterChange={onFilterChange}
      />
    </>
  );
};

export default Header;
