import { Tab, Text, TabList, Tabs, TabIndicator } from "@chakra-ui/react";

const CustomTab = ({
  children,
  value,
}: {
  readonly children: string;
  readonly value: string;
}) => (
  <Tab
    py={1}
    px={4}
    color="h-salmon-pink"
    border="none"
    _focus={{
      outline: `none`,
    }}
    _active={{
      bg: `none`,
    }}
    _selected={{
      color: `h-white`,
    }}
    zIndex={1}
    value={value}
  >
    <Text textStyle="deprecated-heading-sm">{children}</Text>
  </Tab>
);

const SwitchButton = ({
  onChange,
  options,
  value,
}: {
  readonly options: readonly {
    readonly name: string;
    readonly value: string;
  }[];
  readonly value: string;
  readonly onChange: (value: string) => void;
}) => {
  const getIndexValue = (index: number) => options[index].value;
  return (
    <Tabs
      pos="relative"
      overflow="hidden"
      onChange={(index) => onChange(getIndexValue(index))}
      defaultValue={value}
    >
      <TabList
        bg="h-white"
        borderRadius="50px"
        p={1}
        border="1px solid #BCBDC0"
      >
        {options.map((opt) => (
          <CustomTab key={opt.value} value={opt.value}>
            {opt.name}
          </CustomTab>
        ))}
      </TabList>
      <TabIndicator h={7} bg="h-mid-grey" mt="-2.125rem" borderRadius="50px" />
    </Tabs>
  );
};

export default SwitchButton;
