import { useEffect, useState } from "react";

import { Box, Text, Flex } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { CompanyCombobox } from "@/components/companies";
import { Combobox } from "@/components/form";
import {
  WatchlistCompanySearchListCompaniesDocument,
  CompanyWatchlistActorDocument,
  ListCompaniesOrderBy,
  useAddToWatchlistMutation,
  useWatchlistCompanySearchListCompaniesLazyQuery,
} from "@/gql";
import { useCombobox, useDebounce, useMutationWithError } from "@/hooks";

const getVariables = (searchText: string) => ({
  first: 20,
  orderBy: ListCompaniesOrderBy.MarketActivity,
  searchText,
});

export const WatchlistAddCombobox = ({
  onCancel,
}: {
  readonly onCancel: () => void;
}) => {
  const [search, setSearch] = useState(``);
  const { debounce, isDebouncing: isSearchDebouncing } = useDebounce();

  const [
    loadCompanies,
    { data, loading },
  ] = useWatchlistCompanySearchListCompaniesLazyQuery({
    fetchPolicy: `no-cache`,
  });

  useEffect(() => {
    loadCompanies({
      variables: getVariables(``),
    });
  }, []);

  const items =
    data?.listCompanies?.edges?.flatMap((edge) => {
      if (!edge || !edge.node) return [];

      const { id, name, watchlisted, logoUrl } = edge.node;

      return [
        {
          id,
          name,
          logoUrl,
          value: edge.node,
          subLabel: watchlisted ? `Watching` : ``,
          isDisabled: watchlisted,
        },
      ];
    }) || [];

  const [addToWatchlistMutation] = useMutationWithError(
    useAddToWatchlistMutation(),
    `addToWatchlist`,
  );

  const handleClickCompany = (id: string) => {
    addToWatchlistMutation({
      variables: { companyId: id },
      refetchQueries: [
        CompanyWatchlistActorDocument,
        WatchlistCompanySearchListCompaniesDocument,
      ],
      awaitRefetchQueries: true,
    });
  };

  const handleChangeSearch = (search: string) => {
    setSearch(search);
    debounce(() =>
      loadCompanies({
        variables: getVariables(search),
      }),
    );
  };

  const isLoading = loading || isSearchDebouncing;

  const { inputProps, menuProps, labelProps, itemProps, actions } = useCombobox(
    {
      items,
      itemToString: (item) => item.name,
      getItemKey: (item) => item.id,
      onChangeInputValue: handleChangeSearch,
      isLoading,
      onSelectItem: (item) => {
        if (!item) return;
        actions.blur();
        handleClickCompany(item.id);
        handleChangeSearch(``);
      },
      inputValue: search,
    },
  );

  return (
    <Box w="full">
      <Combobox.Label srOnly {...labelProps}>
        Add to Watchlist
      </Combobox.Label>
      <Combobox.Container>
        <Flex gap={2}>
          <Combobox.Input
            width="full"
            autoFocus
            placeholder="Search Company"
            {...inputProps}
          />
          <HiiveButton
            variant="boxed-grey"
            w={100}
            flexShrink={0}
            onClick={onCancel}
            sentryLabel="[WatchlistAddCombobox] Cancel"
          >
            Cancel
          </HiiveButton>
        </Flex>
        <Combobox.Menu
          isLoading={isLoading}
          fallback={<CompanyCombobox.Skeleton />}
          isLazy={false}
          maxH={242}
          {...menuProps}
        >
          {items.map((item, index) => (
            <CompanyCombobox.Item
              isDisabled={item.isDisabled}
              key={item.id}
              index={index}
              item={item}
              {...itemProps}
            >
              <Flex alignItems="center" w="full" gap={4}>
                <Box opacity={item.isDisabled ? 0.6 : 1}>
                  <CompanyCombobox.ItemIcon company={item} />
                </Box>
                <Flex as={Text} w="full" gap={2} alignItems="center">
                  <Text as="span">{item.name}</Text>
                  {item.isDisabled && (
                    <Text as="span" textStyle="text-sm" ml="auto">
                      Watching
                    </Text>
                  )}
                </Flex>
              </Flex>
            </CompanyCombobox.Item>
          ))}
        </Combobox.Menu>
      </Combobox.Container>
    </Box>
  );
};
