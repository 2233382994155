import isEmpty from "lodash/isEmpty";
import xor from "lodash/xor";
import { useCallback, useMemo, useState } from "react";

import { CardBody } from "@chakra-ui/react";

import { SuitabilityModifyFields } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { InvestmentGoalQuestion, UserWithInstitutionFragment } from "@/gql";
import { getIsInstitutionUser } from "@/utils";

import SuitabilityCardForm from "./SuitabilityCardForm";
import SuitabilityCardModifyContentFooter from "./SuitabilityCardModifyContentFooter";

const SuitabilityCardModifyContent = withCurrentActor(
  ({
    actor,
    onClose,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly onClose: () => void;
  }) => {
    const [questionIds, setQuestionIds] = useState<readonly string[]>();
    const [questions, setQuestions] = useState<
      readonly InvestmentGoalQuestion[]
    >();

    const isInstitutionUser = getIsInstitutionUser(actor);

    const suitabilityAnswers = useMemo(
      () =>
        (isInstitutionUser
          ? actor.institution?.investmentGoalAnswers
          : actor.investmentGoalAnswers) ?? [],
      [isInstitutionUser, actor],
    );

    const initialAnswers = useMemo(
      () => ({
        answers: suitabilityAnswers.reduce(
          (prevAnswers, { option, text }) => ({
            ...prevAnswers,
            [option.question.id]: option.custom ? text : option.id,
          }),
          {},
        ),
      }),
      [suitabilityAnswers],
    );

    const onQuestionsChange = useCallback(
      ({
        questions,
      }: {
        readonly questions: readonly InvestmentGoalQuestion[];
      }) => {
        setQuestions(questions);
        setQuestionIds(questions.map(({ id }) => id));
      },
      [setQuestions, setQuestionIds],
    );

    return (
      <SuitabilityCardForm
        initialValues={initialAnswers}
        questionIds={questionIds}
        questions={questions}
        onSuccess={onClose}
      >
        {({ values, setValues }) => (
          <>
            <CardBody px={{ base: 4, lg: 10 }} pt="0px !important">
              <SuitabilityModifyFields
                values={values}
                setValues={setValues}
                onQuestionsChange={onQuestionsChange}
                initialAnswers={initialAnswers}
              />
            </CardBody>
            <SuitabilityCardModifyContentFooter
              onClose={onClose}
              disabled={
                !isEmpty(
                  xor(Object.keys(values?.answers ?? {}), questionIds ?? []),
                )
              }
            />
          </>
        )}
      </SuitabilityCardForm>
    );
  },
);

export default SuitabilityCardModifyContent;
