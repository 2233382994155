import {
  DiscussionState,
  GetIsSensitiveMessageDiscussionFragment,
  GetIsSenderDiscussionMessageFragment,
  GetIsSenderInstitutionDiscussionMessageFragment,
  GetIsSensitiveMessageDiscussionMessageFragment,
  GetIsSenderInstitutionUserFragment,
  GetIsSenderUserFragment,
  GetIsSensitiveMessageUserFragment,
} from "@/gql";

export const getIsSenderInstitution = (
  user: GetIsSenderInstitutionUserFragment,
  discussionMessage: GetIsSenderInstitutionDiscussionMessageFragment,
) =>
  user.institutionId &&
  user.institutionId === discussionMessage.sender.institutionId;

export const getIsSender = (
  user: GetIsSenderUserFragment,
  discussionMessage: GetIsSenderDiscussionMessageFragment,
) => user.id === discussionMessage.senderId;

export const getIsSensitiveMessage = (
  user: GetIsSensitiveMessageUserFragment,
  discussionMessage: GetIsSensitiveMessageDiscussionMessageFragment,
  discussion: GetIsSensitiveMessageDiscussionFragment,
) =>
  !getIsSender(user, discussionMessage) &&
  !getIsSenderInstitution(user, discussionMessage) &&
  discussion.state === DiscussionState.Pending;
