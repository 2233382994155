import { Text, VStack } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { constants } from "@/utils";

const BrokerCustomerAgreementDeclinedPage = () => (
  <VStack spacing={4} textAlign="center">
    <Text fontWeight="medium" mt={7}>
      Contact customer support
    </Text>
    <Text>
      You cannot proceed with onboarding because you have declined the Customer
      Agreement. If you have any questions, contact our support team.
    </Text>
    <MailtoLink fontWeight="medium" email={constants.email_support} pt={8}>
      {constants.email_support}
    </MailtoLink>
  </VStack>
);

export default BrokerCustomerAgreementDeclinedPage;
