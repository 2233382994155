import { KeyboardEvent } from "react";

const getIsOrContainsNode = (parent: HTMLElement, child: HTMLElement) =>
  parent === child || parent.contains(child);

/**
 * Checks if the target node is within any of the elements
 */
export const targetWithin = (
  target: HTMLElement,
  elements: readonly HTMLElement[],
) =>
  elements.some((element) => {
    if (!element || !target) return false;

    return getIsOrContainsNode(element, target);
  });

/**
 * Normalizes the 'key' property of a KeyboardEvent in IE/Edge
 * @param event a keyboardEvent object
 * @return keyboard key
 */
export const normalizeKey = <T>(event: KeyboardEvent<T>) => {
  const { key, keyCode } = event;
  if (keyCode >= 37 && keyCode <= 40 && key.indexOf(`Arrow`) !== 0) {
    return `Arrow${key}`;
  }
  return key;
};
