import { Text } from "@chakra-ui/react";

const TransactionRoundedSharesTooltip = () => (
  <Text>
    The number of shares displayed in listings is rounded in order to help
    protect the identity of Hiive users. Once your bid is accepted, you will see
    the actual number of shares you have agreed to purchase, along with the
    rounded number that was displayed in the original listing. You may also
    message the seller to request the specific lot size.
  </Text>
);

export default TransactionRoundedSharesTooltip;
