import { GridItem, SimpleGrid, Text } from "@chakra-ui/react";

import {
  FeeDiscountAlert,
  HeroBannerWrapper,
  WithQuery,
} from "@/components/common";
import {
  FeeDiscountAlertType,
  useFeeDiscountAlert,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  FeeDiscountFragment,
  UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFragment,
  UserWithInstitutionFragment,
  useDefaultUnaccreditedSellerDashboardPageQuery,
} from "@/gql";
import { getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard } from "@/utils";

import HeroBannerSkeleton from "./HeroBannerSkeleton";

interface DefaultPreListingHeroBannerContentWithFeeDiscountProps {
  readonly companyName: string;
  readonly firstName: string;
  readonly feeDiscount: FeeDiscountFragment;
  readonly feeDiscountAlertType: FeeDiscountAlertType;
}

const DefaultPreListingHeroBannerContentWithFeeDiscount = ({
  companyName,
  firstName,
  feeDiscount,
  feeDiscountAlertType,
}: DefaultPreListingHeroBannerContentWithFeeDiscountProps) => (
  <SimpleGrid
    columns={12}
    columnGap={2}
    alignItems="center"
    pb={{ base: 6, lg: 42 }}
  >
    <GridItem colSpan={{ base: 12, lg: 5, xl: 7 }}>
      <Text textStyle="heading-4xl">Hello, {firstName}</Text>
    </GridItem>

    <GridItem colSpan={{ base: 12, lg: 7, xl: 5 }} mt={{ base: 2, lg: 0 }}>
      <FeeDiscountAlert
        type={feeDiscountAlertType}
        companyName={companyName}
        feeDiscount={feeDiscount}
      />
    </GridItem>
  </SimpleGrid>
);

interface DefaultPreListingHeroBannerContentProps {
  readonly actor: UserWithInstitutionFragment;
  readonly company: UnaccreditedSellerDashboardPagePreListingHeroBannerCompanyFragment;
}

function DefaultPreListingHeroBannerContentChild({
  actor,
  company,
}: DefaultPreListingHeroBannerContentProps) {
  const { feeDiscount, feeDiscountAlertType } = useFeeDiscountAlert({
    company,
  });

  return (
    <HeroBannerWrapper bg={{ base: `transparent`, lg: `white` }}>
      {feeDiscountAlertType ? (
        <DefaultPreListingHeroBannerContentWithFeeDiscount
          companyName={company.name}
          firstName={actor.firstName}
          feeDiscount={feeDiscount}
          feeDiscountAlertType={feeDiscountAlertType}
        />
      ) : (
        <Text textStyle="heading-4xl" mb={7}>
          Hello, {actor.firstName}
        </Text>
      )}
    </HeroBannerWrapper>
  );
}

const DefaultPreListingHeroBannerContent = withCurrentActor(
  (props: DefaultPreListingHeroBannerContentProps) => (
    <DefaultPreListingHeroBannerContentChild {...props} />
  ),
);

const PostActivityPreListingHeroBannerContent = () => (
  <HeroBannerWrapper bg={{ base: `transparent`, lg: `white` }}>
    <Text textStyle="heading-4xl" mb={7}>
      Your Dashboard
    </Text>
    <Text textStyle="heading-2xl" mb={2}>
      Monitor Your Transactions and Associated Activity
    </Text>
    <Text textStyle="text-md">
      Use the activity sidebar to browse your recent actions and progress a
      transaction. Choose to place your own listing by clicking “Create
      Listing.”
    </Text>
  </HeroBannerWrapper>
);

const PreListingHeroBanner = () => {
  const query = useDefaultUnaccreditedSellerDashboardPageQuery();

  return (
    <WithQuery query={query} fallback={<HeroBannerSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity,
          unaccreditedSellerMyCompanyActivity,
        },
      }) => {
        const { myCompany } = unaccreditedSellerMyActivity;

        if (
          getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard(
            unaccreditedSellerMyActivity,
            unaccreditedSellerMyCompanyActivity,
          )
        ) {
          return <PostActivityPreListingHeroBannerContent />;
        }

        return <DefaultPreListingHeroBannerContent company={myCompany} />;
      }}
    </WithQuery>
  );
};

export default PreListingHeroBanner;
