import { SimpleGrid, GridItem, Box } from "@chakra-ui/react";

import {
  FullContentWrapper,
  MobileTabPanel,
  MobileTabPanels,
  MobileTabs,
  MobileTabList,
  MobileTab,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  ListingPageContentListingFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import { getShowListingActivity } from "@/utils";

import { ListingActivity } from "./ListingActivity";
import { ListingInfoCard } from "./ListingInfoCard";

interface DesktopListingPageContentProps {
  readonly listing: ListingPageContentListingFragment;
}

const DesktopListingPageContent = ({
  listing,
}: DesktopListingPageContentProps) => (
  <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
    <GridItem>
      <ListingInfoCard listing={listing} />
    </GridItem>
    <GridItem>
      <ListingActivity listing={listing} />
    </GridItem>
  </SimpleGrid>
);

interface MobileListingPageContentProps {
  readonly listing: ListingPageContentListingFragment;
  readonly actor: UserWithInstitutionFragment;
}

const MobileListingPageContent = withCurrentActor(
  ({ actor, listing }: MobileListingPageContentProps) => {
    const showListingActivity = getShowListingActivity(actor, listing);

    return showListingActivity ? (
      <MobileTabs>
        <MobileTabList>
          <MobileTab>Overview</MobileTab>
          <MobileTab>Activity</MobileTab>
        </MobileTabList>

        <MobileTabPanels>
          <MobileTabPanel>
            <ListingInfoCard listing={listing} />
          </MobileTabPanel>
          <MobileTabPanel>
            <ListingActivity listing={listing} />
          </MobileTabPanel>
        </MobileTabPanels>
      </MobileTabs>
    ) : (
      <ListingInfoCard listing={listing} />
    );
  },
);

interface ListingPageContentProps {
  readonly listing: ListingPageContentListingFragment;
}

export const ListingPageContent = ({ listing }: ListingPageContentProps) => {
  const isDesktopView = useIsDesktop();
  return (
    <FullContentWrapper px={{ base: 4, lg: 8 }}>
      <Box w="full" maxW="max-width-md">
        {isDesktopView ? (
          <DesktopListingPageContent listing={listing} />
        ) : (
          <MobileListingPageContent listing={listing} />
        )}
      </Box>
    </FullContentWrapper>
  );
};
