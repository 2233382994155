import { QueryHookOptions } from "@apollo/client";
import { useSelector } from "react-redux";

import {
  CurrentContextQuery,
  CurrentContextQueryVariables,
  useCurrentContextQuery,
} from "@/gql";
import { RootState } from "@/state";

const useCurrentContext = (
  baseOptions?: QueryHookOptions<
    CurrentContextQuery,
    CurrentContextQueryVariables
  >,
) => {
  const token = useSelector((state: RootState) => state.auth.token);

  const { data, loading } = useCurrentContextQuery({
    skip: !token,
    ...baseOptions,
  });

  return {
    actor: data?.currentContext?.currentActor,
    userNotificationToken: data?.currentContext?.userNotificationToken,
    loading,
  };
};

export default useCurrentContext;
