import { ReactNode } from "react";

import {
  Grid,
  GridItem,
  GridItemProps,
  GridProps,
  Text,
} from "@chakra-ui/react";

import { Tile, TileHeader } from "@/components/common";

const PropertyGridGrid = ({ children }: { readonly children: ReactNode }) => (
  <Grid gap={3} gridTemplateColumns={{ base: `1fr`, md: `repeat(2, 1fr)` }}>
    {children}
  </Grid>
);

interface PropertyGridGridItemProps extends GridItemProps {
  readonly children: ReactNode;
}

const PropertyGridGridItem = ({
  children,
  ...gridItemProps
}: PropertyGridGridItemProps) => (
  <GridItem {...gridItemProps}>{children}</GridItem>
);

interface PropertyGridTileProps extends GridProps {
  readonly children: ReactNode;
}

const PropertyGridTile = ({
  children,
  ...gridProps
}: PropertyGridTileProps) => (
  <Tile
    h="full"
    display="grid"
    gridTemplateColumns={{
      base: `minmax(6rem, 1fr) 1fr`,
      md: `minmax(6rem, auto) 1fr`,
    }}
    {...gridProps}
  >
    {children}
  </Tile>
);

const PropertyGridItem = ({ children }: { readonly children: ReactNode }) => (
  <PropertyGridGridItem>
    <PropertyGridTile>{children}</PropertyGridTile>
  </PropertyGridGridItem>
);

const PropertyGridItemHeader = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <TileHeader w="full" minW={24}>
    {children}
  </TileHeader>
);

const PropertyGridItemValue = ({
  children,
}: {
  readonly children: ReactNode;
}) => <Text w="full">{children}</Text>;

export default {
  Grid: PropertyGridGrid,
  GridItem: PropertyGridGridItem,
  Tile: PropertyGridTile,
  Item: PropertyGridItem,
  ItemHeader: PropertyGridItemHeader,
  ItemValue: PropertyGridItemValue,
};
