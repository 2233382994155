import { useEffect, useRef, RefObject } from "react";

interface FocusHandler {
  readonly ref: RefObject<HTMLElement>;
  readonly handler: (event: FocusEvent) => void;
}

const useFocusOutside = ({ ref, handler }: FocusHandler) => {
  const eventListenerRef = useRef<(event: FocusEvent) => void>(() => null);

  useEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    eventListenerRef.current = (event: FocusEvent) => {
      // https://stackoverflow.com/questions/61164018/typescript-ev-target-and-node-contains-eventtarget-is-not-assignable-to-node

      // Here we check that the element losing focus is the ref we passed in, or any of it's descendants
      // We also check that the element gaining focus is not the ref we passed in, or any of it's decendants
      if (
        (ref.current === event.relatedTarget ||
          ref.current?.contains(event.relatedTarget as Node)) &&
        !ref.current?.contains(event.target as Node)
      ) {
        handler?.(event);
      }
    };
  }, [ref, handler]);

  useEffect(() => {
    // Create event listener for key
    const eventListener: (this: Window, event: FocusEvent) => void = (
      event,
    ) => {
      eventListenerRef.current(event);
    };

    window.addEventListener(`focusin`, eventListener);
    return () => {
      window.removeEventListener(`focusin`, eventListener);
    };
  }, []);
};
export default useFocusOutside;
