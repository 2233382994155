import { useTranslation } from "react-i18next";

import { StackProps, useBreakpointValue } from "@chakra-ui/react";

import { CTACard } from "@/components/common";
import { BidPageBuyerActivityPlaceStandingBidCtaCardBidFragment } from "@/gql";
import { useModal } from "@/hooks";

export const PlaceStandingBidCTACard = ({
  bid,
}: {
  readonly bid: BidPageBuyerActivityPlaceStandingBidCtaCardBidFragment;
}) => {
  const { t } = useTranslation();
  const { modals, onOpenModal } = useModal();

  const onClick = () => {
    onOpenModal(
      modals.placeStandingBid(bid.company, {
        numShares: bid.numShares,
        pricePerShare: bid.pricePerShare / 100,
      }),
    )();
  };

  const direction: StackProps["direction"] = useBreakpointValue(
    { base: `column`, sm: `row` },
    { ssr: false },
  );

  return (
    <CTACard
      heading={t(`place_standing_bid_after_bid_prompt`)}
      description={t(`place_standing_bid_after_bid_description`, {
        companyName: bid.company.name,
      })}
      direction={direction}
      action={{
        ctaText: t(`place_standing_bid`),
        onClick,
      }}
    />
  );
};
