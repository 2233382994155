import { t } from "i18next";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";

import {
  HiiveModalHeader,
  HiiveModalFooter,
  ButtonFooterWrapper,
  HiiveButton,
  HiiveButtonProps,
} from "@/components/common";

type ConfirmationModalProps = Omit<ModalProps, "children"> & {
  body: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  title: React.ReactNode;
  cancelButtonProps?: Partial<HiiveButtonProps>;
  confirmationButtonProps?: Partial<HiiveButtonProps>;
};

const ConfirmationModal = ({
  body,
  cancelButtonProps,
  confirmationButtonProps,
  onCancel,
  onConfirm,
  title,
  ...props
}: ConfirmationModalProps) => (
  <Modal {...props}>
    <ModalOverlay />
    <ModalContent>
      <HiiveModalHeader closeModal={onCancel}>{title}</HiiveModalHeader>
      <ModalBody>{body}</ModalBody>
      <HiiveModalFooter>
        <ButtonFooterWrapper>
          <HiiveButton
            onClick={onCancel}
            size="xl"
            sentryLabel={cancelButtonProps?.sentryLabel ?? ``}
            variant="rounded-outline-grey"
            {...cancelButtonProps}
          >
            {t(`cancel`)}
          </HiiveButton>
          <HiiveButton
            onClick={onConfirm}
            size="xl"
            sentryLabel={confirmationButtonProps?.sentryLabel ?? ``}
            variant="rounded-solid-salmon"
            {...confirmationButtonProps}
          />
        </ButtonFooterWrapper>
      </HiiveModalFooter>
    </ModalContent>
  </Modal>
);

export default ConfirmationModal;
