import { ReactNode } from "react";

import { Text } from "@chakra-ui/react";

import WarningWrapper from "./WarningWrapper";

const PleaseNote = ({ children }: { readonly children: ReactNode }) => (
  <WarningWrapper>
    <Text textStyle="deprecated-heading-lg" color="h-salmon-pink">
      Please Note:
    </Text>
    {children}
  </WarningWrapper>
);

export default PleaseNote;
