import { useField } from "formik";

import { FormErrorMessage, FormErrorMessageProps } from "@chakra-ui/react";

export interface FormFieldErrorProps extends FormErrorMessageProps {
  readonly name: string;
}

const FormFieldError = ({
  name,
  ...errorMessageProps
}: FormFieldErrorProps) => {
  const [_field, { error }] = useField(name);

  return error ? (
    <FormErrorMessage {...errorMessageProps}>{error}</FormErrorMessage>
  ) : null;
};

export default FormFieldError;
