import { CaretDown, CaretRight } from "@phosphor-icons/react";

import { Box, Flex, useDisclosure, VStack } from "@chakra-ui/react";

import { MarkdownDocument } from "@/components/common";
import { CheckboxInput } from "@/components/form";
import styles from "@/styles/company-eligibility-criteria-markdown-styles.module.css";

interface CompanyEligibiltyCriteriaCheckboxProps {
  readonly name: string;
  readonly label: string;
  readonly eligibilityCriterionText: string;
}

const CompanyEligibilityCriteriaCheckbox = ({
  name,
  label,
  eligibilityCriterionText,
}: CompanyEligibiltyCriteriaCheckboxProps) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <VStack gap={2} align="flex-start" w="full">
      <Flex gap={2} align="flex-start" w="full" justify="space-between">
        <CheckboxInput name={name} label={label} />
        <Box onClick={onToggle} cursor="pointer">
          {isOpen ? (
            <CaretDown size={24} weight="bold" />
          ) : (
            <CaretRight size={24} weight="bold" />
          )}
        </Box>
      </Flex>
      {isOpen && (
        <Box className={styles[`company-eligibility-criteria-markdown`]} ml={9}>
          <MarkdownDocument markdown={eligibilityCriterionText} />
        </Box>
      )}
    </VStack>
  );
};

export default CompanyEligibilityCriteriaCheckbox;
