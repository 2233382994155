import { AnimatePresence } from "framer-motion";
import intersection from "lodash/intersection";

import { HStack } from "@chakra-ui/react";

import {
  FadeInOut,
  MessageNotificationCount,
  TotalCount,
  WithQuery,
} from "@/components/common";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import {
  MessageTopicType,
  useUnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery,
} from "@/gql";

const UnaccreditedSellerInquiryNotificationsCountV2 = ({
  listingId,
}: {
  listingId: string;
}) => {
  const {
    newInquiryIds,
    newMessagesCountByInquiryId,
  } = useUnaccreditedSellerInlineNotifications();
  const query = useUnaccreditedSellerByCompanyIdInquiryNotificationsCountQuery({
    variables: {
      topicFilter: { id: listingId, type: MessageTopicType.Listing },
    },
  });

  return (
    <WithQuery query={query} hideLoadingSpinner>
      {({ data: { myDiscussions } }) => {
        const inquiryIds = myDiscussions.map(({ id }) => id);

        const numNewInquiries = intersection(newInquiryIds, inquiryIds).length;
        const hasNewInquiries = numNewInquiries > 0;

        const numNewMessages = inquiryIds.reduce(
          (prev, curr) => prev + (newMessagesCountByInquiryId[curr] || 0),
          0,
        );

        const hasNewMessages = numNewMessages > 0;

        const totalInquiries = myDiscussions.length;
        const hasInquiries = totalInquiries > 0;

        return (
          <HStack>
            <AnimatePresence mode="wait">
              {hasNewMessages && (
                <FadeInOut key={numNewMessages}>
                  <MessageNotificationCount count={numNewMessages} />
                </FadeInOut>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {hasInquiries && (
                <FadeInOut>
                  <TotalCount
                    totalCount={totalInquiries}
                    hasNewNotifications={hasNewInquiries}
                  />
                </FadeInOut>
              )}
            </AnimatePresence>
          </HStack>
        );
      }}
    </WithQuery>
  );
};

export default UnaccreditedSellerInquiryNotificationsCountV2;
