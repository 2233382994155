import { createContext, ReactNode, useContext, useMemo } from "react";

import Link from "next/link";

import {
  Box,
  Card,
  CardBody,
  CardBodyProps,
  CardFooter,
  CardFooterProps,
  HStack,
  Image,
  Text,
  useMultiStyleConfig,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { FocusedShareDetailsHeaderCompanyFragment } from "@/gql";
import { makeUrl } from "@/utils";

type ShareDetailsVariant =
  | "listing"
  | "listing-preview"
  | "bid"
  | "transaction"
  | "discussion"
  | "base";

interface ShareDetailsHeaderProps {
  readonly variant?: ShareDetailsVariant;
  readonly title: string;
  readonly children?: ReactNode;
  readonly company?: FocusedShareDetailsHeaderCompanyFragment;
}

interface ShareDetailsCardProps {
  readonly variant: ShareDetailsVariant;
  readonly children?: ReactNode;
}

interface ShareDetailsCardHeaderCardProps {
  readonly variant?: ShareDetailsVariant;
  readonly children?: ReactNode;
}

interface ShareDetailsCardContext {
  readonly variant: ShareDetailsVariant;
}

const ShareDetailsCardContext = createContext<ShareDetailsCardContext>({
  variant: `listing`,
});

const ShareDetailsHeaderCompanyText = ({
  company,
}: {
  readonly company: NonNullable<ShareDetailsHeaderProps["company"]>;
}) => (
  <Link href={makeUrl(company)}>
    <HStack cursor="pointer" px={{ base: 4, md: 5 }} py={2} w="full">
      {company.logoUrl && (
        <Image
          h={7}
          src={company.logoUrl}
          alt={company.name}
          mr={{ base: 0, sm: 1 }}
          borderRadius={2}
        />
      )}
      <Text
        as="h1"
        w="full"
        data-testid="company-name"
        textStyle={{ base: `heading-lg`, sm: `heading-xl` }}
        color="grey.900"
      >
        {company.name}
      </Text>
    </HStack>
  </Link>
);

const ShareDetailsCardHeader = ({
  title,
  variant,
  children,
  company,
}: ShareDetailsHeaderProps) => {
  const { variant: contextVariant } = useContext(ShareDetailsCardContext);

  const styles = useMultiStyleConfig(`ShareDetailsHeader`, {
    variant: variant ?? contextVariant,
  });

  return (
    <Box __css={styles.container}>
      <Wrap __css={styles.header}>
        <WrapItem flexGrow="1" alignItems="center">
          <Text data-testid="listing-id">{title}</Text>
        </WrapItem>
        {company && (
          <WrapItem ml="auto">
            <Box __css={styles.logo}>
              <ShareDetailsHeaderCompanyText company={company} />
            </Box>
          </WrapItem>
        )}
      </Wrap>
      {children}
    </Box>
  );
};

const ShareDetailsCardHeaderCard = ({
  children,
  variant,
}: ShareDetailsCardHeaderCardProps) => {
  const { variant: contextVariant } = useContext(ShareDetailsCardContext);

  const styles = useMultiStyleConfig(`ShareDetailsHeader`, {
    variant: variant ?? contextVariant,
  });

  return <Box __css={styles.item}>{children}</Box>;
};

const ShareDetailsCardContent = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <Box
    borderColor="grey.200"
    borderRightWidth="0.5px"
    borderLeftWidth="0.5px"
    borderBottomWidth="0.5px"
    borderBottomRadius="md"
    background="white"
    marginTop="-25px"
    paddingTop={6}
  >
    {children}
  </Box>
);

const ShareDetailsCardContentSection = ({
  children,
  ...restProps
}: {
  readonly children: ReactNode;
} & CardBodyProps) => <CardBody {...restProps}>{children}</CardBody>;

const ShareDetailsCardContentFooter = ({
  children,
  variant,
  ...restProps
}: {
  readonly children: ReactNode;
  readonly variant?: ShareDetailsVariant;
} & CardFooterProps) => {
  const { variant: contextVariant } = useContext(ShareDetailsCardContext);

  const styles = useMultiStyleConfig(`ShareDetails`, {
    variant: variant ?? contextVariant,
  });

  return (
    <CardFooter __css={styles.footer} {...restProps}>
      {children}
    </CardFooter>
  );
};

const ShareDetailsCard = ({ children, variant }: ShareDetailsCardProps) => {
  const contextValue = useMemo(() => ({ variant }), [variant]);

  return (
    <ShareDetailsCardContext.Provider value={contextValue}>
      <Card variant="no-border">{children}</Card>
    </ShareDetailsCardContext.Provider>
  );
};

export default {
  Card: ShareDetailsCard,
  Header: ShareDetailsCardHeader,
  HeaderCard: ShareDetailsCardHeaderCard,
  Content: ShareDetailsCardContent,
  ContentSection: ShareDetailsCardContentSection,
  ContentFooter: ShareDetailsCardContentFooter,
};
