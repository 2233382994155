import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanySecuritySpecialistContactCompanyFragment,
  InvestorType,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  getSecuritySpecialistContact,
  getMarketAnalystContact,
  getCC,
} from "@/utils";

const CompanySecuritySpecialistContact = withCurrentActor(
  ({
    actor,
    company,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly company: CompanySecuritySpecialistContactCompanyFragment;
  }) => {
    const { t } = useTranslation();

    const isUnaccreditedSeller =
      actor.investorType === InvestorType.UnaccreditedSeller;
    const securitySpecialistEmail = getSecuritySpecialistContact(company);
    const marketAnalystEmail = getMarketAnalystContact(company);

    const email = isUnaccreditedSeller
      ? marketAnalystEmail
      : securitySpecialistEmail;
    const cc = getCC(company, isUnaccreditedSeller);

    return (
      <Text fontSize={[`sm`, `md`]}>
        Contact{` `}
        <MailtoLink
          fontWeight="medium"
          email={email}
          cc={cc}
          subject={t(`trading_for`, {
            companyName: company.name,
          })}
        >
          {isUnaccreditedSeller ? marketAnalystEmail : securitySpecialistEmail}
        </MailtoLink>
        {` `} for any questions on trading for {company.name}.
      </Text>
    );
  },
);

export default CompanySecuritySpecialistContact;
