import { Flex } from "@chakra-ui/react";

import { ActivityCardSkeleton, Skeleton } from "@/components/common";

export const MarketActivitySkeleton = () => (
  <Flex direction="column" gap={4} w="full">
    <Skeleton h="30px" maxW="25%" />
    <Skeleton h="20px" maxW="30%" />

    <Skeleton h={14} w="full" />

    {[...Array(10).keys()].map((index) => (
      <ActivityCardSkeleton key={index} />
    ))}
  </Flex>
);
