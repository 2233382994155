import { MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Flex,
  HStack,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from "@chakra-ui/react";

import { CompanyCombobox } from "@/components/companies";
import { Combobox } from "@/components/form";
import {
  HoldingDetailsPageCompanyFragment,
  ListCompaniesOrderBy,
  useHoldingDetailsComboboxListCompaniesLazyQuery,
} from "@/gql";
import { useColors, useCombobox, useDebounce } from "@/hooks";

import { HoldingField } from "./types";

const getVariables = (searchText: string) => ({
  first: 20,
  orderBy: ListCompaniesOrderBy.MarketActivity,
  searchText,
});

// Dummy combobox option to add a company manually
const manualAddCompanyOption = {
  id: `manualAddCompany`,
  logoUrl: null,
  name: ``,
  companyId: null,
};

export const AddHoldingCombobox = ({
  holdings = [],
  onSelectCompany,
  onAddCompanyManually,
}: {
  readonly holdings: (HoldingField & { id: string })[];
  readonly onSelectCompany: (
    company: HoldingDetailsPageCompanyFragment,
  ) => void;
  readonly onAddCompanyManually: (companyName: string) => void;
}) => {
  const { t } = useTranslation();
  const { debounce, isDebouncing } = useDebounce();

  const [search, setSearch] = useState<string>(``);

  const [
    loadCompanies,
    { data, loading },
  ] = useHoldingDetailsComboboxListCompaniesLazyQuery({
    fetchPolicy: `no-cache`,
  });

  useEffect(() => {
    loadCompanies({
      variables: getVariables(``),
    });
  }, []);

  const handleChangeSearch = (search: string) => {
    setSearch(search);
    debounce(() =>
      loadCompanies({
        variables: getVariables(search),
      }),
    );
  };

  const currentHoldingCompanyIds = useMemo(
    () =>
      new Set<string>(
        holdings.flatMap(({ companyId }) => (companyId ? [companyId] : [])),
      ),
    [holdings],
  );

  const companyEdges = data?.listCompanies?.edges || [];

  const companiesNotInHoldings = useMemo(
    () =>
      companyEdges.flatMap((edge) => {
        if (!edge?.node || currentHoldingCompanyIds.has(edge.node.id))
          return [];
        return [edge.node];
      }),
    [companyEdges, currentHoldingCompanyIds],
  );

  const isLoading = loading || isDebouncing;

  const {
    inputProps,
    menuProps,
    labelProps,
    itemProps,
    actions,
  } = useCombobox<HoldingDetailsPageCompanyFragment>({
    items: [...companiesNotInHoldings, manualAddCompanyOption],
    itemToString: (item) => item.name,
    getItemKey: (item) => item.id,
    onSelectItem: (item) => {
      if (!item) return;
      actions.blur();

      if (item.id === `manualAddCompany`) {
        onAddCompanyManually(search);
      } else {
        onSelectCompany(item);
      }

      handleChangeSearch(``);
    },
    onChangeInputValue: handleChangeSearch,
    inputValue: search,
    isLoading,
  });

  const [grey500] = useColors([`grey.500`]);

  const isDisabled = holdings.length >= 4;

  return (
    <>
      <Combobox.Label srOnly {...labelProps}>
        {t(`search_company`)}
      </Combobox.Label>
      <Combobox.Container h="auto">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <HStack spacing={2} opacity={isDisabled ? 0.5 : 1}>
              <MagnifyingGlass size={20} color={grey500} />
            </HStack>
          </InputLeftElement>

          <Combobox.Input
            placeholder={t(`search_company`)}
            isDisabled={isDisabled}
            userSelect={isDisabled ? `none` : `auto`}
            {...inputProps}
          />
        </InputGroup>
        <Combobox.Menu
          isLoading={isLoading}
          fallback={<CompanyCombobox.Skeleton />}
          maxH={242}
          isLazy={false}
          {...menuProps}
        >
          {companiesNotInHoldings.map((item, index) => (
            <CompanyCombobox.Item
              key={item.id}
              item={item}
              index={index}
              {...itemProps}
            >
              <CompanyCombobox.ItemIcon company={item} />
              <Text as="span">{item.name}</Text>
            </CompanyCombobox.Item>
          ))}
          {companiesNotInHoldings.length > 0 ? (
            <CompanyCombobox.Item
              key={manualAddCompanyOption.id}
              index={companiesNotInHoldings.length}
              item={manualAddCompanyOption}
              display="flex"
              {...itemProps}
            >
              <Flex gap={2}>
                <Text as="span" textStyle="text-sm">
                  {t(`cant_find_your_company`)}
                </Text>
                <Text as="span" color="salmon.900">
                  + {t(`add_company_manually`)}
                </Text>
              </Flex>
            </CompanyCombobox.Item>
          ) : (
            <CompanyCombobox.Item
              key={manualAddCompanyOption.id}
              index={companiesNotInHoldings.length}
              item={manualAddCompanyOption}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              {...itemProps}
            >
              <VStack spacing={3} p={3}>
                <Text textStyle="text-sm">
                  {t(`company_not_found_on_hiive`)}
                </Text>
                <Text as="span" color="salmon.900">
                  + {t(`add_company_manually`)}
                </Text>
              </VStack>
            </CompanyCombobox.Item>
          )}
        </Combobox.Menu>
      </Combobox.Container>
    </>
  );
};
