export enum UnaccreditedDashboardRoutes {
  ActiveBids = `/dashboard/active-bids`,
  Transactions = `/dashboard/transactions`,
  StandingBids = `/dashboard/standing-bids`,
  Inquiries = `/dashboard/inquiries`,
  PastBids = `/dashboard/past-bids`,
  Overview = `/dashboard/overview`,
}

export enum UnaccreditedDashboardRoutesV2 {
  ActiveBids = `/active-bids`,
  Transactions = `/transactions`,
  StandingBids = `/standing-bids`,
  Inquiries = `/inquiries`,
  PastBids = `/past-bids`,
}
