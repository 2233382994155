import React, { ReactElement } from "react";

import { Box, FormLabel } from "@chakra-ui/react";

import { ItemList } from "@/components/form";
import { useListbox } from "@/hooks";

import Listbox from "./Select/Listbox";

const SimpleListbox = <TItem,>({
  placeholder,
  isLoading,
  items,
  getItemKey,
  itemToString,
  label,
  value,
  onItemSelected,
  itemRenderer,
  inputContainerStyle,
  itemsContainerStyle,
}: {
  readonly placeholder: string;
  readonly isLoading?: boolean;
  readonly items: ItemList<TItem>;
  readonly itemToString: (item: TItem) => string;
  readonly getItemKey: (item: TItem) => string;
  readonly label?: string;
  readonly value: TItem | null;
  readonly onItemSelected: (item: TItem) => void;
  readonly itemRenderer?: (item: TItem) => ReactElement;
  readonly inputContainerStyle?: React.CSSProperties;
  readonly itemsContainerStyle?: React.CSSProperties;
}) => {
  const { buttonProps, menuProps, labelProps, itemProps } = useListbox({
    onClickItem: onItemSelected,
    items,
    itemToString,
    selectedItem: value,
    itemRenderer,
    buttonStyle: inputContainerStyle,
    menuStyle: itemsContainerStyle,
  });

  return (
    <Box w="full">
      <FormLabel {...labelProps}>{label}</FormLabel>
      <Listbox.Container>
        <Listbox.Button placeholder={placeholder} {...buttonProps} />
        <Listbox.Menu isLoading={isLoading} {...menuProps}>
          {items.map((item, index) => (
            <Listbox.Item
              key={getItemKey(item)}
              item={item}
              index={index}
              {...itemProps}
            >
              {!!itemRenderer ? itemRenderer(item) : itemToString(item)}
            </Listbox.Item>
          ))}
        </Listbox.Menu>
      </Listbox.Container>
    </Box>
  );
};

export default SimpleListbox;
