import { useField } from "formik";
import { useTranslation } from "react-i18next";

import {
  Flex,
  FormControl,
  FormErrorMessage,
  GridItem,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import { RoleType } from "@/gql";

import { RoleDefinitionTable } from "./RoleDefinitionTable";

interface RoleSelectorProps {
  readonly disabled: boolean;
  readonly label: string;
  readonly target: RoleType.Admin | RoleType.Trader | RoleType.Viewer;
}

const RoleSelector = ({ disabled, label, target }: RoleSelectorProps) => {
  const [field, _, { setValue }] = useField(`role`);

  return (
    <Flex
      align="center"
      justifyContent="center"
      w="full"
      h="full"
      borderRadius="md"
      borderColor={field.value === target ? `salmon.900` : `grey.700`}
      borderWidth={field.value === target ? 2 : 1}
      cursor="pointer"
      opacity={disabled ? 0.4 : 1}
      p={5}
      onClick={disabled ? undefined : () => setValue(target)}
    >
      <Text textAlign="center" textStyle="heading-md">
        {label}
      </Text>
    </Flex>
  );
};

export const RoleInput = ({
  isDisabled = false,
}: {
  readonly isDisabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [_, meta] = useField(`role`);
  const { error, touched } = meta;

  return (
    <FormControl id="role" width="auto" isInvalid={(error && touched) || false}>
      <VStack alignItems="flex-start" spacing={2.5}>
        <Text textStyle="heading-md">{t(`select_a_role`)}</Text>
        <SimpleGrid columns={3} columnGap={4} w="full">
          <GridItem colSpan={1}>
            <RoleSelector
              disabled={isDisabled}
              label={t(`view_only`)}
              target={RoleType.Viewer}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <RoleSelector
              disabled={isDisabled}
              label={t(`trader`)}
              target={RoleType.Trader}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <RoleSelector
              disabled={isDisabled}
              label={t(`admin`)}
              target={RoleType.Admin}
            />
          </GridItem>
        </SimpleGrid>
        <FormErrorMessage>{error}</FormErrorMessage>
        <Spacer display={{ base: `block` }} h={5} />
        <RoleDefinitionTable />
      </VStack>
    </FormControl>
  );
};
