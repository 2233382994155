import { defineStyleConfig } from "@chakra-ui/react";

export const ActivityCardBadge = defineStyleConfig({
  variants: {
    listing: {
      bg: `olive.50`,
      borderColor: `olive.900`,
      color: `olive.900`,
    },
    bid: {
      bg: `plum.50`,
      borderColor: `plum.900`,
      color: `plum.900`,
    },
    discussion: {
      bg: `grey.50`,
      borderColor: `grey.900`,
      color: `grey.900`,
    },
    transaction: {
      bg: `teal.50`,
      borderColor: `teal.900`,
      color: `teal.900`,
    },
  },
});
