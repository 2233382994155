import { Card, CardBody, Flex, HStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

export const BidCardSkeleton = () => (
  <Card variant="darkened-footer">
    <CardBody>
      <Flex direction="column" gap={6}>
        <HStack spacing={4} mb={2}>
          <Skeleton h="2rem" maxW="2rem" />
          <Skeleton h="2rem" maxW="15rem" />
        </HStack>

        <Skeleton h="8rem" />
        <Skeleton h="62px" />
        <Skeleton h="8rem" />
      </Flex>
    </CardBody>
  </Card>
);
