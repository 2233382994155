import { t } from "i18next";

import Link from "next/link";

import { Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { OnboardingContainer } from "@/components/onboarding";

const PasswordUpdateConfirmedPage = () => (
  <OnboardingContainer heading={t(`new_password_set`)}>
    <VStack spacing={14} mt={7}>
      <Text align="center" textStyle="deprecated-text-xl" color="h-dark-grey">
        {t(`sign_in_with_new_password`)}
      </Text>
      <Link href="/login">
        <HiiveButton
          size="md"
          variant="rounded-outline-salmon"
          sentryLabel="[PasswordUpdateConfirmedPage] Sign In"
        >
          {t(`sign_in`)}
        </HiiveButton>
      </Link>
    </VStack>
  </OnboardingContainer>
);

export default PasswordUpdateConfirmedPage;
