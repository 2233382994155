import { VStack } from "@chakra-ui/react";

import Skeleton from "./Skeleton";

const TextInputSkeleton = () => (
  <VStack alignItems="flex-start" pb={1} w="full">
    <Skeleton h="18px" w="144px" py={0.5} />
    <Skeleton h={10} />
  </VStack>
);

export default TextInputSkeleton;
