import { CardBody, CardHeader, HStack, Text } from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardCompany,
  ActivityCardListingCardFooter,
  ActivityCardListingNumShareAndPriceDetails,
} from "@/components/common";
import { UserActivityListingFragment } from "@/gql";

export const DashboardYourListingCard = ({
  listing,
}: {
  readonly listing: UserActivityListingFragment;
}) => (
  <ActivityCard href={`/listings/${listing.id}`}>
    <CardHeader>
      <HStack justifyContent="space-between">
        <HStack>
          <ActivityCardBadge variant="listing" title="Listing" />
          <Text textStyle="text-lg" color="grey.900">
            {listing.displayId}
          </Text>
        </HStack>
        <ActivityCardCompany company={listing.company} />
      </HStack>
    </CardHeader>
    <CardBody>
      <ActivityCardListingNumShareAndPriceDetails listing={listing} />
    </CardBody>
    <ActivityCardListingCardFooter listing={listing} />
  </ActivityCard>
);
