/* eslint-disable @typescript-eslint/no-explicit-any */
import { CalendarBlank } from "@phosphor-icons/react";
import { forwardRef, LegacyRef, ComponentType } from "react";
import Picker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";

import styles from "./DatePicker.module.css";

// Note: workaround needed until react-datepicker package updates
// for @types/react v18+
// https://github.com/Hacker0x01/react-datepicker/issues/3784
const ReactDatePicker = Picker as ComponentType<ReactDatePickerProps>;

const DatePickerInput = forwardRef((values, ref) => (
  <InputGroup
    onClick={(values as any).onClick}
    ref={ref as LegacyRef<HTMLDivElement>}
  >
    <Input value={(values as any).value} />
    <InputRightElement>
      <CalendarBlank size={22} fill="#2D3748" />
    </InputRightElement>
  </InputGroup>
));

export const DatePicker = ({ ...props }: ReactDatePickerProps) => (
  <ReactDatePicker
    showPopperArrow={false}
    wrapperClassName={styles.reactDatePickerInputWrapper}
    customInput={<DatePickerInput />}
    {...props}
  />
);
