import { User, Bank } from "@phosphor-icons/react";

import { Center } from "@chakra-ui/react";

import { EntityType } from "@/gql";

type TransactingEntityItemIconProps = {
  type: EntityType;
};

const TransactingEntityItemIcon = ({
  type,
}: TransactingEntityItemIconProps) => {
  const isIndividual = type === EntityType.Individual;

  return (
    <Center bg="grey.100" h={12} w={12} borderRadius="100%">
      {isIndividual ? <User size={28} /> : <Bank size={28} />}
    </Center>
  );
};

export default TransactingEntityItemIcon;
