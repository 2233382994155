import { match, P } from "ts-pattern";

import { HStack, Link, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  AcceptDiscussionRequestFormDiscussionFragment,
  BidPageBidByIdDocument,
  DiscussionPageDiscussionByIdDocument,
  DiscussionPermission,
  InvestorType,
  ListingPageListingByIdDocument,
  useAcceptDiscussionRequestMutation,
  UserWithInstitutionFragment,
} from "@/gql";
import { iHaveEntityPermission, useMutationWithError } from "@/hooks";

const getCounterpartyText = (
  actor: UserWithInstitutionFragment,
  discussion: AcceptDiscussionRequestFormDiscussionFragment,
) =>
  match(discussion)
    .with({ initiatorId: actor.id }, () => null)
    .with(
      { topic: { __typename: `Listing`, sellerId: actor.id } },
      () => `A buyer has initiated an inquiry in relation to your Listing.`,
    )
    .with(
      { topic: { __typename: `Listing`, sellerId: P.not(actor.id) } },
      () =>
        `A seller has initiated an inquiry in relation to your Bid on their Listing.`,
    )
    .with(
      { topic: { __typename: `StandingBid`, buyerId: actor.id } },
      () =>
        `A seller has initiated an inquiry in relation to your Standing Bid.`,
    )
    .with(
      {
        topic: {
          __typename: `StandingBid`,
          buyerInstitutionId: actor.institutionId,
        },
      },
      () =>
        `A seller has initiated an inquiry in relation to ${
          actor.institution?.legalName || `your institution`
        }'s Standing Bid.`,
    )
    .otherwise(() => {
      throw new Error(`Unhandled discussion state in getCounterpartyText`);
    });

const getCounterpartyTermsAndServices = (
  actor: UserWithInstitutionFragment,
  discussion: AcceptDiscussionRequestFormDiscussionFragment,
) =>
  match({ discussion, actor })
    .with(
      {
        discussion: { topic: { __typename: `Listing`, sellerId: actor.id } },
        actor: { investorType: InvestorType.UnaccreditedSeller },
      },
      () => `Hiive (Seller Only) Customer Terms and Conditions.`,
    )
    .otherwise(() => `Hiive Customer Terms and Conditions.`);

export const AcceptDiscussionRequestForm = withCurrentActor(
  ({
    discussion,
    actor,
  }: {
    readonly discussion: AcceptDiscussionRequestFormDiscussionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const [acceptDiscussionRequestMutation] = useMutationWithError(
      useAcceptDiscussionRequestMutation(),
      `acceptDiscussionRequest`,
    );

    const handleAcceptDiscussionRequest = () => {
      acceptDiscussionRequestMutation({
        variables: { discussionId: discussion.id },
        refetchQueries: [
          BidPageBidByIdDocument,
          ListingPageListingByIdDocument,
          DiscussionPageDiscussionByIdDocument,
        ],
      });
    };

    const canAcceptDiscussionRequest = iHaveEntityPermission(
      discussion,
      DiscussionPermission.AcceptDiscussionRequest,
    );

    return (
      <HStack
        w="full"
        borderRadius="md"
        bg="grey.50"
        borderWidth={0.5}
        borderColor="grey.200"
        p={5}
      >
        <VStack alignItems="start">
          <Text textStyle="heading-lg" textTransform="uppercase">
            Inquiry request
          </Text>
          <Text>
            {getCounterpartyText(actor, discussion)} Reading this message will
            constitute an Introduction Event under the{` `}
            <Link
              href="/terms-and-conditions"
              textDecoration="underline"
              target="_blank"
            >
              {getCounterpartyTermsAndServices(actor, discussion)}
            </Link>
          </Text>
        </VStack>
        {canAcceptDiscussionRequest && (
          <HiiveButton
            variant="rounded-solid-salmon"
            size="xl"
            w="full"
            onClick={handleAcceptDiscussionRequest}
            sentryLabel="[AcceptDiscussionRequestForm] Accept"
          >
            Accept
          </HiiveButton>
        )}
      </HStack>
    );
  },
);
