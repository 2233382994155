import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Box, Flex, CardFooter, Button } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  InvestorSuitabilityForm,
  useSuitabilityForm,
} from "@/components/suitability";
import { useCurrentActor, useCustomToast } from "@/hooks";

interface Props {
  onClose: () => void;
}

const SuitabilityForm: FC<Props> = ({ onClose }) => {
  const actor = useCurrentActor();
  const { t } = useTranslation();
  const { successToast } = useCustomToast();
  const onSuccess = () => {
    onClose();
    successToast(t`updated_suitability`);
  };
  const {
    formProps,
    questionTree,
    loading,
    country,
    renderDisclaimer,
  } = useSuitabilityForm({ onSuccess, user: actor });

  return (
    <Flex flex={1} alignItems="space-between" flexDirection="column">
      <Box flex={1} px={6} py={3}>
        <InvestorSuitabilityForm
          formProps={formProps}
          hideBorder
          loading={loading}
          questionTree={questionTree}
          country={country.name}
          renderDisclaimer={renderDisclaimer}
        />
      </Box>
      <Box>
        <CardFooter>
          <Flex w="full" justify="flex-end" gap={4}>
            <Button
              variant="rounded-outline-grey"
              size="xl"
              onClick={onClose}
              w={{ base: `full`, lg: 180 }}
              minW="auto"
            >
              {t(`cancel`)}
            </Button>
            <HiiveButton
              sentryLabel="[InvestorStatusPageV2/Submit]"
              variant="rounded-solid-salmon"
              type="submit"
              size="xl"
              maxW="unset"
              w={{ base: `full`, lg: `unset` }}
              isLoading={loading}
              onClick={formProps.handleSubmit}
            >
              {t(`save`)}
            </HiiveButton>
          </Flex>
        </CardFooter>
      </Box>
    </Flex>
  );
};

export default SuitabilityForm;
