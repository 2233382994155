import { ReactNode } from "react";

import { VStack } from "@chakra-ui/react";

const WarningWrapper = ({ children }: { readonly children: ReactNode }) => (
  <VStack
    py={5}
    px={5}
    borderColor="h-salmon-pink"
    borderWidth="1px"
    borderRadius="md"
    alignItems="flex-start"
    w="full"
  >
    {children}
  </VStack>
);

export default WarningWrapper;
