import { ReactNode } from "react";

import { HStack } from "@chakra-ui/react";

export const BannerCardWrapper = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <HStack w="full" h="full" spacing={0}>
    {children}
  </HStack>
);
