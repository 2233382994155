import {
  ActionsTile,
  ActionsTileButtonWrapper,
  HiiveButton,
  TileHeader,
} from "@/components/common";
import { BidBuyerActionsTileBidFragment, BidState } from "@/gql";
import { useCurrentActor, useModal } from "@/hooks";
import { getAvailableBidActions } from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";

const BidBuyerTileHeader = ({
  bid,
  isBuyer,
}: {
  readonly bid: BidBuyerActionsTileBidFragment;

  readonly isBuyer: boolean;
}) => {
  if (!!bid.brokerId && isBuyer) return <>Adjust Broker&lsquo;s bid</>;
  if (bid.state === BidState.Countered) return <>Withdraw your bid</>;
  return <>Adjust your bid</>;
};

export const BidBuyerActionsTile = ({
  bid,
}: {
  readonly bid: BidBuyerActionsTileBidFragment;
}) => {
  const actor = useCurrentActor();
  const { modals, onOpenModal } = useModal();
  const isBuyer = getIsBuyerForBid(actor, bid);
  const { canWithdrawBid, canModifyBid } = getAvailableBidActions(bid);

  const hasCounterBid = bid.state === BidState.Countered;

  return (
    <ActionsTile>
      <TileHeader>
        <BidBuyerTileHeader bid={bid} isBuyer={isBuyer} />
      </TileHeader>
      <ActionsTileButtonWrapper>
        {canModifyBid && !hasCounterBid && (
          <HiiveButton
            size="md"
            variant="rounded-outline-grey"
            onClick={onOpenModal(
              modals.modifyBid(bid.listing.company, bid.listing, bid),
            )}
            sentryLabel="[BidActions] Modify Bid"
          >
            Modify
          </HiiveButton>
        )}
        {canWithdrawBid && (
          <HiiveButton
            size="md"
            variant="rounded-outline-grey"
            onClick={onOpenModal(modals.withdrawBid(bid))}
            sentryLabel="[BidActions] Withdraw Bid"
          >
            Withdraw
          </HiiveButton>
        )}
      </ActionsTileButtonWrapper>
    </ActionsTile>
  );
};
