import { Box, BoxProps, Flex, RadioProps, useRadio } from "@chakra-ui/react";

const RadioCard = (props: { readonly boxProps?: BoxProps } & RadioProps) => {
  const { children, display, boxProps, ...restProps } = props;

  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="full" display={display} {...boxProps}>
      <input {...input} />
      <Flex
        {...checkbox}
        direction="column"
        align="center"
        justify="center"
        cursor="pointer"
        borderRadius="md"
        bg="white"
        textAlign="center"
        textStyle="heading-md"
        outline="1px solid"
        outlineOffset={-1}
        outlineColor="grey.700"
        _checked={{
          outlineOffset: -2,
          outline: `2px solid`,
          outlineColor: `salmon.900`,
        }}
        {...restProps}
      >
        {children}
      </Flex>
    </Box>
  );
};

export default RadioCard;
