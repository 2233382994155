import { isPossibleNumber } from "libphonenumber-js";

export const getIsValidRedirectURL = (redirect: string) =>
  redirect.match(/^\/(?!\/)/);

export const validPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return false;
  return isPossibleNumber(phoneNumber);
};

export const isUUIDV4 = (value: string) => value.match(/^[a-z,0-9,-]{36,36}$/);
