import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Box, Text } from "@chakra-ui/react";

import {
  AutomatedLOITransactionState,
  AutomatedSTNTransactionState,
  ManualLOITransactionState,
  ManualSTNTransactionState,
} from "@/components/postings";
import {
  DocumentType,
  SigningProcedure,
  TransactionStatesBidFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import {
  getIsCounterpartyForBid,
  getIsBrokerForBid,
  getIsBroker,
} from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";

import AutomatedLOIBuyerStates from "./AutomatedLOIBuyerStates";
import AutomatedLOISellerStates from "./AutomatedLOISellerStates";
import AutomatedSTNBuyerStates from "./AutomatedSTNBuyerStates";
import AutomatedSTNSellerStates from "./AutomatedSTNSellerStates";
import ManualLOIBuyerStates from "./ManualLOIBuyerStates";
import ManualLOISellerStates from "./ManualLOISellerStates";
import ManualSTNBuyerStates from "./ManualSTNBuyerStates";
import ManualSTNSellerStates from "./ManualSTNSellerStates";

const TransactionStates = ({
  bid,
}: {
  readonly bid: TransactionStatesBidFragment;
}) => {
  const actor = useCurrentActor();
  const isSSBPEnabled = useSellSideBrokerPortalEnabled();
  const { t } = useTranslation();
  const isBrokerForBid = getIsBrokerForBid({ user: actor, bid });
  const isBuyerForBid = getIsBuyerForBid(actor, bid);
  const isBidder = isBuyerForBid || isBrokerForBid;
  const isCounterparty = getIsCounterpartyForBid({ user: actor, bid });

  const isBroker = getIsBroker(actor);
  const { documentTypeV2: documentType, signingProcedure } =
    bid.transaction || {};

  const isDocumentReady = Boolean(bid.transaction?.document);

  return (
    <Box w="full" data-testid="transaction-states">
      {match({
        isBidder,
        isCounterparty,
        documentType,
        signingProcedure,
        documentReady: isDocumentReady,
        isBroker,
        isSSBPEnabled: !!isSSBPEnabled,
      })
        .with(
          {
            signingProcedure: SigningProcedure.Automated,
            documentReady: false,
          },
          () => <Text>{t`transaction_state_document_being_processed`}</Text>,
        )
        .with(
          {
            isBroker: true,
            isCounterparty: true,
            isSSBPEnabled: true,
            documentType: DocumentType.Loi,
            signingProcedure: SigningProcedure.Manual,
          },
          () => (
            <ManualLOISellerStates
              state={bid.transaction?.state as ManualLOITransactionState}
              bid={bid}
              isBroker
            />
          ),
        )
        .with(
          {
            isBroker: true,
            isCounterparty: true,
            isSSBPEnabled: true,
            documentType: DocumentType.Loi,
            signingProcedure: SigningProcedure.Automated,
          },
          () => (
            <AutomatedLOISellerStates
              state={bid.transaction?.state as AutomatedLOITransactionState}
              bid={bid}
              isBroker
            />
          ),
        )
        .with(
          {
            isBroker: true,
            isCounterparty: true,
            isSSBPEnabled: true,
            documentType: DocumentType.Stn,
            signingProcedure: SigningProcedure.Automated,
          },
          () => (
            <AutomatedSTNSellerStates
              state={bid.transaction?.state as AutomatedSTNTransactionState}
              bid={bid}
              isBroker={isCounterparty}
            />
          ),
        )
        .with(
          {
            isBroker: true,
            isCounterparty: true,
            isSSBPEnabled: true,
            documentType: DocumentType.Stn,
            signingProcedure: SigningProcedure.Manual,
          },
          () => (
            <ManualSTNSellerStates
              state={bid.transaction?.state as ManualSTNTransactionState}
              bid={bid}
              isBroker
            />
          ),
        )
        .with(
          {
            isBidder: true,
            isCounterparty: false,
            documentType: DocumentType.Loi,
            signingProcedure: SigningProcedure.Manual,
          },
          () => (
            <ManualLOIBuyerStates
              state={bid.transaction?.state as ManualLOITransactionState}
              bid={bid}
            />
          ),
        )
        .with(
          {
            isBidder: true,
            isCounterparty: false,
            documentType: DocumentType.Loi,
            signingProcedure: SigningProcedure.Automated,
          },
          () => (
            <AutomatedLOIBuyerStates
              state={bid.transaction?.state as AutomatedLOITransactionState}
              bid={bid}
            />
          ),
        )
        .with(
          {
            isBidder: true,
            isCounterparty: false,
            documentType: DocumentType.Stn,
            signingProcedure: SigningProcedure.Manual,
          },
          () => (
            <ManualSTNBuyerStates
              state={bid.transaction?.state as ManualSTNTransactionState}
              bid={bid}
            />
          ),
        )
        .with(
          {
            isBidder: true,
            isCounterparty: false,
            documentType: DocumentType.Stn,
            signingProcedure: SigningProcedure.Automated,
          },
          () => (
            <AutomatedSTNBuyerStates
              state={bid.transaction?.state as AutomatedSTNTransactionState}
              bid={bid}
            />
          ),
        )
        .with(
          {
            isBidder: false,
            isCounterparty: true,
            documentType: DocumentType.Loi,
            signingProcedure: SigningProcedure.Manual,
          },
          () => (
            <ManualLOISellerStates
              state={bid.transaction?.state as ManualLOITransactionState}
              bid={bid}
            />
          ),
        )
        .with(
          {
            isBidder: false,
            isCounterparty: true,
            documentType: DocumentType.Loi,
            signingProcedure: SigningProcedure.Automated,
          },
          () => (
            <AutomatedLOISellerStates
              state={bid.transaction?.state as AutomatedLOITransactionState}
              bid={bid}
            />
          ),
        )
        .with(
          {
            isBidder: false,
            isCounterparty: true,
            documentType: DocumentType.Stn,
            signingProcedure: SigningProcedure.Manual,
          },
          () => (
            <ManualSTNSellerStates
              state={bid.transaction?.state as ManualSTNTransactionState}
              bid={bid}
            />
          ),
        )
        .with(
          {
            isBidder: false,
            isCounterparty: true,
            documentType: DocumentType.Stn,
            signingProcedure: SigningProcedure.Automated,
          },
          () => (
            <AutomatedSTNSellerStates
              state={bid.transaction?.state as AutomatedSTNTransactionState}
              bid={bid}
            />
          ),
        )
        .otherwise(() => null)}
    </Box>
  );
};

export default TransactionStates;
