import { FeeBreakdownCommissionInfo } from "@/components/common";
import { ShareSeriesMakeupElement } from "@/components/postings";
import { Nullable, sumShareSeriesMakeup } from "@/utils";

interface ListingTransactionSummaryProps {
  readonly isHighFeesWarningVisible: boolean;
  readonly pricePerShare?: number | null;
  readonly shareSeriesMakeup: readonly Nullable<ShareSeriesMakeupElement>[];
  readonly listingId?: string;
  readonly companyId?: string;
}

const ListingTransactionSummary = ({
  isHighFeesWarningVisible,
  pricePerShare,
  shareSeriesMakeup,
  listingId,
  companyId,
}: ListingTransactionSummaryProps) => {
  const totalShares = sumShareSeriesMakeup(shareSeriesMakeup);

  return (
    <FeeBreakdownCommissionInfo
      numShares={totalShares}
      pricePerShare={pricePerShare}
      listingId={listingId}
      companyId={companyId}
      disclaimerVariant="createListingSeller"
      isHighFeesWarningVisible={isHighFeesWarningVisible}
    />
  );
};

export default ListingTransactionSummary;
