import { Form, FormikProps, FormikValues } from "formik";
import invert from "lodash/invert";
import keyBy from "lodash/keyBy";
import { ReactElement, useCallback, useMemo } from "react";
import { match } from "ts-pattern";
import * as Yup from "yup";

import { Box } from "@chakra-ui/react";

import { FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  CurrentContextDocument,
  CurrentSuitabilityDocument,
  InvestmentGoalQuestion,
  UserWithInstitutionFragment,
  useUpdateCaIndividualInvestmentGoalsMutation,
  useUpdateIndividualInvestmentGoalsMutation,
  useUpdateInstitutionInvestmentGoalsMutation,
} from "@/gql";
import { getUserCountry, getIsInstitutionUser } from "@/utils";

type SuitabilityCardFormValues = {
  readonly answers: Record<string, string>;
};

const defaultAnswerMapper = ({ answers }: SuitabilityCardFormValues) => ({
  optionIds: Object.keys(invert(answers)),
});

const SuitabilityCardForm = withCurrentActor(
  ({
    children,
    initialValues,
    onSuccess,
    questionIds,
    questions,
    actor,
  }: {
    readonly children: (props: FormikProps<FormikValues>) => ReactElement;
    readonly initialValues: SuitabilityCardFormValues;
    readonly onSuccess?: () => void;
    readonly questionIds?: readonly string[];
    readonly questions?: readonly InvestmentGoalQuestion[];
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const country = getUserCountry(actor);
    const isInstitutionUser = getIsInstitutionUser(actor);

    const caIndividualAnswerMapper = useCallback(
      ({ answers }: SuitabilityCardFormValues) => {
        const questionsHashMap = keyBy(questions, `id`);

        return {
          input: {
            options: Object.keys(answers).map((key) => {
              // Here we are checking to see if the option is custom. The expectation is
              // that the option is the first item in the array, since multiple text fields
              // in a single option doesnt make sense.
              const [option] = questionsHashMap[key].options;

              if (option?.custom)
                return {
                  text: answers[key],
                  optionId: option.id ?? ``,
                };

              return {
                optionId: answers[key],
              };
            }),
          },
        };
      },
      [questions],
    );

    const validationSchema = useMemo(
      () =>
        Yup.object({
          answers: Yup.lazy(() => {
            if (!questionIds) return Yup.object();

            return Yup.object().shape(
              questionIds.reduce(
                (prevIds, id) => ({
                  ...prevIds,
                  [id]: Yup.string().required(),
                }),
                {},
              ),
            );
          }),
        }),
      [questionIds],
    );

    return match({
      country: country.name,
      isInstitutionUser,
    })
      .with(
        {
          isInstitutionUser: false,
          country: `CA`,
        },
        () => (
          <FormikQL
            mutation={useUpdateCaIndividualInvestmentGoalsMutation({
              refetchQueries: [
                CurrentContextDocument,
                CurrentSuitabilityDocument,
              ],
            })}
            mutationNames={[`updateCaIndividualInvestmentGoals`]}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSuccess={() => onSuccess?.()}
            mapVariables={caIndividualAnswerMapper}
          >
            {(formikProps) => (
              <Box as={Form} display="contents">
                {children({ ...formikProps })}
              </Box>
            )}
          </FormikQL>
        ),
      )
      .with(
        {
          isInstitutionUser: false,
        },
        () => (
          <FormikQL
            mutation={useUpdateIndividualInvestmentGoalsMutation({
              refetchQueries: [
                CurrentContextDocument,
                CurrentSuitabilityDocument,
              ],
            })}
            mutationNames={[`updateIndividualInvestmentGoals`]}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSuccess={() => onSuccess?.()}
            mapVariables={defaultAnswerMapper}
          >
            {(formikProps) => (
              <Box as={Form} display="contents">
                {children({ ...formikProps })}
              </Box>
            )}
          </FormikQL>
        ),
      )
      .with(
        {
          isInstitutionUser: true,
        },
        () => (
          <FormikQL
            mutation={useUpdateInstitutionInvestmentGoalsMutation({
              refetchQueries: [
                CurrentContextDocument,
                CurrentSuitabilityDocument,
              ],
            })}
            mutationNames={[`updateInstitutionInvestmentGoals`]}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSuccess={() => onSuccess?.()}
            mapVariables={defaultAnswerMapper}
          >
            {(formikProps) => (
              <Box as={Form} display="contents">
                {children({ ...formikProps })}
              </Box>
            )}
          </FormikQL>
        ),
      )
      .otherwise(() => null);
  },
);

export default SuitabilityCardForm;
