import { useRouter } from "next/router";

import { WithQuery } from "@/components/common";
import {
  useListingPageListingByIdQuery,
  ListingPageListingFragment,
} from "@/gql";

import { ListingPageContent } from "./ListingPageContent";
import { ListingPageSkeleton } from "./ListingPageSkeleton";

const ListingPage = ({ id }: { readonly id: string }) => {
  const router = useRouter();

  return (
    <WithQuery
      query={useListingPageListingByIdQuery({
        variables: {
          id: id as string,
        },
        fetchPolicy: `network-only`,
        skip: !router.isReady,
        notifyOnNetworkStatusChange: true,
      })}
      fallback={<ListingPageSkeleton />}
    >
      {({
        data: { listingById: listing },
      }: {
        readonly data: { readonly listingById: ListingPageListingFragment };
      }) => <ListingPageContent listing={listing} />}
    </WithQuery>
  );
};

export default ListingPage;
