import { constants } from "@/utils";

const metabaseHost = () =>
  process.env.NEXT_PUBLIC_INFRA_ENV === `production`
    ? constants.metabase_production_host
    : constants.metabase_development_host;

export const metabaseListingLink = (listingShortId: string) =>
  `https://${metabaseHost()}/dashboard/4-listing-information?listing=${listingShortId}`;

export const metabaseStandingBidLink = (standingBidShortId: string) =>
  `https://${metabaseHost()}/dashboard/6-standing-bid-information?standing_bid=${standingBidShortId}`;
