import isNil from "lodash/isNil";

import {
  BidFeeDiscountBreakdownFeeDiscountApplicationFragment,
  FeeDiscountApplicationState,
} from "@/gql";

type FeeDiscountApplicationType = Pick<
  BidFeeDiscountBreakdownFeeDiscountApplicationFragment,
  "state" | "feeDiscount" | "feeDiscountApplicationCommission" | "initial"
>;

type VirtualFeeDiscountApplicationType = {
  amount?: number;
  type: string;
  name: string;
};

export type FeeDiscountApplicationItems =
  | readonly (FeeDiscountApplicationType | VirtualFeeDiscountApplicationType)[]
  | null;

export default function mapFeeDiscountApplications(
  feeDiscountApplications?: FeeDiscountApplicationItems,
  initial?: boolean,
  feeDiscountApplicationState: FeeDiscountApplicationState | null = FeeDiscountApplicationState.Active,
): readonly VirtualFeeDiscountApplicationType[] {
  if (!feeDiscountApplications?.length) {
    return [];
  }
  const isVirtual = !feeDiscountApplications?.find(
    ({ feeDiscount }: FeeDiscountApplicationType) => feeDiscount,
  );
  return isVirtual
    ? (feeDiscountApplications as readonly VirtualFeeDiscountApplicationType[])
    : (feeDiscountApplications as readonly FeeDiscountApplicationType[])
        ?.filter(
          (item) =>
            (isNil(initial) || initial === !!item.initial) &&
            (!feeDiscountApplicationState ||
              feeDiscountApplicationState === item.state),
        )
        .map(({ feeDiscount, feeDiscountApplicationCommission }) => ({
          amount: feeDiscountApplicationCommission?.amount || 0,
          name: feeDiscount.name,
          type: feeDiscount.type,
        }));
}
