import { useField } from "formik";
import { ReactNode } from "react";

import { Flex, FormControl, FormErrorMessage, Text } from "@chakra-ui/react";

import { Card } from "@/components/common";

const QuestionFormCard = ({
  text,
  dataTestId,
  children,
}: {
  readonly text?: string | null;
  readonly dataTestId?: string;
  readonly children: ReactNode;
}) => {
  const [_field, { touched, error }, _] = useField(`answers`);

  return (
    <Card w={{ base: `full`, md: `xl` }} data-testid={dataTestId}>
      <FormControl id="answers" isInvalid={(error && touched) || false}>
        <Flex direction="column" mb={4}>
          <Text textStyle="deprecated-heading-lg">{text}</Text>
          <FormErrorMessage>{error}</FormErrorMessage>
        </Flex>
        {children}
      </FormControl>
    </Card>
  );
};

export default QuestionFormCard;
