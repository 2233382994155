import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { HeroBannerWrapper } from "@/components/common";

const PostListingHeroBanner = ({
  companyName = ``,
}: {
  readonly companyName?: string;
}) => {
  const { t } = useTranslation();

  return (
    <HeroBannerWrapper bg={{ base: `transparent`, lg: `white` }}>
      <Text textStyle="heading-4xl" mb={7}>
        {t(`your_company_dashboard`, { companyName })}
      </Text>
      <Text textStyle="heading-2xl" mb={2}>
        {t(`monitor_your_listing`)}
      </Text>
      <Text textStyle="text-md">{t(`monitor_your_listing_description`)}</Text>
    </HeroBannerWrapper>
  );
};

export default PostListingHeroBanner;
