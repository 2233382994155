import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { ModifyListingMutation } from "@/gql";

import { StepKeys } from "./steps";

export const ModifyListingModalStepFormContext = createStepFormContext<
  StepKeys,
  ModifyListingMutation
>();

export const useModifyListingModalStepFormContext = () =>
  useContext(ModifyListingModalStepFormContext);
