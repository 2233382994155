import { AnalyticsBrowser } from "@segment/analytics-next";
import { createContext, ReactNode, useMemo } from "react";

export type AnalyticsContext = AnalyticsBrowser;

export const AnalyticsContext = createContext<AnalyticsContext | undefined>(
  undefined,
);

const analytics = process.env.NEXT_PUBLIC_SEGMENT_TRACKING_API_KEY
  ? AnalyticsBrowser.load({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_TRACKING_API_KEY,
    })
  : new AnalyticsBrowser();

const AnalyticsProvider = ({ children }: { readonly children: ReactNode }) => {
  const contextValue = useMemo(() => analytics, [analytics]);

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
