import { useContext } from "react";

import { CurrentActorContext } from "@/components/providers";

const useCurrentActor = () => {
  const context = useContext(CurrentActorContext);

  if (context === undefined) {
    throw new Error(
      `useCurrentActor must be used within a CurrentActorProvider`,
    );
  }

  return context;
};

export default useCurrentActor;
