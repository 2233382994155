import { t } from "i18next";

import {
  Card,
  CardBody,
  CardHeader,
  Fade,
  Text,
  VStack,
} from "@chakra-ui/react";

import { HiiveButton, InternalLink } from "@/components/common";

const SellNowCardContent = ({ id }: { id: string }) => (
  <Card w="full">
    <CardHeader>
      <Text textStyle="heading-sm">{t(`want_to_sell_now`)}</Text>
    </CardHeader>
    <CardBody>
      <VStack spacing={7}>
        <Text>{t(`sell_now_description`)}</Text>
        <InternalLink href={`/dashboard/${id}/standing-bids`}>
          <HiiveButton
            boxShadow="card"
            px={16}
            size="xl"
            variant="rounded-solid-salmon"
            sentryLabel="[SellNowCardContent] See Buyers"
          >
            {t(`see_other_buyers`)}
          </HiiveButton>
        </InternalLink>
      </VStack>
    </CardBody>
  </Card>
);

export const SellNowCardV2 = ({
  hasListing,
  id,
}: {
  hasListing: boolean;
  id: string;
}) => {
  if (!hasListing) return null;

  return (
    <Fade in>
      <SellNowCardContent id={id} />
    </Fade>
  );
};
