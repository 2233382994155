import { Box, Text } from "@chakra-ui/react";

interface ActivityCardPropertyPillProps {
  readonly title: string;
  readonly value: string;
}

const ActivityCardPropertyPill = ({
  title,
  value,
}: ActivityCardPropertyPillProps) => (
  <Box
    borderWidth=".5px"
    borderColor="grey.100"
    borderRadius="md"
    px="2"
    py="1"
    width={{ base: `22`, sm: `32` }}
  >
    <Text
      textStyle="text-xs"
      color="grey.700"
      textAlign="left"
      verticalAlign="center"
      lineHeight="1rem"
    >
      {title}
    </Text>
    <Text textStyle="heading-4xs" color="grey.700" textAlign="left">
      {value}
    </Text>
  </Box>
);

export default ActivityCardPropertyPill;
