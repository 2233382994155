import { TFunction } from "i18next";
import { isNil } from "lodash";
import { useFieldArray } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import { Box, Card, CardBody, Flex, Text } from "@chakra-ui/react";

import { HiiveButton, InternalLink } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import { FormCheckboxInput } from "@/components/react-hook-form";
import {
  HoldingDetailsPageCompanyFragment,
  HoldingDetailsPageHoldingFragment,
  HoldingDetailsPageUnlistedHoldingFragment,
  HoldingType,
  useHoldingDetailsPageMyHoldingsQuery,
  useHoldingDetailsPageSetHoldingsMutation,
  useTransitionCurrentStepMutation,
} from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

import { AddHoldingCombobox } from "./AddHoldingCombobox";
import { SelectedHoldingsList } from "./SelectedHoldingsList";
import { HoldingDetailsFormFieldValues, HoldingField } from "./types";

const holdingSchema = (t: TFunction) =>
  Yup.object().shape({
    companyId: Yup.string(),
    numShares: Yup.number()
      .positive(
        t(`validation_min_items`, {
          min: 1,
          field: t(`share`),
        }),
      )
      .transform((value) => (Number.isNaN(value) ? null : value))
      .test(
        `valid-number-when-defined`,
        t(`validation_valid_number`, { field: t(`shares`) }),
        (value) => isNil(value) || Number.isInteger(value),
      )
      .nullable(),
    type: Yup.string().oneOf(Object.values(HoldingType)).required(),
    name: Yup.string().required(t(`validation_required`, { field: t(`name`) })),
    logoUrl: Yup.string(),
  });

const createValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    holdings: Yup.array()
      .of(holdingSchema(t))
      .min(1, t(`seller_lot_details_min_items`, {}))
      .max(4, t(`seller_lot_details_max_items`, {})),
    sellerLotDisclaimer: Yup.boolean()
      .oneOf([true], t`required`)
      .required(t`required`),
  });

const initialValues: HoldingDetailsFormFieldValues = {
  holdings: [],
  sellerLotDisclaimer: false,
};

const mapVariables = ({
  sellerLotDisclaimer: _sellerLotDisclaimer,
  holdings,
  ...values
}: HoldingDetailsFormFieldValues) => ({
  addHoldingsInput: {
    holdings: holdings.map(({ companyId, numShares, type, name }) => ({
      value: type === HoldingType.Listed ? companyId : name,
      numShares,
      type,
    })),
    ...values,
  },
});

const convertExistingHoldingToField = (
  holding:
    | HoldingDetailsPageHoldingFragment
    | HoldingDetailsPageUnlistedHoldingFragment,
) =>
  match(holding)
    .with(
      { __typename: `Holding` },
      ({ company, numSharesV2 }): HoldingField => ({
        name: company.name,
        logoUrl: company.logoUrl,
        companyId: company.id,
        numShares: numSharesV2,
        type: HoldingType.Listed,
      }),
    )
    .with(
      { __typename: `UnlistedHolding` },
      ({ companyName, numSharesV2 }): HoldingField => ({
        name: companyName,
        numShares: numSharesV2,
        type: HoldingType.Unlisted,
        logoUrl: undefined,
        companyId: undefined,
      }),
    )
    .run();

const useExistingHoldingFields = (
  callback: (holdingFields: HoldingField[]) => void,
) => {
  useHoldingDetailsPageMyHoldingsQuery({
    fetchPolicy: `no-cache`,
    onCompleted: ({ myHoldings }) => {
      const holdings = myHoldings.holdingsV2 || [];
      const holdingFields = holdings.map(convertExistingHoldingToField);
      callback(holdingFields);
    },
  });
};

export const HoldingDetailsForm = () => {
  const { t } = useTranslation();
  const actor = useCurrentActor();

  const [
    transitionCurrentStepMutation,
    isTransitioningCurrentStep,
  ] = useMutationWithError(
    useTransitionCurrentStepMutation(),
    `transitionCurrentStep`,
  );

  const mutation = useHoldingDetailsPageSetHoldingsMutation();
  const validationSchema = createValidationSchema(t);

  const onNext = () => {
    transitionCurrentStepMutation({
      // TODO: remove on SUP completion (user migration)
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { onboardingId: actor.onboardingV2?.id! },
    });
  };

  const { handleSubmit, control, watch, reset: resetForm } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess: onNext,
  });

  const { fields, append, replace } = useFieldArray({
    control,
    name: `holdings`,
  });

  useExistingHoldingFields((holdings) => {
    resetForm(
      {
        ...initialValues,
        holdings,
      },
      {
        keepDefaultValues: true,
      },
    );
  });

  const holdingFields = watch(`holdings`);

  const populatedHoldings = fields.map((field, index) => ({
    ...field,
    ...holdingFields[index],
  }));

  const onSelectCompany = (company: HoldingDetailsPageCompanyFragment) => {
    append({
      companyId: company.id,
      name: company.name,
      logoUrl: company.logoUrl,
      numShares: undefined,
      type: HoldingType.Listed,
    });
  };

  const onRemoveCompany = (indexToRemove: number) => {
    const holdingsWithoutCompany = [...populatedHoldings].filter(
      (_, index) => indexToRemove !== index,
    );
    replace(holdingsWithoutCompany);
  };

  const onAddCompanyManually = (companyName: string) => {
    append({
      name: companyName,
      companyId: undefined,
      logoUrl: undefined,
      numShares: undefined,
      type: HoldingType.Unlisted,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap={8}>
        <SlideAnimation>
          <Card>
            <CardBody>
              <AddHoldingCombobox
                onSelectCompany={onSelectCompany}
                onAddCompanyManually={onAddCompanyManually}
                holdings={populatedHoldings || []}
              />
              <SelectedHoldingsList
                holdings={populatedHoldings}
                onRemoveCompany={onRemoveCompany}
                control={control}
              />
              {populatedHoldings.length > 0 && (
                <Box mt={6}>
                  <FormCheckboxInput
                    name="sellerLotDisclaimer"
                    label={
                      <Text textStyle="text-md" fontWeight={400}>
                        <Trans
                          i18nKey="seller_lot_details_terms_and_conditions"
                          components={{
                            italic: (
                              <InternalLink
                                target="_blank"
                                textDecorationLine="underline"
                                href="/terms-and-conditions"
                              />
                            ),
                          }}
                        />
                      </Text>
                    }
                    control={control}
                    align="flex-start"
                  />
                </Box>
              )}
            </CardBody>
          </Card>
        </SlideAnimation>
        <Flex justifyContent="flex-end" w="full">
          <HiiveButton
            type="submit"
            isLoading={isTransitioningCurrentStep}
            size="xl"
            w={{ base: `full`, sm: `unset` }}
            maxW="unset"
            variant="rounded-solid-salmon"
            sentryLabel="[HoldingDetailsPage] Next"
            isDisabled={populatedHoldings.length === 0}
          >
            {t(`next`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </form>
  );
};
