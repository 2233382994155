import {
  CompanyPermission,
  GetAvailableCompanyActionsCompanyFragment,
} from "@/gql";
import { iHaveEntityPermission } from "@/hooks";

const getCanPlaceBid = (company: GetAvailableCompanyActionsCompanyFragment) =>
  iHaveEntityPermission(company, CompanyPermission.PlaceBid);

const getCanAddToWatchlist = (
  company: GetAvailableCompanyActionsCompanyFragment,
) => iHaveEntityPermission(company, CompanyPermission.AddToWatchlist);

const getCanCreateListing = (
  company: GetAvailableCompanyActionsCompanyFragment,
) => iHaveEntityPermission(company, CompanyPermission.CreateListing);

const getCanRemoveFromWatchlist = (
  company: GetAvailableCompanyActionsCompanyFragment,
) => iHaveEntityPermission(company, CompanyPermission.RemoveFromWatchlist);

const getCanPlaceStandingBid = (
  company: GetAvailableCompanyActionsCompanyFragment,
) => iHaveEntityPermission(company, CompanyPermission.PlaceStandingBid);

export const getAvailableCompanyActions = (
  company: GetAvailableCompanyActionsCompanyFragment,
) => {
  const canPlaceBid = getCanPlaceBid(company);
  const canAddToWatchlist = getCanAddToWatchlist(company);
  const canRemoveFromWatchlist = getCanRemoveFromWatchlist(company);
  const canCreateListing = getCanCreateListing(company);
  const canPlaceStandingBid = getCanPlaceStandingBid(company);

  return {
    canPlaceBid,
    canAddToWatchlist,
    canCreateListing,
    canRemoveFromWatchlist,
    canPlaceStandingBid,
  };
};
