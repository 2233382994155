import { AnimatePresence } from "framer-motion";

import { FadeInOut, TotalCount, WithQuery } from "@/components/common";
import { useUnaccreditedSellerPastBidNotificationsCountQuery } from "@/gql";

const UnaccreditedSellerPastBidNotificationsCount = () => {
  const query = useUnaccreditedSellerPastBidNotificationsCountQuery();

  return (
    <WithQuery query={query} hideLoadingSpinner>
      {({
        data: {
          unaccreditedSellerMyListingActivity: { pastBids },
        },
      }) => {
        const totalPastBids = pastBids.length;
        const hasPastBids = totalPastBids > 0;

        return (
          <AnimatePresence>
            {hasPastBids && (
              <FadeInOut>
                <TotalCount totalCount={totalPastBids} />
              </FadeInOut>
            )}
          </AnimatePresence>
        );
      }}
    </WithQuery>
  );
};

export default UnaccreditedSellerPastBidNotificationsCount;
