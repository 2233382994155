import { Flex } from "@chakra-ui/react";

import { MessageCounterpartyCardSkeleton } from "@/components/common";

import { PlaceBidCardSkeleton } from "./PlaceBidCard";

export const BuyerActivitySkeleton = () => (
  <Flex direction="column" gap={4}>
    <PlaceBidCardSkeleton />
    <MessageCounterpartyCardSkeleton />
  </Flex>
);
