import { useTranslation } from "react-i18next";

import {
  ActionsTile,
  ActionsTileButtonWrapper,
  HiiveButton,
  TileHeader,
} from "@/components/common";
import { BidInfoCardBidFragment } from "@/gql";
import { useModal } from "@/hooks";

const SellerCounterBidActionsTile = ({
  bid,
}: {
  readonly bid: BidInfoCardBidFragment;
}) => {
  const { modals, onOpenModal } = useModal();
  const { t } = useTranslation();

  return (
    <ActionsTile>
      <TileHeader>{t`bid_info_seller_counter_bid_actions_title`}</TileHeader>

      <ActionsTileButtonWrapper>
        <HiiveButton
          size="md"
          variant="rounded-outline-grey"
          onClick={onOpenModal(modals.modifyCounterBid(bid))}
          sentryLabel="[BidActions] Modify Counter Bid"
        >
          {t`bid_info_seller_counter_bid_actions_modify`}
        </HiiveButton>
        <HiiveButton
          size="md"
          variant="rounded-outline-grey"
          onClick={onOpenModal(modals.withdrawCounterBid(bid))}
          sentryLabel="[BidActions] Withdraw Counter Bid"
        >
          {t`bid_info_seller_counter_bid_actions_withdraw`}
        </HiiveButton>
      </ActionsTileButtonWrapper>
    </ActionsTile>
  );
};

export default SellerCounterBidActionsTile;
