import {
  MutationTuple,
  MutationFunctionOptions,
  ApolloError,
} from "@apollo/client";
import { useEffect } from "react";

import { useCustomToast } from "@/hooks";

function useMutationWithError<TMutation, TMutationVariables>(
  mutation: MutationTuple<TMutation, TMutationVariables>,
  mutationName: string,
): readonly [
  (
    options?: MutationFunctionOptions<TMutation, TMutationVariables>,
  ) => Promise<TMutation | null | undefined>,
  boolean,
] {
  const { errorToast } = useCustomToast();
  const [runMutation, { error: mutationError, loading }] = mutation;

  useEffect(() => {
    if (!mutationError) return;
    errorToast(mutationError.toString());
  }, [mutationError]);

  const onApolloError = (err: ApolloError) => {
    errorToast(`${err.name}: ${err.message}`);
  };

  const _runMutation = async (
    options?: MutationFunctionOptions<TMutation, TMutationVariables>,
  ) => {
    const { data } = await runMutation({
      ...options,
      onError: onApolloError,
    });

    if (
      data &&
      Object.keys(data as Record<string, unknown>).some(
        (key) => key !== mutationName,
      )
    ) {
      errorToast(`Mutation name "${mutationName}" is invalid`);
    }

    return data;
  };

  return [_runMutation, loading];
}

export default useMutationWithError;
