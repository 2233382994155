import { LongHyphen, SensitiveText } from "@/components/common";
import { formatPricePerShare } from "@/utils";

const PricingBlockValue = ({
  children,
}: {
  readonly children?: number | null;
}) =>
  children ? (
    <SensitiveText textStyle={{ base: `heading-md`, md: `heading-lg` }}>
      {formatPricePerShare(children)}
    </SensitiveText>
  ) : (
    <LongHyphen />
  );

export default PricingBlockValue;
