export const somethingWentWrongIcon = `
<svg width="181" height="180" viewBox="0 0 181 180" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M126.142 47.4987C126.142 47.4987 106.841 62.1455 77.4985 34.7585C51.4396 10.4368 30.1619 48.811 30.013 67.1878C29.8201 90.9928 56.0721 110.04 43.3321 125.676C30.592 141.311 68.5973 167.131 89.0802 144.786C114.56 116.99 121.463 139.574 135.987 139.574C146.41 139.574 167.811 113.674 153.359 94.405C141.198 78.1904 147.831 72.8092 151.043 67.1878C155.676 59.0805 144.673 37.075 126.142 47.4987Z" fill="#C0C0C0" fill-opacity="0.4"/>
  <path d="M48.6552 79.8657C47.6179 80.2512 46.484 80.2769 45.4053 80.0293C40.886 78.9919 25.3463 76.2453 28.0467 88.2871C28.6346 90.6763 37.7359 104.056 24.7747 108.94C23.825 109.303 22.8205 109.501 21.8043 109.525C17.8137 109.607 9.90565 108.665 6.59599 105.296C2.59792 101.226 6.96579 103.156 6.96579 103.156C6.96579 103.156 22.1656 109.789 27.5346 102.752C28.1321 101.969 28.5124 100.832 28.6214 99.8529C28.8104 98.1555 28.0567 95.9125 27.0666 93.9598C25.7266 91.3171 19.2931 79.4289 33.261 75.9657C36.4787 75.1994 41.1521 74.5111 47.1781 77.0482C50.6931 78.528 49.9145 79.3978 48.6552 79.8657Z" fill="#F15F61"/>
  <path d="M45.5448 75.4262L49.5904 76.9403C50.987 77.463 51.6954 79.0189 51.1727 80.4154L50.9722 80.9512C50.4495 82.3478 48.8937 83.0562 47.4971 82.5335L43.4514 81.0194C42.0549 80.4967 41.3465 78.9409 41.8691 77.5443L42.0697 77.0085C42.5924 75.6119 44.1482 74.9035 45.5448 75.4262Z" fill="#E61013"/>
  <path d="M70.3776 79.8086L83.72 84.8022C84.4183 85.0635 84.7725 85.8415 84.5112 86.5397L84.4586 86.6802C84.1973 87.3784 83.4194 87.7327 82.7211 87.4713L69.3787 82.4778C68.6804 82.2164 68.3262 81.4385 68.5875 80.7402L68.6401 80.5998C68.9014 79.9015 69.6793 79.5473 70.3776 79.8086Z" fill="#818285"/>
  <path d="M66.6539 89.7578L79.9964 94.7514C80.6947 95.0128 81.0489 95.7907 80.7875 96.489L80.735 96.6294C80.4736 97.3277 79.6957 97.6819 78.9974 97.4205L65.655 92.427C64.9567 92.1656 64.6025 91.3877 64.8638 90.6894L64.9164 90.549C65.1777 89.8507 65.9556 89.4965 66.6539 89.7578Z" fill="#818285"/>
  <path d="M54.3353 73.0181L65.149 77.0653C66.5456 77.588 67.254 79.1439 66.7313 80.5404L63.0393 90.4051C62.5166 91.8017 60.9608 92.5101 59.5642 91.9874L48.7505 87.9403C47.3539 87.4176 46.6455 85.8617 47.1681 84.4652L50.8602 74.6004C51.3828 73.2039 52.9387 72.4955 54.3353 73.0181Z" fill="#F33E40"/>
  <path d="M64.0326 75.606L74.8463 79.6532C76.2429 80.1759 76.9513 81.7317 76.4286 83.1283L72.0239 94.8973C71.5012 96.2939 69.9454 97.0023 68.5488 96.4796L57.7351 92.4324C56.3385 91.9098 55.6301 90.3539 56.1528 88.9573L60.5575 77.1883C61.0801 75.7918 62.636 75.0833 64.0326 75.606Z" fill="#F15F61"/>
  <path d="M62.3398 73.2182L62.4246 73.2499C63.3556 73.5984 63.8279 74.6356 63.4794 75.5667L57.3089 92.0538C56.9605 92.9848 55.9232 93.4571 54.9922 93.1086L54.9074 93.0769C53.9763 92.7284 53.5041 91.6912 53.8525 90.7602L60.023 74.2731C60.3715 73.342 61.4087 72.8697 62.3398 73.2182Z" fill="#E71013"/>
  <path d="M172.604 83.7592C172.604 83.7592 150.887 77.6559 154.04 91.7173C154.587 93.9422 162.469 105.398 153.081 110.899C151.065 112.08 148.738 112.603 146.402 112.591C142.213 112.569 135.572 111.763 132.589 108.726C128.591 104.656 132.959 106.586 132.959 106.586C132.959 106.586 145.432 111.717 151.848 107.627C154.04 106.23 155.033 103.527 154.438 100.997C154.134 99.7411 153.67 98.5287 153.06 97.3895C151.664 94.7757 145.286 82.8586 159.254 79.3954C162.472 78.6291 167.145 77.9408 173.171 80.4779C179.197 83.015 172.604 83.7592 172.604 83.7592Z" fill="#F15F61"/>
  <path d="M129.669 103.44L133.714 104.954C135.111 105.477 135.819 107.033 135.297 108.429L135.096 108.965C134.574 110.362 133.018 111.07 131.621 110.547L127.575 109.033C126.179 108.51 125.47 106.955 125.993 105.558L126.194 105.022C126.716 103.626 128.272 102.917 129.669 103.44Z" fill="#E61013"/>
  <path d="M122.834 96.7822L128.83 99.0263C130.226 99.5489 130.935 101.105 130.412 102.501L127.521 110.226C126.998 111.623 125.442 112.331 124.046 111.809L118.05 109.565C116.653 109.042 115.945 107.486 116.468 106.09L119.359 98.3645C119.881 96.968 121.437 96.2595 122.834 96.7822Z" fill="#F33E40"/>
  <path d="M112.852 90.836L121.794 94.1825C123.19 94.7052 123.899 96.261 123.376 97.6576L118.971 109.427C118.448 110.823 116.893 111.532 115.496 111.009L106.555 107.662C105.158 107.14 104.45 105.584 104.972 104.187L109.377 92.4183C109.9 91.0217 111.455 90.3133 112.852 90.836Z" fill="#F15F61"/>
  <path d="M110.958 88.3979L111.043 88.4296C111.974 88.7781 112.446 89.8153 112.098 90.7463L105.927 107.233C105.579 108.164 104.541 108.637 103.61 108.288L103.526 108.257C102.594 107.908 102.122 106.871 102.471 105.94L108.641 89.4527C108.99 88.5217 110.027 88.0494 110.958 88.3979Z" fill="#E71013"/>
  <path d="M88.1253 77.8946C88.4473 77.8589 88.7429 77.6996 88.9498 77.4503C89.1568 77.201 89.2589 76.8812 89.2348 76.5581L88.5676 64.3721C88.5279 63.6442 87.9132 63.0911 87.2156 63.1555L86.2436 63.2455C85.546 63.3098 85.0466 63.9655 85.1441 64.6883L86.7836 76.7843C86.8213 77.1065 86.9818 77.4017 87.2317 77.6086C87.4816 77.8154 87.8017 77.9178 88.1253 77.8946Z" fill="#404041"/>
  <path d="M93.8857 78.9453C93.9859 78.9747 94.0909 78.9841 94.1946 78.973C94.2984 78.9619 94.399 78.9304 94.4906 78.8804C94.5823 78.8303 94.6631 78.7628 94.7286 78.6815C94.7941 78.6002 94.843 78.5068 94.8724 78.4067L97.2549 70.8263C97.3242 70.6107 97.3051 70.3765 97.2019 70.1749C97.0987 69.9734 96.9197 69.821 96.7043 69.7513L96.0874 69.5652C95.9816 69.5343 95.8705 69.5256 95.7611 69.5399C95.6518 69.5541 95.5466 69.5908 95.4522 69.6477C95.3578 69.7046 95.2762 69.7805 95.2125 69.8705C95.1489 69.9606 95.1047 70.0628 95.0826 70.1709L93.3168 77.9374C93.2675 78.1462 93.2988 78.3658 93.4042 78.5526C93.5096 78.7394 93.6816 78.8796 93.8857 78.9453Z" fill="#404041"/>
  <path d="M97.1009 83.3159C97.5278 83.8448 98.3863 83.8385 99.0472 83.3016L110.78 73.7687C111.481 73.1992 111.688 72.2567 111.235 71.696L110.604 70.914C110.151 70.3531 109.221 70.3991 108.558 71.0152L97.4557 81.3302C96.8305 81.911 96.6741 82.787 97.1009 83.3159Z" fill="#404041"/>
  <path d="M91.6768 107.939C91.3746 108.056 91.1295 108.286 90.9931 108.579C90.8566 108.873 90.8396 109.209 90.9454 109.515L94.7026 121.126C94.9271 121.82 95.6625 122.197 96.3206 121.957L97.2378 121.622C97.8958 121.382 98.2116 120.62 97.9323 119.946L93.258 108.67C93.1394 108.368 92.9087 108.123 92.6141 107.987C92.3195 107.851 91.9838 107.834 91.6768 107.939Z" fill="#404041"/>
  <path d="M85.8388 108.395C85.7344 108.392 85.6305 108.41 85.533 108.447C85.4355 108.484 85.3463 108.54 85.2705 108.612C85.1946 108.684 85.1337 108.77 85.0911 108.865C85.0485 108.96 85.0251 109.063 85.0223 109.168L84.6548 117.105C84.6428 117.331 84.7211 117.553 84.8723 117.721C85.0236 117.89 85.2356 117.991 85.4617 118.004L86.1057 118.026C86.2159 118.029 86.3255 118.009 86.4276 117.967C86.5297 117.926 86.622 117.863 86.6988 117.784C86.7755 117.705 86.8351 117.611 86.8736 117.508C86.9121 117.404 86.9288 117.294 86.9226 117.184L86.646 109.224C86.6403 109.01 86.554 108.805 86.4044 108.652C86.2549 108.498 86.0529 108.406 85.8388 108.395Z" fill="#404041"/>
  <path d="M81.6143 104.99C81.0663 104.588 80.238 104.813 79.7361 105.501L70.8266 117.714C70.2944 118.444 70.3351 119.408 70.9166 119.834L71.7266 120.429C72.3076 120.856 73.1952 120.574 73.679 119.809L81.7791 107C82.2345 106.279 82.1621 105.393 81.6143 104.99Z" fill="#404041"/>
</svg>
`;
