import { match } from "ts-pattern";

import { AcceptedBidState } from "@/components/postings";
import {
  BidAcceptedStatusItemDocumentSignerFragment,
  BidAcceptedStatusItemTransactionFragment,
  TransactionState,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCurrentActor, useDocumentSigners } from "@/hooks";
import {
  getLongDocumentTitleByDocumentType,
  getTransactionCounterpartyName,
  getIsBroker,
} from "@/utils";
import * as datetime from "@/utils/datetime";

import { TransferStatusItem } from "./TransferStatusItem";

const getAmIBrokerParty = (
  actor: UserWithInstitutionFragment,
  { bid: { brokerId, buyerId } }: BidAcceptedStatusItemTransactionFragment,
) =>
  !!brokerId &&
  ((getIsBroker(actor) && actor.id === brokerId) ||
    (actor.isHiiveUser && buyerId === actor.id));

const getUserIdForCounterparty = (
  actor: UserWithInstitutionFragment,
  transaction: BidAcceptedStatusItemTransactionFragment,
) => {
  const {
    bid: { brokerId },
    counterparty,
  } = transaction;
  const amIBrokerParty = getAmIBrokerParty(actor, transaction);
  return brokerId && !amIBrokerParty ? brokerId : counterparty?.id;
};

const getUserIdForMyParty = (
  actor: UserWithInstitutionFragment,
  transaction: BidAcceptedStatusItemTransactionFragment,
) => {
  const {
    bid: { brokerId },
  } = transaction;
  const amIBrokerParty = getAmIBrokerParty(actor, transaction);
  return brokerId && amIBrokerParty ? brokerId : actor.id;
};

const getDocumentSignedAtTime = (
  userId: string,
  signers?: readonly BidAcceptedStatusItemDocumentSignerFragment[] | null,
  institutionId?: string | null,
) => {
  const signer = signers?.find(
    (signer) =>
      signer.userId === userId ||
      (!!signer.institutionId && signer.institutionId === institutionId),
  );

  return signer ? datetime.toTimestamp(signer.updatedAt) : ``;
};

const useBidAcceptedState = (
  transaction: BidAcceptedStatusItemTransactionFragment,
) => {
  const actor = useCurrentActor();

  const {
    hasNoSigner,
    haveISigned,
    hasCounterSigned,
    haveBothSigned,
  } = useDocumentSigners(transaction);

  const amIBrokerParty = getAmIBrokerParty(actor, transaction);
  const hasBrokerPartySigned =
    !!transaction.bid.brokerId &&
    transaction.document?.signers.some(
      (signer) => signer.userId === transaction.bid.brokerId,
    );

  return match({
    state: transaction.state,
    hasNoSigner,
    haveBothSigned,
    hasMyPartySigned: haveISigned || (amIBrokerParty && hasBrokerPartySigned),
    hasCounterSigned,
  })
    .with(
      { state: TransactionState.BidAccepted, hasNoSigner: true },
      () => AcceptedBidState.BidAcceptedNoSigners,
    )
    .with(
      { state: TransactionState.Expired, hasNoSigner: true },
      () => AcceptedBidState.BidExpiredNoSigners,
    )
    .with(
      { state: TransactionState.BidAccepted, haveBothSigned: true },
      () => AcceptedBidState.BidAcceptedBothSigned,
    )
    .with(
      { state: TransactionState.IssuerApprovalDeclined, haveBothSigned: true },
      () => AcceptedBidState.BidCancelledBothSigned,
    )
    .with(
      { state: TransactionState.BidAccepted, hasMyPartySigned: true },
      () => AcceptedBidState.BidAcceptedMyPartySigned,
    )
    .with(
      {
        state: TransactionState.BidAccepted,
        hasCounterSigned: true,
      },
      () => AcceptedBidState.BidAcceptedCounterSigned,
    )
    .with(
      { state: TransactionState.Expired, hasMyPartySigned: true },
      () => AcceptedBidState.BidExpiredMyPartySigned,
    )
    .with(
      { state: TransactionState.Expired, hasCounterSigned: true },
      () => AcceptedBidState.BidExpiredCounterSigned,
    )
    .with(
      { state: TransactionState.IssuerPendingApproval },
      () => AcceptedBidState.BidAcceptedBothSigned,
    )
    .with(
      { state: TransactionState.IssuerApproved },
      () => AcceptedBidState.BidAcceptedBothSigned,
    )
    .with(
      { state: TransactionState.ClosedFeePending },
      () => AcceptedBidState.BidAcceptedBothSigned,
    )
    .with(
      { state: TransactionState.ClosedFeePaid },
      () => AcceptedBidState.BidAcceptedBothSigned,
    )
    .with(
      { state: TransactionState.AwaitingClosing },
      () => AcceptedBidState.BidAcceptedBothSigned,
    )
    .otherwise(({ state }) => {
      throw new Error(
        `Unhandled transaction state: ${state}, document: ${JSON.stringify(
          transaction.document,
        )}`,
      );
    });
};

const TransferStatusItemGroup = ({
  children,
  transaction,
}: {
  readonly children: JSX.Element | readonly JSX.Element[];
  readonly transaction: BidAcceptedStatusItemTransactionFragment;
}) => {
  const bidState = useDocumentSigners(transaction);

  return transaction.document ? (
    <>
      <TransferStatusItem
        isCompleted
        title={`${
          transaction.bid.standingBidId ? `Standing bid` : `Bid`
        } accepted`}
        subtitle={
          !bidState.haveBothSigned
            ? `Awaiting ${getLongDocumentTitleByDocumentType(
                transaction.document.type,
              )} signatures`
            : undefined
        }
      />
      {children}
    </>
  ) : null;
};

const MyPartySignatureStatusItem = ({
  state,
  transaction,
  actor,
}: {
  readonly state: "SIGNED" | "EXPIRED" | "PENDING";
  readonly transaction: BidAcceptedStatusItemTransactionFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { institutionId: meInstitutionId } = actor;
  const isInstitutionUser = !!meInstitutionId;
  const institutionName = actor.institution?.legalName;
  const { isHiiveUser } = actor;
  const isBroker = getIsBroker(actor);
  const isBrokerBid =
    !!transaction.bid.brokerId || !!transaction.bid.brokerCounterpartyId;

  const alternateSigner = transaction.document?.invitedAlternateSigners?.[0];
  const hasAlternateSigner = !!alternateSigner;

  const userIdForSignature = getUserIdForMyParty(actor, transaction);

  const title = match({
    isHiiveUser,
    isBroker,
    isBrokerBid,
    hasAlternateSigner,
    isInstitutionUser,
    state,
  })
    .with(
      { isHiiveUser: true, isBrokerBid: true },
      () => `Broker's customer's signature`,
    )
    .with({ isHiiveUser: true }, () => `Alternate signer's signature`)
    .with({ isBroker: true }, () => `Your customer's signature`)
    .with({ isInstitutionUser: true }, () => `${institutionName}'s signature`)
    .with(
      { state: `SIGNED`, hasAlternateSigner: true },
      () => `${alternateSigner?.name}'s signature`,
    )
    .otherwise(() => `Your signature`);

  return match(state)
    .with(`EXPIRED`, () => (
      <TransferStatusItem
        title={title}
        description="No signature"
        isCompleted={false}
        isSubitem
      />
    ))
    .with(`SIGNED`, () => (
      <TransferStatusItem
        title={title}
        description={getDocumentSignedAtTime(
          userIdForSignature,
          transaction.document?.signers,
          meInstitutionId,
        )}
        isCompleted
        isSubitem
      />
    ))
    .with(`PENDING`, () => (
      <TransferStatusItem isCompleted={false} title={title} isSubitem />
    ))
    .otherwise(() => {
      throw new Error(`Unhandled state in <MySignatureStatusItem />`);
    });
};

const CountersignatureStatusItem = ({
  state,
  transaction,
}: {
  readonly state: "SIGNED" | "EXPIRED" | "PENDING";
  readonly transaction: BidAcceptedStatusItemTransactionFragment;
}) => {
  const actor = useCurrentActor();
  const title = `${getTransactionCounterpartyName(transaction)}'s signature`;

  const userIdForSignature = getUserIdForCounterparty(actor, transaction);

  return match(state)
    .with(`EXPIRED`, () => (
      <TransferStatusItem
        isCompleted={false}
        title={title}
        description="No signature"
        isSubitem
      />
    ))
    .with(`SIGNED`, () => (
      <TransferStatusItem
        isCompleted
        title={title}
        description={
          userIdForSignature
            ? getDocumentSignedAtTime(
                userIdForSignature,
                transaction.document?.signers,
                transaction.counterparty?.institutionId,
              )
            : ``
        }
        isSubitem
      />
    ))
    .with(`PENDING`, () => (
      <TransferStatusItem isCompleted={false} title={title} isSubitem />
    ))
    .otherwise(() => {
      throw new Error(`Unhandled state in <CountersignatureStatusItem />`);
    });
};

const BidAcceptedStatusItem = ({
  transaction,
}: {
  readonly transaction: BidAcceptedStatusItemTransactionFragment;
}) => {
  const actor = useCurrentActor();
  const state = useBidAcceptedState(transaction);

  return match(state)
    .with(AcceptedBidState.BidExpiredNoSigners, () => (
      <TransferStatusItemGroup transaction={transaction}>
        <MyPartySignatureStatusItem
          state="EXPIRED"
          transaction={transaction}
          actor={actor}
        />
        <CountersignatureStatusItem state="EXPIRED" transaction={transaction} />
      </TransferStatusItemGroup>
    ))
    .with(AcceptedBidState.BidExpiredMyPartySigned, () => (
      <TransferStatusItemGroup transaction={transaction}>
        <MyPartySignatureStatusItem
          state="SIGNED"
          transaction={transaction}
          actor={actor}
        />
        <CountersignatureStatusItem state="EXPIRED" transaction={transaction} />
      </TransferStatusItemGroup>
    ))
    .with(AcceptedBidState.BidExpiredCounterSigned, () => (
      <TransferStatusItemGroup transaction={transaction}>
        <MyPartySignatureStatusItem
          state="EXPIRED"
          transaction={transaction}
          actor={actor}
        />
        <CountersignatureStatusItem state="SIGNED" transaction={transaction} />
      </TransferStatusItemGroup>
    ))
    .with(AcceptedBidState.BidCancelledBothSigned, () => (
      <TransferStatusItemGroup transaction={transaction}>
        <MyPartySignatureStatusItem
          state="SIGNED"
          transaction={transaction}
          actor={actor}
        />
        <CountersignatureStatusItem state="SIGNED" transaction={transaction} />
      </TransferStatusItemGroup>
    ))
    .with(AcceptedBidState.BidAcceptedNoSigners, () => (
      <TransferStatusItemGroup transaction={transaction}>
        <MyPartySignatureStatusItem
          state="PENDING"
          transaction={transaction}
          actor={actor}
        />
        <CountersignatureStatusItem state="PENDING" transaction={transaction} />
      </TransferStatusItemGroup>
    ))
    .with(AcceptedBidState.BidAcceptedMyPartySigned, () => (
      <TransferStatusItemGroup transaction={transaction}>
        <MyPartySignatureStatusItem
          state="SIGNED"
          transaction={transaction}
          actor={actor}
        />
        <CountersignatureStatusItem state="PENDING" transaction={transaction} />
      </TransferStatusItemGroup>
    ))
    .with(AcceptedBidState.BidAcceptedCounterSigned, () => (
      <TransferStatusItemGroup transaction={transaction}>
        <MyPartySignatureStatusItem
          state="PENDING"
          transaction={transaction}
          actor={actor}
        />
        <CountersignatureStatusItem state="SIGNED" transaction={transaction} />
      </TransferStatusItemGroup>
    ))
    .with(AcceptedBidState.BidAcceptedBothSigned, () => (
      <TransferStatusItemGroup transaction={transaction}>
        <MyPartySignatureStatusItem
          state="SIGNED"
          transaction={transaction}
          actor={actor}
        />
        <CountersignatureStatusItem state="SIGNED" transaction={transaction} />
      </TransferStatusItemGroup>
    ))
    .otherwise(() => {
      throw new Error(`Unhandled state in <BidAcceptedStatusItem />`);
    });
};

export default BidAcceptedStatusItem;
