import { useEffect } from "react";

import { Box, GridItem, SimpleGrid } from "@chakra-ui/react";

import {
  FullContentWrapper,
  MobileTab,
  MobileTabList,
  MobileTabPanel,
  MobileTabPanels,
  MobileTabs,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { BidInfo } from "@/components/postings";
import {
  BidPageContentBidFragment,
  BidState,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  useIsDesktop,
  useKnockClient,
  useModal,
  useNotificationFeed,
  useRouteHashChange,
} from "@/hooks";
import {
  maybeViewNewAcceptedBidNotification,
  maybeViewNewBidNotification,
} from "@/services/knock";

import { BidActivity } from "./activity/BidActivity";

const BidPageContent = withCurrentActor(
  ({
    actor,
    userNotificationToken,
    bid,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly userNotificationToken: string;
    readonly bid: BidPageContentBidFragment;
  }) => {
    const isDesktopView = useIsDesktop();
    const { modals, onOpenModal } = useModal();

    useRouteHashChange((hash) => {
      if (hash === `#place-standing-bid`) {
        onOpenModal(
          modals.placeStandingBid(bid.company, {
            numShares: bid.numShares,
            pricePerShare: bid.pricePerShare / 100,
          }),
        )();
      }
    });

    const knockClient = useKnockClient({ actor, userNotificationToken });
    const notificationFeed = useNotificationFeed({
      knockClient,
    });

    useEffect(() => {
      if (bid.state === BidState.Accepted) {
        maybeViewNewAcceptedBidNotification({
          notificationFeed,
          bidId: bid.id,
        });
      }

      maybeViewNewBidNotification({ notificationFeed, bidId: bid.id });
    }, []);

    return (
      <FullContentWrapper px={{ base: 4, lg: 8 }}>
        <Box w="full" maxW="max-width-md">
          {isDesktopView ? (
            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
              <GridItem>
                <BidInfo bid={bid} />
              </GridItem>
              <GridItem>
                <BidActivity bid={bid} />
              </GridItem>
            </SimpleGrid>
          ) : (
            <MobileTabs>
              <MobileTabList>
                <MobileTab>Overview</MobileTab>
                <MobileTab>Activity</MobileTab>
              </MobileTabList>

              <MobileTabPanels>
                <MobileTabPanel>
                  <BidInfo bid={bid} />
                </MobileTabPanel>
                <MobileTabPanel>
                  <BidActivity bid={bid} />
                </MobileTabPanel>
              </MobileTabPanels>
            </MobileTabs>
          )}
        </Box>
      </FullContentWrapper>
    );
  },
);

export default BidPageContent;
