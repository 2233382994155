/**
 * @deprecated - use shared, named wrappers that accept children
 */
export const layerStyles = {
  sensitive: {
    color: `transparent`,
    textShadow: `0 0 8px #000`,
    filter: `blur(4px)`,
    webkitFilter: `blur(4px)`,
    opacity: `.8`,
  },
  "sensitive-lg": {
    color: `transparent`,
    textShadow: `0 0 8px #000`,
    filter: `blur(12px)`,
    webkitFilter: `blur(12px)`,
    opacity: `.8`,
  },
  activity: {
    w: { base: `full`, md: `54%` },
    minH: `full`,
    justifyContent: `flex-start`,
    bg: `grey.50`,
    pt: { base: 5, md: 20 },
    pb: 5,
  },
  "activity-content": {
    w: `full`,
    px: { base: 3, lg: 9 },
    maxW: {
      base: `full`,
      "5xl": `calc(.54 * var(--chakra-sizes-max-width-xl))`,
    },
  },
  "discussion-activity": {
    w: { base: `full`, md: `54%` },
    bg: `grey.50`,
    h: {
      base: `full`,
      md: `desktop-full-height-minus-header`,
    },
    position: { base: `relative`, md: `fixed` },
    overflow: `hidden`,
    maxW: {
      base: `full`,
      "5xl": `calc(.54 * var(--chakra-sizes-max-width-xl))`,
    },
    left: { base: `unset`, md: `46%` },
  },
  "discussion-activity-content": {
    w: `full`,
    px: 0,
    flexDirection: `column`,
    alignItems: `flex-start`,
    maxW: {
      base: `full`,
      "5xl": `calc(.54 * var(--chakra-sizes-max-width-xl))`,
    },
  },
  overview: {
    overflowX: `auto`,
    w: { base: `full`, md: `46%` },
    zIndex: { base: `none`, md: `docked` },
    justifyContent: `flex-end`,
    bg: `h-white`,
    boxShadow: {
      base: `none`,
      md: `0px 0px 4px 0px #0000000F, 0px 2px 6px 0px #00000040;`,
    },
  },
  "overview-content": {
    w: `full`,
    h: `full`,
    maxW: {
      base: `full`,
      "5xl": `calc(.46 * var(--chakra-sizes-max-width-xl))`,
    },
    mx: { base: 0, md: 5, xl: 10 },
    pb: { base: 0, md: 12 },
  },
  "overview-top": {
    pt: { base: 5, md: 10 },
    px: { base: 2.5, md: 0 },
    pb: { base: 6, md: 7 },
  },
  "overview-summary": {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`,
    borderTop: `light-grey-border`,
    pt: 7,
    px: { base: 2.5, md: 0 },
    pb: 8,
  },
  "card-highlight": {
    borderRadius: `md`,
    boxShadow: `0px 3px 6px 0px #00000033`,
    w: `full`,
  },
  info: {
    w: `full`,
    bg: `white`,
    mb: 1,
  },
  "full-content": {
    boxSize: `full`,
    justify: `center`,
    bg: `grey.50`,
    flex: `1 1 auto`,
    margin: 0,
  },
};
