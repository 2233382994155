import { match } from "ts-pattern";

import {
  GetIsBrokerForBidBidFragment,
  GetIsBrokerForBidUserFragment,
  GetIsBrokerForStandingBidStandingBidFragment,
  GetIsBrokerForStandingBidUserFragment,
  GetIsBuyerForDiscussionDiscussionFragment,
  GetIsBuyerForDiscussionUserFragment,
  GetIsCounterpartyForBidBidFragment,
  GetIsCounterpartyForBidUserFragment,
} from "@/gql";

import { getIsSellerForListing } from "./listing";
import { getIsBuyerForStandingBid } from "./standing-bid";

export const getIsBrokerForBid = ({
  user,
  bid,
}: {
  readonly user: GetIsBrokerForBidUserFragment;
  readonly bid: GetIsBrokerForBidBidFragment;
}) => bid.brokerId === user.id;

export const getIsBrokerForStandingBid = ({
  user,
  standingBid,
}: {
  readonly user: GetIsBrokerForStandingBidUserFragment;
  readonly standingBid: GetIsBrokerForStandingBidStandingBidFragment;
}) => standingBid.brokerId === user.id;

export const getIsCounterpartyForBid = ({
  user,
  bid,
}: {
  readonly user: GetIsCounterpartyForBidUserFragment;
  readonly bid: GetIsCounterpartyForBidBidFragment;
}) =>
  bid.brokerCounterpartyId === user.id ||
  bid.counterpartyId === user.id ||
  (!!bid.counterpartyInstitutionId &&
    bid.counterpartyInstitutionId === user.institutionId);

export const getIsBuyerForDiscussion = ({
  user,
  discussion,
}: {
  readonly user: GetIsBuyerForDiscussionUserFragment;
  readonly discussion: GetIsBuyerForDiscussionDiscussionFragment;
}) =>
  match(discussion.topic)
    .with(
      { __typename: `Listing` },
      (listing) => !getIsSellerForListing(user, listing),
    )
    .with({ __typename: `StandingBid` }, (standingBid) =>
      getIsBuyerForStandingBid(user, standingBid),
    )
    .otherwise((topic) => {
      throw new Error(
        `Unhandled discussion topic in isBuyerForDiscussion: ${topic}`,
      );
    });
