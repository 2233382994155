import { useTranslation } from "react-i18next";

import { ShareDetailsDisclaimer } from "@/components/common";
import { ListingViewerRoundedSharesTooltip } from "@/components/tooltip";

const ListingViewerRoundingDisclaimer = () => {
  const { t } = useTranslation();
  return (
    <ShareDetailsDisclaimer tooltip={<ListingViewerRoundedSharesTooltip />}>
      {t(`listing_viewer_rounding_disclaimer`)}
    </ShareDetailsDisclaimer>
  );
};

export default ListingViewerRoundingDisclaimer;
