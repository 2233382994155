import { useField } from "formik";
import { useState } from "react";

import { VStack } from "@chakra-ui/react";

import { CustomRadio } from "@/components/common";
import {
  AccreditationRadioInput,
  AccreditationTextAreaInput,
} from "@/components/form";
import { QuestionFormCard } from "@/components/onboarding";

import { ContinueAsSellerCTA } from "./ContinueAsSellerCTA";

export const INIndividualAccreditationFields = () => {
  const [_field, _, { setValue: setAnswers }] = useField(`answers`);
  const [radioValue, setRadioValue] = useState<string | undefined>();
  const [textAreaValue, setTextAreaValue] = useState<string>(``);

  const onChangeRadioValue = (value?: string) => {
    setRadioValue(value);
    if (!value) {
      setAnswers([]);
      return;
    }
    if (value === `in-individual-other`) {
      setAnswers([{ key: value, text: textAreaValue }]);
      return;
    }
    setAnswers([{ key: value }]);
  };

  const onChangeTextAreaValue = (value: string) => {
    setTextAreaValue(value);
    if (radioValue !== `in-individual-other`) return;
    setAnswers([{ key: `in-individual-other`, text: value }]);
  };

  return (
    <VStack gap={5}>
      <QuestionFormCard text="Securities laws require you to qualify in one of the following ways in order to access and use Hiive. Please let us know how you qualify.">
        <AccreditationRadioInput
          value={radioValue}
          onChange={onChangeRadioValue}
        >
          <VStack alignItems="flex-start">
            <CustomRadio value="in-individual-income">
              I have an annual income greater or equal to INR 2 Crore.
            </CustomRadio>
            <CustomRadio value="in-individual-net-worth">
              I have a net worth of greater or equal to INR 7.5 Crore, of which
              at least INR 3.75 Crore is in the form of financial assets.
            </CustomRadio>
            <CustomRadio value="in-individual-income-and-net-worth">
              I have an annual income of greater than or equal to INR 1 Crore
              and a net worth greater than or equal to 5 Crore, of which at
              least INR 2.5 Crore is in the form of financial assets.
            </CustomRadio>
            <CustomRadio value="in-individual-partnership">
              I have a partnership firm set up under the Indian Partnership Act,
              1932 in which each partner independently meets the accredited
              investor criteria for individuals.
            </CustomRadio>
            <CustomRadio value="in-individual-trust">
              I have a trust with assets under management greater than or equal
              to INR 50 Crore.
            </CustomRadio>
            <CustomRadio value="in-individual-corporate">
              I have a body corporate with a net worth greater than or equal to
              INR 50 Crore.
            </CustomRadio>
            <CustomRadio value="in-individual-other">
              Other (explain below).
            </CustomRadio>
            <CustomRadio value="in-individual-not-accredited">
              I am not an accredited investor.
            </CustomRadio>
          </VStack>
        </AccreditationRadioInput>
      </QuestionFormCard>
      {radioValue === `in-individual-other` && (
        <AccreditationTextAreaInput
          placeholder="Description"
          label="How are you accredited?"
          value={textAreaValue}
          onChange={onChangeTextAreaValue}
        />
      )}
      {radioValue === `in-individual-not-accredited` && <ContinueAsSellerCTA />}
    </VStack>
  );
};
