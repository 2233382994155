import { Form } from "formik";
import * as Yup from "yup";

import { Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FormCountriesCombobox, FormikQL } from "@/components/form";
import {
  CurrentContextDocument,
  useUpdateUserMutation,
  useCountriesQuery,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

const initialValues = {
  countryId: ``,
};

const validationSchema = Yup.object().shape({
  countryId: Yup.string().required(`Required`),
});

const mapVariables = ({ countryId }: { readonly countryId: string }) => ({
  input: {
    countryId,
  },
});

const SellerResidencePage = () => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  const mutation = useUpdateUserMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => {
    routeToNextStep();
  };

  return (
    <>
      <Text align="center" color="grey.900" my={7}>
        What is your legal place of residence?
      </Text>
      <FormikQL
        initialValues={initialValues}
        mapVariables={mapVariables}
        mutation={mutation}
        mutationNames={[`updateUser`]}
        onSuccess={onSuccess}
        query={useCountriesQuery()}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <VStack spacing={9}>
              <FormCountriesCombobox
                name="countryId"
                label="Select Country"
                labelSrOnly
              />
              <HiiveButton
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                sentryLabel="[SellerResidencePage] Next"
                size="md"
                type="submit"
                variant="rounded-solid-salmon"
              >
                Next
              </HiiveButton>
            </VStack>
          </Form>
        )}
      </FormikQL>
    </>
  );
};

export default SellerResidencePage;
