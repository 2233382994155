import { useTranslation } from "react-i18next";

import { Card, CardBody, Text, VStack } from "@chakra-ui/react";

import { Logo } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import { TranslationKey } from "@/i18n";
import { getIsBroker, getIsInstitutionUser } from "@/utils";

const emptyStateMessageTranslationKey = (
  actor: UserWithInstitutionFragment,
  sellSideBrokerPortalFeatureEnabled: boolean,
): TranslationKey => {
  const isInstitutionUser = getIsInstitutionUser(actor);
  const isBroker = getIsBroker(actor);

  if (isBroker && sellSideBrokerPortalFeatureEnabled) {
    return `empty_dashboard_broker_bid_listing`;
  }

  if (isBroker) {
    return `empty_dashboard_broker`;
  }

  if (isInstitutionUser) {
    return `empty_dashboard_institution`;
  }

  return `empty_dashboard`;
};

export const DashboardYourActivityEmptyState = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const sellSideBrokerPortalFeatureEnabled = useSellSideBrokerPortalEnabled();
    const { t } = useTranslation();

    return (
      <Card w="full">
        <CardBody>
          <VStack py={6}>
            <Logo.GreyIcon mb={5} width="32px" height="auto" />
            <Text textAlign="center" maxW="sm">
              {t(
                emptyStateMessageTranslationKey(
                  actor,
                  Boolean(sellSideBrokerPortalFeatureEnabled),
                ),
                { institution_name: actor.institution?.legalName },
              )}
            </Text>
          </VStack>
        </CardBody>
      </Card>
    );
  },
);
