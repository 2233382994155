import currency from "currency.js";
import { useField } from "formik";
import isNaN from "lodash/isNaN";
import isNil from "lodash/isNil";

import {
  Box,
  Flex,
  FormLabel,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";

import { toFixedDecimal } from "@/utils";

import { FormNumberInputProps } from "./FormNumberInput";

export type FormNumberInputFieldProps = FormNumberInputProps;

const FormNumberInputField = ({
  readOnly = false,
  label,
  subLabel,
  labelSrOnly,
  info,
  name,
  fromValue, // gets called before passing the value to the input
  toValue, // gets galled on change, before passing the value back to Formik
  onChange,
  controlledValue,
  rightElement,
  big = false,
  placeholder,
  ...props
}: FormNumberInputProps) => {
  const maxInputValue = big ? 1e15 : 2000000000;

  const [field, _fieldMeta, { setValue }] = useField(name);

  const currencyOptions = { precision: 0, symbol: `` };

  const _fromValue = (value: string) => {
    // NumberInput crashes on null
    if (value === null) return ``;

    return fromValue?.(value) || currency(value, currencyOptions).format();
  };

  const _toValue = (value: string) => {
    if (value === ``) return null;

    // sets value to fixed decimal places of 2 without rounding
    const fixedValue = toFixedDecimal(2, value);
    return (
      toValue?.(fixedValue) || currency(fixedValue, currencyOptions).intValue
    );
  };

  const getIsValidInput = (value: string) => {
    if (isNaN(+value) || +value < 0) return false;
    return true;
  };

  const onInputChanged = (value: string) => {
    if (Number(value) > maxInputValue) {
      const clampedValue = _toValue(`${maxInputValue}`);
      setValue(clampedValue);
      onChange?.(clampedValue);
      return;
    }
    if (getIsValidInput(value)) {
      const updatedValue = _toValue(value);
      setValue(updatedValue);
      onChange?.(value);
      return;
    }
    if (value === `.`) setValue(`0.`);
    onChange?.(value);
  };

  const value = !!controlledValue
    ? _fromValue(controlledValue.toString())
    : _fromValue(field.value);

  return (
    <Flex direction="column-reverse">
      <Flex>
        <NumberInput
          flex="1"
          name={name}
          isDisabled={readOnly}
          min={0}
          pattern="[0-9,\.]*(.[0-9,\.]+)?"
          onChange={onInputChanged}
          value={value}
          precision={2}
          max={maxInputValue}
          {...props}
        >
          <NumberInputField placeholder={placeholder} />
        </NumberInput>
        {!!rightElement && rightElement}
      </Flex>
      <Box>
        <Flex justify="space-between" mb={!isNil(subLabel) ? 0 : 1.5}>
          {label && (
            <FormLabel m={0} srOnly={labelSrOnly} htmlFor={name}>
              {label}
            </FormLabel>
          )}
          {info}
        </Flex>
        {!isNil(subLabel) && (
          <Text textStyle="text-sm" mb={1.5}>
            {subLabel}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default FormNumberInputField;
