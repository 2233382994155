import { ChartAnnouncement, H50Announcement } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { useIsDesktop } from "@/hooks";

import { DashboardWelcomeGuides } from "./DashboardWelcomeGuides";
import { DesktopAccreditedDashboardPage } from "./DesktopAccreditedDashboardPage";
import { MobileAccreditedDashboardPage } from "./MobileAccreditedDashboardPage";

const AccreditedDashboardPage = () => {
  const isDesktopView = useIsDesktop();

  return (
    <>
      <DashboardWelcomeGuides />
      <ChartAnnouncement />
      <H50Announcement />
      {isDesktopView ? (
        <DesktopAccreditedDashboardPage />
      ) : (
        <MobileAccreditedDashboardPage />
      )}
    </>
  );
};

export default withCurrentActor(AccreditedDashboardPage);
