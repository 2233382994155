import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

import { VStack, Flex, HStack, Text } from "@chakra-ui/react";

import { PricingChartContext } from "./PricingChartProvider";
import { chartSeriesSetting } from "./constants";
import { ChartSeriesKey } from "./types";

export const Legend = () => {
  const { visibleSeries } = useContext(PricingChartContext);
  const { t } = useTranslation();

  return (
    <VStack alignItems="start">
      <Text textStyle="heading-2xs">
        <Trans i18nKey="legend" />
      </Text>
      <Flex wrap="wrap">
        {Object.keys(chartSeriesSetting).map((key) => {
          const seriesKey = key as ChartSeriesKey;
          return (
            visibleSeries.includes(seriesKey) && (
              <HStack key={seriesKey} spacing={2} mr={6}>
                {chartSeriesSetting[seriesKey].indicator}
                <Text textStyle="text-sm" color="grey.900">
                  {/* count and value fallbacks for handling pluralization */}
                  {t(chartSeriesSetting[seriesKey].translation, {
                    count: 1,
                    defaultValue: ``,
                  })}
                </Text>
              </HStack>
            )
          );
        })}
      </Flex>
    </VStack>
  );
};
