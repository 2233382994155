import { ReactElement, useState } from "react";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  HStack,
  List,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCardBadge,
  ActivityCardListingNumShareAndPriceDetails,
  ActivityCardTimestamp,
  HiiveButton,
  ActivityCardListingSolicitedCardBody,
  WithQuery,
} from "@/components/common";
import {
  LatestActivityListListingFragment,
  LatestActivityListStandingBidFragment,
  useLatestMarketActivityQuery,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import { constants, getIsBroker, getShowListingSolicitedState } from "@/utils";

import { DashboardLatestMarketActivitySkeleton } from "./DashboardLatestMarketActivitySkeleton";
import { LatestStandingBidSubcard } from "./LatestStandingBidSubcard";

type TabKind = `LISTINGS` | `STANDING_BIDS`;

const Tab = ({
  isActive,
  onClick,
  children,
}: {
  readonly isActive: boolean;
  readonly onClick: () => void;
  readonly children: ReactElement | readonly ReactElement[] | string;
}) => (
  <HiiveButton
    _hover={{ color: `grey.900` }}
    _active={{ color: `grey.900` }}
    _focus={{ outline: `none` }}
    color={isActive ? `grey.900` : `grey.500`}
    backgroundColor="transparent"
    rounded="none"
    size="sm"
    fontWeight="normal"
    onClick={onClick}
    paddingLeft={0}
    sentryLabel="[DashboardLatestMarketActivity] Tab"
  >
    <Box
      borderBottomColor={isActive ? `salmon.900` : `transparent`}
      borderBottomWidth="3px"
      paddingX="3px"
      paddingBottom="8px"
    >
      <Text textStyle="heading-md">{children}</Text>
    </Box>
  </HiiveButton>
);

const MarketActivityListingCard = ({
  listing,
}: {
  readonly listing: LatestActivityListListingFragment;
}) => {
  const actor = useCurrentActor();
  const showListingSolicitedState = getShowListingSolicitedState(
    listing,
    actor,
  );

  if (showListingSolicitedState) {
    return (
      <CardBody w="full">
        <ActivityCardListingSolicitedCardBody
          spacing={0}
          alignItems="flex-start"
          textAlign="left"
          company={listing.company}
        />
      </CardBody>
    );
  }

  return (
    <>
      <CardBody w="full">
        <ActivityCardListingNumShareAndPriceDetails
          listing={listing}
          textStyle="heading-md"
        />
      </CardBody>
      <CardFooter px={4} py={3}>
        <ActivityCardTimestamp title="Placed" date={listing.placedAt} />
      </CardFooter>
    </>
  );
};

const LatestListingSubcard = ({
  listing,
}: {
  readonly listing: LatestActivityListListingFragment;
}) => {
  const router = useRouter();

  return (
    <Card
      variant="activity-subcard"
      as="button"
      w="full"
      onClick={() => router.push(`/listings/${listing.id}`)}
    >
      <CardHeader w="full">
        <HStack>
          <ActivityCardBadge title="Listing" variant="listing" />
          <Text textStyle="text-lg" align="left">
            {listing.company.name}
          </Text>
        </HStack>
      </CardHeader>
      <MarketActivityListingCard listing={listing} />
    </Card>
  );
};

const LatestListings = ({
  latestListings,
}: {
  readonly latestListings: readonly LatestActivityListListingFragment[];
}) => (
  <List spacing={4}>
    {latestListings.map((listing: LatestActivityListListingFragment) => (
      <LatestListingSubcard key={listing.id} listing={listing} />
    ))}
  </List>
);

const LatestStandingBids = ({
  latestStandingBids,
}: {
  readonly latestStandingBids: readonly LatestActivityListStandingBidFragment[];
}) => (
  <List spacing={4}>
    {latestStandingBids.map(
      (standingBid: LatestActivityListStandingBidFragment) => (
        <LatestStandingBidSubcard
          key={standingBid.id}
          standingBid={standingBid}
        />
      ),
    )}
  </List>
);

const BrokerUserDashboardLatestMarketActivity = ({
  ...cardProps
}: CardProps) => {
  const query = useLatestMarketActivityQuery({
    variables: {
      numListings: constants.number_of_latest_listings,
      numStandingBids: 0,
    },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery
      query={query}
      fallback={<DashboardLatestMarketActivitySkeleton {...cardProps} />}
    >
      {({ data: { latestListings } }) => (
        <Card w="full" h="min-content" {...cardProps}>
          <CardHeader>
            <Text textStyle="heading-sm">Latest Listings for sale</Text>
          </CardHeader>
          <CardBody h="auto" overflowY="auto" maxH="1408px">
            <LatestListings latestListings={latestListings} />
          </CardBody>
        </Card>
      )}
    </WithQuery>
  );
};

const RegularUserDashboardLatestMarketActivity = ({
  ...cardProps
}: CardProps) => {
  const [currentTab, setCurrentTab] = useState<TabKind>(`LISTINGS`);

  const onClick = (tab: TabKind) => {
    setCurrentTab(tab);
  };

  const query = useLatestMarketActivityQuery({
    variables: {
      numListings: constants.number_of_latest_listings,
      numStandingBids: constants.number_of_latest_standing_bids,
    },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery
      query={query}
      fallback={<DashboardLatestMarketActivitySkeleton {...cardProps} />}
    >
      {({ data: { latestListings, latestStandingBids } }) => (
        <Card
          data-testid="latest-market-activity"
          w="full"
          h="min-content"
          {...cardProps}
        >
          <CardHeader>
            <Text textStyle="heading-sm">Latest Market Activity</Text>
          </CardHeader>
          <HStack px={{ base: 4, md: 6 }} pt={{ base: 4, md: 6 }}>
            <Tab
              isActive={currentTab === `LISTINGS`}
              onClick={() => onClick(`LISTINGS`)}
            >
              Listings
            </Tab>
            <Tab
              isActive={currentTab === `STANDING_BIDS`}
              onClick={() => onClick(`STANDING_BIDS`)}
            >
              Standing Bids
            </Tab>
          </HStack>
          <CardBody h="auto" overflowY="auto" maxH="1408px">
            {currentTab === `LISTINGS` && (
              <LatestListings latestListings={latestListings} />
            )}
            {currentTab === `STANDING_BIDS` && (
              <LatestStandingBids latestStandingBids={latestStandingBids} />
            )}
          </CardBody>
        </Card>
      )}
    </WithQuery>
  );
};

export const DashboardLatestMarketActivity = ({ ...cardProps }: CardProps) => {
  const actor = useCurrentActor();
  const sellSideBrokerPortalFeatureEnabled = useSellSideBrokerPortalEnabled();

  if (getIsBroker(actor) && !sellSideBrokerPortalFeatureEnabled) {
    return <BrokerUserDashboardLatestMarketActivity {...cardProps} />;
  }
  return <RegularUserDashboardLatestMarketActivity {...cardProps} />;
};
