import { useTranslation } from "react-i18next";

import { VStack } from "@chakra-ui/react";

import { CheckboxInput } from "@/components/form";
import { UnaccreditedSellerCreateListingPageByIdCompanyFragment } from "@/gql";

interface ConfirmCompanyInformationDisclosureInputProps {
  readonly company: UnaccreditedSellerCreateListingPageByIdCompanyFragment;
}

const ConfirmCompanyInformationDisclosureInput = ({
  company,
}: ConfirmCompanyInformationDisclosureInputProps) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="flex-start" spacing={4}>
      <CheckboxInput
        name="confirmedCompanyInformationDisclosure"
        label={t(`agreed_to_issuer_disclosure`, {
          companyName: company.name,
        })}
      />
    </VStack>
  );
};

export default ConfirmCompanyInformationDisclosureInput;
