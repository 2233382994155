import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Card, CardBody, Flex } from "@chakra-ui/react";

import { EMAIL_REGEX } from "@/components/auth";
import { SlideAnimation } from "@/components/auth-v2";
import { HiiveButton } from "@/components/common";
import { FormTextInput } from "@/components/react-hook-form";
import {
  RequestResetPasswordMutation,
  useRequestResetPasswordMutation,
} from "@/gql";
import { useFormQL } from "@/hooks/react-hook-form";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(`Email is required`)
    .matches(EMAIL_REGEX, `Invalid email address`),
});

interface ForgotPasswordFormValues {
  readonly email: string;
}

const initialValues = {
  email: ``,
};

const mapVariables = (values: ForgotPasswordFormValues) => ({
  email: values.email,
});

const ForgotPasswordForm = ({
  onSuccess,
}: {
  readonly onSuccess: (data: RequestResetPasswordMutation) => void;
}) => {
  const { t } = useTranslation();
  const mutation = useRequestResetPasswordMutation();

  const { handleSubmit, isLoading, control } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap={8}>
        <SlideAnimation>
          <Card>
            <CardBody p={{ base: 6, md: 7 }}>
              <FormTextInput
                type="email"
                name="email"
                label={t(`email`)}
                placeholder={t(`email_address`)}
                control={control}
                isRequired
                autoFocus
              />
            </CardBody>
          </Card>
        </SlideAnimation>
        <Flex direction="column" alignItems="flex-end" w="full">
          <HiiveButton
            sentryLabel="[ForgotPasswordPage/Submit]"
            variant="rounded-solid-salmon"
            type="submit"
            size="xl"
            w={{ base: `full`, sm: `unset` }}
            maxW="unset"
            isLoading={isLoading}
          >
            {t(`submit`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </form>
  );
};

export default ForgotPasswordForm;
