import { ShareDetails, ShareDetailsStats } from "@/components/common";
import { ListingSellerRoundingDisclaimer } from "@/components/postings";
import { CounterBidModalListingFragment } from "@/gql";
import { getNumOfShares, getPricePerShare } from "@/utils";

export const CounterBidModalListingShareDetails = ({
  listing,
}: {
  readonly listing: CounterBidModalListingFragment;
}) => {
  const numberOfShares = getNumOfShares(listing);
  const pricePerShare = getPricePerShare(listing);

  return (
    <ShareDetails title="Listing Details" variant="listing">
      <ShareDetailsStats
        numberOfShares={numberOfShares}
        pricePerShare={pricePerShare}
      />
      <ListingSellerRoundingDisclaimer />
    </ShareDetails>
  );
};
