import { Box, Text } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { constants } from "@/utils";

const DocumentNotReady = () => (
  <>
    <Text
      mb={4}
      align="center"
      maxW="lg"
      textStyle="deprecated-text-xl"
      color="h-dark-grey"
    >
      Your document is currently being processed.
      <br />
      Please wait 5-10 minutes and try again.
    </Text>
    <Text
      align="center"
      maxW="lg"
      textStyle="deprecated-text-xl"
      color="h-dark-grey"
    >
      If you&apos;ve already waited past that time please contact{` `}
      <Box as="span" fontWeight="medium">
        <MailtoLink email={constants.email_connect} subject="Contact Hiive">
          support.
        </MailtoLink>
      </Box>
    </Text>
  </>
);

export default DocumentNotReady;
