import { useContext } from "react";

import { createStepFormContext } from "@/components/form";
import { AcceptStandingBidMutation } from "@/gql";

import { StepKeys } from "./steps";

export const AcceptStandingBidSequenceModalContext = createStepFormContext<
  StepKeys,
  AcceptStandingBidMutation
>();

export const useAcceptStandingBidSequenceModalContext = () =>
  useContext(AcceptStandingBidSequenceModalContext);
