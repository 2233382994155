import { CaretDown, CaretRight } from "@phosphor-icons/react";
import { useFormikContext } from "formik";
import intersection from "lodash/intersection";
import { ReactNode, useEffect, useMemo, useRef } from "react";
import * as Yup from "yup";

import { Box, HStack, Text } from "@chakra-ui/react";

import { FullDivider } from "@/components/common";
import { DropdownControls, HeadlessDropdown } from "@/components/form";

const getRequiredFields = (
  validationSchema: Yup.AnyObjectSchema,
  fields: readonly string[],
) =>
  fields.filter(
    (field: string) =>
      validationSchema?.fields[field]?.exclusiveTests?.required === true,
  );

const HiiveAdvancedOptionsDropdown = ({
  children,
  fieldNames,
  validationSchema,
}: {
  readonly children: ReactNode;
  readonly fieldNames: readonly string[];
  readonly validationSchema: Yup.AnyObjectSchema;
}) => {
  const dropdownRef = useRef<DropdownControls>(null);

  const { errors: formErrors, isSubmitting } = useFormikContext();

  const requiredFields = getRequiredFields(validationSchema, fieldNames);

  const errors = useMemo(
    () => intersection(fieldNames, Object.keys(formErrors)),
    [formErrors, fieldNames],
  );

  useEffect(() => {
    if (errors.length) {
      dropdownRef.current?.setShowDropdown(true);
    }
  }, [isSubmitting, errors]);

  return (
    <HeadlessDropdown ref={dropdownRef} show={requiredFields.length > 0}>
      {({ showDropdown, onClickDropdown }) => (
        <>
          <FullDivider />
          <HStack
            as="button"
            justifyContent="space-between"
            w="full"
            type="button"
            onClick={onClickDropdown}
            cursor="pointer"
            py={7}
          >
            <Text color="salmon.900" fontWeight="medium" textStyle="">
              Hiive Advanced Options
            </Text>
            {showDropdown ? <CaretDown /> : <CaretRight />}
          </HStack>
          {showDropdown && <Box pb={7}>{children}</Box>}
          <FullDivider />
        </>
      )}
    </HeadlessDropdown>
  );
};

export default HiiveAdvancedOptionsDropdown;
