import { Card, CardBody, Flex, Stack, StackProps } from "@chakra-ui/react";

import { ButtonSkeleton, Skeleton } from "@/components/common";

const CTACardSkeleton = ({
  direction,
}: {
  readonly direction: StackProps[`direction`];
}) => {
  const isColumn = direction === `column`;

  return (
    <Card w="full">
      <CardBody h="full">
        <Stack
          alignItems={isColumn ? `flex-start` : `center`}
          justifyContent="space-between"
          h="full"
          direction={direction}
          spacing={5}
        >
          <Flex direction="column" flex="1" w="full">
            <Skeleton h="20px" maxW="50%" mb={3} />
            <Flex direction="column" gap={2} w="full">
              <Skeleton h="14px" />
              <Skeleton h="14px" />
              <Skeleton h="14px" />
              <Skeleton h="14px" maxW="35%" />
            </Flex>
          </Flex>
          <ButtonSkeleton w={isColumn ? `full` : 40} maxW="unset" />
        </Stack>
      </CardBody>
    </Card>
  );
};

export default CTACardSkeleton;
