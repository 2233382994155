import { ReactNode } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
} from "@chakra-ui/react";

import { TooltipFormLabel } from "@/components/form";

import styles from "./FormCheckboxInput.module.css";

const FormCheckboxInput = <TFieldValues extends FieldValues>({
  name,
  label,
  helperText,
  align = `center`,
  tooltipContent,
  dataTestId,
  isDisabled = false,
  control,
}: {
  readonly name: Path<TFieldValues>;
  readonly label: ReactNode;
  readonly helperText?: string;
  readonly align?: "flex-start" | "center";
  readonly tooltipContent?: ReactNode;
  readonly dataTestId?: string;
  readonly isDisabled?: boolean;
  readonly control: Control<TFieldValues>;
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });

  const { value } = field;

  return (
    <FormControl isInvalid={invalid} width="auto">
      <HStack spacing={0} alignItems={align}>
        <Checkbox
          size="lg"
          // Customizing chakra checkbox styling requires some css module overrides
          className={`${styles.checkbox} ${!invalid && styles.checkboxValid}`}
          isInvalid={invalid}
          alignItems="flex-start"
          colorScheme="white"
          iconColor="h-dark-grey"
          isChecked={value}
          _focus={{
            boxShadow: `focus`,
          }}
          id={name}
          data-testid={dataTestId}
          isDisabled={isDisabled}
          {...field}
        />
        <TooltipFormLabel
          pl={4}
          tooltipContent={tooltipContent}
          htmlFor={name}
          pb={0}
          cursor="pointer"
        >
          {label}
        </TooltipFormLabel>
      </HStack>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormCheckboxInput;
