import { useToken } from "@chakra-ui/react";

/**
 * Wrapper around Chakra UI useToken, specifically for fetching color tokens from the theme
 */
// eslint-disable-next-line functional/prefer-readonly-type
const useColors = (tokens: string[]) => {
  const colorTokens = useToken(`colors`, tokens);
  return colorTokens;
};

export default useColors;
