import { Form } from "formik";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CardBody,
  CardFooter,
  HStack,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { TransactingEntityItem } from "@/components/account";
import { HiiveButton } from "@/components/common";
import { FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  CreateTransactingEntityBuyerModal,
  CreateTransactingEntitySellerModal,
} from "@/components/modals";
import {
  useLinkEntityMutation,
  TransactionPageTransactionByIdDocument,
  LinkEntityInput,
  LinkEntityMutationVariables,
  UserWithInstitutionFragment,
  EntityType,
  BasicUserEntityFragment,
} from "@/gql";
import { useCustomToast } from "@/hooks";

import CreateSuggestedTransactingEntityConfirmationButton from "./CreateSuggestedTransactingEntityConfirmationButton";
import { TransactingEntitiesSelect } from "./TransactingEntitiesSelect";
import { TransactingEntityCardTypes } from "./TransactingEntityCard";

type TransactingEntityFormProps = {
  actor: UserWithInstitutionFragment;
  displayId: string;
  entities: UserWithInstitutionFragment["entities"];
  isInstitution: boolean;
  transactionId: string;
  type: TransactingEntityCardTypes;
};

const TransactingEntityForm = withCurrentActor(
  ({
    actor,
    displayId,
    entities,
    isInstitution,
    transactionId,
    type,
  }: TransactingEntityFormProps) => {
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
      entityId: Yup.string().required(t(`select_entity_required`)),
      transactionId: Yup.string().required(t(`transaction_id_required`)),
    });

    const { successToast } = useCustomToast();
    const onSuccess = () => {
      successToast(t(`entity_saved_successfully`));
    };
    const mutation = useLinkEntityMutation({
      refetchQueries: [TransactionPageTransactionByIdDocument],
    });
    const mapVariables = (
      input: LinkEntityInput,
    ): LinkEntityMutationVariables => ({
      input,
    });

    const hasMultipleEntities = entities?.length > 1;
    const hasSingleEntitiy = entities?.length === 1;
    const isBuyer = type === TransactingEntityCardTypes.Buyer;

    const { isOpen, onClose, onOpen } = useDisclosure();
    const showSuggestedEntity = entities.length === 0;
    const suggestedEntity = isInstitution
      ? ({
          jurisdictionOfFormation: actor.institution?.country,
          legalName: actor.institution?.legalName,
          type: actor?.institution?.entityType as string,
        } as BasicUserEntityFragment)
      : ({
          jurisdictionOfFormation: actor.country,
          legalName: actor.name,
          type: EntityType.Individual,
        } as BasicUserEntityFragment);

    const getIndividuali18Key = () => {
      if (hasMultipleEntities) {
        return isBuyer
          ? `individual_buyer_description_confirmation_multi_entity`
          : `individual_seller_description_confirmation_multi_entity`;
      }

      return isBuyer
        ? `individual_buyer_description_confirmation`
        : `individual_seller_description_confirmation`;
    };

    const getInstituionali18Key = () => {
      if (hasMultipleEntities) {
        return isBuyer
          ? `institution_buyer_description_confirmation_multi_entity`
          : `institution_seller_description_confirmation_multi_entity`;
      }

      return isBuyer
        ? `institution_buyer_description_confirmation`
        : `institution_seller_description_confirmation`;
    };

    return (
      <>
        <FormikQL
          initialValues={{
            entityId: hasSingleEntitiy ? entities[0].id : ``,
            transactionId,
          }}
          mapVariables={mapVariables}
          mutation={mutation}
          mutationNames={[]}
          onSuccess={onSuccess}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <CardBody>
                <Stack gap={4}>
                  {isInstitution && (
                    <Text>
                      <Trans
                        i18nKey={getInstituionali18Key()}
                        components={{
                          a: (
                            <Link
                              fontWeight={500}
                              href="mailto:execution@hiive.com"
                              rel="noreferrer"
                              target="_blank"
                            />
                          ),
                          bold: <strong />,
                        }}
                        values={{
                          email: `execution@hiive.com`,
                          displayId,
                        }}
                      />
                    </Text>
                  )}
                  {!isInstitution && (
                    <Text>
                      <Trans
                        i18nKey={getIndividuali18Key()}
                        components={{
                          a: (
                            <Link
                              fontWeight={500}
                              href="mailto:execution@hiive.com"
                              rel="noreferrer"
                              target="_blank"
                            />
                          ),
                          bold: <strong />,
                        }}
                        values={{ displayId }}
                      />
                    </Text>
                  )}
                  {hasMultipleEntities && (
                    <TransactingEntitiesSelect
                      entities={entities}
                      onChange={(item) => setFieldValue(`entityId`, item.id)}
                    />
                  )}
                  {hasSingleEntitiy && (
                    <TransactingEntityItem
                      entity={entities[0]}
                      showDate={false}
                      showStatus={false}
                      showRemoveButton={false}
                    />
                  )}
                  {showSuggestedEntity && (
                    <TransactingEntityItem
                      entity={suggestedEntity}
                      showDate={false}
                      showStatus={false}
                      showRemoveButton={false}
                    />
                  )}
                </Stack>
              </CardBody>
              <CardFooter py={6}>
                <HStack justifyContent="flex-end" w="full">
                  <HiiveButton
                    maxW="unset"
                    onClick={() => onOpen()}
                    sentryLabel="[TransactingEntityCard] Add Entity"
                    size="xl"
                    variant="rounded-outline-grey"
                    w={{ base: `full`, md: `170px` }}
                  >
                    {t(`add_entity`)}
                  </HiiveButton>
                  {!showSuggestedEntity && (
                    <HiiveButton
                      isLoading={isSubmitting}
                      maxW="unset"
                      sentryLabel="[TransactingEntityCard] Confirm"
                      size="xl"
                      type="submit"
                      variant="rounded-solid-salmon"
                      w={{ base: `full`, md: `170px` }}
                    >
                      {t(`confirm`)}
                    </HiiveButton>
                  )}
                  {showSuggestedEntity && (
                    <CreateSuggestedTransactingEntityConfirmationButton
                      entity={{
                        jurisdictionOfFormationId:
                          suggestedEntity.jurisdictionOfFormation?.id ?? ``,
                        legalName: suggestedEntity.legalName,
                        type: suggestedEntity.type,
                        transactionId,
                      }}
                    />
                  )}
                </HStack>
              </CardFooter>
            </Form>
          )}
        </FormikQL>
        {isBuyer && (
          <CreateTransactingEntityBuyerModal
            actor={actor}
            isOpen={isOpen}
            onClose={onClose}
            transactionId={transactionId}
          />
        )}
        {!isBuyer && (
          <CreateTransactingEntitySellerModal
            actor={actor}
            isOpen={isOpen}
            onClose={onClose}
            transactionId={transactionId}
          />
        )}
      </>
    );
  },
);

export default TransactingEntityForm;
