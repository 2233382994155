import { ReactNode } from "react";
import { match } from "ts-pattern";

import { useRouter } from "next/router";

import { GridItem, SimpleGrid, useMediaQuery } from "@chakra-ui/react";

import { ContentWrapper } from "@/components/dashboard";
import { UnaccreditedSellerMultipleHoldingsFlow } from "@/components/frigade";
import { FlowKind } from "@/components/providers";
import { UnaccreditedSellerInlineNotificationsProvider } from "@/components/unaccredited-seller";

import { ActiveBidsPageV2 } from "./ActiveBidsPage";
import { HeroBannerV2 } from "./HeroBannerV2";
import { InquiriesPageV2 } from "./InquiriesPage";
import { PastBidsPageV2 } from "./PastBidsPage";
import { StandingBidsPageV2 } from "./StandingBidsPage";
import { TransactionsPageV2 } from "./TransactionsPage";
import { SidebarV2, UnaccreditedDashboardRoutesV2 } from "./sidebar";

interface PostActivityUnaccreditedSellerDashboardPageContainerProps {
  readonly id: string;
  readonly children: ReactNode;
}

const PostActivityUnaccreditedSellerDashboardPageContainer = ({
  id,
  children,
}: PostActivityUnaccreditedSellerDashboardPageContainerProps) => {
  const { asPath } = useRouter();
  const [isDesktopView] = useMediaQuery(`(min-width: 992px)`, { ssr: false });
  const showHeroBanner =
    isDesktopView || asPath === UnaccreditedDashboardRoutesV2.ActiveBids;

  return (
    <>
      {showHeroBanner && <HeroBannerV2 id={id} />}

      <ContentWrapper transform={{ base: `none`, lg: `translateY(-3rem)` }}>
        <SimpleGrid columns={12} gap={4}>
          {isDesktopView && (
            <GridItem colSpan={4}>
              <SidebarV2 id={id} />
            </GridItem>
          )}

          <GridItem
            colSpan={{ base: 12, lg: 8 }}
            pb={14}
            transform={{ base: `none`, lg: `translateY(3rem)` }}
          >
            {children}
          </GridItem>
        </SimpleGrid>
      </ContentWrapper>
    </>
  );
};

interface PostActivityUnaccreditedSellerDashboardPageProps {
  readonly id: string;
}

const PostActivityUnaccreditedSellerDashboardPageV2 = ({
  id,
}: PostActivityUnaccreditedSellerDashboardPageProps) => {
  const router = useRouter();
  const { asPath } = router;

  const page = match({ asPath, id })
    .with(
      { asPath: `/dashboard/${id}${UnaccreditedDashboardRoutesV2.ActiveBids}` },
      () => <ActiveBidsPageV2 id={id} />,
    )
    .with(
      { asPath: `/dashboard/${id}${UnaccreditedDashboardRoutesV2.Inquiries}` },
      () => <InquiriesPageV2 id={id} />,
    )
    .with(
      {
        asPath: `/dashboard/${id}${UnaccreditedDashboardRoutesV2.StandingBids}`,
      },
      () => <StandingBidsPageV2 id={id} />,
    )
    .with(
      {
        asPath: `/dashboard/${id}${UnaccreditedDashboardRoutesV2.Transactions}`,
      },
      () => <TransactionsPageV2 id={id} />,
    )
    .with(
      { asPath: `/dashboard/${id}${UnaccreditedDashboardRoutesV2.PastBids}` },
      () => <PastBidsPageV2 id={id} />,
    )
    .otherwise(() => <ActiveBidsPageV2 id={id} />);

  return (
    <UnaccreditedSellerInlineNotificationsProvider>
      <UnaccreditedSellerMultipleHoldingsFlow
        flowKind={FlowKind.U16RMultipleHoldings}
      />
      <PostActivityUnaccreditedSellerDashboardPageContainer id={id}>
        {page}
      </PostActivityUnaccreditedSellerDashboardPageContainer>
    </UnaccreditedSellerInlineNotificationsProvider>
  );
};

export default PostActivityUnaccreditedSellerDashboardPageV2;
