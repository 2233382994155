import isEmpty from "lodash/isEmpty";
import xor from "lodash/xor";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { CardBody } from "@chakra-ui/react";

import { ActionNeeded } from "@/components/common";
import { SuitabilityModifyFields } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  InvestmentGoalQuestion,
  UserRole,
  UserWithInstitutionFragment,
} from "@/gql";
import { useSuitabilityPageStatus } from "@/hooks";
import { getIsInstitutionUser } from "@/utils";

import SuitabilityCardCreateContentFooter from "./SuitabilityCardCreateContentFooter";
import SuitabilityCardForm from "./SuitabilityCardForm";

const SuitabilityCardActionNeededText = ({
  isInstitutionUser,
  verified,
  companyName,
  isInstitutionAdmin,
}: {
  readonly isInstitutionUser: boolean;
  readonly isInstitutionAdmin: boolean;
  readonly verified: boolean;
  readonly companyName?: string;
}) => {
  const { t } = useTranslation();

  return match({
    isInstitutionUser,
    verified,
    isInstitutionAdmin,
  })
    .with(
      {
        isInstitutionUser: false,
        verified: true,
      },
      () => <>{t(`you_can_currently_sell`)}</>,
    )
    .with(
      {
        isInstitutionUser: true,
        isInstitutionAdmin: true,
        verified: true,
      },
      () => (
        <>
          {t(`company_can_currently_sell`, {
            companyName,
          })}
        </>
      ),
    )
    .with(
      {
        isInstitutionUser: true,
        isInstitutionAdmin: false,
        verified: true,
      },
      () => (
        <>
          {t(`company_admin_can_currently_sell`, {
            companyName,
          })}
        </>
      ),
    )
    .with(
      {
        isInstitutionUser: false,
        verified: false,
      },
      () => <>{t(`if_want_to_make_bids`)}</>,
    )
    .with(
      {
        isInstitutionUser: true,
        isInstitutionAdmin: true,
        verified: false,
      },
      () => (
        <>
          {t(`company_if_want_to_make_bids`, {
            companyName,
          })}
        </>
      ),
    )
    .with(
      {
        isInstitutionUser: true,
        isInstitutionAdmin: false,
        verified: false,
      },
      () => (
        <>
          {t(`company_admin_if_want_to_make_bids`, {
            companyName,
          })}
        </>
      ),
    )
    .otherwise(() => <>{t(`you_can_currently_sell`)}</>);
};

const SuitabilityCardCreateContent = withCurrentActor(
  ({
    actor,
    onClose,
  }: {
    readonly onClose: () => void;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation();

    const suitabilityPageStatus = useSuitabilityPageStatus(actor);

    const isInstitutionUser = getIsInstitutionUser(actor);

    const { institution, membershipAgreementSigned, identityVerified } = actor;

    const verified =
      ((membershipAgreementSigned || institution?.membershipAgreementSigned) &&
        identityVerified) ??
      false;

    const [questionIds, setQuestionIds] = useState<readonly string[]>();
    const [questions, setQuestions] = useState<
      readonly InvestmentGoalQuestion[]
    >();

    const canModifySuitability = suitabilityPageStatus === `modify`;

    const onQuestionsChange = useCallback(
      ({
        questions,
      }: {
        readonly questions: readonly InvestmentGoalQuestion[];
      }) => {
        setQuestions(questions);
        setQuestionIds(questions.map(({ id }) => id));
      },
      [setQuestions, setQuestionIds],
    );

    return (
      <SuitabilityCardForm
        initialValues={{
          answers: {},
        }}
        questionIds={questionIds}
        questions={questions}
        onSuccess={onClose}
      >
        {({ values, setValues }) => (
          <>
            <CardBody p={{ base: 4, lg: 10 }}>
              <ActionNeeded heading={t(`complete_suitability_to_buy`)}>
                <SuitabilityCardActionNeededText
                  companyName={actor.institution?.legalName}
                  isInstitutionAdmin={actor.roles.includes(UserRole.Admin)}
                  isInstitutionUser={isInstitutionUser}
                  verified={verified}
                />
              </ActionNeeded>
              {canModifySuitability && (
                <SuitabilityModifyFields
                  values={values}
                  setValues={setValues}
                  onQuestionsChange={onQuestionsChange}
                />
              )}
            </CardBody>
            {canModifySuitability && (
              <SuitabilityCardCreateContentFooter
                disabled={
                  !isEmpty(
                    xor(Object.keys(values?.answers ?? {}), questionIds ?? []),
                  )
                }
              />
            )}
          </>
        )}
      </SuitabilityCardForm>
    );
  },
);

export default SuitabilityCardCreateContent;
