import { ButtonGroup, Button, Text } from "@chakra-ui/react";

import { AvailableTimeRanges } from "@/gql";

const sortOrders: Record<AvailableTimeRanges, number> = {
  [AvailableTimeRanges.ThreeMonths]: 1,
  [AvailableTimeRanges.SixMonths]: 2,
  [AvailableTimeRanges.OneYear]: 3,
  [AvailableTimeRanges.Max]: 4,
};

const RANGE_LABELS: Record<AvailableTimeRanges, string> = {
  [AvailableTimeRanges.Max]: `max`,
  [AvailableTimeRanges.OneYear]: `1y`,
  [AvailableTimeRanges.SixMonths]: `6m`,
  [AvailableTimeRanges.ThreeMonths]: `3m`,
};

export const RangeSelector = ({
  selected,
  onChange,
  availableTimeRanges,
}: {
  readonly selected: AvailableTimeRanges;
  readonly onChange: (range: AvailableTimeRanges) => void;
  readonly availableTimeRanges: readonly AvailableTimeRanges[];
}) => {
  if (!availableTimeRanges) return null;

  const sortedTimeRanges = [...availableTimeRanges].sort(
    (a, b) => sortOrders[a] - sortOrders[b],
  );

  return (
    <ButtonGroup variant="outline" size="sm" isAttached>
      {sortedTimeRanges.map((option) => (
        <Button
          key={option}
          onClick={() => onChange(option)}
          bg={selected === option ? `grey.800` : `transparent`}
          color={selected === option ? `white` : `inherit`}
        >
          <Text textStyle="heading-sm">{RANGE_LABELS[option]}</Text>
        </Button>
      ))}
    </ButtonGroup>
  );
};
