import { Box, FrigadeAnnouncement, useFlows } from "@frigade/react";
import { MouseEvent } from "react";

import { useRouter } from "next/router";

import { FlowKind } from "@/components/providers";
import { useFlowLauncher } from "@/hooks";

const useCompleteFlowOnLinkClick = (flowID: string) => {
  const router = useRouter();
  const { markFlowCompleted } = useFlows();

  return {
    onClick: (event: MouseEvent<HTMLDivElement>) => {
      if (event.target instanceof Element && event.target.tagName === `A`) {
        event.preventDefault();

        const href = event.target.getAttribute(`href`);
        const target = event.target.getAttribute(`target`);

        if (href && target === `_blank`) {
          window.open(href, `_blank`);
        } else if (href) {
          router.push(href);
        }

        markFlowCompleted(flowID);
      }
    },
  };
};

const HiiveWelcomeFlow = ({ flowKind }: { readonly flowKind: FlowKind }) => {
  const { getFlowID } = useFlowLauncher();

  const flowID = getFlowID(flowKind);

  const { onClick } = useCompleteFlowOnLinkClick(flowID);

  return (
    <Box onClick={onClick}>
      <FrigadeAnnouncement
        className="hiive-welcome"
        flowId={flowID}
        appearance={{
          styleOverrides: {
            announcementTitle: {
              paddingTop: `3rem`,
              paddingBottom: `1rem`,
              position: `relative`,
              fontSize: `1.5rem`,
            },
          },
        }}
      />
    </Box>
  );
};

export default HiiveWelcomeFlow;
