import { ReactNode } from "react";

import { VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

const SelectMenuSkeletonWrapper = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <VStack
    justifyContent="center"
    px={5}
    py={3.5}
    borderBottomWidth={1}
    borderColor="white"
    _last={{ borderBottomWidth: 0 }}
  >
    {children}
  </VStack>
);

export const SelectMenuSkeleton = () => (
  <VStack alignItems="start" spacing={0}>
    <SelectMenuSkeletonWrapper>
      <Skeleton h="12px" w="60px" />
    </SelectMenuSkeletonWrapper>
    <SelectMenuSkeletonWrapper>
      <Skeleton h="12px" w="120px" />
    </SelectMenuSkeletonWrapper>
    <SelectMenuSkeletonWrapper>
      <Skeleton h="12px" w="90px" />
    </SelectMenuSkeletonWrapper>
    <SelectMenuSkeletonWrapper>
      <Skeleton h="12px" w="120px" />
    </SelectMenuSkeletonWrapper>
    <SelectMenuSkeletonWrapper>
      <Skeleton h="12px" w="150px" />
    </SelectMenuSkeletonWrapper>
  </VStack>
);
