import { useTranslation } from "react-i18next";

import { HStack, Text, VStack, Badge, Box, Image } from "@chakra-ui/react";

import { ModernTreasuryVerifyAccountCardBankAccountFragment } from "@/gql";
import { useColors } from "@/hooks";
import { toShortDate } from "@/utils/datetime";

import FlatCard from "./FlatCard";

export const LastTransactionBadge = ({
  transaction,
}: {
  readonly transaction: {
    readonly friendlyId: string;
    readonly company: {
      readonly logoUrl?: string;
      readonly name: string;
    };
  };
}) => {
  const [grey900] = useColors([`grey.900`]);
  return (
    <Badge as={HStack} variant="ghost">
      {transaction.company.logoUrl && (
        <Box h="4" minW="4">
          <Image
            h="full"
            src={transaction.company.logoUrl}
            alt={transaction.company.name}
          />
        </Box>
      )}
      <Text textStyle="text-sm" color={grey900}>
        {transaction.friendlyId}
      </Text>
    </Badge>
  );
};

/**
 * Displays details of a user's bank account.
 */
const BankAccountCard = ({
  bankAccount,
  displayInsertedAt = true,
  displayLastTransaction,
}: {
  readonly bankAccount: ModernTreasuryVerifyAccountCardBankAccountFragment;
  readonly displayInsertedAt?: boolean;
  readonly displayLastTransaction?: boolean;
}) => {
  const { t } = useTranslation();
  const [grey900, grey700, grey500] = useColors([
    `grey.900`,
    `grey.700`,
    `grey.500`,
  ]);

  return (
    <FlatCard>
      <HStack justifyContent="space-between" m={4}>
        <HStack gap={2}>
          <Text textStyle="heading-md" color={grey900}>{t`account`}</Text>
          <Text textStyle="text-md" color={grey700}>
            &#x2022;&#x2022;&#x2022;&#x2022; {bankAccount.last4}
          </Text>
        </HStack>
        {displayInsertedAt && (
          <Text textStyle="text-sm" color={grey500}>{`${t`added`} ${toShortDate(
            bankAccount.insertedAt,
          )}`}</Text>
        )}
        {displayLastTransaction && bankAccount.lastUsedTransaction && (
          <VStack alignItems="flex-end">
            <Text
              textStyle="text-sm"
              color={grey700}
            >{t`used_for_last_transaction`}</Text>
            <LastTransactionBadge
              transaction={{
                friendlyId: bankAccount.lastUsedTransaction.bid.displayId,
                company: {
                  logoUrl:
                    bankAccount.lastUsedTransaction.company.logoUrl || ``,
                  name: bankAccount.lastUsedTransaction.company.name,
                },
              }}
            />
          </VStack>
        )}
      </HStack>
    </FlatCard>
  );
};

export default BankAccountCard;
