import { Form } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import Link from "next/link";

import { Flex, Text, GridItem, SimpleGrid } from "@chakra-ui/react";

import { PasswordField } from "@/components/auth";
import { HiiveButton } from "@/components/common";
import { EmailInput, FormikQL } from "@/components/form";
import { useLoginMutation, Session, LoginMutation } from "@/gql";
import { useAnalytics } from "@/hooks";
import { setAuthFromSession } from "@/state/auth";

const buildPayloadFromSession = (session: Session) => ({
  userId: session.userId,
  token: session.token,
  refreshToken: session.refreshToken,
});

const validationSchema = Yup.object().shape({
  email: Yup.string().nullable().required(`Email is required`),
  password: Yup.string().nullable().required(`Password is required`),
});

interface LoginFormValues {
  readonly email: string;
  readonly password: string;
}

const initialValues: LoginFormValues = {
  email: ``,
  password: ``,
};

const mapVariables = (values: LoginFormValues) => ({
  input: {
    ...values,
  },
});

export const LoginForm = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { t } = useTranslation();

  const onSuccess = (data: LoginMutation) => {
    if (!data.login?.session) return;

    analytics.identify(data.login.session.userId);

    const sessionPayload = buildPayloadFromSession(data.login.session);
    dispatch(setAuthFromSession(sessionPayload));
  };

  const mutation = useLoginMutation();

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`login`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <Form>
          <SimpleGrid rowGap={6} w="full">
            <GridItem colSpan={1}>
              <EmailInput
                name="email"
                label={t(`email`)}
                placeholder={t(`email_address`)}
                bg="h-white"
                autoFocus
              />
            </GridItem>
            <GridItem colSpan={1}>
              <PasswordField
                label={t(`password`)}
                name="password"
                placeholder={t(`enter_password`)}
              />
            </GridItem>
          </SimpleGrid>
          <Flex align="center" justify="space-between" mt={10}>
            <Text textStyle="lozenge-text" fontWeight="medium">
              <Link href="/forgot-password">{t(`forgotten_password`)}</Link>
            </Text>
            <HiiveButton
              type="submit"
              variant="rounded-solid-salmon"
              size="md"
              isLoading={isSubmitting}
              sentryLabel="[LoginForm] Sign in"
            >
              {t(`sign_in`)}
            </HiiveButton>
          </Flex>
        </Form>
      )}
    </FormikQL>
  );
};
