import { HiiveModalContentWrapper } from "@/components/common";
import { CreateListingSequenceModalAuthorization } from "@/components/listings";
import { BrokerSubmitListingSequenceModalCompanyFragment } from "@/gql";

import { BrokerSubmitListingSequenceModalContent } from "./BrokerSubmitListingSequenceModalContent";

interface BrokerSubmitListingSequenceModalProps {
  readonly initialCompany?: BrokerSubmitListingSequenceModalCompanyFragment;
}

const BrokerSubmitListingSequenceModal = ({
  initialCompany,
}: BrokerSubmitListingSequenceModalProps) => (
  <HiiveModalContentWrapper>
    <CreateListingSequenceModalAuthorization initialCompany={initialCompany}>
      <BrokerSubmitListingSequenceModalContent
        initialCompany={initialCompany}
      />
    </CreateListingSequenceModalAuthorization>
  </HiiveModalContentWrapper>
);

export default BrokerSubmitListingSequenceModal;
