import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

const ListingViewerRoundedSharesTooltip = () => {
  const { t } = useTranslation();

  return <Text>{t(`listing_viewer_rounded_shares_tooltip`)}</Text>;
};

export default ListingViewerRoundedSharesTooltip;
