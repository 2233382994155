import { VStack } from "@chakra-ui/react";

import { HeroBannerWrapper, Skeleton } from "@/components/common";

const HeroBannerSkeleton = () => (
  <HeroBannerWrapper bg={{ base: `transparent`, lg: `white` }}>
    <Skeleton h="38px" w="168px" mb={7} />
    <Skeleton h="24px" w="276px" mb={3} />
    <VStack w="full" alignItems="flex-start">
      <Skeleton h="14px" w="80%" />;
      <Skeleton h="14px" w="100%" />
      <Skeleton h="14px" w="90%" />;
    </VStack>
  </HeroBannerWrapper>
);

export default HeroBannerSkeleton;
