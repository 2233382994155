import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { CurrentActorContext } from "@/components/providers";
import {
  SuitabilityOption,
  SuitabilityQuestion,
  useSuitabilityQuestionGroupsQuery,
  useAnswerSuitabilityQuestionsMutation,
  CurrentContextDocument,
  GetCurrentSuitabilityAnswersDocument,
  SuitabilityAnswerInput,
  useGetCurrentSuitabilityAnswersQuery,
} from "@/gql";
import { useFormQL } from "@/hooks/react-hook-form";
import { Exact } from "@/types";
import {
  areSuitabilityAnswersCurrent,
  getUserCountry,
  isUUIDV4,
} from "@/utils";

interface Props {
  user: CurrentActorContext;
  onSuccess: () => void;
}

export interface FullSuitabilityQuestion {
  readonly id: string;
  readonly __typename?: string | null;
  readonly description?: string | null;
  readonly custom?: boolean | null;
  readonly text?: string | null;
  readonly order: number;
  readonly nextQuestionIds?:
    | (FullSuitabilityQuestion | null | string | undefined)[]
    | null;
  readonly suitabilityOptions?:
    | (FullSuitabilityQuestion | FullSuitabilityOption | null | undefined)[]
    | undefined
    | null;
}

export interface FullSuitabilityOption {
  readonly id: string;
  readonly __typename?: string | null;
  readonly description?: string | null;
  readonly custom?: boolean | null;
  readonly text?: string | null;
  readonly order: number;
  readonly nextQuestionIds:
    | (FullSuitabilityQuestion | undefined | null)[]
    | undefined
    | null;
}

export const cadDisclaimerFieldName = `cad-suitability-disclaimer`;
const nextQuestionIdsToSuitabilityQuestionsObject = (
  nextQuestionIds: string[],
  questions: SuitabilityQuestion[],
): FullSuitabilityQuestion[] =>
  nextQuestionIds.map((nextQuestionId: string) => {
    const question = questions.find(
      (question) => question.id === nextQuestionId,
    )!;

    return {
      ...question,
      suitabilityOptions: question?.suitabilityOptions.map(
        (option: SuitabilityOption) => ({
          ...option,
          nextQuestionIds: nextQuestionIdsToSuitabilityQuestionsObject(
            (option?.nextQuestionIds as string[]) || [],
            questions,
          ),
        }),
      ),
    };
  });

const isCustomOption = (
  options:
    | (Omit<SuitabilityOption, `suitabilityQuestion`> | null)[]
    | undefined,
) => !!options && options.length === 1 && options[0]?.custom;
const buildSuitabilityQuestionsTree = (
  questions: SuitabilityQuestion[] | null,
): FullSuitabilityQuestion => {
  if (!questions) return { id: ``, order: 0 };
  const fullQuestions: FullSuitabilityQuestion[] = questions
    .filter((question) => !!question && !!question?.suitabilityOptions)
    .map((question) => ({
      ...question,
      suitabilityOptions: question?.suitabilityOptions.map(
        (option: SuitabilityOption) => ({
          ...option,
          nextQuestionIds: nextQuestionIdsToSuitabilityQuestionsObject(
            (option?.nextQuestionIds as string[]) || [],
            questions,
          ),
        }),
      ),
    }));

  const entryQuestion: FullSuitabilityQuestion = fullQuestions.find(
    (question) => question.order === 0,
  )!;

  return {
    ...entryQuestion,
    suitabilityOptions: entryQuestion?.suitabilityOptions?.map(
      (option: FullSuitabilityOption) => ({
        ...option,
        nextQuestionIds: option?.nextQuestionIds?.map(
          (nextQuestion: FullSuitabilityQuestion) =>
            fullQuestions.find((question) => question.id === nextQuestion?.id),
        ),
      }),
    ),
  };
};

const useSuitabilityForm = ({ user, onSuccess }: Props) => {
  const { t } = useTranslation();

  const country = getUserCountry(user);

  const {
    data,
    loading: suitabilityLoading,
  } = useSuitabilityQuestionGroupsQuery({
    variables: {
      investorStatus: user.investorStatus!,
      countryId: country.id,
    },
  });

  const {
    data: currentAnswers,
    loading: currentSuitabilityLoading,
  } = useGetCurrentSuitabilityAnswersQuery();

  const mutation = useAnswerSuitabilityQuestionsMutation({
    refetchQueries: [
      CurrentContextDocument,
      GetCurrentSuitabilityAnswersDocument,
    ],
  });

  const suitabilityQuestions =
    data?.currentSuitabilityQuestionGroup?.suitabilityQuestions || [];
  const suitabilityQuestionsTree = useMemo(
    () =>
      buildSuitabilityQuestionsTree(
        // FIXME: remove typecasts once types are not all optional (when members are migrated to new onboarding)
        suitabilityQuestions as SuitabilityQuestion[],
      ),
    [suitabilityQuestions],
  );

  const initialValues = useMemo(() => {
    if (
      areSuitabilityAnswersCurrent(
        user,
        data?.currentSuitabilityQuestionGroup,
      ) &&
      currentAnswers
    ) {
      return Object.fromEntries(
        currentAnswers.suitabilityAnswers.map((answer) => [
          answer.suitabilityOption.suitabilityQuestion.id,
          answer.suitabilityOption.id,
        ]),
      );
    }

    return Object.fromEntries(
      suitabilityQuestions.map((question: SuitabilityQuestion) => [
        question.id,
        ``,
      ]),
    );
  }, [user, data, currentAnswers]);

  const optionLookupById: {
    [k: string]: SuitabilityOption;
  } = suitabilityQuestions.reduce((acc, nextQ: SuitabilityQuestion) => {
    const optionsForQ = nextQ.suitabilityOptions.reduce(
      (acc, nextO: SuitabilityOption) => ({
        ...acc,
        ...{
          [nextO.id]: nextO,
        },
      }),
      {},
    );

    return { ...acc, ...optionsForQ };
  }, {});

  const buildRequiredQuestionIds = (formValues: typeof initialValues) => {
    const answerIds = Object.keys(formValues)
      // bypass cad suitability answer
      .filter((key) => key !== cadDisclaimerFieldName)
      // checking that answer for this SuitabilityQuestion is a UUID and therefore a SuitabilityOption (SuitabilityQuestions.custom = true are free text questions )
      // if a SuitabilityOption has been selected, we'll check if it requires more answers to be validated (nextQuestionsIds)
      .filter((key) => (!!formValues[key] ? isUUIDV4(formValues[key]) : false))
      .reduce((acc, key) => {
        if (formValues[key]) return [...acc, formValues[key]];
        return acc;
      }, []);

    return answerIds.flatMap((answerId) => {
      const option = optionLookupById[answerId];
      return option.nextQuestionIds;
    });
  };

  const mapVariables = (values: {
    [k: string]: string;
  }): Exact<{
    answers: SuitabilityAnswerInput[] | SuitabilityAnswerInput;
  }> => {
    const valuesToTake = [
      ...buildRequiredQuestionIds(values),
      suitabilityQuestionsTree.id,
    ];
    const questionKeys = Object.keys(values).filter((key) =>
      valuesToTake.some((val) => val === key),
    );

    const answers = questionKeys.map((questionKey: string) => {
      const originalQuestion = suitabilityQuestions.find(
        (question) => question?.id === questionKey,
      );

      const originalSuitabilityOptions = originalQuestion?.suitabilityOptions;

      if (
        originalSuitabilityOptions &&
        isCustomOption(originalSuitabilityOptions)
      ) {
        return {
          suitabilityOptionId: originalSuitabilityOptions[0]?.id || ``,
          text: values[questionKey],
        };
      }

      return {
        suitabilityOptionId: values[questionKey],
        text: undefined,
      };
    });

    return {
      answers,
    };
  };
  const initialQuestion = suitabilityQuestions.find(
    (question) => question?.order === 0,
  );
  const isQuestionRequired = (
    questionId: string,
    formValues: typeof initialValues,
  ) => buildRequiredQuestionIds(formValues).includes(questionId);

  const yupSchema = Yup.object().shape(
    suitabilityQuestions?.reduce(
      (acc, question: SuitabilityQuestion) => {
        if (!question) return acc;
        return {
          ...acc,
          [question?.id || ``]: Yup.string().test({
            name: `${question?.id}-is-required-test`,
            params: {},
            message: t`required`,
            // eslint-disable-next-line func-names, object-shorthand
            test: function (value) {
              // initialQuestion is always required
              if (question?.id === initialQuestion?.id) return !!value;
              // eslint-disable-next-line react/no-this-in-sfc
              if (isQuestionRequired(question.id!, this.parent)) return !!value;
              return true;
            },
          }),
        };
      },
      {
        [cadDisclaimerFieldName]: Yup.boolean().test({
          name: `${cadDisclaimerFieldName}-required`,
          params: {},
          message: t`required`,
          // eslint-disable-next-line func-names, object-shorthand
          test: function (value) {
            // eslint-disable-next-line react/no-this-in-sfc
            const requiredQuestions = buildRequiredQuestionIds(this.parent);
            const isCADRequired =
              data?.currentSuitabilityQuestionGroup?.country === `CA`;

            return isCADRequired && requiredQuestions.length > 3
              ? !!value
              : true;
          },
        }),
      },
    ),
  );

  const formProps = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    onSuccess,
    validationSchema: yupSchema,
  });

  useEffect(() => {
    formProps.reset(initialValues, { keepDirtyValues: true });
  }, [initialValues]);

  const formAnswers = formProps.watch();

  const loading =
    formProps.isLoading || suitabilityLoading || currentSuitabilityLoading;

  return {
    formProps,
    country,
    questionTree: suitabilityQuestionsTree,
    loading,
    renderDisclaimer:
      country.name === `CA` && buildRequiredQuestionIds(formAnswers).length > 3,
  };
};

export default useSuitabilityForm;
