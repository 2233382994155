import { FormikValues, FormikProvider } from "formik";
import { useMemo } from "react";

import { useStepFormikQL } from "@/hooks";

import { StepFormikQLProps } from "./StepRouter";

const StepFormikQL = <
  TStepKeys extends string,
  TMutationData,
  TMutationVariables,
  TFormValues extends FormikValues
>({
  stepRouter,
  mutation,
  mutationNames,
  mapVariables,
  children,
  initialValues,
  context: Context,
}: StepFormikQLProps<
  TStepKeys,
  TMutationData,
  TMutationVariables,
  TFormValues
>) => {
  const {
    submitMutation,
    mutationData,
    formikProps,
    registerValidator,
  } = useStepFormikQL({
    stepRouter,
    mutation,
    mutationNames,
    mapVariables,
    initialValues,
  });

  const providerValue = useMemo(
    () => ({
      submitMutation: submitMutation(formikProps),
      mutationData,
      registerValidator,
    }),
    [submitMutation, mutationData, formikProps, registerValidator],
  );

  return (
    <FormikProvider value={formikProps}>
      <Context.Provider value={providerValue}>
        {children({ formikProps })}
      </Context.Provider>
    </FormikProvider>
  );
};

export default StepFormikQL;
