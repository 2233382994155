import { ReactNode } from "react";

import Link from "next/link";

import { Card, CardProps } from "@chakra-ui/react";

interface ActivityCardProps extends CardProps {
  readonly children: ReactNode;
  readonly href: string;
  readonly dataTestId?: string;
}

const ActivityCard = ({
  children,
  href,
  dataTestId,
  ...cardProps
}: ActivityCardProps) => (
  <Link style={{ width: `100%` }} href={href} data-testid={dataTestId}>
    <Card
      as="button"
      w="full"
      display="flex"
      textAlign="left"
      variant="activity"
      {...cardProps}
    >
      {children}
    </Card>
  </Link>
);

export default ActivityCard;
