import { ReactNode } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

interface SelectContainerProps extends BoxProps {
  readonly children: ReactNode;
}

export const SelectContainer = ({
  children,
  ...boxProps
}: SelectContainerProps) => (
  <Box position="relative" w="full" h="full" {...boxProps}>
    {children}
  </Box>
);
