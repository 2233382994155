import { ReactNode } from "react";

import { HStack, useRadioGroup } from "@chakra-ui/react";

import { TooltipFormLabel, RadioGroup, RadioCard } from "@/components/form";

interface Option {
  readonly value: string;
  readonly label: string;
}

type Size = `md` | `lg`;

interface AccreditationRadioCardInputProps {
  readonly options: readonly Option[];
  readonly label?: string | null;
  readonly isRequired?: boolean;
  readonly tooltipContent?: string | null;
  readonly cardSize?: Size;
  readonly onChange: (value: string) => void;
  readonly value: string;
  readonly href?: string;
  readonly dataTestid?: string;
  readonly renderOptions?: (
    options: readonly Option[],
    radioGroup: RadioGroup,
  ) => ReactNode;
}

const AccreditationRadioCardInput = ({
  options,
  label,
  isRequired,
  tooltipContent,
  cardSize = `md`,
  onChange,
  value,
  renderOptions,
  href,
  dataTestid,
}: AccreditationRadioCardInputProps) => {
  const radioGroup = useRadioGroup({
    onChange,
    value,
  });

  const group = radioGroup.getRootProps();

  if (!!renderOptions) return <>renderOptions(options, radioGroup)</>;

  return (
    <HStack spacing={5} justify="space-between" data-testid={dataTestid}>
      <TooltipFormLabel
        href={href}
        fontWeight="normal"
        tooltipContent={tooltipContent}
      >
        {label}
        {isRequired ? ` *` : ``}
      </TooltipFormLabel>
      <HStack spacing={5} {...group}>
        {options.map(({ value, label }) => {
          const radio = radioGroup.getRadioProps({ value });

          switch (cardSize) {
            case `md`:
              return (
                <RadioCard w={20} h="60px" key={value} {...radio}>
                  {label}
                </RadioCard>
              );
            case `lg`:
              return (
                <RadioCard w="130px" h={20} key={value} {...radio}>
                  {label}
                </RadioCard>
              );
            default:
              return null;
          }
        })}
      </HStack>
    </HStack>
  );
};
export default AccreditationRadioCardInput;
