import { t } from "i18next";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import { HiiveButton, WithQuery } from "@/components/common";
import { FormikQL } from "@/components/form";
import {
  HoldingsFormValues,
  createValidationSchema,
  mapToVariables,
} from "@/components/unaccredited-seller";
import {
  ListedHoldingFragment,
  UnlistedHoldingFragment,
  useAddHoldingsV2Mutation,
  useHoldingsQuery,
  HoldingsDocument,
} from "@/gql";
import constants from "@/utils/constants";

import { AddHoldingsForm } from "./AddHoldingsForm";
import { ListedHolding, UnListedHolding } from "./Holding";

const ViewHoldings = ({
  onAdd,
  holdings,
}: {
  onAdd: () => void;
  holdings?: (ListedHoldingFragment | UnlistedHoldingFragment)[] | null;
}) => {
  if (!holdings) return null;

  const renderHolding = (
    holding: ListedHoldingFragment | UnlistedHoldingFragment,
  ) => {
    switch (holding.__typename) {
      case `Holding`:
        return <ListedHolding holding={holding} />;
      case `UnlistedHolding`:
        return <UnListedHolding holding={holding} />;
      default:
        return null;
    }
  };

  return (
    <CardBody>
      <VStack alignItems="start" spacing={4}>
        <SimpleGrid
          w="full"
          columns={{ base: 1, xl: 2 }}
          columnGap={6}
          rowGap={4}
        >
          {holdings.map((holding) => (
            <GridItem key={holding.id}>{renderHolding(holding)}</GridItem>
          ))}
        </SimpleGrid>
        {holdings.length < constants.max_holdings && (
          <HiiveButton
            variant="rounded-solid-salmon"
            size="xl"
            onClick={onAdd}
            sentryLabel="[MyHoldings] Add holding"
          >
            <Text as="span">{t(`add_holding`)}</Text>
          </HiiveButton>
        )}
      </VStack>
    </CardBody>
  );
};

const AddHoldings = ({
  onClose,
  holdings,
}: {
  onClose: () => void;
  holdings?: (ListedHoldingFragment | UnlistedHoldingFragment)[] | null;
}) => {
  const mutation = useAddHoldingsV2Mutation({
    refetchQueries: [HoldingsDocument],
  });

  const { t } = useTranslation();
  const validationSchema = createValidationSchema(t);

  const initialValues: HoldingsFormValues = {
    selectedCompany: null,
    holdingBundles: [],
    sellerLotDisclaimer: false,
  };

  return (
    <FormikQL
      initialValues={initialValues}
      mutation={mutation}
      mutationNames={[`addHoldingsV2`]}
      mapVariables={mapToVariables}
      onSuccess={() => onClose()}
      validationSchema={validationSchema}
    >
      {() => <AddHoldingsForm holdings={holdings} onClose={onClose} />}
    </FormikQL>
  );
};

export const HoldingsCard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: `add-holdings`,
  });

  const query = useHoldingsQuery();

  return (
    <Card variant="darkened-footer" w="full">
      <CardHeader>
        <Text textStyle="heading-sm">
          {t(isOpen ? `add_a_holding` : `my_holdings`)}
        </Text>
      </CardHeader>
      <WithQuery query={query}>
        {({
          data: {
            myHoldings: { holdingsV2: holdings },
          },
        }) =>
          isOpen ? (
            <AddHoldings onClose={onClose} holdings={holdings} />
          ) : (
            <ViewHoldings onAdd={onOpen} holdings={holdings} />
          )
        }
      </WithQuery>
    </Card>
  );
};
