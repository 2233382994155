import { Flex } from "@chakra-ui/react";

import { ListingInfoCardSkeleton } from "@/components/postings";

import { BidCardSkeleton } from "./BidCardSkeleton";

export const BidInfoSkeleton = () => (
  <Flex direction="column" gap={4}>
    <BidCardSkeleton />
    <ListingInfoCardSkeleton shouldShowActivity />
  </Flex>
);
