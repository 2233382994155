import { useTranslation } from "react-i18next";

import { Flex, Text } from "@chakra-ui/react";

import { FocusedShareDetails } from "@/components/common";
import {
  BidInfoCardBidShareDetails,
  BidInfoCardStatusTile,
} from "@/components/postings";
import { BidInfoCardBidFragment, BidState } from "@/gql";

import { BrokerSellerFeeBreakdown } from "./shared";

const BrokerSellerBidInfoCard = ({
  bid,
}: {
  readonly bid: BidInfoCardBidFragment;
}) => {
  const { t } = useTranslation();
  const placedAt = bid.placedAt || bid.counteredAt || bid.expireAt;
  const hasCounterBid = bid.state === BidState.Countered;
  const { company } = bid.listing;

  return (
    <Flex direction="column" gap={7}>
      <Flex direction="column" gap={7} data-testid="bid-info-card">
        {hasCounterBid && (
          <Text textStyle="heading-sm">{t`bid_info_original_bid`}</Text>
        )}
        <FocusedShareDetails.Card variant="bid">
          <FocusedShareDetails.Header
            title={`Bid ${bid.displayId}`}
            company={company}
          >
            <BidInfoCardBidShareDetails bid={bid} />
          </FocusedShareDetails.Header>
          <FocusedShareDetails.Content>
            <FocusedShareDetails.ContentSection>
              <Flex direction="column" gap={6}>
                {placedAt && (
                  <BidInfoCardStatusTile
                    bid={bid}
                    showCounterBidDetails={false}
                  />
                )}
                <BrokerSellerFeeBreakdown bid={bid} />
              </Flex>
            </FocusedShareDetails.ContentSection>
          </FocusedShareDetails.Content>
        </FocusedShareDetails.Card>
      </Flex>
    </Flex>
  );
};

export default BrokerSellerBidInfoCard;
