import { ReactNode } from "react";

import { Box, ChakraProps, Container } from "@chakra-ui/react";

interface ContentWrapperProps {
  readonly children: ReactNode;
  readonly transform?: ChakraProps["transform"];
}

export const ContentWrapper = ({
  children,
  transform,
}: ContentWrapperProps) => (
  <Box w="full" px={{ base: 4, lg: 8 }}>
    <Container maxW="max-width-md" px={0} transform={transform}>
      {children}
    </Container>
  </Box>
);
