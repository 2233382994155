import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// https://translate.i18next.com
import { locales, TranslationKey } from "./locales";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: locales,
    lng: `en`, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { locales };
export type { TranslationKey };

export default i18n;
