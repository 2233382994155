import React from "react";

import { Flex } from "@chakra-ui/react";

import {
  StandingBidInfoDiscussionFragment,
  StandingBidInfoStandingBidFragment,
} from "@/gql";

import StandingBidInfoCard from "./StandingBidInfoCard";

const StandingBidInfo = ({
  standingBid,
  discussion,
}: {
  readonly standingBid: StandingBidInfoStandingBidFragment;
  readonly discussion?: StandingBidInfoDiscussionFragment | null;
}) => (
  <Flex direction="column" gap={4}>
    <StandingBidInfoCard standingBid={standingBid} discussion={discussion} />
  </Flex>
);

export default StandingBidInfo;
