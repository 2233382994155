import { Flex, Text } from "@chakra-ui/react";

import { SensitiveText } from "@/components/common";

const PropertyRow = ({
  title,
  children,
  isSensitive,
}: {
  readonly title: string;
  readonly children: JSX.Element | string;
  readonly isSensitive?: boolean;
}) => (
  <Flex w="full" align="center">
    <Text
      w={36}
      whiteSpace="nowrap"
      mr={{ base: 2, sm: 3 }}
      textStyle="heading-3xs"
      textTransform="uppercase"
    >
      {title}
    </Text>
    <SensitiveText fontSize="xs" isSensitive={isSensitive}>
      {children}
    </SensitiveText>
  </Flex>
);

export default PropertyRow;
