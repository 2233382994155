import { useApolloClient } from "@apollo/client";

import { Text, VStack } from "@chakra-ui/react";

import {
  AcceptedBidDetailsBidFragment,
  SigningProcedure,
  TransactionState,
  useOnTransactionDocumentReadySubscription,
} from "@/gql";
import { useCurrentActor } from "@/hooks";

import { TransactionStates } from "./TransactionStates";

const AcceptedBidDetails = ({
  bid,
}: {
  readonly bid: AcceptedBidDetailsBidFragment;
}) => {
  const apolloClient = useApolloClient();
  const actor = useCurrentActor();

  const shouldSubscribeToDocumentReady =
    !bid.transaction?.document &&
    bid.transaction?.signingProcedure === SigningProcedure.Automated;

  useOnTransactionDocumentReadySubscription({
    skip: !shouldSubscribeToDocumentReady,
    variables: { transactionId: bid.transaction?.id || `` },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const document = data?.onTransactionDocumentReady;

      if (document && bid.transaction) {
        apolloClient.cache.modify({
          id: apolloClient.cache.identify(bid.transaction),
          fields: {
            document() {
              return document;
            },
          },
        });
      }
    },
  });

  const showTransferStatus =
    (!bid.transaction?.rofr || actor.id === bid.counterpartyId) &&
    bid.transaction?.state !== TransactionState.Cancelled &&
    bid.transaction?.state !== TransactionState.Pending &&
    bid.transaction?.state !== TransactionState.InReview;

  if (!showTransferStatus) return null;

  return (
    <VStack
      alignItems="flex-start"
      spacing={4}
      borderWidth={0.5}
      borderColor="grey.200"
      borderRadius="md"
      p={5}
    >
      <Text as="h3" textStyle="heading-2xs">
        Transfer Status
      </Text>
      <TransactionStates bid={bid} />
    </VStack>
  );
};

export default AcceptedBidDetails;
