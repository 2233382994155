import { ReactNode } from "react";

import { StackProps, VStack } from "@chakra-ui/react";

interface CardProps extends StackProps {
  readonly children: ReactNode;
}
export const Card = ({ children, ...stackProps }: CardProps) => (
  <VStack
    w="full"
    h="full"
    borderRadius={6}
    borderWidth="0.5px"
    borderColor="grey.200"
    px={4}
    spacing={0}
    py={2}
    alignItems="flex-start"
    justifyContent="space-between"
    {...stackProps}
  >
    {children}
  </VStack>
);
