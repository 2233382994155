import { CaretRight } from "@phosphor-icons/react";

import { Center } from "@chakra-ui/react";

import HiiveButton, { HiiveButtonProps } from "./HiiveButton";

const RightArrow = () => (
  <Center px={2.5} position="absolute" right={0} top={0} h="full">
    <CaretRight size={20} />
  </Center>
);

const HiiveNextButton = ({ sentryLabel, ...buttonProps }: HiiveButtonProps) => (
  <HiiveButton
    variant="rounded-outline-salmon"
    size="xl"
    w={{ base: `full`, sm: `unset` }}
    maxW="unset"
    rightIcon={<RightArrow />}
    type="submit"
    sentryLabel={sentryLabel}
    {...buttonProps}
  >
    Next
  </HiiveButton>
);

export default HiiveNextButton;
