import { useApolloClient } from "@apollo/client";
import { omit } from "lodash";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  Flex,
  GridItem,
  Link,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";

import { EMAIL_REGEX, PASSWORD_REGEX } from "@/components/auth";
import { SlideAnimation } from "@/components/auth-v2";
import { HiiveButton } from "@/components/common";
import {
  FormCheckboxInput,
  FormPasswordInput,
  FormPhoneNumberInput,
  FormTextInput,
} from "@/components/react-hook-form";
import {
  Session,
  SignupAttributionInput,
  SignupConfigFragment,
  SignupMutation,
  useSignupMutation,
} from "@/gql";
import {
  OnboardingRoutes,
  useAnalytics,
  useCustomToast,
  useIsHiiveConnect,
} from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { RootState } from "@/state";
import { reset as resetAuth, setAuthFromSession } from "@/state/auth";
import {
  constants,
  getUTMCookieData,
  utmCookieDataToSegmentOption,
  validPhoneNumber,
} from "@/utils";
import { normalizePhoneNumber } from "@/utils/format";

const createValidationSchema = (requireSignupKey: boolean) =>
  Yup.object().shape({
    firstName: Yup.string().required(`First name is required`),
    lastName: Yup.string().required(`Last name is required`),
    phoneNumber: Yup.string()
      .required(`Phone number is required`)
      .test(
        `valid phone number`,
        `Please enter a valid phone number`,
        validPhoneNumber,
      ),
    email: Yup.string()
      .required(`Email is required`)
      .matches(EMAIL_REGEX, `Invalid email address`),
    password: Yup.string()
      .required(`Password is required`)
      .matches(
        PASSWORD_REGEX,
        `Must contain at least 8 characters, one uppercase, one lowercase, one number or punctuation character`,
      ),
    passwordConfirmation: Yup.string()
      .required(`Password confirmation is required`)
      .oneOf([Yup.ref(`password`)], `Passwords must match`),
    hasAgreedToPolicy: Yup.boolean().required(`Required`),
    signupKey: requireSignupKey
      ? Yup.string().required(`Missing signup key`)
      : Yup.string(),
  });

interface SignUpFormValues {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phoneNumber: string;
  readonly password: string;
  readonly passwordConfirmation: string;
  readonly hasAgreedToPolicy: boolean;
  readonly signupKey: string;
  readonly brokerUser: boolean;
}

const initialValues = (brokerUser: boolean): SignUpFormValues => ({
  firstName: ``,
  lastName: ``,
  email: ``,
  phoneNumber: ``,
  password: ``,
  passwordConfirmation: ``,
  signupKey: ``,
  hasAgreedToPolicy: false,
  brokerUser,
});

const mapAttribution = (attribution: SignupAttributionInput | null) =>
  attribution &&
  Object.keys(attribution).reduce(
    (acc, key: keyof SignupAttributionInput) => ({
      ...acc,
      [key]: omit(attribution[key], `v`),
    }),
    {},
  );

// Note: The hasAgreedToPolicy field is used only for the UI form validation, and we destructure it here
// so that it won't be sent up in the mutation request with the other fields.
const mapVariables = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { hasAgreedToPolicy, ...values }: SignUpFormValues,
  attribution: SignupAttributionInput | null,
) => ({
  input: {
    signup_attribution: attribution ? mapAttribution(attribution) : undefined,
    ...values,
    ...(values.phoneNumber
      ? {
          phoneNumber: normalizePhoneNumber(values.phoneNumber),
        }
      : {}),
  },
});

const buildPayloadFromSession = (session: Session) => ({
  userId: session.userId,
  token: session.token,
  refreshToken: session.refreshToken,
});

const SignUpForm = ({ config }: { readonly config: SignupConfigFragment }) => {
  const { successToast } = useCustomToast();
  const { t } = useTranslation();
  const mutation = useSignupMutation();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const router = useRouter();
  const analytics = useAnalytics();
  const token = useSelector((state: RootState) => state.auth.token);
  const isHiiveConnect = useIsHiiveConnect();

  const [isSigningIn, setIsSigningIn] = useState(false);
  const attribution = getUTMCookieData();
  useEffect(() => {
    if (!token) return;
    // Clearing Apollo Client cache and redux persist on mount
    dispatch(resetAuth());
    client.clearStore();
  }, []);

  useEffect(() => {
    // Navigate to onboarding if we're signing in and there's a token in the store
    if (token && isSigningIn) router.replace(OnboardingRoutes.InvestorStatus);
  }, [token, isSigningIn]);

  const onSuccess = async (data: SignupMutation) => {
    if (!data.signup?.session) return;

    const option = utmCookieDataToSegmentOption(attribution);

    analytics.identify(data.signup.session.userId, {}, option);

    successToast(`Account created successfully!`);
    setIsSigningIn(true);

    const sessionPayload = buildPayloadFromSession(data.signup.session);
    dispatch(setAuthFromSession(sessionPayload));
  };

  const validationSchema = createValidationSchema(config.requireSignupKey);

  const { handleSubmit, isLoading, control, watch } = useFormQL({
    mutation,
    mapVariables: (values) => mapVariables(values, attribution),
    initialValues: initialValues(isHiiveConnect),
    validationSchema,
    onSuccess,
  });

  const hasAgreedToPolicy = watch(`hasAgreedToPolicy`);

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={8}>
        <SlideAnimation>
          <Card>
            <CardBody>
              <SimpleGrid
                columns={2}
                columnGap={5}
                rowGap={{ base: 5, md: 6 }}
                w="full"
              >
                <GridItem colSpan={2} columnGap={5}>
                  <Flex
                    direction={{ base: `column`, md: `row` }}
                    rowGap={4}
                    columnGap={5}
                  >
                    <FormTextInput
                      control={control}
                      isRequired
                      name="firstName"
                      label={t(`name`)}
                      placeholder={t(`first`)}
                      bg="white"
                      autoFocus
                    />
                    <Box w="full">
                      <Spacer display={{ base: `none`, md: `block` }} h={6} />
                      <FormTextInput
                        mt={{ base: 0, md: 1.5 }}
                        control={control}
                        name="lastName"
                        placeholder={t(`last`)}
                        label={t(`last_name`)}
                        labelSrOnly
                      />
                    </Box>
                  </Flex>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormPhoneNumberInput
                    control={control}
                    isRequired
                    name="phoneNumber"
                    label={t(`telephone`)}
                  />
                </GridItem>

                <GridItem colSpan={2}>
                  <FormTextInput
                    type="email"
                    isRequired
                    name="email"
                    label={t(`email`)}
                    placeholder={t(`email_address`)}
                    control={control}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <VStack spacing={1}>
                    <FormPasswordInput
                      isRequired
                      name="password"
                      label={t(`password`)}
                      placeholder={t(`signup_password_placeholder`)}
                      control={control}
                    />
                    <Text textStyle="text-sm">
                      {t(`signup_password_policy`)}
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormPasswordInput
                    control={control}
                    label={t(`signup_confirm_password`)}
                    labelSrOnly
                    name="passwordConfirmation"
                    placeholder={t(`signup_confirm_password`)}
                    isRequired
                  />
                </GridItem>
                {config.requireSignupKey && (
                  <GridItem colSpan={2}>
                    <FormPasswordInput
                      control={control}
                      isRequired
                      name="signupKey"
                      label={t(`signup_key`)}
                      placeholder={t(`signup_key_placeholder`)}
                    />
                  </GridItem>
                )}
                <GridItem colSpan={2}>
                  <FormCheckboxInput
                    control={control}
                    name="hasAgreedToPolicy"
                    dataTestId="termsCheckbox"
                    label={
                      <Text textStyle="text-sm">
                        <Trans
                          i18nKey="signup_terms_and_policy_agreement"
                          components={[
                            <Link
                              key="terms"
                              variant="chunky"
                              target="_blank"
                              href={`${constants.marketing_website_url}/terms`}
                            />,
                            <Link
                              key="privacy"
                              variant="chunky"
                              target="_blank"
                              href={`${constants.marketing_website_url}/privacy`}
                            />,
                          ]}
                        />
                      </Text>
                    }
                  />
                </GridItem>
              </SimpleGrid>
            </CardBody>
          </Card>
        </SlideAnimation>
        <Flex justifyContent="flex-end" w="full">
          <HiiveButton
            type="submit"
            isLoading={isLoading}
            size="xl"
            w={{ base: `full`, sm: `unset` }}
            maxW="unset"
            variant="rounded-solid-salmon"
            isDisabled={!hasAgreedToPolicy}
            sentryLabel="[SignUpPage] Submit"
          >
            {t(`sign_up`)}
          </HiiveButton>
        </Flex>
      </VStack>
    </form>
  );
};

export default SignUpForm;
