import { Trans, useTranslation } from "react-i18next";

import { Box, Card, CardBody, CardFooter, Text } from "@chakra-ui/react";

import {
  ComplianceReminder,
  UnaccreditedSellerComplianceReminder,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { Maybe, TradeIntentType } from "@/types";
import { getIsUnaccreditedSeller } from "@/utils";

interface MockAcceptStandingBidCTACardProps {
  readonly allowPartialAccept: Maybe<boolean> | undefined;
  readonly actor: UserWithInstitutionFragment;
}

export const MockAcceptStandingBidCTACard = withCurrentActor(
  ({ allowPartialAccept, actor }: MockAcceptStandingBidCTACardProps) => {
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    const { t } = useTranslation();

    return (
      <Card w="full" variant="darkened-footer">
        <CardBody h="full">
          <Box>
            <Text textStyle="heading-sm" textTransform="uppercase" mb={3}>
              {t(`accept_standing_bid`)}
            </Text>
            <Text>
              <Trans
                i18nKey={
                  allowPartialAccept
                    ? `accepting_buyer_bid_partial_notice`
                    : `accepting_buyer_bid_notice`
                }
              />
            </Text>
          </Box>
        </CardBody>
        <CardFooter>
          {isUnaccreditedSeller ? (
            <UnaccreditedSellerComplianceReminder />
          ) : (
            <ComplianceReminder tradeIntent={TradeIntentType.Sell} />
          )}
        </CardFooter>
      </Card>
    );
  },
);
