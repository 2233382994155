import currency from "currency.js";
import clamp from "lodash/clamp";
import React from "react";

import {
  FormLabel,
  NumberInput,
  NumberInputField,
  Flex,
} from "@chakra-ui/react";

import { toFixedDecimal } from "@/utils";

export const LastRoundValuationInput = ({
  value,
  label,
  min = 0,
  max,
  placeholder,
  onChange,
}: {
  readonly value: number | null;
  readonly label: string;
  readonly placeholder?: string;
  readonly onChange: (value: number) => void;
  readonly min?: number;
  readonly max?: number;
}) => {
  const currencyOptions = { precision: 0, symbol: `$` };

  const toDisplayValue = (value: number | null) => {
    if (!value) return ``;
    return currency(value, currencyOptions).format();
  };

  const toNumberValue = (value: string) => {
    if (value === ``) return 0;
    const fixedValue = toFixedDecimal(2, value);
    const currencyValue = currency(fixedValue, currencyOptions).intValue;
    return clamp(currencyValue, 0, 999);
  };

  const getIsValidInput = (value: string) => {
    if (Number.isNaN(+value) || +value < 0) return false;
    return true;
  };

  const _onChange = (value: string) => {
    if (getIsValidInput(value)) {
      const numberValue = toNumberValue(value);
      onChange(numberValue);
    }
  };

  const displayedValue = toDisplayValue(value);

  return (
    <Flex direction="column" gap={1}>
      <FormLabel whiteSpace="nowrap" htmlFor={label}>
        {label}
      </FormLabel>
      <NumberInput
        name={label}
        min={min}
        max={max}
        pattern="[0-9,\.]*(.[0-9,\.]+)?"
        onChange={_onChange}
        value={displayedValue}
        precision={2}
      >
        <NumberInputField placeholder={placeholder} />
      </NumberInput>
    </Flex>
  );
};
