import { Eye, EyeSlash } from "@phosphor-icons/react";
import { useField } from "formik";
import { forwardRef, useRef, FocusEvent } from "react";
import { match, P } from "ts-pattern";

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  Text,
} from "@chakra-ui/react";

interface PasswordFieldProps extends InputProps {
  readonly name: string;
  readonly label?: string;
  readonly isRequired?: boolean;
  readonly showPolicy?: boolean;
}

const PasswordPolicy = () => (
  <Text textStyle="deprecated-text-sm">
    Minimum of 8 characters, one uppercase letter, one lowercase letter, one
    number or punctuation character.
  </Text>
);

const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({ name, label, isRequired, showPolicy, ...props }, ref) => {
    const [field, { touched, error }, { setValue }] = useField(name);
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = useRef<HTMLInputElement>(null);

    const mergeRef = useMergeRefs(inputRef, ref);

    const onClickReveal = () => {
      onToggle();
      const input = inputRef.current;
      if (input) {
        input.focus({ preventScroll: true });
        const length = input.value.length * 2;
        requestAnimationFrame(() => {
          input.setSelectionRange(length, length);
        });
      }
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) =>
      setValue(
        match(event?.target?.value)
          .with(``, (_) => null)
          .with(P._, (x) => x)
          .exhaustive(),
      );

    const value = match(field.value)
      .with(null, (_) => ``)
      .with(P._, (x) => x)
      .exhaustive();

    return (
      <FormControl id={name} isInvalid={(error && touched) || false}>
        <FormLabel htmlFor={name}>
          {label}
          {isRequired ? ` *` : ``}
        </FormLabel>
        <InputGroup>
          <InputRightElement>
            <IconButton
              minW={10}
              maxW={10}
              mr={2.5}
              tabIndex={-1}
              _focus={{
                outline: `none`,
              }}
              bg="transparent !important"
              variant="ghost"
              aria-label={isOpen ? `Mask password` : `Reveal password`}
              icon={
                isOpen ? (
                  <EyeSlash color="#403F41" size={32} />
                ) : (
                  <Eye color="#403F41" size={32} />
                )
              }
              onClick={onClickReveal}
            />
          </InputRightElement>
          <Input
            {...field}
            onBlur={handleBlur}
            value={value}
            ref={mergeRef}
            type={isOpen ? `text` : `password`}
            autoComplete="current-password"
            {...props}
          />
        </InputGroup>
        {showPolicy && <PasswordPolicy />}
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  },
);

PasswordField.displayName = `PasswordField`;

export default PasswordField;
