import currency from "currency.js";
import { Form } from "formik";
import { AnimatePresence } from "framer-motion";

import { AnimatedCardStep, StepFormikQL } from "@/components/form";
import {
  BidPageMyActivityDocument,
  CompanyWatchlistActorDocument,
  ListingPageListingByIdDocument,
  ListingPageBuyerActivityPlaceBidCardListingFragment,
  UserActivityMyActivityDocument,
  usePlaceBidCardPlaceBidMutation,
} from "@/gql";
import { useMobileScrollIntoView, useStepRouter } from "@/hooks";
import { getNumSharesAvailableRounded } from "@/utils";

import {
  PlaceBid,
  PlaceBidCardFormValues,
  PlaceBidCardStepFormContext,
  PlaceBidConfirmation,
  stepKeys,
  StepKeys,
} from "./steps";

const createInitialValues = (
  listing?: ListingPageBuyerActivityPlaceBidCardListingFragment,
): PlaceBidCardFormValues => ({
  listingId: listing?.id || ``,
  numShares: listing ? getNumSharesAvailableRounded(listing) : 0,
  pricePerShare: !!listing?.listingPricePerShare
    ? listing.listingPricePerShare / 100
    : ``,
  timeLimit: 7,
  confirmed: false,
  muteNotifyWatchers: false,
  representedEmail: ``,
  representedFirstName: ``,
  representedLastName: ``,
  notifyRepresentingEmail: true,
});

const mapVariables = ({
  confirmed: _confirmed,
  pricePerShare,
  timeLimit,
  representedEmail,
  representedFirstName,
  representedLastName,
  ...values
}: PlaceBidCardFormValues) => ({
  input: {
    ...values,
    pricePerShare: currency(pricePerShare).intValue,
    timeLimit: timeLimit * 24,
    representedUser: {
      email: representedEmail,
      firstName: representedFirstName,
      lastName: representedLastName,
    },
  },
});

const PlaceBidCard = ({
  listing,
}: {
  readonly listing: ListingPageBuyerActivityPlaceBidCardListingFragment;
}) => {
  const mutation = usePlaceBidCardPlaceBidMutation({
    refetchQueries: [
      ListingPageListingByIdDocument,
      BidPageMyActivityDocument,
      UserActivityMyActivityDocument,
      CompanyWatchlistActorDocument,
    ],
  });

  const initialValues = createInitialValues(listing);

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.placeBidFormCard,
      stepKeys.placeBidConfirmationFormCard,
    ],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  const { scrollRef } = useMobileScrollIntoView({}, [currentStepKey]);

  return (
    <StepFormikQL
      stepRouter={stepRouter}
      mutation={mutation}
      mutationNames={[`placeBid`]}
      initialValues={initialValues}
      mapVariables={mapVariables}
      context={PlaceBidCardStepFormContext}
    >
      {({ formikProps }) => (
        <Form ref={scrollRef} style={{ width: `100%` }}>
          <AnimatePresence initial={false} mode="wait">
            {stepKeys.placeBidFormCard === currentStepKey && (
              <AnimatedCardStep key={stepKeys.placeBidFormCard}>
                <PlaceBid
                  listing={listing}
                  stepRouter={stepRouter}
                  {...formikProps}
                />
              </AnimatedCardStep>
            )}
            {stepKeys.placeBidConfirmationFormCard === currentStepKey && (
              <AnimatedCardStep key={stepKeys.placeBidConfirmationFormCard}>
                <PlaceBidConfirmation
                  stepRouter={stepRouter}
                  listing={listing}
                  {...formikProps}
                />
              </AnimatedCardStep>
            )}
          </AnimatePresence>
        </Form>
      )}
    </StepFormikQL>
  );
};
export default PlaceBidCard;
