import { ReactNode } from "react";

import { Box } from "@chakra-ui/react";

const HiiveModalContentWrapper = ({
  children,
}: {
  readonly children: ReactNode;
}) => <Box w={{ base: `full`, md: `2xl` }}>{children}</Box>;

export default HiiveModalContentWrapper;
