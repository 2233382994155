import { Minus, Plus } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
} from "@chakra-ui/react";

import { FocusedShareDetails } from "@/components/common";
import { InitialTransactionFeeBreakdown } from "@/components/postings";
import {
  BidFeeDiscountBreakdownFeeDiscountApplicationFragment,
  BidInfoCardBidTransactionHistoryFragment,
} from "@/gql";

interface InitialTransactionCardProps {
  readonly feeDiscountApplications?:
    | readonly BidFeeDiscountBreakdownFeeDiscountApplicationFragment[]
    | null;
  readonly initialTransaction: BidInfoCardBidTransactionHistoryFragment;
  readonly buyerId: string;
  readonly buyerBrokerId?: string | null;
  readonly brokerCounterpartyId?: string | null;
}

const InitialTransactionCard = ({
  feeDiscountApplications,
  initialTransaction,
  buyerId,
  buyerBrokerId,
  brokerCounterpartyId,
}: InitialTransactionCardProps) => {
  const { t } = useTranslation();

  return (
    <FocusedShareDetails.HeaderCard variant="base">
      <Accordion allowToggle borderWidth={0} p={2}>
        <AccordionItem borderWidth={0} borderBottomWidth={0} borderBlockEnd={0}>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  justifyContent="space-between"
                  _hover={{ bg: `white` }}
                >
                  <Flex align="center">
                    <Text textStyle="heading-sm">
                      {t(`initial_transaction_title`)}
                    </Text>
                  </Flex>
                  {isExpanded ? <Minus size={20} /> : <Plus size={20} />}
                </AccordionButton>
              </h2>
              <AccordionPanel paddingBottom={1}>
                <InitialTransactionFeeBreakdown
                  brokerCounterpartyId={brokerCounterpartyId}
                  buyerBrokerId={buyerBrokerId}
                  buyerId={buyerId}
                  feeDiscountApplications={feeDiscountApplications}
                  transactionHistory={initialTransaction}
                />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </FocusedShareDetails.HeaderCard>
  );
};

export default InitialTransactionCard;
