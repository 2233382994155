import * as React from "react";

import {
  Heading,
  Stack,
  StackProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

interface HeadingGroupProps extends StackProps {
  readonly title: string;
  readonly description: string;
}

const HeadingGroup = ({
  title,
  description,
  ...stackProps
}: HeadingGroupProps) => (
  <Stack spacing="1" {...stackProps}>
    <Heading size="md" fontWeight="semibold">
      {title}
    </Heading>
    <Text color={useColorModeValue(`gray.600`, `gray.400`)}>{description}</Text>
  </Stack>
);

export default HeadingGroup;
