import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCardBadge,
  ActivityCardStandingBidNumShareAndPriceDetails,
  ActivityCardTimestamp,
} from "@/components/common";
import { LatestActivityListStandingBidFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import { getIsBroker } from "@/utils";

import { BrokerLatestStandingBidSubcard } from ".";

export const LatestStandingBidSubcard = ({
  standingBid,
}: {
  readonly standingBid: LatestActivityListStandingBidFragment;
}) => {
  const router = useRouter();
  const actor = useCurrentActor();
  const sellSideBrokerPortalFeatureEnabled = useSellSideBrokerPortalEnabled();
  const isSolicited = standingBid.solicited;

  if (sellSideBrokerPortalFeatureEnabled && getIsBroker(actor) && isSolicited) {
    return <BrokerLatestStandingBidSubcard standingBid={standingBid} />;
  }

  return (
    <Card
      variant="activity-subcard"
      as="button"
      w="full"
      onClick={() => router.push(`/standing-bids/${standingBid.id}`)}
    >
      <CardHeader w="full">
        <HStack>
          <ActivityCardBadge title="Standing Bid" variant="bid" />
          <Text textStyle="text-lg" align="left">
            {standingBid.company.name}
          </Text>
        </HStack>
      </CardHeader>
      <CardBody w="full">
        <ActivityCardStandingBidNumShareAndPriceDetails
          standingBid={standingBid}
          textStyle="heading-md"
        />
      </CardBody>
      <CardFooter px={4} py={3}>
        <ActivityCardTimestamp title="Placed" date={standingBid.placedAt} />
      </CardFooter>
    </Card>
  );
};
