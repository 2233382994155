import { withCurrentActor } from "@/components/hoc";
import { useMultipleHoldings } from "@/hooks/featureFlags";
import { getIsUnaccreditedSeller } from "@/utils";

import { AccreditedDashboardPage } from "./AccreditedDashboardPage";
import { DefaultUnaccreditedSellerDashboardPage } from "./UnaccreditedSellerDashboardPage";

const DashboardPage = withCurrentActor(({ actor }) => {
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
  const multipleHoldingsEnabled = useMultipleHoldings();

  if (isUnaccreditedSeller && !multipleHoldingsEnabled) {
    return <DefaultUnaccreditedSellerDashboardPage />;
  }

  if (isUnaccreditedSeller && multipleHoldingsEnabled) {
    // rerouted to /dashboard/[holdingCompanyId] in HoldingProvider
    return null;
  }

  return <AccreditedDashboardPage />;
});

export default DashboardPage;
