import { useTranslation } from "react-i18next";

import { Text, VStack } from "@chakra-ui/react";

import { OnboardingWatchlistForm } from "@/components/onboarding";

const BrokerWatchlistPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VStack mt={7} mb={8} spacing={6}>
        <Text align="center" textStyle="deprecated-heading-lg">
          Watchlist
        </Text>
        <Text align="center" maxW="md" textStyle="deprecated-text-xl">
          {t(`broker_add_to_watchlist`)}
        </Text>
        <Text align="center" maxW="md" textStyle="deprecated-text-xs">
          *You must select at least one company.
        </Text>
      </VStack>
      <OnboardingWatchlistForm />
    </>
  );
};

export default BrokerWatchlistPage;
