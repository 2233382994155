import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import {
  OnboardingContainerV2,
  BrokerResidencePage,
} from "@/components/onboarding-v2";
import { useCurrentActor } from "@/hooks";
import { getIsBroker } from "@/utils";

import { ResidenceForm } from "./ResidenceForm";

const ResidencePage = () => {
  const { t } = useTranslation();
  const actor = useCurrentActor();
  const isBroker = getIsBroker(actor);

  if (isBroker) {
    return <BrokerResidencePage />;
  }

  return (
    <OnboardingContainerV2 metaTitle="Residence">
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`residence`)}*
          </Text>
          <Text color="grey.900">{t(`place_of_residence`)}</Text>
        </VStack>

        <ResidenceForm />
      </Flex>
    </OnboardingContainerV2>
  );
};

export default ResidencePage;
