import { Eye, EyeSlash } from "@phosphor-icons/react";
import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";

import {
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";

type PasswordInputProps<TFieldValues extends FieldValues> = InputProps &
  ControllerRenderProps<TFieldValues, Path<TFieldValues>>;

export const PasswordInput = <TFieldValues extends FieldValues>({
  ...textInputProps
}: PasswordInputProps<TFieldValues>) => {
  const { isOpen, onToggle } = useDisclosure();

  const handleClickReveal = () => {
    onToggle();
  };

  return (
    <InputGroup>
      <Input
        autoComplete="new-password"
        type={isOpen ? `text` : `password`}
        pr={12}
        {...textInputProps}
      />
      <InputRightElement
        as={IconButton}
        variant="icon"
        aria-label={isOpen ? `Mask password` : `Reveal password`}
        onClick={handleClickReveal}
        mr={2}
      >
        {isOpen ? (
          <EyeSlash color="#403F41" size={26} />
        ) : (
          <Eye color="#403F41" size={26} />
        )}
      </InputRightElement>
    </InputGroup>
  );
};
