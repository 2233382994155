import { useTranslation } from "react-i18next";

import { ActivityCardPropertyPill } from "@/components/common";
import { StandingBidAcceptsSharesOptions } from "@/gql";
import { acceptsSharesToString } from "@/utils";

export const AcceptableShareTypes = ({
  acceptsShares,
}: {
  readonly acceptsShares: StandingBidAcceptsSharesOptions;
}) => {
  const acceptableShareTypes = acceptsSharesToString(acceptsShares);
  const { t } = useTranslation();

  return (
    <ActivityCardPropertyPill
      title={t`share_type`}
      value={acceptableShareTypes}
    />
  );
};
