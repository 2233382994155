import isNil from "lodash/isNil";
import { ReactNode } from "react";

import { GridItem, SimpleGrid, Text } from "@chakra-ui/react";

import { Tile } from "@/components/common";
import { formatPricePerShare, formatShares } from "@/utils";

import {
  TransferTypeChoices,
  transferTypeChoiceToString,
} from "./TransferTypeChoiceInput";

const SummaryItemHeading = ({ children }: { readonly children: ReactNode }) => (
  <Text textStyle="heading-2xs" lineHeight={6}>
    {children}
  </Text>
);

const ListingSummaryTile = ({
  numShares,
  pricePerShare,
  transferTypeChoice,
  otherDetails,
}: {
  readonly numShares?: number | null;
  readonly pricePerShare?: number | null;
  readonly transferTypeChoice?: TransferTypeChoices;
  readonly otherDetails?: string | null;
}) => (
  <Tile w="full">
    <SimpleGrid gridTemplateColumns="auto 1fr" rowGap={6} columnGap={6}>
      <GridItem>
        <SummaryItemHeading>Number of Shares</SummaryItemHeading>
      </GridItem>
      <GridItem>
        <Text>{!isNil(numShares) ? formatShares(numShares) : `-`}</Text>
      </GridItem>
      <GridItem>
        <SummaryItemHeading>Price per Share</SummaryItemHeading>
      </GridItem>
      <GridItem>
        <Text>
          {!isNil(pricePerShare)
            ? formatPricePerShare(pricePerShare, false)
            : `-`}
        </Text>
      </GridItem>
      <GridItem>
        <SummaryItemHeading>Transfer Type</SummaryItemHeading>
      </GridItem>
      <GridItem>
        <Text>
          {!!transferTypeChoice
            ? transferTypeChoiceToString(transferTypeChoice)
            : `-`}
        </Text>
      </GridItem>
      <GridItem>
        <SummaryItemHeading>Listing Notes</SummaryItemHeading>
      </GridItem>
      <GridItem>
        <Text>{!!otherDetails ? otherDetails : `-`}</Text>
      </GridItem>
    </SimpleGrid>
  </Tile>
);

export default ListingSummaryTile;
