import { useMemo } from "react";

import {
  Table,
  Thead,
  Tr,
  Tbody,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { ThreeDotsVerticalIcon } from "@/components/icons";
import {
  UserRole,
  useInstitutionUsersQuery,
  BasicUserFragment,
  useChangeInstitutionUserActiveStatusMutation,
  ChangeInstitutionUserActiveStatusMutation,
  InstitutionUsersDocument,
} from "@/gql";
import { useCustomToast, useModal, useMutationWithError } from "@/hooks";
import * as datetime from "@/utils/datetime";

import { StyledTd } from "./StyledTd";
import { StyledTh } from "./StyledTh";

const ActionsMenu = ({
  user,
  hasOtherInstitutionAdmins,
}: {
  readonly user: BasicUserFragment;
  readonly hasOtherInstitutionAdmins: boolean;
}) => {
  const { successToast } = useCustomToast();
  const [changeInstitutionUserActiveStatusMutation] = useMutationWithError(
    useChangeInstitutionUserActiveStatusMutation(),
    `changeUserActiveStatus`,
  );

  const { onOpenModal, modals } = useModal();

  const onSuccess = (response: ChangeInstitutionUserActiveStatusMutation) => {
    const isActive = response.changeUserActiveStatus?.user?.isActive;

    const message = isActive
      ? `User reactivated successfully!`
      : `User deactivated successfully!`;

    successToast(message);
  };

  const handleChangeUserActiveStatus = () => {
    const params = {
      variables: {
        userId: user.id,
        active: !user.isActive,
      },
      refetchQueries: [InstitutionUsersDocument],
    };

    changeInstitutionUserActiveStatusMutation(params).then(onSuccess);
  };

  return (
    <Menu>
      <MenuButton>
        <ThreeDotsVerticalIcon />
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={onOpenModal(
            modals.editInstitutionUser(user, hasOtherInstitutionAdmins),
          )}
        >
          Edit roles
        </MenuItem>
        {hasOtherInstitutionAdmins && (
          <MenuItem onClick={() => handleChangeUserActiveStatus()}>
            {user.isActive ? `Deactivate` : `Activate`}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

const UserRow = ({
  user,
  hasOtherInstitutionAdmins,
}: {
  readonly user: BasicUserFragment;
  readonly hasOtherInstitutionAdmins: boolean;
}) => {
  const roleText = useMemo(() => {
    switch (true) {
      case user.roles.includes(UserRole.Admin):
        return `Admin`;
      case user.roles.includes(UserRole.Trader):
        return `Trader`;
      case user.roles.includes(UserRole.Viewer):
        return `Viewer`;
      default:
        throw new Error(`Unhandled roleText case in InvitationRow`);
    }
  }, [user.roles]);

  const signerText = useMemo(() => {
    if (user.roles.includes(UserRole.Signer)) return `Yes`;
    return `No`;
  }, [user.roles]);

  return (
    <Tr borderColor="grey.100">
      <StyledTd>{user.name}</StyledTd>
      <StyledTd>{user.email}</StyledTd>
      <StyledTd>{roleText}</StyledTd>
      <StyledTd>{signerText}</StyledTd>
      <StyledTd>
        {user.isActive ? (
          <Text textStyle="heading-xs" color="teal.900">
            Active
          </Text>
        ) : (
          <Text textStyle="heading-xs" color="salmon.900">
            Inactive
          </Text>
        )}
      </StyledTd>
      <StyledTd>{datetime.format(`MMM. DD, YYYY`, user.insertedAt)}</StyledTd>
      <StyledTd>
        <ActionsMenu
          hasOtherInstitutionAdmins={hasOtherInstitutionAdmins}
          user={user}
        />
      </StyledTd>
    </Tr>
  );
};

export const InstitutionUsersTable = () => {
  const query = useInstitutionUsersQuery();

  const hasOtherInstitutionAdmins = (
    userId: string,
    institutionUsers: readonly BasicUserFragment[],
  ) =>
    institutionUsers.some(
      (user) =>
        user.roles.includes(UserRole.Admin) &&
        user.isActive &&
        user.id !== userId,
    );

  return (
    <WithQuery query={query}>
      {({ data: { institutionUsers } }) => (
        <Box
          borderWidth="0.5px"
          borderColor="grey.200"
          borderRadius="md"
          pt={2}
          bg="white"
          overflowX="auto"
        >
          <Table variant="simple" bg="white">
            <Thead>
              <Tr textTransform="uppercase">
                <StyledTh>Name</StyledTh>
                <StyledTh>Email</StyledTh>
                <StyledTh>Role</StyledTh>
                <StyledTh>Signer</StyledTh>
                <StyledTh>Status</StyledTh>
                <StyledTh>Created</StyledTh>
                <StyledTh width={12} />
              </Tr>
            </Thead>
            <Tbody>
              {institutionUsers.map((user: BasicUserFragment) => (
                <UserRow
                  key={user.id}
                  user={user}
                  hasOtherInstitutionAdmins={hasOtherInstitutionAdmins(
                    user.id,
                    institutionUsers,
                  )}
                />
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </WithQuery>
  );
};
