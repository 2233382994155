/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikErrors } from "formik";

import { BoxProps } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";

import { FormFooterWithConfirmation } from "./FormFooterWithConfirmation";
import FormFooterWrapper from "./FormFooterWrapper";

export type ConfirmationProps = {
  readonly onShowConfirm: () => void;
  readonly validateForm: () => Promise<FormikErrors<any>>;
  readonly setErrors: (errors: FormikErrors<any>) => void;
  readonly setFieldTouched: (field: string) => void;
};

interface FormFooterProps extends BoxProps {
  readonly useCustomFooter?: boolean;
  readonly submitText?: string;
  readonly cancelText?: string;
  readonly isSubmitting?: boolean;
  readonly onCancel?: () => void;
  readonly children?: JSX.Element | readonly JSX.Element[];
  readonly disableSubmit?: boolean;
  readonly confirmationProps?: ConfirmationProps;
  readonly hideSubmit?: boolean;
  readonly formName: string;
}

const FormFooter = ({
  children,
  useCustomFooter = false,
  submitText = `Submit`,
  cancelText = `Cancel`,
  isSubmitting = false,
  onCancel,
  disableSubmit,
  confirmationProps,
  hideSubmit,
  formName,
  ...boxProps
}: FormFooterProps) => {
  if (useCustomFooter) {
    return <FormFooterWrapper {...boxProps}>{children}</FormFooterWrapper>;
  }
  if (!!confirmationProps) {
    return (
      <FormFooterWithConfirmation
        hideSubmit={hideSubmit}
        submitText={submitText}
        cancelText={cancelText}
        onCancel={onCancel}
        disableSubmit={disableSubmit}
        confirmationProps={confirmationProps}
        formName={formName}
        {...boxProps}
      />
    );
  }
  return (
    <FormFooterWrapper {...boxProps}>
      {onCancel && (
        <HiiveButton
          onClick={onCancel}
          size="md"
          variant="rounded-outline-grey"
          sentryLabel={`[${formName}/FormFooter] Cancel`}
        >
          {cancelText}
        </HiiveButton>
      )}
      {!hideSubmit && (
        <HiiveButton
          type="submit"
          isLoading={isSubmitting}
          size="md"
          variant="rounded-solid-salmon"
          isDisabled={disableSubmit}
          sentryLabel={`[${formName}/FormFooter] Submit`}
        >
          {submitText}
        </HiiveButton>
      )}
    </FormFooterWrapper>
  );
};

export default FormFooter;
