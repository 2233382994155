import { useField } from "formik";
import { FocusEvent, ReactNode } from "react";
import ReactTextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import { match, P } from "ts-pattern";

import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";

import styles from "./AutosizeTextAreaInput.module.css";
import TooltipFormLabel from "./TooltipFormLabel";

interface AutosizeTextAreaInputProps extends TextareaAutosizeProps {
  readonly name: string;
  readonly label?: string;
  readonly helperText?: string;
  readonly tooltipContent?: ReactNode;
  readonly tooltipSize?: string;
  readonly maxLength?: number;
}

const AutosizeTextAreaInput = ({
  name,
  label,
  helperText,
  tooltipContent,
  tooltipSize,
  maxLength,
  ...rest
}: AutosizeTextAreaInputProps) => {
  const [field, { touched, error }, { setValue }] = useField(name);

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) =>
    setValue(
      match(event?.target?.value)
        .with(``, (_) => null)
        .with(P._, (x) => x)
        .exhaustive(),
    );

  const value = match(field.value)
    .with(null, (_) => ``)
    .with(P._, (x) => x)
    .exhaustive();

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      {label && (
        <TooltipFormLabel
          htmlFor={name}
          tooltipContent={tooltipContent}
          tooltipSize={tooltipSize}
        >
          {label}
        </TooltipFormLabel>
      )}
      <ReactTextareaAutosize
        {...field}
        className={styles.textArea}
        rows={5}
        minRows={5}
        maxRows={5}
        maxLength={maxLength}
        onBlur={handleBlur}
        value={value}
        {...rest}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {!!maxLength && value.length >= maxLength * 0.9 && (
        <Text textAlign="right" w="full" color="red.500">
          {!value ? `0` : value.length}/{maxLength}
        </Text>
      )}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default AutosizeTextAreaInput;
