import { useTranslation } from "react-i18next";

import { Flex, Card, CardBody, Text, VStack } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { StandingBidTransactions } from "@/components/postings";
import {
  StandingBidBrokerActivityStandingBidFragment,
  StandingBidState,
} from "@/gql";
import { constants } from "@/utils";

const InReviewStandingBidSecuritySpecialistCard = ({
  standingBid,
}: {
  readonly standingBid: StandingBidBrokerActivityStandingBidFragment;
}) => {
  const { t } = useTranslation();
  const contactEmail =
    standingBid.state !== StandingBidState.InReview && standingBid.buyerEmail
      ? standingBid.buyerEmail
      : constants.email_security_specialist;

  const companyName = standingBid.company.name;
  return (
    <Card>
      <CardBody>
        <VStack spacing={4} alignItems="flex-start">
          <Text>
            {t(`broker_in_review_standing_bid_security_specialist_card`, {
              companyName,
            })}
          </Text>
          <MailtoLink
            email={contactEmail}
            subject={`Broker interest in ${companyName}`}
            fontWeight="medium"
          >
            {contactEmail}
          </MailtoLink>
        </VStack>
      </CardBody>
    </Card>
  );
};

const LiveStandingBidSecuritySpecialistCard = ({
  standingBid,
}: {
  readonly standingBid: StandingBidBrokerActivityStandingBidFragment;
}) => {
  const { t } = useTranslation();

  const contactEmail = standingBid.buyerEmail;

  if (!contactEmail) return null;

  const companyName = standingBid.company.name;

  return (
    <Card>
      <CardBody>
        <VStack spacing={4} alignItems="flex-start">
          <Text>{t(`broker_live_standing_bid_security_specialist_card`)}</Text>
          <MailtoLink
            email={contactEmail}
            subject={`Broker questions on ${companyName}`}
            fontWeight="medium"
          >
            {contactEmail}
          </MailtoLink>
        </VStack>
      </CardBody>
    </Card>
  );
};

const StandingBidBrokerActivity = ({
  standingBid,
}: {
  readonly standingBid: StandingBidBrokerActivityStandingBidFragment;
}) => {
  const { state } = standingBid;

  return (
    <Flex w="full" direction="column" gap={8}>
      {state === StandingBidState.InReview ? (
        <InReviewStandingBidSecuritySpecialistCard standingBid={standingBid} />
      ) : (
        <LiveStandingBidSecuritySpecialistCard standingBid={standingBid} />
      )}
      <StandingBidTransactions standingBid={standingBid} />
    </Flex>
  );
};

export default StandingBidBrokerActivity;
