import { Box, VStack } from "@chakra-ui/react";

const BrokerStandingBidOtherDetailsTooltip = () => (
  <VStack alignItems="start">
    <Box>
      Use this space to provide any additional information on the buyer&apos;s
      bid, such as:
      <ul style={{ paddingLeft: `1.2em` }}>
        <li>
          If the buyer has any diligence requirements or information requests
        </li>
        <li>If the offer is firm and fully funded</li>
        <li>
          If the buyer would consider a different type of stock at a different
          price
        </li>
      </ul>
    </Box>
  </VStack>
);

export default BrokerStandingBidOtherDetailsTooltip;
