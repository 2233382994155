import { useMemo } from "react";

import { FormLabelProps } from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { BasicCountryFragment, useCountriesQuery } from "@/gql";
import { useCountryList, useMemoizedCountries } from "@/hooks";

import { FormCombobox } from "./Select";

const FormCountriesComboboxContent = ({
  countries,
  disabled,
  name,
  placeholder,
  label,
  labelSrOnly,
  showSearchIcon,
}: {
  readonly countries: readonly BasicCountryFragment[];
  readonly disabled?: boolean;
  readonly placeholder: string;
  readonly name: string;
  readonly label: string;
  readonly labelSrOnly: FormLabelProps[`srOnly`];
  readonly showSearchIcon?: boolean;
}) => {
  const countryList = useCountryList();

  const countriesMap: Record<string, string> = useMemoizedCountries({
    countries,
    countryList,
  });

  const countryIds = useMemo(() => Object.keys(countriesMap), [countriesMap]);

  const filterItems = ({ search }: { readonly search: string }) =>
    countryIds.filter((id) =>
      countriesMap[id]
        .replace(` `, ``)
        .toLowerCase()
        .startsWith(search.replace(` `, ``).toLowerCase()),
    );

  return (
    <FormCombobox
      isDisabled={disabled}
      getItems={filterItems}
      itemToString={(item) => countriesMap[item]}
      getItemKey={(item) => item}
      name={name}
      placeholder={placeholder}
      isLoading={false}
      label={label}
      labelSrOnly={labelSrOnly}
      showSearchIcon={showSearchIcon}
    />
  );
};

const FormCountriesCombobox = ({
  disabled,
  placeholder = `Select country`,
  name,
  label,
  labelSrOnly,
  showSearchIcon,
}: {
  readonly disabled?: boolean;
  readonly name: string;
  readonly label: string;
  readonly placeholder?: string;
  readonly labelSrOnly?: FormLabelProps[`srOnly`];
  readonly showSearchIcon?: boolean;
}) => {
  const query = useCountriesQuery({
    variables: {
      sorted: true,
    },
  });

  return (
    <WithQuery query={query}>
      {({ data: { countries } }) => (
        <FormCountriesComboboxContent
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          countries={countries}
          label={label}
          labelSrOnly={labelSrOnly}
          showSearchIcon={showSearchIcon}
        />
      )}
    </WithQuery>
  );
};

export default FormCountriesCombobox;
