import { Card, CardBody, CardHeader, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

export const DashboardNotificationsSkeleton = () => (
  <Card w="full">
    <CardHeader>
      <Skeleton h={4} maxW="20%" />
    </CardHeader>
    <CardBody maxH="xs" overflowY="auto">
      <VStack spacing={4}>
        <Skeleton h={20} count={3} />
      </VStack>
    </CardBody>
  </Card>
);
