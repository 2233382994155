import { useMemo } from "react";

import {
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardFooterWrapper,
  ActivityCardMostRecentMessage,
} from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  MyDiscussionListDiscussionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  getDiscussionHasCounterpartyMessage,
  getDiscussionStatusText,
} from "@/utils";

export const YourDiscussionCard = withCurrentActor(
  ({
    discussion,
    actor,
  }: {
    readonly discussion: MyDiscussionListDiscussionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const hasCounterpartyMessage = getDiscussionHasCounterpartyMessage(
      actor,
      discussion,
    );
    const discussionStatus = useMemo(
      () => getDiscussionStatusText(discussion),
      [discussion.state],
    );

    const hasMessages = discussion.totalNumMessages > 0;

    return (
      <ActivityCard href={`/discussions/${discussion.id}`}>
        <CardHeader>
          <HStack justifyContent="space-between">
            <ActivityCardBadge variant="discussion" title="Inquiry" />
            <Text textStyle="text-lg">{discussion.displayId}</Text>
          </HStack>
        </CardHeader>
        {hasCounterpartyMessage && !!discussion.mostRecentMessage && (
          <CardBody>
            <ActivityCardMostRecentMessage discussion={discussion} />
          </CardBody>
        )}
        <CardFooter>
          <ActivityCardFooterWrapper>
            <Text textStyle="text-sm">{discussionStatus}</Text>
            {hasMessages && (
              <MessagesBadge
                numUnreadMessages={discussion.numUnreadMessages}
                totalNumMessages={discussion.totalNumMessages}
              />
            )}
          </ActivityCardFooterWrapper>
        </CardFooter>
      </ActivityCard>
    );
  },
);
