import { VStack } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { BidActivityBidFragment, UserWithInstitutionFragment } from "@/gql";
import { getIsBroker } from "@/utils";
import { getIsBuyerForBid } from "@/utils/bid";

import { BrokerActivity } from "./BrokerActivity";
import BuyerActivity from "./BuyerActivity";
import { SellerActivity } from "./SellerActivity";
import TransactionModification from "./TransactionModification";

export const BidActivity = withCurrentActor(
  ({
    bid,
    actor,
  }: {
    readonly bid: BidActivityBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const hasTransactionBeenModified = !!bid?.transaction?.pendingModification;

    const renderPersonaActivity = () => {
      if (getIsBroker(actor)) {
        return <BrokerActivity bid={bid} />;
      }
      return getIsBuyerForBid(actor, bid) ? (
        <BuyerActivity bid={bid} actor={actor} />
      ) : (
        <SellerActivity bid={bid} actor={actor} />
      );
    };

    return (
      <VStack gap={2}>
        {hasTransactionBeenModified && (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <TransactionModification transaction={bid.transaction!} />
        )}
        {renderPersonaActivity()}
      </VStack>
    );
  },
);
