import { useState } from "react";

import { Text } from "@chakra-ui/react";

import { withGuestRoute } from "@/components/hoc";
import { OnboardingContainer } from "@/components/onboarding";
import { RequestResetPasswordMutation } from "@/gql";
import { useCustomToast } from "@/hooks";
import { ToastDuration } from "@/types/toast";

import ForgotPasswordForm from "./ForgotPasswordForm";

const RequestSentMessage = () => (
  <Text my={7} align="center" textStyle="deprecated-heading-lg">
    Check your inbox and click the link to set your new password.
  </Text>
);

const ForgotPasswordPage = () => {
  const { successToast } = useCustomToast();
  const [requestSent, setRequestSent] = useState<boolean>(false);

  const onSuccess = (data: RequestResetPasswordMutation) => {
    successToast(data.requestResetPassword, {
      duration: ToastDuration.Long,
    });
    setRequestSent(true);
  };

  return (
    <OnboardingContainer heading="Forgotten Password">
      {requestSent ? (
        <RequestSentMessage />
      ) : (
        <>
          <Text
            my={7}
            align="center"
            textStyle="deprecated-text-xl"
            color="h-dark-grey"
          >
            Enter your email and we’ll send you a link to reset your password.
          </Text>
          <ForgotPasswordForm onSuccess={onSuccess} />
        </>
      )}
    </OnboardingContainer>
  );
};

export default withGuestRoute(ForgotPasswordPage);
