import * as React from "react";
import { SVGProps } from "react";

const UnaccreditedSellerListForSaleSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 334 375"
    fill="none"
    {...props}
  >
    <mask
      id="u16r_bg_svg__a"
      width={334}
      height={375}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: `alpha`,
      }}
    >
      <path fill="#D9D9D9" d="M0 0h334v375H0z" />
    </mask>
    <g mask="url(#u16r_bg_svg__a)">
      <rect
        width={488.679}
        height={532.553}
        x={-28}
        y={98.063}
        fill="#F9F9F9"
        opacity={0.7}
        rx={110}
        transform="rotate(-38.478 -28 98.063)"
      />
    </g>
    <mask
      id="u16r_bg_svg__b"
      width={284}
      height={261}
      x={50}
      y={83}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: `alpha`,
      }}
    >
      <path fill="#D9D9D9" d="M50 83h284v261H50z" />
    </mask>
    <g mask="url(#u16r_bg_svg__b)">
      <g filter="url(#u16r_bg_svg__c)">
        <rect
          width={289.272}
          height={183.656}
          x={103.098}
          y={97.278}
          fill="#F9F9F9"
          stroke="silver"
          strokeWidth={0.15}
          rx={8.314}
        />
        <path
          fill="#fff"
          stroke="silver"
          strokeWidth={0.15}
          d="M111.389 97.075h272.646a8.314 8.314 0 0 1 8.314 8.314v47.003H103.075v-47.003a8.314 8.314 0 0 1 8.314-8.314Z"
        />
        <g filter="url(#u16r_bg_svg__d)">
          <path
            fill="#fff"
            d="M234.088 199.233a4.048 4.048 0 0 1-4.048 4.048H126.605a4.048 4.048 0 0 1-4.048-4.048v-69.116a4.857 4.857 0 0 1 4.857-4.858h101.817a4.858 4.858 0 0 1 4.857 4.858v69.116Z"
          />
          <path
            stroke="#D5D5D5"
            strokeWidth={0.3}
            d="M230.04 203.131H126.605a3.898 3.898 0 0 1-3.898-3.898v-69.116c0-2.6 2.107-4.708 4.707-4.708h101.817a4.708 4.708 0 0 1 4.707 4.708v69.116a3.898 3.898 0 0 1-3.898 3.898Z"
          />
        </g>
        <g filter="url(#u16r_bg_svg__e)">
          <path
            fill="#fff"
            d="M234.088 237.296a4.048 4.048 0 0 1-4.048 4.048H126.605a4.048 4.048 0 0 1-4.047-4.048v-22.677a4.857 4.857 0 0 1 4.857-4.857h101.816a4.858 4.858 0 0 1 4.857 4.857v22.677Z"
          />
          <path
            stroke="#D5D5D5"
            strokeWidth={0.3}
            d="M230.04 241.194H126.605a3.898 3.898 0 0 1-3.898-3.898v-22.677a4.708 4.708 0 0 1 4.708-4.707h101.816a4.708 4.708 0 0 1 4.707 4.707v22.677a3.898 3.898 0 0 1-3.898 3.898Z"
          />
        </g>
        <rect
          width={91.084}
          height={6.502}
          x={130.932}
          y={149.367}
          fill="#F6F6F6"
          rx={3.251}
        />
        <rect
          width={91.084}
          height={6.502}
          x={130.932}
          y={136.367}
          fill="#F6F6F6"
          rx={3.251}
        />
        <rect
          width={91.084}
          height={6.502}
          x={130.932}
          y={162.406}
          fill="#F6F6F6"
          rx={3.251}
        />
        <rect
          width={91.084}
          height={6.502}
          x={130.932}
          y={175.383}
          fill="#F6F6F6"
          rx={3.251}
        />
      </g>
      <path
        fill="#F2F2EC"
        stroke="#E4E4D9"
        strokeWidth={0.3}
        d="M369.641 165.983h-127.96V130.12c0-2.6 2.107-4.708 4.707-4.708h118.546c2.6 0 4.707 2.108 4.707 4.708v35.863Z"
      />
      <path
        fill="#fff"
        stroke="#D5D5D5"
        strokeWidth={0.3}
        d="M241.677 164.423h127.962v9.857c0 2.6-2.108 4.708-4.707 4.708H246.385a4.708 4.708 0 0 1-4.708-4.708v-9.857Z"
      />
      <g filter="url(#u16r_bg_svg__f)">
        <path
          fill="#fff"
          d="M362.357 153.679a4.048 4.048 0 0 1-4.047 4.048H253.946a4.048 4.048 0 0 1-4.048-4.048v-15.245a4.857 4.857 0 0 1 4.857-4.857H357.5a4.857 4.857 0 0 1 4.857 4.857v15.245Z"
        />
        <path
          stroke="#E4E4D9"
          strokeWidth={0.3}
          d="M358.31 157.577H253.946a3.898 3.898 0 0 1-3.898-3.898v-15.245c0-2.6 2.107-4.707 4.707-4.707H357.5c2.6 0 4.708 2.107 4.708 4.707v15.245a3.898 3.898 0 0 1-3.898 3.898Z"
        />
      </g>
      <g filter="url(#u16r_bg_svg__g)">
        <rect
          width={127.962}
          height={10.848}
          x={241.691}
          y={208.158}
          fill="#F5ECF4"
          stroke="#EBD8EA"
          strokeWidth={0.3}
          rx={5.424}
        />
        <rect
          width={128.261}
          height={11.148}
          x={241.541}
          y={190.359}
          fill="#D5D5D5"
          rx={5.574}
        />
        <rect
          width={127.962}
          height={10.848}
          x={241.691}
          y={224.869}
          fill="#F5ECF4"
          stroke="#EBD8EA"
          strokeWidth={0.3}
          rx={5.424}
        />
        <rect
          width={127.962}
          height={10.848}
          x={241.691}
          y={241.564}
          fill="#F5ECF4"
          stroke="#EBD8EA"
          strokeWidth={0.3}
          rx={5.424}
        />
      </g>
      <path
        fill="#F6F6F6"
        stroke="#D5D5D5"
        strokeWidth={0.405}
        d="M119.565 110.087a3.05 3.05 0 1 1-6.102-.002 3.05 3.05 0 0 1 6.102.002ZM128.872 110.087a3.05 3.05 0 1 1-6.1-.002 3.05 3.05 0 0 1 6.1.002ZM138.147 110.087a3.05 3.05 0 1 1-6.102-.002 3.05 3.05 0 0 1 6.102.002Z"
      />
      <g filter="url(#u16r_bg_svg__h)">
        <path
          fill="#F8F8F4"
          d="M56 185.313c0-.725.588-1.313 1.313-1.313h189.131c.725 0 1.313.588 1.313 1.313v51.008a6.567 6.567 0 0 1-6.567 6.567H62.567A6.567 6.567 0 0 1 56 236.321v-51.008Z"
          shapeRendering="crispEdges"
        />
        <path
          fill="#878552"
          d="M63.135 193.133v-3.678h.736v3.068h1.691v.61h-2.427Zm3.01 0v-2.732h.715v2.732h-.714Zm-.057-3.447c0-.236.174-.415.415-.415.242 0 .415.179.415.415a.403.403 0 0 1-.415.415.403.403 0 0 1-.415-.415Zm2.555 3.51c-.693 0-1.177-.289-1.177-.872v-.048h.631v.037c0 .237.215.378.552.378.3 0 .52-.12.52-.331 0-.131-.069-.21-.226-.246l-.81-.184c-.409-.095-.624-.305-.624-.72 0-.562.457-.872 1.103-.872.657 0 1.082.331 1.082.872v.042h-.625v-.047c0-.211-.131-.358-.447-.358-.289 0-.446.105-.446.3 0 .136.068.199.21.231l.83.205c.405.1.61.315.61.699 0 .53-.505.914-1.183.914Zm2.63-.063c-.525 0-.772-.294-.772-.778v-1.376h-.39v-.578h.39v-.746h.72v.746h.582v.578h-.583v1.324c0 .178.069.252.258.252h.289v.578h-.494Zm1.081 0v-2.732h.715v2.732h-.715Zm-.058-3.447c0-.236.174-.415.415-.415.242 0 .415.179.415.415a.403.403 0 0 1-.415.415.403.403 0 0 1-.415-.415Zm1.494 3.447v-2.732h.683v.326c.137-.253.415-.389.804-.389.594 0 .967.389.967 1.045v1.75h-.715v-1.634c0-.315-.152-.541-.499-.541-.32 0-.525.189-.525.557v1.618h-.715Zm4.323.977c-.641 0-1.172-.299-1.282-.814h.693c.095.189.31.273.589.273.362 0 .593-.2.593-.504v-.431c-.115.189-.43.373-.782.373-.736 0-1.125-.536-1.125-1.135v-.399c0-.61.384-1.135 1.13-1.135.42 0 .714.21.809.394v-.331h.683v2.674c0 .583-.547 1.035-1.308 1.035Zm-.021-1.676c.336 0 .614-.215.614-.599v-.315c0-.368-.252-.61-.593-.61-.4 0-.605.237-.605.61v.315c0 .347.195.599.584.599Z"
        />
        <g filter="url(#u16r_bg_svg__i)">
          <rect
            width={172.822}
            height={26.379}
            x={64.375}
            y={200.313}
            fill="#fff"
            stroke="#53532D"
            strokeWidth={0.109}
            rx={1.259}
          />
          <rect
            width={34.297}
            height={5.322}
            x={82.269}
            y={214.016}
            fill="#D5D5D5"
            rx={0.499}
          />
          <rect
            width={16.325}
            height={2.303}
            x={91.744}
            y={207.484}
            fill="#969696"
            rx={1.152}
          />
          <rect
            width={16.325}
            height={2.303}
            x={188.926}
            y={207.484}
            fill="#969696"
            rx={1.152}
          />
          <rect
            width={33.705}
            height={5.322}
            x={131.941}
            y={214.016}
            fill="#D5D5D5"
            rx={0.499}
          />
          <rect
            width={34.297}
            height={5.322}
            x={178.662}
            y={214.016}
            fill="#D5D5D5"
            rx={0.499}
          />
        </g>
        <path
          stroke="#BCBB9F"
          strokeWidth={0.109}
          d="M57.313 184.055h189.131c.695 0 1.258.563 1.258 1.258v51.008a6.512 6.512 0 0 1-6.512 6.512H62.567a6.513 6.513 0 0 1-6.512-6.512v-51.008c0-.695.563-1.258 1.258-1.258Z"
          shapeRendering="crispEdges"
        />
      </g>
    </g>
    <defs>
      <filter
        id="u16r_bg_svg__c"
        width={309.749}
        height={204.312}
        x={92.848}
        y={90.54}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.923}
          result="effect1_dropShadow_7403_110582"
        />
        <feOffset dy={3.692} />
        <feGaussianBlur stdDeviation={4.615} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7403_110582"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7403_110582"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_svg__d"
        width={129.341}
        height={95.834}
        x={113.652}
        y={119.591}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.81}
          result="effect1_dropShadow_7403_110582"
        />
        <feOffset dy={3.238} />
        <feGaussianBlur stdDeviation={4.048} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7403_110582"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7403_110582"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_svg__e"
        width={129.339}
        height={49.388}
        x={113.654}
        y={204.099}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.81}
          result="effect1_dropShadow_7403_110582"
        />
        <feOffset dy={3.238} />
        <feGaussianBlur stdDeviation={4.048} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7403_110582"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7403_110582"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_svg__f"
        width={130.269}
        height={41.959}
        x={240.993}
        y={127.911}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.81}
          result="effect1_dropShadow_7403_110582"
        />
        <feOffset dy={3.238} />
        <feGaussianBlur stdDeviation={4.048} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7403_110582"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7403_110582"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_svg__g"
        width={146.072}
        height={80.013}
        x={232.636}
        y={184.693}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.81}
          result="effect1_dropShadow_7403_110582"
        />
        <feOffset dy={3.238} />
        <feGaussianBlur stdDeviation={4.048} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7403_110582"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7403_110582"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_svg__h"
        width={196.574}
        height={63.707}
        x={53.592}
        y={182.468}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.219}
          result="effect1_dropShadow_7403_110582"
        />
        <feOffset dy={0.876} />
        <feGaussianBlur stdDeviation={1.095} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7403_110582"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7403_110582"
          result="shape"
        />
      </filter>
      <filter
        id="u16r_bg_svg__i"
        width={177.748}
        height={31.308}
        x={61.912}
        y={198.725}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={0.219}
          result="effect1_dropShadow_7403_110582"
        />
        <feOffset dy={0.876} />
        <feGaussianBlur stdDeviation={1.095} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7403_110582"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_7403_110582"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default UnaccreditedSellerListForSaleSvg;
