import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Center, Flex } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { SlideAnimation } from "@/components/onboarding-v2";
import { FormCountryCombobox } from "@/components/react-hook-form";
import {
  CurrentContextDocument,
  useTransitionCurrentStepMutation,
  useUpdateUserMutation,
} from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

interface ResidenceFormValues {
  readonly countryId: string | null;
}

const mapVariables = ({ countryId }: ResidenceFormValues) => ({
  input: { countryId },
});

export const ResidenceForm = () => {
  const actor = useCurrentActor();
  const mutation = useUpdateUserMutation({
    refetchQueries: [CurrentContextDocument],
  });
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    countryId: Yup.string().required(t(`required`)),
  });

  const initialValues = { countryId: actor.country?.id || null };

  const [
    transitionCurrentStepMutation,
    isTransitioningCurrentStep,
  ] = useMutationWithError(
    useTransitionCurrentStepMutation(),
    `transitionCurrentStep`,
  );

  const onSuccess = () =>
    transitionCurrentStepMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { onboardingId: actor.onboardingV2?.id! },
    });

  const { control, handleSubmit, isLoading } = useFormQL({
    initialValues,
    mapVariables,
    mutation,
    onSuccess,
    validationSchema,
  });

  const { formState } = useController({
    name: `countryId`,
    control,
  });

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Flex direction="column" gap={8}>
        <SlideAnimation>
          <Center
            bgColor="white"
            borderRadius="md"
            borderColor="grey.200"
            borderWidth={0.5}
            boxShadow="card"
            p={6}
          >
            <FormCountryCombobox
              control={control}
              disabled={isLoading}
              label={t(`search_country`)}
              name="countryId"
              placeholder={t(`search_country`)}
              showSearchIcon
              labelSrOnly
            />
          </Center>
        </SlideAnimation>
        <Flex w="full" justifyContent="flex-end">
          <HiiveButton
            isDisabled={!formState.isValid}
            isLoading={isLoading || isTransitioningCurrentStep}
            sentryLabel="[ResidencePage] Next"
            size="xl"
            type="submit"
            variant="rounded-solid-salmon"
          >
            {t(`next`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </form>
  );
};
