import { GridItem, Text } from "@chakra-ui/react";

import { useModifyListingNumSharesEnabled } from "@/hooks/featureFlags";

const TransactionSizeError = ({ error }: { readonly error: string }) => {
  const isModifyListingNumSharesFlagEnabled = useModifyListingNumSharesEnabled();

  if (!isModifyListingNumSharesFlagEnabled) {
    return null;
  }

  return (
    <GridItem colSpan={2}>
      <Text textStyle="text-sm" color="red.500">
        {error}
      </Text>
    </GridItem>
  );
};

export default TransactionSizeError;
