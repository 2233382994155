/* eslint-disable functional/immutable-data */
import { FocusEventHandler } from "react";

import { Input as ChakraInput, InputProps, forwardRef } from "@chakra-ui/react";

import { UseComboboxGetInputProps } from "./types";

interface ComboboxInputProps extends InputProps {
  readonly placeholder?: string;
  readonly isDisabled?: boolean;
  readonly onBlur?: FocusEventHandler<HTMLInputElement>;
  readonly getInputProps: UseComboboxGetInputProps;
}

export const ComboboxInput = forwardRef<ComboboxInputProps, `input`>(
  (
    {
      getInputProps,
      placeholder,
      isDisabled,
      onBlur,
      ...rest
    }: ComboboxInputProps,
    ref,
  ) => (
    <ChakraInput
      {...rest}
      placeholder={placeholder}
      isDisabled={isDisabled}
      {...getInputProps({ ref, onBlur })}
    />
  ),
);

ComboboxInput.displayName = `Input`;
ComboboxInput.id = `Input`;
