export const calculateTotalOpenBids = (
  numActiveBids: number,
  numCounteredBids: number,
) => numActiveBids + numCounteredBids;

export const findHighestAcceptedBidSharePrice = (
  acceptedBidPrices: readonly number[],
) => {
  if (acceptedBidPrices.length === 0)
    throw new Error(
      `Developer message: Don't use fn "findHighestAcceptedBidSharePrice" on a record with no accepted bid prices`,
    );

  return Math.max(...acceptedBidPrices);
};

export const findLowestAcceptedBidSharePrice = (
  acceptedBidPrices: readonly number[],
) => {
  if (acceptedBidPrices.length === 0)
    throw new Error(
      `Developer message: Don't use fn "findLowestAcceptedBidSharePrice" on a record with no accepted bid prices`,
    );

  return acceptedBidPrices.reduce(
    (low, current) => (current < low ? current : low),
    Infinity,
  );
};

export const hasMultipleAcceptedBidSharePrices = (
  acceptedBidPrices: readonly number[],
) => {
  if (acceptedBidPrices.length <= 1) return false;

  return acceptedBidPrices.some((price) => price !== acceptedBidPrices[0]);
};

export const hasSingleAcceptedBidSharePrice = (
  acceptedBidPrices: readonly number[],
) => {
  if (acceptedBidPrices.length === 0) return false;

  if (acceptedBidPrices.length === 1) return true;

  return acceptedBidPrices.every((price) => price === acceptedBidPrices[0]);
};
