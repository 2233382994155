import { Card, CardBody, GridItem, VStack, Show, Hide } from "@chakra-ui/react";

import { ButtonSkeleton, Skeleton } from "@/components/common";

const CardSkeleton = () => (
  <Card>
    <CardBody>
      <VStack
        w="full"
        h="full"
        spacing={8}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <VStack w="full" alignItems="flex-start" spacing={3}>
          <Skeleton h="24px" maxW="276px" my={2} />
          <Show above="md" ssr={false}>
            <Skeleton h="14px" w="60%" />
            <Skeleton h="14px" w="55%" />
            <Skeleton h="14px" w="60%" />
            <Skeleton h="14px" w="50%" />
            <Skeleton h="14px" w="55%" />
            <Skeleton h="14px" w="50%" />
            <Skeleton h="14px" w="20%" />
          </Show>
          <Hide above="md" ssr={false}>
            <Skeleton h="14px" count={4} />
            <Skeleton h="14px" w="45%" />
          </Hide>
        </VStack>
        <ButtonSkeleton maxW="236px" />
      </VStack>
    </CardBody>
  </Card>
);

export const ListSharesSellNowBlockSkeleton = () => (
  <>
    <GridItem colSpan={{ base: 2, md: 1 }}>
      <CardSkeleton />
    </GridItem>
    <GridItem colSpan={{ base: 2, md: 1 }}>
      <CardSkeleton />
    </GridItem>
  </>
);
