import capitalize from "lodash/capitalize";

import { Tile, TileHeader } from "@/components/common";
import { CompanyPageSuperadminDetailsTileCompanyFragment } from "@/gql";

export const SuperadminDetailsTile = ({
  company,
}: {
  readonly company: CompanyPageSuperadminDetailsTileCompanyFragment;
}) => (
  <Tile
    w="full"
    py={2}
    gap={4}
    display="grid"
    gridTemplateColumns="max-content 1fr"
    alignItems="baseline"
  >
    <TileHeader>Listed Status</TileHeader>
    {capitalize(company.status)}
    <TileHeader>Tradeable</TileHeader>
    {company.tradeable ? `Yes` : `No`}
  </Tile>
);
