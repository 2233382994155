import { Flex, HStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

const CompanyPriceGraphSkeleton = () => (
  <Flex
    gap={7}
    direction="column"
    p={8}
    borderWidth={1}
    borderColor="grey.100"
    borderRadius="md"
  >
    <Flex gap={6} justify="end">
      <HStack maxW="50%" w="full">
        {[...Array(3).keys()].map((index) => (
          <Skeleton key={index} h="30px" borderRadius="full" />
        ))}
      </HStack>
    </Flex>
    <Skeleton h={{ base: 32, md: 48 }} />
    <Flex gap={6} justify="end">
      <HStack w="full" maxW="50%">
        {[...Array(2).keys()].map((index) => (
          <Skeleton key={index} h="20px" />
        ))}
      </HStack>
    </Flex>
  </Flex>
);

export default CompanyPriceGraphSkeleton;
