import { FormLabel, Text, Textarea, VStack } from "@chakra-ui/react";

import { AccreditationTextAreaInputProps } from "./types";

const AccreditationTextAreaInput = ({
  value,
  onChange,
  placeholder,
  maxLength,
  label,
}: AccreditationTextAreaInputProps) => (
  <VStack spacing={2.5} w="full" align="start">
    {!!label && <FormLabel m={0}>{label}</FormLabel>}
    <Textarea
      bg="h-white"
      whiteSpace="pre-line"
      borderRadius="md"
      color="grey.900"
      placeholder={placeholder ?? undefined}
      _placeholder={{
        color: `grey.600`,
      }}
      borderColor="grey.600"
      resize="none"
      h="170px"
      maxLength={!!maxLength ? maxLength : undefined}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    />
    {!!maxLength && (
      <Text textAlign="right" w="full">
        {!value ? `0` : value.length}/{maxLength}
      </Text>
    )}
  </VStack>
);

export default AccreditationTextAreaInput;
