import { useTranslation } from "react-i18next";

import { Flex, Text } from "@chakra-ui/react";

import { FocusedShareDetails } from "@/components/common";
import {
  BidInfoCardStatusTile,
  BidActions,
  BidInfoCardCounterBidShareDetails,
  BuyerBidInfoCard,
} from "@/components/postings";
import { BuyerCounterBidInfoCardFragment } from "@/gql";

const BuyerCounterBidInfoCard = ({
  bid,
}: {
  readonly bid: BuyerCounterBidInfoCardFragment;
}) => {
  const { t } = useTranslation();

  const placedAt = bid.counteredAt || bid.expireAt;
  return (
    <Flex direction="column" gap={7}>
      <Flex direction="column" gap={7} data-testid="bid-info-card">
        <Text textStyle="heading-sm">{t`bid_info_buyer_counter_bid`}</Text>
        <FocusedShareDetails.Card variant="bid">
          <FocusedShareDetails.Header title={`Bid ${bid.displayId}`}>
            <BidInfoCardCounterBidShareDetails bid={bid} />
          </FocusedShareDetails.Header>
          <FocusedShareDetails.Content>
            <FocusedShareDetails.ContentSection>
              <Flex direction="column" gap={6}>
                {placedAt && (
                  <BidInfoCardStatusTile bid={bid} showCounterBidDetails />
                )}
              </Flex>
            </FocusedShareDetails.ContentSection>
          </FocusedShareDetails.Content>
        </FocusedShareDetails.Card>
      </Flex>
      <BidActions bid={bid} isBuyer />
      <BuyerBidInfoCard bid={bid} />
    </Flex>
  );
};

export default BuyerCounterBidInfoCard;
