import { Text } from "@chakra-ui/react";

const PlaceStandingBidTooltip = () => (
  <Text>
    A Standing Bid is an independent offer to buy stock at a certain price. It
    may be withdrawn/modified by the bidder but may not be negotiated. It can be
    accepted by a seller at anytime.
  </Text>
);
export default PlaceStandingBidTooltip;
