import { Text } from "@chakra-ui/react";

const RepresentedUserTooltip = () => (
  <Text>
    Add the name and email of the party you are representing. This name and
    email will not be shown on the platform and will only be used for internal
    reporting and operational purposes.
  </Text>
);

export default RepresentedUserTooltip;
