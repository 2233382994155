import { t } from "i18next";
import isNil from "lodash/isNil";

import { Maybe } from "@/gql";
import { formatShares, lot } from "@/utils";

import ShareDetailsPricePerShareStat from "./ShareDetailsPricePerShareStat";
import ShareDetailsStat from "./ShareDetailsStat";
import ShareDetailsStatsWrapper from "./ShareDetailsStatsWrapper";

const ShareDetailsStats = ({
  numberOfShares,
  pricePerShare,
  allowPartialAccept = false,
}: {
  readonly numberOfShares: Maybe<number> | undefined;
  readonly pricePerShare: Maybe<number> | undefined;
  readonly allowPartialAccept?: Maybe<boolean> | undefined;
}) => {
  const numberOfSharesText = numberOfShares
    ? formatShares(numberOfShares)
    : `-`;

  const totalText =
    !isNil(numberOfShares) && !isNil(pricePerShare)
      ? lot(numberOfShares, pricePerShare)
      : `-`;

  return (
    <ShareDetailsStatsWrapper>
      <ShareDetailsStat
        title={t(allowPartialAccept ? `maximum_shares` : `shares`)}
      >
        {numberOfSharesText}
      </ShareDetailsStat>
      <ShareDetailsPricePerShareStat pricePerShare={pricePerShare} />
      <ShareDetailsStat
        title={t(allowPartialAccept ? `maximum_total` : `total`)}
      >
        {totalText}
      </ShareDetailsStat>
    </ShareDetailsStatsWrapper>
  );
};

export default ShareDetailsStats;
