import { useTranslation } from "react-i18next";

import { CardBody, CardFooter, CardHeader, Flex } from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardDisplayId,
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTransactionStatus,
} from "@/components/common";
import { ListingPageBuyerActivityTransactionFragment } from "@/gql";
import { makeUrl } from "@/utils";
import { getBidNumSharesActual } from "@/utils/bid";

export const PendingPurchaseCard = ({
  pendingPurchase,
}: {
  readonly pendingPurchase: ListingPageBuyerActivityTransactionFragment;
}) => {
  const { t } = useTranslation();

  const { displayId } = pendingPurchase.bid;

  const numShares = getBidNumSharesActual(pendingPurchase.bid);
  const price = pendingPurchase.bid.pricePerShare;

  return (
    <ActivityCard href={makeUrl(pendingPurchase)}>
      <CardHeader>
        <Flex alignItems="center" gap={2}>
          <ActivityCardBadge
            variant="transaction"
            title={t(`transaction_on_bid`)}
          />
          <ActivityCardDisplayId displayId={displayId} />
        </Flex>
      </CardHeader>
      <CardBody>
        <ActivityCardPricingWrapper>
          <ActivityCardPricingNumSharesAndPrice
            numShares={numShares}
            price={price}
          />
          <ActivityCardPricingTotal numShares={numShares} price={price} />
        </ActivityCardPricingWrapper>
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            <ActivityCardTransactionStatus
              bid={pendingPurchase.bid}
              company={pendingPurchase.bid.company}
              transaction={pendingPurchase}
            />
          </ActivityCardFooterStatusWrapper>
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};
