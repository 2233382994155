import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  AutomatedLOISellerSteps,
  AutomatedLOIStatesMap,
  AutomatedLOITransactionState,
} from "@/components/postings";
import { AutomatedLoiSellerStatesBidFragment, TransactionState } from "@/gql";
import { useDocumentSigners } from "@/hooks";

import BidAcceptedStatusItem from "./BidAcceptedStatusItem";
import { TransferStatusItem } from "./TransferStatusItem";

const AutomatedLOISellerStates = ({
  state,
  bid,
  isBroker,
}: {
  readonly state: AutomatedLOITransactionState;
  readonly bid: AutomatedLoiSellerStatesBidFragment;
  readonly isBroker?: boolean;
}) => {
  const { t } = useTranslation();

  const states: AutomatedLOIStatesMap<AutomatedLOISellerSteps> = {
    [TransactionState.BidAccepted]: {
      awaitingClosing: null,
      feePayment: null,
      closed: null,
    },
    [TransactionState.Expired]: {
      awaitingClosing: null,
      feePayment: null,
      closed: null,
    },
    [TransactionState.AwaitingClosing]: {
      awaitingClosing: `PENDING`,
      feePayment: null,
      closed: null,
    },
    [TransactionState.ClosedFeePending]: {
      awaitingClosing: `SUCCESS`,
      feePayment: `PENDING`,
      closed: null,
    },
    [TransactionState.ClosedFeePaid]: {
      awaitingClosing: `SUCCESS`,
      feePayment: `SUCCESS`,
      closed: `SUCCESS`,
    },
  };

  const bidState = useDocumentSigners(bid.transaction);

  const [loiSignedSubtitle, transactionCompletedSubtitle] = useMemo(
    () => [
      bidState.haveBothSigned && states[state].awaitingClosing === `PENDING`
        ? `Awaiting closing`
        : undefined,
      states[state].awaitingClosing === `SUCCESS` &&
      states[state].feePayment === `PENDING`
        ? t(`awaiting_closing_fee`)
        : undefined,
    ],
    [states, state, bidState],
  );

  return (
    <>
      {!!bid.transaction && (
        <BidAcceptedStatusItem transaction={bid.transaction} />
      )}
      <TransferStatusItem
        isCompleted={bidState.haveBothSigned}
        title="Letter of Intent signed"
        subtitle={loiSignedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].awaitingClosing === `SUCCESS`}
        title="Transaction completed"
        subtitle={transactionCompletedSubtitle}
      />
      <TransferStatusItem
        isCompleted={states[state].closed === `SUCCESS`}
        title={t(isBroker ? `hiive_co_broker_fee_payed` : `fee_payed`)}
      />
    </>
  );
};

export default AutomatedLOISellerStates;
