/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  GridItem,
  ModalBody,
  Show,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveAdvancedOptionsDropdown,
  HiiveBackButton,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CheckboxInput,
  DateInput,
  OtherDetailsInput,
  StepPropsV2,
  YesNoRadioInput,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { TransferMethodInput } from "@/components/postings";
import {
  EnableManualSigningProcedureTooltip,
  ListingOtherDetailsTooltip,
} from "@/components/tooltip";
import {
  ModifyListingModalListingFragment,
  TransferMethod,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCustomToast, useModal, useStepValidator } from "@/hooks";
import { getLongDocumentTitleByTransferMethod } from "@/utils";

import {
  ModifyListingModalStepFormContext,
  useModifyListingModalStepFormContext,
} from "./ModifyListingModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { ModifyListingModalFormValues } from "./types";

export const validationSchema = Yup.object().shape({
  transferMethod: Yup.string().required(`Required`),
  hasExpirationDate: Yup.bool().required(`Required`),
  expireAt: Yup.string()
    .nullable()
    .test({
      name: `checkRequired`,
      message: `Required`,
      test: function () {
        if (this.parent.hasExpirationDate) {
          return !!this.parent.expireAt;
        }
        return true;
      },
    }),
  otherDetails: Yup.string().nullable(),
  muteNotifyWatchers: Yup.boolean().nullable(),
  manualSigningProcedure: Yup.boolean(),
});

interface AdditionalDetailsProps
  extends StepPropsV2<StepKeys, ModifyListingModalFormValues> {
  readonly listing: ModifyListingModalListingFragment;
  readonly actor: UserWithInstitutionFragment;
}

const AdditionalDetails = ({
  actor,
  values,
  listing,
  isSubmitting,
  stepRouter,
}: AdditionalDetailsProps) => {
  const { closeModal } = useModal();
  const { successToast } = useCustomToast();
  const { stepControls } = stepRouter;
  const { t } = useTranslation();

  const { submitMutation } = useModifyListingModalStepFormContext();

  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  const documentTitle = getLongDocumentTitleByTransferMethod(
    values.transferMethod,
  );

  const onSuccess = () =>
    submitMutation().then(() => {
      successToast(`Listing modified.`);
      closeModal();
    });

  useStepValidator({
    Context: ModifyListingModalStepFormContext,
    stepKey: stepKeys.modifyListingAdditionalDetails,
    validator: {
      validationSchema,
      onSuccess,
    },
    values,
  });

  const [_field, _, { setValue: setManualSigningProcedure }] = useField(
    `manualSigningProcedure`,
  );

  const handleChangeTransferMethod = (transferMethod: TransferMethod) => {
    if (transferMethod === TransferMethod.ForwardContract) {
      setManualSigningProcedure(true);
    }
  };

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          {!isPartiallySold && (
            <GridItem colSpan={2} data-testid="transfer-method-input">
              <TransferMethodInput
                onChange={handleChangeTransferMethod}
                name="transferMethod"
                label="How will these be transferred?"
              />
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <YesNoRadioInput
              name="hasExpirationDate"
              label="Would you like to add an expiration date?"
              tooltipContent={t(
                `listing_additional_details_expiry_date_tooltip`,
              )}
            />
          </GridItem>
          {values.hasExpirationDate && (
            <GridItem colSpan={2}>
              <DateInput name="expireAt" label="Listing expiration date" />
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <OtherDetailsInput
              name="otherDetails"
              label="Additional listing notes"
              placeholder="Add details"
              tooltipContent={<ListingOtherDetailsTooltip />}
            />
          </GridItem>
          {actor.isHiiveUser && (
            <GridItem colSpan={2}>
              <HiiveAdvancedOptionsDropdown
                validationSchema={validationSchema}
                fieldNames={[`manualSigningProcedure`, `muteNotifications`]}
              >
                <VStack spacing={4} alignItems="flex-start">
                  {!!values.transferMethod && (
                    <CheckboxInput
                      isDisabled={
                        values.transferMethod === TransferMethod.ForwardContract
                      }
                      name="manualSigningProcedure"
                      label={`Enable a manual ${documentTitle} procedure`}
                      tooltipContent={
                        <EnableManualSigningProcedureTooltip
                          transferMethod={values.transferMethod}
                        />
                      }
                    />
                  )}
                  <CheckboxInput
                    name="muteNotifications"
                    label="Do not send notifications about this change."
                  />
                </VStack>
              </HiiveAdvancedOptionsDropdown>
            </GridItem>
          )}
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            sentryLabel="[ModifyListing/AdditionalDetails/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveBackButton
          sentryLabel="[ModifyListing/AdditionalDetails/Back]"
          onClick={stepControls.previousStep}
        />
        <HiiveSubmitButton
          sentryLabel="[ModifyListing/AdditionalDetails/Submit]"
          isLoading={isSubmitting}
          submitText="Modify Listing"
          type="submit"
        />
      </HiiveModalFooter>
    </>
  );
};

export default withCurrentActor(AdditionalDetails);
