import { ReactNode } from "react";

import { GridItem, Text } from "@chakra-ui/react";

const InfoGridItem = ({
  title,
  children,
}: {
  readonly title: string;
  readonly children: ReactNode;
}) => (
  <>
    <GridItem py={4} display="flex" alignItems="center">
      <Text textStyle="heading-2xs">{title}</Text>
    </GridItem>
    <GridItem py={4}>
      <Text textStyle="text-md">{children}</Text>
    </GridItem>
  </>
);

export default InfoGridItem;
