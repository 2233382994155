import { Text } from "@chakra-ui/react";

const BidRoundedSharesTooltip = () => (
  <Text>
    The number of shares displayed in your listing is rounded from the actual
    number of shares you listed. This is done to help protect your identity. If
    the buyer bid for more shares than you listed, the bid will display here at
    the listed number of shares. The buyer is also now notified of the exact
    number of shares you are selling.
  </Text>
);

export default BidRoundedSharesTooltip;
