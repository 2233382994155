import { Badge, HStack, Text } from "@chakra-ui/react";

import { MarketActivityBlockCompanyFragment } from "@/gql";

const MarketActivityBlock = ({
  company: { activity },
}: {
  readonly company: MarketActivityBlockCompanyFragment;
}) => (
  <HStack alignItems="flex-start" w="full">
    <Badge variant="grey">
      <Text as="span" textStyle="heading-md" mr={4}>
        {activity.numOpenListings}
      </Text>
      {` `}
      Listings
    </Badge>
    <Badge variant="grey">
      <Text as="span" textStyle="heading-md" mr={4}>
        {activity.numOpenStandingBidsAndActiveBids}
      </Text>
      {` `}
      Bids
    </Badge>
  </HStack>
);

export default MarketActivityBlock;
