import { useRouter } from "next/router";

import { Flex, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";

const SomethingWentWrongError = ({
  isSignedIn,
}: {
  readonly isSignedIn?: boolean;
}) => {
  const router = useRouter();

  return (
    <Flex direction="column" alignItems="center" pt={{ base: 10, md: 20 }}>
      <Text textStyle="deprecated-heading-7xl" textAlign="center">
        Something went wrong
      </Text>
      <Text textStyle="deprecated-text-4xl" textAlign="center" mb={5}>
        We are working on fixing the problem.
        <Text>Please try again later.</Text>
      </Text>
      {isSignedIn && (
        <HiiveButton
          onClick={() => router.push(`/dashboard`)}
          size="md"
          variant="rounded-solid-salmon"
          sentryLabel="[SomethingWentWrongError] Back to Dashboard"
        >
          Back to Dashboard
        </HiiveButton>
      )}
    </Flex>
  );
};

export default SomethingWentWrongError;
