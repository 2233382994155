import { Text, VStack } from "@chakra-ui/react";

import { BidPageNotSignerForInstitutionBidFragment } from "@/gql";
import { getLongDocumentTitleByDocumentType } from "@/utils";

const NotSignerForInstitution = ({
  bid,
}: {
  readonly bid: BidPageNotSignerForInstitutionBidFragment;
}) =>
  bid.transaction?.document ? (
    <VStack
      py={5}
      px={5}
      bg="white"
      borderColor="grey.100"
      borderWidth="1px"
      borderRadius="md"
      alignItems="flex-start"
      spacing={6}
      w="full"
    >
      <Text textStyle="heading-lg">
        You are not a signer for your institution
      </Text>
      <Text>
        Completing the{` `}
        {getLongDocumentTitleByDocumentType(bid.transaction?.document.type)}
        {` `}
        process requires approval from a{` `}
        <Text fontWeight="medium" as="span">
          signer
        </Text>
        {` `}
        in your institution.
      </Text>
    </VStack>
  ) : null;

export default NotSignerForInstitution;
