import { useTranslation } from "react-i18next";

import { Card, CardBody, CardHeader, Text, VStack } from "@chakra-ui/react";

export const MethodologyCard = () => {
  const { t } = useTranslation();

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`methodology`)}</Text>
      </CardHeader>
      <CardBody>
        <VStack alignItems="start" spacing={1}>
          <Text textStyle="text-sm">{t(`methodology_description`)}</Text>
        </VStack>
      </CardBody>
    </Card>
  );
};
