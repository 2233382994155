import { Form } from "formik";
import { useEffect } from "react";
import { match } from "ts-pattern";
import * as Yup from "yup";

import { Stack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FormikQL, RadioCardInput, RadioCard } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  CurrentContextDocument,
  InvestorStatus,
  InvestorType,
  UserWithInstitutionFragment,
  useUpdateUserMutation,
} from "@/gql";
import { useRouteToNextOnboardingStep } from "@/hooks";

enum InvestorTypeOption {
  Investor = `Investor`,
  SellerOnly = `SellerOnly`,
}

const initialValues = {
  selectedOption: null,
};

const mapVariables = ({
  selectedOption,
}: {
  readonly selectedOption: InvestorTypeOption | null;
}) => ({
  input: match(selectedOption)
    .with(InvestorTypeOption.Investor, () => ({
      investorType: InvestorType.Trader,
    }))
    .with(InvestorTypeOption.SellerOnly, () => ({
      investorStatus: InvestorStatus.Individual,
      investorType: InvestorType.UnaccreditedSeller,
    }))
    .run(),
});

const validationSchema = Yup.object().shape({
  selectedOption: Yup.mixed().oneOf(
    [InvestorTypeOption.Investor, InvestorTypeOption.SellerOnly],
    `Required`,
  ),
});

const SelectInput = () => (
  <RadioCardInput
    name="selectedOption"
    textAlign="left"
    options={[
      {
        value: `Investor`,
        label: `Individual Investor`,
        text: `You will use Hiive to buy or sell private stock and monitor data about your portfolio. If you are a high income-earner, have a high net worth, or have holdings in multiple companies, this is the best option for you.`,
      },
      {
        value: `SellerOnly`,
        label: `Seller Only`,
        text: `You will use Hiive only to sell private stock or monitor pricing data for a single company.
        If you are a current or former employee in a startup, and only hold stock in one company, this is the best option for you.`,
      },
    ]}
    renderOptions={(options, { getRootProps, getRadioProps }) => (
      <Stack
        {...getRootProps()}
        alignItems="stretch"
        direction={{ base: `column`, md: `row` }}
        spacing={5}
      >
        {options.map(({ value, label, text }) => {
          const radio = getRadioProps({ value });

          return (
            <RadioCard
              alignItems="start"
              display="flex"
              justifyContent="start"
              key={value}
              p={5}
              textAlign="left"
              w="full"
              {...radio}
            >
              <Text mb={2}>{label}</Text>
              <Text textStyle="text-md">{text}</Text>
            </RadioCard>
          );
        })}
      </Stack>
    )}
    cardSize="lg"
  />
);

const InvestorTypePage = ({
  actor: { investorType },
}: {
  readonly actor: UserWithInstitutionFragment;
}) => {
  const routeToNextStep = useRouteToNextOnboardingStep();

  useEffect(() => {
    if (!investorType) return;
    routeToNextStep();
  }, []);

  const mutation = useUpdateUserMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const onSuccess = () => routeToNextStep();

  return (
    <FormikQL
      initialValues={initialValues}
      mapVariables={mapVariables}
      mutation={mutation}
      mutationNames={[`updateUser`]}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <VStack
            maxW={{ base: `none`, md: `40rem` }}
            mx={{ base: `auto`, md: `calc((100% - 40rem) / 2)` }}
            spacing={5}
          >
            <Text align="center" color="grey.900" maxW="md" mt={7}>
              In what capacity will you be using Hiive?
            </Text>
            <SelectInput />
            <HiiveButton
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              sentryLabel="[InvestorTypePage] Next"
              size="md"
              type="submit"
              variant="rounded-solid-salmon"
            >
              Next
            </HiiveButton>
          </VStack>
        </Form>
      )}
    </FormikQL>
  );
};

export default withCurrentActor(InvestorTypePage);
