import { Form } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { PasswordField, PASSWORD_REGEX } from "@/components/auth";
import { ButtonFooterWrapper, HiiveSubmitButton } from "@/components/common";
import { FormikQL } from "@/components/form";
import { useUpdatePasswordMutation } from "@/gql";
import { useSignOut } from "@/hooks";

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .nullable()
    .required(`Current password is required`),
  password: Yup.string()
    .nullable()
    .required(`Password is required`)
    .matches(
      PASSWORD_REGEX,
      `Must contain at least 8 characters, one uppercase, one lowercase, one number or punctuation character`,
    ),
  passwordConfirmation: Yup.string()
    .nullable()
    .required(`Password confirmation is required`)
    .oneOf([Yup.ref(`password`)], `Passwords must match`),
});

interface ChangePasswordFormValues {
  readonly currentPassword: string;
  readonly password: string;
  readonly passwordConfirmation: string;
}

const initialValues: ChangePasswordFormValues = {
  currentPassword: ``,
  password: ``,
  passwordConfirmation: ``,
};

const mapVariables = (values: ChangePasswordFormValues) => ({
  input: {
    ...values,
  },
});

export const ChangePasswordCard = () => {
  const signOut = useSignOut();

  const onSuccess = () => signOut(`/account/password-update-confirmed`);

  const mutation = useUpdatePasswordMutation();

  return (
    <FormikQL
      mutation={mutation}
      mutationNames={[`updatePassword`]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapVariables={mapVariables}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <Card w="full" flex="1" as={Form} autoComplete="off">
          <CardHeader>
            <Text textStyle="heading-sm">Change Password</Text>
          </CardHeader>
          <CardBody>
            <SimpleGrid columns={2} columnGap={9}>
              <GridItem colSpan={{ base: 2, xl: 1 }}>
                <VStack spacing={6}>
                  <SimpleGrid columns={1} rowGap={9} w="full">
                    <PasswordField
                      isRequired
                      name="currentPassword"
                      label="Current Password"
                      placeholder="Current password"
                    />
                    <VStack spacing={2}>
                      <PasswordField
                        isRequired
                        name="password"
                        label="New Password"
                        placeholder="Enter a password"
                      />
                      <PasswordField
                        name="passwordConfirmation"
                        placeholder="Confirm new password"
                      />
                    </VStack>
                  </SimpleGrid>
                  <ButtonFooterWrapper>
                    <HiiveSubmitButton
                      submitText="Update"
                      type="submit"
                      sentryLabel="[ChangePassword/Submit]"
                      isLoading={isSubmitting}
                    />
                  </ButtonFooterWrapper>
                </VStack>
              </GridItem>
            </SimpleGrid>
          </CardBody>
        </Card>
      )}
    </FormikQL>
  );
};
