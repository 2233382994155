import { BoxProps } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { DocumentType, UserWithInstitutionFragment } from "@/gql";

import { PandadocFormFrame } from "./PandadocFormFrame";

interface IndividualCustomerAgreementProps extends BoxProps {
  readonly onFormSigning: () => void;
  readonly actor: UserWithInstitutionFragment;
}

const IndividualCustomerAgreement = ({
  onFormSigning,
  actor: { id, firstName, lastName, email, name },
  ...boxProps
}: IndividualCustomerAgreementProps) => {
  const formData = {
    recipients: {
      Customer: {
        FirstName: firstName || ``,
        LastName: lastName || ``,
        Email: email,
      },
    },
    metadata: {
      user_id: id,
      document_type: DocumentType.MembershipAgreement,
    },
    fields: { CustomerFullName: name, CustomerEmail: email },
  };

  return (
    <PandadocFormFrame
      title="Customer Agreement Embed"
      onCompleted={onFormSigning}
      formId={process.env.NEXT_PUBLIC_INDIVIDUAL_AGREEMENT_FORM_ID || ``}
      data={formData}
      border="grey-border"
      w={{ base: `100%`, md: `900px` }}
      h="754px"
      {...boxProps}
    />
  );
};

export default withCurrentActor(IndividualCustomerAgreement);
