import { useFormikContext } from "formik";

import { VStack } from "@chakra-ui/react";

import { HoldingsFormValues } from "@/components/unaccredited-seller";

import { HoldingInputCard } from "./HoldingInputCard";

const HoldingsInput = ({
  handleRemove,
}: {
  handleRemove: (index: number) => () => void;
}) => {
  const { values } = useFormikContext<HoldingsFormValues>();

  return (
    <VStack align="stretch" width="full" gap={4}>
      {values.holdingBundles.map(({ company, holding }, index) => (
        <HoldingInputCard
          key={`holding-${index.toString()}`}
          holding={holding}
          company={company}
          inputId={`holdingBundles[${index}].holding`}
          handleRemove={handleRemove(index)}
        />
      ))}
    </VStack>
  );
};

export default HoldingsInput;
