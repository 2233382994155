import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { StackProps } from "@chakra-ui/react";

import {
  ComplianceReminder,
  CTACard,
  MailtoLink,
  WithQuery,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  BidState,
  CompanyActionsBidFragment,
  CompanyActionsCompanyFragment,
  InvestorType,
  ListingPermission,
  ListingState,
  useCompanyPageActionsCompanyByIdQuery,
  UserPermissionV2,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  iHaveEntityPermission,
  useBidFilter,
  useIHavePermission,
  useModal,
} from "@/hooks";
import { useSellSideBrokerPortalEnabled } from "@/hooks/featureFlags";
import { TradeIntentType } from "@/types";
import {
  getAvailableCompanyActions,
  hasInvestorType,
  getIsBroker,
  getIsInstitutionUser,
  getIsInstitutionViewer,
  getSecuritySpecialistContact,
} from "@/utils";

import { CompanyActionsCTACardGrid } from "./CompanyActionsCTACardGrid";
import CompanyActionsSkeleton from "./CompanyActionsSkeleton";
import { useCompanyActionsCTACardDirection } from "./util";

const BuyerNeedsCompanyApprovalCard = ({
  direction,
  company,
}: {
  readonly direction: StackProps["direction"];
  readonly company: CompanyActionsCompanyFragment;
}) => {
  const { t } = useTranslation();

  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <CTACard
      direction={direction}
      heading={t(`make_bid`)}
      description={
        <Trans
          i18nKey="must_be_approved_by_company"
          t={t}
          components={[
            <MailtoLink
              key="contact_for_buyer_approval"
              textDecoration="underline"
              email={securitySpecialistEmail}
              subject={`Buy Approval on ${company.name}`}
            />,
          ]}
          values={{ companyName: company.name }}
        />
      }
      action={{ disabled: true }}
    />
  );
};

const ListForSaleCTACard = ({
  direction,
  company,
  actor,
}: {
  readonly direction: StackProps["direction"];
  readonly company: CompanyActionsCompanyFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation();
  const { modals, onOpenModal } = useModal();

  const { canCreateListing } = getAvailableCompanyActions(company);

  const handleClick = () => {
    onOpenModal(modals.createListingOnCompany(company))();
  };

  const isSSBPFlagOn = useSellSideBrokerPortalEnabled();

  if (getIsBroker(actor) && isSSBPFlagOn) {
    return (
      <CTACard
        direction={direction}
        heading={t(`submit_a_listing`)}
        description={t(`submit_listing_cta`)}
        action={{
          ctaText: t(`submit_a_listing`),
          onClick: onOpenModal(modals.brokerSubmitListingOnCompany(company)),
        }}
      />
    );
  }

  return (
    <CTACard
      direction={direction}
      heading={t(`list_for_sale`)}
      description={t(`create_listing_cta`)}
      action={{
        ctaText: t(`sell`),
        disabled: !canCreateListing,
        onClick: handleClick,
      }}
      footer={
        canCreateListing ? null : (
          <ComplianceReminder tradeIntent={TradeIntentType.Sell} />
        )
      }
    />
  );
};

const BrokerMakeBidCTACard = ({
  handleClick,
  direction,
  company,
}: {
  readonly handleClick: () => void;
  readonly direction: StackProps[`direction`];
  readonly company: CompanyActionsCompanyFragment;
}) => {
  const { t } = useTranslation();

  const { canPlaceBid: iCanPlaceBidOnCompany } = getAvailableCompanyActions(
    company,
  );
  const iHavePlaceBidPermission = useIHavePermission(UserPermissionV2.PlaceBid);

  const iNeedCompanyBuyerApproval =
    company.requiresApprovedBuyers &&
    !iCanPlaceBidOnCompany &&
    iHavePlaceBidPermission;

  if (iNeedCompanyBuyerApproval) {
    return (
      <BuyerNeedsCompanyApprovalCard direction={direction} company={company} />
    );
  }

  return (
    <CTACard
      direction={direction}
      heading={t(`submit_an_order`)}
      description={t(`submit_order_description`)}
      action={{
        ctaText: t(`submit_order_cta`),
        onClick: handleClick,
      }}
    />
  );
};

const MakeBidCTACard = ({
  direction,
  actor,
  company,
}: {
  readonly direction: StackProps["direction"];
  readonly actor: UserWithInstitutionFragment;
  readonly company: CompanyActionsCompanyFragment;
}) => {
  const { id: meId, institutionId: meInstitutionId } = actor;

  const { modals, onOpenModal } = useModal();
  const { t } = useTranslation();
  const { filterBidsIMade } = useBidFilter(actor);
  const { canPlaceBid: iCanPlaceBidOnCompany } = getAvailableCompanyActions(
    company,
  );
  const iHavePlaceBidPermission = useIHavePermission(UserPermissionV2.PlaceBid);

  const iNeedCompanyBuyerApproval =
    iHavePlaceBidPermission &&
    company.requiresApprovedBuyers &&
    !iCanPlaceBidOnCompany;

  const hasActiveBid = useCallback(
    (bids: readonly CompanyActionsBidFragment[]) =>
      filterBidsIMade(bids)?.some(
        (bid) =>
          (bid.buyerId === meId ||
            (!!bid.buyerInstitutionId &&
              bid.buyerInstitutionId === meInstitutionId)) &&
          bid.state === BidState.Active,
      ) || false,
    [filterBidsIMade],
  );

  const listingsICanBidOn = useCallback(
    (_company: CompanyActionsCompanyFragment) =>
      _company.activity.othersListings.filter(
        (listing) =>
          listing.state === ListingState.Open &&
          (listing.bids.length === 0 || !hasActiveBid(listing.bids)) &&
          iHaveEntityPermission(listing, ListingPermission.PlaceBid),
      ),
    [hasActiveBid],
  );

  const handleClick = () => {
    if (listingsICanBidOn(company).length === 0) {
      onOpenModal(modals.placeStandingBid(company))();
      return;
    }
    onOpenModal(modals.placeBid(company))();
  };

  if (!iHavePlaceBidPermission) {
    return (
      <CTACard
        direction={direction}
        heading={t(`make_bid`)}
        description={t(`bid_on_existing_listing`)}
        action={{ disabled: true }}
        footer={<ComplianceReminder tradeIntent={TradeIntentType.Buy} />}
      />
    );
  }

  if (iNeedCompanyBuyerApproval) {
    return (
      <BuyerNeedsCompanyApprovalCard direction={direction} company={company} />
    );
  }

  return (
    <CTACard
      direction={direction}
      heading={t(`make_bid`)}
      description={t(`bid_on_existing_listing`)}
      action={{ ctaText: t(`buy`), onClick: handleClick }}
    />
  );
};

const BrokerCompanyActions = ({
  actor,
  company,
  direction,
}: {
  readonly direction: StackProps[`direction`];
  readonly actor: UserWithInstitutionFragment;
  readonly company: CompanyActionsCompanyFragment;
}) => {
  const isSSBPFlagOn = useSellSideBrokerPortalEnabled();
  const { modals, onOpenModal } = useModal();

  return (
    <CompanyActionsCTACardGrid numActionsAvailable={2}>
      {isSSBPFlagOn && (
        <ListForSaleCTACard
          company={company}
          direction={direction}
          actor={actor}
        />
      )}
      <BrokerMakeBidCTACard
        direction={direction}
        company={company}
        handleClick={() => onOpenModal(modals.brokerSubmitBid(company))()}
      />
    </CompanyActionsCTACardGrid>
  );
};

export const CompanyActionsContent = withCurrentActor(
  ({
    actor,
    company,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly company: CompanyActionsCompanyFragment;
  }) => {
    const isInstitutionUser = getIsInstitutionUser(actor);
    const isInstitutionViewer = getIsInstitutionViewer(actor);
    const isTrader = hasInvestorType(actor, InvestorType.Trader);
    const isCompanyTradeable = company.tradeable;
    const isBroker = getIsBroker(actor);
    const isSSBPFlagOn = useSellSideBrokerPortalEnabled();

    const numActionsBroker = isSSBPFlagOn ? 2 : 1;
    const directionBroker = useCompanyActionsCTACardDirection(numActionsBroker);

    const canViewMakeBidCTA = isInstitutionUser || isTrader;
    const numActionsAvailable = canViewMakeBidCTA ? 2 : 1;
    const direction = useCompanyActionsCTACardDirection(numActionsAvailable);

    if (isInstitutionViewer || !isCompanyTradeable) {
      return null;
    }

    if (isBroker)
      return (
        <BrokerCompanyActions
          direction={directionBroker}
          actor={actor}
          company={company}
        />
      );

    return (
      <CompanyActionsCTACardGrid numActionsAvailable={numActionsAvailable}>
        <ListForSaleCTACard
          company={company}
          direction={direction}
          actor={actor}
        />
        {canViewMakeBidCTA && (
          <MakeBidCTACard
            actor={actor}
            company={company}
            direction={direction}
          />
        )}
      </CompanyActionsCTACardGrid>
    );
  },
);

const CompanyActions = ({ companyId }: { readonly companyId: string }) => {
  const query = useCompanyPageActionsCompanyByIdQuery({
    variables: { id: companyId },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<CompanyActionsSkeleton />}>
      {({ data: { companyById: company } }) => (
        <CompanyActionsContent company={company} />
      )}
    </WithQuery>
  );
};

export default CompanyActions;
