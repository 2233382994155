import { ListItem, Text, UnorderedList, VStack } from "@chakra-ui/react";

const ListingNotesExplainer = () => (
  <VStack alignItems="flex-start">
    <Text>
      Provide any relevant additional details to potential buyers.{` `}
      <Text as="strong" fontWeight="medium">
        We will share these notes publicly on your Listing once posted.
      </Text>
    </Text>
    <Text>
      Here are some examples of additional details that can be helpful to share
      with buyers:
    </Text>
    <UnorderedList stylePosition="inside">
      <ListItem>
        Can you share some information on the company (verbally or in writing),
        and under what conditions?
      </ListItem>
      <ListItem>
        Do you hold options and need help funding the exercise?
      </ListItem>
      <ListItem>
        Are there any timing limitations on closing (e.g. must be before or
        after a specific date)?
      </ListItem>
      <ListItem>Is your price flexible?</ListItem>
    </UnorderedList>
  </VStack>
);

export default ListingNotesExplainer;
