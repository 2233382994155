import { Warning, WarningCircle } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { HStack, Text, VStack } from "@chakra-ui/react";

import { useColors } from "@/hooks";

interface Theme {
  border: string;
  icon: string;
  background?: string;
  component: typeof WarningCircle | typeof Warning;
}

const THEME: Record<"critical" | "warning", Theme> = {
  critical: {
    border: `salmon.900`,
    icon: `red.600`,
    component: WarningCircle,
  },
  warning: {
    border: `yellow.600`,
    icon: `yellow.600`,
    background: `yellow.100`,
    component: Warning,
  },
};

interface ActionNeededProps {
  heading?: string | null;
  children: ReactNode;
  variant?: "critical" | "warning";
  noHeading?: boolean;
}

const ActionNeeded = ({
  heading,
  children,
  variant = `critical`,
  noHeading = false,
}: ActionNeededProps) => {
  const { t } = useTranslation();
  const { border, icon, background = `white`, component: Icon } = THEME[
    variant
  ];
  const [iconColor] = useColors([icon]);

  const showHeading = !noHeading;

  return (
    <VStack
      alignItems="flex-start"
      p={4}
      borderWidth={1}
      borderColor={border}
      bg={background}
      borderRadius="md"
      w="full"
      spacing={2.5}
    >
      <HStack alignItems={showHeading ? `center` : `start`} spacing={2.5}>
        <Icon
          size={20}
          color={iconColor}
          weight="fill"
          style={{ flexShrink: 0 }}
        />
        {showHeading ? (
          <Text textStyle="heading-md">{heading ?? t(`action_needed`)}</Text>
        ) : (
          <Text>{children}</Text>
        )}
      </HStack>
      {showHeading && <Text>{children}</Text>}
    </VStack>
  );
};

export default ActionNeeded;
