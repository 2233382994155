import { useMemo } from "react";

import { client, createClient } from "@/apollo-client";

import { useServerPreview } from "./useServerPreview";

export function useApolloClient() {
  const apiUrl = useServerPreview();

  return useMemo(() => createClient(apiUrl || ``) || client, [apiUrl]);
}
