/* eslint-disable consistent-return */

/* eslint-disable functional/immutable-data */

/* eslint-disable no-param-reassign */
import { useLayoutEffect, useRef, RefObject } from "react";

/**
 * This hook utilizes the ResizeObserver API to allow an element to sync or "mimic" another element's height.
 *
 * @param referenceRef - the ref of the element with a height that the target element should sync
 * @param targetRef - the ref of the element to apply the synced height too
 */
const useSyncHeight = <
  TReferenceElement extends Element,
  TTargetElement extends Element & { readonly style: CSSStyleDeclaration }
>(
  referenceRef: RefObject<TReferenceElement>,
  targetRef: RefObject<TTargetElement>,
) => {
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useLayoutEffect(() => {
    if (!referenceRef.current || !targetRef.current) {
      if (!resizeObserverRef.current) return;

      resizeObserverRef.current.disconnect();
      return;
    }

    resizeObserverRef.current = new ResizeObserver((entries) => {
      if (!targetRef.current) return;

      const {
        contentRect: { height },
      } = entries[0];

      targetRef.current.style.minHeight = `${height}px`;
    });

    resizeObserverRef.current.observe(referenceRef.current);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }

      if (targetRef.current) {
        targetRef.current.style.minHeight = ``;
      }
    };
  }, [referenceRef, targetRef]);
};

export default useSyncHeight;
