import { defineStyleConfig } from "@chakra-ui/react";

export const Form = defineStyleConfig({
  baseStyle: () => ({
    helperText: {
      color: `grey.900`,
      lineHeight: `short`,
    },
  }),
});
