import { TransactingEntityItem } from "@/components/account";

import TransactingEntityCard, {
  TransactingEntityCardProps,
  TransactingEntityCardTypes,
} from "./TransactingEntityCard";

type TransactingEntitySellerCardProps = Omit<
  TransactingEntityCardProps,
  "type"
>;

const TransactingEntitySellerCard = (
  props: TransactingEntitySellerCardProps,
) => {
  const { transaction } = props;
  const transactingEntity = transaction?.sellerEntity;
  const hasTransactingEntity = !!transactingEntity;

  return (
    <TransactingEntityCard type={TransactingEntityCardTypes.Seller} {...props}>
      {hasTransactingEntity && (
        <TransactingEntityItem
          entity={transactingEntity}
          showDate={false}
          showRemoveButton={false}
          showStatus={false}
        />
      )}
    </TransactingEntityCard>
  );
};

export default TransactingEntitySellerCard;
