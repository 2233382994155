import { MagnifyingGlass } from "@phosphor-icons/react";
import isNil from "lodash/isNil";
import { useState } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import {
  FormControl,
  FormErrorMessage,
  FormLabelProps,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

import { Combobox, ItemList } from "@/components/form";
import { useColors, useCombobox } from "@/hooks";

interface FormComboboxProps<TItem, TFieldValues extends FieldValues> {
  /** Name of the combobox field */
  readonly name: Path<TFieldValues>;
  /** The placeholder text that should be shown in the combobox input */
  readonly placeholder: string;
  /** A function that returns a list of items to show in the combobox given a search value */
  readonly getItems: ({
    search,
  }: {
    readonly search: string;
  }) => ItemList<TItem>;
  /** Callback that fires when the search value changes */
  readonly onChangeSearch?: (search: string) => void;
  /** Callback that fires when an item is selected */
  readonly onSelectItem?: (item: TItem | null) => void;
  /** Function that maps items to a string representation
   * that is shown in the input when an item is selected */
  readonly itemToString: (item: TItem) => string;
  /** Function that maps an item to a unique key */
  readonly getItemKey: (item: TItem) => string;
  /** Function that checks if a given item is disabled */
  readonly getItemDisabled?: (item: TItem) => boolean;
  /** Label for the combobox input field */
  readonly label: string;
  /** Whether or not to hide the label (screen-reader only) */
  readonly labelSrOnly?: FormLabelProps[`srOnly`];
  /** Description of the field (shown below between the label & the input) */
  readonly description?: string;
  /** Whether or not the combobox is loading (shows a skeleton in the dropdown menu) */
  readonly isLoading: boolean;
  /** Whether or not the combobox is disabled (don't allow typing in input) */
  readonly isDisabled?: boolean;
  /** React-Hook-Form control */
  readonly control: Control<TFieldValues>;
  /** Whether or not the search icon is shown */
  readonly showSearchIcon?: boolean;
}

const FormCombobox = <TItem, TFieldValues extends FieldValues>({
  control,
  description,
  getItemDisabled = () => false,
  getItemKey,
  getItems,
  isDisabled,
  isLoading,
  itemToString,
  label,
  labelSrOnly,
  name,
  onChangeSearch,
  onSelectItem,
  placeholder,
  showSearchIcon,
}: FormComboboxProps<TItem, TFieldValues>) => {
  const {
    field: { value, onChange, onBlur, ref, disabled },
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control, disabled: isDisabled });

  const [search, setSearch] = useState(!!value ? itemToString(value) : ``);

  const handleChangeInputValue = (inputValue: string) => {
    if (!isNil(onChangeSearch)) onChangeSearch(inputValue);

    setSearch(inputValue);
  };

  const items = getItems({ search });

  const { inputProps, menuProps, labelProps, itemProps } = useCombobox<TItem>({
    items,
    itemToString,
    getItemKey,
    selectedItem: value,
    onSelectItem: (item: TItem | null) => {
      onChange(item);
      onSelectItem?.(item);
      if (!isNil(item)) handleChangeInputValue(itemToString(item));
    },
    inputValue: search,
    onChangeInputValue: handleChangeInputValue,
    isLoading,
  });

  const [grey500] = useColors([`grey.500`]);

  return (
    <FormControl id={name} isInvalid={invalid}>
      <Combobox.Label srOnly={labelSrOnly} {...labelProps}>
        {label}
      </Combobox.Label>
      {description && <Combobox.Description description={description} />}
      <Combobox.Container>
        <InputGroup>
          {showSearchIcon && (
            <InputLeftElement pointerEvents="none">
              <MagnifyingGlass
                size={20}
                color={grey500}
                opacity={isDisabled ? `.5` : ``}
              />
            </InputLeftElement>
          )}
          <Combobox.Input
            isDisabled={disabled}
            placeholder={placeholder}
            onBlur={onBlur}
            name={name}
            ref={ref}
            {...inputProps}
          />
        </InputGroup>
        <Combobox.Menu isLoading={isLoading} {...menuProps}>
          {items.map((item, index) => (
            <Combobox.Item
              key={getItemKey(item)}
              isDisabled={getItemDisabled(item)}
              item={item}
              index={index}
              {...itemProps}
            >
              {itemToString(item)}
            </Combobox.Item>
          ))}
        </Combobox.Menu>
      </Combobox.Container>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormCombobox;
